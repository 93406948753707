import {
    ChangeDetectorRef,
    Component,
    ElementRef,
    EventEmitter,
    HostListener,
    Input,
    OnDestroy,
    OnInit,
    Output,
    ViewChild,
} from '@angular/core';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { Router } from '@angular/router';
import JSZip from 'jszip';
import { Subscription } from 'rxjs';
import { dialogEnterAndLeaveAnimation } from '@autoixpert/animations/dialog-enter-and-leave.animation';
import { ClaimantSignatureDocumentsComponent } from '@autoixpert/components/claimant-signature/claimant-signature-documents/claimant-signature-documents.component';
import {
    ConfirmDialogComponent,
    ConfirmDialogData,
} from '@autoixpert/components/confirm-dialog/confirm-dialog.component';
import { SignaturePadComponent } from '@autoixpert/components/signature-pad/signature-pad.component';
import {
    RenderedDocumentBuildingBlock,
    chooseAndRenderDocumentBuildingBlocks,
} from '@autoixpert/lib/document-building-blocks/choose-and-render-document-building-blocks';
import { matchConditions } from '@autoixpert/lib/document-building-blocks/match-conditions';
import { sortBuildingBlocksAccordingToTemplateConfig } from '@autoixpert/lib/document-building-blocks/sort-building-blocks-according-to-template-config';
import { addMissingClaimantSignaturesOnPdfTemplateDocument } from '@autoixpert/lib/documents/add-missing-claimant-signatures-on-pdf-template-document';
import { getAllCheckboxElementsFromSignablePdfTemplateConfig } from '@autoixpert/lib/documents/get-all-checkbox-elements-from-signable-pdf-template-config';
import { getAllSignatureElementsFromSignablePdfTemplateConfig } from '@autoixpert/lib/documents/get-all-signature-elements-from-signable-pdf-template-config';
import { setupAllClaimantSignaturesOnBuildingBlockDocuments } from '@autoixpert/lib/documents/setup-all-claimant-signatures-on-building-block-documents';
import { generateId } from '@autoixpert/lib/generate-id';
import { isEqualAx } from '@autoixpert/lib/is-equal-ax';
import {
    PlaceholderValueTree,
    getPlaceholderValueTree,
} from '@autoixpert/lib/placeholder-values/get-placeholder-value-tree';
import { PlaceholderValues } from '@autoixpert/lib/placeholder-values/get-placeholder-values';
import { isReportLocked } from '@autoixpert/lib/report/is-report-locked';
import { shouldPowerOfAttorneyDocumentBeVisible } from '@autoixpert/lib/signable-documents/should-power-of-attorney-document-be-visible';
import { getSignableDocumentsRenderingConfig } from '@autoixpert/lib/signable-documents/signable-documents-rendering-config';
import { trackById } from '@autoixpert/lib/track-by/track-by-id';
import { DocumentTypes, translateDocumentType } from '@autoixpert/lib/translators/translate-document-type';
import { isAdmin } from '@autoixpert/lib/users/is-admin';
import { translateAccessRightToGerman } from '@autoixpert/lib/users/translate-access-right-to-german';
import { FieldGroupConfig } from '@autoixpert/models/custom-fields/field-group-config';
import { DocumentBuildingBlock } from '@autoixpert/models/documents/document-building-block';
import { DocumentBuildingBlockVariant } from '@autoixpert/models/documents/document-building-block-variant';
import { SignableDocumentType } from '@autoixpert/models/documents/document-metadata';
import { DocumentOrderConfig } from '@autoixpert/models/documents/document-order-config';
import { AxError } from '@autoixpert/models/errors/ax-error';
import { CustomFeeSet } from '@autoixpert/models/reports/assessors-fee/custom-fee-set';
import { Report } from '@autoixpert/models/reports/report';
import { ClaimantSignature } from '@autoixpert/models/signable-documents/claimant-signature';
import { CheckboxValueTracker, SignableDocument } from '@autoixpert/models/signable-documents/signable-document';
import { SignableDocumentsRenderingConfig } from '@autoixpert/models/signable-documents/signable-document-rendering-config';
import {
    CheckboxElement,
    SignablePdfTemplateConfig,
    SignatureElement,
} from '@autoixpert/models/signable-documents/signable-pdf-template-config';
import { Team } from '@autoixpert/models/teams/team';
import { User } from '@autoixpert/models/user/user';
import { CustomFeeSetService } from 'src/app/shared/services/custom-fee-set.service';
import { DownloadService } from 'src/app/shared/services/download.service';
import { fadeInAndOutAnimation } from '../../../../shared/animations/fade-in-and-out.animation';
import { getMissingAccessRightTooltip } from '../../../../shared/libraries/get-missing-access-right-tooltip';
import { hasAccessRight } from '../../../../shared/libraries/user/has-access-right';
import { ApiErrorService } from '../../../../shared/services/api-error.service';
import { DocumentBuildingBlockService } from '../../../../shared/services/document-building-block.service';
import { DocumentOrderConfigService } from '../../../../shared/services/document-order-config.service';
import { FieldGroupConfigService } from '../../../../shared/services/field-group-config.service';
import { LoggedInUserService } from '../../../../shared/services/logged-in-user.service';
import { NewWindowService } from '../../../../shared/services/new-window.service';
import { ReportDetailsService } from '../../../../shared/services/report-details.service';
import { SignablePdfTemplateConfigService } from '../../../../shared/services/signable-pdf-template-config.service';
import { TeamService } from '../../../../shared/services/team.service';
import { TemplatePlaceholderValuesService } from '../../../../shared/services/template-placeholder-values.service';
import { ToastService } from '../../../../shared/services/toast.service';
import { UserService } from '../../../../shared/services/user.service';

/**
 * This component offers signing documents to the customer of our users.
 *
 * There are two types of documents:
 * - generic: generated from document building blocks. Mainly a simple PDF.
 * - custom: the user uploaded a PDF in settings and defined where to put
 *           placeholder values and have the user sign the document.
 */
@Component({
    selector: 'customer-signatures-dialog',
    templateUrl: 'customer-signatures-dialog.component.html',
    styleUrls: ['customer-signatures-dialog.component.scss'],
    animations: [
        fadeInAndOutAnimation(), // In case the animation duration is changed, update the switchTab method of this class
        dialogEnterAndLeaveAnimation(),
    ],
})
export class CustomerSignaturesDialogComponent implements OnInit, OnDestroy {
    constructor(
        private toastService: ToastService,
        private loggedInUserService: LoggedInUserService,
        private documentBuildingBlockService: DocumentBuildingBlockService,
        private reportDetailsService: ReportDetailsService,
        private userService: UserService,
        private changeDetectorRef: ChangeDetectorRef,
        private apiErrorService: ApiErrorService,
        private teamService: TeamService,
        private fieldGroupConfigService: FieldGroupConfigService,
        private newWindowService: NewWindowService,
        private documentOrderConfigService: DocumentOrderConfigService,
        private templatePlaceholderValuesService: TemplatePlaceholderValuesService,
        private signablePdfTemplateConfigService: SignablePdfTemplateConfigService,
        private customFeeSetService: CustomFeeSetService,
        private dialog: MatDialog,
        private downloadService: DownloadService,
        private router: Router,
    ) {}

    trackById = trackById;

    public user: User;
    public team: Team;
    protected signableDocumentsRenderingConfig: SignableDocumentsRenderingConfig;

    private subscriptions: Subscription[] = [];

    //*****************************************************************************
    //  Inputs & Outputs
    //****************************************************************************/
    @Input() public report: Report;

    @Output() public close: EventEmitter<void> = new EventEmitter<void>();
    /////////////////////////////////////////////////////////////////////////////*/
    //  END Inputs & Outputs
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  View Children
    //****************************************************************************/
    @ViewChild(SignaturePadComponent) signaAllAtOnceSignaturePad: SignaturePadComponent;
    @ViewChild(ClaimantSignatureDocumentsComponent) claimantSignatureDocuments?: ClaimantSignatureDocumentsComponent;
    @ViewChild('dialogScrollableArea') dialogScrollableArea: ElementRef<HTMLDivElement>;
    // Component used to display PDF files that the user may sign on.
    @ViewChild(ClaimantSignatureDocumentsComponent)
    claimantSignatureDocumentsComponent: ClaimantSignatureDocumentsComponent;
    /////////////////////////////////////////////////////////////////////////////*/
    //  END View Children
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Tabs
    //****************************************************************************/
    public selectedTab: DocumentTab;
    public availableTabs: DocumentTab[] = [];
    public tabSelectionChangeOverlayShown: boolean = false;
    /////////////////////////////////////////////////////////////////////////////*/
    //  END Tabs
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Document Building Blocks
    //****************************************************************************/
    public renderedDocumentBuildingBlocks: LoadedDocumentBuildingBlocks = {};
    public visibleDocumentBuildingBlocks: RenderedDocumentBuildingBlock[] = [];
    public documentOrderConfigs: DocumentOrderConfig[] = [];
    public buildingBlocksPending: boolean = true;
    /////////////////////////////////////////////////////////////////////////////*/
    //  END Document Building Blocks
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Signable PDF Template Configs
    //****************************************************************************/
    public signablePdfTemplateConfigs: SignablePdfTemplateConfig[] = [];
    public selectedSignablePdfTemplateConfig: SignablePdfTemplateConfig;
    /////////////////////////////////////////////////////////////////////////////*/
    //  END Signable PDF Template Configs
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Placeholder Value Tree
    //****************************************************************************/
    // Used to determine the right signable PDF template config.
    public placeholderValues: PlaceholderValues;
    public placeholderValueTree: PlaceholderValueTree;
    /////////////////////////////////////////////////////////////////////////////*/
    //  END Placeholder Value Tree
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Signature
    //****************************************************************************/
    public selectedSignableDocument: SignableDocument;
    public savingSignaturePending: boolean;
    // While on the "signAllDocumentsAtOnce" screen, these claimant signatures must be passed to the SignaturePad component.
    public allClaimantSignatures: ClaimantSignature[] = [];
    /////////////////////////////////////////////////////////////////////////////*/
    //  END Signature
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Custom Fields
    //****************************************************************************/
    private fieldGroupConfigs: FieldGroupConfig[] = [];
    /////////////////////////////////////////////////////////////////////////////*/
    //  END Custom Fields
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Initialization
    //****************************************************************************/

    private getSignableDocumentId(signableDocument: DocumentTab): string {
        if (!signableDocument.customDocumentOrderConfigId) {
            return signableDocument.documentType;
        }
        return `${signableDocument.documentType}-${signableDocument.customDocumentOrderConfigId}`;
    }

    async ngOnInit() {
        this.user = this.loggedInUserService.getUser();
        this.subscriptions.push(this.loggedInUserService.getTeam$().subscribe((team) => (this.team = team)));

        // Available Tabs
        this.determineVisibleTabs();

        // Required additional data
        await this.loadFieldGroupConfigs();

        // Content
        await this.loadBuildingBlocksForAllDocuments();
        await this.loadSignablePdfTemplateConfigs();

        // Setup document structure
        await this.setupDocuments();

        // Display content
        this.triggerTabSwitch(this.availableTabs[0]);

        // Assemble signable documents rendering config
        {
            const responsibleAssessor: User = this.userService.getTeamMemberFromCache(this.report.responsibleAssessor);

            // Only fetch custom fee sets if necessary for rendering
            let customFeeSets: CustomFeeSet[] = [];
            if (this.report.feeCalculation.selectedFeeTable === 'custom') {
                customFeeSets = await new Promise((resolve) => {
                    this.customFeeSetService.find().subscribe({
                        next: (customFeeSets) => {
                            resolve(customFeeSets);
                        },
                        error: (error) => {
                            this.apiErrorService.handleAndRethrow({
                                axError: error,
                                handlers: {},
                                defaultHandler: {
                                    title: 'Honorartabelle nicht geladen',
                                    body: 'Die Tabelle konnte nicht geladen werden. Bitte versuche er später erneut oder kontaktiere die Hotline.',
                                },
                            });
                        },
                    });
                });
            }

            const renderedBuildingBlocks = [];
            for (const signableDocumentId of Object.keys(this.renderedDocumentBuildingBlocks)) {
                const [documentType, customDocumentOrderConfigId] = signableDocumentId.split('-');

                renderedBuildingBlocks.push(
                    ...this.renderedDocumentBuildingBlocks[signableDocumentId].map((buildingBlock) => ({
                        ...buildingBlock,
                        documentType,
                        customDocumentOrderConfigId,
                    })),
                );
            }

            const reportPlaceholderValues = await this.templatePlaceholderValuesService.getReportValues({
                reportId: this.report?._id,
            });

            this.signableDocumentsRenderingConfig = getSignableDocumentsRenderingConfig({
                report: this.report,
                responsibleAssessor,
                team: this.team,
                user: this.user,
                customFeeSets,
                renderedBuildingBlocks,
                reportPlaceholderValues,
                fieldGroupConfigs: this.fieldGroupConfigs,
                documentOrderConfigs: this.documentOrderConfigs,
                signablePdfTemplateConfigs: this.signablePdfTemplateConfigs,
            });
        }
    }

    private async loadFieldGroupConfigs() {
        this.fieldGroupConfigs = await this.fieldGroupConfigService.getAllFromInMemoryCacheAndPopulateIfNecessary();
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Initialization
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Documents Setup
    //****************************************************************************/
    /**
     * We may deal with two types of documents:
     * - PDF template
     * - document building blocks
     *
     * Depending on the type, set up:
     * - claimant signatures
     * - checkboxes
     */
    private async setupDocuments() {
        for (const signableDocument of this.report.signableDocuments) {
            let signablePdfTemplateConfig: SignablePdfTemplateConfig;

            //*****************************************************************************
            //  Existing PDF Template
            //****************************************************************************/
            if (signableDocument.pdfTemplateId) {
                // Load referenced template.
                signablePdfTemplateConfig = this.signablePdfTemplateConfigs.find(
                    (signablePdfTemplateConfig) => signablePdfTemplateConfig._id === signableDocument.pdfTemplateId,
                );

                //*****************************************************************************
                //  Referenced Template Not Found
                //****************************************************************************/
                if (!signablePdfTemplateConfig) {
                    const decision = await this.dialog
                        .open<ConfirmDialogComponent, ConfirmDialogData, boolean>(ConfirmDialogComponent, {
                            data: {
                                heading: 'PDF-Vorlage nicht gefunden',
                                content: `Die PDF-Vorlage, die du für das Dokument ${translateDocumentType(
                                    signableDocument.documentType,
                                )} ausgewählt hast, wurde nicht gefunden.\n\nDas Dokument ist damit nicht mehr druckbar.`,
                                confirmLabel: 'Verknüpfung entfernen',
                                cancelLabel: 'Beibehalten',
                            },
                        })
                        .afterClosed()
                        .toPromise();
                    if (decision) {
                        signableDocument.pdfTemplateId = null;
                    }
                }
                /////////////////////////////////////////////////////////////////////////////*/
                //  END Referenced Template Not Found
                /////////////////////////////////////////////////////////////////////////////*/

                //*****************************************************************************
                //  Check Other Templates
                //****************************************************************************/
                // If there's another matching document, offer that.
                const possiblyOtherConfig: SignablePdfTemplateConfig =
                    await this.matchSignablePdfTemplateConfig(signableDocument);
                if (possiblyOtherConfig && possiblyOtherConfig._id !== signableDocument.pdfTemplateId) {
                    // If an ID is set but deviates from the determined one, offer updating it.
                    const decision = await this.dialog
                        .open<ConfirmDialogComponent, ConfirmDialogData, boolean>(ConfirmDialogComponent, {
                            data: {
                                heading: 'PDF-Vorlage aktualisiert',
                                content: `Gemäß deiner eingestellten Bedingungen steht eine neue PDF-Vorlage für das Dokument ${translateDocumentType(
                                    signableDocument.documentType,
                                )} zur Verfügung (Titel: ${
                                    possiblyOtherConfig.title || translateDocumentType(possiblyOtherConfig.documentType)
                                }).\n\nBestehende Unterschriften werden entfernt.`,
                                confirmLabel: 'Neue Vorlage verwenden',
                                cancelLabel: 'Bisherige behalten',
                                confirmColorRed: true,
                            },
                        })
                        .afterClosed()
                        .toPromise();
                    if (decision) {
                        signableDocument.pdfTemplateId = possiblyOtherConfig._id;
                        signablePdfTemplateConfig = possiblyOtherConfig;
                        // Remove old signatures. The new ones will be set up further down.
                        signableDocument.signatures = [];
                    }
                }
                /////////////////////////////////////////////////////////////////////////////*/
                //  END Check Other Templates
                /////////////////////////////////////////////////////////////////////////////*/

                addMissingClaimantSignaturesOnPdfTemplateDocument({
                    signablePdfTemplateConfig,
                    signableDocument,
                });
            }
            /////////////////////////////////////////////////////////////////////////////*/
            //  END Existing PDF Template
            /////////////////////////////////////////////////////////////////////////////*/

            //*****************************************************************************
            //  No PDF Template
            //****************************************************************************/
            // Either a fresh document or one with document building blocks.
            if (!signableDocument.pdfTemplateId) {
                // If there are any signatures, we do not want to change the underlying signature document, so we don't try to find a PDF.
                const doSignaturesExist: boolean = !!signableDocument.signatures[0]?.hash;
                if (!doSignaturesExist) {
                    // Try to find PDF template.
                    signablePdfTemplateConfig = await this.matchSignablePdfTemplateConfig(signableDocument);

                    // Match found.
                    if (signablePdfTemplateConfig) {
                        addMissingClaimantSignaturesOnPdfTemplateDocument({
                            signablePdfTemplateConfig,
                            signableDocument,
                        });
                        signableDocument.pdfTemplateId = signablePdfTemplateConfig._id;

                        // No need to set up document building blocks if a PDF template was found.
                        continue;
                    }
                }
                /**
                 * A signable document with document building blocks has one or more signatures. Go through setup in case
                 * a second signature building block (typically below the fee set table) was added in the meantime.
                 */
                setupAllClaimantSignaturesOnBuildingBlockDocuments({
                    signableDocument,
                    buildingBlocks: this.renderedDocumentBuildingBlocks[this.getSignableDocumentId(signableDocument)],
                });
            }
            /////////////////////////////////////////////////////////////////////////////*/
            //  END No PDF Template
            /////////////////////////////////////////////////////////////////////////////*/
        }

        this.saveReport();
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Documents Setup
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Tab Visibility
    //****************************************************************************/
    /**
     * Tabs are generally visible if the user selected that the referenced document shall be signed.
     *
     * - Tab power of attorney: Only available if there's a lawyer involved in the report.
     * - Tab sign all documents at once: Only available if the according user preference is set.
     */
    private determineVisibleTabs(): void {
        this.availableTabs = [];

        // Order of the array must match the order of the icons in the view. Therefore, we cannot simply use the report's array which is sorted by order of activation.
        const documentTypesInOrderOfView: SignableDocumentType[] = [
            'declarationOfAssignment',
            'revocationInstruction',
            'consentDataProtection',
        ];
        for (const documentType of documentTypesInOrderOfView) {
            if (this.getSignableDocumentByType({ documentType })) {
                this.availableTabs.push({ documentType: documentType });
            }
        }

        /**
         * The power of attorney needs to be signed only if an attorney was added to this report.
         *
         * There is a special case in which the power of attorney is part of the array at report.documentsToBeSigned but does not
         * need to be signed:
         * - In a previous report with a power of attorney document, the assessor activated the document on the signature card in the AccidentDataComponent
         *   and saved that config to his team preferences.
         * - The assessor opens a new report into which the team preferences are merged. report.documentsToBeSigned now contains the power of attorney.
         * - The assessor does not add a lawyer to the new report, so the signature card in the AccidentDataComponent does not contain the power of attorney icon (that's correct)
         */
        if (
            this.getSignableDocumentByType({ documentType: 'powerOfAttorney' }) &&
            this.shouldPowerOfAttorneyDocumentBeVisible()
        ) {
            this.availableTabs.push({ documentType: 'powerOfAttorney' });
        }

        /**
         * Add custom signable documents to the list of available tabs.
         */
        const customSignableDocuments = this.report.signableDocuments.filter(
            (signableDocument) => signableDocument.documentType === 'customDocument',
        );

        for (const customSignableDocument of customSignableDocuments) {
            this.availableTabs.push({
                documentType: 'customDocument',
                customDocumentOrderConfigId: customSignableDocument.customDocumentOrderConfigId,
            });
        }

        if (this.team.preferences.signAllDocumentsAtOnce) {
            this.availableTabs.push({ documentType: 'signAllDocumentsAtOnce' });
        }
    }

    private getSignableDocumentByType(tab: DocumentTab): SignableDocument {
        return this.report.signableDocuments.find((signableDocument) => {
            if (signableDocument.documentType === 'customDocument') {
                return tab && tab.customDocumentOrderConfigId === signableDocument.customDocumentOrderConfigId;
            } else {
                return tab && tab.documentType === signableDocument.documentType;
            }
        });
    }

    protected doesAvailableTabsInclude(tab: DocumentTab): boolean {
        return this.availableTabs.some(
            (availableTab) =>
                availableTab.documentType === tab.documentType &&
                availableTab.customDocumentOrderConfigId === tab.customDocumentOrderConfigId,
        );
    }

    get availableCustomDocumentTabs(): Array<DocumentTab & { _id: string; icon: string; title: string }> {
        return this.availableTabs
            .filter((tab) => tab.documentType === 'customDocument')
            .map((tab) => {
                const documentOrderConfig = this.documentOrderConfigs.find(
                    (documentOrderConfig) =>
                        documentOrderConfig.type === tab.documentType &&
                        documentOrderConfig._id === tab.customDocumentOrderConfigId,
                );

                return {
                    ...tab,
                    _id: `${tab.documentType}-${tab.customDocumentOrderConfigId}`,
                    icon: documentOrderConfig?.customDocumentConfig?.googleMaterialIconName || 'description',
                    title: documentOrderConfig?.titleShort || 'Unbekanntes Dokument',
                };
            });
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Tab Visibility
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Document / Tab Selection
    //****************************************************************************/
    /**
     * Trigger the animation of switching a tab.
     * @param targetTab
     */
    public async triggerTabSwitch(targetTab: DocumentTab) {
        if (isEqualAx(this.selectedTab, targetTab)) return;

        let animationDuration = 200;
        // On initial load (when blocks are fetched), skip animation.
        if (this.buildingBlocksPending) {
            animationDuration = 0;
        }

        //*****************************************************************************
        //  PDF Template
        //****************************************************************************/
        if (this.selectedSignableDocument?.pdfTemplateId) {
            //*****************************************************************************
            //  Save Signatures
            //****************************************************************************/
            if (this.claimantSignatureDocumentsComponent) {
                await this.claimantSignatureDocumentsComponent.saveSignatures();
            }
            /////////////////////////////////////////////////////////////////////////////*/
            //  END Save Signatures
            /////////////////////////////////////////////////////////////////////////////*/

            //*****************************************************************************
            //  Checkbox: May Deduct VAT
            //****************************************************************************/
            /**
             * Checkboxes
             * If there are any checkboxes with a target property, copy the signer's response to that target property to the report.
             * Only use this setting if the user hasn't set any value on the report yet.
             */
            if (
                this.getCheckboxValueTracker('claimantMayDeductVat')?.value !=
                this.report.claimant?.contactPerson.mayDeductTaxes
            ) {
                this.report.claimant.contactPerson.mayDeductTaxes =
                    this.getCheckboxValueTracker('claimantMayDeductVat')?.value;
                this.saveReport();
            }
            /////////////////////////////////////////////////////////////////////////////*/
            //  END Checkbox: May Deduct VAT
            /////////////////////////////////////////////////////////////////////////////*/
        }
        /////////////////////////////////////////////////////////////////////////////*/
        //  END PDF Template
        /////////////////////////////////////////////////////////////////////////////*/
        //*****************************************************************************
        //  Building Block Template or Sign All Documents At Once
        //****************************************************************************/
        else {
            // Leaving any tab, save the signature to the server.
            for (const signaturePad of this.signaturePads) {
                await signaturePad.saveSignature();
            }
        }
        /////////////////////////////////////////////////////////////////////////////*/
        //  END Building Block Template or Sign All Documents At Once
        /////////////////////////////////////////////////////////////////////////////*/

        this.tabSelectionChangeOverlayShown = true;

        // Use timeout to only replace contents as soon as the overlay has finished appearing (full opacity)
        /**
         * The callback of setTimeout is executed in the main thread.
         * Therefore angular and Sentry cannot catch errors thrown in the callback.
         *
         * To notify the user and our error reporting, we catch the error using a promise and throw it again.
         */
        const awaitLoadTab = new Promise<void>((resolve, reject) => {
            setTimeout(async () => {
                try {
                    await this.showContentForTab(targetTab);
                    resolve();
                } catch (e) {
                    reject(e);
                }
            }, animationDuration);
        });

        awaitLoadTab.catch((error) => {
            this.toastService.error(
                'Fehler beim Laden des Dokuments',
                'Bitte versuche es erneut oder kontaktiere die Hotline.',
            );
            throw error;
        });

        window.setTimeout(() => (this.tabSelectionChangeOverlayShown = false), animationDuration + 150); // Allow enough extra time for the selectDocumentType method to run
    }

    public async showContentForTab(tab: DocumentTab) {
        // Already in target state.
        if (isEqualAx(this.selectedTab, tab)) return;

        this.selectedTab = tab;

        // Clear the previously selected signablePdfTemplateConfig.
        this.selectedSignablePdfTemplateConfig = null;
        this.visibleDocumentBuildingBlocks = [];

        // TODO Refactor this section into separate components taking care of the display of the different document types.
        if (tab.documentType === 'signAllDocumentsAtOnce') {
            this.visibleDocumentBuildingBlocks = [];
            this.dialogScrollableArea.nativeElement.scrollTop = 0;
            this.selectedSignableDocument = null;

            // allClaimantSignatures will be passed to the SignaturePad component, therefore update them before initializing the pad.
            const allClaimantSignatures = this.report.signableDocuments.reduce((allSignatures, signableDocument) => {
                allSignatures.push(...signableDocument.signatures);
                return allSignatures;
            }, []);

            // Sort by available tabs
            this.allClaimantSignatures = [];
            for (const tab of this.availableTabs) {
                this.allClaimantSignatures.push(
                    ...allClaimantSignatures.filter(
                        (signature) =>
                            signature.documentType === tab.documentType &&
                            signature.customDocumentOrderConfigId === tab.customDocumentOrderConfigId,
                    ),
                );
            }
        } else {
            // Select signable document.
            this.selectedSignableDocument = this.report.signableDocuments.find((signableDocument) => {
                if (signableDocument.documentType === 'customDocument') {
                    return tab.customDocumentOrderConfigId === signableDocument.customDocumentOrderConfigId;
                } else {
                    return tab && tab.documentType === signableDocument.documentType;
                }
            });

            //*****************************************************************************
            //  Placeholder Values
            //****************************************************************************/
            // Update placeholder values on every tab switch because they depend on the selected document
            // (e.g. UnterschriftKundeDatum => the date of the customer signature).
            this.placeholderValues = await this.templatePlaceholderValuesService.getReportValues({
                reportId: this.report?._id,
                letterDocument: this.report.documents.find(
                    (reportDocument) =>
                        reportDocument.type === this.selectedSignableDocument?.documentType &&
                        reportDocument.customDocumentOrderConfigId ===
                            this.selectedSignableDocument?.customDocumentOrderConfigId,
                ),
            });
            /////////////////////////////////////////////////////////////////////////////*/
            //  END Placeholder Values
            /////////////////////////////////////////////////////////////////////////////*/

            //*****************************************************************************
            //  Show content based on document type (PDF / Building Block)
            //****************************************************************************/

            /**
             * We had some TypeErrors (AX-4154, 2024-04-26) in production where the selectedSignableDocument was null and the pdfTemplateId was not accessible.
             * If this happens, the user can sign the document but the signature is lost, which is a very bad user experience.
             *
             * We currently have no real idea why this happened, but we can at least catch it here and throw a more meaningful error.
             * The errors happen only on mobile devices, maybe it is related to timing and duration of the creation of the placeholder value tree..
             */
            if (!this.selectedSignableDocument) {
                /**
                 * There could be a valid reason, where the selectedSignableDocument is null:
                 * The user moved to signAllDocumentsAtOnce before the placehoderValues were loaded. In this case, we decided to return early.
                 * If this code is refactored, these states could maybe improved using an observable.
                 */
                if (this.selectedTab?.documentType === 'signAllDocumentsAtOnce') {
                    return;
                }

                // It would be fine to notify us about this error (using Sentry) and not throw an error to the user.
                // But it is better for the user to get an error before he signs the document than that the signature is lost afterwards.
                throw new AxError({
                    code: 'SELECTED_SIGNABLE_DOCUMENT_IS_MISSING',
                    message: `Customer-Signatures-Dialog: No signable document found for document type ${tab}.`,
                    data: {
                        documentType: tab,
                        'report.signableDocuments': this.report.signableDocuments,
                    },
                });
            }

            // Reference to PDF template exists.
            if (this.selectedSignableDocument.pdfTemplateId) {
                this.selectedSignablePdfTemplateConfig = this.signablePdfTemplateConfigs.find(
                    (signablePdfTemplateConfig) =>
                        signablePdfTemplateConfig._id === this.selectedSignableDocument.pdfTemplateId,
                );
            }
            /////////////////////////////////////////////////////////////////////////////*/
            //  END Show content based on document type (PDF / Building Block)
            /////////////////////////////////////////////////////////////////////////////*/
            else {
                this.visibleDocumentBuildingBlocks =
                    this.renderedDocumentBuildingBlocks[this.getSignableDocumentId(tab)];
            }

            // Scroll up so that the customer can read a document from the top.
            this.dialogScrollableArea.nativeElement.scrollTop = 0;
        }

        // Depending on whether the canvas is visible (or hidden through *ngIf), the method isSignatureMissing() may return different values while the animation runs.
        // Detecting changes removes the Expression Has Changed error.
        this.changeDetectorRef.detectChanges();
    }

    public isLastTabSelected(): boolean {
        const lastAvailableTab = this.availableTabs[this.availableTabs.length - 1];
        return (
            this.selectedTab?.documentType === lastAvailableTab.documentType &&
            this.selectedTab?.customDocumentOrderConfigId === lastAvailableTab.customDocumentOrderConfigId
        );
    }

    /**
     * Whether the tab for the power of attorney document should be visible on the left of this dialog.
     */
    public shouldPowerOfAttorneyDocumentBeVisible(): boolean {
        return shouldPowerOfAttorneyDocumentBeVisible(this.report);
    }

    /**
     * Either
     * - open the next document or
     * - close the editor if this already is the last document.
     *
     * Having the fork logic in this method allows us to keep the template cleaner.
     */
    public async openNextDocument(): Promise<void> {
        // Both the checkbox that allows saving the signature and the signature itself must be filled to enable saving the signature.
        if (!this.isDocumentComplete()) {
            if (this.selectedSignableDocument.pdfTemplateId) {
                let toastBody: string = 'Unterschrift oder Checkboxen fehlen:\n\n';

                //*****************************************************************************
                //  Missing Checkboxes
                //****************************************************************************/
                const missingCheckboxes = this.getMissingRequiredCheckboxes();
                if (missingCheckboxes.length) {
                    toastBody += '<strong>Checkboxen</strong>\n';

                    for (const missingCheckbox of missingCheckboxes) {
                        toastBody += `• Seite ${missingCheckbox.pageNumber}\n`;
                    }
                }
                /////////////////////////////////////////////////////////////////////////////*/
                //  END Missing Checkboxes
                /////////////////////////////////////////////////////////////////////////////*/

                //*****************************************************************************
                //  Missing Signatures
                //****************************************************************************/
                const missingSignatures = this.getMissingSignaturesOnPdfTemplate();
                if (missingSignatures.length) {
                    toastBody += '<strong>Unterschriften</strong>\n';

                    for (const missingSignature of missingSignatures) {
                        toastBody += `• Seite ${missingSignature.pageNumber}\n`;
                    }
                }
                /////////////////////////////////////////////////////////////////////////////*/
                //  END Missing Signatures
                /////////////////////////////////////////////////////////////////////////////*/

                this.toastService.info('Eingaben erforderlich', toastBody);
            } else {
                this.toastService.info(
                    'Unterschrift erforderlich',
                    'Bitte lasse zuerst unterschreiben, bevor du speichern kannst.\n\nUm den Dialog zu schließen, nutze das X oben rechts.',
                );
            }
            return;
        }

        const indexOfCurrentTab = this.availableTabs.findIndex((tab) => isEqualAx(tab, this.selectedTab));
        const nextTab = this.availableTabs[indexOfCurrentTab + 1];
        if (nextTab) {
            await this.triggerTabSwitch(nextTab);
        }
        // No next tab -> Start closing, either by uploading the last signature or closing directly.
        else {
            this.savingSignaturePending = true;

            // PDF Templates
            if (this.selectedSignableDocument.pdfTemplateId) {
                await this.claimantSignatureDocumentsComponent.saveSignatures();
            }
            // Document building block templates
            else {
                for (const signaturePad of this.signaturePads) {
                    await signaturePad.saveSignature();
                }
            }

            await this.saveReport();
            this.savingSignaturePending = false;
            this.closeDialog();
        }
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Document / Tab Selection
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Text Document Content
    //****************************************************************************/
    public async loadBuildingBlocksForAllDocuments(): Promise<void> {
        this.buildingBlocksPending = true;

        // Get all building blocks.
        let documentBuildingBlocks: DocumentBuildingBlock[];
        let placeholderValues: PlaceholderValues;

        //*****************************************************************************
        //  Building Blocks
        //****************************************************************************/
        try {
            documentBuildingBlocks = await this.documentBuildingBlockService.find().toPromise();
        } catch (error) {
            this.apiErrorService.handleAndRethrow({
                axError: error,
                handlers: {},
                defaultHandler: {
                    title: `Textbausteine nicht geladen`,
                    body: `Bitte warte ein paar Minuten, bis sich alle Daten synchronisiert haben. Falls es nach 5 min bei guter Internetverbindung immer noch nicht funktioniert, kontaktiere die <a href='/Hilfe'>Hotline</a>.`,
                },
            });
        }
        /////////////////////////////////////////////////////////////////////////////*/
        //  END Building Blocks
        /////////////////////////////////////////////////////////////////////////////*/
        //*****************************************************************************
        //  Document Order Configs
        //****************************************************************************/
        try {
            this.documentOrderConfigs = await this.documentOrderConfigService.find().toPromise();
        } catch (error) {
            this.apiErrorService.handleAndRethrow({
                axError: error,
                handlers: {},
                defaultHandler: {
                    title: `Dokument-Reihenfolgen nicht geladen`,
                    body: `Bitte warte ein paar Minuten, bis sich alle Daten synchronisiert haben. Falls es nach 5 min bei guter Internetverbindung immer noch nicht funktioniert, kontaktiere die <a href='/Hilfe'>Hotline</a>.`,
                },
            });
        }
        /////////////////////////////////////////////////////////////////////////////*/
        //  END Document Order Configs
        /////////////////////////////////////////////////////////////////////////////*/
        //*****************************************************************************
        //  Placeholder Values
        //****************************************************************************/
        try {
            placeholderValues = await this.templatePlaceholderValuesService.getReportValues({
                reportId: this.report?._id,
                letterDocument: this.report.documents.find(
                    (reportDocument) =>
                        reportDocument.type === this.selectedSignableDocument?.documentType &&
                        reportDocument.customDocumentOrderConfigId ===
                            this.selectedSignableDocument?.customDocumentOrderConfigId,
                ),
            });
        } catch (error) {
            this.apiErrorService.handleAndRethrow({
                axError: error,
                handlers: {},
                defaultHandler: {
                    title: `Platzhalter-Werte nicht geladen`,
                    body: `Bitte warte ein paar Minuten, bis sich alle Daten synchronisiert haben. Falls es nach 5 min bei guter Internetverbindung immer noch nicht funktioniert, kontaktiere die <a href='/Hilfe'>Hotline</a>.`,
                },
            });
        }
        /////////////////////////////////////////////////////////////////////////////*/
        //  END Placeholder Values
        /////////////////////////////////////////////////////////////////////////////*/

        /**
         * - Download the document building block for all documents,
         * - replace its placeholders and
         * - save them to the respective variables for the view.
         */
        for (const tab of this.availableTabs) {
            const documentOrderConfig = this.documentOrderConfigs.find((documentOrderConfig) => {
                if (documentOrderConfig.type === 'customDocument') {
                    return tab.customDocumentOrderConfigId === documentOrderConfig._id;
                } else {
                    return tab.documentType === documentOrderConfig.type;
                }
            });

            if (!documentOrderConfig) continue;
            if (tab.documentType === 'signAllDocumentsAtOnce') continue;

            // Sort blocks by the order structure of the document
            const sortedBlocks = sortBuildingBlocksAccordingToTemplateConfig(
                documentBuildingBlocks,
                documentOrderConfig.documentBuildingBlockIds,
            );
            // Choose and render variants
            this.renderedDocumentBuildingBlocks[this.getSignableDocumentId(tab)] =
                chooseAndRenderDocumentBuildingBlocks({
                    documentBuildingBlocks: sortedBlocks,
                    placeholderValues,
                    fieldGroupConfigs: this.fieldGroupConfigs,
                });
        }
        this.buildingBlocksPending = false;
    }

    public async createDocumentBuildingBlockForPowerOfAttorney() {
        if (!isAdmin(this.user._id, this.team)) {
            this.toastService.warn(
                'Nur für Admins erlaubt',
                'Bitte kontaktiere deinen Administrator, um die Texte zu hinterlegen.',
            );
            return;
        }

        let attorneyBuildingBlocks: DocumentBuildingBlock[];
        try {
            attorneyBuildingBlocks = await this.documentBuildingBlockService
                .find({
                    placeholder: 'Anwaltsvollmacht',
                })
                .toPromise();
        } catch (error) {
            this.apiErrorService.handleAndRethrow({
                axError: error,
                handlers: {
                    MISSING_DOCUMENT_BUILDING_BLOCK: {
                        title: 'Textbaustein "Anwaltsvollmacht" fehlt',
                        body: "Bitte kontaktiere die <a href='https://www.autoixpert.de/Kontakt.html'>Hotline</a>.",
                    },
                },
                defaultHandler: {
                    title: `Textbaustein "Anwaltsvollmacht" nicht abrufbar`,
                    body: "Das ist ein technisches Problem. Bitte kontaktiere die <a href='https://www.autoixpert.de/Kontakt.html'>Hotline</a>.",
                },
            });
        }
        //*****************************************************************************
        //  Create Variant Matching Lawyer Organization
        //****************************************************************************/
        const buildingBlock = attorneyBuildingBlocks[0];
        if (!buildingBlock) {
            this.toastService.error(
                `Baustein für Anwaltsvollmacht fehlt`,
                "Bitte kontaktiere die <a href='/Hilfe'>Hotline</a>.",
            );
        }

        // Variant matching the organization of the selected lawyer
        const newVariant: DocumentBuildingBlockVariant = new DocumentBuildingBlockVariant({
            heading: `Anwaltsvollmacht ${this.report.lawyer.contactPerson.organization}`,
            content: '<p>Hier kann der Text des Anwalts eingefügt werden.</p>',
            conditions: [
                {
                    _id: generateId(),
                    propertyPath: 'Anspruchsteller.Anwalt.Organisation',
                    operator: 'equal',
                    comparisonValue: this.report.lawyer.contactPerson.organization,
                },
            ],
        });
        buildingBlock.variants.unshift(newVariant);
        await this.documentBuildingBlockService.put(buildingBlock);
        /////////////////////////////////////////////////////////////////////////////*/
        //  END Create Variant Matching Lawyer Organization
        /////////////////////////////////////////////////////////////////////////////*/
        if (this.selectedTab?.documentType !== 'signAllDocumentsAtOnce') {
            this.openDocumentBuildingBlocks('Anwaltsvollmacht');
        }
    }

    public openDocumentBuildingBlocks(searchQuery?: string): void {
        if (this.selectedTab?.documentType === 'signAllDocumentsAtOnce') {
            this.toastService.warn(
                'Bitte Dokument wählen',
                'Bitte wähle ein einzelnes Dokument, um dessen Text zu bearbeiten.',
            );
            return;
        }

        const queryParams = {
            documentType: this.selectedTab,
            search: searchQuery,
        };

        const queryString = Object.entries(queryParams)
            .filter((entry) => entry[1]) // Only non-empty values
            .map(([key, value]) => `${key}=${value}`)
            .join('&');

        this.newWindowService.open(`/Einstellungen/Textbausteine?${queryString || ''}`);
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Text Document Content
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Signable PDF Template Configs
    //****************************************************************************/
    private async loadSignablePdfTemplateConfigs() {
        try {
            this.signablePdfTemplateConfigs = await this.signablePdfTemplateConfigService.find().toPromise();
        } catch (error) {
            this.apiErrorService.handleAndRethrow({
                axError: error,
                handlers: {},
                defaultHandler: {
                    title: `PDF-Vorlagen nicht geladen`,
                    body: `Bitte versuche es erneut oder kontaktiere die <a href='/Hilfe'>Hotline</a>.`,
                },
            });
        }
    }

    /**
     * Signable PDF template configs are matched through
     * - document type
     * - conditions
     */
    private async matchSignablePdfTemplateConfig(
        signableDocument: SignableDocument,
    ): Promise<SignablePdfTemplateConfig> {
        this.placeholderValues ??= await this.templatePlaceholderValuesService.getReportValues({
            reportId: this.report?._id,
            letterDocument: this.report.documents.find(
                (reportDocument) =>
                    reportDocument.type === this.selectedSignableDocument?.documentType &&
                    reportDocument.customDocumentOrderConfigId ===
                        this.selectedSignableDocument?.customDocumentOrderConfigId,
            ),
        });
        this.placeholderValueTree ??= getPlaceholderValueTree({ fieldGroupConfigs: this.fieldGroupConfigs });

        const matchingConfigs: SignablePdfTemplateConfig[] = matchConditions({
            // Filter: must match tab + must not be archived.
            elementsWithConditions: this.signablePdfTemplateConfigs.filter(
                (signablePdfTemplateConfig) =>
                    // Match the type
                    signablePdfTemplateConfig.documentType === signableDocument.documentType &&
                    // A PDF template must be uploaded for the template to be considered.
                    signablePdfTemplateConfig.pdfUploaded &&
                    // Match the custom document ID if provided.
                    signablePdfTemplateConfig.customDocumentOrderConfigId ===
                        signableDocument.customDocumentOrderConfigId &&
                    // The template must not have been archived.
                    !signablePdfTemplateConfig.archivedAt,
            ),
            placeholderValues: this.placeholderValues,
            placeholderValueTree: this.placeholderValueTree,
        });

        // No matching config. The user must use the text block documents instead of PDFs.
        if (!matchingConfigs.length) return;

        // More than one config? Inform user and link to settings.
        if (matchingConfigs.length > 1) {
            this.toastService.warn(
                'Mehrere PDF-Dateien gefunden',
                `Es gibt mehrere PDF-Dateien, deren Bedingungen für das Dokument ${translateDocumentType(
                    signableDocument.documentType,
                )} zutreffen. Bitte gib in den <a href='/Einstellungen/Unterschreibbare-PDF-Dokumente'>Einstellungen</a> eindeutige Bedingungen an.\n\nEs wird die erste gefundene Datei verwendet.`,
            );
        }

        return matchingConfigs[0];
    }

    public openPdfTemplateConfig() {
        this.newWindowService.open(
            `/Einstellungen/Unterschreibbare-PDF-Dokumente?id=${this.selectedSignablePdfTemplateConfig._id}`,
        );
    }

    /**
     * Cut the connection of the PDF template to this signable document.
     *
     * - Remove PDF template ID
     * - Reload tab content
     */
    public async unlinkPdfTemplateConfig() {
        this.selectedSignableDocument.pdfTemplateId = null;

        // Correctly setup claimant signatures now that the PDF template is removed.
        this.selectedSignableDocument.signatures = [];
        setupAllClaimantSignaturesOnBuildingBlockDocuments({
            signableDocument: this.selectedSignableDocument,
            buildingBlocks: this.renderedDocumentBuildingBlocks[this.selectedSignableDocument.documentType],
        });

        this.saveReport();

        // Force reload by first clearing the selected tab. showContentForTab returns if the target tab has already been selected.
        const selectedTab = this.selectedTab;
        this.selectedTab = undefined;
        await this.showContentForTab(selectedTab);
    }

    public getCheckboxValueTracker(name: CheckboxValueTracker['name']): CheckboxValueTracker {
        return this.selectedSignableDocument?.checkboxValueTrackers.find(
            (checkboxValueTracker) => checkboxValueTracker.name === name,
        );
    }

    addPowerOfAttorney() {
        this.router.navigate(['/Einstellungen/Unterschreibbare-PDF-Dokumente']);
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Signable PDF Template Configs
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Signatures
    //****************************************************************************/
    get signaturePads(): SignaturePadComponent[] {
        return [this.signaAllAtOnceSignaturePad, ...(this.claimantSignatureDocuments?.signaturePads ?? [])].filter(
            (signaturePad) => !!signaturePad,
        );
    }

    public isDocumentSigned(signableDocumentType: DocumentTab): boolean {
        const signableDocument = this.getSignableDocumentByType(signableDocumentType);
        if (!signableDocument) return false;
        return signableDocument.signatures.some((claimantSignature) => claimantSignature.hash);
    }

    /**
     * Does this document require a signature?
     * Only usable for text block-based documents. PDF-based documents carry signature objects describing if a signature is required.
     */
    public isSignaturePadVisible(): boolean {
        /**
         * When the individual documents are not yet signed and the assessor has set the preference to sign all documents at once,
         * do not show the signature pad for individual documents. That prevents the assessor's customer from trying to sign individual documents.
         *
         * If at least one signature was set, display the signature pad. That makes signed documents look complete and allows seeing missing signatures.
         */
        if (this.team.preferences.signAllDocumentsAtOnce) {
            return (
                this.selectedTab?.documentType === 'signAllDocumentsAtOnce' || this.isDocumentSigned(this.selectedTab)
            );
        }
        // Otherwise, every tab requires a signature.
        else {
            return true;
        }
    }

    /**
     * Allow progressing to the next document if either:
     * - there is no signature required
     * - the signature is already provided
     */
    public isSignatureMissing(): boolean {
        if (!this.isSignaturePadVisible()) return false;

        // Signature is required, but the canvas is missing -> signature is missing.
        return this.signaturePads.some((signaturePad) => signaturePad.isEmpty());
    }

    /**
     * The signaturePad may be disabled.
     * - Report is locked:
     */
    public getSignaturePadDisabledReason(): SignaturePadComponent['disabledReason'] {
        if (isReportLocked(this.report)) {
            return 'reportLocked';
        }
        // Disable all signature pads except the one on the sign all screen.
        if (
            this.doesAvailableTabsInclude({ documentType: 'signAllDocumentsAtOnce' }) &&
            this.selectedTab?.documentType !== 'signAllDocumentsAtOnce'
        ) {
            return 'signAllAtOnceActive';
        }
    }

    public async downloadSignatureFiles() {
        const signaturePads = this.signaturePads.filter((signaturePad) => !signaturePad.isEmpty());

        if (signaturePads.length === 0) {
            this.toastService.info(
                'Keine Unterschriften',
                'Auf dem Dokument sind bisher keine Unterschriften vorhanden.',
            );
            return;
        }

        const fileNameWithoutSuffix = `Unterschrift_${this.getFullNameOfCurrentSigner()}`;

        /**
         * Download single signature as png file
         */
        if (signaturePads.length === 1) {
            const signaturePad = signaturePads[0];
            const fileName = `${fileNameWithoutSuffix}.png`;
            this.downloadService.downloadFile(signaturePad.getSignatureBlob(), fileName);

            this.toastService.success(
                'Unterschrift heruntergeladen',
                `Die Unterschrift von ${this.getFullNameOfCurrentSigner()} wurde als Bild-Datei heruntergeladen.`,
            );
            return;
        }

        /**
         * Download multiple signatures as zip-file
         * Safari on iOS does not support downloading multiple files at once.
         */
        const signaturesZip = new JSZip();

        for (let i = 0; i < signaturePads.length; i++) {
            const signaturePad = signaturePads[i];

            // Add a number to the file name if there are multiple signature pads.
            let fileName = fileNameWithoutSuffix;
            fileName += `_${i + 1}.png`;

            signaturesZip.file(fileName, signaturePad.getSignatureBlob());
        }

        const signaturesZipFile = await signaturesZip.generateAsync({ type: 'blob' });
        this.downloadService.downloadFile(signaturesZipFile, `${fileNameWithoutSuffix}.zip`);

        this.toastService.success(
            'Unterschriften heruntergeladen',
            `Die ${signaturePads.length} Unterschriften von ${this.getFullNameOfCurrentSigner()} wurden in einer gemeinsamen ZIP-Datei heruntergeladen.`,
        );
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Signatures
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Document Completion Checks
    //****************************************************************************/
    /**
     * PDF-based documents:
     * - All signatures given?
     * - All required checkboxes answered?
     *
     * Text block documents:
     * - Is a signature required? If so, is it there?
     */
    public isDocumentComplete(): boolean {
        // PDF-based documents
        if (this.selectedSignableDocument?.pdfTemplateId) {
            // The config may not be present as long as the user still has to confirm
            // the dialog if there's a newer PDF template available.
            if (!this.selectedSignablePdfTemplateConfig) return false;

            // The child component may initialize later than this method is called the first time.
            if (!this.claimantSignatureDocumentsComponent) return false;

            if (this.isSignaturePadVisible()) {
                return !this.getMissingRequiredCheckboxes().length && !this.getMissingSignaturesOnPdfTemplate().length;
            } else {
                return !this.getMissingRequiredCheckboxes().length;
            }
        }
        // Text block-based documents
        else {
            return !this.isSignatureMissing();
        }
    }

    /**
     * Find the required checkboxes that do not have a value.
     */
    private getMissingRequiredCheckboxes(): CheckboxElement[] {
        const allCheckboxes: CheckboxElement[] = getAllCheckboxElementsFromSignablePdfTemplateConfig(
            this.selectedSignablePdfTemplateConfig,
        );
        const missingCheckboxes: CheckboxElement[] = [];

        for (const requiredCheckboxTargetPropertyOrPairOrId of this.selectedSignablePdfTemplateConfig
            .requiredCheckboxes) {
            const checkbox = allCheckboxes.find(
                (checkbox) =>
                    checkbox.targetProperty === requiredCheckboxTargetPropertyOrPairOrId ||
                    checkbox.checkboxPairId === requiredCheckboxTargetPropertyOrPairOrId ||
                    checkbox._id === requiredCheckboxTargetPropertyOrPairOrId,
            );

            // Checkbox doesn't exist anymore -> continue.
            if (!checkbox) continue;

            const checkboxValueTracker = this.getCheckboxValueTracker(requiredCheckboxTargetPropertyOrPairOrId);
            if (checkboxValueTracker?.value == null) {
                missingCheckboxes.push(checkbox);
            }
        }

        return missingCheckboxes;
    }

    /**
     * On the currently visible PDF template, which signatures are missing?
     */
    private getMissingSignaturesOnPdfTemplate(): SignatureElement[] {
        const signatureElements = getAllSignatureElementsFromSignablePdfTemplateConfig(
            this.selectedSignablePdfTemplateConfig,
        );

        const filledSignaturePads = this.claimantSignatureDocumentsComponent?.getFilledSignaturePads() ?? [];

        // Which signatureElement does not have a corresponding filled-out signaturePad?
        return signatureElements.filter((signatureElement) => {
            return !filledSignaturePads.find(
                (signaturePadComponent) =>
                    signaturePadComponent.claimantSignatures[0].signatureSlotId === signatureElement._id,
            );
        });
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Document Completion Checks
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Signer Name
    //****************************************************************************/
    /**
     * Either the claimant or his representative signs.
     *
     * The representative is prepended in its own line before the claimant.
     */
    public getSignerName(): string {
        const signerLines: string[] = [];

        // Representative
        if (!this.report.orderPlacedByClaimant && this.report.actualCustomer) {
            signerLines.push(`i. A. ${this.report.actualCustomer}`);
        }

        // Claimant
        signerLines.push(this.getFullNameOfCurrentSigner());

        return signerLines.join('\n');
    }

    /**
     * Get the claimant's name or a fallback if no name is available.
     */
    public getFullNameOfCurrentSigner(): string {
        if (this.report.claimant.contactPerson.firstName || this.report.claimant.contactPerson.lastName) {
            return (
                this.report.claimant.contactPerson.firstName +
                ' ' +
                this.report.claimant.contactPerson.lastName
            ).trim();
        } else {
            return 'Anspruchsteller';
        }
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Signer Name
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Sign All Documents At Once
    //****************************************************************************/
    public async setPreferenceToSignDocumentsAtOnce(value: boolean): Promise<void> {
        if (!hasAccessRight(this.user, 'editTextsAndDocumentBuildingBlocks')) {
            // As this option is very likely to be used on touch devices (where the tooltip is not shown on hover),
            // let's make it very clear to the user why the button is disabled.
            this.toastService.error(
                'Zugriffsrecht fehlt',
                `Diese Einstellung kann nur mit dem Zugriffsrecht ${translateAccessRightToGerman(
                    'editTextsAndDocumentBuildingBlocks',
                )} vorgenommen werden. Bitte kontaktiere deinen Admin.`,
            );

            return;
        }

        this.team.preferences.signAllDocumentsAtOnce = value;

        this.determineVisibleTabs();

        // If it was possible to select the signAll tab before, the user must have turned the setting off.
        if (this.selectedTab?.documentType === 'signAllDocumentsAtOnce') {
            this.triggerTabSwitch(this.availableTabs[0]);
        }

        try {
            await this.teamService.put(this.team);
        } catch (error) {
            this.apiErrorService.handleAndRethrow({
                axError: error,
                handlers: {},
                defaultHandler: {
                    title: 'Einstellung nicht gespeichert',
                    body: "Es konnte nicht gespeichert werden, ob Dokumente einzeln oder gemeinsam unterschrieben werden sollen. Bitte kontaktiere die <a href='/Hilfe'>Hotline</a>.",
                },
            });
        }
    }

    /**
     * Upload the same signature to each document to be signed.
     */
    public async uploadSignatureToAllDocuments(): Promise<void> {
        if (this.isSignatureMissing()) {
            this.toastService.warn('Bitte erst unterschreiben', 'Danach kannst du den Dialog abschließen.');
            return;
        }

        this.savingSignaturePending = true;

        let numberOfUploadedSignatures: number = 0;
        for (const signaturePad of this.signaturePads) {
            numberOfUploadedSignatures += (await signaturePad.saveSignature())?.length;
        }

        this.savingSignaturePending = false;

        if (numberOfUploadedSignatures > 0) {
            this.toastService.success(
                'Dokumente unterschrieben',
                'Für alle ausgewählten Dokumente wurde die Unterschrift hinterlegt.',
            );
            this.saveReport();
        }
        this.closeDialog();
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Sign All Documents At Once
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Server Communication
    //****************************************************************************/
    public saveReport({ waitForServer }: { waitForServer?: boolean } = {}): Promise<Report> {
        try {
            return this.reportDetailsService.patch(this.report, { waitForServer });
        } catch (error) {
            this.apiErrorService.handleAndRethrow({
                axError: error,
                handlers: {},
                defaultHandler: {
                    title: 'Fehler beim Sync',
                    body: 'Das Gutachten konnte nicht gespeichert werden. Wenn dieser Fehler erneut auftritt, wende dich bitte an die <a href="/Hilfe" target="_blank">Hotline</a>.',
                },
            });
        }
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Server Communication
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  View Handlers
    //****************************************************************************/
    public translateDocumentType(documentType: DocumentTypes): string {
        return translateDocumentType(documentType);
    }

    public userIsAdmin(): boolean {
        return isAdmin(this.user._id, this.team);
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END View Handlers
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Keyboard Shortcuts
    //****************************************************************************/
    @HostListener('window:keydown', ['$event'])
    private ctrlEnterEventListener(event: KeyboardEvent) {
        if (event.key === 'Escape') {
            this.closeDialog();
        }
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Keyboard Shortcuts
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Visibility Change
    //****************************************************************************/
    // When the user comes back to this tab, e.g. after editing building blocks, reload the building blocks
    @HostListener('window:visibilitychange', ['$event'])
    private reloadBuildingBlocksOnVisibilityChange(): void {
        this.loadBuildingBlocksForAllDocuments();
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Visibility Change
    /////////////////////////////////////////////////////////////////////////////*/

    public handleOverlayClick(event: MouseEvent) {
        if (event.target === event.currentTarget) {
            this.closeDialog();
        }
    }

    public closeDialog(): void {
        this.close.emit();
    }

    ngOnDestroy() {
        for (const subscription of this.subscriptions) {
            subscription.unsubscribe();
        }
    }

    protected readonly hasAccessRight = hasAccessRight;
    protected readonly getMissingAccessRightTooltip = getMissingAccessRightTooltip;
}

type LoadedDocumentBuildingBlocks = {
    [key: string]: RenderedDocumentBuildingBlock[];
};

interface DocumentTab {
    documentType: SignableDocumentType | 'signAllDocumentsAtOnce';
    customDocumentOrderConfigId?: string;
}

import { CdkDragDrop } from '@angular/cdk/drag-drop';
import { Component, EventEmitter, HostListener, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { Subscription } from 'rxjs';
import { dialogEnterAndLeaveAnimation } from '@autoixpert/animations/dialog-enter-and-leave.animation';
import { placeholderDetectionRegexString } from '@autoixpert/lib/document-building-blocks/get-available-placeholders';
import { replacePlaceholders } from '@autoixpert/lib/documents/replace-document-building-block-placeholders';
import { sanitizeHtmlExceptStyle } from '@autoixpert/lib/html/sanitize-html-except-style';
import { FieldConfig } from '@autoixpert/models/custom-fields/field-config';
import { FieldGroupConfig } from '@autoixpert/models/custom-fields/field-group-config';
import { DocumentMetadata } from '@autoixpert/models/documents/document-metadata';
import { Report } from '@autoixpert/models/reports/report';
import { Team } from '@autoixpert/models/teams/team';
import { TeamPreferences } from '@autoixpert/models/teams/team-preferences';
import { TextTemplate, TextTemplateType } from '@autoixpert/models/text-templates/text-template';
import { UserPreferences } from '@autoixpert/models/user/preferences/user-preferences';
import { User } from '@autoixpert/models/user/user';
import { getMissingAccessRightTooltip } from '../../libraries/get-missing-access-right-tooltip';
import { isSmallScreen } from '../../libraries/is-small-screen';
import { FieldGroupConfigService } from '../../services/field-group-config.service';
import { LoggedInUserService } from '../../services/logged-in-user.service';
import { TeamService } from '../../services/team.service';
import { TemplatePlaceholderValuesService } from '../../services/template-placeholder-values.service';
import { TextTemplateService } from '../../services/textTemplate.service';
import { ToastService } from '../../services/toast.service';
import { UserPreferencesService } from '../../services/user-preferences.service';
import { MatQuillComponent } from '../mat-quill/mat-quill.component';

/**
 * Editor and list of multi-line templates.
 *
 * Allows inserting text into textareas such as damage description, emails and the like.
 */
@Component({
    selector: 'text-template-selector',
    templateUrl: 'text-template-selector.component.html',
    styleUrls: ['text-template-selector.component.scss'],
    animations: [dialogEnterAndLeaveAnimation()],
})
export class TextTemplateSelectorComponent implements OnInit, OnDestroy {
    constructor(
        private loggedInUserService: LoggedInUserService,
        private userPreferences: UserPreferencesService,
        private teamService: TeamService,
        private textTemplateService: TextTemplateService,
        private toastService: ToastService,
        private domSanitizer: DomSanitizer,
        private fieldGroupConfigService: FieldGroupConfigService,
        private templatePlaceholderValuesService: TemplatePlaceholderValuesService,
    ) {}

    public user: User;
    public team: Team;
    public textTemplates: TextTemplate[] = [];
    public textTemplatesFiltered: TextTemplate[] = [];
    public textTemplateInEditMode: TextTemplate;
    public searchTerm: string;

    @Input() text: string;
    @Output() textChange: EventEmitter<string> = new EventEmitter();

    // The headline above the result input
    @Input() placeholderForResultingText: string;

    @Output() close: EventEmitter<boolean> = new EventEmitter();
    @Input() associatedUserPreference: keyof UserPreferences;
    @Input() associatedTeamPreference: keyof TeamPreferences;
    @Input() textTemplateType: TextTemplateType; // repairedPreviousDamage | unrepairedPreviousDamage
    // Pass a DocumentMetadata object if you want the placeholder values of the report/invoice to be enriched with
    // additional values based on this documentMetadata. This is useful if you're using this selector to generate
    // text for letters or an expert statement.
    @Input() letterDocument: DocumentMetadata;
    @Input() report: Report;
    @Input() customFieldConfigId: FieldConfig['_id'];

    @ViewChild('resultQuill') resultQuill: MatQuillComponent;

    public placeholderValuesForCurrentReport;
    private fieldGroupConfigs: FieldGroupConfig[];

    private subscriptions: Subscription[] = [];

    //*****************************************************************************
    //  Initialization
    //****************************************************************************/
    async ngOnInit() {
        this.user = this.loggedInUserService.getUser();
        this.team = this.loggedInUserService.getTeam();

        this.loadTextTemplates();

        void this.getLatestPlaceholderValues();

        this.fieldGroupConfigs = await this.fieldGroupConfigService.getAllFromInMemoryCacheAndPopulateIfNecessary();
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Initialization
    /////////////////////////////////////////////////////////////////////////////*/

    public acceptText(text: string): void {
        this.textChange.emit(text);
        /**
         * Allow the editor to close.
         * @type {boolean}
         */
        this.closeTextTemplateSelector();
    }

    public closeTextTemplateSelector() {
        this.close.emit(true);
    }

    /**
     * Insert text at the caret position or at the end if a caret isn't set.
     * @param textTemplate
     */
    public insertTextTemplate(textTemplate: TextTemplate) {
        // Don't insert when selecting text within the rich text editor.
        if (this.textTemplateInEditMode === textTemplate) return;

        let templateText = replacePlaceholders({
            textWithPlaceholders: textTemplate.body || '',
            placeholderValues: this.placeholderValuesForCurrentReport,
            fieldGroupConfigs: this.fieldGroupConfigs,
        });

        if (!this.text) {
            /**
             * If a text template has an empty paragraph at the beginning, remove it if this is the first text template that's inserted.
             */
            if (templateText && templateText.substring(0, '<p><br></p>'.length) === '<p><br></p>') {
                templateText = templateText.substring('<p><br></p>'.length);
            }

            this.resultQuill.quillInstance.setContents(
                this.resultQuill.quillInstance.clipboard.convert(
                    sanitizeHtmlExceptStyle(templateText, this.domSanitizer),
                ),
            );
            // Set cursor to the end of the field so that the user may continue typing.
            this.resultQuill.quillInstance.setSelection(
                {
                    index: this.resultQuill.quillInstance.getLength(),
                    length: 0,
                },
                'user',
            );
        } else {
            /**
             * The caret is initialized at the end if the user has not explicitly clicked into the input.
             */
            if (!this.resultQuill.quillInstance.getSelection()) {
                this.resultQuill.quillInstance.setSelection(
                    {
                        index: this.resultQuill.quillInstance.getLength(),
                        length: 0,
                    },
                    'user',
                );
            }
            /**
             * If the user selected multiple characters, those should be replaced. We remove them and add the new text at the caretStart.
             * If the selectionStart and end are equal to each other but not at the last position, we can use the same algorithm.
             */
            let currentCursorPosition = this.resultQuill.quillInstance.getSelection().index;

            /**
             * If a text template has an empty paragraph at the beginning, remove it if this is the first text template that's inserted.
             */
            if (templateText && templateText.substring(0, '<p><br></p>'.length) === '<p><br></p>') {
                this.resultQuill.quillInstance.insertText(currentCursorPosition, '\n');
                currentCursorPosition++;
                templateText = templateText.substring('<p><br></p>'.length);
            } else {
                /**
                 * If the last character is not a space, add a space. Otherwise, the text template's first word would be added to the former result's last word
                 * without a space --> grammatical error, duh.
                 *
                 * It's important that this space is not added as the last character in a paragraph, e.g. due to a line break. Otherwise HTML code is produced
                 * that will be shortened during MatQuillComponent's > set value() > sanitizeHtmlExceptStyle(value, this.domSanitizer);
                 * Example HTML code that will be shortened: "<p>Test </p><p>New template that was added with a line break</p>" <-- The space before the first closing paragraph will be removed.
                 * Since HTML code will be removed, Quill's internal selection index gets confused an Quill sets the cursor to position 0.
                 */
                const characterBeforeInsertion = this.resultQuill.quillInstance.getText(currentCursorPosition - 1, 1);
                if (characterBeforeInsertion && !characterBeforeInsertion.match(/\s/)) {
                    this.resultQuill.quillInstance.insertText(currentCursorPosition, ' ');
                    currentCursorPosition++;
                }
            }

            const sanitizedHtml = sanitizeHtmlExceptStyle(templateText, this.domSanitizer);
            this.resultQuill.quillInstance.clipboard.dangerouslyPasteHTML(currentCursorPosition, sanitizedHtml, 'user');
        }
        this.textChange.emit(this.text);
    }

    //*****************************************************************************
    //  Filter & Sort
    //****************************************************************************/
    public filterAndSortTextTemplates(): void {
        this.sortByDragOrder();
        this.applySearchFilter();
    }

    public applySearchFilter() {
        this.textTemplatesFiltered = [...this.textTemplates];

        // If the search string is empty, abort search and reset the list.
        if (!this.searchTerm) {
            return;
        }

        const searchWords = this.searchTerm.toLowerCase().split(' ');

        /**
         * Filter the textTemplates for each of the search words (separated by comma).
         * Look for the search words in the properties
         * - ID
         * - Heading
         * - Body
         */
        this.textTemplatesFiltered = this.textTemplatesFiltered.filter((textTemplate) => {
            // This variable will only be set to false (see below) if any of the searchWords don't match.
            let allWordsMatched = true;
            searchWords.forEach((searchWord) => {
                if (
                    (textTemplate.idSetByUser + '').toLowerCase().indexOf(searchWord) > -1 ||
                    (textTemplate.title || '').toLowerCase().indexOf(searchWord) > -1 ||
                    (textTemplate.body || '').toLowerCase().indexOf(searchWord) > -1
                ) {
                    // If the search word is matched, proceed. Otherwise set the allWordsMathed flag to false and abort.
                } else {
                    allWordsMatched = false;
                    return;
                }
            });
            return allWordsMatched;
        });
    }

    private sortByDragOrder(): void {
        this.textTemplates.sort((templateA, templateB) => {
            return +templateA.dragOrderPosition - +templateB.dragOrderPosition;
        });
    }

    public sortByTitle(): void {
        if (!this.user?.accessRights.editTextsAndDocumentBuildingBlocks) {
            this.showMissingAccessRightNotification();
            return;
        }

        this.textTemplates.sort((templateA, templateB) => {
            return (templateA.title ?? '').localeCompare(templateB.title ?? '');
        });
        // Save order to server
        this.updateDragOrderPositionOnAllTemplates();

        this.filterAndSortTextTemplates();
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Filter & Sort
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Manage text templates
    //****************************************************************************/

    public loadTextTemplates() {
        const query: Partial<TextTemplate> = {
            type: this.textTemplateType,
        };

        // Attach custom field ID
        if (this.customFieldConfigId) {
            query.customFieldConfigId = this.customFieldConfigId;
        }

        this.textTemplateService.find(query).subscribe({
            next: (textTemplates: TextTemplate[]) => {
                this.textTemplates = textTemplates;

                // Set up the initial value of the filtered array of text templates.
                this.filterAndSortTextTemplates();
            },
        });
    }

    protected showMissingAccessRightNotification(): void {
        this.toastService.warn(
            'Fehlende Berechtigung',
            'Dir fehlt die Berechtigung Textvorlagen zu bearbeiten, sie anzulegen oder sie zu löschen. Dein Admin muss dich dafür freischalten.',
        );
        return;
    }

    public addTextTemplate(): void {
        if (!this.user?.accessRights.editTextsAndDocumentBuildingBlocks) {
            this.showMissingAccessRightNotification();
            return;
        }

        const newTextTemplate = new TextTemplate({
            createdBy: this.user._id,
            teamId: this.user.teamId,
            type: this.textTemplateType,
            customFieldConfigId: this.customFieldConfigId,
        });

        this.startEditMode(newTextTemplate);

        this.textTemplates.unshift(newTextTemplate);
        this.applySearchFilter();

        this.textTemplateService.create(newTextTemplate);
    }

    public startEditMode(textTemplate: TextTemplate): void {
        // Leave edit mode of all other templates
        if (this.textTemplateInEditMode) {
            this.leaveEditMode(this.textTemplateInEditMode);
            this.saveTextTemplate(this.textTemplateInEditMode);
        }
        // Enter edit mode
        this.textTemplateInEditMode = textTemplate;
    }

    public saveTextTemplate(textTemplate: TextTemplate): void {
        // Don't save empty templates
        if (!textTemplate.title && !textTemplate.body) return;

        this.textTemplateService.put(textTemplate);
    }

    public leaveEditMode(textTemplate: TextTemplate): void {
        this.textTemplateInEditMode = null;
        // Remove empty templates
        if (!textTemplate.title && !textTemplate.body) {
            this.deleteTextTemplate(textTemplate);
        }
    }

    public leaveEditModeOnShortcut(currentTextTemplate: TextTemplate, keyboardEvent: KeyboardEvent): void {
        if (keyboardEvent.key === 'Escape') {
            this.leaveEditMode(currentTextTemplate);
        }
        if (keyboardEvent.key === 'Enter' && (keyboardEvent.ctrlKey || keyboardEvent.metaKey)) {
            this.saveTextTemplate(currentTextTemplate);
            this.leaveEditMode(currentTextTemplate);
        }
    }

    public deleteTextTemplate(textTemplate: TextTemplate): void {
        const index: number = this.textTemplates.indexOf(textTemplate);
        this.textTemplates.splice(index, 1);
        this.applySearchFilter();

        this.textTemplateService.delete(textTemplate._id);

        // Don't warn the user about having deleted an empty text block
        if (!textTemplate.title && !textTemplate.body) return;

        const restorationToast = this.toastService.info('Textvorlage gelöscht', 'Zum Wiederherstellen hier klicken.', {
            showProgressBar: true,
            timeOut: 10000,
        }); //RestorationToast
        this.subscriptions.push(
            restorationToast.click.subscribe(() => {
                this.restoreTextTemplate(textTemplate, index);
            }),
        );
    }

    private restoreTextTemplate(textTemplate: TextTemplate, index: number): void {
        // Re-create on server
        this.textTemplateService.create(textTemplate);
        this.toastService.success('Vorlage wiederhergestellt');

        // Re-create locally
        this.textTemplates.splice(index, 0, textTemplate);
        this.applySearchFilter();
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Manage text templates
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Drag & Drop
    //****************************************************************************/
    public handleReorderingDrop(event: CdkDragDrop<TextTemplate>): void {
        // Extract from the array...
        const textTemplate = this.textTemplates.splice(event.previousIndex, 1)[0];
        // ... and insert at new position.
        this.textTemplates.splice(event.currentIndex, 0, textTemplate);

        // Save order to server
        this.updateDragOrderPositionOnAllTemplates();

        this.filterAndSortTextTemplates();
    }

    private updateDragOrderPositionOnAllTemplates(): void {
        for (const [key, messageTemplate] of this.textTemplates.entries()) {
            const oldPosition: number = messageTemplate.dragOrderPosition;
            messageTemplate.dragOrderPosition = key;

            // Send updates to server
            if (messageTemplate.dragOrderPosition !== oldPosition) {
                this.saveTextTemplate(messageTemplate);
            }
        }
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Drag & Drop
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Replace Placeholders
    //****************************************************************************/
    private async getLatestPlaceholderValues(): Promise<void> {
        this.placeholderValuesForCurrentReport = await this.templatePlaceholderValuesService.getReportValues({
            reportId: this.report?._id,
            letterDocument: this.letterDocument,
        });
    }

    public textTemplateContainsPlaceholders(textTemplate: TextTemplate): boolean {
        return new RegExp(placeholderDetectionRegexString).test(textTemplate.body);
    }

    public replacePlaceholders(textTemplate: TextTemplate): string {
        return replacePlaceholders({
            textWithPlaceholders: textTemplate.body,
            placeholderValues: this.placeholderValuesForCurrentReport,
            fieldGroupConfigs: this.fieldGroupConfigs,
        });
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Replace Placeholders
    /////////////////////////////////////////////////////////////////////////////*/

    /**
     * Set the current content of the text field as the standard text for this user.
     * @param text
     */
    public async setStandardText(text: string) {
        if (this.associatedUserPreference) {
            (this.userPreferences[this.associatedUserPreference] as any) = text;
        } else if (this.associatedTeamPreference) {
            (this.team.preferences[this.associatedTeamPreference] as any) = text;
            await this.teamService.put(this.team);
        }

        this.toastService.success('Standard gesetzt');
    }

    //*****************************************************************************
    //  Dialog Controls
    //****************************************************************************/
    /**
     * Close the entire windows when the user clicks outside of it on the black, partially transparent overlay.
     * @param event
     */
    public closeSelectorWithClickOnOverlay(event) {
        if (event.target === event.currentTarget) {
            this.closeTextTemplateSelector();
        }
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Dialog Controls
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Responsive Layout
    //****************************************************************************/
    public isSmallScreen = isSmallScreen;
    /////////////////////////////////////////////////////////////////////////////*/
    //  END Responsive Layout
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Keyboard Shortcuts
    //****************************************************************************/
    @HostListener('window:keydown', ['$event'])
    public handleKeyboardShortcuts(event) {
        switch (event.key) {
            case 'Escape':
                // Make sure saving is triggered if the user is still inside an input.
                if (document.activeElement.nodeName === 'INPUT' || document.activeElement.nodeName === 'TEXTAREA') {
                    (document.activeElement as HTMLElement).blur();
                }
                this.close.emit(true);
                break;
        }
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Keyboard Shortcuts
    /////////////////////////////////////////////////////////////////////////////*/

    /**
     * When the user double clicks on a text template row, the text area (to the right)
     * loses focus. This breaks inserting the selected text at the current cursor position.
     * This function prevents this behavior by calling preventDefault on mousedown.
     */
    protected preventLosingFocus(event: MouseEvent): void {
        event.preventDefault();
    }

    ngOnDestroy() {
        this.subscriptions.forEach((subscription) => subscription.unsubscribe());
    }

    protected readonly getMissingAccessRightTooltip = getMissingAccessRightTooltip;
}

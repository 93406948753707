import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { SwUpdate } from '@angular/service-worker';
import { TextTemplate } from '@autoixpert/models/text-templates/text-template';
import { LiveSyncWithInMemoryCacheServiceBase } from '../libraries/database/live-sync-with-in-memory-cache.service-base';
import { keysOfQuickSearchTextTemplates } from '../libraries/text-templates/keys-of-quick-search-text-templates';
import { FeathersSocketioService } from './feathers-socketio.service';
import { FrontendLogService } from './frontend-log.service';
import { NetworkStatusService } from './network-status.service';
import { SyncIssueNotificationService } from './sync-issue-notification.service';

@Injectable()
export class TextTemplateService extends LiveSyncWithInMemoryCacheServiceBase<TextTemplate> {
    constructor(
        protected httpClient: HttpClient,
        protected networkStatusService: NetworkStatusService,
        protected feathersSocketioService: FeathersSocketioService,
        protected frontendLogService: FrontendLogService,
        protected syncIssueNotificationService: SyncIssueNotificationService,
        protected serviceWorker: SwUpdate,
    ) {
        super({
            serviceName: 'textTemplate',
            httpClient,
            networkStatusService,
            feathersSocketioClient: feathersSocketioService,
            syncIssueNotificationService,
            serviceWorker,
            frontendLogService,
            objectStoreAndIndexMigrations: undefined,
            recordMigrations: [],
            keysOfQuickSearchRecords: keysOfQuickSearchTextTemplates,
        });
    }
}

import { Component, EventEmitter, HostListener, Input, Output } from '@angular/core';
import { AccountStatus } from '@autoixpert/models/teams/account-status';
import { Team } from '@autoixpert/models/teams/team';
import { User } from '@autoixpert/models/user/user';
import { UserRegistration } from '@autoixpert/models/user/user-registration/user-registration';
import { fadeInAndOutAnimation } from 'src/app/shared/animations/fade-in-and-out.animation';
import { ApiErrorService } from '../../shared/services/api-error.service';
import { LoggedInUserService } from '../../shared/services/logged-in-user.service';
import { ToastService } from '../../shared/services/toast.service';
import { UserService } from '../../shared/services/user.service';

@Component({
    selector: 'team-member-invitation-dialog',
    templateUrl: 'team-member-invitation-dialog.component.html',
    styleUrls: ['team-member-invitation-dialog.component.scss'],
    animations: [fadeInAndOutAnimation()],
})
export class TeamMemberInvitationDialog {
    constructor(
        private userService: UserService,
        private loggedInUserService: LoggedInUserService,
        private toastService: ToastService,
        private apiErrorService: ApiErrorService,
    ) {}

    private user: User;
    public team: Team;
    private teamId: string;
    private accountStatus: AccountStatus;
    public userRegistrations: UserRegistration[] = [];
    public invitingUserRegistration: UserRegistration;
    public email: string;
    public userInvitationPending: boolean;

    // temporary user
    public isTemporaryUser: boolean = false;
    public deactivationDate: string = null;

    @Input() teamMemberToReactivate: User = null;
    @Output() invitationSent: EventEmitter<UserRegistration> = new EventEmitter();
    @Output() close: EventEmitter<void> = new EventEmitter();

    ngOnInit() {
        this.user = this.loggedInUserService.getUser();
        this.team = this.loggedInUserService.getTeam();
        this.teamId = this.user.teamId;
        this.accountStatus = this.team.accountStatus;

        if (!this.teamMemberToReactivate) {
            this.fetchUserRegistrations();
        }
    }

    public fetchUserRegistrations(): void {
        this.userService.fetchUserRegistrations(this.teamId).subscribe({
            next: (userRegistrations) => {
                this.userRegistrations = userRegistrations;

                this.invitingUserRegistration = this.userRegistrations.find(
                    (registration) => registration.contactPerson.email === this.user.email,
                );

                // If no user registrations matches (e.g. email address changed by aX-Team) use the first one.
                if (!this.invitingUserRegistration) {
                    this.invitingUserRegistration = this.userRegistrations[0];
                }
            },
            error: (error) => {
                this.apiErrorService.handleAndRethrow({
                    axError: error,
                    handlers: {},
                    defaultHandler: {
                        title: 'Nutzer-Registrierung nicht gefunden',
                        body: 'Bitte kontaktiere die Hotline.',
                    },
                });
            },
        });
    }

    /**
     * Reactivates the user.
     */
    public async reactivateTeamMember() {
        try {
            this.teamMemberToReactivate.deactivateAt = this.deactivationDate ?? null;
            this.userInvitationPending = true;
            await this.userService.orderUser(this.team, this.teamMemberToReactivate);
            this.userInvitationPending = false;
            this.toastService.success('Nutzer aktiviert', 'Der Nutzer kann sich wieder einloggen.');
            this.emitCloseEvent();
        } catch (error) {
            this.apiErrorService.handleAndRethrow({
                axError: error,
                handlers: {},
                defaultHandler: {
                    title: 'Nutzer nicht aktiviert',
                    body: 'Der Nutzer konnte nicht aktiviert werden. Bitte kontaktiere die Hotline.',
                },
            });
        }
    }

    /**
     * Sends an invitation email and adds the user to the registration backend.
     */
    public async sendInvitation() {
        if (this.teamMemberToReactivate) {
            return this.reactivateTeamMember();
        }

        if (!this.isEmailValid()) return;

        if (!this.invitingUserRegistration) {
            this.toastService.error('Es existiert keine Registrierung', 'Bitte kontaktiere die Hotline.');
            return;
        }

        if (!this.isTemporaryUser) {
            this.deactivationDate = null;
        }

        this.userInvitationPending = true;

        this.userService
            .invite(this.teamId, this.email, this.invitingUserRegistration._id, this.deactivationDate)
            .subscribe({
                next: (userRegistration) => {
                    this.userInvitationPending = false;
                    this.toastService.success(
                        'Einladung versendet',
                        'Der Eingeladene kann sich über den Link selbständig registrieren.',
                    );
                    this.emitInvitationSentEvent(userRegistration);
                    this.emitCloseEvent();
                },
                error: (error) => {
                    this.userInvitationPending = false;
                    this.apiErrorService.handleAndRethrow({
                        axError: error,
                        handlers: {
                            DUPLICATE_EMAIL_ADDRESS: () =>
                                this.toastService.error(
                                    'E-Mailadresse bereits registriert',
                                    'Bitte verwende eine andere E-Mailadresse.',
                                ),
                            ERROR_FROM_REGISTRATION_SERVICE: () =>
                                this.toastService.error(
                                    'Fehler bei Registrierung',
                                    'Der Server hat einen Fehler zurück gegeben. Bitte kontaktiere die Hotline.',
                                ),
                        },
                        defaultHandler: {
                            title: 'Einladung nicht gesendet',
                            body: 'Bitte kontaktiere die Hotline.',
                        },
                    });
                },
            });
    }

    public isEmailValid(): boolean {
        if (!this.email) return false;
        return /^([a-zA-Z0-9_\-.]+)@([a-zA-Z0-9_\-.]+)\.([a-zA-Z]{2,5})$/.test(this.email);
    }

    public isTestOrPartnerAccount(): boolean {
        return this.accountStatus === 'test' || this.accountStatus === 'partner';
    }

    public handleOverlayClick(event: MouseEvent): void {
        if (event.target === event.currentTarget) {
            this.emitCloseEvent();
        }
    }

    //*****************************************************************************
    //  Events
    //****************************************************************************/
    public emitCloseEvent(): void {
        this.close.emit();
    }

    public emitInvitationSentEvent(userRegistration: UserRegistration): void {
        this.invitationSent.emit(userRegistration);
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Events
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Keyboard Shortcuts
    //****************************************************************************/
    @HostListener('window:keydown', ['$event'])
    public handleKeyboardShortcuts(event: KeyboardEvent) {
        switch (event.key) {
            case 'Escape':
                this.emitCloseEvent();
                break;
            case 'Enter':
                if (this.teamMemberToReactivate) {
                    this.reactivateTeamMember();
                } else {
                    this.sendInvitation();
                }
        }
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Keyboard Shortcuts
    /////////////////////////////////////////////////////////////////////////////*/
}

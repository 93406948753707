import { animate, state, style, transition, trigger } from '@angular/animations';

export function sentEmailItemAnimation() {
    return trigger('sentEmailItem', [
        state(
            'shown',
            style({
                transform: 'scaleY(1)',
            }),
        ),
        transition('void => *', [
            style({
                transform: 'scaleY(0)',
            }),
            animate('300ms ease-in-out'),
        ]),
    ]);
}

import { DateTime } from 'luxon';
import { RequireSome } from '../../../helper-types/require-some';
import { generateId } from '../../../lib/generate-id';

export class ResidualValueBidderGroup {
    constructor(residualValueBidderGroupTemplate?: RequireSome<ResidualValueBidderGroup, 'title' | 'createdBy'>) {
        Object.assign(this, residualValueBidderGroupTemplate);
    }

    _id: string = generateId();
    title: string;
    carBrand?: string;
    updatedAt: string = DateTime.now().toISO();
    createdBy: string;
    teamId: string;
    createdAt: string = DateTime.now().toISO();
    _documentVersion: number = 1;
    _schemaVersion = 1 as const;
}

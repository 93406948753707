/**
 * Creates a debounced function that delays invoking `callback` until after `delay` milliseconds
 * have elapsed since the last time the debounced function was called.
 */
export function debounceAx<T extends unknown[]>(callback: (...args: T) => void, delay: number) {
    let timeoutTimer: ReturnType<typeof setTimeout>;

    return (...args: T) => {
        clearTimeout(timeoutTimer);

        timeoutTimer = setTimeout(() => {
            callback(...args);
        }, delay);
    };
}

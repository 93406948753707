<div class="template-list">
    <div
        class="template large-selection-box contains-details"
        *ngFor="let template of sortedTemplates"
        (click)="selectTemplate(template)"
        (dblclick)="useTemplate(template)"
        [ngClass]="{ selected: selectedTemplate === template }">
        <div class="template-title">
            {{ template.title }}
        </div>
        <div class="detail">
            <!--********** If a detail getter is provided, use it instead of the default icon. **********-->
            <div *ngIf="detailTextGetter">{{ detailTextGetter(template) }}</div>
            <mat-icon *ngIf="!detailTextGetter" matTooltip="Vorlage">description</mat-icon>
        </div>
        <div class="large-selection-box-active-marker">
            <mat-icon>check</mat-icon>
        </div>
        <mat-icon
            class="template-submenu-icon"
            *ngIf="deleteAllowed && template.createdBy !== 'autoixpert-default'"
            [matMenuTriggerFor]="templateSubmenu">
            more_vert
        </mat-icon>
        <mat-menu #templateSubmenu>
            <a mat-menu-item class="menu-item-with-icon" (click)="emitTemplateDeletion(template)">
                <mat-icon>delete</mat-icon>
                Löschen
            </a>
        </mat-menu>
    </div>
</div>
<div class="buttons-container">
    <button
        (click)="useTemplate(selectedTemplate)"
        [ngClass]="{ disabled: !selectedTemplate }"
        [matTooltip]="selectedTemplate ? '' : 'Bitte eine Vorlage auswählen'">
        Übernehmen
    </button>
    <button class="secondary" (click)="proceedWithoutTemplate()">Keine Vorlage</button>
</div>

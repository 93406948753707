import { Report } from '@autoixpert/models/reports/report';
import { ContactPersonService } from '../../services/contact-person.service';
import { rememberContactPerson } from './remember-contact-person-in-report';

/**
 * Make certain contactPeople available as templates the next time the appraiser
 * fills out a report.
 */
export async function rememberContactPeopleInReport(
    report: Report,
    contactPersonService: ContactPersonService,
): Promise<void> {
    if (report.claimant?.contactPerson)
        await rememberContactPerson(report.claimant.contactPerson, contactPersonService);
    if (report.garage?.contactPerson) await rememberContactPerson(report.garage.contactPerson, contactPersonService);
    if (report.lawyer?.contactPerson) await rememberContactPerson(report.lawyer.contactPerson, contactPersonService);
    if (report.insurance?.contactPerson)
        await rememberContactPerson(report.insurance.contactPerson, contactPersonService);
    if (report.leaseProvider?.contactPerson)
        await rememberContactPerson(report.leaseProvider.contactPerson, contactPersonService);
}

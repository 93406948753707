import { Component, ElementRef, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import {
    Translator,
    VehicleValueLabelGerman,
    VehicleValueLabelGermanShort,
} from '@autoixpert/lib/placeholder-values/translator';
import { TaxationType, ValuationVehicleValueType } from '@autoixpert/models/reports/market-value/valuation';

@Component({
    selector: 'vehicle-value-settings-card',
    templateUrl: './vehicle-value-settings-card.component.html',
    styleUrls: ['./vehicle-value-settings-card.component.scss'],
    host: {
        '[class.active]': 'this.isActive',
        '[class.disabled]': 'this.disabled',
    },
})
export class VehicleValueSettingsCardComponent {
    @ViewChild('grossValueInput') grossValueInput: ElementRef<HTMLInputElement>;
    @ViewChild('netValueInput') netValueInput: ElementRef<HTMLInputElement>;

    @Input() vehicleValueType: ValuationVehicleValueType;
    @Input() taxationType: TaxationType;
    @Input() isVatDeductible: boolean;
    /**
     * The sum of all corrections made in the valuation screen.
     * Determines the presence and color of the corrections target selector.
     */
    @Input() correctionsSum: number;
    /**
     * Whether the corrections shall be factored into this value.
     * If so, the other value (dealer purchase or dealer sales price) will be derived from
     * this value by applying a profit margin.
     */
    @Input() isCorrectionsTarget: boolean;
    @Output() selectedAsCorrectionTarget: EventEmitter<void> = new EventEmitter();

    @Input() netValue: number;
    @Input() grossValue: number;
    @Input() heading: string;
    @Input() isOverwritten: boolean;

    /**
     * Whether the card (vehicle value) is activated (toggle active) or not.
     */
    @Input() isActive: boolean = true;

    @Input() showVatDeductible: boolean;
    @Input() showTaxationType: boolean;

    @Input() showDecimalPlaces: boolean;

    /**
     * Disable all input controls
     */
    @Input() disabled: boolean = false;

    @Output() vehicleValueTypeChange: EventEmitter<ValuationVehicleValueType> =
        new EventEmitter<ValuationVehicleValueType>();
    @Output() taxationTypeChange: EventEmitter<TaxationType> = new EventEmitter<TaxationType>();
    @Output() netValueChange: EventEmitter<number> = new EventEmitter<number>();
    @Output() grossValueChange: EventEmitter<number> = new EventEmitter<number>();
    @Output() isActiveChange: EventEmitter<boolean> = new EventEmitter<boolean>();
    @Output() isOverwrittenChange: EventEmitter<boolean> = new EventEmitter<boolean>();

    /**
     * Event that notifies listeners, that any value in this component has changed (e.g. taxation type, vehicle value, etc.).
     */
    @Output() anyValueChange: EventEmitter<void> = new EventEmitter<void>();

    protected selectThisValueAsCorrectionsTarget() {
        this.selectedAsCorrectionTarget.emit();
    }

    /**
     * Helper variables to track the editing state of vehicle value inputs
     */
    protected isEditingNetValue: boolean = false;
    protected isEditingGrossValue: boolean = false;

    protected translateVehicleValueType(
        valueType: ValuationVehicleValueType,
    ): VehicleValueLabelGerman | VehicleValueLabelGermanShort {
        return Translator.vehicleValueLabelShort(valueType);
    }

    protected toggleEditingNetValue(): void {
        this.isEditingNetValue = !this.isEditingNetValue;

        if (this.isEditingNetValue) {
            setTimeout(() => {
                // Wait for the disabled binding to take effect (otherwise it won't focus the still disabled input)
                this.netValueInput.nativeElement.focus();
            }, 0);
        } else {
            this.markValueAsOverwritten();
        }
    }

    protected toggleEditingGrossValue(): void {
        this.isEditingGrossValue = !this.isEditingGrossValue;

        if (this.isEditingGrossValue) {
            setTimeout(() => {
                // Wait for the disabled binding to take effect (otherwise it won't focus the still disabled input)
                this.grossValueInput.nativeElement.focus();
            }, 0);
        } else {
            this.markValueAsOverwritten();
        }
    }

    private markValueAsOverwritten(): void {
        this.isOverwrittenChange.emit(true);
        this.anyValueChange.emit();
    }
}

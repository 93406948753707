import { ApiErrorHandler, ApiErrorHandlerCreator } from '../../services/api-error.service';

export function getInvoiceApiErrorHandlers(): {
    [key: string]: ApiErrorHandler | ApiErrorHandlerCreator;
} {
    return {
        INVOICE_NUMBER_EXISTS: (error) => ({
            title: 'Rechnungsnummer vergeben',
            body: `Eine andere <a href="/Rechnungen/${error.data.invoiceIdSameInvoiceNumber}" target="_blank">Rechnung mit der Nummer ${error.data.invoiceNumber}</a> existiert bereits. Bitte wähle eine andere Rechnungsnummer.`,
        }),
        CANCELLATION_INVOICE_NUMBER_EXISTS: {
            title: 'Storno-Rechnung nicht angelegt',
            body: 'Es existiert bereits eine Rechnung mit derselben Rechnungsnummer wie die Storno-Rechnung. Bitte prüfe die Einstellungen für die Vergabe der Rechnungsnummern.',
        },
    };
}

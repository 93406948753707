import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { SwUpdate } from '@angular/service-worker';
import { Query as FeathersQuery } from '@feathersjs/feathers';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { Manufacturer } from '@autoixpert/models/reports/car-identification/dat-models';
import { OfflineSyncServiceBase } from '../libraries/database/offline-sync-service.base';
import { FrontendLogService } from './frontend-log.service';
import { NetworkStatusService } from './network-status.service';
import { SyncIssueNotificationService } from './sync-issue-notification.service';

@Injectable()
export class ManufacturerService extends OfflineSyncServiceBase<Manufacturer> {
    constructor(
        protected httpClient: HttpClient,
        protected networkStatusService: NetworkStatusService,
        protected frontendLogService: FrontendLogService,
        protected syncIssueNotificationService: SyncIssueNotificationService,
        protected serviceWorker: SwUpdate,
    ) {
        super({
            serviceName: 'manufacturers',
            httpClient,
            networkStatusService,
            syncIssueNotificationService,
            serviceWorker,
            frontendLogService,
        });
    }

    public find(feathersQuery?: FeathersQuery | Partial<Manufacturer>): Observable<Manufacturer[]> {
        return super.find(feathersQuery).pipe(
            // Sort the manufacturers by name. Since the server loads the data in parallel, the order is not preserved.
            tap((manufacturers) => {
                manufacturers.forEach((manufacturer) => {
                    if (manufacturer.baseModels) {
                        manufacturer.baseModels.forEach((baseModel) => {
                            baseModel.subModels.sort((subModelA, subModelB) =>
                                subModelA.name < subModelB.name ? -1 : 1,
                            );
                        });
                        manufacturer.baseModels.sort((baseModelA, baseModelB) =>
                            baseModelA.name < baseModelB.name ? -1 : 1,
                        );
                    }
                });

                return manufacturers.sort((manufacturerA, manufacturerB) =>
                    manufacturerA.name < manufacturerB.name ? -1 : 1,
                );
            }),
        );
    }

    /**
     * No need to reload the manufacturers every time the user comes back online since the manufacturers usually do not change.
     * They will be updated when the user logs in or reloads the page, that's enough.
     */
    protected registerBackOnlineHandlers() {
        return;
    }
}

import { Overlay, OverlayRef } from '@angular/cdk/overlay';
import { ComponentPortal } from '@angular/cdk/portal';
import {
    ComponentRef,
    Directive,
    ElementRef,
    EventEmitter,
    HostListener,
    Injector,
    Input,
    Output,
} from '@angular/core';
import { IsoDate } from '@autoixpert/lib/date/iso-date.types';
import { DatePickerWithInputOverlayComponent } from './date-picker-with-input-overlay.component';

@Directive({
    selector: '[datePickerWithInputAnchor]',
    exportAs: 'datePickerWithInputAnchor',
})
export class DatePickerWithInputAnchorDirective {
    constructor(
        private overlayService: Overlay,
        private injector: Injector,
        private elementRef: ElementRef,
    ) {}

    @Input() date: IsoDate;
    @Input() time: string;
    @Input() showTimeInput: boolean = false;

    @Output() dateChange: EventEmitter<IsoDate> = new EventEmitter();
    @Input() disabled: boolean = false;
    @Output() timeChange: EventEmitter<string> = new EventEmitter();

    private overlayRef: OverlayRef;
    private componentRef: ComponentRef<DatePickerWithInputOverlayComponent>;

    //*****************************************************************************
    //  Anchor Click Handler
    //****************************************************************************/
    @HostListener('click', ['$event'])
    public openOverlay() {
        // Avoid duplicate panels.
        if (this.overlayRef) return;

        if (this.disabled) return;

        // Configure overlay
        this.overlayRef = this.overlayService.create({
            hasBackdrop: true,
            backdropClass: 'panel-transparent-backdrop',
            positionStrategy: this.overlayService
                .position()
                .flexibleConnectedTo(this.elementRef)
                .withPositions([
                    {
                        originX: 'start',
                        originY: 'bottom',
                        overlayX: 'start',
                        overlayY: 'top',
                        offsetY: 5,
                    },
                ])
                .withViewportMargin(10),
            scrollStrategy: this.overlayService.scrollStrategies.noop(),
        });

        // Close panel when clicking the backdrop.
        this.overlayRef.backdropClick().subscribe(() => {
            this.overlayRef.detach();
        });
        this.overlayRef.detachments().subscribe(() => {
            this.overlayRef = null;
        });

        // Instantiate the portal component.
        const componentPortal = new ComponentPortal<DatePickerWithInputOverlayComponent>(
            DatePickerWithInputOverlayComponent,
            null,
            Injector.create({
                parent: this.injector,
                providers: [
                    {
                        provide: OverlayRef,
                        useValue: this.overlayRef,
                    },
                ],
            }),
        );

        // Attach Component to Portal Outlet
        this.componentRef = this.overlayRef.attach(componentPortal);

        // Update component properties.
        this.componentRef.instance.date = this.date as IsoDate;
        this.componentRef.instance.time = this.time;
        this.componentRef.instance.showTimeInput = this.showTimeInput;

        // Subscribe to changes in component.
        this.componentRef.instance.dateChange.subscribe((newDate) => {
            this.dateChange.emit(newDate);
        });

        this.componentRef.instance.timeChange.subscribe((newTime) => {
            this.timeChange.emit(newTime);
        });
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Anchor Click Handler
    /////////////////////////////////////////////////////////////////////////////*/
}

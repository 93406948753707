<div
    id="three-columns-layout-container"
    *ngIf="report"
    [ngClass]="{
        'justify-content-center':
            report.type === 'shortAssessment' || report.type === 'valuation' || report.type === 'invoiceAudit',
    }">
    <!-------------- Left column: Garage -->

    <div id="garage-column" class="one-third-data-column">
        <div class="card card-with-padding" *ngIf="report.type !== 'valuation'">
            <h2>Werkstatt</h2>

            <!--============================================-->
            <!-- DEKRA Fees -->
            <!--============================================-->
            <div class="checkbox-group centered" *ngIf="report.damageCalculation && report.type !== 'invoiceAudit'">
                <!--********** Use DEKRA Fees **********-->
                <mat-checkbox
                    class="margin-bottom-20"
                    [(ngModel)]="report.damageCalculation.repair.useDekraFees"
                    [disabled]="isReportLocked()"
                    (change)="deactivateRepairOrderIssuedIfDekraFeesUsed(); saveReport(); clearGarage()"
                    matTooltip="Regionaler Durchschnitt von Reparaturkostensätzen auf Basis von Erhebungen der DEKRA.{{
                        '\n\n'
                    }}{{ getDekraFeesZipSourceTooltip() }}{{
                        '\n\n'
                    }}Die DEKRA liefert keine Kostensätze für Elektrik und Dellendrücken. Für Elektrik wird der Kostensatz für Mechanik angenommen. Als Grundlage für das Dellendrücken dient der Kostensatz von Lackarbeiten.">
                    DEKRA-Sätze verwenden
                </mat-checkbox>
            </div>
            <!--============================================-->
            <!-- END DEKRA Fees -->
            <!--============================================-->

            <!--============================================-->
            <!-- Garage Contact -->
            <!--============================================-->
            <ng-container *ngIf="report.garage && !report.damageCalculation?.repair.useDekraFees">
                <!-- TODO Add DAT customer number back as soon as we know how to proceed with the DAT myclaim export. -->
                <contact-person-form
                    [contact-person]="report.garage.contactPerson"
                    [organizationTypes]="['garage']"
                    (contactPersonChange)="saveReport()"
                    (contactPersonSelection)="selectDefaultGarageFeeSet($event); saveReport()"
                    (contactReset)="clearSelectedGarageFeeSet(); saveReport()"
                    organization-placeholder="Werkstatt"
                    [additionalContactPeopleForAutocomplete]="garagesFromOpenReports"
                    [show-website]="true"
                    [show-notes]="true"
                    [show-dat-customer-number]="false"
                    [show-contact-reset]="true"
                    [show-search-matches]="true"
                    [show-suggestions-if-empty]="true"
                    [disabled]="isReportLocked()"></contact-person-form>

                <!--********** Garage Case Number **********-->
                <mat-form-field class="mat-block" *ngIf="report.type === 'leaseReturn'">
                    <input
                        matInput
                        placeholder="Aktenzeichen"
                        [(ngModel)]="report.garage.caseNumber"
                        (change)="saveReport()"
                        [disabled]="isReportLocked()" />
                </mat-form-field>

                <!--============================================-->
                <!-- Garage Fees -->
                <!--============================================-->
                <div id="garage-fees-container" *ngIf="report.type !== 'invoiceAudit'">
                    <div id="add-garage-fees-container" *ngIf="!report.garage.contactPerson.garageFeeSets?.length">
                        <button
                            (click)="showGarageFeesDialog()"
                            [ngClass]="{ disabled: !report.garage.contactPerson.organization }"
                            matTooltip="Bitte gib einen Werkstattnamen ein"
                            [matTooltipDisabled]="!!report.garage.contactPerson.organization">
                            + Kostensätze
                        </button>
                    </div>

                    <div
                        id="garage-fee-set-selection-container"
                        *ngIf="report.garage.contactPerson.garageFeeSets?.length">
                        <!--********** Icon **********-->
                        <div
                            id="garage-fee-set-icon-container"
                            (click)="showGarageFeesDialog()"
                            matTooltip="Kostensätze der Werkstatt{{
                                '\n\n'
                            }}Sie werden beim Start der Kalkulation automatisch übertragen.">
                            <mat-icon class="medium-icon">euro_symbol</mat-icon>
                        </div>

                        <div
                            id="single-garage-fee-set-container"
                            *ngIf="report.garage.contactPerson.garageFeeSets.length === 1"
                            (click)="showGarageFeesDialog()">
                            <div *ngIf="getSelectedGarageFeeSet()">
                                <div class="bold">{{ getSelectedGarageFeeSet().title || 'Kein Titel' }}</div>
                                <div class="label" *ngIf="getSelectedGarageFeeSet().validFrom">
                                    Gültig seit {{ getSelectedGarageFeeSet().validFrom | moment }}
                                </div>
                            </div>
                            <mat-icon>edit</mat-icon>
                        </div>

                        <ng-container *ngIf="report.garage.contactPerson.garageFeeSets.length > 1">
                            <mat-form-field class="mat-block" floatLabel="never">
                                <mat-select
                                    id="fee-set-title-container"
                                    [(ngModel)]="report.garage.selectedFeeSetId"
                                    (selectionChange)="saveReport()"
                                    placeholder="Kostensätze"
                                    [disabled]="isReportLocked()">
                                    <mat-select-trigger>
                                        <!--********** Fee Set Selected **********-->
                                        <div *ngIf="getSelectedGarageFeeSet()">
                                            <div class="bold">
                                                {{ getSelectedGarageFeeSet().title || 'Kein Titel' }}
                                            </div>
                                            <div class="label" *ngIf="getSelectedGarageFeeSet().validFrom">
                                                Gültig seit {{ getSelectedGarageFeeSet().validFrom | moment }}
                                            </div>
                                        </div>
                                    </mat-select-trigger>

                                    <!--********** Options **********-->
                                    <mat-option
                                        class="option-with-label"
                                        *ngFor="let availableFeeSet of this.report.garage.contactPerson.garageFeeSets"
                                        [value]="availableFeeSet._id">
                                        <div class="available-fee-set-option">
                                            <div>
                                                <div class="semibold" [matTooltip]="availableFeeSet.title">
                                                    {{ availableFeeSet.title || 'Kein Titel' }}
                                                </div>
                                                <div class="label">
                                                    Gültig seit {{ availableFeeSet.validFrom | moment }}
                                                </div>
                                            </div>
                                            <mat-icon
                                                *ngIf="availableFeeSet.isDefault"
                                                class="default-garage-fee-set-marker medium-icon"
                                                matTooltip="Standard-Kostensätze">
                                                star
                                            </mat-icon>
                                        </div>
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>

                            <!--********** Edit Fee Set Icon **********-->
                            <div id="edit-garage-fees-icon">
                                <mat-icon
                                    class="medium-icon cursor-pointer"
                                    (click)="showGarageFeesDialog()"
                                    matTooltip="Kostensätze bearbeiten">
                                    edit
                                </mat-icon>
                            </div>
                        </ng-container>
                    </div>
                </div>
                <garage-fees
                    *ngIf="garageFeesDialogShown"
                    [garageContact]="report.garage.contactPerson"
                    [car]="report.car"
                    [(selectedFeeSetId)]="report.garage.selectedFeeSetId"
                    (contactPersonChange)="saveReport()"
                    (selectedFeeSetIdChange)="saveReport()"
                    (close)="closeGarageFeesDialog(); selectDefaultGarageFeeSetIfEmpty()"
                    [askToUpdateContact]="true"
                    [disabled]="isReportLocked()"
                    @runChildAnimations></garage-fees>
                <!--============================================-->
                <!-- END Garage Fees -->
                <!--============================================-->

                <!--============================================-->
                <!-- Garage Brands -->
                <!--============================================-->
                <garage-brands
                    [garageContact]="report.garage.contactPerson"
                    (contactPersonChange)="saveReport()"
                    [disabled]="isReportLocked()"></garage-brands>
                <!--============================================-->
                <!-- END Garage Brands -->
                <!--============================================-->

                <!--********** Repair Order Issued **********-->
                <div
                    id="repair-order-issued-container"
                    class="checkbox-group parent-of-an-only-child"
                    *ngIf="report.type !== 'invoiceAudit'">
                    <tri-state-checkbox
                        *ngIf="report.damageCalculation && !report.damageCalculation.repair.useDekraFees"
                        [(ngModel)]="report.damageCalculation.repair.repairOrderIssued"
                        [disabled]="isReportLocked()"
                        (change)="saveReport()"
                        matTooltip="Wurde der Auftrag bereits erteilt, kann es Kürzungen bei Werkstattkostensätzen vorbeugen, dies der Versicherung mitzuteilen.">
                        Reparaturauftrag erteilt
                    </tri-state-checkbox>
                </div>
            </ng-container>
            <!--============================================-->
            <!-- END Garage Contact -->
            <!--============================================-->

            <!--============================================-->
            <!-- DEKRA fees shall be used -->
            <!--============================================-->
            <ng-container *ngIf="report.damageCalculation.repair.useDekraFees">
                <div id="dekra-fee-zip-container">
                    <!--********** Readonly mode **********-->
                    <div
                        id="dekra-fee-zip-readonly-mode-container"
                        (click)="toggleCustomDekraFeesZipInput()"
                        *ngIf="!editDekraFeeZip">
                        <mat-icon>place</mat-icon>
                        <div id="dekra-fee-city-and-zip-container">
                            <div class="bold" *ngIf="!dekraZipToCityLookupPending">{{ getCityForDekraFees() }}</div>
                            <div id="dekra-fee-city-lookup-pending-indicator" *ngIf="dekraZipToCityLookupPending">
                                <img src="/assets/images/icons/loading-indicator.svg" alt="" />
                            </div>
                            <div class="label" [matTooltip]="getDekraFeesZipSourceTooltip()">
                                {{ getZipForDekraFees() }}
                            </div>
                        </div>
                        <mat-icon id="edit-dekra-fee-icon" class="medium-icon" *ngIf="!editDekraFeeZip">
                            mode_edit
                        </mat-icon>
                    </div>

                    <!--********** Edit Mode **********-->
                    <ng-container *ngIf="editDekraFeeZip">
                        <mat-form-field id="custom-dekra-zip-input" class="mat-block">
                            <input
                                matInput
                                placeholder="PLZ für DEKRA-Sätze"
                                [(ngModel)]="report.damageCalculation.repair.customDekraFeeZip"
                                (keydown)="leaveCustomDekraFeesZipInputOnEnter($event)"
                                (change)="saveReport()"
                                [disabled]="isReportLocked()" />
                        </mat-form-field>
                        <mat-icon
                            class="toolbar-icon"
                            (click)="
                                toggleCustomDekraFeesZipInput(); validateCustomDekraFeeZip() && lookupCityForDekraZip()
                            ">
                            done
                        </mat-icon>
                    </ng-container>
                </div>

                <!--********** ZIP invalid for DEKRA fees **********-->
                <div
                    id="custom-dekra-zip-warning"
                    class="info-note warning-note"
                    *ngIf="dekraFeesCustomZipWarnings.length">
                    <mat-icon>warning</mat-icon>
                    <div>
                        <span *ngFor="let dekraFeesCustomZipWarning of dekraFeesCustomZipWarnings">
                            {{ dekraFeesCustomZipWarning }}
                        </span>
                    </div>
                </div>

                <!--============================================-->
                <!-- Note: Audatex Wage Rate Translation -->
                <!--============================================-->
                <div
                    id="audatex-wage-rate-translation-note-container"
                    *ngIf="
                        !user?.userInterfaceStates.audatexWageRateTranslationNoteClosed &&
                        isAudatexUserComplete(user) &&
                        (!report.car.identificationProvider || report.car.identificationProvider === 'audatex') &&
                        isRemappingRequiredForAudatexWageRates({
                            manufacturer: report.car.make,
                            carShape: report.car.shape,
                        })
                    "
                    @slideOutVertical>
                    <audatex-wage-rate-translation-note
                        [carManufacturer]="report.car.make"></audatex-wage-rate-translation-note>
                </div>
                <!--============================================-->
                <!-- END Note: Audatex Wage Rate Translation -->
                <!--============================================-->

                <!--********** Placeholder Image **********-->
                <div id="garage-placeholder-icon-container">
                    <img id="garage-placeholder-icon" src="/assets/images/icons/screw-driver.svg" alt="Werkstatt" />
                </div>
            </ng-container>
            <!--============================================-->
            <!-- END DEKRA fees shall be used -->
            <!--============================================-->

            <!--============================================-->
            <!-- Custom Fields -->
            <!--============================================-->
            <custom-field-display
                fieldLocation="damage-calculation_garage"
                [report]="report"
                (reportChange)="saveReport()"
                [disabled]="isReportLocked()"></custom-field-display>
            <!--============================================-->
            <!-- END Custom Fields -->
            <!--============================================-->
        </div>
    </div>
    <!-------------- Center column: External calculation -->

    <div class="one-third-data-column" *ngIf="report.type !== 'invoiceAudit'">
        <!--============================================-->
        <!-- Damage Calculation -->
        <!--============================================-->
        <div id="external-damage-calculation-column" class="card card-with-padding" *ngIf="report.type !== 'valuation'">
            <h2 id="external-damage-calculation-heading">
                <ng-container *ngIf="report.damageCalculation.repair.calculationProvider === 'manual'">
                    Manuelle
                </ng-container>
                Kalkulation
            </h2>

            <damage-calculation-providers
                [report]="report"
                (valuesDidChange)="determineDamageType(report, userPreferences); saveReport()"
                (showDiminishedValueNotification)="
                    diminishedValueWarningNotificationShown = true
                "></damage-calculation-providers>
        </div>
        <!--============================================-->
        <!-- END Damage Calculation -->
        <!--============================================-->

        <!--============================================-->
        <!-- Market Analysis -->
        <!--============================================-->
        <div id="market-analyses-container" class="card card-with-padding" *ngIf="report.type !== 'shortAssessment'">
            <h2 id="market-analysis-heading">Marktwert</h2>

            <market-value-overview
                [report]="report"
                [user]="user"
                (vehicleValueGrossSelected)="setVehicleValueGross($event)"></market-value-overview>
        </div>
        <!--============================================-->
        <!-- END Market Analysis -->
        <!--============================================-->

        <!--***********************************************
        ** Downtime Compensation
        ************************************************-->
        <div
            id="downtime-compensation-card"
            class="card card-with-padding"
            (change)="saveReport()"
            *ngIf="!['valuation', 'leaseReturn', 'usedVehicleCheck'].includes(report.type) && !isKaskoCase()">
            <!--********** Submenu **********-->
            <div class="card-toolbar" *ngIf="report.type !== 'shortAssessment'">
                <mat-icon
                    *ngIf="!isReportLocked()"
                    id="downtime-compensation-submenu-icon"
                    class="toolbar-icon"
                    [matMenuTriggerFor]="compensationSubmenu">
                    more_vert
                </mat-icon>
                <mat-menu #compensationSubmenu>
                    <!--********** Hours per workday **********-->
                    <a
                        mat-menu-item
                        class="menu-item-with-icon"
                        matTooltip="AutoiXpert kann aus den Arbeitszeiten der Kalkulation die Reparaturdauer in Tagen ermitteln. Lege hier fest, wie viele Stunden ein Werkstatttag haben soll."
                        [mat-menu-trigger-for]="hoursInARepairDayMenu">
                        <mat-icon>timer</mat-icon>
                        Arbeitsstunden pro Tag
                    </a>

                    <!--********** Rental Car Costs per Day **********-->
                    <a
                        mat-menu-item
                        class="menu-item-with-icon"
                        matTooltip="Blende das Feld der Mietwagenkosten pro Tag ein."
                        (click)="toggleRentalCarCostsShown()">
                        <mat-icon>visibility</mat-icon>
                        {{
                            userPreferences.showRentalCarCosts
                                ? 'Mietwagenkosten pro Tag ausblenden'
                                : 'Mietwagenkosten pro Tag einblenden'
                        }}
                    </a>
                </mat-menu>

                <mat-menu #hoursInARepairDayMenu>
                    <a
                        mat-menu-item
                        class="day-selection-with-icon"
                        (click)="setHoursInRepairWorkday(6)"
                        [matTooltip]="
                            !hasAccessRight(user, 'editTextsAndDocumentBuildingBlocks')
                                ? getMissingAccessRightTooltip('editTextsAndDocumentBuildingBlocks')
                                : ''
                        "
                        [class.disabled]="!hasAccessRight(user, 'editTextsAndDocumentBuildingBlocks')">
                        6-Stundentag
                        <mat-icon class="selection-icon" *ngIf="this.team.preferences.hoursInRepairWorkday === 6">
                            check
                        </mat-icon>
                    </a>
                    <a
                        mat-menu-item
                        class="day-selection-with-icon"
                        (click)="setHoursInRepairWorkday(8)"
                        [matTooltip]="
                            !hasAccessRight(user, 'editTextsAndDocumentBuildingBlocks')
                                ? getMissingAccessRightTooltip('editTextsAndDocumentBuildingBlocks')
                                : ''
                        "
                        [class.disabled]="!hasAccessRight(user, 'editTextsAndDocumentBuildingBlocks')">
                        8-Stundentag
                        <mat-icon class="selection-icon" *ngIf="this.team.preferences.hoursInRepairWorkday === 8">
                            check
                        </mat-icon>
                    </a>
                </mat-menu>
            </div>

            <div id="downtime-and-provisioning-costs-heading-container">
                <h2>
                    <ng-container *ngIf="!report.damageCalculation.useProvisioningCosts">Nutzungsausfall</ng-container>
                    <ng-container *ngIf="report.damageCalculation.useProvisioningCosts">Vorhaltekosten</ng-container>
                    <mat-icon
                        *ngIf="!isReportLocked()"
                        id="switch-downtime-and-provisioning-costs-icon"
                        class="medium-icon toolbar-icon"
                        (click)="toggleProvisioningCosts(); saveReport()"
                        matTooltip="Wechseln zwischen Nutzungsausfall und Vorhaltekosten">
                        swap_horiz
                    </mat-icon>
                </h2>
            </div>
            <ng-container *ngIf="report.type !== 'shortAssessment'">
                <!--============================================-->
                <!-- Downtime Compensation Inputs -->
                <!--============================================-->
                <ng-container *ngIf="!report.damageCalculation.useProvisioningCosts">
                    <div id="downtime-compensation-container" class="mat-block">
                        <!--********** Downtime Compensation Select **********-->
                        <div id="downtime-compensation-group-container">
                            <mat-form-field id="downtime-compensation-group">
                                <mat-select
                                    placeholder="Ausfallgruppe"
                                    [(ngModel)]="report.damageCalculation.downtimeCompensationGroup"
                                    [disabled]="isReportLocked()"
                                    (selectionChange)="
                                        setDowntimeCompensationPerWorkday(
                                            report.damageCalculation.downtimeCompensationGroup
                                        );
                                        saveReport()
                                    "
                                    matTooltip="Gruppen gemäß Eurotax-Schwacke. Die Klassen werden automatisch für Fahrzeuge älter als 5 Jahre um eine, älter als 10 Jahre um zwei Klassen herabgesetzt.">
                                    <mat-select-trigger>
                                        Gruppe {{ report.damageCalculation.downtimeCompensationGroup }}
                                    </mat-select-trigger>
                                    <!-- Add an empty option to remove the value after the user accidentally chose a wrong value. -->
                                    <mat-option [value]="null"></mat-option>

                                    <!--============================================-->
                                    <!-- Eurotax/Schwacke Downtime Compensation Groups -->
                                    <!--============================================-->
                                    <ng-container>
                                        <mat-option
                                            class="option-with-label"
                                            *ngFor="
                                                let downtimeCompensationGroup of downtimeCompensationGroupsForCarsEurotax;
                                                let index = index
                                            "
                                            [value]="downtimeCompensationGroup.groupName">
                                            <div class="autocomplete-option-wrapper">
                                                <!--********** Icon **********-->
                                                <div class="downtime-compensation-group-icon">
                                                    {{ downtimeCompensationGroup.groupName }}
                                                </div>

                                                <!--********** Text **********-->
                                                <div class="downtime-compensation-group-text">
                                                    Gruppe {{ downtimeCompensationGroup.groupName }}
                                                    <div class="label">
                                                        <ng-container *ngIf="downtimeCompensationGroup.customLabel">
                                                            {{ downtimeCompensationGroup.customLabel }} -&nbsp;
                                                        </ng-container>
                                                        {{
                                                            downtimeCompensationGroup.compensationPerDay
                                                                | number: '1.0-0'
                                                        }}
                                                        €
                                                    </div>
                                                </div>
                                            </div>
                                        </mat-option>

                                        <!--********** Motorcycles **********-->
                                        <mat-optgroup label="Zweiräder">
                                            <mat-option
                                                class="option-with-label"
                                                *ngFor="
                                                    let downtimeCompensationGroup of downtimeCompensationGroupsForMotorcyclesEurotax
                                                "
                                                [value]="downtimeCompensationGroup.groupName">
                                                <div class="autocomplete-option-wrapper">
                                                    <!--********** Icon **********-->
                                                    <div class="downtime-compensation-group-icon">
                                                        {{ downtimeCompensationGroup.groupName }}
                                                    </div>

                                                    <!--********** Text **********-->
                                                    <div class="downtime-compensation-group-text">
                                                        Gruppe {{ downtimeCompensationGroup.groupName }}
                                                        <div class="label">
                                                            <ng-container *ngIf="downtimeCompensationGroup.customLabel">
                                                                {{ downtimeCompensationGroup.customLabel }} -&nbsp;
                                                            </ng-container>
                                                            {{
                                                                downtimeCompensationGroup.compensationPerDay
                                                                    | number: '1.0-0'
                                                            }}
                                                            €
                                                        </div>
                                                    </div>
                                                </div>
                                            </mat-option>
                                        </mat-optgroup>
                                    </ng-container>
                                    <!--============================================-->
                                    <!-- END Eurotax/Schwacke Downtime Compensation Groups -->
                                    <!--============================================-->
                                </mat-select>
                            </mat-form-field>

                            <!--********** Original Group **********-->
                            <span
                                id="original-downtime-compensation-group"
                                *ngIf="report.damageCalculation.originalDowntimeCompensationGroup"
                                [matTooltip]="getOriginalDowntimeCompensationGroupTooltip()">
                                {{ report.damageCalculation.originalDowntimeCompensationGroup }}
                            </span>
                        </div>

                        <!--********** Downtime Compensation Monetary Value **********-->
                        <currency-input
                            id="downtime-compensation-per-workday"
                            placeholder="Kosten pro Tag"
                            [(value)]="report.damageCalculation.downtimeCompensationPerWorkday"
                            (valueChange)="saveReport()"
                            [disabled]="isReportLocked()"></currency-input>
                        <img
                            id="downtime-compensation-request-pending-icon"
                            *ngIf="downtimeCompensationRequestPending"
                            src="/assets/images/icons/loading-indicator.svg"
                            matTooltip="Die Nutzungsausfall-Abfrage läuft..." />
                        <mat-icon
                            id="get-downtime-compensation-icon"
                            class="action-icon"
                            *ngIf="!isReportLocked() && !downtimeCompensationRequestPending"
                            (click)="getDowntimeCompensation()"
                            [class.disabled]="!requestingDowntimeCompensationPricesAllowed()"
                            [matTooltip]="getRentalPricesIconTooltip()">
                            get_app
                        </mat-icon>
                    </div>
                    <div id="rental-car-data-container" class="input-with-external-icon-container mat-block">
                        <mat-form-field
                            id="rental-car-class"
                            [ngClass]="{
                                'full-width':
                                    !userPreferences.showRentalCarCosts &&
                                    !report.damageCalculation.rentalCarClassCostsPerDay,
                            }">
                            <mat-select
                                placeholder="Mietwagenklasse"
                                [(ngModel)]="report.damageCalculation.rentalCarClass"
                                [disabled]="isReportLocked()"
                                (selectionChange)="saveReport()">
                                <!-- Add an empty option to remove the value after the user accidentally chose a wrong value. -->
                                <mat-option [value]="undefined"></mat-option>
                                <mat-option
                                    *ngFor="let rentalCarClass of rentalCarClasses"
                                    [value]="rentalCarClass.id"
                                    [matTooltip]="getRentalCarClassTooltip(rentalCarClass.id)">
                                    {{ rentalCarClass.id }} - {{ rentalCarClass.description }}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                        <currency-input
                            id="rental-car-price-per-workday"
                            placeholder="Mietwagenkosten pro Tag"
                            *ngIf="
                                userPreferences.showRentalCarCosts || report.damageCalculation.rentalCarClassCostsPerDay
                            "
                            [(value)]="report.damageCalculation.rentalCarClassCostsPerDay"
                            (valueChange)="saveReport()"
                            [disabled]="isReportLocked()"></currency-input>
                        <mat-icon
                            class="input-external-icon show-icon-on-card-hover outlined"
                            (click)="toggleDowntimeCompensationRemark(); focusDowntimeCompensationRemark()"
                            *ngIf="!isReportLocked()"
                            matTooltip="Kommentar Nutzungsausfall">
                            chat_bubble
                        </mat-icon>
                    </div>
                    <!--********** Remarks **********-->
                    <mat-form-field class="mat-block" *ngIf="downtimeCompensationCommentShown">
                        <mat-quill
                            [(ngModel)]="report.damageCalculation.downtimeCompensationComment"
                            placeholder="Kommentar Nutzungsausfall"
                            [disabled]="isReportLocked()"
                            #downtimeCompensationRemark
                            (change)="saveReport()"></mat-quill>
                        <mat-icon
                            matSuffix
                            *ngIf="!isReportLocked()"
                            class="input-toolbar-icon"
                            (click)="downtimeCompensationTextTemplatesShown = true"
                            matTooltip="Textvorlagen">
                            playlist_add
                        </mat-icon>
                    </mat-form-field>
                    <text-template-selector
                        [(text)]="report.damageCalculation.downtimeCompensationComment"
                        (textChange)="saveReport()"
                        [report]="report"
                        *ngIf="downtimeCompensationTextTemplatesShown"
                        placeholderForResultingText="Kommentar Nutzungsausfall"
                        (close)="downtimeCompensationTextTemplatesShown = false"
                        [textTemplateType]="'downtimeCompensationComment'"
                        @runChildAnimations></text-template-selector>
                </ng-container>
                <!--============================================-->
                <!-- END Downtime Compensation Inputs -->
                <!--============================================-->

                <!--============================================-->
                <!-- Provisioning Costs -->
                <!--============================================-->
                <ng-container *ngIf="report.damageCalculation.useProvisioningCosts">
                    <currency-input
                        [(value)]="report.damageCalculation.provisioningCosts"
                        (valueChange)="saveReport()"
                        placeholder="Vorhaltekosten pro Tag"></currency-input>
                </ng-container>
                <!--============================================-->
                <!-- END Provisioning Costs -->
                <!--============================================-->

                <!--********** DAT Downtime warning **********-->
                <context-sensitive-downtime-notification
                    childClass="margin-bottom-10"
                    *ngIf="report.car.identificationProvider === 'dat'"
                    [processContext]="'rentalCarPrices'"></context-sensitive-downtime-notification>
            </ng-container>
            <div id="repair-duration-container">
                <mat-form-field id="repair-duration-input-container" class="mat-block">
                    <input
                        matInput
                        placeholder="Reparaturdauer"
                        [(ngModel)]="report.damageCalculation.downtimeInWorkdaysDueToReparation"
                        (change)="checkRepairDurationFormat(); saveReport()"
                        [disabled]="isReportLocked()" />
                    <span matSuffix>
                        {{ report.damageCalculation.downtimeInWorkdaysDueToReparation === '1' ? 'Tag' : 'Tage' }}
                    </span>
                    <mat-hint *ngIf="repairDurationFormatInvalid" class="warning">
                        Format: "5 Tage" oder "2-3 Tage"
                    </mat-hint>
                </mat-form-field>
                <mat-icon
                    id="repair-duration-calculator-icon"
                    class="action-icon outlined"
                    *ngIf="
                        (report.damageCalculation.repair.garageLaborHours ||
                            report.damageCalculation.repair.lacquerLaborHours) &&
                        !isReportLocked()
                    "
                    (click)="calculateRepairDuration()"
                    [matTooltip]="getRepairDurationTooltip()">
                    timer
                </mat-icon>
            </div>
            <mat-form-field
                id="replacement-duration-input-container"
                class="mat-block"
                *ngIf="report.type !== 'shortAssessment'">
                <input
                    matInput
                    placeholder="Wiederbeschaffungsdauer"
                    [(ngModel)]="report.damageCalculation.replacementTimeInWorkdays"
                    (change)="checkReplacementTimeFormat(); saveReport()"
                    [disabled]="isReportLocked()" />
                <span matSuffix>{{ report.damageCalculation.replacementTimeInWorkdays === '1' ? 'Tag' : 'Tage' }}</span>
                <mat-hint *ngIf="replacementTimeFormatInvalid" class="warning">
                    Format: "5 Tage" oder "2-3 Tage"
                </mat-hint>
            </mat-form-field>

            <!--============================================-->
            <!-- Custom Fields -->
            <!--============================================-->
            <custom-field-display
                fieldLocation="damage-calculation_downtime-compensation"
                [report]="report"
                (reportChange)="saveReport()"
                [disabled]="isReportLocked()"></custom-field-display>
            <!--============================================-->
            <!-- END Custom Fields -->
            <!--============================================-->
        </div>
        <!--***********************************************
        ** END Downtime Compensation
        ************************************************-->

        <!--============================================-->
        <!-- Residual Value - Valuation -->
        <!--============================================-->
        <div
            id="residual-value-analysis-container-valuation"
            class="card card-with-padding"
            *ngIf="report.type === 'leaseReturn'">
            <h2>Restwert</h2>
            <residual-value-overview
                [report]="report"
                [user]="user"
                [team]="team"
                [showValuationResult]="true"
                (residualValueChange)="determineDamageType(report, userPreferences)"
                (setVehicleValue)="setVehicleValue($event)"></residual-value-overview>
        </div>
    </div>

    <!-------------- Right column -->
    <div
        id="replacement-column"
        class="one-third-data-column"
        *ngIf="!['valuation', 'shortAssessment', 'invoiceAudit'].includes(report.type)">
        <!--============================================-->
        <!-- Residual Value - Damage -->
        <!--============================================-->
        <div
            id="residual-value-analysis-container-damage-calculation"
            class="card card-with-padding"
            *ngIf="report.type !== 'leaseReturn' && report.type !== 'valuation'">
            <h2>Restwert</h2>
            <residual-value-overview
                [report]="report"
                [user]="user"
                [team]="team"
                (residualValueChange)="determineDamageType(report, userPreferences)"
                (setVehicleValue)="setVehicleValue($event)"></residual-value-overview>
        </div>
        <!--============================================-->
        <!-- Vehicle Value -->
        <!--============================================-->
        <div id="vehicle-value-card" class="card card-with-padding" *ngIf="report.type !== 'valuation'">
            <h2>Fahrzeugwert</h2>

            <!--********** Toolbar **********-->
            <div class="card-toolbar">
                <mat-icon *ngIf="!isReportLocked()" class="toolbar-icon" [matMenuTriggerFor]="vehicleValueSubmenu">
                    more_vert
                </mat-icon>
                <mat-menu #vehicleValueSubmenu>
                    <mat-option (click)="toggleValueIncreaseInput()">
                        Wertverbesserung {{ userPreferences.valueIncreaseShown ? 'verbergen' : 'anzeigen' }}
                    </mat-option>
                    <mat-option (click)="toggleTechnicalDiminishedValueInput()">
                        Technischen Minderwert
                        {{ userPreferences.technicalDiminishedValueShown ? 'verbergen' : 'anzeigen' }}
                    </mat-option>
                </mat-menu>
            </div>

            <!--============================================-->
            <!-- Replacement Value -->
            <!--============================================-->
            <div class="input-with-external-icon-container" *ngIf="reportRequiresReplacementValue()">
                <div class="value-and-tax-container">
                    <currency-input
                        class="mat-block flex-grow"
                        placeholder="Wiederbeschaffungswert"
                        [(value)]="report.valuation.vehicleValueGross"
                        (change)="saveReport()"
                        (valueChange)="
                            setTaxationTypeIfNotSet();
                            determineDamageType(report, userPreferences);
                            calculateVehicleValueNetFromGross()
                        "
                        [disabled]="isReportLocked() || replacementValueCorrectionExists(report)"
                        (mousedown)="$event.shiftKey ? openReplacementValueCorrectionDialog() : null"
                        [matTooltip]="
                            replacementValueCorrectionExists(report)
                                ? 'Der Wiederbeschaffungswert (brutto) wurde aus dem Fahrzeuggrundwert (brutto) und den eingetragenen Ab- und Aufwertungen berechnet. Der Grundwert kann im Dialog &quot;Wertkorrektur&quot; bearbeitet werden.'
                                : 'Wiederbeschaffungswert (brutto)'
                        ">
                        <currency-input-prefix
                            #currencyInputPrefix
                            *ngIf="replacementValueCorrectionExists(report)"
                            [label]="Math.abs(replacementValueCorrectionsSum()) | convertToEuro: 2 : true"
                            [color]="replacementValueCorrectionsSum() > 0 ? 'green' : 'red'"
                            [iconName]="
                                currencyInputPrefix.color === 'red' ? 'arrow_cool_down' : 'arrow_warm_up'
                            "></currency-input-prefix>

                        <currency-input-display-value
                            *ngIf="replacementValueCorrectionExists(report)"
                            [label]="report.valuation.vehicleValueGross | convertToEuro"
                            iconName="edit"
                            (iconWasClicked)="openReplacementValueCorrectionDialog(true)"
                            (wasDoubleClicked)="
                                openReplacementValueCorrectionDialog(true)
                            "></currency-input-display-value>
                    </currency-input>
                    <mat-form-field class="taxation-type mat-block">
                        <mat-select
                            [(ngModel)]="report.valuation.taxationType"
                            (selectionChange)="
                                calculateVehicleValueNetFromGross();
                                determineDamageType(report, userPreferences);
                                saveReport()
                            "
                            placeholder="Steuersatz"
                            matTooltip="im WBW enthaltene Mehrwertsteuer"
                            [disabled]="isReportLocked()">
                            <mat-select-trigger [ngSwitch]="report.valuation.taxationType">
                                <span *ngSwitchCase="'full'">Regelsatz</span>
                                <span *ngSwitchCase="'full_16'">16%</span>
                                <span *ngSwitchCase="'margin'">Differenz - 2,4%</span>
                                <span *ngSwitchCase="'margin_reduced'">Differenz - 2%</span>
                                <span *ngSwitchCase="'neutral'">Neutral</span>
                            </mat-select-trigger>
                            <mat-option
                                value="full"
                                matTooltip="Neue oder junge Fahrzeuge, für die überwiegend MwSt. ausgewiesen werden kann (Verkäufer sind Hersteller, Händler, Gewerbetreibende).">
                                Regelsatz - 19 %
                            </mat-option>
                            <mat-option
                                value="margin"
                                matTooltip="Mittelalte Fahrzeuge, die überwiegend im Gebrauchtwagenhandel verfügbar sind. Typisches Fahrzeugalter: 3 bis 10 Jahre.">
                                Differenz - 2,4 %
                            </mat-option>
                            <mat-option
                                value="neutral"
                                matTooltip="Ältere Fahrzeuge, die überwiegend auf dem Privatmarkt gehandelt werden. MwSt. kann nicht ausgewiesen werden.">
                                Neutral - 0 %
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
                <mat-icon
                    class="input-external-icon show-icon-on-card-hover"
                    *ngIf="!isReportLocked()"
                    [matMenuTriggerFor]="replacementValueSubmenu">
                    more_vert
                </mat-icon>
                <mat-menu #replacementValueSubmenu>
                    <a
                        mat-menu-item
                        class="menu-item-with-icon"
                        matTooltip="Wiederbeschaffungswert ab- oder aufwerten. {{
                            '\n\n'
                        }}Alternativ: Umschalt ⇧ + Klick im Eingabefeld &quot;Wiederbeschafungswert&quot;."
                        (click)="openReplacementValueCorrectionDialog()">
                        <mat-icon>arrow_cool_down</mat-icon>
                        Wertkorrektur
                    </a>
                    <a
                        mat-menu-item
                        class="menu-item-with-icon"
                        matTooltip="Kommentar Wiederbeschaffungswert"
                        (click)="toggleReplacementValueRemark(); focusReplacementValueRemark()">
                        <mat-icon class="outlined">chat_bubble</mat-icon>
                        Kommentar
                    </a>
                </mat-menu>
            </div>

            <!--============================================-->
            <!-- END Replacement Value -->
            <!--============================================-->

            <!--============================================-->
            <!-- Vehicle Value -->
            <!--============================================-->
            <div class="input-with-external-icon-container" *ngIf="reportRequiresTimeValue()">
                <!--********** Vehicle Value Type **********-->
                <mat-form-field class="mat-block">
                    <mat-select
                        [(ngModel)]="report.valuation.vehicleValueType"
                        placeholder="Wertangabe"
                        (selectionChange)="saveReport()"
                        [disabled]="isReportLocked()">
                        <mat-option value="dealerPurchase" matTooltip="Händler-Einkaufspreis">
                            {{ translateVehicleValueType('dealerPurchase') }}
                        </mat-option>
                        <mat-option value="dealerSales" matTooltip="Händler-Verkaufspreis">
                            {{ translateVehicleValueType('dealerSales') }}
                        </mat-option>
                        <mat-option value="marketValue">
                            {{ translateVehicleValueType('marketValue') }}
                        </mat-option>
                        <mat-option value="presentValue">
                            {{ translateVehicleValueType('presentValue') }}
                        </mat-option>
                        <mat-option value="replacementValue">
                            {{ translateVehicleValueType('replacementValue') }}
                        </mat-option>
                    </mat-select>
                </mat-form-field>

                <div class="value-and-tax-container">
                    <currency-input
                        class="mat-block flex-grow"
                        [placeholder]="translateVehicleValueType(report.valuation.vehicleValueType)"
                        [(value)]="report.valuation.vehicleValueGross"
                        (change)="calculateVehicleValueNetFromGross(); saveReport()"
                        [disabled]="isReportLocked()"
                        [matTooltip]="
                            getValuationPriceLabelTooltip(report.valuation.vehicleValueType)
                        "></currency-input>
                    <mat-form-field class="taxation-type mat-block">
                        <mat-select
                            [(ngModel)]="report.valuation.taxationType"
                            (selectionChange)="calculateVehicleValueNetFromGross(); saveReport()"
                            placeholder="Steuersatz"
                            matTooltip="enthaltene Mehrwertsteuer"
                            [disabled]="isReportLocked()">
                            <mat-select-trigger [ngSwitch]="report.valuation.taxationType">
                                <span *ngSwitchCase="'full'">Regelsatz</span>
                                <span *ngSwitchCase="'margin'">Differenz</span>
                                <span *ngSwitchCase="'neutral'">Neutral</span>
                            </mat-select-trigger>
                            <mat-option
                                value="full"
                                matTooltip="Neue oder junge Fahrzeuge, für die überwiegend MwSt. ausgewiesen werden kann (Verkäufer sind Hersteller, Händler, Gewerbetreibende).">
                                Regelsatz - 19 %
                            </mat-option>
                            <mat-option
                                value="margin"
                                *ngIf="report.type !== 'usedVehicleCheck' && report.type !== 'leaseReturn'"
                                matTooltip="Mittelalte Fahrzeuge, die überwiegend im Gebrauchtwagenhandel verfügbar sind. Typisches Fahrzeugalter: 3 bis 10 Jahre.">
                                Differenz - 2,4 %
                            </mat-option>
                            <mat-option
                                value="neutral"
                                matTooltip="Ältere Fahrzeuge, die überwiegend auf dem Privatmarkt gehandelt werden. MwSt. kann nicht ausgewiesen werden.">
                                Neutral - 0 %
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
                <mat-icon
                    class="input-external-icon-lease-return show-icon-on-card-hover outlined"
                    (click)="toggleReplacementValueRemark(); focusReplacementValueRemark()"
                    *ngIf="!isReportLocked() && report.valuation.vehicleValueType === 'replacementValue'"
                    matTooltip="Kommentar Wiederbeschaffungswert">
                    chat_bubble
                </mat-icon>
            </div>
            <!--============================================-->
            <!-- END Vehicle Value -->
            <!--============================================-->

            <!--********** Remarks **********-->
            <mat-form-field class="mat-block" *ngIf="replacementValueRemarkShown">
                <mat-quill
                    [(ngModel)]="report.valuation.vehicleValueRemark"
                    placeholder="Kommentar Wiederbeschaffungswert"
                    [disabled]="isReportLocked()"
                    #replacementValueRemark
                    (change)="saveReport()"></mat-quill>
                <mat-icon
                    matSuffix
                    *ngIf="!isReportLocked()"
                    class="input-toolbar-icon"
                    (click)="replacementValueTextTemplateSelectorShown = true"
                    matTooltip="Textvorlagen">
                    playlist_add
                </mat-icon>
            </mat-form-field>
            <text-template-selector
                [(text)]="report.valuation.vehicleValueRemark"
                (textChange)="saveReport()"
                [report]="report"
                *ngIf="replacementValueTextTemplateSelectorShown"
                placeholderForResultingText="Kommentar Wiederbeschaffungswert"
                (close)="replacementValueTextTemplateSelectorShown = false"
                [textTemplateType]="'replacementValueRemark'"
                @runChildAnimations></text-template-selector>

            <ng-container *ngIf="report.type !== 'leaseReturn' && report.type !== 'usedVehicleCheck'">
                <!--********** Residual Value **********-->

                <div class="input-with-external-icon-container">
                    <currency-input
                        id="residual-value"
                        class="mat-block"
                        placeholder="Restwert"
                        [(value)]="report.valuation.residualValue"
                        (change)="saveReport()"
                        (valueChange)="determineDamageType(report, userPreferences)"
                        matTooltip="Restwert (inkl. eventueller MwSt.){{
                            '\n\n'
                        }}Ob die MwSt. ausweisbar ist, richtet sich nach der Vorsteuerabzugsberechtigung des Fahrzeugeigentümers, nicht etwa nach dem Restwertbieter.{{
                            '\n\n'
                        }}Für mehr Infos suche online nach 'autoiXpert Restwert Besteuerung'."
                        [disabled]="isReportLocked()"></currency-input>
                    <mat-icon
                        class="input-external-icon show-icon-on-card-hover outlined"
                        (click)="toggleResidualValueRemark(); focusResidualValueRemark()"
                        *ngIf="!isReportLocked()"
                        matTooltip="Kommentar zum Restwert">
                        chat_bubble
                    </mat-icon>
                </div>

                <mat-form-field class="mat-block" *ngIf="residualValueRemarkShown">
                    <mat-quill
                        [(ngModel)]="report.valuation.residualValueRemark"
                        placeholder="Kommentar Restwert"
                        [disabled]="isReportLocked()"
                        #residualValueRemark
                        (change)="saveReport()"></mat-quill>
                    <mat-icon
                        matSuffix
                        *ngIf="!isReportLocked()"
                        class="input-toolbar-icon"
                        (click)="residualValueTextTemplateSelectorShown = true"
                        matTooltip="Textvorlagen">
                        playlist_add
                    </mat-icon>
                </mat-form-field>

                <text-template-selector
                    [(text)]="report.valuation.residualValueRemark"
                    (textChange)="saveReport()"
                    [report]="report"
                    *ngIf="residualValueTextTemplateSelectorShown"
                    placeholderForResultingText="Kommentar Restwert"
                    (close)="residualValueTextTemplateSelectorShown = false"
                    [textTemplateType]="'residualValueRemark'"
                    @runChildAnimations></text-template-selector>

                <div id="diminished-value-container">
                    <currency-input
                        id="reduced-market-value"
                        class="mat-block"
                        [placeholder]="
                            userPreferences.technicalDiminishedValueShown ? 'Merkantiler Minderwert' : 'Minderwert'
                        "
                        [(value)]="report.valuation.diminishedValue"
                        (change)="saveReport()"
                        (valueChange)="
                            determineDamageType(report, userPreferences); deactivateDiminishedValueProtocolIfNecessary()
                        "
                        matTooltip="Minderwert (steuerneutral)"
                        [disabled]="isReportLocked()"></currency-input>
                    <div
                        class="diminished-value-warning-container"
                        *ngIf="report.valuation.diminishedValue && report.damageCalculation.damageType === 'totalLoss'"
                        matTooltip="Ein Minderwert kann nur anfallen, wenn das Fahrzeug in der Zukunft verkauft werden könnte. Ist das bei diesem Totalschaden der Fall?">
                        <mat-icon class="diminished-value-warning-icon">warning</mat-icon>
                        <span class="label">Totalschaden</span>
                    </div>
                    <mat-icon
                        id="diminished-value-calculator-opener"
                        class="action-icon"
                        (click)="showDiminishedValueCalculator()"
                        matTooltip="Minderwert berechnen">
                        trending_down
                    </mat-icon>
                </div>

                <div
                    class="info-note warning-note margin-bottom-15"
                    *ngIf="diminishedValueWarningNotificationShown"
                    @fadeInAndOut>
                    <mat-icon>info</mat-icon>
                    <div>
                        <h3>Minderwert</h3>
                        <div>
                            Die aktualisierte Kalkulation könnte auch den Minderwert beeinflussen.
                            <a class="cursor-pointer" (click)="showDiminishedValueCalculator()">
                                Willst du das überprüfen?
                            </a>
                        </div>
                    </div>
                    <mat-icon
                        class="medium-icon cursor-pointer diminished-value-notification-close-icon"
                        (click)="hideDiminishedValueNotification()"
                        matTooltip="Hinweis ausblenden">
                        close
                    </mat-icon>
                </div>

                <!--============================================-->
                <!-- Technical Diminished Value -->
                <!--============================================-->
                <div
                    class="input-with-external-icon-container"
                    *ngIf="report.valuation.technicalDiminishedValue || userPreferences.technicalDiminishedValueShown">
                    <currency-input
                        class="mat-block"
                        placeholder="Technischer Minderwert"
                        [(value)]="report.valuation.technicalDiminishedValue"
                        (change)="saveReport()"
                        (valueChange)="determineDamageType(report, userPreferences)"
                        matTooltip="Wertverlust aufgrund technischer Nachteile nach einer Reparatur. Bei modernen Fahrzeugen aufgrund fortschrittlicher Reparaturmethoden äußerst selten."
                        [disabled]="isReportLocked()"></currency-input>
                    <mat-icon
                        class="input-external-icon show-icon-on-card-hover outlined"
                        (click)="toggleTechnicalDiminishedValueRemark(); focusTechnicalDiminishedValueRemark()"
                        *ngIf="!isReportLocked()"
                        matTooltip="Kommentar zum technischen Minderwert">
                        chat_bubble
                    </mat-icon>
                </div>

                <mat-form-field class="mat-block" *ngIf="technicalDiminishedValueCommentShown">
                    <mat-quill
                        [(ngModel)]="report.valuation.technicalDiminishedValueComment"
                        placeholder="Kommentar technischer Minderwert"
                        [disabled]="isReportLocked()"
                        #technicalDiminishedValueRemark
                        (change)="saveReport()"></mat-quill>
                    <mat-icon
                        matSuffix
                        *ngIf="!isReportLocked()"
                        class="input-toolbar-icon"
                        (click)="technicalDiminishedValueTextTemplatesShown = true"
                        matTooltip="Textvorlagen">
                        playlist_add
                    </mat-icon>
                </mat-form-field>
                <text-template-selector
                    [(text)]="report.valuation.technicalDiminishedValueComment"
                    (textChange)="saveReport()"
                    [report]="report"
                    *ngIf="technicalDiminishedValueTextTemplatesShown"
                    placeholderForResultingText="Kommentar technischer Minderwert"
                    (close)="technicalDiminishedValueTextTemplatesShown = false"
                    [textTemplateType]="'technicalDiminishedValueComment'"
                    @runChildAnimations></text-template-selector>
                <!--============================================-->
                <!-- END Technical Diminished Value -->
                <!--============================================-->

                <!--============================================-->
                <!-- Value Increase -->
                <!--============================================-->
                <div
                    class="input-with-external-icon-container"
                    *ngIf="report.valuation.valueIncrease || userPreferences.valueIncreaseShown">
                    <currency-input
                        class="mat-block"
                        placeholder="Wertverbesserung"
                        [(value)]="report.valuation.valueIncrease"
                        (valueChange)="saveReport()"
                        (click)="handleClickOnValueIncreaseFormField()"
                        [readonly]="!!report.damageCalculation.repair.increasedValue"
                        [matTooltip]="
                            'Wertverbesserung (steuerneutral)\n\nGibt an, um welchen Betrag der Fahrzeugwert durch die Reparatur steigt.' +
                            (report.damageCalculation.repair.increasedValue
                                ? '\n\nDie Wertverbesserung stammt aus der Kalkulation. Falls du sie ändern möchtest, passe sie dort an und importiere die Kalkulation erneut.'
                                : '')
                        "
                        [disabled]="isReportLocked()"></currency-input>
                    <mat-icon
                        id="value-increase-remark-icon"
                        class="input-external-icon show-icon-on-card-hover outlined"
                        (click)="toggleValueIncreaseRemark(); focusValueIncreaseRemark()"
                        *ngIf="!isReportLocked()"
                        matTooltip="Kommentar zur Wertverbesserung">
                        chat_bubble
                    </mat-icon>
                </div>

                <mat-form-field class="mat-block" *ngIf="valueIncreaseRemarkShown">
                    <mat-quill
                        [(ngModel)]="report.valuation.valueIncreaseRemark"
                        placeholder="Kommentar Wertverbesserung"
                        [disabled]="isReportLocked()"
                        #valueIncreaseRemark
                        (change)="saveReport()"></mat-quill>
                    <mat-icon
                        matSuffix
                        *ngIf="!isReportLocked()"
                        class="input-toolbar-icon"
                        (click)="valueIncreaseTextTemplateSelectorShown = true"
                        matTooltip="Textvorlagen">
                        playlist_add
                    </mat-icon>
                </mat-form-field>
                <text-template-selector
                    [(text)]="report.valuation.valueIncreaseRemark"
                    (textChange)="saveReport()"
                    [report]="report"
                    *ngIf="valueIncreaseTextTemplateSelectorShown"
                    placeholderForResultingText="Kommentar Wertverbesserung"
                    (close)="valueIncreaseTextTemplateSelectorShown = false"
                    [textTemplateType]="'valueIncreaseRemark'"
                    @runChildAnimations></text-template-selector>
                <!--============================================-->
                <!-- END Value Increase -->
                <!--============================================-->

                <!--============================================-->
                <!-- Special Costs -->
                <!--============================================-->
                <div style="text-align: center">
                    <button class="flat" (click)="showSpecialCostsDialog()">+ Sonderkosten</button>
                </div>
                <!--============================================-->
                <!-- END Special Costs -->
                <!--============================================-->

                <!--============================================-->
                <!-- Value Summary Graph -->
                <!--============================================-->
                <div
                    id="value-summary-graph"
                    *ngIf="
                        report.damageCalculation.repair.correctedRepairCostsGross &&
                        report.valuation.vehicleValueGross &&
                        getHighestValueInValueSummaryGraph()
                    "
                    @fadeInAndOut>
                    <div class="value-summary-row">
                        <div class="value-summary-label label" [matTooltip]="getGraphTooltipReplacementValue()">
                            WBW
                        </div>
                        <div class="value-summary-graph-bar-container">
                            <div
                                class="value-summary-graph-bar"
                                [style.width]="getReplacementValueBarWidth() + '%'"
                                matTooltip="{{ applicableReplacementValue | number: '1.0-0' }} € {{
                                    this.report.claimant.contactPerson.mayDeductTaxes ? '(netto)' : ''
                                }}"></div>
                        </div>
                    </div>

                    <!--********** Repair Costs + Diminished Value **********-->
                    <div
                        class="value-summary-row"
                        *ngIf="
                            report.damageCalculation.repair.repairCostsGross &&
                            (report.valuation.diminishedValue || report.valuation.technicalDiminishedValue)
                        ">
                        <div
                            class="value-summary-label label"
                            [matTooltip]="getGraphTooltipRepairCostsPlusDiminishedValue()">
                            RK
                            <span *ngIf="report.valuation.diminishedValue || report.valuation.technicalDiminishedValue">
                                +MW
                            </span>
                        </div>
                        <div class="value-summary-graph-bar-container">
                            <div
                                class="value-summary-graph-bar"
                                [style.width]="getRestorationValueBarWidth() + '%'"
                                [ngClass]="{
                                    warning:
                                        restorationValue > applicableReplacementValue &&
                                        restorationValue <= applicableReplacementValue * 1.3,
                                    alert: restorationValue > applicableReplacementValue * 1.3,
                                }"
                                (click)="
                                    openWindow(
                                        'https://wissen.autoixpert.de/hc/de/articles/13546263425426#h_01H9NG9C1Q9M54A8QXQZ2W8Z93'
                                    )
                                "
                                matTooltip="{{ restorationValue | number: '1.0-0' }} € &nbsp;&nbsp;|&nbsp;&nbsp; {{
                                    (restorationValue / applicableReplacementValue) * 100 | number: '1.0-0'
                                }} % des WBW{{ '\n\n' }}{{ getGraphTooltipAdditionAboutRepairCostRatio() }}">
                                <div class="value-summary-graph-percentage label">
                                    {{ (restorationValue / applicableReplacementValue) * 100 | number: '1.0-0' }} %
                                </div>
                            </div>
                        </div>
                    </div>

                    <!--********** Repair Costs **********-->
                    <div class="value-summary-row" *ngIf="report.damageCalculation.repair.correctedRepairCostsGross">
                        <div class="value-summary-label label" [matTooltip]="getGraphTooltipRepairCosts()">RK</div>
                        <div class="value-summary-graph-bar-container">
                            <div
                                class="value-summary-graph-bar"
                                [style.width]="getCorrectedRepairCostsGrossBarWidth() + '%'"
                                [ngClass]="{
                                    warning:
                                        applicableRepairCosts > applicableReplacementValue &&
                                        applicableRepairCosts <= applicableReplacementValue * 1.3,
                                    alert: applicableRepairCosts > applicableReplacementValue * 1.3,
                                }"
                                (click)="
                                    openWindow(
                                        'https://wissen.autoixpert.de/hc/de/articles/13546263425426#h_01H9NG9C1Q9M54A8QXQZ2W8Z93'
                                    )
                                "
                                matTooltip="{{ applicableRepairCosts | number: '1.0-0' }} € &nbsp;&nbsp;|&nbsp;&nbsp; {{
                                    (applicableRepairCosts / applicableReplacementValue) * 100 | number: '1.0-0'
                                }} % des WBW{{ '\n\n' }}{{ getGraphTooltipAdditionAboutRepairCostRatio() }}">
                                <div class="value-summary-graph-percentage label">
                                    {{ (applicableRepairCosts / applicableReplacementValue) * 100 | number: '1.0-0' }}
                                    %
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="value-summary-row" *ngIf="report.valuation.residualValue">
                        <div class="value-summary-label label" [matTooltip]="getGraphTooltipResidualValue()">RW</div>
                        <div class="value-summary-graph-bar-container">
                            <div
                                class="value-summary-graph-bar"
                                [style.width]="getResidualValueBarWidth() + '%'"
                                matTooltip="{{
                                    applicableResidualValue | number: '1.0-0'
                                }} € &nbsp;&nbsp;|&nbsp;&nbsp; {{
                                    (applicableResidualValue / applicableReplacementValue) * 100 | number: '1.0-0'
                                }} % des WBW">
                                <div class="value-summary-graph-percentage label">
                                    {{ (applicableResidualValue / applicableReplacementValue) * 100 | number: '1.0-0' }}
                                    %
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <!--============================================-->
                <!-- END Value Summary Graph -->
                <!--============================================-->
                <div class="input-with-external-icon-container">
                    <mat-form-field id="damage-category" class="mat-block">
                        <mat-select
                            placeholder="Schadensklasse"
                            [(ngModel)]="report.damageCalculation.damageType"
                            (selectionChange)="saveReport()"
                            [disabled]="isReportLocked()"
                            matTooltip="Wird automatisch gesetzt, kann aber überschrieben werden, z. B. bei technischem Totalschaden oder der 130 %-Regelung">
                            <mat-option
                                value="repairableDamage"
                                matTooltipPosition="left"
                                matTooltip="Reparaturkosten ({{
                                    report.claimant.contactPerson.mayDeductTaxes ? 'netto' : 'brutto'
                                }}) geringer als der Wiederbeschaffungswert.">
                                Reparaturschaden
                            </mat-option>
                            <mat-option
                                value="repairableDamageTo130Percent"
                                matTooltipPosition="left"
                                matTooltip="Reparaturkosten ({{
                                    report.claimant.contactPerson.mayDeductTaxes ? 'netto' : 'brutto'
                                }}) zzgl. Minderwert liegen zwischen 100% und 130 % des WBW.{{
                                    '\n\n'
                                }}Der Anspruchsteller hat Anspruch auf eine Reparatur bis zur Kostengrenze von 130 % des WBW.{{
                                    '\n\n'
                                }}Nicht möglich bei fiktiver Abrechnung.">
                                <div class="autocomplete-option-wrapper">
                                    <span>Reparaturschaden auf 130 %</span>

                                    <!--********** Most users don't want the 130% repair damage class to be selected automatically. Therefore, the user must explicitly activate it first.  **********-->
                                    <mat-slide-toggle
                                        class="damage-class-selection-toggle"
                                        [(ngModel)]="userPreferences.include130PercentRuleInAutomaticSelection"
                                        (click)="$event.stopPropagation()"
                                        [ngClass]="{
                                            active: userPreferences.include130PercentRuleInAutomaticSelection,
                                        }"
                                        [disabled]="!user.accessRights.editTextsAndDocumentBuildingBlocks"
                                        [matTooltip]="
                                            getTooltipForInclude130PercentRuleInAutomaticSelection()
                                        "></mat-slide-toggle>
                                </div>
                            </mat-option>
                            <mat-option
                                value="economicTotalLoss"
                                class="option-"
                                matTooltipPosition="left"
                                matTooltip="Reparaturkosten ({{
                                    report.claimant.contactPerson.mayDeductTaxes ? 'netto' : 'brutto'
                                }}) plus Minderwert höher als WBW abzüglich Restwert.{{
                                    '\n\n'
                                }}Für die Versicherung ist es am günstigsten, das Schadenfahrzeug zum Restwert zu veräußern und zu ersetzen. Der Anspruchsteller kann dem Ersatz zustimmen, kann das Fahrzeug aber auch reparieren lassen.{{
                                    '\n\n'
                                }}Bei fiktiver Abrechnung erhält der Anspruchsteller die Differenz aus WBW und Restwert.">
                                <div class="autocomplete-option-wrapper">
                                    <span>Wirtschaftlicher Totalschaden</span>

                                    <!--********** Most users don't want economic total losses to be selected automatically. Therefore, the user must explicitly activate it first.  **********-->
                                    <mat-slide-toggle
                                        class="damage-class-selection-toggle"
                                        [(ngModel)]="userPreferences.includeEconomicTotalLossInAutomaticSelection"
                                        (click)="$event.stopPropagation()"
                                        [ngClass]="{
                                            active: userPreferences.includeEconomicTotalLossInAutomaticSelection,
                                        }"
                                        [disabled]="!user.accessRights.editTextsAndDocumentBuildingBlocks"
                                        [matTooltip]="
                                            getTooltipForIncludeEconomicTotalLossInAutomaticSelection()
                                        "></mat-slide-toggle>
                                </div>
                            </mat-option>
                            <mat-option
                                value="totalLoss"
                                matTooltipPosition="left"
                                matTooltip="Reparaturkosten ({{
                                    report.claimant.contactPerson.mayDeductTaxes ? 'netto' : 'brutto'
                                }}) höher als WBW.{{
                                    '\n\n'
                                }}Außer wenn im Rahmen der 130-%-Regelung reguliert wird, erhält der Anspruchsteller den WBW abzüglich Restwert für den Kauf eines Ersatzfahrzeugs.">
                                Totalschaden
                            </mat-option>
                        </mat-select>
                    </mat-form-field>

                    <mat-icon
                        class="input-external-icon show-icon-on-card-hover outlined"
                        (click)="openHelpcenterArticleOnDamageClass()"
                        matTooltip="Definition der Schadensklassen">
                        help
                    </mat-icon>
                </div>

                <div
                    class="info-note"
                    *ngIf="
                        report.damageCalculation.damageType === 'totalLoss' &&
                        !user?.userInterfaceStates.hideSpecialCostsNotification
                    "
                    @fadeInAndOut>
                    <mat-icon>info</mat-icon>
                    <div>
                        <h3>Sonderkosten</h3>
                        <div>
                            Bei Totalschäden können Kosten für Abmeldung und Restkraftstoff erstattungsfähig sein.
                            <a class="cursor-pointer" (click)="showSpecialCostsDialog()">Sonderkosten öffnen.</a>
                        </div>
                    </div>
                    <mat-icon
                        id="special-cost-notification-close-icon"
                        class="medium-icon cursor-pointer"
                        (click)="closeSpecialCostNotification()"
                        matTooltip="Hinweis dauerhaft ausblenden">
                        close
                    </mat-icon>
                </div>

                <div class="checkbox-group parent-of-an-only-child">
                    <!--********** Notional Settlement **********-->
                    <tri-state-checkbox
                        [(ngModel)]="report.isNotionalSettlement"
                        [disabled]="isReportLocked()"
                        (change)="saveReport()"
                        matTooltip="Bei fiktiver Abrechnung werden dem Kunden die Kosten für die Reparatur ausbezahlt, das Fahrzeug wird nicht repariert. Die Entscheidung trifft der Anspruchsteller oder Fahrzeughalter.">
                        Fiktive Abrechnung
                    </tri-state-checkbox>
                </div>

                <div
                    class="info-note warning-note"
                    *ngIf="
                        report.isNotionalSettlement &&
                        report.damageCalculation.damageType === 'repairableDamageTo130Percent'
                    ">
                    <mat-icon>warning</mat-icon>
                    Sicher? 130%-Regelung und fiktive Abrechnung widersprechen sich.
                </div>
            </ng-container>

            <!--============================================-->
            <!-- Custom Fields -->
            <!--============================================-->
            <custom-field-display
                fieldLocation="damage-calculation_vehicle-value"
                [report]="report"
                (reportChange)="saveReport()"
                [disabled]="isReportLocked()"></custom-field-display>
            <!--============================================-->
            <!-- END Custom Fields -->
            <!--============================================-->
        </div>
        <!--============================================-->
        <!-- END Vehicle Value -->
        <!--============================================-->

        <!--============================================-->
        <!-- Repair -->
        <!--============================================-->
        <!--********** In order to show the repair card if the damage type is still null, query for total loss instead of repair damages. **********-->
        <div
            id="repair-card"
            class="card card-with-padding"
            *ngIf="report.type !== 'leaseReturn' && report.type !== 'usedVehicleCheck' && report.type !== 'valuation'">
            <h2>Reparatur</h2>
            <!--============================================-->
            <!-- Axis Measurement -->
            <!--============================================-->
            <div class="input-with-external-icon-container">
                <mat-form-field class="mat-block">
                    <mat-select
                        placeholder="Achsvermessung"
                        [(ngModel)]="report.damageCalculation.repair.axisMeasurement"
                        (selectionChange)="saveReport()"
                        [disabled]="isReportLocked()"
                        matTooltip="Schäden an Rädern und Felgen können darauf hindeuten, dass eine Achsvermessung erforderlich ist.">
                        <mat-option *ngFor="let option of standardAxisMeasurementOptions" [value]="option.value">
                            {{ option.label }}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
                <mat-icon
                    class="input-external-icon show-icon-on-card-hover outlined"
                    (click)="toggleAxisMeasurementRemark(); focusAxisMeasurementRemark()"
                    *ngIf="!isReportLocked()"
                    matTooltip="Kommentar zur Achsvermessung">
                    chat_bubble
                </mat-icon>
            </div>

            <!--********** Axis Measurement Comment **********-->
            <mat-form-field class="mat-block" *ngIf="axisMeasurementCommentShown">
                <mat-quill
                    placeholder="Kommentar Achsvermessung"
                    [(ngModel)]="report.damageCalculation.repair.axisMeasurementComment"
                    (change)="saveReport()"
                    [disabled]="isReportLocked()"
                    #axisMeasurementRemark></mat-quill>
                <div matSuffix *ngIf="!isReportLocked()">
                    <mat-icon
                        class="input-toolbar-icon"
                        matTooltip="Textvorlagen"
                        (click)="axisMeasurementTextTemplatesShown = true">
                        playlist_add
                    </mat-icon>
                </div>
            </mat-form-field>
            <text-template-selector
                [(text)]="report.damageCalculation.repair.axisMeasurementComment"
                (textChange)="saveReport()"
                [report]="report"
                *ngIf="axisMeasurementTextTemplatesShown"
                placeholderForResultingText="Achsvermessung"
                (close)="axisMeasurementTextTemplatesShown = false"
                [textTemplateType]="'axisMeasurement'"
                @runChildAnimations></text-template-selector>
            <!--============================================-->
            <!-- END Axis Measurement -->
            <!--============================================-->

            <!--============================================-->
            <!-- Car Body Measurement -->
            <!--============================================-->
            <div class="input-with-external-icon-container">
                <mat-form-field class="mat-block">
                    <mat-select
                        placeholder="Karosserievermessung"
                        [(ngModel)]="report.damageCalculation.repair.carBodyMeasurement"
                        (selectionChange)="saveReport()"
                        [disabled]="isReportLocked()"
                        matTooltip="Karosserievermessungen können offenlegen, ob sich der Stahl der Karosserie verzogen hat, was mit bloßem Auge oft nicht erkennbar ist.">
                        <mat-option *ngFor="let option of standardCarBodyMeasurementOptions" [value]="option.value">
                            {{ option.label }}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
                <mat-icon
                    class="input-external-icon show-icon-on-card-hover outlined"
                    (click)="toggleCarBodyMeasurementRemark(); focusCarBodyMeasurementRemark()"
                    *ngIf="!isReportLocked()"
                    matTooltip="Kommentar zur Karosserievermessung">
                    chat_bubble
                </mat-icon>
            </div>

            <mat-form-field class="mat-block" *ngIf="carBodyMeasurementCommentShown">
                <mat-quill
                    placeholder="Kommentar Karosserievermessung"
                    [(ngModel)]="report.damageCalculation.repair.carBodyMeasurementComment"
                    (change)="saveReport()"
                    [disabled]="isReportLocked()"
                    #carBodyMeasurementRemark></mat-quill>
                <div matSuffix *ngIf="!isReportLocked()">
                    <mat-icon
                        class="input-toolbar-icon"
                        matTooltip="Textvorlagen"
                        (click)="carBodyMeasurementTextTemplatesShown = true">
                        playlist_add
                    </mat-icon>
                </div>
            </mat-form-field>
            <text-template-selector
                [(text)]="report.damageCalculation.repair.carBodyMeasurementComment"
                (textChange)="saveReport()"
                [report]="report"
                *ngIf="carBodyMeasurementTextTemplatesShown"
                placeholderForResultingText="Karosserievermessung"
                (close)="carBodyMeasurementTextTemplatesShown = false"
                [textTemplateType]="'carBodyMeasurement'"
                @runChildAnimations></text-template-selector>
            <!--============================================-->
            <!-- END Car Body Measurement -->
            <!--============================================-->

            <!--============================================-->
            <!-- Battery Check -->
            <!--============================================-->
            <ng-container *ngIf="doesVehicleHaveBatteryElectricEngine(report)">
                <div class="input-with-external-icon-container">
                    <mat-form-field class="mat-block">
                        <mat-select
                            placeholder="Prüfung Hochvoltsystem"
                            [(ngModel)]="report.damageCalculation.repair.highVoltageSystemCheck"
                            (selectionChange)="saveReport()"
                            [disabled]="isReportLocked()"
                            matTooltip="Abhängig von der Beschädigung des Fahrzeugs kann es notwendig sein, das Hochvoltsystem zu prüfen. z. B. auf Leckage des Kühlsystems, auf mechanische Verformung der Batterie oder auf ">
                            <mat-option
                                *ngFor="let option of standardHighVoltageSystemCheckOptions"
                                [value]="option.value">
                                {{ option.label }}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                    <mat-icon
                        class="input-external-icon show-icon-on-card-hover outlined"
                        (click)="toggleHighVoltageSystemCheckRemark(); focusHighVoltageSystemCheckRemark()"
                        *ngIf="!isReportLocked()">
                        chat_bubble
                    </mat-icon>
                </div>

                <!--********** Battery Measurement Comment **********-->
                <mat-form-field class="mat-block" *ngIf="highVoltageSystemCheckCommentShown">
                    <mat-quill
                        placeholder="Kommentar Prüfung Hochvoltsystem"
                        [(ngModel)]="report.damageCalculation.repair.highVoltageSystemCheckComment"
                        (change)="saveReport()"
                        [disabled]="isReportLocked()"
                        #highVoltageSystemCheckRemark></mat-quill>
                    <div matSuffix *ngIf="!isReportLocked()">
                        <mat-icon
                            class="input-toolbar-icon"
                            matTooltip="Textvorlagen"
                            (click)="highVoltageSystemCheckTextTemplatesShown = true">
                            playlist_add
                        </mat-icon>
                    </div>
                </mat-form-field>
                <text-template-selector
                    [(text)]="report.damageCalculation.repair.highVoltageSystemCheckComment"
                    (textChange)="saveReport()"
                    [report]="report"
                    *ngIf="highVoltageSystemCheckTextTemplatesShown"
                    placeholderForResultingText="Prüfung Hochvoltsystem"
                    (close)="highVoltageSystemCheckTextTemplatesShown = false"
                    [textTemplateType]="'highVoltageSystemCheck'"
                    @runChildAnimations></text-template-selector>
            </ng-container>
            <!--============================================-->
            <!-- END Battery Check -->
            <!--============================================-->

            <!--============================================-->
            <!-- Plastic Repair -->
            <!--============================================-->
            <div class="checkbox-group">
                <mat-checkbox
                    [(ngModel)]="report.damageCalculation.repair.plasticRepairRequired"
                    (change)="saveReport()"
                    [disabled]="isReportLocked()"
                    matTooltip="Beschädigte Bauteile aus Kunststoff können instandgesetzt werden">
                    Kunststoffreparatur
                </mat-checkbox>
            </div>
            <!--============================================-->
            <!-- END Plastic Repair -->
            <!--============================================-->

            <!--============================================-->
            <!-- Paint Blending -->
            <!--============================================-->
            <div class="checkbox-group">
                <mat-checkbox
                    [(ngModel)]="report.damageCalculation.repair.paintBlendingRequired"
                    (change)="saveReport()"
                    [disabled]="isReportLocked()"
                    [matTooltip]="
                        'Manche Lacke können eine Lackierung der angrenzenden Teile erfordern, um optische Unterschiede zwischen originalen und reparierten Teilen zu verringern.' +
                        (report.car.paintType
                            ? '\n\nLacktyp: ' +
                              (report.car.paintType || 'unbekannt') +
                              '\nFarbe: ' +
                              (report.car.paintColor || 'unbekannt') +
                              (report.car.paintColorCode ? ' (' + report.car.paintColorCode + ')' : '') +
                              '\nZustand: ' +
                              (report.car.paintCondition || 'unbekannt')
                            : '')
                    ">
                    Beilackierung
                </mat-checkbox>
            </div>
            <mat-form-field
                class="repair-comment-container mat-block"
                *ngIf="report.damageCalculation.repair.paintBlendingRequired">
                <mat-quill
                    placeholder="Kommentar Beilackierung"
                    [(ngModel)]="report.damageCalculation.repair.paintBlendingComment"
                    (change)="saveReport()"
                    [disabled]="isReportLocked()"></mat-quill>
                <div matSuffix *ngIf="!isReportLocked()">
                    <mat-icon
                        class="input-toolbar-icon"
                        matTooltip="Textvorlagen"
                        (click)="paintBlendingTextTemplatesShown = true">
                        playlist_add
                    </mat-icon>
                </div>
            </mat-form-field>
            <text-template-selector
                [(text)]="report.damageCalculation.repair.paintBlendingComment"
                (textChange)="saveReport()"
                [report]="report"
                *ngIf="paintBlendingTextTemplatesShown"
                placeholderForResultingText="Beilackierung"
                (close)="paintBlendingTextTemplatesShown = false"
                [textTemplateType]="'paintBlending'"
                @runChildAnimations></text-template-selector>
            <!--============================================-->
            <!-- END Paint Blending -->
            <!--============================================-->

            <!--============================================-->
            <!-- Repair Instructions -->
            <!--============================================-->
            <mat-form-field class="mat-block">
                <mat-quill
                    placeholder="Reparaturweg"
                    (change)="saveReport()"
                    [(ngModel)]="report.damageCalculation.repair.repairInstructions"
                    [disabled]="isReportLocked()"></mat-quill>
                <div matSuffix *ngIf="!isReportLocked()" class="align-center">
                    <!--********** Generate Based on Photos or Damage Calculation **********-->
                    <mat-icon
                        class="input-toolbar-icon medium-icon"
                        #generateRepairInstructionsMenuTrigger="matMenuTrigger"
                        [matMenuTriggerFor]="repairInstructionsSubmenu">
                        auto_fix_high
                    </mat-icon>
                    <mat-icon
                        class="input-toolbar-icon"
                        (click)="repairInstructionsTextTemplatesShown = true"
                        matTooltip="Textvorlagen">
                        playlist_add
                    </mat-icon>
                </div>
            </mat-form-field>

            <mat-menu #repairInstructionsSubmenu="matMenu">
                <!--********** Generate Based on Calculation **********-->
                <a
                    mat-menu-item
                    [ngClass]="{ disabled: !report.damageCalculation?.repair?.damagedParts?.length }"
                    class="menu-item-with-icon"
                    matTooltip="Mit Daten aus Schadenkalkulation vorbefüllen, um dir die Ausformulierung zu erleichtern.{{
                        '\n\n'
                    }}Erstellt eine Liste aus den Ersatzteilen und Reparaturarbeiten der Kalkulation. Du kannst konfigurieren, welche Inhalte in die Schadenbeschreibung übernommen werden. {{
                        report.damageCalculation?.repair?.calculationProvider !== 'audatex' &&
                        report.damageCalculation?.repair?.calculationProvider !== 'dat'
                            ? '\n\nDiese Funktion ist nur in Verbindung mit einer DAT- oder Audatex-Kalkulation verfügbar. Erstelle eine Kalkulation mit einem der beiden Anbieter.'
                            : !report.damageCalculation?.repair?.damagedParts?.length
                              ? '\n\nDie Kalkulation enthält keine Ersatzteile und Arbeitspositionen. Führe die Kalkulation durch oder importiere die Kalkulation erneut.'
                              : ''
                    }}"
                    (click)="generateRepairInstructionsFromCalculation()">
                    <mat-icon>build</mat-icon>
                    <span style="flex: 1">Aus Kalkulation</span>
                    <mat-icon
                        class="repair-instruction-configuration-icon"
                        (click)="
                            $event.stopPropagation();
                            generateRepairInstructionsMenuTrigger.closeMenu();
                            openRepairInstructionsFromCalculationConfigurationDialog()
                        ">
                        settings
                    </mat-icon>
                </a>
            </mat-menu>
            <text-template-selector
                [(text)]="report.damageCalculation.repair.repairInstructions"
                (textChange)="saveReport()"
                [report]="report"
                *ngIf="repairInstructionsTextTemplatesShown"
                placeholderForResultingText="Reparaturweg"
                (close)="repairInstructionsTextTemplatesShown = false"
                [textTemplateType]="'repairInstructions'"
                @runChildAnimations></text-template-selector>
            <!--============================================-->
            <!-- END Repair Instructions -->
            <!--============================================-->

            <!--============================================-->
            <!-- Repair Risks -->
            <!--============================================-->
            <div class="input-with-external-icon-container">
                <mat-form-field class="mat-block position-relative">
                    <mat-chip-list #repairRisksChipList>
                        <mat-chip
                            *ngFor="let repairRisk of report.damageCalculation.repair.repairRisks"
                            [removable]="!isReportLocked()"
                            (removed)="removeRepairRisk(repairRisk); saveReport()"
                            [disabled]="isReportLocked()">
                            {{ repairRisk }}
                            <mat-icon matChipRemove *ngIf="!isReportLocked()">close</mat-icon>
                        </mat-chip>
                        <!-- The input should be inside the list or else the chips are rendered below the input. -->
                        <input
                            placeholder="Risiken"
                            #repairRisksInput
                            #repairRisksAutocompleteTrigger="matAutocompleteTrigger"
                            [matAutocomplete]="repairRisksAutocomplete"
                            [matChipInputFor]="repairRisksChipList"
                            (input)="filterRepairRiskAutocomplete(repairRisksInput.value)"
                            (focus)="filterRepairRiskAutocomplete(repairRisksInput.value)"
                            (matChipInputTokenEnd)="enterRepairRisk($event); saveReport()"
                            matChipInputAddOnBlur="true"
                            [disabled]="isReportLocked()" />
                    </mat-chip-list>
                    <mat-autocomplete
                        #repairRisksAutocomplete="matAutocomplete"
                        (mousedown)="$event.preventDefault()"
                        (optionSelected)="
                            selectRepairRiskFromAutocomplete($event, repairRisksInput, repairRisksAutocompleteTrigger);
                            saveReport()
                        ">
                        <mat-option *ngFor="let repairRisk of filteredRepairRisks" [value]="repairRisk">
                            <div class="autocomplete-option-wrapper">
                                <div class="autocomplete-option-label" [matTooltip]="repairRisk">
                                    {{ repairRisk }}
                                </div>
                                <mat-icon
                                    class="toolbar-icon"
                                    *ngIf="isCustomRepairRiskEntry(repairRisk)"
                                    (click)="
                                        removeCustomRiskAutocompleteEntry(repairRisk);
                                        $event.stopPropagation();
                                        saveReport()
                                    ">
                                    delete
                                </mat-icon>
                            </div>
                        </mat-option>
                    </mat-autocomplete>
                </mat-form-field>

                <mat-icon
                    class="input-external-more-vert-icon show-icon-on-card-hover"
                    [matMenuTriggerFor]="repairRisksSubmenu">
                    more_vert
                </mat-icon>
                <mat-menu #repairRisksSubmenu>
                    <a
                        mat-menu-item
                        class="menu-item-with-icon outlined"
                        (click)="toggleRepairRisksRemark(); focusRepairRisksRemark()"
                        matTooltip="Kommentar zu Reparaturrisiken">
                        <mat-icon>chat_bubble</mat-icon>
                        Kommentar einfügen
                    </a>
                    <!--********** Remember Risks as Autocomplete Entries **********-->
                    <a
                        mat-menu-item
                        class="menu-item-with-icon"
                        *ngIf="user?.accessRights.editTextsAndDocumentBuildingBlocks"
                        (click)="
                            rememberCustomRiskAutocompleteEntry(report.damageCalculation.repair.repairRisks);
                            saveReport()
                        "
                        matTooltip="Risiken der Vorschlagsliste hinzufügen für schnellere Auswahl">
                        <mat-icon class="outlined">bookmark_border</mat-icon>
                        Risiken als Vorschläge merken
                    </a>
                </mat-menu>
            </div>

            <mat-form-field class="mat-block" *ngIf="repairRisksCommentShown">
                <mat-quill
                    placeholder="Kommentar Risiken"
                    [(ngModel)]="report.damageCalculation.repair.repairRisksComment"
                    (change)="saveReport()"
                    [disabled]="isReportLocked()"
                    #repairRisksRemark></mat-quill>
                <div matSuffix *ngIf="!isReportLocked()">
                    <mat-icon
                        class="input-toolbar-icon"
                        matTooltip="Textvorlagen"
                        (click)="repairRisksTextTemplatesShown = true">
                        playlist_add
                    </mat-icon>
                </div>
            </mat-form-field>
            <text-template-selector
                [(text)]="report.damageCalculation.repair.repairRisksComment"
                (textChange)="saveReport()"
                [report]="report"
                *ngIf="repairRisksTextTemplatesShown"
                placeholderForResultingText="Reparaturrisiken"
                (close)="repairRisksTextTemplatesShown = false"
                [textTemplateType]="'repairRisksRemark'"
                @runChildAnimations></text-template-selector>
            <!--============================================-->
            <!-- END Repair Risks -->
            <!--============================================-->

            <!--============================================-->
            <!-- Custom Fields -->
            <!--============================================-->
            <custom-field-display
                fieldLocation="damage-calculation_repair"
                [report]="report"
                (reportChange)="saveReport()"
                [disabled]="isReportLocked()"></custom-field-display>
            <!--============================================-->
            <!-- END Custom Fields -->
            <!--============================================-->
        </div>
        <!--============================================-->
        <!-- END Repair -->
        <!--============================================-->

        <!--***********************************************
        ** Old Vehicle Certificate (German "Altfahrzeugzertifikat")
        ************************************************-->
        <div id="old-vehicle-certificate-container" class="card card-with-padding" *ngIf="isAfzzertUserComplete(user)">
            <old-vehicle-certificates-overview
                [report]="report"
                [user]="user"
                [team]="team"></old-vehicle-certificates-overview>
        </div>
        <!--***********************************************
        ** END Old Vehicle Certificate (German "Altfahrzeugzertifikat")
        ************************************************-->
    </div>
</div>

<!--********** Diminished Value Calculator **********-->
<diminished-value-calculator
    *ngIf="diminishedValueCalculatorShown"
    (diminishedValue)="acceptDiminishedValue($event)"
    (close)="hideDiminishedValueCalculator()"
    [report]="report"
    [disabled]="isReportLocked()"></diminished-value-calculator>

<special-costs-dialog
    *ngIf="specialCostsDialogShown"
    [report]="report"
    (close)="hideSpecialCostsDialog()"
    (reportChange)="saveReport()"></special-costs-dialog>

<replacement-value-corrections-dialog
    *ngIf="replacementValueCorrectionsDialogShown"
    (close)="replacementValueCorrectionsDialogShown = false; setTaxationTypeIfNotSet(); saveReport()"
    [report]="report"
    [focusBaseValueInput]="replacementValueCorrectionsDialogFocusInput"
    @runChildAnimations></replacement-value-corrections-dialog>

import { HttpClient, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { DownloadService } from '../download.service';

@Injectable()
export class InvoicesWithReportsService {
    constructor(
        private httpClient: HttpClient,
        private downloadService: DownloadService,
    ) {}

    private apiPath = '/api/v0';

    public getRecords(invoiceIds: string[]): Promise<HttpResponse<Blob>> {
        return this.httpClient
            .post(
                `${this.apiPath}/exports/invoicesWithReports`,
                { invoiceIds },
                {
                    observe: 'response',
                    responseType: 'blob',
                },
            )
            .toPromise();
    }

    public async downloadRecordsAsCsv(invoiceIds: string[]): Promise<HttpResponse<Blob>> {
        const response = await this.getRecords(invoiceIds);
        this.downloadService.downloadBlobResponseWithHeaders(response);

        return response;
    }
}

<!-- Fee set container-->
<div class="customer-signatures-fee-set">
    <!-- First fee table-->
    <table *ngIf="feeTableColumnA?.length" class="customer-signatures-fee-table-1">
        <tr>
            <th rowspan="2" class="text-align-start">
                Schadenhöhe*
                <br />
                bis
            </th>
            <th colSpan="2" class="text-align-center">
                <ng-container *ngIf="config.report.feeCalculation.selectedFeeTable !== 'HUK'">
                    Grundhonorar
                </ng-container>
                <ng-container *ngIf="config.report.feeCalculation.selectedFeeTable === 'HUK'">Endbetrag</ng-container>
            </th>
        </tr>

        <tr>
            <th>ohne MwSt.</th>
            <th>mit MwSt.</th>
        </tr>

        <tr *ngFor="let row of feeTableColumnA">
            <td>{{ row.damageValue }}</td>
            <td>{{ row.feeNet }}</td>
            <td>{{ row.feeGross }}</td>
        </tr>
    </table>

    <!-- Second fee table-->
    <table *ngIf="feeTableColumnB?.length" class="customer-signatures-fee-table-2">
        <tr>
            <th rowspan="2" class="text-align-start">
                Schadenhöhe*
                <br />
                bis
            </th>
            <th colSpan="2" class="text-align-center">
                <ng-container *ngIf="config.report.feeCalculation.selectedFeeTable !== 'HUK'">
                    Grundhonorar
                </ng-container>
                <ng-container *ngIf="config.report.feeCalculation.selectedFeeTable === 'HUK'">Endbetrag</ng-container>
            </th>
        </tr>

        <tr>
            <th>ohne MwSt.</th>
            <th>mit MwSt.</th>
        </tr>

        <tr *ngFor="let row of feeTableColumnB">
            <td>{{ row.damageValue }}</td>
            <td>{{ row.feeNet }}</td>
            <td>{{ row.feeGross }}</td>
        </tr>
    </table>

    <!-- Additional fees list -->
    <div class="customer-signatures-fee-additional" [quillContent]="buildingBlock?.content"></div>
</div>

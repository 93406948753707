<!--============================================-->
<!-- Navigation -->
<!--============================================-->
<nav id="navigation-container" class="card dark-card">
    <div id="navigation-heading-container"><h3>Externe API</h3></div>

    <div id="navigation-list">
        <!--        <a class="navigation-list-item" (click)="scrollIntoView('#api-usage-section')">API-Nutzung</a>-->
        <a class="navigation-list-item" (click)="scrollIntoView('#api-users-section')">Zugangsschlüssel</a>
        <a class="navigation-list-item" (click)="scrollIntoView('#webhooks-section')">Webhooks</a>
        <a class="navigation-list-item" href="https://dev.autoixpert.de" target="_blank" rel="noopener">API-Doku</a>
    </div>
</nav>
<!--============================================-->
<!-- END Navigation -->
<!--============================================-->

<!--============================================-->
<!-- External API Preferences -->
<!--============================================-->
<main id="preferences-container">
    <!--============================================-->
    <!-- Developer Options -->
    <!--============================================-->
    <section id="developer-tools-section" class="card card-with-padding preference-section-container">
        <h2>Entwickleroptionen</h2>

        <div class="display-internal-id-settings-container">
            <div>
                <p>
                    Du kannst dir die internen IDs von Gutachten, Rechnungen, Kontakten, Standorten oder Benutzern
                    anzeigen lassen (und per Klick in die Zwischenablage kopieren). Das hilft dir, Datensätze über die
                    API zu finden.
                    <a
                        target="_blank"
                        rel="noopener"
                        href="https://dev.autoixpert.de/dokumentation/einleitung#entwickleroptionen">
                        Mehr Infos in der Dokumentation
                    </a>
                </p>
                <mat-slide-toggle
                    matTooltip="Zeigt die IDs von Gutachten, Rechnungen, Kontakte, Standorten oder Benutzern an."
                    [(ngModel)]="userPreferences.developerTools_displayIds">
                    <span *ngIf="userPreferences.developerTools_displayIds">ID-Anzeige aktiviert</span>
                    <span *ngIf="!userPreferences.developerTools_displayIds">ID-Anzeige deaktiviert</span>
                </mat-slide-toggle>
            </div>
            <img
                src="assets/images/developer-tools-internal-id.png"
                alt="Developer Tools - Interne ID für Kontakt anzeigen" />
        </div>
    </section>
    <!--============================================-->
    <!-- END Developer Options -->
    <!--============================================-->
    <!--============================================-->
    <!-- API Usage -->
    <!--============================================-->
    <section *ngIf="false" id="api-usage-section" class="card card-with-padding preference-section-container">
        <h2>API-Nutzung</h2>
        <external-api-usage></external-api-usage>
    </section>
    <!--============================================-->
    <!-- END API Usage -->
    <!--============================================-->

    <!--============================================-->
    <!-- API Users -->
    <!--============================================-->
    <section id="api-users-section" class="card card-with-padding preference-section-container">
        <h2>Zugangsschlüssel</h2>
        <external-api-users></external-api-users>
    </section>
    <!--============================================-->
    <!-- END API Users -->
    <!--============================================-->

    <!--============================================-->
    <!-- Webhooks -->
    <!--============================================-->
    <section id="webhooks-section" class="card card-with-padding preference-section-container">
        <h2>Webhooks</h2>
        <team-webhooks></team-webhooks>
    </section>
    <!--============================================-->
    <!-- END Webhooks -->
    <!--============================================-->
</main>
<!--============================================-->
<!-- END External API Preferences -->
<!--============================================-->

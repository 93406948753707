import { DocumentTypeGroupName } from '../documents/get-document-type-groups-from-document-order-configs';

export type DocumentTypeGroupNameGerman =
    | 'Gutachten'
    | 'Gutachten-Anhang'
    | 'Rechnung'
    | 'Unterschrift'
    | 'Sonstige'
    | 'Unbekannter Dokumentgruppentyp';

export function translateDocumentTypeGroupToGerman(
    documentTypeGroup: DocumentTypeGroupName,
): DocumentTypeGroupNameGerman {
    const dictionary: { [key in DocumentTypeGroupName]: DocumentTypeGroupNameGerman } = {
        report: 'Gutachten',
        reportAttachment: 'Gutachten-Anhang',
        invoice: 'Rechnung',
        signature: 'Unterschrift',
        other: 'Sonstige',
    };

    let translatedDocumentGroupType = dictionary[documentTypeGroup];

    if (!translatedDocumentGroupType) {
        console.error('No document type group translation found. Please add a document group translation.', {
            documentTypeGroup,
        });
        translatedDocumentGroupType = 'Unbekannter Dokumentgruppentyp';
    }

    return translatedDocumentGroupType;
}

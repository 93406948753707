<div
    id="three-columns-layout-container"
    [ngClass]="{ 'justify-content-center': report.type === 'oldtimerValuationSmall' }"
    *ngIf="report">
    <!--============================================-->
    <!-- Valuation Partners -->
    <!--============================================-->
    <div class="one-third-data-column card card-with-padding" id="external-valuation-container">
        <div class="card-submenu" *ngIf="report.valuation.valuationProvider === 'classicAnalytics'">
            <mat-icon [matMenuTriggerFor]="externalValuationSubMenu">more_vert</mat-icon>
            <mat-menu #externalValuationSubMenu="matMenu">
                <a mat-menu-item (click)="downloadExternalValuationPdf()">PDF anzeigen</a>
                <a mat-menu-item (click)="!isReportLocked() && removeExternalValuation()" [disabled]="isReportLocked()">
                    PDF löschen
                </a>
            </mat-menu>
        </div>

        <h2>Externe Bewertung</h2>
        <div id="valuation-source-label" class="label">
            Optional: Bewertungs-PDF hochladen
            <span
                class="help-indicator"
                matTooltip="Wenn keine PDF-Datei hochgeladen wird, generiert autoiXpert anhand der angegebenen Noten eine eigene Zustandsübersicht.">
                ?
            </span>
        </div>

        <!--********** Providers **********-->
        <div id="valuation-providers-container">
            <!--            <div id="own-research" class="valuation-provider">Eigene Recherche</div>-->
            <!--============================================-->
            <!-- Classic Analytics Button and Drop Zone -->
            <!--============================================-->
            <div
                id="valuation-provider-classic-analytics"
                class="valuation-provider drop-zone"
                ng2FileDrop
                [class.file-over]="isFileOverAudatexDropZone"
                [class.file-over-body]="isFileOverBody"
                (fileOver)="onFileOverClassicAnalyticsDropZone($event)"
                (onFileDrop)="onFileDrop($event)"
                [uploader]="uploader"
                (click)="!isReportLocked() && fileInput.click()">
                <img src="/assets/images/logos/classic-analytics.png" alt="" />
                <div
                    id="classicanalytics-pdf-status-indicator-container"
                    *ngIf="
                        report.valuation.valuationProvider === 'classicAnalytics' || classicanalyticsPdfUploadPending
                    "
                    @fadeInAndOut>
                    <mat-icon
                        id="classicanalytics-pdf-uploaded-indicator"
                        matTooltip="Es liegt eine PDF-Datei vor."
                        *ngIf="!classicanalyticsPdfUploadPending">
                        check
                    </mat-icon>
                    <img
                        id="pdf-upload-loader"
                        src="/assets/images/icons/loading-indicator.svg"
                        alt=""
                        *ngIf="classicanalyticsPdfUploadPending" />
                </div>
            </div>
            <input
                style="display: none"
                type="file"
                ng2FileSelect
                [uploader]="uploader"
                (change)="onFileDrop($any($event.currentTarget).files)"
                #fileInput
                accept="application/pdf" />
            <!--============================================-->
            <!-- END Classic Analytics Button and Drop Zone -->
            <!--============================================-->
        </div>

        <!--============================================-->
        <!-- Warning: Photos & Grades not Printed -->
        <!--============================================-->
        <div class="info-note margin-top-30" *ngIf="report.valuation.valuationProvider === 'classicAnalytics'">
            <mat-icon class="outlined">info</mat-icon>
            <div>
                <h3>Notenskala wird nicht gedruckt</h3>
                <p>Damit verwendest du nun die Benotung von Classic Analytics.</p>
                <p>
                    Um die Skala aus {{ getProductName() }} zu drucken, lade die Classic Analytics-PDF nicht hier,
                    sondern im Reiter
                    <i>Druck & Versand</i>
                    als Anhang hoch.
                </p>
            </div>
        </div>
        <!--============================================-->
        <!-- END Warning: Photos & Grades not Printed -->
        <!--============================================-->
    </div>
    <!--============================================-->
    <!-- END Valuation Partners -->
    <!--============================================-->

    <!--============================================-->
    <!-- Vehicle Value -->
    <!--============================================-->
    <div class="one-third-data-column card card-with-padding">
        <h2>Fahrzeugwert</h2>
        <currency-input
            [(value)]="report.valuation.vehicleValueGross"
            (valueChange)="saveReport()"
            placeholder="Marktwert"
            [disabled]="isReportLocked()"
            matTooltip="Durchschnittspreis bei An- oder Verkauf. Enthält keine MwSt & Händlergewinnspannen. Grundlage für Versicherungseinstufung."></currency-input>
        <currency-input
            [(value)]="report.valuation.secondVehicleValueGross"
            (valueChange)="saveReport()"
            placeholder="Wiederbeschaffungswert"
            [disabled]="isReportLocked()"
            matTooltip="Kaufpreis bei gewerblichem Händler. Enthält MwSt & Händlergewinnspannen. Grundlage für Abwicklung von Haftpflichtschäden."></currency-input>

        <div *ngIf="restorationValueControlsShown">
            <h4>Wiederherstellungswert</h4>

            <div id="restoration-value-input-row">
                <currency-input
                    [(value)]="report.valuation.restorationBaseVehicleValue"
                    (valueChange)="updateRestorationValue()"
                    placeholder="Basisfahrzeugwert"
                    [disabled]="isReportLocked()"
                    matTooltip="Kosten zur Beschaffung des Basisfahrzeugs (Kaufpreis)."></currency-input>
                <currency-input
                    [(value)]="report.valuation.restorationCosts"
                    (valueChange)="updateRestorationValue()"
                    placeholder="Wiederherstellungskosten"
                    [disabled]="isReportLocked()"
                    matTooltip="Summe des Restaurationskosten (Beschaffung und Anfertigung der Teile, sowie Arbeitskosten)."></currency-input>
            </div>

            <div id="restoration-value-result" *ngIf="report.valuation.restorationValue" @slideInAndOutVertically>
                <h3
                    class="restoration-value-label"
                    matTooltip="Wiederherstellungswert (Wiederaufbauwert) berechnet aus Kaufpreis und Restaurationskosten. Rein rechnerischer Wert, der vom Marktpreis teils stark abweichen kann.">
                    Summe
                </h3>
                <span id="restoration-value-equal-sign" class="restoration-value-label">=</span>
                <span id="restoration-value">{{ report.valuation.restorationValue | convertToEuro }}</span>
            </div>
        </div>

        <!--********** Restoration Value **********-->
        <div id="show-restoration-value-button-container">
            <button *ngIf="!restorationValueControlsShown" class="flat" (click)="showRestorationData(true)">
                <mat-icon class="button-icon">add</mat-icon>
                Wiederherstellungswert
            </button>
        </div>
    </div>

    <!--============================================-->
    <!-- END Vehicle Value -->
    <!--============================================-->
</div>

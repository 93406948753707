<div id="equipments-search-and-filters-container">
    <!--********** Search **********-->
    <div id="equipments-search-container">
        <mat-form-field class="search-field">
            <mat-icon matPrefix>search</mat-icon>
            <input
                matInput
                placeholder="Suchen"
                [(ngModel)]="equipmentsSearchTerm"
                (ngModelChange)="filterEquipmentPositions()" />
        </mat-form-field>
    </div>

    <!--********** Filters **********-->
    <div id="equipments-filters-container">
        <div
            class="equipment-filter-container"
            [ngClass]="{ selected: visibleEquipmentCategories.includes('series') }"
            (click)="toggleEquipmentCategory('series'); filterEquipmentPositions()"
            matTooltip="Serienausstattungen ein-/ausblenden">
            <mat-icon class="small-icon">check</mat-icon>
            Serie
        </div>

        <div
            class="equipment-filter-container"
            [ngClass]="{ selected: visibleEquipmentCategories.includes('special') }"
            (click)="toggleEquipmentCategory('special'); filterEquipmentPositions()"
            matTooltip="Sonderausstattungen ein-/ausblenden">
            <mat-icon class="small-icon">star</mat-icon>
            Sonder
        </div>

        <div
            class="equipment-filter-container"
            [ngClass]="{ selected: visibleEquipmentCategories.includes('additional') }"
            (click)="toggleEquipmentCategory('additional'); filterEquipmentPositions()"
            matTooltip="Zusatzausstattungen ein-/ausblenden">
            <mat-icon class="small-icon outlined">star</mat-icon>
            Zusatz
        </div>

        <div id="sort-container" class="label" matTooltip="Alle Ausstattungen werden stets alphabetisch sortiert.">
            <mat-icon class="small-icon">swap_vert</mat-icon>
            Alphabetisch
        </div>
    </div>
</div>

<section id="equipment-lists" *ngIf="!equipmentGroupTemplatesShown">
    <div id="equipment-lists-loading-indicator-container" *ngIf="getEquipmentRequestPending">
        <img class="loading-indicator" src="/assets/images/icons/loading-indicator.svg" alt="" />
        Lade Ausstattungen ...
    </div>

    <ng-container *ngIf="!getEquipmentRequestPending">
        <!--============================================-->
        <!-- Available Equipment List -->
        <!--============================================-->
        <div
            *ngIf="possibleEquipmentPositions.length || builtinEquipmentPositions.length"
            class="equipment-list-column">
            <div class="equipment-list-column-heading-container">
                <h3 matTooltip="Beim Hersteller für dieses Modell verfügbare Ausstattungen">Möglich</h3>
                <div class="label number-of-equipments" matTooltip="Anzahl möglicherweise verbauter Ausstattungen">
                    <span
                        *ngIf="filteredPossibleEquipmentPositions.length !== possibleEquipmentPositions.length"
                        matTooltip="Nach Filterung sichtbare Ausstattungen">
                        {{ filteredPossibleEquipmentPositions.length }} von
                    </span>
                    {{ possibleEquipmentPositions.length }}
                </div>
                <div style="flex-grow: 2"></div>
            </div>

            <!--********** List **********-->
            <div
                class="equipment-list"
                cdkDropList
                #availableEquipmentList="cdkDropList"
                [cdkDropListData]="possibleEquipmentPositions"
                [cdkDropListConnectedTo]="[builtinEquipmentList]"
                (cdkDropListDropped)="onDropEquipment($event)">
                <div
                    class="equipment-item"
                    *ngFor="let equipmentItem of filteredPossibleEquipmentPositions"
                    cdkDrag
                    [cdkDragDisabled]="isTouchDevice() || isReportLocked(report)"
                    (dblclick)="moveItemToBuiltinEquipments(equipmentItem)">
                    <div class="equipment-item-description" [matTooltip]="equipmentItem.description">
                        {{ equipmentItem.description }}
                    </div>
                    <mat-icon
                        class="medium-icon toolbar-icon equipment-delete-icon"
                        *ngIf="!equipmentItem.datEquipmentId && !isReportLocked(report)"
                        (click)="deleteEquipment(possibleEquipmentPositions, equipmentItem); saveEquipment()">
                        delete
                    </mat-icon>
                    <mat-icon
                        class="small-icon equipment-type-icon"
                        *ngIf="equipmentItem.type === 'special'"
                        matTooltip="Sonderausstattung">
                        star
                    </mat-icon>
                    <mat-icon
                        class="small-icon equipment-type-icon outlined"
                        *ngIf="equipmentItem.type === 'additional'"
                        matTooltip="Zusatzausstattung">
                        star
                    </mat-icon>
                </div>

                <!--********** No Items Placeholder **********-->
                <div
                    class="no-equipments-placeholder-container"
                    *ngIf="!filteredPossibleEquipmentPositions.length && !isClientOffline()">
                    <div class="no-equipments-placeholder">
                        <h3>Keine verfügbaren Ausstattungen</h3>
                        <div class="label" *ngIf="!possibleEquipmentPositions.length">
                            <ng-container *ngIf="report.car.identificationProvider === 'dat'">
                                Führe eine Fahrzeugidentifikation durch.
                            </ng-container>
                            <ng-container *ngIf="report.car.identificationProvider !== 'dat'">
                                Wenn du vorhandene Ausstattungen entfernst, landen die hier.
                            </ng-container>
                        </div>
                        <div class="label" *ngIf="possibleEquipmentPositions.length">Ändere die Filterkriterien.</div>
                    </div>
                </div>

                <!--********** Offline Placeholder **********-->
                <div
                    class="offline-placeholder-container"
                    *ngIf="isClientOffline() && !possibleEquipmentPositionsFromVehicleIdentification.length">
                    <mat-icon class="medium-icon margin-right-10">cloud_off</mat-icon>
                    <div>
                        <div class="semibold">Offline</div>
                        <div class="label">Daten könnten unvollständig sein</div>
                    </div>
                </div>
            </div>
        </div>
        <!--============================================-->
        <!-- END Available Equipment List -->
        <!--============================================-->

        <!--============================================-->
        <!-- Built-in Equipment List -->
        <!--============================================-->
        <div class="equipment-list-column">
            <div class="equipment-list-column-heading-container heading-and-toolbar-container">
                <h3>Vorhanden</h3>
                <div class="label number-of-equipments" matTooltip="Anzahl vorhandener Ausstattungen">
                    <span
                        *ngIf="filteredBuiltinEquipmentPositions.length !== builtinEquipmentPositions.length"
                        matTooltip="Nach Filterung sichtbare Ausstattungen">
                        {{ filteredBuiltinEquipmentPositions.length }} von
                    </span>
                    {{ builtinEquipmentPositions.length }}
                </div>

                <div style="flex-grow: 2"></div>

                <!--********** Add Equipment Positions **********-->
                <div *ngIf="!isReportLocked(report)" class="split-button add-equipment-positions-button">
                    <!--********** Add single equipment position **********-->
                    <div
                        class="split-button-left"
                        (click)="showNewEquipmentInput()"
                        matTooltip="Einzelne Ausstattung hinzufügen">
                        <mat-icon class="small-icon">add</mat-icon>
                    </div>

                    <!--********** More options **********-->
                    <div class="split-button-right" [matMenuTriggerFor]="addBuiltinEquipmentSubmenu">
                        <mat-icon class="small-icon">arrow_drop_down</mat-icon>
                    </div>
                </div>

                <!--********** Submenu **********-->
                <mat-menu #addBuiltinEquipmentSubmenu>
                    <mat-option (click)="showEquipmentGroupTemplates()">
                        <mat-icon>list_alt</mat-icon>
                        Aus Vorlage einfügen
                    </mat-option>

                    <mat-option
                        (click)="openEquipmentImportDialog()"
                        matTooltip="Du kannst die Ausstattungsliste aus einer E-Mail oder einer Website per Kopieren & Einfügen hier einfügen.">
                        <mat-icon>upload</mat-icon>
                        Aus Liste einfügen
                    </mat-option>
                </mat-menu>
                <div class="heading-toolbar more-options-toolbar">
                    <mat-icon class="toolbar-icon" [matMenuTriggerFor]="builtinEquipmentSubmenu">more_vert</mat-icon>
                    <!--********** Submenu **********-->
                    <mat-menu #builtinEquipmentSubmenu>
                        <mat-option (click)="showEquipmentGroupTemplateTitleDialog()">
                            <mat-icon>playlist_add_check</mat-icon>
                            Als Vorlage speichern
                        </mat-option>

                        <mat-option
                            (click)="copyFilteredEquipmentPositionsToClipboard()"
                            matTooltip="Alle Ausstattungen werden mit einem Komma getrennt in deine Zwischenablage kopiert.">
                            <mat-icon>content_copy</mat-icon>
                            In Zwischenablage kopieren
                        </mat-option>
                    </mat-menu>
                </div>
            </div>

            <!--********** New Item **********-->
            <ng-container *ngIf="newEquipmentItemInputShown">
                <div id="add-equipment-container">
                    <!--********** Description **********-->
                    <autocomplete-with-memory
                        placeholder="Neue Ausstattung"
                        [(ngModel)]="newEquipmentItem.description"
                        (keydown)="handleNewEquipmentInputKeydown($event)"
                        [axAutofocus]="true"
                        [hint]="newEquipmentJustAdded ? 'Position hinzugefügt' : null"
                        autocompleteEntryType="newEquipment"></autocomplete-with-memory>

                    <!--********** Type **********-->
                    <mat-form-field id="new-equipment-type-dropdown">
                        <mat-select [(ngModel)]="newEquipmentItem.type" placeholder="Typ">
                            <mat-select-trigger [ngSwitch]="newEquipmentItem.type">
                                <ng-container *ngSwitchCase="'series'">Serie</ng-container>
                                <ng-container *ngSwitchCase="'special'">Sonder</ng-container>
                                <ng-container *ngSwitchCase="'additional'">Zusatz</ng-container>
                            </mat-select-trigger>
                            <mat-option value="series">Serienausstattung</mat-option>
                            <mat-option value="special">Sonderausstattung</mat-option>
                            <mat-option value="additional">Zusatzausstattung</mat-option>
                        </mat-select>
                    </mat-form-field>
                    <mat-icon
                        class="toolbar-icon"
                        (click)="addNewEquipment(); filterEquipmentPositions()"
                        matTooltip="Hinzufügen">
                        check
                    </mat-icon>
                    <mat-icon class="toolbar-icon" (click)="hideNewEquipmentInput()" matTooltip="Abbrechen">
                        close
                    </mat-icon>
                </div>

                <div
                    id="add-equipment-container-info"
                    class="label"
                    *ngIf="newEquipmentItem.type === 'series' || newEquipmentItem.type === 'special'">
                    Neue Positionen werden von der DAT, Audatex und GT Motive nur als Zusatzausstattung akzeptiert &
                    automatisch als solche übermittelt. Im Fall einer manuellen Kalkulation kann autoiXpert deine
                    Einsortierung aber beibehalten.
                </div>
            </ng-container>

            <!--********** List **********-->
            <div
                *ngIf="builtinEquipmentPositions.length > 0"
                class="equipment-list"
                cdkDropList
                #builtinEquipmentList="cdkDropList"
                [cdkDropListData]="builtinEquipmentPositions"
                [cdkDropListConnectedTo]="[availableEquipmentList]"
                (cdkDropListDropped)="onDropEquipment($event)">
                <div
                    class="equipment-item"
                    *ngFor="let equipmentPosition of filteredBuiltinEquipmentPositions"
                    cdkDrag
                    [cdkDragDisabled]="isTouchDevice() || isReportLocked(report)"
                    (dblclick)="moveItemToPossibleEquipments(equipmentPosition)">
                    <div class="equipment-item-description" [matTooltip]="equipmentPosition.description">
                        {{ equipmentPosition.description }}
                    </div>
                    <mat-icon
                        class="medium-icon toolbar-icon equipment-delete-icon"
                        *ngIf="!equipmentPosition.datEquipmentId && !isReportLocked(report)"
                        (click)="deleteEquipment(builtinEquipmentPositions, equipmentPosition); saveEquipment()"
                        matTooltip="Löschen">
                        delete
                    </mat-icon>
                    <div
                        class="equipment-restriction-icon"
                        *ngIf="equipmentPosition.isDatCalculationOnly"
                        matTooltip="Nur in DAT-Kalkulationen verfügbar.{{
                            '\n\n'
                        }}Nicht in Bewertungen verfügbar, weil die DAT diese Ausstattung als irrelevant für die Bewertung einstuft.">
                        K
                    </div>
                    <div
                        class="equipment-restriction-icon"
                        *ngIf="equipmentPosition.isDatValuationOnly"
                        matTooltip="Nur in DAT-Bewertungen verfügbar.{{
                            '\n\n'
                        }}In Kalkulationen nicht verfügbar, weil die DAT diese Ausstattung nur für die Bewertung als relevant betrachtet.">
                        B
                    </div>
                    <mat-icon
                        class="small-icon equipment-type-icon"
                        *ngIf="equipmentPosition.type === 'special'"
                        matTooltip="Sonderausstattung">
                        star
                    </mat-icon>
                    <mat-icon
                        class="small-icon equipment-type-icon outlined"
                        *ngIf="equipmentPosition.type === 'additional'"
                        matTooltip="Zusatzausstattung">
                        star
                    </mat-icon>
                </div>
                <!--********** No Items Placeholder **********-->
                <div
                    class="no-equipments-placeholder-container"
                    *ngIf="builtinEquipmentPositions.length && !filteredBuiltinEquipmentPositions.length">
                    <div class="no-equipments-placeholder">
                        <h4>Keine Ausstattungen</h4>
                        <div class="label">Ändere die Filterkriterien.</div>
                    </div>
                </div>
            </div>

            <!--********** No Items Placeholder **********-->
            <div class="no-equipments-placeholder" *ngIf="!builtinEquipmentPositions?.length">
                <h4>Keine Ausstattungen</h4>
                <div class="label">
                    In der Regel werden die Ausstattungen über die VIN-Abfrage automatisch eingefügt. Für alle anderen
                    Fälle kannst du dir entweder Vorlagen anlegen oder Ausstattungen per Text einfügen (z. B. von einer
                    Website oder E-Mail).
                </div>
                <div class="no-equipments-buttons">
                    <button
                        class="secondary"
                        (click)="showEquipmentGroupTemplates()"
                        [disabled]="isReportLocked(report)"
                        [class.disabled]="isReportLocked(report)">
                        Vorlage öffnen
                    </button>
                    <button
                        class="secondary"
                        (click)="openEquipmentImportDialog()"
                        [disabled]="isReportLocked(report)"
                        [class.disabled]="isReportLocked(report)">
                        Ausstattung importieren
                    </button>
                </div>
            </div>
        </div>
        <!--============================================-->
        <!-- END Built-in Equipment List -->
        <!--============================================-->
    </ng-container>
</section>

<!--============================================-->
<!-- Equipment Group Templates -->
<!--============================================-->
<section id="equipment-group-templates-section" *ngIf="equipmentGroupTemplatesShown">
    <h3>Vorlagen</h3>
    <div id="equipment-group-templates-list">
        <div
            class="equipment-group-template"
            *ngFor="let equipmentGroupTemplate of equipmentGroupTemplates"
            (click)="selectEquipmentGroupTemplate(equipmentGroupTemplate)"
            (dblclick)="useEquipmentGroupTemplate(equipmentGroupTemplate)"
            [ngClass]="{ selected: selectedEquipmentGroupTemplate === equipmentGroupTemplate }">
            <div class="equipment-group-template-title">
                <span class="equipment-group-template-title-span">{{ equipmentGroupTemplate.title }}</span>
                <div
                    class="label"
                    matTooltip="Erstellt am {{
                        equipmentGroupTemplate.createdAt | moment: 'DD.MM.YYYY - HH:mm [Uhr]'
                    }}">
                    {{ equipmentGroupTemplate.createdAt | moment: 'DD.MM.YYYY' }}
                </div>
            </div>

            <div class="equipment-group-template-length" matTooltip="Anzahl vorhandener Ausstattungen">
                {{ equipmentGroupTemplate.builtinEquipments.length }}
            </div>

            <mat-icon
                class="equipment-group-template-delete-icon"
                (click)="deleteEquipmentGroupTemplate(equipmentGroupTemplate)"
                matTooltip="Vorlage löschen">
                delete
            </mat-icon>
        </div>

        <!--********** No Templates **********-->
        <div id="no-equipment-templates-placeholder-container" *ngIf="!equipmentGroupTemplates.length">
            <h3>Keine Vorlagen vorhanden</h3>
            <p>
                Wenn du häufig wiederkehrende Fahrzeuge manuell kalkulierst, bieten sich Vorlagen für ihre Ausstattungen
                an.
            </p>
            <p>Lege dazu Ausstattungen manuell an und speichere sie als Vorlage für das nächste Mal.</p>
        </div>
    </div>
    <div id="equipment-group-templates-buttons-container">
        <button
            (click)="useEquipmentGroupTemplate(selectedEquipmentGroupTemplate)"
            [ngClass]="{ disabled: !selectedEquipmentGroupTemplate }"
            [matTooltip]="selectedEquipmentGroupTemplate ? '' : 'Bitte eine Vorlage auswählen'">
            Übernehmen
        </button>
        <button class="secondary" (click)="hideEquipmentGroupTemplates()">Vorlagen schließen</button>
    </div>
</section>
<!--============================================-->
<!-- END Equipment Group Templates -->
<!--============================================-->

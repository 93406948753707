<div class="dialog-overlay" (click)="handleOverlayClick($event)" @dialogEnterAndLeave>
    <div class="dialog-container contains-scrollable-area">
        <h2>GT-Motive-Vorgang verknüpfen</h2>
        <mat-icon class="dialog-close-icon" (click)="closeDialog()" matTooltip="Schließen">close</mat-icon>

        <!--============================================-->
        <!-- GT Motive Estimates Loaded -->
        <!--============================================-->
        <ng-container *ngIf="!gtmotiveEstimatesPending">
            <div class="dialog-scrollable-area">
                <!--============================================-->
                <!-- GT Motive Estimate List -->
                <!--============================================-->
                <div id="record-list" *ngIf="gtmotiveEstimates" @fadeInAndSlide>
                    <table id="record-table">
                        <!--********** Record **********-->
                        <tr
                            class="gtmotive-record record"
                            *ngFor="let gtmotiveEstimate of gtmotiveEstimates"
                            (click)="selectGtmotiveEstimate(gtmotiveEstimate); hideGtmotiveEstimateIdInput()"
                            (dblclick)="emitGtmotiveEstimateSelection(); closeDialog()"
                            [ngClass]="{ active: selectedGtmotiveEstimate === gtmotiveEstimate }">
                            <!--********** Icon **********-->
                            <td class="manufacturer-icon-cell">
                                <div class="manufacturer-icon-container" [matTooltip]="gtmotiveEstimate.make">
                                    <img
                                        class="car-brand-logo"
                                        *ngIf="iconForCarBrandExists(gtmotiveEstimate.make)"
                                        [src]="iconFilePathForCarBrand(gtmotiveEstimate.make, 'large')" />
                                    <div
                                        class="no-brand-icon-replacement text-overflow-ellipsis flex-center-content"
                                        *ngIf="!iconForCarBrandExists(gtmotiveEstimate.make)">
                                        ?
                                    </div>
                                </div>
                            </td>

                            <!--********** Model & Date **********-->
                            <td class="model-and-date-container">
                                <div class="model text-overflow-ellipsis" [matTooltip]="gtmotiveEstimate.baseModel">
                                    {{ gtmotiveEstimate.baseModel || 'Kein Fahrzeug' }}
                                </div>
                                <div
                                    class="label creation-date"
                                    matTooltip="Zuletzt bearbeitet: {{
                                        gtmotiveEstimate.updatedAt | moment: 'DD.MM.YYYY'
                                    }}">
                                    {{ capitalize(gtmotiveEstimate.updatedAt | momentCalendar: 'day') }}
                                </div>
                            </td>

                            <!--********** License Plate **********-->
                            <td class="license-plate-cell">
                                <license-plate
                                    *ngIf="gtmotiveEstimate.licensePlate"
                                    [licensePlate]="gtmotiveEstimate.licensePlate"
                                    [disabled]></license-plate>
                            </td>

                            <!--********** Calculated Amount **********-->
                            <td class="vin-container text-align-end">
                                <!--********** No VIN - Placeholder **********-->
                                <div
                                    *ngIf="!gtmotiveEstimate.vin"
                                    class="no-vin label italic"
                                    matTooltip="In diesem Vorgang ist noch keine VIN eingetragen.">
                                    Keine VIN
                                </div>
                                <!--********** VIN **********-->
                                <div *ngIf="gtmotiveEstimate.vin" class="vin semibold">{{ gtmotiveEstimate.vin }}</div>
                            </td>
                        </tr>
                    </table>

                    <!--============================================-->
                    <!-- No Records - Placeholder -->
                    <!--============================================-->
                    <div id="no-records-placeholder" class="text-align-center" *ngIf="!gtmotiveEstimates.length">
                        Keine Vorgänge gefunden
                    </div>
                    <!--============================================-->
                    <!-- END No Records - Placeholder -->
                    <!--============================================-->
                </div>
                <!--============================================-->
                <!-- END Audatex Task List -->
                <!--============================================-->

                <!--============================================-->
                <!-- Connect other Calculation -->
                <!--============================================-->
                <div
                    class="link text-align-center"
                    (click)="showGtmotiveEstimateIdInput()"
                    *ngIf="!gtmotiveEstimateIdInputShown">
                    Andere GT-Motive-Kalkulation
                </div>
                <div
                    id="custom-gtmotive-task-id-input-container"
                    class="text-align-center"
                    *ngIf="gtmotiveEstimateIdInputShown">
                    <!--                    <div class="margin-bottom-10">-->
                    <!--                        <a href="https://wissen.autoixpert.de/hc/de/articles/4404167436434" target="_blank">Wo finde ich die GT-Motive-Kalkulations-Nr.?</a>-->
                    <!--                    </div>-->
                    <mat-form-field>
                        <input
                            matInput
                            [(ngModel)]="customGtmotiveEstimateId"
                            placeholder="GT-Motive-Kalkulations-Nr."
                            [axAutofocus]="true" />
                    </mat-form-field>
                </div>
                <!--============================================-->
                <!-- END Connect other Calculation -->
                <!--============================================-->
            </div>
            <!--============================================-->
            <!-- END GT Motive Tasks Loaded -->
            <!--============================================-->

            <!--============================================-->
            <!-- Buttons -->
            <!--============================================-->
            <div class="dialog-buttons-container">
                <button
                    [ngClass]="{ disabled: !selectedGtmotiveEstimate && !customGtmotiveEstimateId }"
                    (click)="handleConnectionButtonClick()">
                    Verknüpfen
                </button>
            </div>
            <!--============================================-->
            <!-- END Buttons -->
            <!--============================================-->
        </ng-container>
        <!--============================================-->
        <!-- Dossiers Pending -->
        <!--============================================-->
        <div id="tasks-pending-placeholder" class="text-align-center" *ngIf="gtmotiveEstimatesPending">
            <img src="/assets/images/icons/loading-indicator.svg" />
            <div><strong>Warte auf GT Motive...</strong></div>
            <div class="label">Das kann je nach Tagesform der GT-Motive-Server etwas dauern.</div>
        </div>
        <!--============================================-->
        <!-- END Dossiers Pending -->
        <!--============================================-->
    </div>
</div>

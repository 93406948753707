import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges } from '@angular/core';
import { Router } from '@angular/router';
import { Subject, Subscription } from 'rxjs';
import { debounceTime, filter, switchMap, tap } from 'rxjs/operators';
import { iconFilePathForCarBrand, iconForCarBrandExists } from '@autoixpert/lib/car/icon-for-car-brand-exists';
import { Report } from '@autoixpert/models/reports/report';
import { AbstractApiErrorService } from '../../abstract-services/api-error.abstract.service';
import { AbstractReportService } from '../../abstract-services/report.abstract.service';
import { AbstractToastService } from '../../abstract-services/toast.abstract.service';

/**
 * A component to search a report.
 *
 * When selected, the full Report object will be emitted.
 */
@Component({
    selector: 'report-selector',
    templateUrl: './report-selector.component.html',
    styleUrls: ['./report-selector.component.scss'],
})
export class ReportSelectorComponent implements OnInit {
    constructor(
        private reportService: AbstractReportService,
        private toastService: AbstractToastService,
        private apiErrorService: AbstractApiErrorService,
        private router: Router,
    ) {}

    // If a report ID is passed from outside, this component automatically loads the full report.
    @Input() reportId: Report['_id'];
    @Input() disabled: boolean;

    /**
     * The report may be loaded either
     * - after this component loaded the report with the given reportID.
     * - after the user selected a new report.
     */
    @Output() reportLoad: EventEmitter<Report> = new EventEmitter();
    // Emitted if the user selected a report from the autocomplete.
    @Output() reportIdChange: EventEmitter<Report['_id']> = new EventEmitter();
    // Emitted when disconnecting a report. The parent should remove its reportId if it's saved.
    @Output() reportDisconnection: EventEmitter<Report['_id']> = new EventEmitter();

    public selectedReport: Report;
    // Reports returned from the server search. May be filtered down locally to improve performance.
    public reports: Report[] = [];
    public filteredReports: Report[] = [];
    public searchTerm: string;
    public isReportLoading: boolean;
    public isReportSearchPending: boolean;
    public reportSearchTerm$: Subject<string> = new Subject<string>();
    public reportSearchTermSubscription: Subscription;

    ngOnInit() {
        this.subscribeToSearchTermChanges();
    }

    //*****************************************************************************
    //  React to Changes
    //****************************************************************************/
    ngOnChanges(changes: SimpleChanges) {
        if (changes['reportId']) {
            this.loadSelectedReport();
        }
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END React to Changes
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Load Report
    //****************************************************************************/
    private async loadSelectedReport() {
        if (!this.reportId) return;

        this.isReportLoading = true;

        try {
            this.selectedReport = await this.reportService.get(this.reportId);
        } catch (response) {
            this.apiErrorService.handleAndRethrow({
                axError: response.error,
                handlers: {},
                defaultHandler: {
                    title: `Gutachten nicht geladen`,
                    body: `Bitte versuche es erneut oder kontaktiere die <a href='/Hilfe'>Hotline</a>.`,
                },
            });
        } finally {
            this.isReportLoading = false;
        }

        this.isReportLoading = false;
        this.reportLoad.emit(this.selectedReport);
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Load Report
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Select Report
    //****************************************************************************/
    /**
     * Select report from autocomplete.
     */
    public selectReport(report: Report) {
        this.selectedReport = report;
        this.reportIdChange.emit(this.selectedReport._id);
        this.reportLoad.emit(this.selectedReport);
        /**
         * If the search field isn't visible after selecting a report, we don't have to listen to events.
         */
        this.unsubscribeFromSearchTermSubscription();
    }

    public disconnectReport() {
        const reportId = this.selectedReport._id;
        this.selectedReport = null;
        this.reportId = null;
        this.reportIdChange.emit(null);
        this.reportDisconnection.emit(reportId);
        this.subscribeToSearchTermChanges();
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Select Report
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Report Search
    //****************************************************************************/
    public subscribeToSearchTermChanges(): void {
        this.reportSearchTermSubscription = this.reportSearchTerm$
            .pipe(
                /**
                 * Ensure that a search term has 3 or more characters. Otherwise, searches like "a" are so unspecific
                 * that our backend server collapses under the load because for large customer accounts, thousand of reports
                 * are loaded into memory.
                 *
                 * Three characters allow for searches like "BMW" oder "Audi".
                 *
                 * Also allow searches if there are two or more search words separated by space. That allows to search for license plates
                 * like "PB SL".
                 */
                filter((searchTerm) => {
                    if (!searchTerm || typeof searchTerm !== 'string') return;

                    // Prevent strings like "PB " or "PB  T " to count as multiple search terms.
                    const searchTermParts = searchTerm
                        .trim()
                        .split(' ')
                        .filter((searchTerm) => !!searchTerm.trim());
                    return searchTermParts.some((searchTermPart) => searchTermPart.length >= 3);
                }),
                tap(() => {
                    this.isReportSearchPending = true;
                }),
                debounceTime(300),
                switchMap((searchTerm) => this.reportService.searchReportsWithoutPagination({ $search: searchTerm })),
            )
            .subscribe({
                next: (reports) => {
                    this.reports = reports;
                    this.sortReports();
                    this.filterReportsAutocomplete();
                    this.isReportSearchPending = false;
                },
                error: (error) => {
                    this.isReportSearchPending = false;

                    this.apiErrorService.handleAndRethrow({
                        axError: error,
                        handlers: {},
                        defaultHandler: {
                            title: 'Fehler bei Suche nach Gutachten',
                            body: "Bitte kontaktiere die <a href='/Hilfe'>Hotline</a>.",
                        },
                    });
                },
            });
    }

    public unsubscribeFromSearchTermSubscription(): void {
        this.reportSearchTermSubscription.unsubscribe();
    }

    public updateSearchTerm(searchTerm: string): void {
        this.reportSearchTerm$.next(searchTerm);
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Report Search
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Sort & Filter
    //****************************************************************************/
    public sortReports(): void {
        this.reports.sort((reportA, reportB) => {
            const tokenA = (reportA.token || '').toLowerCase().trim();
            const tokenB = (reportB.token || '').toLowerCase().trim();

            return tokenA.localeCompare(tokenB);
        });
    }

    public filterReportsAutocomplete(): void {
        const searchTerms = (this.searchTerm || '').split(' ');

        this.filteredReports = this.reports.filter((report) => {
            const propertiesToBeSearched = [
                (report.token || '').toLowerCase(),
                (report.car.licensePlate || '').toLowerCase(),
                (report.claimant.contactPerson.organization || '').toLowerCase(),
                (report.claimant.contactPerson.firstName || '').toLowerCase(),
                (report.claimant.contactPerson.lastName || '').toLowerCase(),
            ];

            return searchTerms.every((searchTerm) => {
                searchTerm = (searchTerm || '').toLowerCase();
                return propertiesToBeSearched.some((property) => property.includes(searchTerm));
            });
        });
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Sort & Filter
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Navigation
    //****************************************************************************/
    public navigateToReport(report: Report): void {
        this.router.navigateByUrl(`/Gutachten/${report._id}`);
    }

    public handleOpenInNewClick(reportId: string, event: MouseEvent): void {
        window.open(`/Gutachten/${reportId}`);
        // Prevent the click from selecting the autocomplete entry
        event.stopPropagation();
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Navigation
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Car Brand Icons
    //****************************************************************************/
    protected readonly iconForCarBrandExists = iconForCarBrandExists;
    protected readonly iconFilePathForCarBrand = iconFilePathForCarBrand;
    /////////////////////////////////////////////////////////////////////////////*/
    //  END Car Brand Icons
    /////////////////////////////////////////////////////////////////////////////*/
}

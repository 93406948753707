import { Query as FeathersQuery } from '@feathersjs/feathers';
import { Query as MingoQuery } from 'mingo';
import qs from 'qs';

/**
 * Use this function to filter a given array of records using a MongoDB-/feathers.js-style query object.
 *
 * This function is used in this class when filtering local records but can also be used in a component after a full sync
 * to apply filter criteria without loading all records from disk/IndexedDB again (slow).
 *
 * @param feathersQuery
 * @param records
 * @param searchQueryTranslator - If a $search key is passed within feathersQuery, this method must translate the string to a MongoDB-like query object, most likely spanning multiple fields.
 */
export function applyMongoQuery<DataType>(
    feathersQuery: FeathersQuery,
    records: DataType[],
    searchQueryTranslator: (string) => [string, any],
): DataType[] {
    // You ain't passin me a query? I ain't filterin no records, boy.
    if (!feathersQuery) return records;

    const feathersQueryElements: [string, any][] = Object.entries(feathersQuery);
    const mongoQueryElements: [string, any][] = [];
    let sort: any;
    let skip: any;
    let limit: any;
    /**
     * Some feathers elements are translated in a special way. Extract them here.
     */
    for (const [key, value] of feathersQueryElements) {
        if (key === '$sort') {
            sort = value;
            continue;
        }
        /**
         * Allow handling $sort objects like "{date: -1, number: -1}" as used in the invoice list. In the feathersQueryElements array, they look like:
         * [
         *     ['$sort[date]', -1],
         *     ['$sort[number]', -1],
         * ]
         *
         * These instructions create a sort object like this:
         * {
         *     date: -1,
         *     number : -1
         * }
         */
        if (key.includes('$sort[')) {
            const sortKeyObject = qs.parse(key);
            const sortKey = Object.keys(sortKeyObject.$sort)[0];
            if (!sort) {
                sort = {
                    [sortKey]: value,
                };
            } else {
                sort = {
                    ...sort,
                    [sortKey]: value,
                };
            }
            continue;
        }
        if (key === '$skip') {
            skip = value;
            continue;
        }
        if (key === '$limit') {
            limit = value;
            continue;
        }
        /**
         * This is a non-standard query that autoiXpert introduced. It splits the search query into Regular Expressions
         * that allow searching across fields Google-style.
         */
        if (key === '$search') {
            /**
             * Only consider the search term if it's not empty.
             */
            if (typeof value === 'string' && value.trim() !== '') {
                /**
                 * this.get$SearchMongoQuery() should be implemented in the service class that extends this base class.
                 * This should be equal to the interpretSearchParameters feathers hook in the backend of this application.
                 */
                mongoQueryElements.push(searchQueryTranslator(value));
            }
            continue;
        }
        /**
         * This is used for server-side pagination. It is not used in the frontend and may be skipped.
         */
        if (key === '$searchAfterPaginationToken') {
            continue;
        }
        mongoQueryElements.push([key, value]);
    }

    let foundRecords: DataType[] = records;
    /**
     * Filter records only if they need to be filtered to increase performance.
     */
    if (mongoQueryElements.length || sort || skip || limit) {
        const mongoQuery = Object.fromEntries(mongoQueryElements);
        const mingoQuery = new MingoQuery(mongoQuery);
        foundRecords = mingoQuery
            .find(records)
            .collation({ locale: 'de' })
            .sort(sort)
            .skip(skip)
            .limit(limit)
            .all() as DataType[];
    }

    return foundRecords;

    /**
     * Currently, this method of splitting the results is disabled so that we can use Mingo's sort, skip and limit functions.
     * Ideally, mingo would support loading records through async querying but that's not possible today.
     */
    ///**
    // * Prevent memory exhaustion on mobile devices by splitting the filtering up into batches. Objects with a full sync (CRM data)
    // * are large in numbers (20,000 objects for large/old teams), so scanning them all in memory might crash the app.
    // */
    //let counter                            = 0;
    //let recordsIndexeddbCursor: DataType[] = [];
    //const BATCH_SIZE                       = 1000;
    //const foundRecords: DataType[]         = [];
    //while (cursor) {
    //    const record: DataType = cursor.value;
    //    recordsIndexeddbCursor.push(record);
    //
    //    counter++;
    //    if (counter === BATCH_SIZE) {
    //        const foundRecordsInThisIteration: DataType[] = mingoQuery.find(recordsIndexeddbCursor).all() as DataType[];
    //        foundRecords.push(...foundRecordsInThisIteration);
    //        // Reset this so that the garbage collector can catch the non-matching array elements.
    //        recordsIndexeddbCursor = [];
    //    }
    //
    //    cursor = await cursor.continue();
    //}
    //// All records that are left over after chunking should be added to the result, too, of course.
    //const leftoverFoundRecords: DataType[] = mingoQuery.find(recordsIndexeddbCursor).all() as DataType[];
    //foundRecords.push(...leftoverFoundRecords);
}

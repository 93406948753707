import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatLegacyProgressSpinnerModule as MatProgressSpinnerModule } from '@angular/material/legacy-progress-spinner';
import { WeekdayPipe } from '@autoixpert/pipes/weekday.pipe';
import { SharedModule } from '../shared/shared.module';
import { TaskCapacityPreviewComponent } from './task-list/task-capacity-preview/task-capacity-preview.component';
import { TaskListComponent } from './task-list/task-list.component';
import { TaskListSettingsComponent } from './task-list/task-settings/task-list-settings.component';
import { WorkdaySettingsColumnComponent } from './task-list/task-settings/workday-settings-column/workday-settings-column.component';
import { WorkingHoursSelectorDirective } from './task-list/task-settings/workday-settings-column/working-hours-selector.directive';
import { TasksModuleComponent } from './tasks-module.component';
import { tasksRouting } from './tasks.routing';

@NgModule({
    declarations: [
        TasksModuleComponent,
        TaskListComponent,
        TaskCapacityPreviewComponent,
        TaskListSettingsComponent,
        WorkdaySettingsColumnComponent,
        WorkingHoursSelectorDirective,
    ],
    imports: [MatProgressSpinnerModule, SharedModule, FormsModule, tasksRouting, WeekdayPipe],
    providers: [],
    exports: [],
})
export class TasksModule {}

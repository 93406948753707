import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatMomentDateModule } from '@angular/material-moment-adapter';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacyFormFieldModule as MatFormFieldModule } from '@angular/material/legacy-form-field';
import { MatLegacyInputModule as MatInputModule } from '@angular/material/legacy-input';
import { MatLegacySelectModule as MatSelectModule } from '@angular/material/legacy-select';
import { BrowserModule } from '@angular/platform-browser';
import { FileUploadModule } from 'ng2-file-upload';
import { SignablePdfTemplateEditorModule } from '@autoixpert/components/signable-pdf-template-editor/signable-pdf-template-editor.module';
import { SignaturePadModule } from '@autoixpert/components/signature-pad/signature-pad.module';
import { SharedDirectivesModule } from '@autoixpert/directives/shared-directives.module';
import { SharedPipesModule } from '@autoixpert/pipes/shared-pipes.module';
import { ClaimantSignatureDocumentsComponent } from './claimant-signature-documents/claimant-signature-documents.component';
import { CustomerSignatureParticipantsSummaryComponent } from './customer-signature-participants-summary/customer-signature-participants-summary.component';
import { CustomerSignaturesFeeSetComponent } from './customer-signatures-fee-set/customer-signatures-fee-set.component';

@NgModule({
    imports: [
        BrowserModule,
        FormsModule,
        FileUploadModule,
        MatFormFieldModule,
        MatIconModule,
        MatDatepickerModule,
        MatMomentDateModule,
        SharedDirectivesModule,
        SharedPipesModule,
        MatInputModule,
        MatSelectModule,
        SignaturePadModule,
        SignablePdfTemplateEditorModule,
    ],
    declarations: [
        ClaimantSignatureDocumentsComponent,
        CustomerSignatureParticipantsSummaryComponent,
        CustomerSignaturesFeeSetComponent,
    ],
    exports: [
        ClaimantSignatureDocumentsComponent,
        CustomerSignatureParticipantsSummaryComponent,
        CustomerSignaturesFeeSetComponent,
    ],
})
export class ClaimantSignatureModule {}

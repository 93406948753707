import { DateTime } from 'luxon';
import { RequireSome } from '../../helper-types/require-some';
import { generateId } from '../../lib/generate-id';
import { DataTypeBase } from '../indexed-db/database.types';
import { AccessRights } from './user';

/**
 * Our external api uses api keys for authentication and authorization.
 * We use a separate model 'ApiUser' to manage these api keys.
 * These ApiUsers stub normal users in the backend context.
 *
 * ApiUsers contain only those fields, that are necessary for api endpoints.
 *  */
export class ApiUser implements DataTypeBase {
    constructor(options?: RequireSome<ApiUser, 'title'>) {
        Object.assign(this, options);
    }

    _id: string = `api-${generateId()}`;

    // New fields for apiUser
    apiKey: string = null;
    title: string = null;

    // email: string = '';
    // password: string = null; // CHECK Must not be kept on the frontend. This property only exists so that TypeScript allows deletion.
    // passwordUpdatedAt: Date;

    active: boolean = null;
    // deactivateAt: string = null;
    //*****************************************************************************
    //  Contact Details
    //****************************************************************************/
    // organization: string = null;
    // salutation: string = null;
    // firstName: string = null;
    // lastName: string = null;
    // phone: string = null;
    // phone2: string = null;

    // Default Office Location is relevant for creating new reports
    defaultOfficeLocationId: string = null;
    /////////////////////////////////////////////////////////////////////////////*/
    //  END Contact Details
    /////////////////////////////////////////////////////////////////////////////*/

    // Initials may be displayed in some UI fields (e.g. createdBy)
    initials: string = null;
    // sealHash: string = null;
    // profilePictureHash: string = null;
    // avatarColor: string = null;
    // hideNameBelowSeal: boolean = false;
    // occupationalQualification: string = null;

    teamId: string = null;
    // preferences: UserPreferences = new UserPreferences();
    // tutorialState: TutorialState = new TutorialState();

    // These have the same structure as the user's access rights
    // We may add additional rights here in the future
    accessRights: AccessRights = new AccessRights();
    // Required to start the damage calculation with the user's credentials
    // datUser?: DatUser = new DatUser();
    // audatexUser?: AudatexUser = new AudatexUser();
    // gtmotiveUser?: GtmotiveUser = new GtmotiveUser();
    // autoonlineUser: AutoonlineUser = new AutoonlineUser();
    // cartvUser: CartvUser = new CartvUser();
    // carcasionUser: CarcasionUser = new CarcasionUser();
    // adeltaFinanzUser: AdeltaFinanzUser = new AdeltaFinanzUser();
    // kfzvsUser: KfzvsUser = new KfzvsUser();
    // persaldoUser: PersaldoUser = new PersaldoUser();
    // crashback24User: Crashback24User = new Crashback24User();
    // gdvUser: GdvUser = new GdvUser();
    // winvalueUser: WinvalueUser = new WinvalueUser();
    // emailAccount: EmailCredentials = new EmailCredentials();
    // isBetaTester: boolean = false;
    // isAssessor: boolean = true;
    // gamification: UserGamification = new UserGamification();
    // Use time zone "UTC" to ensure the string has a Z at the end. That's the format, the autoiXpert backend uses, too.
    updatedAt: string = DateTime.now().setZone('UTC').toISO();
    createdAt: string = DateTime.now().setZone('UTC').toISO();
    createdBy: string;

    _documentVersion: number = 0;
    _schemaVersion = 5 as const;
}

import { Report } from '@autoixpert/models/reports/report';

export function isDatValuationInputDataComplete(report: Report): boolean {
    return !!(
        report.car.datIdentification.datEuropaCode &&
        report.car.datIdentification.marketIndex &&
        report.car.firstRegistration &&
        (report.car.mileage || report.car.mileageMeter || report.car.mileageAsStated)
    );
}

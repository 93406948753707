import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { StaticCanvas } from 'fabric';
import { map } from 'rxjs/operators';
import { apiBasePath } from '@autoixpert/external-apis/api-base-path';
import { keepErrorType } from '@autoixpert/lib/errors/keep-error-type';
import { simpleHash } from '@autoixpert/lib/simple-hash';
import { AxError, BadRequest, NotFound } from '@autoixpert/models/errors/ax-error';
import { BlobDataType } from '@autoixpert/models/indexed-db/database-blob.types';
import {
    Photo,
    PhotoConfiguration,
    PhotoConfigurationPattern,
    PhotoFormat,
    PhotoVersion,
} from '@autoixpert/models/reports/damage-description/photo';
import { Report } from '@autoixpert/models/reports/report';
import { Team } from '@autoixpert/models/teams/team';
import { OfflineSyncBlobServiceBase } from '../libraries/database/offline-sync-blob-service.base';
import { canvasToBlob } from '../libraries/photos/canvas-to-blob';
import { getHtmlImageFromFileOrBlob } from '../libraries/photos/get-html-image-from-file-or-blob';
import { isPhotoEmpty } from '../libraries/photos/is-photo-empty';
import { render } from '../libraries/photos/render';
import { ResizePhotoResult, resizePhoto } from '../libraries/photos/resize-photo';
import { FrontendLogService } from './frontend-log.service';
import { LoggedInUserService } from './logged-in-user.service';
import { NetworkStatusService } from './network-status.service';
import { OriginalPhotoService } from './original-photo.service';
import { SyncIssueNotificationService } from './sync-issue-notification.service';
import { WatermarkImageFileService } from './watermark-image-file.service';

@Injectable()
export class RenderedPhotoFileService extends OfflineSyncBlobServiceBase {
    private photoDownloads = new Map<string, Promise<HttpResponse<Blob>>>();
    // Set to true if the photo takes a little longer to load. Let the user know that he should be patient.
    private photoDownloadTakesLonger = new Map<string, boolean>();

    constructor(
        protected httpClient: HttpClient,
        protected networkStatusService: NetworkStatusService,
        protected syncIssueNotificationService: SyncIssueNotificationService,
        protected frontendLogService: FrontendLogService,
        private originalPhotoService: OriginalPhotoService,
        private loggedInUserService: LoggedInUserService,
        private watermarkImageFileService: WatermarkImageFileService,
    ) {
        super({
            serviceName: 'renderedPhotoFile',
            httpClient,
            frontendLogService,
            networkStatusService,
            syncIssueNotificationService,
            skipOutstandingSyncsCheckOnLogout: true,
            // Rendered photos are never uploaded to the server. They are only rendered locally or requested & cached from the server.
            formDataBlobFieldName: undefined,
        });
    }

    //*****************************************************************************
    //  Download Handlers
    //****************************************************************************/
    /**
     * Returns true if the photo download is in progress.
     */
    public isPhotoDownloading(
        reportId: string,
        photoId: string,
        version: keyof PhotoConfiguration,
        format: PhotoFormat,
    ): boolean {
        return this.photoDownloads.has(this.getPhotoDownloadId(reportId, photoId, version, format));
    }

    /**
     * Returns true if the photo download takes longer than usually.
     */
    public doesPhotoDownloadTakeLonger(
        reportId: string,
        photoId: string,
        version: keyof PhotoConfiguration,
        format: PhotoFormat,
    ): boolean {
        return this.photoDownloadTakesLonger.has(this.getPhotoDownloadId(reportId, photoId, version, format));
    }

    /**
     * Returns a string that's used for identifying a photo download. One photo instance can have multiple downloads, depending on the version, format etc.
     */
    private getPhotoDownloadId(
        reportId: string,
        photoId: string,
        version: keyof PhotoConfiguration,
        format: PhotoFormat,
    ): string {
        return `${reportId}-${photoId}-${version}-${format}`;
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Download Handlers
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Get Rendered Photo Blob
    //****************************************************************************/
    /**
     * Get the rendered file from the local cache (IndexedDB) or render it on the fly.
     */
    public async getFile({
        reportId,
        photo,
        version,
        format,
    }: {
        reportId: Report['_id'];
        photo: Photo;
        version: PhotoVersion;
        format: PhotoFormat;
    }): Promise<Blob> {
        const renderedPhotoFileRecordId = this.getPhotoDownloadId(reportId, photo._id, version, format);
        const cachedRenderedPhotoRecord: BlobDataType = await this.localDb.getLocal(renderedPhotoFileRecordId);

        //*****************************************************************************
        //  Determine Correct FabricJs Information
        //****************************************************************************/
        let fabricJsInformation: PhotoConfigurationPattern['fabricJsInformation'] =
            photo.versions[version].fabricJsInformation;
        /**
         * If this photo version for the residual value exchange is queried, and it does not have any fabric.js information, copy the fabric.js information from the report version. That way,
         * the assessor does not need to edit both versions except if he wants different versions, e.g. through blacking out the license plate or faces.
         */
        if ('residualValueExchange' === version && isPhotoEmpty('residualValueExchange', photo)) {
            // Create a copy to prevent errors caused to object reference issues.
            fabricJsInformation = JSON.parse(JSON.stringify(photo.versions['report'].fabricJsInformation));
        }
        const fabricJsInformationHash: string = simpleHash(JSON.stringify(fabricJsInformation));

        /////////////////////////////////////////////////////////////////////////////*/
        //  END Determine Correct FabricJs Information
        /////////////////////////////////////////////////////////////////////////////*/

        //*****************************************************************************
        //  Check for Cached File
        //****************************************************************************/
        if (cachedRenderedPhotoRecord) {
            /**
             * If the cached record's fabricJsInformation is up-to-date, the cached record can be served immediately, yey!
             */
            if (cachedRenderedPhotoRecord.blobContentHash === fabricJsInformationHash) {
                return cachedRenderedPhotoRecord.blob;
            }
        }
        /////////////////////////////////////////////////////////////////////////////*/
        //  END Check for Cached File
        /////////////////////////////////////////////////////////////////////////////*/

        let renderedPhotoBlob: Blob;
        //*****************************************************************************
        //  Download Rendered Thumbnail from Server
        //****************************************************************************/
        /**
         * If the user is online, use the power of the autoiXpert backend to quickly render all photos in parallel. The backend
         * is able to scale up and down faster than the client which is possibly using a slow Internet connection or has limited
         * CPU/GPU power for rendering.
         */
        if (
            this.networkStatusService.isOnline() &&
            !(await this.originalPhotoService.isPhotoUploadIncomplete(reportId, photo._id))
        ) {
            try {
                // Use httpClient.request (instead of httpClient.get) to send a request without Angular's standard "Cache-Control: no-cache" headers.
                renderedPhotoBlob = await this.httpClient
                    .request(
                        'GET',
                        `${apiBasePath}/reports/${reportId}/photoFiles/${photo._id}?format=${format}&version=${version}`,
                        {
                            headers: new HttpHeaders().set('Accept', 'image/jpeg, image/png, */*'),
                            observe: 'response',
                            responseType: 'blob',
                        },
                    )
                    .pipe(map((response) => response.body))
                    .toPromise();
            } catch (error) {
                throw keepErrorType({
                    code: 'DOWNLOADING_RENDERED_PHOTO_FAILED',
                    message: `The rendered photo file could not be loaded from the server.`,
                    error,
                });
            }
        }
        // @formatter:off
        /////////////////////////////////////////////////////////////////////////////*/
        //  END Download Rendered Thumbnail from Server
        /////////////////////////////////////////////////////////////////////////////*/
        //*****************************************************************************
        //  Render File from Local Original Photo
        //****************************************************************************/
        // @formatter:on
        else {
            const timerLabel = `Rendering photo ${renderedPhotoFileRecordId} (Timer ID ${(Math.random() * 10e5).toFixed(
                0,
            )})`;
            console.time(timerLabel);
            //*****************************************************************************
            //  Render New File
            //****************************************************************************/
            const originalPhotoFileId = `${reportId}-${photo._id}`;
            const localOriginalPhotoBlobDataType =
                await this.originalPhotoService.localDb.getLocal(originalPhotoFileId);
            let localOriginalPhotoBlob: Blob = localOriginalPhotoBlobDataType?.blob;
            if (!localOriginalPhotoBlob) {
                console.log(
                    `No original photo file found for photo ${originalPhotoFileId}. Try to get it from the server.`,
                );
                if (this.networkStatusService.isOnline()) {
                    localOriginalPhotoBlob = await this.originalPhotoService.get(originalPhotoFileId);
                } else {
                    /**
                     * The photo file is not available in the IndexedDB and this device is offline, so there is no
                     * way to get the photo file.
                     */
                    throw new NotFound({
                        code: 'ORIGINAL_PHOTO_FILE_CANNOT_BE_LOADED_FROM_THE_SERVER_WHILE_OFFLINE',
                        message: `The original photo file is relevant for rendering the rendered photo file. But the original can't be loaded since this device is offline.`,
                    });
                }
            }

            //*****************************************************************************
            //  Watermark Image
            //****************************************************************************/
            let watermarkImage: HTMLImageElement;
            const team: Team = this.loggedInUserService.getTeam();
            if (team.preferences.watermark?.type === 'image') {
                try {
                    const watermarkImageBlob: Blob = await this.watermarkImageFileService.get(
                        team._id,
                        team.preferences.watermark.imageHash,
                    );

                    watermarkImage = new Image();
                    watermarkImage.src = window.URL.createObjectURL(watermarkImageBlob);
                } catch (error) {
                    console.error(
                        `Getting the watermark image for rendering a photo file failed. Fail silently to allow the user to continue working without a watermark image.`,
                        { error },
                    );
                }
            }
            /////////////////////////////////////////////////////////////////////////////*/
            //  END Watermark Image
            /////////////////////////////////////////////////////////////////////////////*/

            const htmlImage: HTMLImageElement = await getHtmlImageFromFileOrBlob(localOriginalPhotoBlob);

            const renderedFabricCanvas: StaticCanvas = await render({
                canvas: document.createElement('canvas'),
                image: htmlImage,
                /**
                 * Work on a copy. Rendering a photo thumbnail may change the scaleX and scaleY properties of shapes like rectangles. The image in the
                 * fabric.js information is not adjusted in size, though. So, if this partial change is persisted on the report, the shapes are rescaled
                 * and moved without the user intending this.
                 */
                fabricJsInformation: JSON.parse(JSON.stringify(fabricJsInformation)),
                watermarkImage,
                originalImageDimensions: {
                    /**
                     * If the photo height is not defined, use the actual HTML Image height instead.
                     * That's important for when the photo dimension is empty. Apparently, some cameras do not provide dimension information.
                     */
                    height: photo.height || htmlImage.height,
                    width: photo.width || htmlImage.width,
                },
            });
            let renderedBlob: Blob = await canvasToBlob(renderedFabricCanvas.getElement());

            if (!renderedBlob) {
                throw new AxError({
                    code: 'RENDERING_PHOTO_BLOB_FROM_CANVAS_FAILED',
                    message:
                        'The reason may be that the canvas size is too large for the user agent. This is a known bug on the iPad for example.',
                    data: {
                        canvasHeight: renderedFabricCanvas.getElement().height,
                        canvasWidth: renderedFabricCanvas.getElement().width,
                    },
                });
            }
            /////////////////////////////////////////////////////////////////////////////*/
            //  END Render New File
            /////////////////////////////////////////////////////////////////////////////*/

            //*****************************************************************************
            //  Resize
            //****************************************************************************/
            if (format === 'thumbnail400') {
                const resizePhotoResult: ResizePhotoResult = await resizePhoto({
                    photoFileOrBlob: renderedBlob,
                    targetWidth: 400,
                });

                renderedBlob = resizePhotoResult.photoBlob;
            }
            /////////////////////////////////////////////////////////////////////////////*/
            //  END Resize
            /////////////////////////////////////////////////////////////////////////////*/
            renderedPhotoBlob = renderedBlob;
            console.timeEnd(timerLabel);
        }
        /////////////////////////////////////////////////////////////////////////////*/
        //  END Render File from Local Original Photo
        /////////////////////////////////////////////////////////////////////////////*/

        //*****************************************************************************
        //  Save Rendered File to IndexedDB/Cache
        //****************************************************************************/
        /**
         * Use source external so that this service does not try to sync this rendered photo to the server.
         */
        this.localDb
            .createLocal(
                {
                    _id: renderedPhotoFileRecordId,
                    blob: renderedPhotoBlob,
                    blobContentHash: fabricJsInformationHash,
                },
                'external',
            )
            .catch((error) => {
                console.error('Error writing the rendered photo file to the IndexedDB.', error, photo);
            });
        /////////////////////////////////////////////////////////////////////////////*/
        //  END Save Rendered File to IndexedDB/Cache
        /////////////////////////////////////////////////////////////////////////////*/

        return renderedPhotoBlob;
    }

    /**
     * If the rendered photo file with the current fabric.js hash exists locally, it can be used without getting the server version
     * since the fabric.js hash denotes the current version.
     *
     * @deprecated Use getFile() instead which includes the function parameters for checking if the fabric.js information is up-to-date.
     */
    public async get(recordId: string): Promise<Blob> {
        throw new BadRequest({
            code: 'INVALID_METHOD_USED_FOR_GETTING_RENDERED_PHOTO_FILE',
            message: `Since the method DatabaseService.get does not support photo version and format types in the function parameters, please use getFile() instead.`,
            data: {
                recordId,
            },
        });
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Get Rendered Photo Blob
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Prevent Writing to Server
    //****************************************************************************/
    /**
     * Rendered photo files are always only rendered locally when the user is offline or requested (and cached) from the server
     * when the user is online. So, there is no need to send rendered photo files to the server.
     */
    public async pushToServer(): Promise<number> {
        return 0;
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Prevent Writing to Server
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Clear Local Cache
    //****************************************************************************/
    /**
     * This removes all locally rendered thumbnails to force loading them from the server. That way, the user sees that the original file is
     * missing on the server. This is usually executed after rendering a document with photos failed because the photos are missing.
     * @param reportId
     */
    public async clearLocalThumbnails(reportId: Report['_id']): Promise<void> {
        const renderedPhotoFileRecords: BlobDataType[] = await this.localDb.findLocalByPrefix(reportId);
        /**
         * Use source "external" to prevent the photo to be deleted on the server since the API endpoint does not support deleting rendered photos on the server. Instead,
         * rendered photos are deleted automatically after a certain amount of time (on 2022-08-06 the clean-up happens after 14 days) through an AWS S3 lifecycle rule.
         */
        await this.localDb.deleteLocal(
            renderedPhotoFileRecords.map((renderedPhotoFileRecord) => renderedPhotoFileRecord._id),
            'external',
        );
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Clear Local Cache
    /////////////////////////////////////////////////////////////////////////////*/
}

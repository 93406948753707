import { animate, style, transition, trigger } from '@angular/animations';
import { ChangeDetectorRef, Component, ElementRef, HostListener, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { ActivatedRoute, Router } from '@angular/router';
import * as Sentry from '@sentry/angular';
import moment from 'moment';
import { BehaviorSubject, Subject, Subscription, fromEvent } from 'rxjs';
import { distinctUntilChanged, filter, map, scan, switchMap, takeUntil } from 'rxjs/operators';
import { removeFromArray } from '@autoixpert/lib/arrays/remove-from-array';
import { isReportLocked } from '@autoixpert/lib/report/is-report-locked';
import { applyOfflineSyncPatchEventToLocalRecord } from '@autoixpert/lib/server-sync/apply-offline-sync-patch-event-to-local-record';
import { getReportTypeAbbreviation } from '@autoixpert/lib/translators/get-report-type-abbreviation';
import { translateDocumentType } from '@autoixpert/lib/translators/translate-document-type';
import { AxError } from '@autoixpert/models/errors/ax-error';
import { PatchedEvent } from '@autoixpert/models/indexed-db/database.types';
import { RealtimeEditSession } from '@autoixpert/models/realtime-editing/realtime-edit-session';
import { RealtimeEditorColorMap } from '@autoixpert/models/realtime-editing/realtime-editor-color-map';
import { ReportTabName } from '@autoixpert/models/realtime-editing/report-tab-name';
import { ReportProgressConfig } from '@autoixpert/models/report-progress/report-progress-config';
import { Report } from '@autoixpert/models/reports/report';
import { Task } from '@autoixpert/models/tasks/task';
import { User } from '@autoixpert/models/user/user';
import { isSmallScreen } from 'src/app/shared/libraries/is-small-screen';
import { fadeInAndOutAnimation } from '../../shared/animations/fade-in-and-out.animation';
import { fadeInAndSlideAnimation } from '../../shared/animations/fade-in-and-slide.animation';
import { getTooltipForTaskOverlayAnchor } from '../../shared/components/tasks/tasks-panel/get-tooltip-for-task-overlay-anchor';
import { realtimeEditorColors } from '../../shared/libraries/realtime-edit-sessions/realtime-editor-colors';
import { scrollToTheTop } from '../../shared/libraries/scroll-to-the-top';
import { ApiErrorService } from '../../shared/services/api-error.service';
import { FieldGroupConfigService } from '../../shared/services/field-group-config.service';
import { LoggedInUserService } from '../../shared/services/logged-in-user.service';
import { NetworkStatusService } from '../../shared/services/network-status.service';
import { ReportDetailsService } from '../../shared/services/report-details.service';
import { ReportProgressConfigService } from '../../shared/services/report-progress-config.service';
import { ReportRealtimeEditorService } from '../../shared/services/report-realtime-editor.service';
import { ReportService } from '../../shared/services/report.service';
import { ScreenTitleService } from '../../shared/services/screen-title.service';
import { TaskService } from '../../shared/services/task.service';
import { ToastService } from '../../shared/services/toast.service';
import { UserPreferencesService } from '../../shared/services/user-preferences.service';
import { UserService } from '../../shared/services/user.service';
import {
    CopyReportDialogComponent,
    CopyReportDialogComponentConfig,
} from '../copy-report-dialog/copy-report-dialog.component';
import { isReportTabVisible } from './shared/is-report-tab-visible';
import { getLinkFragmentForReportTabName } from './shared/report-tabs/get-link-fragment-for-report-tab-name';
import { ReportTabWithFragment, getReportTabOrder } from './shared/report-tabs/get-report-tab-order';
import { translateReportTabName } from './shared/report-tabs/translate-report-tab-name';

@Component({
    selector: 'report-details',
    templateUrl: 'report-details.component.html',
    styleUrls: ['report-details.component.scss'],
    animations: [
        trigger('reportStateLock', [
            transition(':enter', [
                style({
                    width: 0,
                    opacity: 0,
                    'margin-left': 0,
                }),
                animate(
                    '300ms ease',
                    style({
                        width: '24px',
                        opacity: 1,
                        'margin-left': '12px',
                    }),
                ),
            ]),
            transition(
                ':leave',
                animate(
                    '300ms ease',
                    style({
                        width: 0,
                        opacity: 0,
                        'margin-left': '0px',
                    }),
                ),
            ),
        ]),
        fadeInAndSlideAnimation({ duration: 100 }),
        fadeInAndOutAnimation(),
    ],
})
export class ReportDetailsComponent implements OnInit, OnDestroy {
    constructor(
        private screenTitleService: ScreenTitleService,
        private reportService: ReportService,
        private route: ActivatedRoute,
        private router: Router,
        private toastService: ToastService,
        private loggedInUserService: LoggedInUserService,
        private userService: UserService,
        private reportDetailsService: ReportDetailsService,
        private reportProgressConfigService: ReportProgressConfigService,
        private reportRealtimeEditorService: ReportRealtimeEditorService,
        private networkStatusService: NetworkStatusService,
        public userPreferences: UserPreferencesService,
        public fieldGroupConfigService: FieldGroupConfigService,
        private dialog: MatDialog,
        private changeDetectorRef: ChangeDetectorRef,
        private elemRef: ElementRef,
        private taskService: TaskService,
        private apiErrorService: ApiErrorService,
    ) {}

    public report: Report;
    private subscriptions: Subscription[] = [];
    /**
     * Subject used to subscribe to observables with takeUntil only until
     * this subject gets destroyed (in onDestroy lifecycle method).
     */
    private destroySubject$: Subject<void> = new Subject<void>();

    public user: User;

    // Report Progress
    public reportProgressConfig: ReportProgressConfig;

    // Tasks / ToDos
    protected tasks: Task[] = [];
    protected tasksIconVisible: boolean;
    @ViewChild('tasksIcon', { read: ElementRef }) tasksIcon: ElementRef;

    // Notes overlay
    public notesIconVisible: boolean;
    @ViewChild('notesIcon', { read: ElementRef }) notesIcon: ElementRef;

    // Realtime Editors
    public externalRealtimeEditSessions: RealtimeEditSession[] = [];
    private areRealtimeEditorSubscriptionsEstablished: boolean;

    // Patch & Delete events from other clients.
    private patchUpdatesSubscription: Subscription;
    private deletionsSubscription: Subscription;

    private backOnlineSubscription: Subscription;

    /**
     * Progress bar is either:
     *  - on large screens: sticky or hidden after scrolling down (depending on user preference)
        - dynamically hidden on small screens (visible after scrolling a bit up, hidden after scrolling down)
     */
    // Progress bar is only visible on initial position and when scrolling up.
    public isProcessBarDynamicallyHidden: boolean = false;
    // The scroll event is triggered on the router-outlet-container.
    private scrollEventTargetElement: Element;
    // Store the last scroll position to determine the scrolling direction.
    private lastScrollPosition = 0;
    // Use an observable to track the scroll position.
    private scrollPosition$ = new BehaviorSubject<number>(0);

    ngOnInit() {
        scrollToTheTop();

        // Every time the route changes, set this component's reportID and query the ReportDetailsService for the new report.
        const reportSubscription = this.route.params
            .pipe(
                map((params) => params['reportId']),
                switchMap((reportId) => this.reportDetailsService.get(reportId)),
            )
            .subscribe((report) => {
                this.report = report;

                // Realtime Editors
                this.reportService
                    .joinUpdateChannel(this.report._id)
                    .catch(() =>
                        console.warn(
                            `[report details] Joining the update channel "reports/${this.report._id}" failed. Update channels are optional, so fail silently.`,
                        ),
                    );
                this.registerRealtimeEditorWebsocketListeners();
                this.displayOtherRealtimeEditors();
                // Tasks
                this.loadTasks();

                // Add context to errors in our error monitoring tool Sentry.
                Sentry.setTag('reportId', this.report._id);

                // Realtime Changes
                this.registerPatchAndDeleteWebsocketEvents();

                // Coming back online
                this.registerBackOnlineHandlers();

                this.setScreenTitle();

                // In case of navigation to another report, this component will not be re-initialized. The
                // user can barely see the navigation process then. Scrolling back to the top will add one
                // more hint to this.
                scrollToTheTop();
            });

        this.subscriptions.push(reportSubscription);
        this.user = this.loggedInUserService.getUser();

        // Get config for report progress indicators.
        const reportProgressConfigSubscription = this.reportProgressConfigService.find().subscribe({
            next: (configs) => {
                this.reportProgressConfig = configs[0];
            },
        });
        this.subscriptions.push(reportProgressConfigSubscription);

        this.manageRealtimeEditSessionsWhenNetworkStatusChanges();

        this.registerProcessBarScrollBehavior();

        this.setupClickListenerForDisabledInputFields();
    }

    //*****************************************************************************
    //  Screen Title
    //****************************************************************************/
    setScreenTitle(): void {
        const organization = this.report.claimant.contactPerson.organization || '';
        const firstName = this.report.claimant.contactPerson.firstName || '';
        const lastName = this.report.claimant.contactPerson.lastName || '';

        const screenTitleOptions = {
            screenTitle: null,
            screenSubtitle: null,
            licensePlate: this.report.car.licensePlate || undefined,
            reportToken: this.report.token || undefined,
        };

        if (this.report.claimant.contactPerson.organization) {
            screenTitleOptions.screenTitle = organization;
            screenTitleOptions.screenSubtitle = `${firstName} ${lastName}`.trim();
        } else {
            screenTitleOptions.screenTitle = `${firstName} ${lastName}`.trim();
        }
        this.screenTitleService.setScreenTitle(screenTitleOptions);
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Screen Title
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Report Lock
    //****************************************************************************/
    public getTooltipForLock(): string {
        if (!isReportLocked(this.report) || !this.user) {
            return '';
        }

        if (!this.report.lockedAt || !this.report.lockedBy) {
            // The report was locked previously but is unlocked now
            if (this.userMayLockReport()) {
                return `Gutachten erneut abschließen`;
            } else {
                return `Erneutes Abschließen nicht möglich, da Zugriffsrecht fehlt.`;
            }
        }

        const formattedDate = moment(this.report.lockedAt).format('DD.MM.YYYY - HH:mm') + ' Uhr';
        const lockedByUser: User = this.userService.getTeamMemberFromCache(this.report.lockedBy);

        // The lockedByUser may be undefined if the teamMember cache has not yet been populated after a refresh of the page.
        if (!lockedByUser) {
            return `Am ${formattedDate} abgeschlossen. Zum Entsperren klicken.`;
        }
        if (this.userMayLockReport()) {
            return `Am ${formattedDate} von ${lockedByUser.firstName} ${lockedByUser.lastName} abgeschlossen. Zum Entsperren klicken.`;
        }
        return `Am ${formattedDate} von ${lockedByUser.firstName} ${lockedByUser.lastName} abgeschlossen. Entsperren nicht möglich, da Zugriffsrecht fehlt.`;
    }

    public userMayLockReport(): boolean {
        return !!this.user && this.user.accessRights.lockReports;
    }

    protected async toggleReportLockedState(): Promise<void> {
        await this.reportDetailsService.toggleLockReport(this.report);
        this.saveReport();
    }

    /**
     * When the report is locked, we listen for click events on the report detail page and if the user clicked a
     * disabled input twice in a short time frame, we display a message telling the user that the report is locked.
     */
    private setupClickListenerForDisabledInputFields(): void {
        const isDisabledInteractiveElement = (event: MouseEvent) => {
            const targetElement = event.target as HTMLElement;
            const nodeType = targetElement.nodeName;
            const interactiveNodeTypes = ['INPUT', 'BUTTON', 'TEXTAREA', 'A'];

            const interactiveDisabledElementSelectors = [
                'mat-checkbox.mat-checkbox-disabled',
                'mat-form-field.mat-form-field-disabled',
                'mat-slider.mat-slider-disabled',
                'mat-slide-toggle.mat-disabled',
                'mat-icon.disabled',
                '.signable-document-option-container.unselectable',
                '.option-container.unselectable',
                '.vin-provider-container.unselectable',
                'button.disabled',
                'input.disabled',
                '.floating-action-button.disabled',
                '.selection-bar-item.disabled',
                '.show-section-card-button.disabled',
                '.dashed-button.disabled',
                '.toolbar-icon.disabled',
                '#fee-invoice-recipient-container.disabled',
                '.cdk-drag.cdk-drag-disabled',
                'div.disabled',
                'a.disabled',
                'span.disabled',
                'a[disabled="true"]',
            ];

            const isNativeInteractiveElement = interactiveNodeTypes.includes(nodeType);
            const isInsideDisabledComponent = interactiveDisabledElementSelectors.some(
                (selector) => !!targetElement.closest(selector),
            );

            return (targetElement['disabled'] && isNativeInteractiveElement) || isInsideDisabledComponent;
        };

        // We listen for pointerup event because disabled native input elements completely block all click/mousedown/mouseup events
        const clickedOnDisabledInput$ = fromEvent<MouseEvent>(this.elemRef.nativeElement, 'pointerup').pipe(
            takeUntil(this.destroySubject$),
            // Only listen for clicks on disabled and interactive elements
            filter(() => isReportLocked(this.report)),
            filter(isDisabledInteractiveElement),
            // Scan generally works like Array.reduce but we use it to get access to the last element in the stream and calculate the time between the current and last click
            scan(
                (previousClick) => {
                    return { time: Date.now(), timeSinceLastClick: Date.now() - previousClick.time };
                },
                { time: 0, timeSinceLastClick: null },
            ),
            // Now the observable only fires, if there were at least two clicks within the specified amount of time
            filter((currentClick) => currentClick.timeSinceLastClick < 3000),
        );

        this.subscriptions.push(
            clickedOnDisabledInput$.subscribe(() => {
                this.toastService.info(
                    'Abgeschlossenes Gutachten',
                    'Dieses Gutachten ist bereits abgeschlossen. Um Änderungen vorzunehmen, entsperre das Gutachten über das Schloss-Symbol in der Prozessleiste.',
                );
            }),
        );
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Report Lock
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Tabs
    //****************************************************************************/
    public translateReportTabName(tabName: ReportTabName): string {
        return translateReportTabName(tabName, this.report.type);
    }

    public getLinkFragmentForReportTabName(tabName: ReportTabName): string {
        return getLinkFragmentForReportTabName(tabName);
    }

    public isReportTabVisible(reportTabName: ReportTabName): boolean {
        return isReportTabVisible({
            reportTabName,
            reportType: this.report.type,
        });
    }

    public getNeighborReportTabs(): { prev?: ReportTabWithFragment; next?: ReportTabWithFragment } {
        const currentReportLinkFragment = this.route.children[0]?.snapshot?.url?.[0].path;
        const reportTabs = getReportTabOrder(this.report.type);
        const indexOfCurrentTab = reportTabs.findIndex(
            (reporTab) => reporTab.reportLinkFragment.toLowerCase() === currentReportLinkFragment.toLowerCase(),
        );

        const prevIndex = indexOfCurrentTab - 1;
        const nextIndex = indexOfCurrentTab + 1;
        const neighborTabs = { next: null, prev: null };
        if (0 <= nextIndex && nextIndex <= reportTabs.length) {
            neighborTabs.next = reportTabs[nextIndex];
        }
        if (0 <= prevIndex && prevIndex <= reportTabs.length) {
            neighborTabs.prev = reportTabs[prevIndex];
        }

        return neighborTabs;
    }

    public navigateToNextTab() {
        const { next } = this.getNeighborReportTabs();
        if (!next) return;
        this.router.navigate([next.reportLinkFragment], {
            relativeTo: this.route,
        });
    }
    public navigateToPrevTab() {
        const { prev } = this.getNeighborReportTabs();
        if (!prev) return;
        this.router.navigate([prev.reportLinkFragment], {
            relativeTo: this.route,
        });
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Tabs
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Tasks
    //****************************************************************************/
    protected async loadTasks() {
        try {
            this.tasks = await this.taskService.find({ 'associatedReport.reportId': this.report._id }).toPromise();
        } catch (error) {
            this.apiErrorService.handleAndRethrow({
                axError: error,
                handlers: {},
                defaultHandler: {
                    title: `Aufgaben nicht geladen`,
                    body: `Bitte versuche es erneut oder kontaktiere die <a href='/Hilfe'>Hotline</a>.`,
                },
            });
        }
    }

    public showTasksIcon(): void {
        this.tasksIconVisible = true;
    }

    /**
     * Trigger the click handler that's added by the tasks-panel-anchor directive.
     */
    public openTasksPanel() {
        // Use timeout to give Angular time to insert the icon.
        window.setTimeout(() => {
            // If the icon is not visible yet, take another try in a few cycles.
            if (!this.tasksIcon) {
                this.openTasksPanel();
                return;
            }
            this.tasksIcon.nativeElement.click();
        });
    }
    /////////////////////////////////////////////////////////////////////////////*/
    //  END Tasks
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Notes
    //****************************************************************************/

    public showNotesIcon(): void {
        this.notesIconVisible = true;
    }

    /**
     * Trigger the click handler that's added by the internal-notes-panel-anchor directive.
     */
    public openNotesPanel() {
        // Use timeout to give Angular time to insert the icon.
        window.setTimeout(() => {
            // If the icon is not visible yet, take another try in a few cycles.
            if (!this.notesIcon) {
                this.openNotesPanel();
                return;
            }
            this.notesIcon.nativeElement.click();
        });
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Notes
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Custom Field Config
    //****************************************************************************/
    /**
     * The edit mode is propagated globally through the custom field config service.
     */
    public toggleCustomFieldEditMode() {
        if (!this.user?.accessRights.editTextsAndDocumentBuildingBlocks) {
            this.toastService.info(
                'Zugriffsrecht "Texte bearbeiten" fehlt',
                'Bitte kontaktiere deinen Team-Administrator.',
            );
            return;
        }

        this.fieldGroupConfigService.isEditModeEnabled = !this.fieldGroupConfigService.isEditModeEnabled;
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Custom Field Config
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Progress Bar (sticky or dynamically hidden)
    //****************************************************************************/

    public toggleNavBarSticky() {
        this.userPreferences.navBarSticky = !this.userPreferences.navBarSticky;
    }

    private registerProcessBarScrollBehavior() {
        /**
         * Register for scroll events to dynamically show / hide the process bar on small devices.
         */
        if (isSmallScreen('medium')) {
            // Hide process bar to prevent UI from jumping when scrolling starts.
            this.isProcessBarDynamicallyHidden = true;
            this.scrollEventTargetElement = document.querySelector('#router-outlet-container');
            this.scrollEventTargetElement.addEventListener('scroll', this.handleContainerScroll);

            let previousScrollPosition;
            const scrollPositionSubscription = this.scrollPosition$
                .pipe(
                    distinctUntilChanged(),
                    /**
                     * Throttle the scroll events. Sometimes at the end of scrolling there may be a small bounce by a few pixels in the wrong direction.
                     */
                    filter((scrollPosition) => {
                        if (previousScrollPosition && Math.abs(scrollPosition - previousScrollPosition) < 20) {
                            return false;
                        }
                        previousScrollPosition = scrollPosition;
                        return true;
                    }),
                )
                .subscribe((scrollPosition) => {
                    if (scrollPosition < this.lastScrollPosition && this.isProcessBarDynamicallyHidden) {
                        this.isProcessBarDynamicallyHidden = false;
                        this.changeDetectorRef.detectChanges();
                    } else if (scrollPosition > this.lastScrollPosition && !this.isProcessBarDynamicallyHidden) {
                        this.isProcessBarDynamicallyHidden = true;
                        this.changeDetectorRef.detectChanges();
                    }
                    this.lastScrollPosition = scrollPosition;
                });

            // Add the subscription to the list of subscriptions to be cleaned up on destroy.
            this.subscriptions.push(scrollPositionSubscription);
        }
    }

    /**
     * Callback for scroll events. Use arrow function to keep the context of "this".
     */
    private handleContainerScroll = (event) => {
        if (isSmallScreen('medium')) {
            this.scrollPosition$.next(event.target.scrollTop);
        }
    };

    protected isSmallScreen = isSmallScreen;
    /////////////////////////////////////////////////////////////////////////////*/
    //  END Progress Bar (sticky or dynamically hidden)
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Report Type
    //****************************************************************************/
    public async changeReportType(targetType: Report['type'] | 'repairConfirmation') {
        // The repair confirmation cannot be selected when changing the report type. It's invisible in the dialog. Narrow the type.
        if (targetType === 'repairConfirmation') return;

        // Hold on to the previous value.
        const previousType: Report['type'] = this.report.type;

        // Actual type change.
        try {
            await this.reportService.changeReportType(this.report, targetType);
        } catch (error) {
            this.toastService.error(
                'Gutachten-Typ nicht geändert',
                'Bitte lade die Seite neu und versuche es erneut<br><br>Sollte das Problem bestehen bleiben, kontaktiere die <a href="/Hilfe" target="_blank">Hotline</a>.',
            );
            throw new AxError({
                code: 'REPORT_TYPE_CHANGE_FAILED',
                message: 'The report type could not be changed in the report details component.',
                data: {
                    previousType,
                    targetType,
                },
                error,
            });
        }

        // Notify listening components about this change.
        this.reportDetailsService.emitReportTypeChange({
            report: this.report,
            previousType,
            newType: this.report.type,
        });
        this.saveReport();
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Report Type
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Process Bar 3-Dot-Menu
    //****************************************************************************/

    public navigateToRepairConfirmation(): void {
        this.router.navigate(['Reparaturbestätigung'], {
            relativeTo: this.route,
        });
    }

    public navigateToExpertStatement(): void {
        this.router.navigate(['Stellungnahme'], {
            relativeTo: this.route,
        });
    }

    public openAmendmentReportDialog() {
        this.dialog.open<CopyReportDialogComponent, CopyReportDialogComponentConfig>(CopyReportDialogComponent, {
            data: {
                sourceReport: this.report,
                createAmendmentReport: true,
            },
        });
    }

    public openInvoiceAuditDialog() {
        this.dialog.open<CopyReportDialogComponent, CopyReportDialogComponentConfig>(CopyReportDialogComponent, {
            data: {
                sourceReport: this.report,
                createInvoiceAudit: true,
            },
        });
    }

    public navigateToReportList(): void {
        this.router.navigate(['Gutachten']);
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Process Bar 3-Dot-Menu
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Websocket Events
    //****************************************************************************/
    private registerPatchAndDeleteWebsocketEvents() {
        this.registerPatchWebsocketEvent();
        this.registerDeletionWebsocketEvent();
    }

    /**
     * On each websocket put event, update local records.
     * @private
     */
    private registerPatchWebsocketEvent() {
        // Don't subscribe twice.
        if (this.patchUpdatesSubscription) return;

        this.patchUpdatesSubscription = this.reportService.patchedFromExternalServerOrLocalBroadcast$
            .pipe(
                // Only consider updates made to the current report.
                filter(({ patchedRecord }) => patchedRecord?._id === this.report._id),
            )
            .subscribe({
                next: (patchedEvent: PatchedEvent<Report>) => {
                    const reportHasBeenMovedToTrash: boolean =
                        this.report.state !== 'deleted' && patchedEvent.patchedRecord.state === 'deleted';
                    if (reportHasBeenMovedToTrash) {
                        this.navigateToReportList();
                        // No need to merge the put because the report list will load reports from IndexedDB anyway.
                        return;
                    }

                    /**
                     * We use an object merge instead of replacing the entire object because
                     * - Angular causes the UI to jump because we use *ngIf="report" on many components. That causes a full re-render of the view if the report is replaced.
                     * - Replacing the entire object with the remote would overwrite values from an input field being edited.
                     */
                    const mergeResult = applyOfflineSyncPatchEventToLocalRecord({
                        localRecord: this.report,
                        patchedEvent,
                    });

                    console.log(
                        `[report details] Reacted to patch event from ${
                            patchedEvent.eventSource === 'localBroadcastChannel'
                                ? 'local BroadcastChannel'
                                : 'external server/WebSocket'
                        }.`,
                        {
                            mergeResult,
                            serverShadow: patchedEvent.serverShadow,
                            patchedRecord: patchedEvent.patchedRecord,
                        },
                    );

                    // For debugging the merge result.
                    //console.log(`Merge result three way merge reports`, mergeResult.nonConflictingChanges, {
                    //    localRecordClaimantFirstName   : this.report.claimant.contactPerson.firstName,
                    //    serverShadowClaimantFirstName  : serverShadow.claimant.contactPerson.firstName,
                    //    patchedRecordClaimantFirstName : patchedRecord.claimant.contactPerson.firstName,
                    //});

                    // In case the claimant, token or license plate have been updated, the screen title must be changed.
                    this.setScreenTitle();
                },
            });

        this.subscriptions.push(this.patchUpdatesSubscription);
    }

    /**
     * On each websocket delete event, delete local record.
     * @private
     */
    private registerDeletionWebsocketEvent() {
        this.deletionsSubscription = this.reportService.deletedInLocalDatabase$
            .pipe(
                // Only consider deletions of the current report.
                filter((deletedReportId) => deletedReportId === this.report._id),
            )
            .subscribe({
                next: () => {
                    this.navigateToReportList();
                },
            });

        this.subscriptions.push(this.deletionsSubscription);
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Websocket Events
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Back Online Handlers
    //****************************************************************************/
    private registerBackOnlineHandlers() {
        if (this.backOnlineSubscription) {
            removeFromArray(this.backOnlineSubscription, this.subscriptions);
            this.backOnlineSubscription.unsubscribe();
        }

        this.backOnlineSubscription = this.networkStatusService.networkBackOnline$.subscribe({
            next: () => {
                if (this.report?._id) {
                    this.reportService.find({ _id: this.report._id }).subscribe();
                }
            },
        });
        this.subscriptions.push(this.backOnlineSubscription);
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Back Online Handlers
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Realtime Editors
    //****************************************************************************/
    /**
     * Joining and updating realtime editors will be handled in the respective components.
     * @private
     */
    // private joinAsRealtimeEditor() {
    // }

    /**
     * Who else is editing this record?
     * @private
     */
    private async registerRealtimeEditorWebsocketListeners() {
        // Don't subscribe twice, e.g. in case of the report coming in from cache and from network.
        if (this.areRealtimeEditorSubscriptionsEstablished) {
            return;
        }

        // Add new editors
        const joinSubscription = this.reportRealtimeEditorService.realtimeEditorJoin$.subscribe((newEditSession) => {
            const existingEditor = this.externalRealtimeEditSessions.find(
                (editor) => editor.socketId === newEditSession.socketId,
            );
            if (!existingEditor) {
                console.log(`[report details] New realtime editor "${newEditSession.createdBy}" joined.`, {
                    currentTab: newEditSession.currentTab,
                    deviceType: newEditSession.deviceType,
                });
                this.externalRealtimeEditSessions.push(newEditSession);
                this.updateEditorsWithinTab();
            } else {
                console.log(
                    `[report details] Realtime editor "${newEditSession.createdBy}" joined and is already known.`,
                    { currentTab: newEditSession.currentTab, deviceType: newEditSession.deviceType },
                );
            }
        });

        // Update existing editors
        const viewSwitchSubscription = this.reportRealtimeEditorService.realtimeEditorViewSwitch$.subscribe(
            (updatedEditSession) => {
                console.log(
                    `[report details] Realtime editor session of user "${updatedEditSession.createdBy}" updated.`,
                    { currentTab: updatedEditSession.currentTab, deviceType: updatedEditSession.deviceType },
                );
                const existingEditSession = this.externalRealtimeEditSessions.find(
                    (editor) => editor.socketId === updatedEditSession.socketId,
                );
                if (existingEditSession) {
                    existingEditSession.createdBy = updatedEditSession.createdBy;
                    existingEditSession.currentTab = updatedEditSession.currentTab;
                    existingEditSession.deviceType = updatedEditSession.deviceType;
                } else {
                    this.externalRealtimeEditSessions.push(updatedEditSession);
                }
                this.updateEditorsWithinTab();
            },
        );

        // Remove leaving editors
        const leaveSubscription = this.reportRealtimeEditorService.realtimeEditorLeave$.subscribe(
            (deletedEditSession) => {
                console.log(
                    `[report details] "Editor deleted" event received from the realtime editor service. Remove it from this component.`,
                    { currentTab: deletedEditSession.currentTab, deviceType: deletedEditSession.deviceType },
                );
                const existingEditor = this.externalRealtimeEditSessions.find(
                    (editor) => editor.socketId === deletedEditSession.socketId,
                );
                if (existingEditor) {
                    removeFromArray(existingEditor, this.externalRealtimeEditSessions);
                    this.updateEditorsWithinTab();
                }
            },
        );

        this.subscriptions.push(joinSubscription, viewSwitchSubscription, leaveSubscription);
        this.areRealtimeEditorSubscriptionsEstablished = true;
    }

    private async displayOtherRealtimeEditors() {
        /**
         * After all events have been set up, trigger looking up other editors.
         */
        try {
            await this.reportRealtimeEditorService.getOtherEditors(this.report._id);
        } catch (error) {
            console.warn(
                '[report details] Realtime edit sessions could not be loaded. Fail silently since they are optional.',
                { error },
            );
        }

        this.updateEditorsWithinTab();
    }

    public leaveAsRealtimeEditor() {
        if (!this.report) return;

        this.reportRealtimeEditorService.leaveAsEditor();
    }

    public realtimeEditorsWithinTab: { [key in ReportTabName]: RealtimeEditSession[] };
    public realtimeEditorColorMap: RealtimeEditorColorMap = new Map();

    /**
     * Who is working in which tab? What color are they assigned?
     * @private
     */
    private updateEditorsWithinTab() {
        // Who is active in which tab?
        this.realtimeEditorsWithinTab = {
            accidentData: this.getEditorsWithinTab('accidentData'),
            carData: this.getEditorsWithinTab('carData'),
            carCondition: this.getEditorsWithinTab('carCondition'),
            carConditionOldtimer: this.getEditorsWithinTab('carConditionOldtimer'),
            photos: this.getEditorsWithinTab('photos'),
            damageCalculation: this.getEditorsWithinTab('damageCalculation'),
            valuation: this.getEditorsWithinTab('valuation'),
            valuationOldtimer: this.getEditorsWithinTab('valuationOldtimer'),
            leaseReturnChecklist: this.getEditorsWithinTab('leaseReturnChecklist'),
            invoiceAudit: this.getEditorsWithinTab('invoiceAudit'),
            fees: this.getEditorsWithinTab('fees'),
            printAndTransmission: this.getEditorsWithinTab('printAndTransmission'),
        };

        // Which user gets which color?
        for (const realtimeEditSession of this.externalRealtimeEditSessions) {
            const userId: string = realtimeEditSession.createdBy;

            // If the user doesn't have a color yet, find one and save it locally.
            if (!this.realtimeEditorColorMap.has(userId)) {
                const assignedColors = [...this.realtimeEditorColorMap.values()];
                const unusedColor: string = realtimeEditorColors.find((color) => !assignedColors.includes(color));
                this.realtimeEditorColorMap.set(userId, unusedColor);
            }
        }
    }

    private getEditorsWithinTab(tab: ReportTabName): RealtimeEditSession[] {
        return this.externalRealtimeEditSessions.filter((editSession) => editSession.currentTab === tab);
    }

    /**
     * When offline, clear all external realtime edit sessions.
     *
     * When back online, query external edit sessions from server.
     * @private
     */
    private manageRealtimeEditSessionsWhenNetworkStatusChanges() {
        this.subscriptions.push(
            this.networkStatusService.networkWentOffline$.subscribe(() => {
                // When going offline, remove all visible co-editors.
                this.externalRealtimeEditSessions = [];
                this.updateEditorsWithinTab();
            }),
            this.networkStatusService.networkBackOnline$.subscribe(() => {
                // Fetch other editors currently in this record.
                void this.displayOtherRealtimeEditors();
            }),
        );
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Realtime Editors
    /////////////////////////////////////////////////////////////////////////////*/

    public saveReport(): void {
        this.reportDetailsService.patch(this.report);
    }

    // Listen to the window event since listening should be possible even if the focus is removed from the editor.
    @HostListener('window:keydown', ['$event'])
    public keyDownListener(event) {
        /**
         * All following keyboard shortcuts cannot be used if the user currently types in a textarea, e.g. when editing a numberAndText or the image description.
         * Exclude checkboxes so the user may continue to navigate between photos with arrow keys after activating or deactivating a photo.
         */
        if (
            (document.activeElement.tagName === 'INPUT' &&
                (document.activeElement as HTMLInputElement).type !== 'checkbox') ||
            document.activeElement.tagName === 'TEXTAREA'
        ) {
            return;
        }

        // handle tab navigation with keys
        if (event.altKey) {
            switch (event.key) {
                case 'ArrowRight':
                    this.navigateToNextTab();
                    event.preventDefault();
                    break;

                case 'ArrowLeft':
                    this.navigateToPrevTab();
                    event.preventDefault();
                    break;
            }
        }
    }
    ngOnDestroy() {
        this.scrollEventTargetElement?.removeEventListener('scroll', this.handleContainerScroll);

        // Remove the license plate as soon as we leave this component.
        this.screenTitleService.setLicensePlate(null);
        this.screenTitleService.setReportToken(null);

        this.destroySubject$.next();
        this.destroySubject$.unsubscribe();

        this.subscriptions.forEach((subscription) => {
            subscription.unsubscribe();
        });

        /**
         * Clear the ReportDetailService's subject so that it fetches a
         * fresh copy next time the user enters the ReportDetailsComponent.
         *
         * Without this, changing a report outside of the Report Details
         * and then coming back to the same report does not load the latest
         * changes.
         */
        this.reportDetailsService.clearCachedReport();

        /**
         * this.report may be undefined if the user accessed a report URL to which he does not have access. Optional chaining still allows the user to leave this ReportDetailsComponent.
         */
        this.reportService
            .leaveUpdateChannel(this.report?._id)
            .catch(() =>
                console.warn(
                    `[report details] Leaving the update channel "reports/${this.report._id}" when navigating away from the report details component failed. That's optional, so fail silently.`,
                ),
            );

        this.leaveAsRealtimeEditor();

        // Prevent errors on other pages from showing the wrong report ID in Sentry.
        Sentry.setTag('reportId', undefined);
    }

    protected readonly isReportLocked = isReportLocked;
    protected readonly getTooltipForTaskOverlayAnchor = getTooltipForTaskOverlayAnchor;
}

import moment from 'moment';
import { FieldGroupConfig } from '../../models/custom-fields/field-group-config';
import { AxError } from '../../models/errors/ax-error';
import { Report } from '../../models/reports/report';
import { OfficeLocation } from '../../models/teams/office-location';
import { User } from '../../models/user/user';
import { replacePlaceholders } from '../documents/replace-document-building-block-placeholders';
import { PlaceholderValues } from '../placeholder-values/get-placeholder-values';
import { getReportTypeAbbreviation } from '../translators/get-report-type-abbreviation';
import { convertCounterPatternToRegularExpression } from './convert-counter-pattern-to-regular-expression';

const PATTERN_REPORT_TYPE = /{TYP}/g;
const PATTERN_OFFICE_LOCATION = /{STO}/g;
const PATTERN_USER_INITIALS = /{INI}/g;
const PATTERN_REPORT_TOKEN = /{AZ}/g;
const PATTERN_INVOICE_SUB_COUNTER = /{R}/g;

export class ReportTokenOrInvoiceNumberPlaceholderValues {
    constructor(templ: Partial<ReportTokenOrInvoiceNumberPlaceholderValues> = {}) {
        Object.assign(this, templ);
    }
    TT: string = '';
    MM: string = '';
    JJJJ: string = '';
    JJ: string = '';
    // report type
    TYP: string = '';
    // user initials
    INI: string = '';
    // office location initials
    STO: string = '';
    COUNTER: string = '';
}

/**
 * A counter pattern is a string which contains placeholders for the counter and additional information.
 * This pattern is used in a report token or invoice number.
 *
 * This object provides methods:
 *  - to fill the pattern
 *  - to extract values from a filled pattern
 *  - to check, weather the pattern includes a specific placeholder
 */
export class CounterPattern {
    constructor(private pattern: string) {
        if (typeof this.pattern === 'undefined') {
            throw new AxError({
                code: 'REPORT_TOKEN_OR_INVOICE_NUMBER_PATTERN_MISSING',
                message: `The pattern which is required for extracting the counter from the report token or invoice number is missing.`,
            });
        }
    }

    /**
     * Checks if the pattern includes the report type initials placeholder.
     */
    public includesReportTypeInitials() {
        if (typeof this.pattern !== 'string') {
            return false;
        }
        return !!this.pattern.match(PATTERN_REPORT_TYPE);
    }

    /**
     * Checks if the pattern includes the user initials placeholder.
     * This is useful to load the user data only if a user is necessary to fill the counter.
     */
    public includesUserInitials() {
        if (typeof this.pattern !== 'string') {
            return false;
        }
        return !!this.pattern.match(PATTERN_USER_INITIALS);
    }

    /**
     * Checks if the pattern includes the office location initials placeholder.
     * This is useful to load the office location data only if an office location is necessary to fill the counter.
     */
    public includesOfficeLocationInitials() {
        if (typeof this.pattern !== 'string') {
            return false;
        }
        return !!this.pattern.match(PATTERN_OFFICE_LOCATION);
    }

    /**
     * Checks if the pattern includes the report token placeholder.
     */
    private includesReportToken() {
        if (typeof this.pattern !== 'string') {
            return false;
        }
        return !!this.pattern.match(PATTERN_REPORT_TOKEN);
    }

    /**
     * Checks if the pattern includes the invoice sub counter placeholder.
     */
    private includesInvoiceSubCounter() {
        if (typeof this.pattern !== 'string') {
            return false;
        }
        return !!this.pattern.match(PATTERN_INVOICE_SUB_COUNTER);
    }

    /**
     * Fills the pattern with information from the given user, office location or report type.
     */
    public fill(
        counter: number,
        params: {
            reportType?: Report['type'];
            associatedUser?: User;
            associatedOfficeLocation?: OfficeLocation;
            referenceDate?: moment.Moment;
            reportToken?: string;
            reportId?: Report['_id'];
            placeholderValues?: PlaceholderValues;
            fieldGroupConfigs?: FieldGroupConfig[];
        },
    ): string {
        if (isNaN(+counter)) {
            console.error(
                'ERR_WRONG_PARAMETERS',
                'Wrong parameters detected for replacing the placeholders in a prefix, e.g. in the report token or invoice number.',
            );
            return;
        }

        const { referenceDate = moment(), reportType, associatedUser, associatedOfficeLocation, reportToken } = params;

        const shorthandPlaceholderValues = new ReportTokenOrInvoiceNumberPlaceholderValues({
            TT: referenceDate.format('DD'),
            MM: referenceDate.format('MM'),
            JJJJ: referenceDate.format('YYYY'),
            JJ: referenceDate.format('YY'),
            COUNTER: `${counter}`,
        });

        /**
         * Get the report type.
         */
        if (this.includesReportTypeInitials()) {
            shorthandPlaceholderValues['TYP'] = getReportTypeAbbreviation(reportType);
        }

        /**
         * If the user is not provided, the placeholder will be empty (removed).
         */
        if (this.includesUserInitials() && associatedUser?.initials) {
            shorthandPlaceholderValues['INI'] = associatedUser.initials;
        }

        /**
         * If the office location is not provided, the placeholder will be empty (removed).
         * If the office location has no abbreviation, the first three letters of the title will be used.
         */
        if (this.includesOfficeLocationInitials() && associatedOfficeLocation) {
            shorthandPlaceholderValues['STO'] =
                associatedOfficeLocation.initials || associatedOfficeLocation.title.slice(0, 3).toUpperCase();
        }

        if (this.includesReportToken() && reportToken) {
            shorthandPlaceholderValues['AZ'] = reportToken;
        }

        if (this.includesInvoiceSubCounter() && counter) {
            // Note: Invoice sub counter is passed in regular counter variable
            shorthandPlaceholderValues['R'] = counter;
        }

        return this.replaceAllPlaceholders({
            shorthandPlaceholderValues,
            regularPlaceholderValues: params.placeholderValues,
            fieldGroupConfigs: params.fieldGroupConfigs,
        });
    }

    /**
     * Replace placeholder values (anything within an opening and closing curly bracket) with a variable names like
     * "Feld1", "Feld2" etc.
     * This is necessary to shorten the pattern for preview purposes. A better approach would be to insert actual values
     * for the placeholders, but therefore we would need to let the user select an example report, where these placeholder
     * values come from. This is currently too much effort for the edge case of using regular placeholders in report/invoice number
     * patterns. If this is too confusing for users, we should implement it like that.
     */
    private replacePlaceholderNamesWithVariableNames(pattern: string): string {
        let counter = 0;
        return pattern.replace(/{[^{}]+}/g, () => {
            return `[Feld${++counter}]`;
        });
    }

    /**
     * Replace all placeholders (shorthands for invoice like "MM" or "INI", as well as regular placeholders
     * like "Gutachten.EigeneFelder.XY").
     */
    public replaceAllPlaceholders({
        shorthandPlaceholderValues,
        regularPlaceholderValues,
        fieldGroupConfigs,
    }: {
        shorthandPlaceholderValues?: ReportTokenOrInvoiceNumberPlaceholderValues;
        regularPlaceholderValues?: PlaceholderValues;
        fieldGroupConfigs?: FieldGroupConfig[];
    }): string {
        const tokenWithReplacedShorthandPlaceholders = this.replaceShorthandPlaceholders(shorthandPlaceholderValues);
        return this.replaceRegularPlaceholders(tokenWithReplacedShorthandPlaceholders, {
            placeholderValues: regularPlaceholderValues,
            fieldGroupConfigs: fieldGroupConfigs,
        });
    }

    /**
     * Replace regular placeholders like "Gutachten.Anspruchsteller.Nachname". If you omit placeholderValues
     * and fieldGroupConfigs, exemplary variable names (e.g. "Feld1", "Feld2") will be inserted instead of actual values.
     */
    public replaceRegularPlaceholders(
        token: string,
        {
            placeholderValues,
            fieldGroupConfigs,
        }: {
            placeholderValues?: PlaceholderValues;
            fieldGroupConfigs?: FieldGroupConfig[];
        },
    ): string {
        if (token.includes('{')) {
            if (!placeholderValues) {
                // Preview
                return this.replacePlaceholderNamesWithVariableNames(token);
            }

            // If there are still placeholders in the token after replacing the shorthands, these are likely general placeholders
            return replacePlaceholders({
                textWithPlaceholders: token,
                placeholderValues: placeholderValues,
                fieldGroupConfigs: fieldGroupConfigs,
                isHtmlAllowed: false,
            });
        }

        return token;
    }

    /**
     * Replace the shorthand placeholders in the pattern with the values from the placeholderValues object.
     * Those are the shorthands used for invoice numbers and report tokens (like "MM" or "INI").
     */
    public replaceShorthandPlaceholders(placeholderValues?: ReportTokenOrInvoiceNumberPlaceholderValues): string {
        if (!placeholderValues) {
            throw new AxError({
                code: 'NO_VALUES_PROVIDED',
                message: `No values are provided to fill into the pattern.`,
            });
        }

        /** Convert year */
        if (placeholderValues.JJJJ && !placeholderValues.JJ) {
            placeholderValues.JJ = placeholderValues.JJJJ.slice(2, 4);
        }

        if (placeholderValues.JJ && !placeholderValues.JJJJ) {
            // RemindMe! an meinem 103. Geburtstag (Lukas)
            placeholderValues.JJJJ = `20${placeholderValues.JJ}`;
        }

        return this.pattern.replace(/{[^{}]+}/g, (placeholder) => {
            const placeholderWithoutBrackets: string = placeholder.replace('{', '').replace('}', '');

            /**
             * Fill the counter. If multiple hashes are provided, the counter will be padded with zeros.
             */
            if (placeholderWithoutBrackets.includes('#')) {
                const numberOfHashCharacters: number = placeholderWithoutBrackets.length;
                return (placeholderValues['COUNTER'] + '').padStart(numberOfHashCharacters, '0');
            }

            /**
             * Fill all other placeholders
             */
            const replacementValue = placeholderValues[placeholderWithoutBrackets];
            if (replacementValue !== undefined) {
                // Only replace the placeholder, if we got some value for it (e. g. the shorthand placeholders)
                return placeholderValues[placeholderWithoutBrackets];
            } else {
                // Keep all other placeholders intact -> e. g. general placeholders (they get replaced later on)
                return placeholder;
            }
        });
    }

    /**
     * Extract placeholder values from a filled report token or invoice number.
     */
    public extractPlaceholderValues(filledPattern: string): ReportTokenOrInvoiceNumberPlaceholderValues {
        const { regularExpression, placeholdersWithoutBrackets } = convertCounterPatternToRegularExpression({
            pattern: this.pattern,
        });

        const placeholderValues = new ReportTokenOrInvoiceNumberPlaceholderValues();

        const matches = filledPattern.match(regularExpression);
        if (!matches) {
            throw new AxError({
                code: 'COUNTER_PATTERN_DOES_NOT_MATCH_REPORT_TOKEN_OR_INVOICE_NUMBER',
                message: `The invoice number or report token does not match the given pattern. Values cannot be extracted.`,
                data: {
                    templatePattern: this.pattern,
                    filledPattern,
                },
            });
        }
        // The first match is the entire string. But we are only interested in the capturing groups.
        const [, ...placeholderValueMatches] = matches;

        for (const index in placeholdersWithoutBrackets) {
            const placeholder = placeholdersWithoutBrackets[index];
            placeholderValues[placeholder] = placeholderValueMatches[index];
        }

        return placeholderValues;
    }
}

import { SafeHtml } from '@angular/platform-browser';
import { FieldGroupConfig } from '../../models/custom-fields/field-group-config';
import { DocumentBuildingBlock } from '../../models/documents/document-building-block';
import { getPlaceholderValueTree } from '../placeholder-values/get-placeholder-value-tree';
import { chooseDocumentBuildingBlocks } from './choose-document-building-blocks';
import { renderDocumentBuildingBlock } from './render-document-building-blocks';

/**
 * 1. Choose the right DocumentBuildingBlocks based on their conditions which are in turn based on the placeholder values.
 * 2. Render each block: Fill placeholders from the DocumentBuildingBlock's body with a placeholder value.
 */
export function chooseAndRenderDocumentBuildingBlocks({
    documentBuildingBlocks,
    placeholderValues,
    fieldGroupConfigs,
}: {
    documentBuildingBlocks: DocumentBuildingBlock[];
    placeholderValues: any;
    fieldGroupConfigs: FieldGroupConfig[];
}): RenderedDocumentBuildingBlock[] {
    // Choose the right document building block according to the report's current state.
    const chosenDocumentBuildingBlocks: DocumentBuildingBlock[] = chooseDocumentBuildingBlocks({
        documentBuildingBlocks,
        placeholderValues,
        placeholderValueTree: getPlaceholderValueTree({ fieldGroupConfigs }),
    });

    const renderedBuildingBlocks: RenderedDocumentBuildingBlock[] = [];

    // Replace placeholders within chosen blocks and concatenate matching variants
    for (const documentBuildingBlock of chosenDocumentBuildingBlocks) {
        // Rendered = different variants concatenated & placeholders replaced
        const renderedDocumentBuildingBlock = renderDocumentBuildingBlock({
            documentBuildingBlock,
            placeholderValues,
            fieldGroupConfigs,
        });

        renderedBuildingBlocks.push({
            _id: documentBuildingBlock._id,
            placeholder: documentBuildingBlock.placeholder,
            heading: renderedDocumentBuildingBlock['Überschrift'],
            content: renderedDocumentBuildingBlock['Inhalt'],
        });
    }

    return renderedBuildingBlocks;
}

export interface RenderedDocumentBuildingBlock {
    _id: string;
    placeholder: string;
    heading: string;
    content: SafeHtml;
}

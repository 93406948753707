import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError as observableThrowError } from 'rxjs';
import { ContactPerson } from '@autoixpert/models/contacts/contact-person';

@Injectable()
export class AdeltaLiquidityService {
    constructor(private httpClient: HttpClient) {}

    private apiPath: string = '/api/v0/adeltafinanz/liquidity';

    public checkLiquidity(contactPerson: ContactPerson): Observable<AdeltaLiquidityResponse> {
        if (
            !contactPerson ||
            !contactPerson.firstName ||
            !contactPerson.lastName ||
            !contactPerson.zip ||
            !contactPerson.city
        ) {
            return observableThrowError('CONTACT_PERSON_INCOMPLETE');
        }

        const params: any = {
            firstName: contactPerson.firstName,
            lastName: contactPerson.lastName,
            streetAndHouseNumberOrLockbox: contactPerson.streetAndHouseNumberOrLockbox,
            zip: contactPerson.zip,
            city: contactPerson.city,
        };
        // Only add the organization as a parameter if the user entered an organization.
        // Interesting: If the parameter exists on the params object but is undefined, Angular sends ?organization=undefined to the server. That
        // string will be recognized by the server as an organization.
        if (contactPerson.organization) {
            params.organization = contactPerson.organization;
        }

        return this.httpClient.get<AdeltaLiquidityResponse>(this.apiPath, {
            params,
        });
    }
}

interface AdeltaLiquidityResponse {
    code: string;
    message: string;
}

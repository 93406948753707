import moment from 'moment/moment';
import { ContactPerson } from '@autoixpert/models/contacts/contact-person';
import { ContactPersonService } from '../../services/contact-person.service';
import { areContactPeopleDifferent } from '../are-contact-people-equal';

export async function rememberContactPerson(
    contactPersonToRemember: ContactPerson,
    contactPersonService: ContactPersonService,
): Promise<void> {
    // Don't allow empty contact people to be saved
    if (!contactPersonToRemember.organization && !contactPersonToRemember.lastName) {
        return;
    }

    const query = {
        // If any of the properties isn't set within the report, let duplicates be found if their field value is either null or empty string.
        organizationType: contactPersonToRemember.organizationType,
        organization: contactPersonToRemember.organization || { $in: [null, ''] },
        zip: contactPersonToRemember.zip || { $in: [null, ''] },
        streetAndHouseNumberOrLockbox: contactPersonToRemember.streetAndHouseNumberOrLockbox || { $in: [null, ''] },
        firstName: contactPersonToRemember.firstName || { $in: [null, ''] },
        lastName: contactPersonToRemember.lastName || { $in: [null, ''] },
    };

    // Commented out because if we check for equality of all contact address info, we will never get a match to update, but create new entries when only providing e.g. a new phone number.
    // if(contactPersonToRemember.organizationType === 'insurance') {
    //     // If it's an insurance,
    //     query['email']                     = contactPersonToRemember.email;
    //     query['phone']                     = contactPersonToRemember.phone;
    // }

    const roughMatches = await contactPersonService.httpSync.findRemote(query);
    // If it doesn't exist yet, create it
    if (!roughMatches.length) {
        const copyOfReportContactPerson = JSON.parse(JSON.stringify(contactPersonToRemember));

        // Remove metadata to have them initialized with `new ContactPerson()`. We sometimes use the createdBy property for source values ("from-open-report"). This is deleted here.
        delete copyOfReportContactPerson._id;
        delete copyOfReportContactPerson.updatedAt;
        delete copyOfReportContactPerson.createdAt;
        delete copyOfReportContactPerson.createdBy;

        // Ensure the document version is set to 0
        delete copyOfReportContactPerson._documentVersion;

        await contactPersonService.create(new ContactPerson(copyOfReportContactPerson));
        return;
    }

    // Only update or create new if the contact person to remember does not exist as template yet. (= all existing are different)
    const isContactDifferentFromAllTemplates: boolean = roughMatches.every((roughMatch) =>
        areContactPeopleDifferent(contactPersonToRemember, roughMatch),
    );
    if (isContactDifferentFromAllTemplates) {
        //*****************************************************************************
        //  Create Record to Save
        //****************************************************************************/
        // Remove unwanted properties to not overwrite ID and timestamps when merging
        const copyOfContactToRemember: ContactPerson = JSON.parse(JSON.stringify(contactPersonToRemember));

        delete copyOfContactToRemember._id;
        delete copyOfContactToRemember.updatedAt;
        delete copyOfContactToRemember.createdAt;
        delete copyOfContactToRemember.createdBy;
        /////////////////////////////////////////////////////////////////////////////*/
        //  END Create Record to Save
        /////////////////////////////////////////////////////////////////////////////*/

        //*****************************************************************************
        //  Update Notes
        //****************************************************************************/
        if (contactPersonToRemember.notes) {
            // Take the first match
            const existingContactPerson: ContactPerson = roughMatches[0];

            if (contactPersonToRemember.notes !== existingContactPerson.notes) {
                const mergedContactPerson: ContactPerson = Object.assign<
                    Record<string, any>,
                    ContactPerson,
                    Partial<ContactPerson>
                >({}, existingContactPerson, {
                    notes: contactPersonToRemember.notes,
                });
                await contactPersonService.put(mergedContactPerson);
            }
        }
        /////////////////////////////////////////////////////////////////////////////*/
        //  END Update Notes
        /////////////////////////////////////////////////////////////////////////////*/

        //*****************************************************************************
        //  Update Garage Fee Sets
        //****************************************************************************/
        if (
            contactPersonToRemember.organizationType.includes('garage') &&
            contactPersonToRemember.garageFeeSets?.length
        ) {
            // Take the first match
            const existingContactPerson: ContactPerson = roughMatches[0];
            // Only update the fee set
            // - if a validity date is set, and it's newer.
            // - the existing contact person doesn't have a fee set.
            const isSomeFeeSetToRememberNewer: boolean = contactPersonToRemember.garageFeeSets.some(
                (feeSetToRemember) => {
                    const existingFeeSet = existingContactPerson.garageFeeSets?.find(
                        (feeSet) => feeSet._id === feeSetToRemember._id,
                    );

                    if (feeSetToRemember.validFrom && existingFeeSet?.validFrom) {
                        return moment(feeSetToRemember.validFrom).isAfter(existingFeeSet.validFrom, 'days');
                    }
                },
            );

            const existingContactDoesntContainFeeSet: boolean = !existingContactPerson.garageFeeSets;
            // We assume that if a fee set has been updated, the brands and characteristics are up-to-date as well.
            if (isSomeFeeSetToRememberNewer || existingContactDoesntContainFeeSet) {
                const mergedContactPerson: ContactPerson = Object.assign<
                    Record<string, any>,
                    ContactPerson,
                    Partial<ContactPerson>
                >({}, existingContactPerson, {
                    garageFeeSets: contactPersonToRemember.garageFeeSets,
                    garageBrands: contactPersonToRemember.garageBrands,
                    isBrandCertified: contactPersonToRemember.isBrandCertified,
                    garageCharacteristics: contactPersonToRemember.garageCharacteristics,
                });
                await contactPersonService.put(mergedContactPerson);
            }
        }
        /////////////////////////////////////////////////////////////////////////////*/
        //  END Update Garage Fee Sets
        /////////////////////////////////////////////////////////////////////////////*/
    }
}

import { AnimationEvent } from '@angular/animations';
import { OverlayRef } from '@angular/cdk/overlay';
import { formatNumber } from '@angular/common';
import { Component, HostBinding, HostListener } from '@angular/core';
import { translateDatabaseServiceName } from '@autoixpert/lib/server-sync/translate-database-service-name';
import { DatabaseServiceName } from '@autoixpert/models/indexed-db/database.types';
import { fadeInAndOutAnimation } from '../../shared/animations/fade-in-and-out.animation';
import { fadeInAndSlideAnimation } from '../../shared/animations/fade-in-and-slide.animation';
import { slideInAndOutVertically } from '../../shared/animations/slide-in-and-out-vertical.animation';
import { slideOutDialogVertical } from '../../shared/animations/slide-out-dialog-vertical.animation';
import { slideOutHorizontally } from '../../shared/animations/slide-out-horizontally.animation';
import {
    DeviceStorageStatistics,
    StorageService,
    StorageSpaceManager,
} from '../../shared/libraries/storage-space-manager/storage-space-manager.class';
import { trackById } from '../../shared/libraries/track-by-id';
import { FrontendLogService } from '../../shared/services/frontend-log.service';
import { SyncIssueNotificationService } from '../../shared/services/sync-issue-notification.service';
import { ToastService } from '../../shared/services/toast.service';

/**
 * Panel that displays the capacity of the local Indexed DB.
 */
@Component({
    selector: 'storage-usage-panel',
    templateUrl: 'storage-usage-panel.component.html',
    styleUrls: ['storage-usage-panel.component.scss'],
    animations: [
        slideOutDialogVertical(),
        slideInAndOutVertically(),
        slideOutHorizontally(400),
        fadeInAndSlideAnimation(),
        fadeInAndOutAnimation(),
    ],
})
export class StorageUsagePanelComponent {
    constructor(
        private overlayRef: OverlayRef,
        private toastService: ToastService,
        private syncIssueNotificationService: SyncIssueNotificationService,
        private frontendLogService: FrontendLogService,
    ) {}

    public deviceStorageStatistics: DeviceStorageStatistics;
    public storageUnits: StorageService[] = [];
    public storageUnitSizes: Map<StorageService, number> = new Map();
    public storageUnitClearanceInProgress: Map<StorageService, boolean> = new Map();

    // The graphs of storage unit sizes are displayed relative to the largest unit's storage so that the first graph is full width.
    public largestStorageUnitSize: number;

    // The content of IndexedDB services may be dumped to the server. In that case, show a loading indicator.
    public dumpsInProgress = new Map<StorageService, 'readingFromIndexeddb' | 'uploading'>();
    public syncsInProgress = new Map<StorageService, boolean>();

    ngOnInit() {
        this.determineTotalAvailableStorage();
    }

    //*****************************************************************************
    //  Determine Storage Size
    //****************************************************************************/
    /**
     * Load the storage size of each service once, sort the database services by size and determine the largest one.
     */
    public async determineTotalAvailableStorage(): Promise<void> {
        this.deviceStorageStatistics = await StorageSpaceManager.getDeviceStorageStatistics();

        // Find the size of each database service in parallel to increase performance.
        const databaseSizes: number[] = await Promise.all(
            this.deviceStorageStatistics.services.map((databaseService) => databaseService.getDatabaseSize()),
        );

        // Set the result for each service on the internal Map.
        this.deviceStorageStatistics.services.forEach((databaseService, index) => {
            // The index must correspond to the right index in databaseSizes because Promise.all respects order.
            this.storageUnitSizes.set(databaseService, databaseSizes[index]);
        });

        // Sort storage units by size. Large units go to the front of the array.
        this.storageUnits = [...this.deviceStorageStatistics.services].sort((storageUnitA, storageUnitB) => {
            const sizeA = this.storageUnitSizes.get(storageUnitA);
            const sizeB = this.storageUnitSizes.get(storageUnitB);

            if (sizeA === sizeB) return 0;
            return sizeA > sizeB ? -1 : 1;
        });

        this.largestStorageUnitSize = this.storageUnitSizes.get(this.storageUnits[0]);
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Determine Storage Size
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Storage Unit Types
    //****************************************************************************/
    /**
     * Each record type is stored in its own database manager and displayed with its own icon and name.
     * @param serviceName
     */
    public getRecordTypeGerman(serviceName: DatabaseServiceName): string {
        return translateDatabaseServiceName(serviceName)?.plural ?? serviceName;
    }

    /**
     * Display the German name of a database service as tooltip, e.g. "Gutachten" or "Dokumentvorlagen".
     * @param serviceName
     */
    public getRecordTypeIconTooltip(serviceName: DatabaseServiceName): string {
        return translateDatabaseServiceName(serviceName).plural;
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Storage Unit Types
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Clear Storage Unit
    //****************************************************************************/
    public async clearStorageUnit(storageUnit: StorageService): Promise<void> {
        this.storageUnitClearanceInProgress.set(storageUnit, true);

        await storageUnit.clearDatabase();

        // Clear any associated sync issues.
        this.syncIssueNotificationService.clearIssuesForService({ databaseService: storageUnit });

        // Re-determine the storage size
        this.storageUnitSizes.set(storageUnit, await storageUnit.getDatabaseSize());

        this.storageUnitClearanceInProgress.delete(storageUnit);
        this.toastService.success(`${this.getRecordTypeGerman(storageUnit.serviceName)} geleert`);
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Clear Storage Unit
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Utilities
    //****************************************************************************/
    public trackById = trackById;

    public convertToSensibleByteUnit(bytes: number): string {
        // Smaller than 1 MB -> Display in KB
        if (bytes < 1_000_000) {
            const numberOfKilobytes = bytes / Math.pow(1024, 1);
            return `${formatNumber(numberOfKilobytes, 'de', numberOfKilobytes < 10 ? '1.1-1' : '1.0-0')} KB`;
        }

        // Smaller than 1 GB -> Display in MB
        if (bytes < 1_000_000_000) {
            const numberOfMegabytes = bytes / Math.pow(1024, 2);
            return `${formatNumber(numberOfMegabytes, 'de', numberOfMegabytes < 10 ? '1.1-1' : '1.0-0')} MB`;
        }

        // Larger than 1 GB -> Display in GB
        if (bytes > 1_000_000_000) {
            const numberOfGigabytes = bytes / Math.pow(1024, 3);
            return `${formatNumber(numberOfGigabytes, 'de', numberOfGigabytes < 10 ? '1.1-1' : '1.0-0')} GB`;
        }
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Utilities
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Trigger Sync
    //****************************************************************************/
    /**
     * Trigger syncing all records from a service.
     */
    public async triggerServiceSync(storageService: StorageService) {
        this.syncsInProgress.set(storageService, true);
        try {
            await storageService.pushToServer();
            this.toastService.success('Erfolgreich synchronisiert');
        } catch (error) {
            this.toastService.error(
                'Fehler beim Synchronisieren',
                'Bitte versuche, deine lokalen Änderungen zurückzusetzen oder kontaktiere die Hotline.',
            );
            console.error(`Push to server failed for database service ${storageService.serviceName}.`);
        }
        this.syncsInProgress.delete(storageService);
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Trigger Sync
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Dump IndexedDB to Server & Display Virtual Console
    //****************************************************************************/
    public async openVirtualConsole() {
        function injectScript(src) {
            return new Promise((resolve, reject) => {
                const script = document.createElement('script');
                script.src = src;
                script.addEventListener('load', resolve);
                script.addEventListener('error', (e) => reject(e.error));
                document.head.appendChild(script);
            });
        }

        try {
            await injectScript('https://unpkg.com/vconsole@latest/dist/vconsole.min.js');
            new (window as any).VConsole();
        } catch (error) {
            window.alert('Inserting the script that loads vConsole failed.');
            console.error('Error loading vConsole.', error);
        }
    }

    public async logIndexeddbObjectStoreToServer(storageService: StorageService) {
        this.dumpsInProgress.set(storageService, 'readingFromIndexeddb');
        try {
            const payload: any = await storageService.localDb.dumpData();

            this.dumpsInProgress.set(storageService, 'uploading');

            const frontendLogBlob = new Blob([JSON.stringify(payload)], {
                type: 'application/json',
            });
            const frontendLogId = await this.frontendLogService.createFile(frontendLogBlob);

            this.toastService.success(
                'Inhalte geloggt',
                `Die Daten können über den AWS S3 Bucket "frontend-logs-autoixpert" eingesehen werden. Die ID lautet "${frontendLogId}".`,
            );
        } catch (error) {
            this.toastService.error(
                'Inhalte nicht übertragen',
                `Die Daten konnten nicht geloggt werden. In der JavaScript-Konsole steht Genaueres.`,
            );
            console.error('Sending the dumped IndexedDB contents to the server failed.', error);
        }

        // If an error occurred or the upload was successful, remove the loading indicator.
        this.dumpsInProgress.delete(storageService);
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Dump IndexedDB to Server & Display Virtual Console
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Overlay Controls
    //****************************************************************************/
    /**
     * Trigger the dialog's content hide animation
     */
    public close(): void {
        this.overlayRef.detach();
    }

    @HostBinding('@fadeInAndSlide')
    public fadeInAndSlideAnimationActive = true;

    /**
     * Detaching only removes the portal. After the portal has been removed and its animation has finished, remove the overlay (portal outlet).
     * @param event
     */
    @HostListener('@fadeInAndSlide.done', ['$event'])
    public disposeOverlayCompletely(event: AnimationEvent): void {
        if (event.toState === 'void') {
            this.overlayRef.dispose();
        }
    }

    @HostListener('window:keydown', ['$event'])
    public handleKeyboardShortcuts(event: KeyboardEvent) {
        switch (event.key) {
            case 'Escape':
                this.close();
                break;
        }
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Overlay Controls
    /////////////////////////////////////////////////////////////////////////////*/
}

import { Directive, ElementRef, EventEmitter, HostListener, Output } from '@angular/core';
import * as IBAN from 'iban';
import { BicAndBankName, BicService } from '../services/bic.service';

@Directive({
    selector: '[iban-input]',
})
export class IbanInputDirective {
    constructor(
        private hostElement: ElementRef<HTMLInputElement>,
        private bicService: BicService,
    ) {}

    @Output() ibanChange: EventEmitter<IbanChangeEvent> = new EventEmitter<IbanChangeEvent>();
    @Output() bicRecognized: EventEmitter<BicAndBankName> = new EventEmitter<BicAndBankName>();

    @HostListener('input') getBicAndBankNameByIban(): void {
        const iban: string = (this.hostElement.nativeElement.value || '').toUpperCase();

        // If the IBAN is valid, get the BIC and bank data
        const isValid = IBAN.isValid(iban);

        // Check for the BIC and bank name if the IBAN is valid
        if (isValid) {
            this.bicService.getBicAndBankNameByIban(iban).subscribe({
                next: (response) => {
                    this.bicRecognized.emit(response);
                },
            });
        }
    }

    private formatIban(): void {
        const iban: string = this.hostElement.nativeElement.value || '';
        this.hostElement.nativeElement.value = IBAN.printFormat(iban, ' ');
    }

    @HostListener('change') validateIban(): void {
        this.formatIban();
        const iban: string = this.hostElement.nativeElement.value || '';
        const isValid = IBAN.isValid(iban);

        this.ibanChange.emit({
            isValid,
        });
    }
}

export interface IbanChangeEvent {
    isValid: boolean;
}

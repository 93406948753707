import {
    ActiveSelection,
    Canvas,
    Circle,
    Control,
    FabricImage,
    FabricObject,
    FabricText,
    Group,
    Rect,
    Shadow,
    util,
} from 'fabric';
import type { TBBox } from 'fabric/src/typedefs';
import { generateId } from '@autoixpert/lib/generate-id';
import { isApproximatelyEqual } from '@autoixpert/lib/is-approximately-equal';
import { getWatermarkTextOrImage } from '../../../../shared/libraries/fabric/ts/get-watermark-text-or-image';
import { AxFabricGroup, DEFAULT_FABRIC_CONTROL_OPTIONS } from './fabric-js-custom-types';
import { CanvasManager } from './photo-editor.canvas-manager';
import { CanvasBlurPatternManager } from './photo-editor.canvas-manager.blur-pattern';
import { CanvasObjectsManagerOptions, FabricImageScaleOptions } from './photo-editor.interfaces';

// Identifiers used to track fabric.js objects
export const CROP_AREA_OBJECT_ID = 'crop-rectangle';
export const CROP_AREA_BACKGROUND_OBJECT_ID = 'crop-background-rectangle';

export class CanvasObjectManager {
    private canvasManager: CanvasManager;
    private blurPatternManager: CanvasBlurPatternManager;
    private clipboard: FabricObject | Group;

    constructor(parameters: CanvasObjectsManagerOptions) {
        this.canvasManager = parameters.canvasManager;
        this.blurPatternManager = parameters.blurPatternManager;
    }

    createNumberToken({ number, color }: { number?: number; color: string }) {
        const circle = new Circle({
            radius: 21,
            fill: '#fff',
            stroke: color,
            strokeWidth: 2,
            originX: 'center',
            originY: 'center',
            shadow: new Shadow('rgb(34,34,34) 3px 3px 7px'),
            opacity: 0.5,
        });
        const text = new FabricText('' + (number || this.canvasManager.numberTokens.length + 1), {
            fontSize: 19,
            fontFamily: 'Source Sans Pro',
            originX: 'center',
            originY: 'center',
        });
        const group = new AxFabricGroup([circle, text], {
            left: 150,
            top: 100,
            // Add a custom marker to this group so that it can later be identified as a number token.
            // That's important when reassembling the photo and its shapes from the server. Then we must know how many
            // tokens are present when adding a new one.

            data: {
                axType: 'numberToken',
                axPhotoDescription: '',
                // Add a custom property so angular knows where to position the card with the inputs for damage descriptions
                /**
                 * TODO This would be preferable outside the data property since this information does not need to be saved to the server.
                 * But currently, I see no way to extend typescript's fabric.IObjectOptions from within this script.
                 */
                axPhotoDescriptionUICard: {
                    x: 0,
                    y: 0,
                    flippedVertically: false,
                    offsetX: 0,
                },
            },
            ...DEFAULT_FABRIC_CONTROL_OPTIONS,
        });

        this.initializeNumberToken(group);

        return group;
    }

    /**
     * If the user entered text into the photo description, indicate this on the numberToken
     */
    updatePhotoDescriptionIndication(numberToken) {
        numberToken.forEachObject((object) => {
            // We need to find the circle to modify its shadow.
            if (object instanceof Circle) {
                // Photo description is available
                if (numberToken.data.axPhotoDescription.trim()) {
                    // object.set('strokeWidth', 4);
                    object.set('opacity', 1);
                } else {
                    object.set('opacity', 0.5);
                    // object.set('strokeWidth', 2);
                }
            }
        });
        this.canvasManager.canvas.requestRenderAll();
    }

    /**
     * Open text box on double-clicking a numberAndText element
     * @param numberToken
     */
    private addDblclickEventListenerToNumberToken(numberToken) {
        const canvas = this.canvasManager.canvas;

        numberToken.on('mousedblclick', () => {
            this.canvasManager.activeNumberToken = numberToken;
            this.updatePhotoDescriptionUICardPosition(numberToken);
            // Remove the visual selection handles from the double-clicked object
            canvas.discardActiveObject();
            canvas.requestRenderAll();

            const hidePhotoDescriptionHandlers = () => {
                // When the user clicks on anything on the canvas, hide the current photo description
                numberToken.close();
            };
            numberToken.hidePhotoDescriptionHandlers = hidePhotoDescriptionHandlers;
            canvas.on('mouse:down', hidePhotoDescriptionHandlers);
        });
    }

    private updatePhotoDescriptionUICardPosition(numberToken) {
        const position = this.getAbsolutePositionOnCanvas(numberToken);
        numberToken.data.axPhotoDescriptionUICard = {
            x: position.x,
            y: position.y + numberToken.height / 2,
            flippedVertically: false,
            offsetX: 0,
        };
        // Render the view to measure the height and width of the UI card
        this.canvasManager.editor.changeDetectorRef.detectChanges();

        const uiCardClientRect =
            this.canvasManager.editor.photoDescriptionContainer.nativeElement.getBoundingClientRect();
        const canvasClientRect = this.canvasManager.editor.canvasHTMLElement.nativeElement.getBoundingClientRect();
        // Flip the description container if its bottom overlaps the canvas bottom
        numberToken.data.axPhotoDescriptionUICard.flippedVertically = uiCardClientRect.bottom > canvasClientRect.bottom;
        // Move the photo description to the left or right if the numberToken is too close to the canvas' left or right border
        if (uiCardClientRect.width / 2 > position.x) {
            numberToken.data.axPhotoDescriptionUICard.offsetX = uiCardClientRect.width / 2 - position.x;
        } else if (uiCardClientRect.width / 2 > canvasClientRect.width - position.x) {
            numberToken.data.axPhotoDescriptionUICard.offsetX =
                -1 * (uiCardClientRect.width / 2 - (canvasClientRect.width - position.x));
        } else {
            numberToken.data.axPhotoDescriptionUICard.offsetX = 0;
        }
    }

    getPhotoDescriptionUICardTransform(numberToken) {
        const translateX = numberToken
            ? 'translateX(calc(-50% + ' + numberToken.data.axPhotoDescriptionUICard.offsetX + 'px))'
            : '';
        // To flip the photo description, move it up
        // 1. its height
        // 2. the numberToken's height (to move it above the number token)
        // 3. the pointing triangle's height 8px * 2 = 16px plus some padding (16px + 2px = 18px) because we want to make up for the space reserved for the triangle when moving upwards
        //    and also create new space so the triangle is visible below the card.
        const translateY =
            numberToken && numberToken.data.axPhotoDescriptionUICard.flippedVertically
                ? `translateY(calc(-100% - ${numberToken.height}px - 18px))`
                : '';
        return this.canvasManager.editor.domSanitizer.bypassSecurityTrustStyle(translateX + ' ' + translateY);
    }

    getPhotoDescriptionPointingTriangleStyle(numberToken) {
        const translateX = numberToken
            ? 'translateX(' + -1 * numberToken.data.axPhotoDescriptionUICard.offsetX + 'px)'
            : '';
        const rotate =
            numberToken && numberToken.data.axPhotoDescriptionUICard.flippedVertically ? 'rotate(180deg)' : '';
        const transform = 'transform: ' + [translateX, rotate].join(' ');
        const positionBottom =
            numberToken && numberToken.data.axPhotoDescriptionUICard.flippedVertically
                ? 'top: auto; bottom: -9px;'
                : '';
        return this.canvasManager.editor.domSanitizer.bypassSecurityTrustStyle(`${transform}; ${positionBottom}`);
    }

    private addCloseHandlerToNumberToken(numberToken) {
        //eslint-disable-next-line @typescript-eslint/no-this-alias
        const $this = this;
        /**
         * Close the photo-description-card for the given numberToken. This needs to be a non-anonymous function so that multiple numberTokens can be added and then closed independently.
         */
        numberToken.close = function closePhotoDescription() {
            $this.canvasManager.activeNumberToken = null;
            // Stop listening to clicks on entire canvas after photo descriptions are hidden.
            $this.canvasManager.canvas.off('mouse:down', this.hidePhotoDescriptionHandlers);
            $this.updatePhotoDescriptionIndication(numberToken);
        };
    }

    initializeNumberToken(numberToken) {
        this.addCloseHandlerToNumberToken(numberToken);
        this.addDblclickEventListenerToNumberToken(numberToken);
        this.updatePhotoDescriptionIndication(numberToken);
        this.canvasManager.numberTokens.push(numberToken);
    }

    /**
     * Fabric elements' positions are always relative to their collection context (either a group or the canvas). So the
     * child of a group will not return its absolute position but its relative position with their top and left properties.
     * @param canvasElement
     * @return {Point} The canvasElement's center coordinates
     */
    private getAbsolutePositionOnCanvas(canvasElement) {
        const position = canvasElement.getCenterPoint();

        // If there is a parent container, add its relative position to
        if (canvasElement.group) {
            const parentContainerPosition = this.getAbsolutePositionOnCanvas(canvasElement.group);
            position.x += parentContainerPosition.x;
            position.y += parentContainerPosition.y;
        }

        return position;
    }

    /**
     * Check if this element is a numberToken or is a shape group that contains a number token
     */
    private containsNumberToken(context): boolean {
        let numberTokenFound = false;

        // If this function is executed on a numberToken, return true as well.
        if (context.data && context.data.axType === 'numberToken') {
            return true;
        }

        this.forEach.numberToken(() => {
            numberTokenFound = true;
        }, context);

        return numberTokenFound;
    }

    /**
     * Check if this canvas contains a watermark.
     */
    public findWatermark(context): any {
        let watermark = false;

        this.forEach.object((object) => {
            if (['watermarkImage', 'watermarkText'].includes(object.data?.axType)) {
                watermark = object;
            }
        }, context);

        return watermark;
    }

    /**
     * Resets the numbers on the numberTokens. May be called when a token is deleted.
     */
    resetNumberTokenNumbering() {
        const newNumberTokens = [];

        this.forEach.numberToken((numberToken) => {
            newNumberTokens.push(numberToken);

            // Set the text within the numberToken according to the numberToken's index within the numberTokens array.
            numberToken.forEachObject((object) => {
                if (object instanceof FabricText) {
                    object.set('text', newNumberTokens.length.toString());
                }
            });
        });

        this.canvasManager.numberTokens = newNumberTokens;
    }

    /**
     * Collection to iterate over different object types
     */
    forEach = {
        /**
         * Function has the same signature as fabric.Canvas.forEachObject but this function does only execute the callback
         * for shapes, not for the image.
         * @param callback
         */
        shape: (callback: (element) => any) => {
            this.forEach.object((object) => {
                // Depending on what the callback does, the object may have been deleted during a previous iteration. So check
                // if the object still exists.
                if (!object) {
                    console.warn(
                        'Iterating over the canvas objects resulted in an undefined index. Were the canvas._objects changed while iterating?',
                    );
                }
                // Remove everything from the canvas except the image itself
                else if (!object.isType('image')) {
                    callback(object);
                }
            });
        },
        /**
         * Iterate over all objects recursively and execute the given callback.
         * @param callback Called for each numberToken. Parameter is the numberToken: fabric.Group.
         * @param context The context in which this method shall search for numberTokens
         */
        numberToken: (callback: (numberToken) => void, context: Canvas | Group = this.canvasManager.canvas) => {
            this.forEach.object((object) => {
                if (object.data && object.data.axType === 'numberToken') {
                    callback(object);
                }
                // Parse groups which are no numberTokens recursively since the numberTokens are part
                // of other groups, e.g. numberAndText
                else if (object instanceof Group) {
                    // Find all numberTokens within this group
                    this.forEach.numberToken(callback, object);
                }
            }, context);
        },
        /**
         * Iterate over every canvas object there is except for the photo.
         * @param callback
         * @param context
         */
        object: (callback: (element) => any, context: Canvas | Group = this.canvasManager.canvas) => {
            context.forEachObject((object) => {
                if (!(object.data && object.data.axType === 'photo')) callback(object);
            });
        },
    };

    disableObjectDragging(exceptForObject?: FabricObject, cursor: string = 'crosshair'): void {
        this.forEach.object((object) => {
            // Exclude a specific object
            if (object === exceptForObject) return;

            if (!object.data) object.data = {};

            object.data.disableShapeDraggingTemp = {
                lockMovementX: object.lockMovementX,
                lockMovementY: object.lockMovementY,
                selectable: object.selectable,
                hoverCursor: object.hoverCursor,
            };

            object.set({
                lockMovementX: true,
                lockMovementY: true,
                selectable: false,
                hoverCursor: cursor,
            });
        });
    }

    /**
     * While a new shape is being dragged onto the canvas, dragging any other element is not allowed. This function resets
     * this restriction and should be called when the new shape has been placed on the canvas.
     */
    enableObjectDragging(): void {
        this.forEach.object((object) => {
            // Only enable those objects that were previously disabled. All others should (the photo and potentially newly added
            // objects) should be in the right state.
            if (object.data && object.data.disableShapeDraggingTemp) {
                object.set({
                    lockMovementX: object.data.disableShapeDraggingTemp.lockMovementX,
                    lockMovementY: object.data.disableShapeDraggingTemp.lockMovementY,
                    selectable: object.data.disableShapeDraggingTemp.selectable,
                    hoverCursor: object.data.disableShapeDraggingTemp.hoverCursor,
                });
                delete object.data.disableShapeDraggingTemp;
            }
        });
    }

    /**
     * Delete the object(s) currently selected on the canvas (= "active" objects)
     */
    deleteFocusedObjects() {
        const canvas = this.canvasManager.canvas;
        // Remove objects when there are multiple objects selected. This does NOT cover the case that a single object
        // group (e.g. our numberAndText) is selected. Fabric.js counts that as a single object. Yes, the wording is strange.
        if (canvas.getActiveObjects()) {
            this.deleteCanvasElements(canvas.getActiveObjects());
            canvas.discardActiveObject();
            this.canvasManager.canvas.requestRenderAll();
        } else {
            console.log('No active object found which can be deleted.');
        }
    }

    /**
     * Remove a set of canvas elements (shape, group, image, ...)
     * @param canvasObjects
     */
    deleteCanvasElements(canvasObjects) {
        this.canvasManager.canvas.renderOnAddRemove = false;
        for (const canvasObject of canvasObjects) {
            this.canvasManager.canvas.remove(canvasObject);

            // If the deleted element is or contains a numberToken, reset the numbering.
            if (canvasObject instanceof Group && this.containsNumberToken(canvasObject) === true) {
                this.resetNumberTokenNumbering();
                // Save changes
                this.canvasManager.editor.savePhoto();
            }
        }
        this.canvasManager.canvas.renderOnAddRemove = true;

        // Object deletion is not covered by the object:modified event (that saves history).
        // So we need to trigger saving this step to the history manually.
        this.canvasManager.saveHistory();
    }

    /**
     * Rescale and reposition objects on the canvas.
     * @param objects The objects to be repositioned. Their top, left, width and height properties will be adjusted.
     * @param oldFabricPhoto An object describing the old photo's scale and position properties. This may be either a
     * fabric.Image or a plain object.
     */
    rescaleObjects(objects: any[], oldFabricPhoto: FabricImageScaleOptions | FabricImage): any[] {
        // Calculate the scale factor to bring the objects from the oldFabricPhoto to the scale of the new one
        const serializedToCurrentScale = {
            x: this.canvasManager.fabricPhoto.scaleX / oldFabricPhoto.scaleX,
            y: this.canvasManager.fabricPhoto.scaleY / oldFabricPhoto.scaleY,
        };

        objects.forEach((object) => {
            /*
             * Depending on the photo's and the canvas' sizes, the photo has margins to the left or top so that the
             * canvas space may be used as much as possible while keeping the photo's aspect ratio.
             * Since this may change on different screen sizes, remove the old margin before rescaling the position.left
             * and then add the current margin back to its position
             */
            object.left =
                (object.left - oldFabricPhoto.left) * serializedToCurrentScale.x + this.canvasManager.fabricPhoto.left;
            object.top =
                (object.top - oldFabricPhoto.top) * serializedToCurrentScale.y + this.canvasManager.fabricPhoto.top;
            object.scaleX = object.scaleX * serializedToCurrentScale.x;
            object.scaleY = object.scaleY * serializedToCurrentScale.y;
        });

        return objects;
    }

    async copyToClipboard(): Promise<void> {
        const activeObject = this.canvasManager.canvas.getActiveObject();

        if (activeObject) {
            this.clipboard = await activeObject.clone(['data']);
        }
    }

    async insertFromClipboard(): Promise<void> {
        if (!this.clipboard) {
            return;
        }

        const clonedObject: FabricObject = await this.clipboard.clone(['data']);

        // Ensure unique ID for the copied object
        if (clonedObject.data?.axId) {
            clonedObject.data.axId = generateId();
        }

        // Deselect the currently selected object. The selection will be transferred to the pasted object.
        this.canvasManager.canvas.discardActiveObject();
        // Move object a little to let the user know visually that copy-paste worked.
        clonedObject.set({
            left: clonedObject.left + 10,
            top: clonedObject.top + 10,
            evented: true,
        });

        /**
         * Blur pattern is not natively supported by fabricjs. Therefore, we only created the fabric object with the metadata necessary to create the blur pattern.
         * The blur pattern needs to be enlivened after the object is added to the canvas, meaning that a static canvas with a blurred version of the image is created
         * and used as the pattern canvas for the fill property of the object.
         */
        if (this.blurPatternManager.isBlurPatternObject(clonedObject)) {
            await this.blurPatternManager.enlivenObjectBlurPattern(clonedObject);
        }

        // If manual selection was copied to clipboard, treat it accordingly.
        if (clonedObject instanceof ActiveSelection) {
            // The active selection needs a reference to the canvas.
            clonedObject.canvas = this.canvasManager.canvas;

            if (clonedObject instanceof Group) {
                clonedObject.forEachObject((obj) => {
                    this.canvasManager.canvas.add(obj);
                });
            }
            // This should solve that the object is not selectable.
            clonedObject.setCoords();
        } else {
            this.canvasManager.canvas.add(clonedObject);
        }
        // When inserting multiple times, each insertion should move 10 pixels down and right.
        this.clipboard.top += 10;
        this.clipboard.left += 10;

        // Select the inserted object(s)
        this.canvasManager.canvas.setActiveObject(clonedObject);
        this.canvasManager.canvas.requestRenderAll();
    }

    public async drawWatermark(): Promise<void> {
        const canvas = this.canvasManager.canvas;
        const watermarkPreferences = this.canvasManager.editor.team.preferences.watermark;

        // If there is an existing watermark, remove it before adding a new one.
        const existingWatermark = this.canvasManager.objectManager.findWatermark(canvas);
        if (existingWatermark) {
            this.canvasManager.objectManager.deleteCanvasElements([existingWatermark]);
        }

        let watermarkImageBlob: Blob;

        if (this.canvasManager.editor.team.preferences.watermark?.type === 'image') {
            try {
                watermarkImageBlob = await this.canvasManager.editor.watermarkImageFileService.get(
                    this.canvasManager.editor.team._id,
                    this.canvasManager.editor.team.preferences.watermark.imageHash,
                );
            } catch (error) {
                if (error.code === 'WATERMARK_IMAGE_NOT_FOUND_LOCALLY_AND_CLIENT_OFFLINE') {
                    this.canvasManager.editor.toastService.warn(
                        'Wasserzeichen offline nicht gefunden',
                        'Stelle eine Internetverbindung her, um das Wasserzeichen abzurufen.',
                    );
                } else {
                    this.canvasManager.editor.toastService.warn(
                        'Wasserzeichen nicht gefunden',
                        'Lade bitte das Wasserzeichen-Bild erneut über die Wasserzeichen-Einstellungen hoch.',
                    );
                }
                return;
            }
        }

        const watermarkTextOrImage = await getWatermarkTextOrImage({
            // The watermarkImageFileRecord is not available when a text watermark is used.
            watermarkImageUrl: watermarkImageBlob ? window.URL.createObjectURL(watermarkImageBlob) : null,
            watermarkPreferences,
            canvasHeight: canvas.height,
            canvasWidth: canvas.width,
        });

        if (watermarkTextOrImage) {
            canvas.add(watermarkTextOrImage);
            canvas.requestRenderAll();

            // Track change to trigger saving
            this.canvasManager.canvas.fire('object:modified', { target: watermarkTextOrImage });
        } else {
            console.error("Tried to load a watermark but couldn't find one.");
        }
    }

    public async createCropSelectionRectangle(
        fabricPhoto: FabricImage,
        confirmCrop: (cropArea: Rect) => void,
        cancelCrop: () => void,
    ): Promise<Rect> {
        const canvasManager = this.canvasManager;
        const canvas = canvasManager.canvas;

        let previousCropArea: TBBox;
        if (fabricPhoto.cropX || fabricPhoto.cropY) {
            previousCropArea = {
                left: fabricPhoto.data.axCropAreaX,
                top: fabricPhoto.data.axCropAreaY,
                width: fabricPhoto.data.axCropAreaWidth,
                height: fabricPhoto.data.axCropAreaHeight,
            };

            // In case this image was cropped before -> restore the original image and display the previous crop area again
            canvasManager.restoreUncroppedImage();

            // Update the scale after restoring the cropped image, which recalculates the scaleX + scaleY values used here
            previousCropArea.left = previousCropArea.left * fabricPhoto.scaleX;
            previousCropArea.top = previousCropArea.top * fabricPhoto.scaleY;
            previousCropArea.width = previousCropArea.width * fabricPhoto.scaleX;
            previousCropArea.height = previousCropArea.height * fabricPhoto.scaleY;
        }

        // Background rectangle that darkens the whole image (except for crop area rectangle)
        const backgroundRect = new Rect({
            id: CROP_AREA_BACKGROUND_OBJECT_ID,
            top: 0,
            left: 0,
            angle: 0,
            width: canvas.width,
            height: canvas.height,
            backgroundColor: 'black',
            opacity: 0.7,
            lockRotation: true,
            selectable: false,
        });

        // This is the crop area rectangle. By default positioned in the center of the image (half the width/height of the image).
        // In case there was a previous crop area we use the same crop area as before.
        const preferredAspectRatio = this.canvasManager.editor.team.preferences.preferredPhotoAspectRatio;
        let cropAreaWidth = fabricPhoto.getBoundingRect().width / 2;
        let cropAreaHeight = fabricPhoto.getBoundingRect().height / 2;

        if (preferredAspectRatio) {
            // If the user saved a preferred aspect ratio, use that one.
            const currentAspectRatio = fabricPhoto.getBoundingRect().width / fabricPhoto.getBoundingRect().height;

            if (isApproximatelyEqual(currentAspectRatio, preferredAspectRatio, 0.1)) {
                // If the photo has the same (or almost the same) aspect ratio -> let's keep the crop area small (because its likely that the user wants to highlight a detail)
                cropAreaHeight = cropAreaWidth / preferredAspectRatio;
            } else {
                // But if the current aspect ratio is different from the preferred aspect ratio -> make the crop area as large as possible. Because it is very likely
                // that the user just wanted to fix the aspect ratio. That way the user does not need to adjust the size of the crop area.
                if (currentAspectRatio > preferredAspectRatio) {
                    cropAreaHeight = fabricPhoto.getBoundingRect().height;
                    cropAreaWidth = cropAreaHeight * preferredAspectRatio;
                } else {
                    cropAreaWidth = fabricPhoto.getBoundingRect().width;
                    cropAreaHeight = cropAreaWidth / preferredAspectRatio;
                }
            }
        }

        const clipRectangle = new Rect({
            id: CROP_AREA_OBJECT_ID,
            top: previousCropArea ? previousCropArea.top : 0,
            left: previousCropArea ? previousCropArea.left : 0,
            width: previousCropArea ? previousCropArea.width : cropAreaWidth,
            height: previousCropArea ? previousCropArea.height : cropAreaHeight,
            globalCompositeOperation: 'destination-over',
            absolutePositioned: true,
            lockRotation: true,
            ...DEFAULT_FABRIC_CONTROL_OPTIONS,
        });

        // Prevent deselection
        clipRectangle.on('deselected', function () {
            canvas.setActiveObject(clipRectangle);
        });

        // Add controls to confirm or cancel the crop selection, hide rotation control
        await this.configureCropAreaControls(clipRectangle, confirmCrop, cancelCrop);

        // Use the crop area rectangle as a clip path for the black background rectangle. By default this would only show the
        // black background within the bounds of the crop rectangle. But we can change this by inverting the rectangle (inverted = true)
        // so that only the portion of the background is shown that is not covered by the crop rectangle. That way everything except the crop
        // selection within the image is darkened.
        backgroundRect.clipPath = clipRectangle;
        clipRectangle.inverted = true;

        // Add the controls to the canvas and center the crop rectangle (in case we are not coming from an already cropped image)
        canvas.add(backgroundRect);
        canvas.add(clipRectangle);
        if (!previousCropArea) canvas.centerObject(clipRectangle);

        // Focus the crop area
        canvas.setActiveObject(clipRectangle);

        return clipRectangle;
    }

    private async configureCropAreaControls(
        clipRectangle: Rect,
        confirmCrop: (cropArea: Rect) => void,
        cancelCrop: () => void,
    ): Promise<void> {
        const controlDistanceToParent = 24;
        const paddingBetweenControls = 12;

        // Hide rotation control
        clipRectangle.setControlVisible('mtr', false);

        // Create the confirm and cancel controls for the crop area
        const confirmIcon = document.createElement('img');
        const cancelIcon = document.createElement('img');

        confirmIcon.src = '/assets/images/icons/checkmark-green_48.png';
        cancelIcon.src = '/assets/images/icons/cancel-dark-grey_48.png';

        const imageLoadedPromises: Promise<void>[] = [
            new Promise((resolve) => {
                confirmIcon.onload = () => resolve();
            }),
            new Promise((resolve) => {
                cancelIcon.onload = () => resolve();
            }),
        ];

        // Wait for the images to load before we continue. Otherwise the first render of the custom controls might
        // run into a race condition (initially icons not displayed until the user moves the crop rectangle)
        await Promise.allSettled(imageLoadedPromises);

        function renderIcon(icon: HTMLImageElement) {
            return function renderIcon(ctx, left, top, styleOverride, fabricObject) {
                const controlSize = 32;
                ctx.save();
                ctx.translate(left, top);
                ctx.rotate(util.degreesToRadians(fabricObject.angle));
                ctx.drawImage(icon, -controlSize / 2, -controlSize / 2, controlSize, controlSize);
                ctx.restore();
            };
        }

        const confirmControl = new Control({
            x: 0.5,
            y: 0.5,
            offsetY: -controlDistanceToParent,
            offsetX: -controlDistanceToParent,
            cursorStyle: 'pointer',
            mouseUpHandler: () => {
                confirmCrop(clipRectangle);
            },
            render: renderIcon(confirmIcon),
        });

        const cancelControl = new Control({
            x: 0.5,
            y: 0.5,
            offsetY: -controlDistanceToParent,
            offsetX: -(2 * controlDistanceToParent + paddingBetweenControls), // To the left of the confirm button with a little spacing in-between
            cursorStyle: 'pointer',
            mouseUpHandler: cancelCrop,
            render: renderIcon(cancelIcon),
        });
        clipRectangle.controls.confirm = confirmControl;
        clipRectangle.controls.cancel = cancelControl;

        return;
    }

    public removeCropHelperObjects(): void {
        this.canvasManager.canvas.getObjects().forEach((object: FabricObject) => {
            if (object.id === CROP_AREA_OBJECT_ID || object.id === CROP_AREA_BACKGROUND_OBJECT_ID) {
                // Remove any event listeners (like the one that listens for deselect to prevent deselection)
                object.off();

                // And finally remove them
                this.canvasManager.canvas.remove(object);
            }
        });
    }
}

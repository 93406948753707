import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { SwUpdate } from '@angular/service-worker';
import moment from 'moment';
import { Subject, Subscription } from 'rxjs';
import { removeFromArray } from '@autoixpert/lib/arrays/remove-from-array';
import { sanitizeHtmlExceptStyle } from '@autoixpert/lib/html/sanitize-html-except-style';
import { getUserGroups } from '@autoixpert/lib/users/get-user-groups';
import {
    AffectedUserGroup,
    DowntimeNotification,
    ProcessContext,
} from '@autoixpert/models/notifications/downtime-notification';
import { Team } from '@autoixpert/models/teams/team';
import { User } from '@autoixpert/models/user/user';
import { LiveSyncServiceBase } from '../libraries/database/live-sync.service-base';
import { ApiErrorService } from './api-error.service';
import { FeathersSocketioService } from './feathers-socketio.service';
import { FrontendLogService } from './frontend-log.service';
import { LoggedInUserService } from './logged-in-user.service';
import { NetworkStatusService } from './network-status.service';
import { SyncIssueNotificationService } from './sync-issue-notification.service';

@Injectable()
export class DowntimeNotificationService extends LiveSyncServiceBase<DowntimeNotification> {
    constructor(
        protected httpClient: HttpClient,
        protected networkStatusService: NetworkStatusService,
        protected frontendLogService: FrontendLogService,
        protected syncIssueNotificationService: SyncIssueNotificationService,
        protected serviceWorker: SwUpdate,
        protected feathersSocketioService: FeathersSocketioService,
        protected loggedInUserService: LoggedInUserService,
        protected domSanitizer: DomSanitizer,
        protected apiErrorService: ApiErrorService,
    ) {
        super({
            serviceName: 'downtimeNotification',
            httpClient,
            networkStatusService,
            syncIssueNotificationService,
            serviceWorker,
            frontendLogService,
            feathersSocketioClient: feathersSocketioService,
        });

        this.loggedInUserService.getUser$().subscribe((user) => {
            this.user = user;
            this.filterDowntimeNotificationsForUser();
        });
        this.loggedInUserService.getTeam$().subscribe((team) => {
            this.team = team;
            this.filterDowntimeNotificationsForUser();
        });

        void this.loadDowntimeNotifications();

        // Only as soon as the user is present, this component will be initialized through *ngIf="user"
        this.subscribeToDowntimeNotificationSubjects();
    }

    private user: User;
    private team: Team;

    private mayHaveChanged$ = new Subject<number>();
    public readonly mayHaveChanged$$ = this.mayHaveChanged$.asObservable();

    public downtimeNotifications: DowntimeNotification[] = [];

    // Contains all downtime notifications that are relevant for the user.
    public filteredDowntimeNotificationsForUser: DowntimeNotification[] = [];

    // Contains all downtime notifications that are relevant for the header bar.
    // Does not contain notifications that are displayed in the contexts in the application.
    public filteredDowntimeNotificationsForHeader: DowntimeNotification[] = [];
    public downtimeNotificationsWith: DowntimeNotification[] = [];

    private subscriptions: Subscription[] = [];

    public subscribeToDowntimeNotificationSubjects() {
        // Unsubscribe from previous authentications.
        if (this.subscriptions.length) {
            this.subscriptions.forEach((subscription) => subscription.unsubscribe());
            this.subscriptions.length = 0;
        }

        // "Created" event
        const createSubscription = this.createdFromExternalServerOrLocalBroadcast$.subscribe({
            next: (newNotification) => {
                // Notification already exists
                if (this.downtimeNotifications.find((notification) => notification._id === newNotification._id)) return;
                this.downtimeNotifications.push({
                    ...newNotification,
                    message: sanitizeHtmlExceptStyle(newNotification.message, this.domSanitizer),
                });
                this.filterDowntimeNotificationsForUser();
            },
        });
        this.subscriptions.push(createSubscription);

        // "Patched" event
        const patchSubscription = this.patchedFromExternalServerOrLocalBroadcast$.subscribe({
            next: (patchedNotification) => {
                const index = this.downtimeNotifications.findIndex(
                    (notification) => notification._id === patchedNotification.patchedRecord._id,
                );
                if (index > -1) {
                    this.downtimeNotifications.splice(index, 1, patchedNotification.patchedRecord);
                } else {
                    this.downtimeNotifications.push(patchedNotification.patchedRecord);
                }
                this.filterDowntimeNotificationsForUser();
            },
        });
        this.subscriptions.push(patchSubscription);

        // "Deleted" event
        const deleteSubscription = this.deletedInLocalDatabase$.subscribe({
            next: (value) => {
                const notification = this.downtimeNotifications.find((notification) => notification._id === value);
                removeFromArray(notification, this.downtimeNotifications);
                this.filterDowntimeNotificationsForUser();
            },
        });
        this.subscriptions.push(deleteSubscription);

        // Show downtime notification for old frontend client
        this.subscriptions.push(
            this.localOnly$.subscribe({
                next: (downtimeNotification) => {
                    this.downtimeNotifications.push(downtimeNotification);
                    this.filterDowntimeNotificationsForUser();
                },
            }),
        );
    }

    /**
     * Get all downtimes that are currently active.
     */
    public async loadDowntimeNotifications() {
        try {
            // Fetch and store locally *all* downtime notifications. Filtering will be done locally so that in case of adding a new interface user, the notification will be displayed right away.
            const notifications = await this.find({ validUntil: { $gte: moment().format() } }).toPromise();
            this.downtimeNotifications = [
                ...notifications.map((notification) => {
                    return {
                        ...notification,
                        // Prevent injection attacks by sanitizing HTML-containing message.
                        message: sanitizeHtmlExceptStyle(notification.message, this.domSanitizer),
                    };
                }),
            ];
            this.filterDowntimeNotificationsForUser();
            this.mayHaveChanged$.next(this.filteredDowntimeNotificationsForHeader.length);
        } catch (error) {
            this.apiErrorService.handleAndRethrow({
                axError: error,
                handlers: {},
                defaultHandler: {
                    title: 'Ausfallmeldungen nicht geladen',
                    body: 'Versuche es später erneut. Sollte das Problem weiterhin auftreten, kontaktiere bitte die <a href="/Hilfe" target="_blank">Hotline</a>.',
                },
            });
        }
    }

    public filterDowntimeNotificationsForUser() {
        if (!this.user || !this.team) {
            this.filteredDowntimeNotificationsForUser = [];
            return;
        }

        const userGroups: AffectedUserGroup[] = getUserGroups({ user: this.user, team: this.team });

        this.filteredDowntimeNotificationsForUser = this.downtimeNotifications.filter((downtime) => {
            //*****************************************************************************
            //  Filter by User Group
            //****************************************************************************/
            if (!userGroups.includes(downtime.affectedUserGroup)) return false;
            /////////////////////////////////////////////////////////////////////////////*/
            //  END Filter by User Group
            /////////////////////////////////////////////////////////////////////////////*/

            //*****************************************************************************
            //  Filter by Date
            //****************************************************************************/
            let registeredAsDeleted: boolean = false;
            let deletedToday: boolean = false;
            const isDowntimeToday: boolean = this.downtimeIsToday(downtime);

            const localStorageEntry: { closed: boolean; timestamp: string } = store.get(
                `downtimeNotification-closed-${downtime._id}`,
            );

            if (localStorageEntry) {
                registeredAsDeleted = localStorageEntry.closed;
                deletedToday =
                    moment(localStorageEntry.timestamp).format('DD.MM.YYYY') === moment().format('DD.MM.YYYY');
            }

            return (
                (moment().isBetween(
                    moment(downtime.validFrom).subtract(downtime.numberOfDaysVisibleInAdvance, 'days'),
                    moment(downtime.validUntil),
                ) &&
                    !registeredAsDeleted) ||
                (registeredAsDeleted && isDowntimeToday && !deletedToday)
            );
            /////////////////////////////////////////////////////////////////////////////*/
            //  END Filter by Date
            /////////////////////////////////////////////////////////////////////////////*/
        });

        this.mayHaveChanged$.next(this.filteredDowntimeNotificationsForHeader.length);
    }

    public getDowntimeNotificationsForContext(context: ProcessContext): DowntimeNotification[] {
        return this.filteredDowntimeNotificationsForUser.filter((downtime) =>
            downtime.showInContexts?.includes(context),
        );
    }

    private downtimeIsToday(downtime: DowntimeNotification): boolean {
        return moment(downtime.validFrom).format('DD.MM.YYYY') === moment().format('DD.MM.YYYY');
    }

    public removeDowntimeNotificationFromSession(downtime: DowntimeNotification) {
        removeFromArray(downtime, this.downtimeNotifications);
        // Remember the downtimes _id via local storage, so the downtime will not be shown again if the user closes it.
        const localStorageEntry: { closed: boolean; timestamp: string } = {
            closed: true,
            timestamp: moment().format(),
        };
        store.set(`downtimeNotification-closed-${downtime._id}`, localStorageEntry);
        this.filterDowntimeNotificationsForUser();
    }

    public localOnly$ = new Subject<DowntimeNotification>();

    /**
     * Create a temporary downtime notification that is not synced to the server.
     */
    public createLocalOnly(downtimeNotification: DowntimeNotification) {
        this.localOnly$.next(downtimeNotification);
    }
}

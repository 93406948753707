import { ReplacementValueCorrectionItem } from '@autoixpert/models/reports/replacement-value-corrections/replacement-value-correction-item';

export class ReplacementValueCorrectionConfig {
    constructor(options: Partial<ReplacementValueCorrectionConfig> = {}) {
        Object.assign(this, options);
    }
    /**
     * List of previous (repaired and unrepaired) damages, that decrease the replacement value.
     */
    decreases: ReplacementValueCorrectionItem[] = [];
    /**
     * List of things, that increase the replacement value.
     */
    increases: ReplacementValueCorrectionItem[] = [];
    /**
     * Sum of all decreases.
     */
    totalDecrease: number = 0;
    /**
     * Sum of all increases.
     */
    totalIncrease: number = 0;
    /**
     * If each value increase/decrease should be printed together with its value or
     * if only the sum of all corrections should be printed in the report.
     */
    printSumOnly: boolean;
}

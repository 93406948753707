<div class="dialog-overlay" (mousedown)="handleOverlayClick($event)" @dialogEnterAndLeave>
    <div class="dialog-container">
        <!--============================================-->
        <!-- Left Column - Report types and recipients -->
        <!--============================================-->
        <div id="left-column">
            <div class="label">Definiere Standardreihenfolgen für Gutachten- und Empfängertypen.</div>
            <!--============================================-->
            <!-- Report Type Selector -->
            <!--============================================-->
            <ng-container *ngFor="let defaultDocumentOrderGroup of defaultDocumentOrderGroups">
                <default-document-order-group
                    *ngIf="shouldGroupBeDisplayed(defaultDocumentOrderGroup)"
                    [team]="team"
                    [isDefault]="false"
                    [defaultDocumentOrderGroup]="defaultDocumentOrderGroup"
                    [selectedDefaultDocumentOrder]="selectedDefaultDocumentOrder"
                    [availableReportTypes]="availableReportTypes"
                    (defaultDocumentOrderSelected)="selectDefaultDocumentOrder(defaultDocumentOrderGroup, $event)"
                    (defaultDocumentOrderGroupDeleted)="deleteDefaultDocumentOrderGroup(defaultDocumentOrderGroup)"
                    (defaultDocumentOrderGroupChanged)="
                        onDefaultDocumentOrderGroupChange()
                    "></default-document-order-group>
            </ng-container>

            <!-- Add new Override -->
            <div
                id="add-default-document-group"
                class="dashed-button"
                matTooltip="Mit einer neuen Gruppe kannst du für bestimmte Gutachtentypen (z. B. nur für die Oldtimerbewertung oder für alle Bewertungsgutachten) eine abweichende Reihenfolge und Konfiguration einstellen."
                (click)="addDefaultDocumentOrderGroup()">
                + Gruppe
            </div>
            <!--============================================-->
            <!-- END Report Type Selector -->
            <!--============================================-->

            <div id="allow-order-per-recipient">
                <mat-slide-toggle
                    [(ngModel)]="team.preferences.allowCustomDocumentOrderPerRecipient"
                    [disabled]="!hasAccessRight(user, 'editTextsAndDocumentBuildingBlocks')"
                    (change)="onAllowCustomDocumentOrderPerRecipientChange($event)"></mat-slide-toggle>

                <div class="toggle-text">
                    freie Reihenfolge pro Empfänger
                    <span
                        class="help-indicator"
                        matTooltip="Du kannst eine unterschiedliche Reihenfolge pro Empfängertyp festlegen. Damit kannst du auch im Gutachten die Dokumente pro Empfänger anders sortieren.">
                        ?
                    </span>
                </div>
            </div>
        </div>
        <!--============================================-->
        <!-- END Left Column - Garage Info & Fee Sets -->
        <!--============================================-->

        <!--============================================-->
        <!-- Right Column - Editor -->
        <!--============================================-->
        <div id="right-column">
            <mat-icon class="dialog-close-icon" (click)="closeFullDocumentConfigurationDialog()" matTooltip="Schließen">
                close
            </mat-icon>

            <h2>Reihenfolge Gesamt-PDF</h2>

            <div class="label">Bestimme, in welcher Reihenfolge deine Dokumente standardmäßig sortiert werden.</div>
            <!--============================================-->
            <!-- List of Documents -->
            <!--============================================-->
            <div id="document-list-container" cdkDropList (cdkDropListDropped)="onDocumentOrderItemReordered($event)">
                <div
                    class="document-container"
                    *ngFor="let defaultDocumentOrderItem of selectedDefaultDocumentOrder.items"
                    [ngClass]="{ excluded: !defaultDocumentOrderItem.includedInFullDocument }"
                    cdkDrag>
                    <mat-icon class="drag-handle" cdkDragHandle>drag_indicator</mat-icon>

                    <div class="document-title">
                        {{ getGermanDocumentTitle(defaultDocumentOrderItem) }}
                    </div>

                    <div class="document-submenu-and-indicators-container">
                        <!--********** Permanent user upload **********-->
                        <mat-icon
                            class="permanent-user-uploaded-document-icon"
                            matTooltip="Dieses Dokument wurde von dir hochgeladen."
                            *ngIf="defaultDocumentOrderItem.documentType === 'manuallyUploadedPdf'">
                            storage
                        </mat-icon>

                        <!--********** Slide Toggle **********-->
                        <!-- Shift + Klick toggles all -->
                        <mat-slide-toggle
                            class="document-slide-toggle"
                            [(ngModel)]="defaultDocumentOrderItem.includedInFullDocument"
                            (click)="handleToggleClick($event)"
                            (change)="handleToggleChange($event)"></mat-slide-toggle>
                    </div>
                </div>
            </div>
            <!--============================================-->
            <!-- END List of Documents -->
            <!--============================================-->
        </div>
        <!--============================================-->
        <!-- END Right Column - Editor -->
        <!--============================================-->
    </div>
</div>

<div id="layout-container">
    <!--============================================-->
    <!-- Menu -->
    <!--============================================-->
    <div id="menu-column"></div>
    <!--============================================-->
    <!-- END Menu -->
    <!--============================================-->

    <!--============================================-->
    <!-- Reporting Column -->
    <!--============================================-->
    <div id="reporting-column">
        <!--============================================-->
        <!-- Graph -->
        <!--============================================-->
        <div id="graph-container" class="card card-with-padding">
            <!--============================================-->
            <!-- Graph Header -->
            <!--============================================-->
            <div id="graph-header">
                <!--********** Report Name **********-->
                <div id="report-name-container">
                    <mat-icon
                        class="toolbar-icon"
                        matTooltip="Zurück zur Übersicht"
                        (click)="navigateBackToAnalyticsList()">
                        arrow_back
                    </mat-icon>
                    <h2>{{ chartTitle }}</h2>
                </div>

                <analytics-filter-button [filterRef]="filter"></analytics-filter-button>

                <!--********** Date Filter **********-->
                <date-range-picker
                    [(dateFrom)]="filterAnalyticsFrom"
                    [(dateTo)]="filterAnalyticsTo"
                    (dateRangeChange)="updateAnalytics(); rememberDateRange()"></date-range-picker>
            </div>

            <!--============================================-->
            <!-- END Graph Header -->
            <!--============================================-->

            <analytics-filter (filterChange)="updateAnalytics()" (initialized)="filtersLoaded()"></analytics-filter>

            <div class="chart-container">
                <div class="chart-placeholder" *ngIf="analyticsPending">
                    <img class="loading-indicator" src="/assets/images/icons/loading-indicator.svg" alt="" />
                    Lade Auswertung ...
                </div>
                <div class="chart-placeholder" *ngIf="!analyticsPending && records.length === 0">
                    <img class="no-records-to-display-image" src="/assets/images/icons/no-data-to-display.png" />
                    <div class="no-data-found-message">Keine Daten gefunden</div>
                    Probiere doch andere Filter.
                </div>
                <div id="chart" *ngIf="!analyticsPending && records.length"></div>
            </div>

            <!--============================================-->
            <!-- Graph Summary -->
            <!--============================================-->
            <div id="graph-summary-container" class="card">
                <!--********** Total **********-->
                <div class="graph-summary-item-container">
                    <!--********** Label **********-->
                    <div class="graph-summary-item-label">
                        Gesamtumsatz
                        <span class="series-color analytics-series-1"></span>
                    </div>

                    <!--********** Value **********-->
                    <div class="graph-summary-item-value">{{ totalRevenue | number: '1.0-0' }} €</div>
                </div>

                <!--********** Invoices **********-->
                <div class="graph-summary-item-container">
                    <!--********** Label **********-->
                    <div class="graph-summary-item-label">Rechnungen</div>

                    <!--********** Value **********-->
                    <div class="graph-summary-item-value">
                        {{ totalNumberOfInvoices | number: '1.0-0' }}
                    </div>
                </div>

                <!--********** Average **********-->
                <div class="graph-summary-item-container">
                    <!--********** Label **********-->
                    <div class="graph-summary-item-label">Umsatz pro Rechnung</div>

                    <!--********** Value **********-->
                    <div class="graph-summary-item-value">
                        {{ totalRevenue / totalNumberOfInvoices || 0 | number: '1.0-0' }} €
                    </div>
                </div>

                <!--********** Places **********-->
                <div class="graph-summary-item-container">
                    <!--********** Label **********-->
                    <div class="graph-summary-item-label">Hersteller</div>

                    <!--********** Value **********-->
                    <div class="graph-summary-item-value">
                        {{ records.length }}
                    </div>
                </div>
            </div>
            <!--============================================-->
            <!-- END Graph Summary -->
            <!--============================================-->
        </div>
        <!--============================================-->
        <!-- END Graph -->
        <!--============================================-->

        <!--============================================-->
        <!-- Series List -->
        <!--============================================-->
        <div id="series-list-card" class="card card-with-padding">
            <div id="series-list-toolbar">
                <export-invoice-analytics-records-icon
                    [records]="records"
                    [summaryDownloadName]="chartTitle"></export-invoice-analytics-records-icon>
            </div>
            <div id="series-list-table-container">
                <table id="series-list">
                    <thead>
                        <tr>
                            <th class="series-entry-title-heading">Hersteller</th>
                            <th class="series-entry-value-heading">
                                <span class="series-color analytics-series-1"></span>
                                Gesamtumsatz (netto)
                            </th>
                            <th class="series-entry-value-heading">Rechnungen</th>
                            <th class="series-entry-value-heading">Umsatz pro Rechnung</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr class="series-entry-row" *ngFor="let record of records">
                            <td class="series-entry-title-cell" [ngClass]="{ 'not-available': !record.manufacturer }">
                                <div class="car-manufacturer-container">
                                    <img
                                        [src]="iconFilePathForCarBrand(record.manufacturer, 'large')"
                                        alt="{{ record.manufacturer }}"
                                        class="car-make-option-image"
                                        *ngIf="iconForCarBrandExists(record.manufacturer)" />
                                    {{ record.manufacturer || 'Nicht angegeben' }}
                                </div>
                            </td>
                            <td class="series-entry-value">{{ record.totalRevenueNet | number: '1.0-0' }} €</td>
                            <td class="series-entry-value">
                                <span
                                    invoice-list-overlay-anchor
                                    [invoiceIds]="record.idsOfAllInvoices"
                                    [displayNetValues]="true">
                                    {{ record.idsOfRegularInvoices.length }} Stk.
                                </span>
                            </td>
                            <td class="series-entry-value">
                                {{ record.totalRevenueNet / record.idsOfRegularInvoices.length || 0 | number: '1.0-0' }}
                                €
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
        <!--============================================-->
        <!-- END Series List -->
        <!--============================================-->
    </div>
    <!--============================================-->
    <!-- END Reporting Column -->
    <!--============================================-->
</div>

import { HttpClient, HttpResponse } from '@angular/common/http';
import { ChangeDetectorRef, Injectable, NgZone } from '@angular/core';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { BehaviorSubject } from 'rxjs';
import { apiBasePath } from '@autoixpert/external-apis/api-base-path';
import { addDocumentToReport } from '@autoixpert/lib/documents/add-document-to-report';
import { round } from '@autoixpert/lib/numbers/round';
import { isDatTestAccount } from '@autoixpert/lib/users/is-dat-test-account';
import { isDatUserComplete } from '@autoixpert/lib/users/is-dat-user-complete';
import { GarageFeeSet } from '@autoixpert/models/contacts/garage-fee-set';
import { DocumentMetadata } from '@autoixpert/models/documents/document-metadata';
import { AxError } from '@autoixpert/models/errors/ax-error';
import { Repair } from '@autoixpert/models/reports/damage-description/repair';
import { DatValuation } from '@autoixpert/models/reports/market-value/dat-valuation';
import { Report } from '@autoixpert/models/reports/report';
import { Team } from '@autoixpert/models/teams/team';
import { User } from '@autoixpert/models/user/user';
import {
    ConnectDatDossierDialogComponent,
    ConnectDatDossierDialogData,
} from 'src/app/reports/details/shared/connect-dat-dossier-dialog/connect-dat-dossier-dialog.component';
import { clearRepairCalculationData } from '../libraries/damage-calculation/clear-repair-calculation-data';
import { determineDamageType } from '../libraries/damage-calculation/determine-damage-type';
import { persistExportedGarageFeeSet } from '../libraries/damage-calculation/persist-exported-garage-fee-set';
import { automaticallyAttachVxsIfConfigured } from '../libraries/dat-helpers/automatically-attach-vxs-if-configured';
import { getDatErrorHandlers } from '../libraries/error-handlers/get-dat-error-handlers';
import { confirmToRemoveValueIncreaseFromReport } from '../libraries/report/confirm-to-remove-value-increase-from-report';
import { ApiErrorHandleParameters, ApiErrorService } from './api-error.service';
import { DatAuthenticationService, DatJwtResponse } from './dat-authentication.service';
import { DownloadService } from './download.service';
import { LoggedInUserService } from './logged-in-user.service';
import { NetworkStatusService } from './network-status.service';
import { NewWindowService } from './new-window.service';
import { ReportService } from './report.service';
import { ToastService } from './toast.service';
import { TutorialStateService } from './tutorial-state.service';
import { UserPreferencesService } from './user-preferences.service';

@Injectable()
export class DatDamageCalculationService {
    constructor(
        private loggedInUserService: LoggedInUserService,
        private httpClient: HttpClient,
        private datAuthenticationService: DatAuthenticationService,
        private toastService: ToastService,
        private reportService: ReportService,
        private apiErrorService: ApiErrorService,
        private newWindowService: NewWindowService,
        private tutorialStateService: TutorialStateService,
        private userPreferences: UserPreferencesService,
        private dialog: MatDialog,
        private networkStatusService: NetworkStatusService,
        private downloadService: DownloadService,
    ) {
        this.user = this.loggedInUserService.getUser();
        this.team = this.loggedInUserService.getTeam();
    }

    private user: User;
    private team: Team;

    /**
     * Allow the components to display a loading spinner while the request is pending.
     */
    private isRequestPending$ = new BehaviorSubject<boolean>(false);
    public readonly isRequestPending$$ = this.isRequestPending$.asObservable();

    /**
     * Create a new DAT damage calculation dossier.
     *
     */
    public async create({
        report,
        selectedDekraFeeSet,
    }: {
        report: Report;
        selectedDekraFeeSet?: GarageFeeSet;
    }): Promise<number> {
        if (report.state === 'done') return;

        if (!isDatUserComplete(this.user)) {
            throw new AxError({
                code: 'INCOMPLETE_DAT_USER',
                message: 'Bitte vervollständige deine DAT-Zugangsdaten in den Einstellungen.',
            });
        }

        if (!this.networkStatusService.isOnline()) {
            throw new AxError({
                code: 'CLIENT_IS_OFFLINE',
                message: 'DAT calculations are not available offline.',
            });
        }

        if (!report.car.datIdentification) {
            throw new AxError({
                code: 'DAT_IDENTIFICATION_MISSING',
                message:
                    'Bitte führe im Reiter "Fahrzeugauswahl" eine DAT-VIN-Abfrage aus, bevor du die DAT-Kalkulation startest.',
            });
        }

        this.isRequestPending$.next(true);
        const infoToast = this.toastService.info('DAT-Vorgang erstellen', 'Dies kann einige Sekunden dauern.', {
            timeOut: 10_000,
        });

        const datJwt = await this.datAuthenticationService.getJwt();

        // DAT doesn't understand floats in the displacement field (Hubraum)
        if (report.car.engineDisplacement && round(report.car.engineDisplacement, 1).toString().includes('.')) {
            report.car.engineDisplacement = round(report.car.engineDisplacement, 0);
        }

        // Set the calculation provider and save the report to the server.
        report.damageCalculation.repair.calculationProvider = 'dat';
        await this.reportService.put(report, { waitForServer: true });

        let datDossierId: number;
        try {
            const damageCalculationResponse = await this.httpClient
                .post<{ datDossierId: number }>(
                    `/api/v0/reports/${report._id}/damageCalculation`,
                    {},
                    {
                        headers: DatAuthenticationService.getDatJwtHeaders(datJwt),
                    },
                )
                .toPromise();
            datDossierId = damageCalculationResponse.datDossierId;
        } catch (error) {
            // Remove repair calculation data to avoid inconsistencies.
            clearRepairCalculationData(report);
            throw error;
        }

        // Save the new contractId to the report and save the report back to the server.
        report.damageCalculation.repair.datCalculation = {
            dossierId: datDossierId,
        };

        // Save the selected garage fee set to the report to be able to compare it later.
        persistExportedGarageFeeSet(report, selectedDekraFeeSet);

        try {
            await this.reportService.put(report, { waitForServer: true });
        } catch (error) {
            console.error('The report could not be saved back to the server after DAT´s contractId was added.', {
                error,
            });
        }

        this.isRequestPending$.next(false);
        this.toastService.remove(infoToast.id); // Close the info toast from above
        return datDossierId;
    }

    public async updateDossier({
        report,
        selectedDekraFeeSet,
    }: {
        report: Report;
        selectedDekraFeeSet?: GarageFeeSet;
    }): Promise<number> {
        if (report.state === 'done') return;

        if (!isDatUserComplete(this.user)) {
            throw new AxError({
                code: 'INCOMPLETE_DAT_USER',
                message: 'Bitte vervollständige deine DAT-Zugangsdaten in den Einstellungen.',
            });
        }

        if (!this.networkStatusService.isOnline()) {
            throw new AxError({
                code: 'CLIENT_IS_OFFLINE',
                message: 'DAT calculations are not available offline.',
            });
        }

        if (!report.car.datIdentification) {
            throw new AxError({
                code: 'DAT_IDENTIFICATION_MISSING',
                message:
                    'Bitte führe im Reiter "Fahrzeugauswahl" eine DAT-VIN-Abfrage aus, bevor du die DAT-Kalkulation startest.',
            });
        }

        // Only send data if the report has a contractId already
        if (report.damageCalculation.repair.datCalculation.dossierId) {
            const datJwt = await this.datAuthenticationService.getJwt();
            try {
                await this.httpClient
                    .patch(
                        `${apiBasePath}/reports/${report._id}/damageCalculation`,
                        {},
                        {
                            headers: DatAuthenticationService.getDatJwtHeaders(datJwt),
                        },
                    )
                    .toPromise();
                this.toastService.success('Übertragung erfolgreich', 'Daten erfolgreich gesendet.');

                persistExportedGarageFeeSet(report, selectedDekraFeeSet);
            } catch (error) {
                this.apiErrorService.handleAndRethrow({
                    axError: error,
                    handlers: {
                        ...getDatErrorHandlers(),
                    },
                    defaultHandler: (error) => ({
                        title: 'Fehler in DAT-Schnittstelle',
                        body:
                            `Die Schadenskalkulation konnte nicht erstellt werden.` +
                            (error.data?.datErrorMessage
                                ? `<br><br>Fehlermeldung der DAT: ${error.data.datErrorMessage}`
                                : ''),
                        partnerLogo: 'dat',
                    }),
                });
            }
            return report.damageCalculation.repair.datCalculation.dossierId;
        } else {
            throw new AxError({
                code: 'UPDATING_DAT_DOSSIER_FAILED_TO_DUE_MISSING_DAT_DOSSIER_ID',
                message: `The DAT dossier cannot be updated if the aX report is not yet linked to a DAT dossier.`,
                data: {
                    reportId: report._id,
                },
            });
        }
    }

    public async retrieveResults({ report }: { report: Report }): Promise<void> {
        if (report.state === 'done') return;
        if (!report.damageCalculation.repair.datCalculation?.dossierId) return;

        this.isRequestPending$.next(true);

        if (!report.damageCalculation.repair.datCalculation.dossierId) {
            throw Error('Missing DAT contract ID. Cannot retrieve calculation results.');
        }

        let datDamageCalculationResponse: GetDatDamageCalculationResponse;
        try {
            const datJwt = await this.datAuthenticationService.getJwt();

            datDamageCalculationResponse = await this.httpClient
                .get<GetDatDamageCalculationResponse>(`/api/v0/reports/${report._id}/damageCalculation`, {
                    headers: DatAuthenticationService.getDatJwtHeaders(datJwt),
                })
                .toPromise();
        } catch (error) {
            this.isRequestPending$.next(false);
            this.apiErrorService.handleAndRethrow({
                axError: error,
                handlers: {
                    ...getDatErrorHandlers(),
                },
                defaultHandler: (error) => ({
                    title: 'Fehler in DAT-Schnittstelle',
                    body:
                        `Die Schadenskalkulation konnte nicht importiert werden.` +
                        (error.data && error.data.datErrorMessage
                            ? `<br><br>Fehlermeldung der DAT: ${error.data.datErrorMessage}`
                            : ''),
                    partnerLogo: 'dat',
                }),
            });
        }

        const increasedValueExistedBefore: boolean = !!report.damageCalculation.repair.increasedValue;

        Object.assign<Repair, GetDatDamageCalculationResponse>(
            report.damageCalculation.repair,
            datDamageCalculationResponse,
        );

        // If there's a value increase in the calculation, copy that to the report so that it's printed.
        if (report.damageCalculation.repair.increasedValue) {
            report.valuation.valueIncrease = report.damageCalculation.repair.increasedValue;
        }
        // If the value increase has been removed from the calculation, offer the user to remove it from the report as well.
        else if (increasedValueExistedBefore && report.valuation.valueIncrease) {
            await confirmToRemoveValueIncreaseFromReport({ report, dialogService: this.dialog });
        }

        automaticallyAttachVxsIfConfigured({ report, userPreferences: this.userPreferences });

        // If the user wants the damage calculation to be available as an individual document in the print and send screen, create it here.
        if (report.type !== 'liability' || this.userPreferences.createDocumentMetadataForDamageCalculation) {
            addDocumentToReport({
                report: report,
                team: this.team,
                newDocument: new DocumentMetadata({
                    type: 'datDamageCalculation',
                    title: 'DAT Kalkulation',
                    createdBy: this.user._id,
                }),
                documentGroup: 'report',
            });
        }

        this.tutorialStateService.markUserTutorialStepComplete('datCalculationImported');

        determineDamageType(report, this.userPreferences);
        await this.reportService.put(report, { waitForServer: true });
        this.isRequestPending$.next(false);
    }

    public async openDossierConnectionDialog({ report }: { report: Report }) {
        if (report.state === 'done') return;

        if (isDatTestAccount(this.user.datUser)) {
            throw new AxError({
                code: 'NOT_AVAILABLE_FOR_DAT_TEST_ACCOUNT',
                message:
                    "This function is available once you've entered your own DAT account in the <a href='/Einstellungen#calculation-providers-section'>settings</a>.",
            });
        }

        const datDossierId = await this.dialog
            .open<ConnectDatDossierDialogComponent, ConnectDatDossierDialogData, DatValuation['dossierId']>(
                ConnectDatDossierDialogComponent,
                {
                    data: {
                        licensePlate: report.car.licensePlate,
                        vin: report.car.vin,
                        dossierType: 'damageCalculation',
                    },
                    panelClass: 'dialog-without-padding',
                    minWidth: '400px',
                    maxWidth: '90vw',
                },
            )
            .afterClosed()
            .toPromise();

        console.log('datDossierId', datDossierId);
        if (!datDossierId) {
            console.log('No datDossierId provided. Aborting.');
            return;
        }

        report.damageCalculation.repair.datCalculation = {
            dossierId: datDossierId,
        };
        report.damageCalculation.repair.calculationProvider = 'dat';

        // Save the report so that the server knows about the new datDossierId, then retrieve the results.
        await this.reportService.put(report, { waitForServer: true });
        await this.retrieveResults({ report });
    }

    public async reset({ report }: { report: Report }): Promise<void> {
        if (report.state === 'done') return;

        try {
            const datJwt = await this.datAuthenticationService.getJwt();
            await this.httpClient
                .delete(`/api/v0/reports/${report._id}/damageCalculation`, {
                    headers: DatAuthenticationService.getDatJwtHeaders(datJwt),
                })
                .toPromise();
        } catch (error) {
            // Don't do anything if the DAT failed to delete the calculation. The reference in the autoiXpert report was deleted, so the user can go on.
            console.error(
                'Failed to delete the damage calculation from the DAT server. No further action required.',
                error,
            );
        }

        await this.unlink({ report });
    }

    /**
     * Clear all imported data without deleting the DAT calculation from DAT.de
     */
    public async unlink({ report }: { report: Report }) {
        if (report.state === 'done') return;

        clearRepairCalculationData(report);
        await this.reportService.put(report, { waitForServer: true });
    }

    public async downloadVxs({ report }: { report: Report }): Promise<void> {
        if (!this.networkStatusService.isOnline()) {
            this.toastService.offline(
                'Offline nicht verfügbar',
                'Der VXS-Download ist verfügbar, sobald du wieder online bist.',
            );
            return;
        }

        const datJwt = await this.datAuthenticationService.getJwt();
        this.httpClient
            .get(`/api/v0/reports/${report._id}/vxsExport`, {
                observe: 'response',
                responseType: 'blob',
                headers: DatAuthenticationService.getDatJwtHeaders(datJwt),
            })
            .subscribe({
                next: (response: HttpResponse<Blob>) => {
                    this.downloadService.downloadBlobResponseWithHeaders(response);
                },
                error: (error) => {
                    this.apiErrorService.handleAndRethrow({
                        axError: error,
                        handlers: { ...getDatErrorHandlers() },
                        defaultHandler: {
                            title: 'VXS-Download gescheitert',
                            body: 'Bitte importiere die DAT-Kalkulation erneut oder kontaktiere die <a href="/Hilfe" target="_blank">Hotline</a>.',
                        },
                    });
                },
            });
    }

    ///**
    //* Lets the aX server send the current aX data to the DAT server.
    //*/
    //updateDossier: async (): Promise<number> => {
    //},

    //*****************************************************************************
    //  DAT Damage Calculation UI
    //****************************************************************************/
    /**
     * Get the URL and parameters for the DAT damage calculation interface.
     */
    private getDamageCalculationUrl(report: Report, { onlyIdentification }: { onlyIdentification?: boolean } = {}) {
        let urlDatDamageCalculationInterface = `/assets/dat-schadenskalkulation/index.html?datDossierId=${report.damageCalculation.repair.datCalculation.dossierId}&datCustomerNumber=${this.user.datUser.customerNumber}`;

        // If the user uses a myclaim account, open the myclaim interface.
        if (this.user.datUser.isMyclaimUser) {
            urlDatDamageCalculationInterface += '&myclaim=true';
        }
        // If the user is langsteff, use the DAT test environment gold.dat.de
        if (this.user.datUser.username === 'langsteff') {
            urlDatDamageCalculationInterface += '&useTestEnvironment=true';
        }

        // If the vehicle could not be identified via a VIN request, DAT€Code request or if the vehicle simply cannot be calculated via DAT, start in the
        // vehicle selection screen.
        if (!report.car.datIdentification.isDamageCalculationPossible) {
            urlDatDamageCalculationInterface += '&phantomDamageCalculation=true';
        }

        // If the report is locked, jump to calculation results page
        if (report.state === 'done') {
            urlDatDamageCalculationInterface += '&viewResults=true';
        }

        // If the interface is only used for identification, stop after the equipment selection.
        if (onlyIdentification) {
            urlDatDamageCalculationInterface += '&lastPage=equipmentSelection';
        }

        return urlDatDamageCalculationInterface;
    }

    /**
     * Opens the DAT damage calculation interface in a new window.
     * The provided callback function will be triggered when the user has finished working with the DAT interface.
     * E.g. for fetching the results from DAT and updating the report.
     */
    public openDamageCalculationUI({
        report,
        triggeringComponent,
        onFinish,
        options = {},
    }: {
        report: Report;
        triggeringComponent: ComponentWithNgZoneAndChangeDetectorRef;
        onFinish: () => unknown;
        options?: { onlyIdentification?: boolean };
    }) {
        // Open the Damage Calculation in a new window.
        const datUiUrl = this.getDamageCalculationUrl(report, options);
        this.newWindowService.open(datUiUrl);

        const runCallbackAndTriggerChangeDetection = () => {
            triggeringComponent.ngZone.run(async () => {
                await onFinish();

                // Trigger change detection because angular does not detect changes when this function is triggered from dat-schadenskalkulation.html
                // Only check though if the component still exists
                if (!triggeringComponent.changeDetectorRef['destroyed']) {
                    triggeringComponent.changeDetectorRef.detectChanges();
                }
            });
        };

        // Expose a global callback for when the calculation has successfully completed. This is called by
        // the file dat-schadenskalkulation.html.
        const callbacks: DatUiCallbacks = {
            getDatCredentials: () => this.datAuthenticationService.getJwt(),
            done: runCallbackAndTriggerChangeDetection,
        };
        (<any>window).datDamageCalculation = callbacks;
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END DAT Damage Calculation UI
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Handle Errors
    //****************************************************************************/
    public handleAndRethrow({
        axError,
        defaultHandler,
    }: {
        axError: AxError;
        defaultHandler: ApiErrorHandleParameters['defaultHandler'];
    }) {
        this.isRequestPending$.next(false);
        this.apiErrorService.handleAndRethrow({
            axError,
            handlers: {
                ...getDatErrorHandlers(),
                DAT_IDENTIFICATION_MISSING: {
                    title: 'DAT-VIN-Abfrage nötig',
                    body: 'Bitte führe im Reiter "Fahrzeugauswahl" eine DAT-VIN-Abfrage aus, bevor du die DAT-Bewertung startest.',
                },
            },
            defaultHandler: defaultHandler ?? {
                title: 'Fehler in DAT-Schnittstelle',
                body: 'Probiere es erneut oder kontaktiere die Hotline.',
                partnerLogo: 'dat',
            },
        });
    }
    /////////////////////////////////////////////////////////////////////////////*/
    //  END Handle Errors
    /////////////////////////////////////////////////////////////////////////////*/
}

export interface GetDatDamageCalculationResponse {
    auxiliaryCostsNet: number;
    bodyworkLaborHours: number;
    correctedRepairCostsGross: number;
    correctedRepairCostsNet: number;
    discountGross: number;
    discountNet: number;
    electricLaborHours: number;
    garageLaborCostsNet: number;
    garageLaborHours: number;
    increasedValue: number;
    increasedValueTaxationType: string;
    isApproximateCalculation: boolean;
    isPhantomCalculation: boolean;
    hasInvalidPositions: boolean;
    lacquerCostsNet: number;
    lacquerLaborCostsNet: number;
    lacquerLaborHours: number;
    lacquerMaterialCostsNet: number;
    mechanicLaborHours: number;
    newForOldGross: number;
    newForOldNet: number;
    repairCostsGross: number;
    repairCostsNet: number;
    sparePartsCostsNet: number;
}

interface ComponentWithNgZoneAndChangeDetectorRef {
    ngZone: NgZone;
    changeDetectorRef: ChangeDetectorRef;
}

/**
 * This callbacks are used from the DAT interface.
 */
export interface DatUiCallbacks {
    // This callback is called inside sphinx for logging in the user.
    getDatCredentials: () => Promise<DatJwtResponse>;
    // This callback is called when the user has finished working with the DAT interface.
    // This should be used to load the data from DAT and update the report.
    done: () => void;
}

import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { removeFromArray } from '@autoixpert/lib/arrays/remove-from-array';
import { OfficeLocation } from '@autoixpert/models/teams/office-location';

@Component({
    selector: 'office-location-list',
    templateUrl: './office-location-list.component.html',
    styleUrls: ['./office-location-list.component.scss'],
})
export class OfficeLocationListComponent implements OnInit {
    /**
     * All office locations: The ones already selected and those which are still
     * available for selection.
     */
    @Input() public officeLocations!: OfficeLocation[];
    /**
     * Office locations which may be added to the list of selected office locations.
     *
     * When is this necessary?
     * In the document layout group preferences, every office location may be associated with only
     * one document layout group. Every document layout group has its own office location list
     * but only office locations which are not associated with any other document layout group
     * may be linked to a document layout group.
     */
    @Input() availableOfficeLocations!: OfficeLocation[];
    @Input() selectedOfficeLocationIds!: OfficeLocation['_id'][];
    @Input() horizontalDisplay: boolean = false;

    @Output() change = new EventEmitter<OfficeLocationSelectionChange>();

    ngOnInit() {
        // By default, all office locations can be added to the selection.
        if (!this.availableOfficeLocations) {
            this.availableOfficeLocations = this.officeLocations;
        }
    }

    public getSelectedOfficeLocations(): OfficeLocation[] {
        return this.officeLocations
            .filter((officeLocation) => this.selectedOfficeLocationIds?.includes(officeLocation._id) === true)
            .sort((officeLocationA, officeLocationB) => officeLocationA.title.localeCompare(officeLocationB.title));
    }

    public selectOfficeLocation(officeLocationId: OfficeLocation['_id']): void {
        this.selectedOfficeLocationIds.push(officeLocationId);

        this.change.emit({
            selectedOfficeLocationIds: this.selectedOfficeLocationIds,
            changedOfficeLocationId: officeLocationId,
            change: 'added',
        });
    }

    public unselectOfficeLocation(officeLocationId: OfficeLocation['_id']) {
        removeFromArray(officeLocationId, this.selectedOfficeLocationIds);

        this.change.emit({
            selectedOfficeLocationIds: this.selectedOfficeLocationIds,
            changedOfficeLocationId: officeLocationId,
            change: 'removed',
        });
    }
}

export interface OfficeLocationSelectionChange {
    selectedOfficeLocationIds: OfficeLocation['_id'][];
    changedOfficeLocationId: OfficeLocation['_id'];
    change: 'added' | 'removed';
}

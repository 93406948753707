import { ServerError } from '@autoixpert/models/errors/ax-error';

export function extractConstructionTimesFromDatBaseModelName(datBaseModelName) {
    // Use these regular expressions to extract the production time frame
    const regExBaseModels = {
        from: /(.+)\((\d{2}\.)? ?(\d{4}) ?-?>\)$/,
        fromAndTo: /(.+)\((\d{4})->?(\d{4})\)$/,
        to: /(.+)\( ?->(\d{2}\.)?(\d{4})\)$/,
    };

    let matches;
    let manufacturingStart = null;
    let manufacturingEnd = null;
    let baseModelName: string;
    if (datBaseModelName === null || datBaseModelName === undefined) {
        baseModelName = '';
    }
    // FROM
    else if (regExBaseModels.from.test(datBaseModelName)) {
        // Match "Giulietta (191) (2010->)" and "Audi R8 (08.2010->)"
        matches = datBaseModelName.match(regExBaseModels.from);
        baseModelName = matches[1];
        manufacturingStart = `${matches[2] || ''}${matches[3]}`;
    }
    // FROM and TO
    else if (regExBaseModels.fromAndTo.test(datBaseModelName)) {
        // Match "Feroza (F300)(1989->1997)"
        matches = datBaseModelName.match(regExBaseModels.fromAndTo);
        baseModelName = matches[1];
        manufacturingStart = matches[2];
        manufacturingEnd = matches[3];
    }
    // TO
    else if (regExBaseModels.to.test(datBaseModelName)) {
        // Match "Charmant (A35/45/60)(->1988)"
        matches = datBaseModelName.match(regExBaseModels.to);
        baseModelName = matches[1];
        manufacturingEnd = `${matches[2] || ''}${matches[3]}`;
    } else {
        baseModelName = datBaseModelName;
    }

    if (typeof baseModelName !== 'string') {
        baseModelName = '' + baseModelName;
    }

    if (!baseModelName) {
        // There are DAT vehicles that do not have a base model name. In that case, return null.
        try {
            baseModelName = datBaseModelName?.trim();
        } catch (error) {
            throw new ServerError({
                code: 'DAT_BASE_MODEL_TRIM_FAILED',
                message: "The base model name couldn't be trimmed.",
                data: {
                    baseModelName,
                },
                error,
            });
        }
    }

    return {
        baseModelName,
        manufacturingStart,
        manufacturingEnd,
    };
}

import { generateId } from '../../lib/generate-id';
import { DataTypeBase } from '../indexed-db/database.types';
import { ReportType } from '../reports/report';
import { Timestamps } from '../timestamps';
import { CustomFieldDropdownOption } from './custom-field-dropdown-option';
import { CustomFieldType } from './custom-field-type';

/**
 * The configuration of custom fields.
 *
 * The name is shortened (as opposed to "CustomFieldConfig") in order to provide better
 * distinguishability from the CustomField class.
 */
export class FieldConfig implements Timestamps, DataTypeBase {
    constructor(template: Partial<FieldConfig> = {}) {
        Object.assign(this, template);
    }

    _id: string = generateId();
    // The value name. It's displayed in the user interface.
    name: string;
    // Name within the placeholder value tree. Reduced by special characters such as braces and spaces.
    placeholder: string;
    type: CustomFieldType = 'singleLineText';
    // The string suffix that's both displayed in the frontend and on the document. Can be used for both type text and number. It's
    // important for text, too, because "3-5 Arbeitstage" needs to be enabled.
    suffix?: string;
    dropdownOptions?: CustomFieldDropdownOption[];
    defaultValue?: number | string | boolean | any; // Type "any" is necessary for allowing a binding to number-input.directive
    reportTypes: ReportType[] = [];

    webhookUrl?: string;
    webhookEventType?: string;
    googleMaterialIconName?: string;

    createdBy: string;
    updatedAt: string;
    createdAt: string;

    _documentVersion: number = 0;
    _schemaVersion = 1 as const;
}

import { removeDocumentTypeFromReport } from '@autoixpert/lib/documents/remove-document-type-from-report';
import { RepairCalculation } from '@autoixpert/models/reports/damage-description/repair';
import { Report } from '@autoixpert/models/reports/report';

/**
 * Reset the repairCalculation
 */
export function clearRepairCalculationData(report: Report) {
    //*****************************************************************************
    //  Reset values
    //****************************************************************************/
    report.damageCalculation.repair = {
        ...report.damageCalculation.repair,
        // Reset only the properties associated with a calculation. On the Repair object, there are numerous other properties, such as paint blending, that shall not be reset.
        ...new RepairCalculation(),
    };
    // Remove Audatex task ID
    report.audatexTaskId = null;

    // Remove DAT calculation object
    delete report.damageCalculation.repair.datCalculation;

    // Remove provider
    report.damageCalculation.repair.calculationProvider = null;

    // The entire object is optional, so remove it instead of setting its properties to null.
    report.damageCalculation.repair.isApproximateCalculation = null;
    /////////////////////////////////////////////////////////////////////////////*/
    //  END Reset values
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    // Remove Document
    //****************************************************************************/
    // The damage calculation might have been inserted manually by the user or through the user's preference to always have autoiXpert make
    // the damage calculation available as a report document.
    removeDocumentTypeFromReport({
        report: report,
        documentType: 'datDamageCalculation',
        documentGroup: 'report',
    });
    /////////////////////////////////////////////////////////////////////////////*/
    //  END Remove Document
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Remove VXS Attachment
    //****************************************************************************/
    // If the user has set the value before, e.g. through importing a DAT calculation, set it back to its default.
    if (report.garage.outgoingMessageDraft.report.isDatVxsAttached == true) {
        report.garage.outgoingMessageDraft.report.isDatVxsAttached = undefined;
    }
    // If the user has set the value before, e.g. through importing a DAT calculation, set it back to its default.
    if (report.garage.outgoingMessageDraft.repairConfirmation?.isDatVxsAttached == true) {
        report.garage.outgoingMessageDraft.repairConfirmation.isDatVxsAttached = undefined;
    }
    /////////////////////////////////////////////////////////////////////////////*/
    //  END Remove VXS Attachment
    /////////////////////////////////////////////////////////////////////////////*/
}

import { Component, EventEmitter, Input, Output, ViewChild, ViewChildren } from '@angular/core';
import { trackById } from '@autoixpert/lib/track-by/track-by-id';
import { SignableDocumentType } from '../../../models/documents/document-metadata';
import { ClaimantSignature } from '../../../models/signable-documents/claimant-signature';
import { SignableDocument } from '../../../models/signable-documents/signable-document';
import {
    RenderedDocumentBuildingBlock,
    SignableDocumentsRenderingConfig,
} from '../../../models/signable-documents/signable-document-rendering-config';
import { SignablePdfTemplateEditorComponent } from '../../signable-pdf-template-editor/signable-pdf-template-editor.component';
import { SignaturePadComponent } from '../../signature-pad/signature-pad.component';

/**
 * Shared component used both by the frontend and the claimant signature form application to render multiple signable documents.
 * Gets a signable documents rendering config object that includes all necessary data for rendering the documents.
 */
@Component({
    selector: 'claimant-signature-documents',
    templateUrl: './claimant-signature-documents.component.html',
    styleUrls: ['./claimant-signature-documents.component.scss'],
})
export class ClaimantSignatureDocumentsComponent {
    protected readonly trackById = trackById;

    // Signature pad used by text block documents.
    @ViewChildren(SignaturePadComponent) private readonly signaturePadComponents: SignaturePadComponent[];
    @ViewChild(SignablePdfTemplateEditorComponent)
    private readonly signablePdfTemplateEditor?: SignablePdfTemplateEditorComponent;

    @Input() config: SignableDocumentsRenderingConfig;
    @Input() dipslayTitle: boolean = false;
    @Input() parentScrollContainer?: HTMLElement | string;
    @Input() showPdfDocumentFrame = true;
    @Input() signaturePadFullscreenMode: 'allOptions' | 'signatureOnly' = 'signatureOnly';
    @Input() documentReadonlyReason: 'reportLocked' | 'deadlinePassed' | 'signatureSubmitted' | false = false;
    @Input() selectedTab: DocumentTab;
    @Input() signatureDisabledReason: SignaturePadComponent['disabledReason'] | undefined;
    @Input() disableSignatureTransfer = false;
    @Input() pageCounterAndZoomLevel: {
        position: string;
        left: string;
        bottom: string;
        marginLeft: string;
        marginTop: string;
    } = {
        position: 'absolute',
        bottom: '20px',
        left: '30px',
        marginLeft: '20px',
        marginTop: '0px',
    };

    @Output() saveReport = new EventEmitter<void>();
    @Output() signatureEndStroke: EventEmitter<ClaimantSignature[]> = new EventEmitter();
    @Output() signaturePadFullscreenChange: EventEmitter<'draw' | 'upload' | 'smartphone' | false> = new EventEmitter();
    @Output() signaturePadCreated: EventEmitter<SignaturePadComponent> = new EventEmitter();
    @Output() addPowerOfAttorney = new EventEmitter<void>();
    @Output() transferSignature = new EventEmitter<{ signaturePad: SignaturePadComponent }>();

    buildingBlocksPending = false;

    get selectedSignablePdfTemplateConfig() {
        const config = this.config.signablePdfTemplateConfigs.find(
            (signablePdfTemplateConfig) =>
                signablePdfTemplateConfig._id === this.selectedSignableDocument.pdfTemplateId,
        );

        return config;
    }

    get signaturePads(): SignaturePadComponent[] {
        return [...this.signaturePadComponents, ...(this.signablePdfTemplateEditor?.signaturePadComponents ?? [])];
    }

    get selectedSignableDocument(): SignableDocument {
        return this.config.report.signableDocuments.find(
            ({ documentType, customDocumentOrderConfigId }) =>
                documentType === this.selectedTab?.documentType &&
                customDocumentOrderConfigId === this.selectedTab?.customDocumentOrderConfigId,
        );
    }

    get visibleDocumentBuildingBlocks() {
        return (
            this.config.renderedBuildingBlocks
                .filter(
                    ({ documentType, customDocumentOrderConfigId }) =>
                        documentType === this.selectedTab?.documentType &&
                        customDocumentOrderConfigId === this.selectedTab?.customDocumentOrderConfigId,
                )
                // Make ID unique by adding the document type for trackById to work correctly.
                .map((buildingBlock) => ({
                    ...buildingBlock,
                    _id: `${buildingBlock.documentType}-${buildingBlock.customDocumentOrderConfigId}-${buildingBlock._id}`,
                }))
        );
    }

    getSignableDocumentId(signableDocument: SignableDocument) {
        if (!signableDocument.customDocumentOrderConfigId) {
            return signableDocument.documentType;
        }

        return `${signableDocument.documentType}-${signableDocument.customDocumentOrderConfigId}`;
    }

    /**
     * The signaturePad may be disabled.
     * - Report is locked:
     */
    public getSignaturePadDisabledReason(): SignaturePadComponent['disabledReason'] {
        if (this.signatureDisabledReason) {
            return this.signatureDisabledReason;
        }

        switch (this.documentReadonlyReason) {
            case 'reportLocked':
                return 'reportLocked';
            case 'deadlinePassed':
                return 'deadlinePassed';
            case 'signatureSubmitted':
                return 'signatureSubmitted';
            default:
                return undefined;
        }
    }

    //*****************************************************************************
    //  Signer Name
    //****************************************************************************/
    /**
     * Either the claimant or his representative signs.
     *
     * The representative is prepended in its own line before the claimant.
     */
    public getSignerName(): string {
        const signerLines: string[] = [];

        // Representative
        if (!this.config.report.orderPlacedByClaimant && this.config.report.actualCustomer) {
            signerLines.push(`i. A. ${this.config.report.actualCustomer}`);
        }

        // Claimant
        signerLines.push(this.getFullNameOfCurrentSigner());

        return signerLines.join('\n');
    }

    /**
     * Get the claimant's name or a fallback if no name is available.
     */
    public getFullNameOfCurrentSigner(): string {
        if (this.config.report.claimant.contactPerson.firstName || this.config.report.claimant.contactPerson.lastName) {
            return (
                this.config.report.claimant.contactPerson.firstName +
                ' ' +
                this.config.report.claimant.contactPerson.lastName
            ).trim();
        } else {
            return 'Anspruchsteller';
        }
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Signer Name
    /////////////////////////////////////////////////////////////////////////////*/#

    /**
     * The tab heading is derived from the first document building block in the respective document that has a heading.
     *
     * If there is no heading, the view defines defaults.
     */
    public getTabsHeading(): string {
        // Find the first block with a heading.
        const responsibleDocumentBuildingBlock: RenderedDocumentBuildingBlock = this.visibleDocumentBuildingBlocks.find(
            (documentBuildingBlock) => documentBuildingBlock.heading,
        );

        return responsibleDocumentBuildingBlock?.heading;
    }

    async saveSignatures() {
        await this.signablePdfTemplateEditor?.saveSignatures();
    }

    getFilledSignaturePads() {
        return this.signaturePads.filter((signaturePad) => !signaturePad.isEmpty());
    }

    getCanvasWidth(signaturePad: SignaturePadComponent) {
        const element = signaturePad.elRef.nativeElement.parentElement;
        const canvasWidth = Math.max(180, Math.min(375, element.clientWidth));
        return canvasWidth;
    }

    showNoDocumentContentPlaceholder(): boolean {
        return (
            !this.buildingBlocksPending &&
            !this.visibleDocumentBuildingBlocks.filter(
                ({ placeholder }) =>
                    !['UnterschriftKunde', 'ZusammenfassungAnwaltsvollmacht', 'ZweiteUnterschriftKunde'].includes(
                        placeholder,
                    ),
            ).length
        );
    }
}

interface DocumentTab {
    documentType: SignableDocumentType | 'signAllDocumentsAtOnce';
    customDocumentOrderConfigId?: string;
}

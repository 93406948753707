import { Pipe, PipeTransform } from '@angular/core';
import { ResidualValueBid } from '@autoixpert/models/reports/residual-value/residual-value-bid';

/**
 * Builds the label that is placed at the very bottom of the residual value request card. It states how many
 * of the selected bids include a value and how many bidders did not place a bid. E.g. "3 Gebote, 4 Bieter ohne Gebot".
 */
@Pipe({
    name: 'selectedBidsSummaryLabel',
})
export class SelectedBidsSummaryLabelPipe implements PipeTransform {
    transform(selectedBids: ResidualValueBid[]): string {
        const numberOfNotInterestedBids = selectedBids.filter((bid) => bid.bidValue.value === null).length;
        const numberOfInterestedBids = selectedBids.length - numberOfNotInterestedBids;

        const labelParts = [];
        if (numberOfInterestedBids > 0) {
            labelParts.push(`${numberOfInterestedBids} ${selectedBids.length === 1 ? 'Gebot' : 'Gebote'}`);
        }
        if (numberOfNotInterestedBids > 0) {
            labelParts.push(`${numberOfNotInterestedBids} Bieter ohne Gebot`);
        }

        return labelParts.join(', ');
    }
}

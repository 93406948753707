<div id="layout-container" *ngIf="invoice">
    <div id="recipients-column" *ngIf="recipients && recipients.length > 1">
        <!--============================================-->
        <!-- Recipients -->
        <!--============================================-->
        <recipients-column
            [recipients]="recipients"
            [selectedRecipient]="selectedRecipient"
            (selectedRecipientChange)="selectRecipient($event)"></recipients-column>
        <!--============================================-->
        <!-- END Recipients -->
        <!--============================================-->
    </div>

    <div id="invoice-letter-and-email-column">
        <!--============================================-->
        <!-- Invoice Letter -->
        <!--============================================-->
        <div
            id="invoice-letter-container"
            class="card card-with-padding"
            *ngIf="invoiceLetterInEditMode"
            @slideInAndOutVertically>
            <h2 id="invoice-letter-heading">
                <mat-icon class="heading-icon">description</mat-icon>
                {{ invoiceLetterInEditMode.title || 'Dokument' }}
            </h2>
            <div id="invoice-letter-submenu-container" class="message-submenu-container">
                <mat-icon
                    class="toolbar-icon invoice-letter-submenu"
                    matTooltip="Vorlage wählen"
                    (click)="showMessageTemplateSelector()">
                    post_add
                </mat-icon>
                <mat-icon class="toolbar-icon invoice-letter-submenu" [matMenuTriggerFor]="invoiceLetterSubmenu">
                    more_vert
                </mat-icon>
                <mat-menu #invoiceLetterSubmenu="matMenu">
                    <a mat-menu-item class="menu-item-with-icon" (click)="removeInvoiceLetter(invoiceLetterInEditMode)">
                        <mat-icon>delete</mat-icon>
                        Dokument löschen
                    </a>
                </mat-menu>
            </div>

            <!--============================================-->
            <!-- Invoice Letter Templates -->
            <!--============================================-->
            <section id="invoice-letter-templates-section" *ngIf="invoiceLetterTemplatesShown">
                <h3>Vorlagen</h3>
                <record-template-selector
                    [templates]="invoiceLetterTemplates"
                    (templateUsage)="useInvoiceLetterTemplate($event); saveInvoice()"
                    (noTemplateUsage)="hideInvoiceLetterTemplates()"></record-template-selector>
            </section>
            <!--============================================-->
            <!-- END Invoice Letter Templates -->
            <!--============================================-->

            <!--============================================-->
            <!-- Invoice Letter Editor -->
            <!--============================================-->
            <div *ngIf="!invoiceLetterTemplatesShown">
                <div id="invoice-letter-header">
                    <div id="invoice-letter-recipient-container">
                        <div class="message-recipient-icon-container">
                            <img
                                class="message-recipient-icon"
                                [src]="'/assets/images/icons/' + invoiceLetterInEditMode.recipientRole + '-blue.svg'" />
                        </div>
                        <div class="message-recipient-contact-details-container">
                            <div id="invoice-letter-recipient">
                                <div
                                    *ngIf="
                                        recipientOfInvoiceLetterInEditMode &&
                                        recipientOfInvoiceLetterInEditMode.contactPerson.organization
                                    ">
                                    {{ recipientOfInvoiceLetterInEditMode.contactPerson.organization }}
                                </div>
                                <div
                                    *ngIf="
                                        recipientOfInvoiceLetterInEditMode &&
                                        (recipientOfInvoiceLetterInEditMode.contactPerson.firstName ||
                                            recipientOfInvoiceLetterInEditMode.contactPerson.lastName)
                                    ">
                                    {{
                                        recipientOfInvoiceLetterInEditMode.contactPerson.firstName ||
                                            recipientOfInvoiceLetterInEditMode.contactPerson.salutation
                                    }}
                                    {{ recipientOfInvoiceLetterInEditMode.contactPerson.lastName }}
                                </div>
                                <div
                                    *ngIf="
                                        !recipientOfInvoiceLetterInEditMode ||
                                        (!recipientOfInvoiceLetterInEditMode.contactPerson.organization &&
                                            !recipientOfInvoiceLetterInEditMode.contactPerson.lastName &&
                                            !recipientOfInvoiceLetterInEditMode.contactPerson.firstName)
                                    ">
                                    Keine Kontaktdaten
                                </div>
                            </div>
                            <div class="label" [ngSwitch]="recipientOfInvoiceLetterInEditMode.role">
                                <span *ngSwitchCase="'invoiceRecipient'">Rechnungsempfänger</span>
                                <span *ngSwitchCase="'claimant'">Anspruchsteller</span>
                                <span *ngSwitchCase="'garage'">Werkstatt</span>
                                <span *ngSwitchCase="'lawyer'">Rechtsanwalt</span>
                                <span *ngSwitchCase="'insurance'">Versicherung</span>
                                <span *ngSwitchCase="'factoringProvider'">Factoring Provider</span>
                            </div>
                        </div>
                    </div>
                </div>
                <div id="subject-and-date-container">
                    <mat-form-field class="subject mat-block">
                        <input
                            matInput
                            placeholder="Betreff"
                            [(ngModel)]="invoiceLetterInEditMode.subject"
                            (change)="saveInvoice(); deriveDocumentTitleFromInvoiceLetterSubject()" />
                    </mat-form-field>
                    <date-input [(date)]="invoiceLetterInEditMode.date" (dateChange)="saveInvoice()"></date-input>
                </div>
                <mat-form-field class="message mat-block">
                    <mat-quill
                        placeholder="Nachricht"
                        [(ngModel)]="invoiceLetterInEditMode.body"
                        (change)="saveInvoice()"></mat-quill>
                </mat-form-field>
                <div id="invoice-letter-editor-buttons-container">
                    <button (click)="leaveDocumentEditor()">Schließen</button>
                    <button
                        class="secondary"
                        (click)="previewInvoiceLetter()"
                        [ngClass]="{ disabled: !isDocumentAvailable(invoiceLetterInEditMode) }"
                        [matTooltip]="getTooltipForDocument(invoiceLetterInEditMode)">
                        <img
                            class="pending-icon"
                            src="/assets/images/icons/loading-indicator.svg"
                            alt=""
                            *ngIf="invoiceLetterPreviewPending" />
                        Vorschau
                    </button>
                </div>
            </div>
            <!--============================================-->
            <!-- END Invoice Letter Editor -->
            <!--============================================-->
        </div>
        <!--============================================-->
        <!-- END Invoice Letter -->
        <!--============================================-->

        <!--============================================-->
        <!-- E-Mail -->
        <!--============================================-->
        <div id="email-message-height-container">
            <email-editor
                [recipient]="selectedRecipient"
                (recipientChange)="saveInvoice()"
                [email]="selectedRecipient.outgoingMessageDraft"
                (emailChange)="saveInvoice()"
                (documentChange)="saveInvoice()"
                (deactivateDocument)="deactivateFromCurrentFullDocument($event)"
                [invoice]="invoice"
                [report]="report"
                [activeDocuments]="getActiveDocuments()"
                (emailSend)="sendEmail($event)"
                [emailTransmissionPending]="emailTransmissionPending"
                [emailJustSentInfo]="emailJustSentInfo"
                [selectedDocumentGroup]="'invoice'"
                templateType="invoiceEmail"></email-editor>
        </div>
        <!--============================================-->
        <!-- END E-Mail -->
        <!--============================================-->
    </div>

    <div id="documents-and-sent-mail-column">
        <!--============================================-->
        <!-- Documents -->
        <!--============================================-->
        <div id="documents-container" class="card card-with-padding">
            <div id="documents-toolbar">
                <mat-icon
                    class="toolbar-icon"
                    (click)="createNewInvoiceLetter()"
                    matTooltip="Neues individuelles Dokument">
                    add
                </mat-icon>
                <mat-icon
                    class="toolbar-icon"
                    (click)="nextSingleUploadDocument = null; fileInput.click()"
                    matTooltip="Neues Dokument hochladen">
                    file_upload
                </mat-icon>
                <mat-icon class="toolbar-icon" [mat-menu-trigger-for]="documentsSubMenu">more_vert</mat-icon>
                <mat-menu #documentsSubMenu>
                    <a
                        mat-menu-item
                        class="menu-item-with-icon"
                        (click)="toggleHeaderAndFooterOnDocuments()"
                        matTooltip="Ohne Kopf- & Fußzeile kannst du auf vorgedrucktes Briefpapier drucken.{{
                            '\n\n'
                        }}Falls die Dokumente per E-Mail versendet werden, erhalten sie natürlich immer die Kopf- & Fußzeile.">
                        <mat-icon>description</mat-icon>
                        Kopf- & Fußzeile
                        {{ userPreferences.printDocumentsWithoutHeaderAndFooter ? 'aktivieren' : 'deaktivieren' }}
                    </a>
                    <a mat-menu-item class="menu-item-with-icon" (click)="activateVisibleDocuments()">
                        <mat-icon>check_box</mat-icon>
                        Alle Dokumente aktivieren
                    </a>
                    <a mat-menu-item class="menu-item-with-icon" (click)="deactivateVisibleDocuments()">
                        <mat-icon>check_box_outline_blank</mat-icon>
                        Alle Dokumente deaktivieren
                    </a>
                </mat-menu>
            </div>

            <h2>Dokumente</h2>

            <!--====================================================-->
            <!-- Info Note If Header and Footer Are Deactivated     -->
            <!--====================================================-->

            <div
                id="header-and-footer-deactivated"
                class="info-note"
                *ngIf="userPreferences.printDocumentsWithoutHeaderAndFooter"
                @fadeInAndOut>
                <mat-icon>info</mat-icon>
                <div class="info-note-text">
                    <div>
                        Kopf- & Fußzeile für Druck deaktiviert.
                        <a class="cursor-pointer" (click)="toggleHeaderAndFooterOnDocuments()">Aktivieren.</a>
                    </div>
                </div>
            </div>

            <!--====================================================-->
            <!-- END Info Note If Header and Footer Are Deactivated -->
            <!--====================================================-->

            <div id="document-list-container" cdkDropList (cdkDropListDropped)="onDocumentReordered($event)">
                <div
                    class="document-container"
                    *ngFor="let document of filteredDocuments"
                    [ngClass]="{
                        excluded: !getDocumentOrderItemForDocument(document)?.includedInFullDocument,
                        'not-available': !isDocumentAvailable(document),
                    }"
                    cdkDrag>
                    <div
                        class="drop-zone"
                        ng2FileDrop
                        [class.file-over]="document === documentHoveredByFile"
                        (fileOver)="onFileOverDropZone($event, document)"
                        (onFileDrop)="onFileDrop($event, document)"
                        [uploader]="uploader">
                        <mat-icon class="drag-handle" cdkDragHandle>drag_indicator</mat-icon>

                        <div class="document-title-container">
                            <div class="document-title-edit" *ngIf="renameModeActiveForDocuments.get(document)">
                                <mat-form-field class="subject mat-block">
                                    <input
                                        matInput
                                        placeholder="Titel"
                                        [(ngModel)]="document.title"
                                        (change)="saveInvoice()"
                                        (keydown)="renameKeydownListener($event, document)"
                                        [axAutofocus]="true" />
                                </mat-form-field>
                            </div>
                            <!-- Use mousedown to ensure that accidental selection on shift + click is prevented. -->
                            <div
                                class="document-title"
                                *ngIf="!renameModeActiveForDocuments.get(document)"
                                matTooltip="Dokument herunterladen"
                                (mousedown)="handleDocumentClick(document, $event)"
                                [ngClass]="{ 'missing-data-info': !document.title }">
                                {{ document.title || 'Kein Titel' }}
                            </div>
                        </div>

                        <div class="document-submenu-and-indicators-container">
                            <img
                                class="document-loading-indicator"
                                *ngIf="pendingDocumentDownloads.get(document) || pendingDocumentUploads.get(document)"
                                src="/assets/images/icons/loading-indicator.svg" />

                            <!--********** Invoice locked **********-->
                            <mat-icon
                                id="invoice-locked-icon"
                                *ngIf="document.type === 'invoice' && invoice.lockedAt"
                                matTooltip="Gebucht am {{ invoice.lockedAt | moment: 'DD.MM.YYYY - HH:mm [Uhr]' }}">
                                lock
                            </mat-icon>

                            <!--********** Permanent user upload **********-->
                            <mat-icon
                                class="permanent-user-uploaded-document-icon"
                                matTooltip="Dokument wird jedem neuen Gutachten angehängt"
                                *ngIf="
                                    document.type === 'manuallyUploadedPdf' && document.permanentUserUploadedDocument
                                ">
                                storage
                            </mat-icon>

                            <!--********** User upload **********-->
                            <svg
                                class="user-uploaded-document-icon"
                                *ngIf="document.uploadedDocumentId"
                                version="1.1"
                                xmlns="http://www.w3.org/2000/svg"
                                x="0px"
                                y="0px"
                                width="32px"
                                height="32px"
                                viewBox="0 0 32 32"
                                enable-background="new 0 0 32 32"
                                xml:space="preserve"
                                matTooltip="Eigenes PDF wurde hochgeladen">
                                <path
                                    fill-rule="evenodd"
                                    clip-rule="evenodd"
                                    d="M4.109,26.636c0,0-0.106-3.104,1.031-3.883c1.139-0.777,5.557-2.646,7.732-4.159
                                    c0.293-0.203,0.53-1.097,0.078-1.738c-0.527-0.746-0.85-2.034-1.024-3.447c-0.564-0.014-0.579-1.432-0.094-1.453
                                    c-0.104-1.672-0.061-3.187,0.098-4.206c0.258-1.669,2.792-2.284,4.242-2.284c1.451,0,3.294,0.632,3.659,2.284
                                    c0.225,1.015,0.287,2.679,0.16,4.354c0.267,0,0.338,1.259-0.137,1.268c-0.194,1.407-0.536,2.703-1.044,3.482
                                    c-0.428,0.659-0.216,1.535,0.076,1.738c2.177,1.513,6.594,3.382,7.733,4.159c1.139,0.778,1.031,3.883,1.031,3.883H4.109z"></path>
                            </svg>

                            <mat-slide-toggle
                                class="document-slide-toggle"
                                *ngIf="getDocumentOrderItemForDocument(document)"
                                [(ngModel)]="getDocumentOrderItemForDocument(document).includedInFullDocument"
                                [disabled]="!isDocumentAvailable(document)"
                                (click)="handleToggleClick($event)"
                                [matTooltip]="getTooltipForDocument(document)"
                                (change)="handleToggleChange($event)"></mat-slide-toggle>

                            <mat-icon class="document-submenu-icon" [matMenuTriggerFor]="documentSubmenu">
                                more_vert
                            </mat-icon>
                            <mat-menu #documentSubmenu="matMenu">
                                <!--********** Edit document **********-->
                                <a
                                    mat-menu-item
                                    class="menu-item-with-icon"
                                    *ngIf="document.type === 'letter'"
                                    (click)="editInvoiceLetter(document)">
                                    <mat-icon>edit</mat-icon>
                                    Bearbeiten
                                </a>

                                <!--********** Open **********-->
                                <a mat-menu-item class="menu-item-with-icon" (click)="downloadDocument(document)">
                                    <mat-icon>visibility</mat-icon>
                                    PDF öffnen
                                </a>

                                <!--********** Lock Invoice **********-->
                                <a
                                    mat-menu-item
                                    class="menu-item-with-icon"
                                    (click)="lockInvoice()"
                                    *ngIf="document.type === 'invoice' && !invoice.lockedAt"
                                    matTooltip="Festgeschriebene Rechnungen können nicht mehr bearbeitet werden">
                                    <mat-icon>lock</mat-icon>
                                    Festschreiben
                                </a>

                                <!--********** Edit in word **********-->
                                <a
                                    mat-menu-item
                                    class="menu-item-with-icon"
                                    *ngIf="
                                        [
                                            'invoice',
                                            'letter',
                                            'paymentReminderLevel0',
                                            'paymentReminderLevel1',
                                            'paymentReminderLevel2',
                                            'paymentReminderLevel3',
                                        ].includes(document.type)
                                    "
                                    (click)="downloadDocument(document, 'docx')"
                                    [matTooltip]="
                                        'DOCX bearbeiten, PDF erstellen und hier wieder hochladen' +
                                        isTeamMatureCustomer()
                                            ? ''
                                            : '.\n\n' + getMessageAboutBlockedDocxDownload()
                                    "
                                    [matTooltipPosition]="'right'"
                                    [disabled]="!isTeamMatureCustomer()">
                                    <mat-icon>description</mat-icon>
                                    In Word bearbeiten
                                </a>

                                <!--********** Upload Custom PDF **********-->
                                <a
                                    mat-menu-item
                                    class="menu-item-with-icon"
                                    *ngIf="
                                        !document.uploadedDocumentId &&
                                        document.type !== 'letter' &&
                                        document.type !== 'manuallyUploadedPdf'
                                    "
                                    (click)="nextSingleUploadDocument = document; fileInput.click()">
                                    <mat-icon>upload_file</mat-icon>
                                    Eigene PDF hochladen
                                </a>
                                <a
                                    mat-menu-item
                                    class="menu-item-with-icon"
                                    *ngIf="document.uploadedDocumentId && document.type !== 'manuallyUploadedPdf'"
                                    (click)="removeUploadedDocument(document)">
                                    <mat-icon>clear</mat-icon>
                                    Eigene PDF entfernen
                                </a>

                                <!--********** Rename document **********-->
                                <a
                                    mat-menu-item
                                    class="menu-item-with-icon"
                                    *ngIf="document.type === 'manuallyUploadedPdf' || document.type === 'letter'"
                                    (click)="toggleRenamingUploadedDocument(document)">
                                    <mat-icon class="outlined">drive_file_rename_outline</mat-icon>
                                    {{
                                        renameModeActiveForDocuments.get(document) ? 'Umbenennen beenden' : 'Umbenennen'
                                    }}
                                </a>

                                <!--********** Delete **********-->
                                <a
                                    mat-menu-item
                                    class="menu-item-with-icon"
                                    *ngIf="
                                        document.type === 'manuallyUploadedPdf' ||
                                        document.type === 'letter' ||
                                        document.type.includes('paymentReminderLevel')
                                    "
                                    (click)="deleteCustomDocument(document)">
                                    <mat-icon>delete</mat-icon>
                                    Löschen
                                </a>
                            </mat-menu>
                        </div>
                    </div>
                </div>

                <!--============================================-->
                <!-- New Document Drop Zone -->
                <!--============================================-->
                <div class="document-container">
                    <div
                        class="drop-zone new-document-zone"
                        ng2FileDrop
                        *ngIf="fileIsOverBody"
                        [class.file-over]="fileOverNewDocumentDropZone"
                        (fileOver)="onFileOverNewDocumentDropZone($event)"
                        (onFileDrop)="onFileDrop($event, null)"
                        [uploader]="uploader">
                        + Neues Dokument hochladen
                    </div>
                </div>
                <!--============================================-->
                <!-- END New Document Drop Zone -->
                <!--============================================-->

                <input
                    style="display: none"
                    type="file"
                    ng2FileSelect
                    [uploader]="uploader"
                    (change)="onFileDrop($any($event.currentTarget).files, nextSingleUploadDocument)"
                    #fileInput
                    accept="application/pdf" />
            </div>

            <div id="document-buttons-container">
                <button
                    id="download-full-document-button"
                    (click)="getActiveDocuments().length > 0 && downloadFullDocument()"
                    [matTooltip]="
                        getActiveDocuments().length
                            ? 'Alle aktivierten Dokumente als eine große PDF herunterladen'
                            : 'Mindestens ein Dokument muss aktiv sein.'
                    "
                    [ngClass]="{ disabled: getActiveDocuments().length === 0 }">
                    <img
                        id="report-pdf-download-pending-icon"
                        class="pending-icon"
                        *ngIf="fullDocumentLoading"
                        src="/assets/images/icons/loading-indicator-white.svg"
                        matTooltip="Der PDF-Download wird vorbereitet..."
                        matTooltipShowDelay="300" />
                    Gesamt-PDF
                </button>
            </div>
        </div>
        <!--============================================-->
        <!-- END Documents -->
        <!--============================================-->

        <!--============================================-->
        <!-- Outgoing messages -->
        <!--============================================-->
        <div id="message-list-container" class="card" *ngIf="outgoingMessages && outgoingMessages.length > 0">
            <outgoing-messages-list
                [outgoingMessages]="outgoingMessages"
                [selectedRecipientRole]="selectedRecipient?.role"
                (editAsNew)="editAsNew($event)"></outgoing-messages-list>
        </div>
        <!--============================================-->
        <!-- END Outgoing messages -->
        <!--============================================-->
    </div>
</div>
<message-template-selector
    *ngIf="templateSelectorShown"
    [report]="report"
    [invoice]="invoice"
    [recipientRole]="selectedRecipient.role"
    [letterDocument]="invoiceLetterInEditMode"
    templateType="invoiceLetter"
    (close)="hideMessageTemplateSelector()"
    (templateSelection)="insertTemplateText($event)"
    @runChildAnimations></message-template-selector>

export class TutorialState {
    constructor() {}

    /**
     * WHEN ADDING STEPS,
     * update the tutorialStateService.markStepComplete method too.
     */
    liabilityReportCreated: boolean;
    helpcenterSearched: boolean;
    headerAndFooterTemplatesConfigured: boolean;
    userSealUploaded: boolean;
    reportLocked: boolean;
    invoiceListOpened: boolean;
    // Earn Money tutorial
    reportTokenConfigured: boolean;
    invoiceNumberConfigured: boolean;
    reportTokenRetrieved: boolean;
    invoiceNumberRetrieved: boolean;
    datCalculationImported: boolean;
    diminishedMarketValueCalculated: boolean;
    paymentAdded: boolean;
    paymentReminderCreated: boolean;
    customInvoiceCreated: boolean;
    customDocumentCreatedInInvoice: boolean;
    // Discover More tutorial
    revenueAnalyticsOpened: boolean;
    documentBuildingBlockListOpened: boolean;
    residualValueExchangeCredentialsAdded: boolean;
    residualValueRequestCreated: boolean;
    customPdfUploadedToReport: boolean;
    emailCredentialsAdded: boolean;
    datCredentialsAdded: boolean;
    ownContactsImported: boolean;
    newContactCreatedManually: boolean;
}

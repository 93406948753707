import { Component, HostBinding, HostListener, Inject, Input } from '@angular/core';
import {
    MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA,
    MatLegacyDialogRef as MatDialogRef,
} from '@angular/material/legacy-dialog';
import { dialogEnterAndLeaveAnimation } from '@autoixpert/animations/dialog-enter-and-leave.animation';
import { CustomMarketAnalysis } from '@autoixpert/models/reports/market-value/custom-market-analysis';
import { CustomAutocompleteEntry } from '@autoixpert/models/text-templates/custom-autocomplete-entry';
import { slideOutDialogVertical } from '../../../../shared/animations/slide-out-dialog-vertical.animation';
import { triggerClickEventOnSpaceBarPress } from '../../../../shared/libraries/trigger-click-event-on-space-bar-press';

@Component({
    selector: 'custom-market-analysis-dialog',
    templateUrl: 'custom-market-analysis-dialog.component.html',
    styleUrls: ['custom-market-analysis-dialog.component.scss'],
    animations: [dialogEnterAndLeaveAnimation(), slideOutDialogVertical()],
})
export class CustomMarketAnalysisDialogComponent {
    constructor(
        private dialog: MatDialogRef<CustomMarketAnalysisDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: CustomMarketAnalysisDialogData,
    ) {}

    customMarketAnalysis: CustomMarketAnalysis;
    disabled: boolean;

    ngOnInit() {
        this.customMarketAnalysis = this.data.customMarketAnalysis;
        this.disabled = this.data.disabled;
    }

    public sourcesWithLogo: CustomAutocompleteEntry[] = ['DAT-Webscan', 'AutoScout24', 'mobile.de', 'pkw.de'].map(
        (label) =>
            new CustomAutocompleteEntry({
                type: 'customMarketAnalysisSource',
                value: label,
                createdBy: 'autoixpert-default',
                teamId: 'autoixpert-ADMIN',
            }),
    );

    /**
     * Do we have a logo prepared for a source?
     * Match the default autocomplete entries. That's usually the group of sources for which we have logos.
     * Compare case-insensitive.
     */
    public customMarketAnalysisSourceHasIcon(): boolean {
        return !!this.sourcesWithLogo.find(
            (defaultSource) =>
                defaultSource.value.toLowerCase() === (this.customMarketAnalysis.title || '').toLowerCase(),
        );
    }

    public calculateMeanValue() {
        this.customMarketAnalysis.averagePrice =
            (this.customMarketAnalysis.minCorridor + this.customMarketAnalysis.maxCorridor) / 2;
    }

    public triggerClickEventOnSpaceBarPress = triggerClickEventOnSpaceBarPress;

    //*****************************************************************************
    //  Events
    //****************************************************************************/
    public closeDialog() {
        this.dialog.close();
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Events
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Animations
    //****************************************************************************/
    @HostBinding('@slideOutDialogVertical') get slideOut() {
        return true;
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Animations
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Keyboard Shortcuts
    //****************************************************************************/
    @HostListener('window:keydown', ['$event'])
    public handleKeyboardShortcuts(event: KeyboardEvent) {
        switch (event.key) {
            case 'Escape':
                this.closeDialog();
                break;
            case 'Enter':
                if (event.ctrlKey || event.metaKey) {
                    this.closeDialog();
                }
        }
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Keyboard Shortcuts
    /////////////////////////////////////////////////////////////////////////////*/
}

export interface CustomMarketAnalysisDialogData {
    customMarketAnalysis: CustomMarketAnalysis;
    disabled: boolean;
}

import { AfterViewInit, Directive, Input } from '@angular/core';
import { MatLegacyInput as MatInput } from '@angular/material/legacy-input';

/**
 * Allows an element in a view to be autofocused as soon as the view is initialized.
 * Just add the attribute "axAutofocus" to the element.
 * Example: <input type="password" [axAutofocus]="true">
 */
@Directive({
    selector: 'input[axAutofocus], textarea[axAutofocus]',
})
export class AutofocusDirective implements AfterViewInit {
    constructor(private matInput: MatInput) {}

    private shouldAutofocus: boolean;

    @Input('axAutofocus')
    set focus(focus: boolean) {
        // Every time the value changes to true, focus the matInput
        if (focus) {
            this.shouldAutofocus = true;
            // According to the angular2 team, a timeout is required here. See https://github.com/angular/material2/issues/1474
            setTimeout(() => {
                this.matInput.focus();
            });
        }
    }

    ngAfterViewInit() {
        if (this.shouldAutofocus) {
            // According to the angular2 team, a timeout is required here. See https://github.com/angular/material2/issues/1474
            setTimeout(() => {
                this.matInput.focus();
            });
        }
    }
}

import { getApplicableReplacementValue } from '@autoixpert/lib/car-valuation/get-applicable-replacement-value';
import { getApplicableResidualValue } from '@autoixpert/lib/car-valuation/get-applicable-residual-value';
import { getApplicableRepairCosts } from '@autoixpert/lib/damage-calculation-values/get-applicable-repair-costs';
import { Report } from '@autoixpert/models/reports/report';
import { UserPreferences } from '@autoixpert/models/user/preferences/user-preferences';

/**
 * Depending on the repair costs, replacement value and residual value, determine the damageType.
 *
 * According to most sources (e.g. Allianz insurance, DA Direct, ..) the economic total loss is applicable
 * when the repair costs are lower than the replacement value (Wiederbeschaffungswert) but higher than the
 * replacement effort (Wiederbeschaffungsaufwand). This function adheres to that definition.
 *
 * A few other lawyers, insurances and assessors argue that the economic total loss is always applicable
 * when the repair costs are higher than the replacement value (which we don't recommend, because it's already
 * covered by the total loss).
 *
 * More details: https://wissen.autoixpert.de/hc/de/articles/13546263425426-Schadensklassen-Reparaturschaden-Totalschaden-130-Grenze
 */
export const determineDamageType = (report: Report, userPreferences: UserPreferences): void => {
    // The damage type is only relevant for reports with a damage and a damage calculation.
    if (!report.damageCalculation) return;

    // Abort if either value is empty
    if (
        report.damageCalculation.repair.correctedRepairCostsNet === null ||
        report.valuation.vehicleValueGross === null
    ) {
        return;
    }

    const applicableReplacementValue = getApplicableReplacementValue(report);
    const applicableRepairCosts = getApplicableRepairCosts(report);

    // Money the insurance needs to pay the customer in case of total loss
    const replacementCompensation = getApplicableReplacementValue(report) - (getApplicableResidualValue(report) || 0);

    /**
     * In case of repair, insurance needs to pay for the repair itself and for a market value reduction.
     *
     * Source:
     * "Ausschlaggebend ist die jeweilige finanzielle Belastung und diese konkretisiert sich vornehmlich einerseits
     * in den Kosten der Reparatur einschließlich des danach etwa verbleibenden Minderwerts [...]"
     * URL: https://www.iww.de/quellenmaterial/id/40926
     */
    const repairCompensation =
        applicableRepairCosts +
        (report.valuation.diminishedValue || 0) +
        (report.valuation.technicalDiminishedValue || 0);

    const repairToReplacementValueRatio = repairCompensation / applicableReplacementValue;

    // 130 % rule (repair costs is below 130 % of replacement value, and the owner is especially attached to the car; will drive it for more than 6 months)
    if (
        userPreferences.include130PercentRuleInAutomaticSelection &&
        repairToReplacementValueRatio > 1 &&
        repairToReplacementValueRatio <= 1.3
    ) {
        report.damageCalculation.damageType = 'repairableDamageTo130Percent';
        return;
    }
    // If the repair costs exceed the replacement value (Wiederbeschaffungswert), it's generally a total loss (exception: 130% rule).
    else if (repairToReplacementValueRatio > 1) {
        // If the 130% rule has already been set, keep that because we cannot say that the 130 % choice is wrong here.
        if (
            report.damageCalculation.damageType !== 'repairableDamageTo130Percent' ||
            // If the ratio is above 130%, overwrite the 130% percent selection.
            repairToReplacementValueRatio > 1.3
        ) {
            report.damageCalculation.damageType = 'totalLoss';
            return;
        }
    }
    // Most assessors don't want the economic total loss to be set automatically because it confuses their customers. "Does my car have to be replaced now? 😨"
    else if (
        userPreferences.includeEconomicTotalLossInAutomaticSelection &&
        repairCompensation > replacementCompensation
    ) {
        report.damageCalculation.damageType = 'economicTotalLoss';
        return;
    } else {
        report.damageCalculation.damageType = 'repairableDamage';
    }
};

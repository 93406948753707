import { SetupSteps } from './setup-steps';

export class TeamUserInterfaceStates {
    // Professional Associations
    submittedProfessionalAssociations: boolean = false;

    isInfoDismissed_activateBiddersWithNoBidForCustomResidualValueSheet: boolean = false; // There is an info note asking the user to include bidders without a bid. If dismissed, this property is true

    // Tracks whether to display the welcome splash screen.
    welcomeSplashScreenClosed: boolean = false;
    setupSteps: SetupSteps = new SetupSteps();
}

import { ResidualValueBid } from '../../models/reports/residual-value/residual-value-bid';
import { residualValueBidSortFunction } from '../residual-value-bid-sort-function';
import {
    ResidualValueBidGerman,
    getTemplatePlaceholderValuesBid,
} from './get-template-placeholder-values-residual-value-bid';

export function getTemplatePlaceholderValuesCustomResidualValueBids(
    customBids: ResidualValueBid[],
    includeBiddersWithNoBid: boolean = false,
): PlaceholderValuesCustomResidualValueBids {
    customBids.sort(residualValueBidSortFunction);

    const customBidsGerman: ResidualValueBidGerman[] = customBids
        .map((bid) => {
            return getTemplatePlaceholderValuesBid(bid, undefined);
        })
        .map((bidPlaceholder, index) => {
            bidPlaceholder.Gebot.Nummer = index + 1;
            return bidPlaceholder;
        })
        .filter((bid) => (includeBiddersWithNoBid ? true : !bid.Gebot.KeinGebot));

    return {
        // Determine the highest bid and put the rest in the WeitereEigeneGebote list.
        // In case there are only bids with "not interested", the highest bid is set to null
        // and all bids are put into the WeitereEigeneGebote list.

        HöchstesEigenesGebot: customBidsGerman[0]?.Gebot?.KeinGebot ? null : customBidsGerman[0],
        WeitereEigeneGebote: customBidsGerman[0]?.Gebot?.KeinGebot ? customBidsGerman : customBidsGerman.slice(1),
    };
}

export interface PlaceholderValuesCustomResidualValueBids {
    HöchstesEigenesGebot: ResidualValueBidGerman;
    WeitereEigeneGebote: ResidualValueBidGerman[];
}

import { ApiErrorHandler, ApiErrorHandlerCreator } from '../../services/api-error.service';
import { getDekraErrorHandlers } from './get-dekra-error-handlers';
import { getRepairCalculationErrorHandlers } from './get-repair-calculation-error-handlers';

/**
 * Map of error handlers that may happen at multiple locations touching the Audatex API.
 *
 * The order of handlers in this map is not important. The API error handler service
 * checks for the presence of a specific handler for the top most error, then dives
 * deeper for each causedBy property on the error chain until it finds a proper handler.
 */
export function getAudatexErrorHandlers(): { [key: string]: ApiErrorHandler | ApiErrorHandlerCreator } {
    return {
        ...getRepairCalculationErrorHandlers(),
        ...getDekraErrorHandlers(),
        //*****************************************************************************
        //  General
        //****************************************************************************/
        AUDATEX_REQUEST_FAILED: {
            title: 'Anfrage gescheitert',
            body: 'Die Audatex-Schnittstelle hat einen Fehler zurück gegeben. Bitte kontaktiere die <a href="/Hilfe" target="_blank">Hotline</a>.',
        },
        AUDATEX_API_UNAVAILABLE: {
            title: 'Audatex-Server nicht erreichbar',
            body: 'Das scheint ein temporäres Problem zu sein. Bitte versuche es später noch einmal oder hake beim Audatex-Support nach.',
            partnerLogo: 'audatex',
        },
        AUDATEX_FIELD_VALUE_TOO_LARGE: {
            title: 'Audatex: Wert zu lang',
            body: 'Ein Wert ist zu lang, um ihn zu Audatex zu übertragen. Leider liefert Audatex nicht den Namen des Feldes mit, das zu lang ist.<br><br>Bitte prüfe alle Felder, ob sie ungewöhnlich lange Werte enthalten.<br><br>Falls du nicht weiter kommst, melde dich bei der <a href="/Hilfe" target="_blank">Hotline</a>.',
            partnerLogo: 'audatex',
        },
        NO_PERMISSION_TO_GET_AUDATEX_TASK: {
            title: 'Berechtigung fehlt',
            body: `Der hinterlegte Audatex-Nutzer hat keinen Zugriff auf den angefragten Task. Bitte prüfe, ob der richtige Audatex-Account in den Einstellungen angegeben wurde, oder starte einen neuen Vorgang.`,
            partnerLogo: 'audatex',
        },
        AUDATEX_TASK_IS_ARCHIVED: {
            title: 'Vorgang archiviert fehlt',
            body: `Der Audatex-Vorgang ist archiviert und kann deshalb nicht abgerufen werden. Bitte hole den Vorgang in Qapter aus dem Archiv und frage ihn erneut ab.`,
            partnerLogo: 'audatex',
        },
        /////////////////////////////////////////////////////////////////////////////*/
        //  END General
        /////////////////////////////////////////////////////////////////////////////*/

        //*****************************************************************************
        //  Authentication/Authorization
        //****************************************************************************/
        AUDATEX_CREDENTIALS_INCOMPLETE: {
            title: 'Zugangsdaten unvollständig',
            body: 'Bitte vervollständige deine Audatex-Zugangsdaten in den <a href="/Einstellungen#calculation-providers-section">Einstellungen</a>.',
        },
        AUDATEX_LICENSE_MISSING: {
            title: 'Audatex-Lizenz fehlt',
            body: 'Die Audatex-Schnittstelle erfordert eine separate Lizenz, die dir fehlt. Bitte kontaktiere die <a href="/Hilfe" target="_blank">Hotline</a>.',
        },
        AUDATEX_CREDENTIALS_INVALID: {
            title: 'Zugangsdaten ungültig',
            body: 'Bitte überprüfe deine Audatex-Zugangsdaten in den <a href="/Einstellungen#calculation-providers-section">Einstellungen</a>.',
        },
        /////////////////////////////////////////////////////////////////////////////*/
        //  END Authentication/Authorization
        /////////////////////////////////////////////////////////////////////////////*/

        //*****************************************************************************
        //  VIN
        //****************************************************************************/
        /////////////////////////////////////////////////////////////////////////////*/
        //  END VIN
        /////////////////////////////////////////////////////////////////////////////*/

        //*****************************************************************************
        //  KBA Code
        //****************************************************************************/
        /////////////////////////////////////////////////////////////////////////////*/
        //  END KBA Code
        /////////////////////////////////////////////////////////////////////////////*/

        //*****************************************************************************
        //  Calculation
        //****************************************************************************/
        NO_AUDATEX_CALCULATION_FOUND: {
            title: 'Kalkulation unfertig',
            body: 'Bitte kalkuliere den Schaden in Qapter.',
            partnerLogo: 'audatex',
        },
        NO_AUDATEX_CALCULATION_FOUND_WITHIN_AUDATEX_TASK: {
            title: 'Keine Audatex-Kalkulation gefunden',
            body: 'Die Werkstattkostensätze und andere Daten konnten nicht in die Audatex-Kalkulation übernommen werden, weil keine Kalkulation im Audatex-Vorgang existiert. Bitte übertrage die Daten manuell.',
            partnerLogo: 'audatex',
        },
        NO_AUDATEX_CALCULATION_SELECTED: {
            title: 'Keine Kalkulation ausgewählt',
            body: 'Bitte wähle in Qapter eine Kalkulation aus, die du im Gutachten verwenden möchtest.',
        },
        AUDATEX_CALCULATION_UNFINISHED: {
            title: 'Unfertige Kalkulation',
            body: 'Bitte kalkuliere den Schaden in Qapter zu Ende.',
        },
        /////////////////////////////////////////////////////////////////////////////*/
        //  END Calculation
        /////////////////////////////////////////////////////////////////////////////*/

        NO_AUDATEX_VALUATION_DOCUMENT_FOUND: {
            title: 'Audatex-Bewertung nicht abgeschlossen',
            body: 'Bitte schließe die Bewertung in Audatex ab und versuche es erneut.',
        },
        IMPORTING_AUDATEX_VALUATION_DOCUMENT_FAILED: {
            title: 'Audatex-Bewertung nicht importiert',
            body: 'Bitte versuche es erneut oder kontaktiere die <a href="/Hilfe" target="_blank">Hotline</a>.',
        },
    };
}

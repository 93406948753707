import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { CdkVirtualScrollViewport } from '@angular/cdk/scrolling';
import { Component, ElementRef, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { get } from 'lodash-es';
import { duplicateDocumentBuildingBlockCondition } from '@autoixpert/lib/document-building-blocks/duplicate-document-building-block-condition';
import { filterDocumentPlaceholders } from '@autoixpert/lib/document-building-blocks/get-available-placeholders';
import { isDocumentBuildingBlockConditionGroup } from '@autoixpert/lib/document-building-blocks/is-document-building-block-condition-group';
import { replacePlaceholders } from '@autoixpert/lib/documents/replace-document-building-block-placeholders';
import {
    PlaceholderValueLeafDefinition,
    PlaceholderValueTree,
} from '@autoixpert/lib/placeholder-values/get-placeholder-value-tree';
import { PlaceholderValues } from '@autoixpert/lib/placeholder-values/get-placeholder-values';
import { FieldGroupConfig } from '@autoixpert/models/custom-fields/field-group-config';
import { DocumentBuildingBlockCondition } from '@autoixpert/models/documents/document-building-block-condition';
import { DocumentBuildingBlockConditionGroup } from '@autoixpert/models/documents/document-building-block-condition-group';
import { DateInputComponent } from 'src/app/shared/components/date-input/date-input.component';
import { exampleReport } from 'src/app/shared/mock-data/example-report';
import { getTypeAtPropertyPath } from '../../libraries/document-building-blocks/get-type-at-property-path';
import { ToastService } from '../../services/toast.service';

/**
 * Component for displaying a single condition (or condition group) inside an conditions editor (document building block or document building block variant).
 * Uses itself recursively to display nested conditions inside a condition group.
 */
@Component({
    selector: 'document-building-block-condition',
    templateUrl: './document-building-block-condition.component.html',
    styleUrls: ['./document-building-block-condition.component.scss'],
})
export class DocumentBuildingBlockConditionComponent {
    constructor(private toastService: ToastService) {}

    readonly Math = Math;
    readonly isDocumentBuildingBlockConditionGroup = isDocumentBuildingBlockConditionGroup;

    @Input() condition: DocumentBuildingBlockCondition | DocumentBuildingBlockConditionGroup;
    @Input() conditionNestingDepth: number = 0;

    @Input() availableDocumentPlaceholders: string[] = [];
    filteredPlaceholdersForConditions: string[] = [];

    // Placeholder values for the selected report or invoice, used to show values in property path selection overlay
    @Input() placeholderValues: PlaceholderValues | undefined;

    // Custom Fields
    @Input() placeholderValueTree: PlaceholderValueTree;
    @Input() fieldGroupConfigs: FieldGroupConfig[] = [];

    @Output() removeCondition = new EventEmitter<
        DocumentBuildingBlockCondition | DocumentBuildingBlockConditionGroup
    >();

    @Output() duplicateCondition = new EventEmitter<
        DocumentBuildingBlockCondition | DocumentBuildingBlockConditionGroup
    >();

    @Output() turnIntoConditionGroup = new EventEmitter<DocumentBuildingBlockCondition>();

    @ViewChild(CdkVirtualScrollViewport) autocompleteVirtualScrollViewport?: CdkVirtualScrollViewport;
    @ViewChild('propertyPathInput') propertyPathInput?: ElementRef<HTMLInputElement>;
    @ViewChild('comparisonValueInput') comparisonValueInput?: ElementRef<HTMLInputElement>;
    @ViewChild('comparisonValueDateInput') comparisonValueDateInput?: DateInputComponent;

    private cachedPlaceholderValue: { [placeholder: string]: string } = {};

    //*****************************************************************************
    //  View Handlers
    //****************************************************************************/
    public addCondition(): void {
        if (!isDocumentBuildingBlockConditionGroup(this.condition)) {
            return;
        }

        const newCondition = new DocumentBuildingBlockCondition();
        this.condition.conditions.push(newCondition);
    }

    public addConditionGroup(): void {
        if (!isDocumentBuildingBlockConditionGroup(this.condition)) {
            return;
        }

        const newConditionGroup = new DocumentBuildingBlockConditionGroup();
        newConditionGroup.conditions = [new DocumentBuildingBlockCondition()];
        this.condition.conditions.push(newConditionGroup);
    }

    public onPropertyPathSelect(condition: DocumentBuildingBlockCondition): void {
        this.setDefaultOperator(condition);

        // Some operators must be compared with certain value. E.g. truthy with true.
        this.setDefaultComparisonValue(condition);
    }

    handleRemoveChildCondition(condition: DocumentBuildingBlockCondition | DocumentBuildingBlockConditionGroup) {
        if (!isDocumentBuildingBlockConditionGroup(this.condition)) {
            return;
        }

        const conditionGroup = this.condition;

        // Last condition in group, remove entire group
        if (conditionGroup.conditions.length === 1) {
            this.removeCondition.emit(conditionGroup);
        } else {
            const index = conditionGroup.conditions.indexOf(condition);
            const [deletedCondition] = conditionGroup.conditions.splice(index, 1);

            // Restore deleted condition toast
            const toast = this.toastService.info('Bedingung gelöscht', 'Klicken, um sie wiederherzustellen.', {
                showProgressBar: true,
                timeOut: 10000,
            });

            toast.click.subscribe(() => {
                conditionGroup.conditions.splice(index, 0, deletedCondition);
            });
        }
    }

    handleDuplicateChildCondition(condition: DocumentBuildingBlockCondition | DocumentBuildingBlockConditionGroup) {
        if (!isDocumentBuildingBlockConditionGroup(this.condition)) {
            return;
        }

        const index = this.condition.conditions.indexOf(condition);
        const newCondition = duplicateDocumentBuildingBlockCondition(condition);
        this.condition.conditions.splice(index + 1, 0, newCondition);
    }

    handleTurnIntoConditionGroup(condition: DocumentBuildingBlockCondition) {
        if (!isDocumentBuildingBlockConditionGroup(this.condition)) {
            return;
        }

        const index = this.condition.conditions.indexOf(condition);
        const newConditionGroup = new DocumentBuildingBlockConditionGroup();
        newConditionGroup.conditions = [condition];
        this.condition.conditions.splice(index, 1, newConditionGroup);
    }

    initializeAutocompleteVirtualScrollViewport() {
        /**
         * This is necessary to fix the following bug:
         * 1. User opens overlay and scrolls down through the list
         * 2. User closes and re-opens the overlay
         * 3. Sometimes the list is completely empty and the user has to scroll once for the items to be rendered
         */
        this.autocompleteVirtualScrollViewport?.checkViewportSize();
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END View Handlers
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Conditions
    //****************************************************************************/

    public propertyPathExists(propertyPath: string): boolean {
        return !!get(this.placeholderValueTree, propertyPath);
    }

    public getTypeAtPropertyPath(propertyPath: string) {
        return getTypeAtPropertyPath(propertyPath, this.placeholderValueTree);
    }

    public filterPlaceholdersForConditions(searchTerm: string): void {
        this.filteredPlaceholdersForConditions = this.filterDocumentPlaceholders(searchTerm);
        this.autocompleteVirtualScrollViewport?.scrollToOffset(0);
    }

    private filterDocumentPlaceholders(searchTerm: string): string[] {
        return filterDocumentPlaceholders(this.availableDocumentPlaceholders, searchTerm);
    }

    public trackById(
        index: number,
        condition: DocumentBuildingBlockCondition | DocumentBuildingBlockConditionGroup,
    ): string {
        return condition._id;
    }

    // Operators
    /**
     * @param {string} propertyPath
     * @returns {string[]}
     */
    public getOperatorsForProperty(propertyPath: string): DocumentBuildingBlockCondition['operator'][] {
        const type = this.getTypeAtPropertyPath(propertyPath);

        // Possible operators depend on the property's type
        switch (type) {
            case 'String':
                return ['equal', 'notEqual', 'includes', 'includesNot', 'empty', 'notEmpty'];
            case 'Boolean':
                return ['truthy', 'falsy'];
            case 'Tristate':
                return ['truthy', 'falsy', 'unknown'];
            case 'Number':
                return [
                    'equal',
                    'notEqual',
                    'greaterThan',
                    'greaterThanOrEqual',
                    'lessThan',
                    'lessThanOrEqual',
                    'empty',
                    'notEmpty',
                    'truthy',
                    'falsy',
                ];
            case 'Date':
            case 'Date-month-only':
            case 'Time':
                return [
                    'equal',
                    'notEqual',
                    'greaterThan',
                    'greaterThanOrEqual',
                    'lessThan',
                    'lessThanOrEqual',
                    'empty',
                    'notEmpty',
                ];
            case 'Array':
                return ['includes', 'includesNot', 'empty', 'notEmpty'];
            default:
                return [];
        }
    }

    public setOperator(
        condition: DocumentBuildingBlockCondition,
        operator: DocumentBuildingBlockCondition['operator'],
    ): void {
        condition.operator = operator;
        // Use setTimeout as the input might not be rendered yet (currently selected operator is "empty", "notEmpty", ...)
        setTimeout(() => this.focusComparisonValueInput());
    }

    public setGroupOperator(
        condition: DocumentBuildingBlockConditionGroup,
        operator: DocumentBuildingBlockConditionGroup['conditionsOperator'],
    ): void {
        condition.conditionsOperator = operator;
    }

    private setDefaultOperator(condition: DocumentBuildingBlockCondition): string {
        if (!condition.propertyPath) return;

        const possibleOperators = this.getOperatorsForProperty(condition.propertyPath);
        if (!condition.operator || !possibleOperators.includes(condition.operator)) {
            condition.operator = possibleOperators[0];
        }

        return condition.operator;
    }

    public getComparisonValueType(propertyPath: string): string {
        const type = this.getTypeAtPropertyPath(propertyPath);

        switch (type) {
            case 'String':
            case 'Array':
                return 'text';
            case 'Number':
                return 'number';
            case 'Date':
            case 'Date-month-only':
                return 'date';
            case 'Boolean':
                return 'boolean';
            case 'Tristate':
                return 'tristate';
            case 'Time':
                return 'time';
            case undefined:
                return null;
            default:
                throw Error('UNKNOWN_COMPARISON_VALUE_TYPE. Please use types like String oder Number.');
        }
    }

    /**
     * Depending on the placeholder's type, replace forbidden comparison values with an allowed one.
     *
     * @param {DocumentBuildingBlockCondition} condition
     */
    public setDefaultComparisonValue(condition: DocumentBuildingBlockCondition): void {
        const propertyType = get(this.placeholderValueTree, condition.propertyPath);

        // Booleans always compare to 'true'
        if (propertyType === 'Boolean') {
            condition.comparisonValue = true;
            return;
        }

        // in all other cases, replace true or null values
        if (condition.comparisonValue === true || condition.comparisonValue === null) {
            condition.comparisonValue = '';
            return;
        }
    }

    protected isComparisonValueHidden(condition: DocumentBuildingBlockCondition): boolean {
        return (
            !condition.operator ||
            condition.operator === 'truthy' ||
            condition.operator === 'falsy' ||
            condition.operator === 'empty' ||
            condition.operator === 'notEmpty' ||
            condition.operator === 'unknown'
        );
    }

    public getComparisonValueSuggestions(condition: DocumentBuildingBlockCondition): {
        availableSuggestions: string[];
        suggestionType: 'enum' | 'autocomplete';
    } {
        const propertyType: PlaceholderValueLeafDefinition = get(
            this.placeholderValueTree,
            condition.propertyPath,
        ) as PlaceholderValueLeafDefinition;
        let availableSuggestions: string[] = [];
        let suggestionType: 'enum' | 'autocomplete' = null;

        if (propertyType) {
            if (typeof propertyType === 'object' && ('enum' in propertyType || 'autocomplete' in propertyType)) {
                availableSuggestions = propertyType.enum || propertyType.autocomplete || [];
                if (typeof propertyType.enum !== 'undefined') {
                    suggestionType = 'enum';
                }
                if (typeof propertyType.autocomplete !== 'undefined') {
                    suggestionType = 'autocomplete';
                }
            }
        }

        return {
            availableSuggestions,
            suggestionType,
        };
    }

    public filterComparisonValueSuggestions(condition: DocumentBuildingBlockCondition): string[] {
        const { availableSuggestions } = this.getComparisonValueSuggestions(condition);

        const searchTerms: string[] = (condition.comparisonValue || '').toLowerCase().split(' ');

        return availableSuggestions.filter((suggestion) => {
            const suggestionInLowerCase: string = suggestion.toLowerCase();
            return searchTerms.every((searchTerm) => suggestionInLowerCase.includes(searchTerm));
        });
    }

    public isValidComparisonValue(condition: DocumentBuildingBlockCondition): boolean {
        const { availableSuggestions, suggestionType } = this.getComparisonValueSuggestions(condition);

        if (!availableSuggestions.length) return true;

        // In case of enum, only allow the specified values.
        if (suggestionType === 'enum') {
            return availableSuggestions.includes(condition.comparisonValue);
        }
        // In case of autocomplete, allow any value.
        if (suggestionType === 'autocomplete') {
            return true;
        }
    }

    public hanldeConditionDrop(
        event: CdkDragDrop<DocumentBuildingBlockCondition | DocumentBuildingBlockConditionGroup>,
    ): void {
        if (isDocumentBuildingBlockConditionGroup(this.condition)) {
            moveItemInArray(this.condition.conditions, event.previousIndex, event.currentIndex);
        }
    }

    getPlaceholderValue(placeholder: string) {
        // Cache placeholder values to avoid unnecessary calculations due to change detection
        if (this.cachedPlaceholderValue[placeholder] === undefined) {
            const placeholderValue = replacePlaceholders({
                textWithPlaceholders: `{${placeholder}}`,
                placeholderValues: this.placeholderValues || (exampleReport as any),
                fieldGroupConfigs: this.fieldGroupConfigs,
                isHtmlAllowed: false,
            });
            // Filter out "WERT_..._FEHLT" or invalid placeholder
            if (placeholderValue !== `WERT_${placeholder}_FEHLT` && placeholderValue !== `{${placeholder}}`) {
                this.cachedPlaceholderValue[placeholder] = placeholderValue;
            }
        }

        return this.cachedPlaceholderValue[placeholder];
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Conditions
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Public API
    //****************************************************************************/

    focusPropertyPathInput({ preventScroll }: { preventScroll?: boolean } = {}) {
        this.propertyPathInput?.nativeElement.focus({ preventScroll });
    }

    focusComparisonValueInput({ preventScroll }: { preventScroll?: boolean } = {}) {
        this.comparisonValueInput?.nativeElement.focus({ preventScroll });
        this.comparisonValueDateInput?.focusInput({ preventScroll });
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Public API
    /////////////////////////////////////////////////////////////////////////////*/
}

import { HttpClient, HttpHeaders } from '@angular/common/http';
import { map, tap } from 'rxjs/operators';
import { apiBasePath } from '@autoixpert/external-apis/api-base-path';
import { pluralize } from '@autoixpert/lib/pluralize';
import { httpRetry } from '@autoixpert/lib/rxjs-http-retry';
import { AxError } from '@autoixpert/models/errors/ax-error';
import { BlobDataType } from '@autoixpert/models/indexed-db/database-blob.types';
import { DatabaseServiceName } from '@autoixpert/models/indexed-db/database.types';

export class AxHttpSyncBlob {
    public readonly serviceName: DatabaseServiceName; // Name of the service in the backend that this database shall sync with. Singular, such as "report" for the endpoint "/reports"
    protected serviceNamePlural: string; // Plural name, such as "reports" or "contactPeople"-> Is explicitly passed or inferred automatically.

    /**
     * The field name in the FormData object that's used for a POST request when creating the blob on the server.
     */
    protected formDataBlobFieldName: string;
    /**
     * The content of the "Accept" HTTP header. This lets the server know that kind of content this client expects.
     */
    protected acceptHeaderContent: string;

    /**
     * Ensure that the same blob is not downloaded in parallel to save bandwidth. This may happen if the same image
     * is requested twice when the initialization progress is being triggered in an Observable callback.
     */
    private downloadPromises = new Map<BlobDataType['_id'], Promise<Blob>>();

    protected httpClient: HttpClient;

    constructor(params: {
        serviceName: DatabaseServiceName;
        serviceNamePlural?: string;
        httpClient: HttpClient;
        formDataBlobFieldName: string;
        acceptHeaderContent?: string;
    }) {
        this.serviceName = params.serviceName;
        this.serviceNamePlural = params.serviceNamePlural ?? pluralize(this.serviceName);

        this.httpClient = params.httpClient;

        this.formDataBlobFieldName = params.formDataBlobFieldName;
        this.acceptHeaderContent = params.acceptHeaderContent || 'image/jpeg, image/png, */*';
    }

    public async createRemote(
        record: { _id: BlobDataType['_id']; blob: Blob },
        options: { apiUrl?: string } = {},
    ): Promise<void> {
        // Allow custom URL segments in target path such as /reports/${reportId}/photoFiles
        const apiUrl: string = options?.apiUrl || `${apiBasePath}/${this.serviceNamePlural}`;

        const formData = new FormData();
        formData.append('_id', record._id);
        formData.append(this.formDataBlobFieldName, record.blob);

        await this.httpClient
            .post(apiUrl, formData)
            .pipe(
                // Retry once in case of an error.
                httpRetry({
                    delayMs: 2000,
                    backoffMs: 3_000,
                    maxRetry: 3,
                }),
            )
            .toPromise();
    }

    public async getRemote(recordId: string, options: { apiUrl?: string } = {}): Promise<Blob> {
        // Allow custom URL segments in target path such as /reports/${reportId}/photoFiles
        const apiUrl: string = options?.apiUrl || `${apiBasePath}/${this.serviceNamePlural}`;

        if (!this.downloadPromises.has(recordId)) {
            // Use httpClient.request (instead of httpClient.get) to send a request without Angular's standard "Cache-Control: no-cache" headers.
            const downloadPromise = this.httpClient
                .get(`${apiUrl}/${recordId}`, {
                    headers: new HttpHeaders().set('Accept', this.acceptHeaderContent),
                    observe: 'response',
                    responseType: 'blob',
                })
                .pipe(
                    map((response) => response.body),
                    tap({
                        complete: () => {
                            // Remove the entry so that new calls to this function getFile() create a new server request.
                            this.downloadPromises.delete(recordId);
                        },
                        error: () => {
                            // Remove the entry so that new calls to this function getFile() create a new server request.
                            this.downloadPromises.delete(recordId);
                        },
                    }),
                )
                .toPromise();

            this.downloadPromises.set(recordId, downloadPromise);
        }

        try {
            return await this.downloadPromises.get(recordId);
        } catch (error) {
            throw new AxError({
                code: 'GETTING_BLOB_FROM_SERVER_FAILED',
                message: `The blob could not be fetched from the server. Have a look at the causedBy/error property.`,
                data: {
                    serviceName: this.serviceName,
                    recordId,
                },
            });
        }
    }

    public async deleteRemote(recordId: string, options: { apiUrl?: string } = {}): Promise<void> {
        // Allow custom URL segments in target path such as /reports/${reportId}/photoFiles
        const apiUrl: string = options?.apiUrl || `${apiBasePath}/${this.serviceNamePlural}`;

        await this.httpClient.delete(`${apiUrl}/recordId`).toPromise();
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Server
    /////////////////////////////////////////////////////////////////////////////*/
}

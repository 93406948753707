<div class="dialog-overlay" (mousedown)="handleOverlayClick($event)">
    <div class="dialog-container">
        <mat-icon class="dialog-close-icon" (click)="emitCloseEvent()">close</mat-icon>
        <h2>Audatex Add-on buchen</h2>

        <p class="text-align-center" *ngIf="!isTestOrPartnerAccount()">
            Die Kosten für das Audatex Add-on sind abhängig von der Anzahl der Benutzer.
            <br />
            Für dein Team ändern sich die Kosten wie folgt:
        </p>
        <p class="text-align-center" *ngIf="isTestOrPartnerAccount()">
            Während des Tests sind zusätzliche Nutzer kostenfrei.
            <br />
            Für die Zeit danach:
        </p>

        <!--============================================-->
        <!-- Pricing Table -->
        <!--============================================-->
        <div id="pricing-table-container">
            <table id="pricing-table">
                <thead>
                    <th></th>
                    <th>
                        <div class="label">Preis/Monat</div>
                    </th>
                </thead>
                <tbody>
                    <!--============================================-->
                    <!-- Display both options during test - With/Without Audatex Add-on -->
                    <!--============================================-->
                    <tr>
                        <td class="item-title-cell">
                            <div class="semibold">
                                Zusatz für Team-Account
                                <span
                                    class="help-indicator"
                                    matTooltip="Ob du das Audatex-Addon buchen möchtest, kannst du bei der Bestellung deines Büro-Accounts angeben.">
                                    ?
                                </span>
                            </div>
                            <div class="label">1 Nutzer inklusive</div>
                        </td>
                        <td class="price-cell">{{ PRICE_PER_TEAM }} €</td>
                    </tr>
                    <tr>
                        <td class="item-title-cell">
                            <div class="semibold">Zusatz pro Zusatznutzer</div>
                            <div class="label">{{ userCount }} Zusatznutzer à {{ PRICE_PER_USER }} €</div>
                        </td>
                        <td class="price-cell">{{ userCount * PRICE_PER_USER }} €</td>
                    </tr>
                    <!--============================================-->
                    <!-- END Display both options during test - With/Without Audatex Add-on -->
                    <!--============================================-->
                </tbody>
            </table>

            <div class="label text-align-center">
                <!--********** Price List **********-->
                <a
                    href="https://www.autoixpert.de/Preise?utm_source=autoixpert-settings&utm_medium=invite-colleagues-dialog"
                    target="_blank"
                    rel="”noopener”">
                    Preisliste ansehen
                </a>
            </div>
        </div>
        <!--============================================-->
        <!-- END Pricing Table -->
        <!--============================================-->

        <div class="dialog-buttons-container">
            <button (click)="orderAudatexAddon()">Aktivieren</button>
        </div>
    </div>
</div>

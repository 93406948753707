import { Component, EventEmitter, Input, Output } from '@angular/core';
import {
    CustomCarProperty,
    CustomCarPropertyAutocomplete,
} from '@autoixpert/models/reports/car-identification/custom-car-property';

@Component({
    selector: 'custom-car-property-input-value',
    templateUrl: './custom-car-property-input-value.component.html',
    styleUrls: ['./custom-car-property-input-value.component.scss'],
})
export class CustomCarPropertyInputValueComponent {
    @Input() property: CustomCarProperty;
    @Input() isReportLocked: boolean;

    // Default values for the current property title - if available.
    @Input() currentPropertyAutocompleteValues: CustomCarPropertyAutocomplete['values'] = [];

    @Output() propertyChanged = new EventEmitter<CustomCarProperty>();

    filteredPropertyValuesAutocomplete: CustomCarProperty['value'][] = [];

    ngOnInit() {
        this.filterPropertyValuesForAutocomplete();
    }

    /**
     * Filter the available property titles for the autocomplete.
     */
    filterPropertyValuesForAutocomplete() {
        if (!this.currentPropertyAutocompleteValues?.values || !this.property?.value) {
            this.filteredPropertyValuesAutocomplete = [];
        }

        // Return only values, that contain the user's search terms.
        const searchTerms = this.property.value?.toLowerCase().split(' ') ?? [];
        this.filteredPropertyValuesAutocomplete = this.currentPropertyAutocompleteValues.filter((propertyValue) => {
            return searchTerms.every((searchTerm) => {
                return propertyValue.toLowerCase().includes(searchTerm);
            });
        });
    }

    /**
     * Helpers
     */
    emitChange() {
        this.propertyChanged.emit(this.property);
    }
}

<div id="layout-container">
    <!--============================================-->
    <!-- Menu -->
    <!--============================================-->
    <div id="menu-column"></div>
    <!--============================================-->
    <!-- END Menu -->
    <!--============================================-->

    <!--============================================-->
    <!-- Reporting Column -->
    <!--============================================-->
    <div id="reporting-column">
        <!--============================================-->
        <!-- Graph -->
        <!--============================================-->
        <div id="graph-container" class="card card-with-padding">
            <!--============================================-->
            <!-- Graph Header -->
            <!--============================================-->
            <div id="graph-header">
                <!--********** Report Name **********-->
                <div id="report-name-container">
                    <mat-icon
                        class="toolbar-icon"
                        matTooltip="Zurück zur Übersicht"
                        (click)="navigateBackToAnalyticsList()">
                        arrow_back
                    </mat-icon>
                    <h2>{{ chartTitle }}</h2>
                </div>

                <short-payment-analytics-navigation-bar></short-payment-analytics-navigation-bar>

                <analytics-filter-button [filterRef]="filter"></analytics-filter-button>

                <div class="date-and-settings-container">
                    <!--********** Date Filter **********-->
                    <date-range-picker
                        [(dateFrom)]="filterAnalyticsFrom"
                        [(dateTo)]="filterAnalyticsTo"
                        (dateRangeChange)="updateAnalytics(); rememberDateRange()"></date-range-picker>
                    <analytics-settings-menu
                        (useNetValuesChange)="refreshLocalData()"
                        localStoragePostfix="ShortPayments"
                        [useNetValuesDefaultValue]="false"></analytics-settings-menu>
                </div>
            </div>

            <!--============================================-->
            <!-- END Graph Header -->
            <!--============================================-->

            <analytics-filter (filterChange)="updateAnalytics()" (initialized)="filtersLoaded()"></analytics-filter>

            <div class="chart-container">
                <div class="chart-placeholder" *ngIf="analyticsPending">
                    <img class="loading-indicator" src="/assets/images/icons/loading-indicator.svg" alt="" />
                    Lade Auswertung ...
                </div>
                <div class="chart-placeholder" *ngIf="!analyticsPending && records.length === 0">
                    <img class="no-records-to-display-image" src="/assets/images/icons/no-data-to-display.png" />
                    <div class="no-data-found-message">Keine Daten gefunden</div>
                    Probiere doch andere Filter.
                </div>
                <div id="chart" *ngIf="!analyticsPending && records.length"></div>
            </div>

            <!--============================================-->
            <!-- Graph Summary -->
            <!--============================================-->
            <div id="graph-summary-container" class="card">
                <!--********** Total **********-->
                <div class="graph-summary-item-container">
                    <!--********** Label **********-->
                    <div class="graph-summary-item-label">
                        Bezahlte Kürzungen
                        <span class="series-color short-payment-color"></span>
                    </div>

                    <!--********** Value **********-->
                    <div class="graph-summary-item-value">{{ totalShortPayments | number: '1.0-0' }} €</div>
                </div>

                <!--********** Most Frequent Reason **********-->
                <div
                    class="graph-summary-item-container"
                    matTooltip="{{ mostFrequentReason?.resolutionName }} | {{
                        mostFrequentReason?.occurrences
                    }}-mal im ausgewählten Zeitraum zu einer Nachzahlung geführt">
                    <!--********** Label **********-->
                    <div class="graph-summary-item-label">Häufigster Zahler</div>

                    <!--********** Value **********-->
                    <div class="graph-summary-item-value text-overflow-ellipsis smaller-size">
                        {{ mostFrequentReason?.resolutionName || 'Kein Zahler angegeben' }}
                    </div>
                </div>

                <!--********** Financially Most Impactful **********-->
                <div
                    class="graph-summary-item-container"
                    matTooltip="{{ financiallyMostImpactfulReason?.resolutionName }} | {{
                        financiallyMostImpactfulReason?.shortPaymentAmountGross | number: '1.2-2'
                    }} € wurden im ausgewählten Zeitraum durch diese Methode doch noch eingebracht">
                    <!--********** Label **********-->
                    <div class="graph-summary-item-label">Monetär wichtigster</div>

                    <!--********** Value **********-->
                    <div class="graph-summary-item-value text-overflow-ellipsis smaller-size">
                        {{ financiallyMostImpactfulReason?.resolutionName || 'Kein Zahler angegeben' }}
                    </div>
                </div>
            </div>
            <!--============================================-->
            <!-- END Graph Summary -->
            <!--============================================-->
        </div>
        <!--============================================-->
        <!-- END Graph -->
        <!--============================================-->

        <!--============================================-->
        <!-- Series List -->
        <!--============================================-->
        <div id="series-list-card" class="card card-with-padding">
            <div id="series-list-toolbar">
                <export-invoice-analytics-records-icon
                    [records]="$any(records)"
                    [summaryDownloadName]="chartTitle"></export-invoice-analytics-records-icon>
            </div>
            <div id="series-list-table-container">
                <table id="series-list">
                    <thead>
                        <tr>
                            <!--********** Insurance Name **********-->
                            <th class="series-entry-title-heading">Versicherung</th>

                            <!--********** Short Payment Total **********-->
                            <th class="series-entry-value-heading">
                                <span class="series-color short-payment-color"></span>
                                Bezahlte Kürzungen ({{ settings.useNetValues ? 'netto' : 'brutto' }})
                            </th>

                            <!--********** Possible Reasons **********-->
                            <th
                                class="series-entry-value-heading"
                                *ngFor="let possiblePayer of possiblePayers; let index = index">
                                <!--============================================-->
                                <!-- Header Colors -->
                                <!--============================================-->
                                <!--********** If at least one undefined reason is present, squeeze the column in. **********-->
                                <ng-container *ngIf="areSomeRecordsMissingPayer">
                                    <span *ngIf="!possiblePayer" class="series-color undetermined-color"></span>
                                    <!-- No index bumping required because we squeezed in the undetermined color. -->
                                    <span
                                        *ngIf="possiblePayer"
                                        class="series-color analytics-series-{{ index }}"></span>
                                </ng-container>

                                <!--********** No reason is undefined **********-->
                                <span
                                    *ngIf="!areSomeRecordsMissingPayer"
                                    class="series-color analytics-series-{{ index + 1 }}"></span>
                                <!--============================================-->
                                <!-- END Header Colors -->
                                <!--============================================-->

                                {{ possiblePayer || 'Ohne Zahler' }}
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr
                            class="series-entry-row"
                            *ngFor="let record of records"
                            [ngClass]="{ hidden: isRecordHidden(record) }">
                            <!--********** Insurance Name **********-->
                            <td class="series-entry-title-cell" [ngClass]="{ 'not-available': !record.insurance }">
                                <div class="series-entry-title-inner-container">
                                    <div class="flex-grow text-overflow-ellipsis">
                                        {{ record.insurance || 'Ohne Versicherung' }}
                                    </div>
                                    <mat-icon
                                        class="toolbar-icon toggle-hide-record-icon"
                                        matTooltip="Diesen Datensatz im Diagramm ausblenden"
                                        *ngIf="!isRecordHidden(record)"
                                        (click)="toggleHideRecord(record); refreshLocalData()">
                                        visibility
                                    </mat-icon>
                                    <mat-icon
                                        class="toolbar-icon toggle-hide-record-icon"
                                        matTooltip="Diesen Datensatz im Diagramm einblenden"
                                        *ngIf="isRecordHidden(record)"
                                        (click)="toggleHideRecord(record); refreshLocalData()">
                                        visibility_off
                                    </mat-icon>
                                </div>
                            </td>

                            <!--********** Short Payment Total **********-->
                            <td class="series-entry-value">
                                {{
                                    (settings.useNetValues
                                        ? record.allShortPaymentsSumNet
                                        : record.allShortPaymentsSumGross
                                    ) | number: '1.0-0'
                                }}
                                €
                                <div
                                    class="label"
                                    invoice-list-overlay-anchor
                                    [invoiceIds]="record.idsOfRegularInvoices"
                                    [displayNetValues]="settings.useNetValues">
                                    in {{ record.idsOfRegularInvoices.length }}
                                    {{ record.idsOfRegularInvoices.length === 1 ? 'Rechnung' : 'Rechnungen' }}
                                </div>
                            </td>

                            <!--********** Possible Reasons **********-->

                            <td
                                class="series-entry-value"
                                *ngFor="let possibleReason of possiblePayers; let index = index">
                                {{
                                    (settings.useNetValues
                                        ? getShortPaymentReasonObject(record, possibleReason)?.shortPaymentsSumNet
                                        : getShortPaymentReasonObject(record, possibleReason)?.shortPaymentsSumGross
                                    ) | number: '1.0-0'
                                }}
                                €
                                <div
                                    class="label"
                                    invoice-list-overlay-anchor
                                    [invoiceIds]="
                                        getShortPaymentReasonObject(record, possibleReason)?.idsOfRegularInvoices
                                    "
                                    [displayNetValues]="settings.useNetValues">
                                    in
                                    {{
                                        getShortPaymentReasonObject(record, possibleReason)?.idsOfRegularInvoices.length
                                    }}
                                    {{
                                        getShortPaymentReasonObject(record, possibleReason)?.idsOfRegularInvoices
                                            .length === 1
                                            ? 'Rechnung'
                                            : 'Rechnungen'
                                    }}
                                </div>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
        <!--============================================-->
        <!-- END Series List -->
        <!--============================================-->
    </div>
    <!--============================================-->
    <!-- END Reporting Column -->
    <!--============================================-->
</div>

<h2>Rechnungsnummern</h2>
<span class="label light mat-block text-align-center">
    Nach welchem Muster sollen deine Rechnungsnummern erzeugt werden?
</span>

<!--***********************************************
** Sub Menu
************************************************-->
<div id="invoice-number-preferences-toolbar-container">
    <mat-icon
        class="toolbar-icon"
        (click)="addInvoiceNumberConfig()"
        matTooltip="Weiteren Rechnungskreis hinzufügen. So kannst du pro Standort unabhängige Rechnungsnummern vergeben.">
        add
    </mat-icon>

    <mat-icon class="toolbar-icon" [matMenuTriggerFor]="invoiceNumberSubmenu">more_vert</mat-icon>

    <mat-menu #invoiceNumberSubmenu>
        <a [routerLink]="'Rechnungsnummern-Journal'" mat-menu-item>Rechnungsnummern-Journal</a>
    </mat-menu>
</div>
<!--***********************************************
** END Sub Menu
************************************************-->

<div class="info-note" *ngIf="isConfigOverwrittenByReportTokenSettings()">
    <mat-icon>info</mat-icon>
    <div>
        Manche
        <a (click)="scrollToReportTokenSettings.emit()">Aktenzeichen-Einstellungen</a>
        überschreiben diese Einstellungen für Rechnungen zu Gutachten. Freie Rechnungen ohne Gutachtenbezug nutzen
        weiterhin diese Konfiguration.
    </div>
</div>

<ng-container *ngFor="let invoiceNumberConfig of invoiceNumberConfigs">
    <h3 class="invoice-number-config-headline" *ngIf="invoiceNumberConfigs.length > 1">
        <span *ngIf="!invoiceNumberConfigTitlesInEditMode.has(invoiceNumberConfig)">
            {{ invoiceNumberConfig.title || getOfficeLocationTitle(invoiceNumberConfig) }}
        </span>

        <mat-form-field *ngIf="invoiceNumberConfigTitlesInEditMode.has(invoiceNumberConfig)">
            <input
                matInput
                [placeholder]="getOfficeLocationTitle(invoiceNumberConfig)"
                [(ngModel)]="invoiceNumberConfig.title"
                (change)="saveTeam()" />
            <span matSuffix>
                <mat-icon
                    class="input-toolbar-icon"
                    (click)="leaveTitleEditMode(invoiceNumberConfig)"
                    matTooltip="Bearbeitung beenden">
                    check
                </mat-icon>
            </span>
        </mat-form-field>

        <mat-icon
            id="default-icon"
            class="medium-icon toolbar-icon"
            [ngClass]="{ active: invoiceNumberConfig.isDefault }"
            matTooltip="Der Standard-Rechnungskreis gilt für alle Standorte, die mit keinem Rechnungskreis verknüpft sind.">
            star
        </mat-icon>
    </h3>
    <div class="invoice-number-config">
        <div class="invoice-number-preferences-submenu" *ngIf="invoiceNumberConfigs.length > 1">
            <mat-icon class="toolbar-icon" [matMenuTriggerFor]="invoiceNumberSubmenu">more_vert</mat-icon>
        </div>
        <mat-menu #invoiceNumberSubmenu>
            <!--********** Rename **********-->
            <a
                mat-menu-item
                class="menu-item-with-icon"
                (click)="startTitleEditMode(invoiceNumberConfig)"
                [matTooltip]="'Rechnungskreis umbenennen.'">
                <mat-icon>edit</mat-icon>
                Umbenennen
            </a>

            <!--********** Löschen **********-->
            <a
                mat-menu-item
                class="menu-item-with-icon"
                (click)="deleteInvoiceNumberConfig(invoiceNumberConfig)"
                [matTooltip]="
                    invoiceNumberConfig.isDefault
                        ? 'Der Standard-Rechnungskreis kann nicht gelöscht werden.'
                        : 'Rechnungskreis entfernen.'
                "
                [class.disabled]="invoiceNumberConfig.isDefault">
                <mat-icon>delete</mat-icon>
                Löschen
            </a>
        </mat-menu>

        <!--***********************************************
        ** Token Settings
        ************************************************-->

        <token-pattern-editor
            #tokenPatternEditor
            [team]="team"
            [user]="user"
            [teamMembers]="teamMembers"
            [invoiceNumberConfig]="invoiceNumberConfig"
            [disabled]="!userIsAdmin()"
            [showOfficeLocation]="invoiceNumberConfigs.length > 1"
            [allConfigs]="invoiceNumberConfigs"
            [showBorderBottom]="true"
            [(isExpanded)]="invoiceNumberConfigExpandedStates[invoiceNumberConfig._id]"
            [canCollapse]="invoiceNumberConfigs.length >= collapseThreshold"></token-pattern-editor>

        <!--***********************************************
        ** END Token Settings
        ************************************************-->

        <!--============================================-->
        <!-- Cancellation Invoice Numbers -->
        <!--============================================-->
        <preferences-row
            iconName="undo"
            id="cancellation-invoice-number-settings"
            *ngIf="
                invoiceNumberConfigExpandedStates[invoiceNumberConfig._id] ||
                invoiceNumberConfigs.length < collapseThreshold
            "
            @slideInAndOutVertically>
            <span class="">Nummer für Stornorechnungen</span>
            <div class="label light">
                Wie sollen die Nummern für Stornorechnungen, die nicht zu einem Gutachten gehören, vergeben werden?
            </div>

            <mat-select
                right
                class="styled"
                [disabled]="!userIsAdmin()"
                [ngModel]="invoiceNumberConfig.useCancellationInvoiceSuffix ? 'suffix' : 'continuous'"
                (selectionChange)="cancellationInvoiceSettingsChanged($event, invoiceNumberConfig)">
                <mat-option
                    value="continuous"
                    matTooltip="Stornorechnungen reihen sich in deinen normalen Rechnungskreis ein. Der Rechnungszähler wird hochgezählt.">
                    Fortlaufend
                </mat-option>
                <mat-option
                    value="suffix"
                    matTooltip="Einige Zeichen werden der Nummer der Originalrechnung angehängt, z. B. '-S' oder '-Storno'. Der Zähler wird nicht hochgezählt, sodass die Nummer der Stornorechnung eindeutig auf die stornierte Rechnung schließen lässt.">
                    Suffix
                </mat-option>
            </mat-select>
            <preferences-row *ngIf="invoiceNumberConfig.useCancellationInvoiceSuffix" @slideInAndOutVertically>
                <span>Suffix für Stornorechnungen</span>
                <span class="label light">
                    Dieser Text bildet zusammen mit der Nummer der Originalrechnung die Rechnungsnummer für die
                    Stornorechnung.
                </span>

                <mat-form-field right class="without-hint-spacing">
                    <input
                        id="suffix-input-field"
                        matInput
                        placeholder="Suffix"
                        (focus)="previousCancellationSuffix = invoiceNumberConfig.cancellationSuffix"
                        [(ngModel)]="invoiceNumberConfig.cancellationSuffix"
                        (change)="handleCancellationSuffixChange(invoiceNumberConfig)"
                        [disabled]="!userIsAdmin()" />
                </mat-form-field>

                <div helpSection class="help-links">
                    <span class="help-link">
                        <mat-icon class="small-icon outlined margin-right-5">help</mat-icon>
                        <a
                            target="_blank"
                            href="https://wissen.autoixpert.de/hc/de/articles/360004032791-Rechnungsnummern-einstellen">
                            Hilfe-Artikel
                        </a>
                    </span>
                </div>
            </preferences-row>
        </preferences-row>

        <!--============================================-->
        <!-- END Cancellation Invoice Numbers -->
        <!--============================================-->

        <!-- Optional Disclosure Indicator -->
        <div
            class="disclosure-icon"
            *ngIf="tokenPatternEditor.canCollapse"
            [matTooltip]="
                invoiceNumberConfigExpandedStates[invoiceNumberConfig._id]
                    ? 'Einstellungen verbergen'
                    : 'Weitere Einstellungen anzeigen'
            "
            (click)="
                invoiceNumberConfigExpandedStates[invoiceNumberConfig._id] =
                    !invoiceNumberConfigExpandedStates[invoiceNumberConfig._id]
            ">
            <mat-icon class="medium-icon">
                {{ invoiceNumberConfigExpandedStates[invoiceNumberConfig._id] ? 'unfold_less' : 'unfold_more' }}
            </mat-icon>
        </div>
    </div>
</ng-container>

<div class="helpcenter-link-container margin-top-20">
    <a
        class="helpcenter-link"
        (click)="scrollToReportTokenSettings.emit()"
        matTooltip="Diese Einstellung kannst du beim Aktenzeichenzähler vornehmen.">
        Zähler mit Aktenzeichen koppeln
    </a>
</div>

import { Component, HostBinding, HostListener, Inject, OnInit } from '@angular/core';
import {
    MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA,
    MatLegacyDialogRef as MatDialogRef,
} from '@angular/material/legacy-dialog';
import { CustomAutocompleteEntryType } from '@autoixpert/models/text-templates/custom-autocomplete-entry';
import { ToastService } from '../../services/toast.service';

@Component({
    selector: 'prompt-dialog',
    templateUrl: 'prompt-dialog.component.html',
    styleUrls: ['prompt-dialog.component.scss'],
})
export class PromptDialogComponent implements OnInit {
    constructor(
        private dialog: MatDialogRef<PromptDialogComponent, PromptDialogReturnValue>,
        @Inject(MAT_DIALOG_DATA) public data: PromptDialogData,
        private toastService: ToastService,
    ) {}

    public userInput: string;

    ngOnInit() {
        this.userInput = this.data.initialInputValue;
        this.maxWidth = this.data.maxWidth + 'px';
    }

    public confirm(): void {
        if (!this.userInput && !this.data.optional) {
            this.toastService.info(`Bitte fülle das Feld "${this.data.placeholder}"`);
            return;
        }

        this.dialog.close({
            confirmed: true,
            userInput: this.userInput,
        });
    }

    public cancel(): void {
        this.dialog.close({
            confirmed: false,
            userInput: this.userInput,
        });
    }

    public handleKeydownInInput(event: KeyboardEvent) {
        if (event.key === 'Enter') {
            this.confirm();
        }
    }

    @HostListener('window:keydown', ['$event'])
    handleKeydownOnHost(event: KeyboardEvent) {
        if (event.key === 'Escape') {
            this.cancel();
        }
    }

    @HostBinding('style.maxWidth') maxWidth: string;
}

export interface PromptDialogData {
    heading: string;
    content: string;
    helpIndicatorText?: string;
    placeholder: string;
    initialInputValue?: string;
    optional?: boolean;
    confirmLabel: string;
    cancelLabel: string;
    maxWidth?: number;
    inputFieldWidth?: string;
    // If provided, the dialog will display a CustomAutocompleteWithMemory instead of a regular input field
    autocompleteEntryType?: CustomAutocompleteEntryType;
}

export interface PromptDialogReturnValue {
    confirmed: boolean;
    userInput: string;
}

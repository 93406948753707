import { Component } from '@angular/core';
import { MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { getProductName } from '../../shared/libraries/get-product-name';

@Component({
    selector: 'repair-confirmation-creation-instructions-dialog',
    templateUrl: './repair-confirmation-creation-instructions-dialog.component.html',
    styleUrls: ['./repair-confirmation-creation-instructions-dialog.component.scss'],
})
export class RepairConfirmationCreationInstructionsDialogComponent {
    constructor(private dialog: MatDialogRef<RepairConfirmationCreationInstructionsDialogComponent>) {}

    public selectedSource: 'ownOffice' | 'thirdPartyOffice' = 'ownOffice';

    public closeDialog({ action }: { action: 'none' | 'createReportWithoutTokenOrInvoice' }) {
        this.dialog.close({ action });
    }

    protected readonly getProductName = getProductName;
}

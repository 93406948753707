import { Component, EventEmitter, HostListener, Inject, Input, OnInit, Output } from '@angular/core';
import {
    MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA,
    MatLegacyDialogRef as MatDialogRef,
} from '@angular/material/legacy-dialog';
import { isReportLocked } from '@autoixpert/lib/report/is-report-locked';
import { DatValuation, DatVehicleValueType } from '@autoixpert/models/reports/market-value/dat-valuation';
import { Report } from '@autoixpert/models/reports/report';
import { DatUser } from '@autoixpert/models/user/third-party-accounts/dat-user';
import { fadeInAndOutAnimation } from '../../../../shared/animations/fade-in-and-out.animation';
import { ReportDetailsService } from '../../../../shared/services/report-details.service';
import { ToastService } from '../../../../shared/services/toast.service';
import { UserPreferencesService } from '../../../../shared/services/user-preferences.service';

@Component({
    selector: 'dat-valuation-settings-dialog',
    templateUrl: 'dat-valuation-settings-dialog.component.html',
    styleUrls: ['dat-valuation-settings-dialog.component.scss'],
    animations: [fadeInAndOutAnimation()],
})
export class DatValuationSettingsDialogComponent implements OnInit {
    constructor(
        private userPreferences: UserPreferencesService,
        private dialog: MatDialogRef<DatValuationSettingsDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: DatValuationSettingsData,
        private reportDetailsService: ReportDetailsService,
        private toastService: ToastService,
    ) {}
    @Input() report: Report;
    @Input() datUser: DatUser;
    @Output() datValuationChange: EventEmitter<DatValuation> = new EventEmitter<DatValuation>();
    @Output() close: EventEmitter<void> = new EventEmitter<void>();

    private initialValuationSettings: DatValuation;
    public userPreferencesUpdatedIconShown: boolean = false;

    ngOnInit() {
        // Merge of the user preferences is handled in the report service
        this.report = this.data.report;
        this.datUser = this.data.datUser;

        // Remember the initial state of the valuation settings
        this.initialValuationSettings = JSON.parse(JSON.stringify(this.report.valuation.datValuation));
    }

    public selectPriceOrigin(origin: DatValuation['priceOrigin']): void {
        this.report.valuation.datValuation.priceOrigin = origin;
        this.saveReport();
    }

    //*****************************************************************************
    //  Value Type
    //****************************************************************************/
    // The value type of the DAT valuation should automatically set the overall vehicle value type.
    public updateOverallValueTypeForValuationReports() {
        if (this.report.type === 'valuation') {
            this.report.valuation.vehicleValueType = this.report.valuation.datValuation.vehicleValueType;
        }
    }
    /////////////////////////////////////////////////////////////////////////////*/
    //  END Value Type
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  VAT Handling
    //****************************************************************************/
    public selectVatHandling(vatHandling: DatValuation['requestedVatHandling']) {
        this.report.valuation.datValuation.requestedVatHandling = vatHandling;
        this.saveReport();
    }
    /////////////////////////////////////////////////////////////////////////////*/
    //  END VAT Handling
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  DAT Value / Assessor Value
    //****************************************************************************/
    /**
     * The assessor may overwrite the DAT replacement value (which is equal to the dealer sales price). If the user chooses to retrieve the assessor value with the present value or the dealer purchase
     * price, this function returns false and an error is shown in the user interface.
     */
    public isAssessorDefinedDatValueLogical(): boolean {
        return (
            this.report.valuation.datValuation.priceOrigin === 'dat' ||
            (this.report.valuation.datValuation.priceOrigin === 'assessor' &&
                new Array<DatVehicleValueType>('replacementValue', 'dealerSales', 'marketValue').includes(
                    this.report.valuation.datValuation.vehicleValueType,
                ))
        );
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END DAT Value / Assessor Value
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Documents
    //****************************************************************************/
    public toggleDocument(property: keyof DatValuation['includedDocuments'], event: MouseEvent) {
        // Only proceed if the container itself has been clicked
        if (event.target !== event.currentTarget) {
            return;
        }

        this.report.valuation.datValuation.includedDocuments[property] =
            !this.report.valuation.datValuation.includedDocuments[property];

        this.rememberIncludedDocuments();
        this.saveReport();
    }

    /**
     * On deactivation of the protocol slide toggle, deactivate all other documents too.
     * @param newValue
     */
    public deactivateAllDocuments(newValue: boolean) {
        if (newValue === true) {
            return;
        }

        // Protocol has been deactivated -> deactivate all other documents
        for (const documentKey of Object.keys(this.report.valuation.datValuation.includedDocuments)) {
            this.report.valuation.datValuation.includedDocuments[documentKey] = false;
        }
    }

    public activateProtocol(): void {
        this.report.valuation.datValuation.includedDocuments.protocol = true;
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Documents
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Close Dialog
    //****************************************************************************/
    private emitChangeEvent(): void {
        // Only emit change event if the user has actually changed something.
        if (JSON.stringify(this.report.valuation.datValuation) !== JSON.stringify(this.initialValuationSettings)) {
            this.dialog.close(this.report.valuation.datValuation);
        } else {
            this.dialog.close();
        }
    }

    public handleOverlayClick(event: MouseEvent) {
        if (event.target === event.currentTarget) {
            this.closeEditor();
        }
    }

    public closeEditor(): void {
        this.emitChangeEvent();
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Close Dialog
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  User Preferences
    //****************************************************************************/

    public rememberSettings(): void {
        this.rememberIncludedDocuments();
        this.rememberVehicleValueType();
        this.rememberVatHandling();
        this.rememberVatDisplayType();
        this.rememberPriceOrigin();

        this.userPreferencesUpdatedIconShown = true;
        window.setTimeout(() => {
            this.userPreferencesUpdatedIconShown = false;
        }, 1000);
    }

    public rememberIncludedDocuments(): void {
        this.userPreferences.datValuationIncludedDocuments = Object.assign(
            {},
            this.report.valuation.datValuation.includedDocuments,
        );
    }

    public rememberVehicleValueType(): void {
        this.userPreferences.datValuationVehicleValueType = this.report.valuation.datValuation.vehicleValueType;
    }

    public rememberVatHandling(): void {
        this.userPreferences.datValuationVatIncluded = this.report.valuation.datValuation.requestedVatHandling;
    }

    public rememberVatDisplayType(): void {
        this.userPreferences.datValuationVatDisplayType = this.report.valuation.datValuation.vatDisplayType;
    }

    public rememberPriceOrigin(): void {
        this.userPreferences.datValuationPriceOrigin = this.report.valuation.datValuation.priceOrigin;
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END User Preferences
    /////////////////////////////////////////////////////////////////////////////*/
    /**
     * Save reports to the server.
     */
    public saveReport({ waitForServer }: { waitForServer?: boolean } = {}): Promise<Report> {
        // Save the report because we manipulated values of the valuation object
        if (isReportLocked(this.report)) {
            return;
        }

        return this.reportDetailsService.patch(this.report, { waitForServer }).catch((error) => {
            this.toastService.error('Fehler beim Sync', 'Bitte versuche es später erneut');
            console.error('An error occurred while saving the report via the ReportService.', this.report, { error });
            throw error;
        });
    }

    @HostListener('window:keydown', ['$event'])
    public handleKeyboardShortcuts(event: KeyboardEvent) {
        switch (event.key) {
            case 'Escape':
                this.closeEditor();
                break;
            case 'Enter':
                if (event.ctrlKey || event.metaKey) {
                    this.closeEditor();
                    break;
                }
        }
    }
}

export interface DatValuationSettingsData {
    report: Report;
    datUser: DatUser;
}

/**
 * Pluralization rules.
 *
 * These rules are applied while processing the argument to `toCollectionName`.
 */

const pluralizationRules: [RegExp, string][] = [
    [/(m)an$/gi, '$1en'],
    [/(pe)rson$/gi, '$1ople'],
    [/(child)$/gi, '$1ren'],
    [/^(ox)$/gi, '$1en'],
    [/(ax|test)is$/gi, '$1es'],
    [/(octop|vir)us$/gi, '$1i'],
    [/(alias|status)$/gi, '$1es'],
    [/(bu)s$/gi, '$1ses'],
    [/(buffal|tomat|potat)o$/gi, '$1oes'],
    [/([ti])um$/gi, '$1a'],
    [/sis$/gi, 'ses'],
    [/(?:([^f])fe|([lr])f)$/gi, '$1$2ves'],
    [/(hive)$/gi, '$1s'],
    [/([^aeiouy]|qu)y$/gi, '$1ies'],
    [/(x|ch|ss|sh)$/gi, '$1es'],
    [/(matr|vert|ind)ix|ex$/gi, '$1ices'],
    [/([m|l])ouse$/gi, '$1ice'],
    [/(kn|w|l)ife$/gi, '$1ives'],
    [/(quiz)$/gi, '$1zes'],
    [/s$/gi, 's'],
    [/([^a-z])$/, '$1'],
    [/$/gi, 's'],
];

/**
 * Uncountable words.
 *
 * These words are applied while processing the argument to `toCollectionName`.
 * @api public
 */

const uncountables = [
    'advice',
    'cooperation',
    'deer',
    'digestion',
    'energy',
    'equipment',
    'excretion',
    'expertise',
    'fish',
    'health',
    'information',
    'justice',
    'labour',
    'machinery',
    'media',
    'money',
    'moose',
    'news',
    'paper',
    'pollution',
    'rain',
    'rice',
    'series',
    'sewage',
    'sheep',
    'species',
    'status',
];

export function pluralize(input: string) {
    if (uncountables.includes(input)) {
        return input;
    }
    const relevantRule: [RegExp, string] = pluralizationRules.find(function (rule) {
        return input.match(rule[0]);
    });
    if (relevantRule) {
        return input.replace(relevantRule[0], relevantRule[1]);
    }
    return input;
}

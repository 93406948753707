import {
    CheckboxElement,
    SignablePdfTemplateConfig,
} from '@autoixpert/models/signable-documents/signable-pdf-template-config';

export function getAllCheckboxElementsFromSignablePdfTemplateConfig(
    signablePdfTemplateConfig: SignablePdfTemplateConfig,
): CheckboxElement[] {
    return signablePdfTemplateConfig.pages.reduce((allCheckboxes, page) => {
        allCheckboxes.push(...page.checkboxElements);
        return allCheckboxes;
    }, []);
}

export function getRentalCarClasses(): RentalCarClassData[] {
    /**
     * Rental cars are classified in two formats:
     * - International: The ACRISS-Code (also SIPP-Code or CRS-Code) contains 4 characters for
     *                  - category
     *                  - auto body (2 or 4 doors)
     *                  - transmission (automatic vs. manual)
     *                  - air conditioning yes/no or fuel type
     *                  The relevant identifier for autoiXpert is the first letter: the category. This is used by Audatex.
     * - DAT: DAT has a number mapped to each rental car class.
     *
     * Source: https://www.billiger-mietwagen.de/faq/mietwagen-klassen.html > "Übersicht über die Codes: Mietwagenklassen-Tabelle"
     */
    const rentalCarClasses: RentalCarClassData[] = [
        {
            id: 1,
            acrissCodes: ['M', 'N'],
            description: 'Mini',
        },
        {
            id: 2,
            acrissCodes: ['E'],
            description: 'Kleinwagen',
        },
        {
            id: 3,
            acrissCodes: ['C'],
            description: 'Kompakt',
        },
        {
            id: 4,
            acrissCodes: ['D'],
            description: 'Kompakt +',
        },
        {
            id: 5,
            acrissCodes: ['I', 'S'],
            description: 'Mittelklasse',
        },
        {
            id: 6,
            acrissCodes: ['J', 'R'],
            description: 'Mittelklasse +',
        },
        {
            id: 7,
            acrissCodes: ['F'],
            description: 'Obere Mittelklasse',
        },
        {
            id: 8,
            acrissCodes: ['G'],
            description: 'Obere Mittelklasse +',
        },
        {
            id: 9,
            acrissCodes: ['L', 'W', 'H'],
            description: 'Luxusklasse',
        },
        {
            id: 10,
            acrissCodes: ['P'],
            description: 'Premium',
        },
        {
            id: 11,
            acrissCodes: ['U'],
            description: 'Premium +',
        },
    ];

    // Transporter classes.
    // See https://www.dat.de/fileadmin/media/sphinx/rentalPrices_help/DE/SilverDAT_Mietspiegel_Bedienungsanleitung.pdf for details.
    for (let i = 21; i <= 31; i++) {
        rentalCarClasses.push({
            id: i,
            acrissCodes: ['K'],
            description: 'Transporter',
        });
    }

    rentalCarClasses.push(
        // Motorcycles
        {
            id: 40,
            description: 'Roller',
        },
        {
            id: 41,
            acrissCodes: ['Y'],
            description: 'Motorrad',
        },
        {
            id: 90,
            description: 'Taxi',
        },
        {
            id: 91,
            description: 'LKW',
        },
        {
            id: 92,
            description: 'Sattelzugmaschine',
        },
        {
            id: 93,
            description: 'Sattelauflieger',
        },
        {
            id: 99,
            description: 'Sonderfahrzeug',
        },
    );

    return rentalCarClasses;
}

export interface RentalCarClassData {
    // The DAT identifier.
    id: number;
    // The international/Audatex identifier. Not very detailed for transporters and motorcycles.
    acrissCodes?: string[];
    description: string;
}

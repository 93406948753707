import { RequireSome } from '../../helper-types/require-some';
import { generateId } from '../../lib/generate-id';
import { CustomField } from './custom-field';
import { CustomFieldLocation } from './custom-field-location';

export class CustomFieldGroup {
    constructor(template: RequireSome<CustomFieldGroup, 'fieldLocation'>) {
        Object.assign(this, template);
    }

    _id: string = generateId();
    fieldLocation: CustomFieldLocation;
    customFields: CustomField[] = [];
}

import { BadRequest } from '@autoixpert/models/errors/ax-error';
import { Car } from '@autoixpert/models/reports/car-identification/car';
import { DamageCalculation } from '@autoixpert/models/reports/damage-calculation/damage-calculation';

export function getCalculationProviderName(
    calculationProvider: DamageCalculation['repair']['calculationProvider'],
): string {
    if (!calculationProvider) {
        return calculationProvider;
    }

    switch (calculationProvider) {
        case 'dat':
            return 'DAT';
        case 'audatex':
        case 'audatex-textimport':
            return 'Audatex';
        case 'gtmotive':
            return 'GT Motive';
        case 'estimate':
            return 'Schätzung';
        case 'manual':
            return 'Manuelle Kalkulation';
        case 'other':
            return 'Andere';
        default:
            throw new BadRequest({
                code: 'INVALID_CALCULATION_PROVIDER',
                message: `The calculation provider identifier could not be mapped to a name.`,
                data: {
                    calculationProvider,
                },
            });
    }
}

export function getIdentificationProviderName(identificationProvider: Car['identificationProvider']): string {
    if (!identificationProvider) {
        return identificationProvider;
    }

    switch (identificationProvider) {
        case 'audatex':
            return 'Audatex';
        case 'dat':
            return 'DAT';
        case 'gtmotive':
            return 'GT Motive';
        default:
            throw new BadRequest({
                code: 'INVALID_IDENTIFICATION_PROVIDER',
                message: `The identification provider identifier could not be mapped to a name.`,
                data: {
                    identificationProvider,
                },
            });
    }
}

import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { removeFromArray } from '@autoixpert/lib/arrays/remove-from-array';
import { simpleHash } from '@autoixpert/lib/simple-hash';
import { translateDocumentOrderConfigName } from '@autoixpert/lib/translators/translate-document-order-config-name';
import { translateDocumentType } from '@autoixpert/lib/translators/translate-document-type';
import { DocumentLayoutConfig, DocumentLayoutGroup } from '@autoixpert/models/documents/document-layout-group';
import { DocumentOrderConfig } from '@autoixpert/models/documents/document-order-config';
import { blobToBase64 } from '../../../../shared/libraries/blob-to-base64';
import { getHeaderAndFooterTemplateId } from '../../../../shared/libraries/template-engine/get-header-and-footer-template-id';
import { ApiErrorService } from '../../../../shared/services/api-error.service';
import { DocumentTemplateService } from '../../../../shared/services/document-template.service';
import { DownloadService } from '../../../../shared/services/download.service';
import { HeaderAndFooterTemplateService } from '../../../../shared/services/header-and-footer-template.service';
import { ToastService } from '../../../../shared/services/toast.service';
import { TutorialStateService } from '../../../../shared/services/tutorial-state.service';
import { DocumentOrderConfigAssociationChange } from '../../../document-types-dialog/document-types-dialog.component';

@Component({
    selector: 'document-layout-config',
    templateUrl: './document-layout-config.component.html',
    styleUrls: ['./document-layout-config.component.scss'],
})
export class DocumentLayoutConfigComponent implements OnInit {
    constructor(
        private toastService: ToastService,
        private headerAndFooterTemplateService: HeaderAndFooterTemplateService,
        private downloadService: DownloadService,
        private apiErrorService: ApiErrorService,
        private tutorialStateService: TutorialStateService,
        private documentTemplateService: DocumentTemplateService,
    ) {}

    @Input() documentLayoutConfig: DocumentLayoutConfig;
    @Input() documentLayoutGroupId: DocumentLayoutGroup['_id'];
    @Input() disabled: boolean = false;

    // The document order configs are required to associate a layout config with a specific document type.
    @Input() documentOrderConfigs: DocumentOrderConfig[];

    @Output() change = new EventEmitter<DocumentLayoutConfig>();
    @Output() default = new EventEmitter<DocumentLayoutConfig>();
    @Output() delete = new EventEmitter<DocumentLayoutConfig>();

    @ViewChild('fileUpload') private fileUploadElement: ElementRef;

    public isHeaderAndFooterUploadPending: boolean = false;
    public isHeaderAndFooterDownloadPending: boolean = false;

    public isTitleEditModeActive: boolean = false;

    private headerAndFooterTemplateId: string;

    /**
     * If the dialog type dialog is open, no opacity styling must be applied to the
     * document layout config item because the dialog is a child of that item.
     * Otherwise, the dialog would blur if the user's mouse leaves the window.
     */
    public isDocumentTypeDialogOpen: boolean = false;

    ngOnInit() {
        this.headerAndFooterTemplateId = getHeaderAndFooterTemplateId({
            documentLayoutGroupId: this.documentLayoutGroupId,
            documentLayoutConfigId: this.documentLayoutConfig._id,
        });
    }

    //*****************************************************************************
    //  Title
    //****************************************************************************/
    public enterTitleEditMode(): void {
        this.isTitleEditModeActive = true;
    }

    public leaveTitleEditMode(): void {
        this.isTitleEditModeActive = false;
    }

    public leaveTitleEditModeOnEnter(event: KeyboardEvent): void {
        if (event.key === 'Enter') {
            this.leaveTitleEditMode();
        }
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Title
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Header & Footer File Upload
    //****************************************************************************/
    public openFileSelector() {
        this.fileUploadElement.nativeElement.click();
    }

    public async onChangeHeaderAndFooterUploadFile(eventTarget: EventTarget) {
        const fileInput: HTMLInputElement = eventTarget as any;
        const headerAndFooterUploadFile: File = fileInput.files[0];
        /**
         * Reset the file input so the input triggers a change event when the user selects the same file again
         * in case an error occurred.
         */
        fileInput.value = null;

        //*****************************************************************************
        //  File Validation
        //****************************************************************************/
        // If the mime type is not a DOCX, remove the file from the queue
        if (
            headerAndFooterUploadFile.type &&
            headerAndFooterUploadFile.type !== 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'
        ) {
            console.error('The given file is not a DOCX file.', headerAndFooterUploadFile);
            this.toastService.error('Bitte lade eine DOCX-Datei hoch', '');
            fileInput.value = null;
            return;
        }

        // Warn about file size
        if (headerAndFooterUploadFile.size > 4000 * 1024) {
            this.toastService.error(
                'Datei zu groß',
                'Bitte lade nur Vorlagen unter 4 MB hoch. Versuche dazu, Logos und andere Grafiken zu verkleinern oder als JPG abzuspeichern, das kleinere Dateigrößen als eine PNG bietet.\n\nManchmal hilft es auch, die Grafik einmal zu entfernen und frisch in Word einzufügen.',
            );
            fileInput.value = null;
        }
        /////////////////////////////////////////////////////////////////////////////*/
        //  END File Validation
        /////////////////////////////////////////////////////////////////////////////*/

        try {
            this.isHeaderAndFooterUploadPending = true;

            await this.headerAndFooterTemplateService.create(this.headerAndFooterTemplateId, headerAndFooterUploadFile);

            this.isHeaderAndFooterUploadPending = false;
            this.toastService.success('Hochladen der Kopf- & Fußzeile erfolgreich');
            this.tutorialStateService.markUserTutorialStepComplete('headerAndFooterTemplatesConfigured');
            this.tutorialStateService.markTeamSetupStepComplete('headerAndFooterTemplate');
        } catch (error) {
            this.isHeaderAndFooterUploadPending = false;

            this.apiErrorService.handleAndRethrow({
                axError: error,
                handlers: {
                    MISSING_DOCUMENT_BUILDING_BLOCK: (error) => ({
                        title: 'Textbaustein fehlt',
                        body: `Der Textbaustein mit der ID "${
                            error.data.documentBuildingBlockId
                        }" fehlt im Dokument "${translateDocumentType(
                            error.data.documentType,
                        )}".<br><br>Bitte kontaktiere die <a href="/Hilfe" target="_blank">Hotline</a>.`,
                    }),
                    DOCX_STYLE_NOT_FOUND: (error) => ({
                        title: 'DOCX-Stil nicht gefunden',
                        body: `Der DOCX-Stil "${error.data.docxStyleId}" konnte nicht in deiner Kopf- & Fußzeile gefunden werden, wird aber benötigt. Bitte stelle sicher, dass der Stil da ist.<br><br>Bitte kontaktiere die <a href="/Hilfe" target="_blank">Hotline</a>.`,
                    }),
                    POSITIONING_DOCX_LETTER_WINDOW_FAILED: (error) => ({
                        title: 'Brieffenster nicht positionierbar',
                        body: `Das Brieffenster in der Vorlage "${translateDocumentType(
                            error.data.documentType,
                        )}" kann nicht positioniert werden, sodass deine Kopf- & Fußzeile passt.<br><br>Bitte kontaktiere die <a href="/Hilfe" target="_blank">Hotline</a>.`,
                    }),
                    POSITIONING_DOCX_REPORT_TITLE_FAILED: (error) => ({
                        title: 'Titel auf Titelblatt nicht positionierbar',
                        body: `Der Titel auf dem Titelblatt der Vorlage "${translateDocumentType(
                            error.data.documentType,
                        )}" kann nicht positioniert werden, sodass deine Kopf- & Fußzeile passt.<br><br>Bitte kontaktiere die <a href="/Hilfe" target="_blank">Hotline</a>.`,
                    }),
                },
                defaultHandler: {
                    title: 'Hochladen der Kopf- & Fußzeile gescheitert',
                    body: 'Bitte kontaktiere die <a href="/Hilfe" target="_blank">Hotline</a>.',
                },
            });
        }

        this.documentLayoutConfig.headerAndFooterTemplateHash = simpleHash(
            await blobToBase64(headerAndFooterUploadFile),
        );
        this.emitChangeEvent();
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Header & Footer File Upload
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Download Header & Footer Template
    //****************************************************************************/
    public async downloadHeaderAndFooterTemplate(): Promise<void> {
        try {
            this.isHeaderAndFooterDownloadPending = true;

            const response = await this.headerAndFooterTemplateService.get(this.headerAndFooterTemplateId);
            this.downloadService.downloadBlobResponseWithHeaders(response);

            this.isHeaderAndFooterDownloadPending = false;
        } catch (error) {
            this.isHeaderAndFooterDownloadPending = false;

            console.error('Error downloading the DOCX buffer.', { error });
            this.apiErrorService.handleAndRethrow({
                axError: error,
                handlers: {},
                defaultHandler: {
                    title: 'Fehler beim Download',
                    body: 'Die Vorlage konnte nicht heruntergeladen werden.',
                },
            });
        }
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Download Header & Footer Template
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Change Associated Document Types
    //****************************************************************************/
    public async handleChangeInAssociatedDocumentTypes(documentTypeChanges: DocumentOrderConfigAssociationChange[]) {
        for (const documentTypeChange of documentTypeChanges) {
            /**
             * In case a header and footer template's association with a document type was removed,
             * the currently rendered template needs to be invalidated since a new template with
             * the newly associated header and footer template must be rendered.
             *
             * If no header and footer template is associated with this document type, the default
             * header and footer template is used.
             */
            if (documentTypeChange.change === 'removed') {
                removeFromArray(
                    documentTypeChange.documentOrderConfig._id,
                    this.documentLayoutConfig.associatedDocumentOrderConfigIds,
                );

                try {
                    await this.documentTemplateService.remove({
                        documentLayoutGroupId: this.documentLayoutGroupId,
                        documentOrderConfigId: documentTypeChange.documentOrderConfig._id,
                    });
                } catch (error) {
                    const documentOrderConfigName: string = translateDocumentOrderConfigName({
                        documentOrderConfig: documentTypeChange.documentOrderConfig,
                    });
                    this.apiErrorService.handleAndRethrow({
                        axError: error,
                        handlers: {},
                        defaultHandler: {
                            title: 'Dokumentvorlage nicht gelöscht',
                            body: `Die Dokumentvorlage "${documentOrderConfigName}", die mit dieser Kopf- & Fußzeile verknüpft war, konnte nicht gelöscht werden.<br><br>Bitte kontaktiere die <a href="/Hilfe" target="_blank">Hotline</a>.`,
                        },
                    });
                }
            } else if (documentTypeChange.change === 'added') {
                this.documentLayoutConfig.associatedDocumentOrderConfigIds.push(
                    documentTypeChange.documentOrderConfig._id,
                );
            }

            this.emitChangeEvent();
        }
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Change Associated Document Types
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Remove or Reset Header & Footer Templates
    //****************************************************************************/
    public async deleteDocumentLayoutConfig(): Promise<void> {
        if (this.documentLayoutConfig.isDefault) {
            this.toastService.error(
                'Standard nicht löschbar',
                'Die als Standard markierte Kopf- & Fußzeile kann nicht gelöscht werden.',
            );
            return;
        }

        await this.removeHeaderAndFooterTemplateFromServer();

        this.delete.emit(this.documentLayoutConfig);
    }

    /**
     * When removing the header and footer template we must also remove the rendered
     * document template which will be re-rendered the next time the user tries to print a document.
     */
    public async removeHeaderAndFooterTemplateFromServer(): Promise<void> {
        const deleteDocumentTemplatePromises = [];
        for (const documentOrderConfigId of this.documentLayoutConfig.associatedDocumentOrderConfigIds) {
            deleteDocumentTemplatePromises.push(
                this.documentTemplateService.remove({
                    documentLayoutGroupId: this.documentLayoutGroupId,
                    documentOrderConfigId: documentOrderConfigId,
                }),
            );
        }

        try {
            await Promise.all([
                ...deleteDocumentTemplatePromises,
                this.headerAndFooterTemplateService.remove(this.headerAndFooterTemplateId),
            ]);
        } catch (error) {
            this.apiErrorService.handleAndRethrow({
                axError: error,
                handlers: {},
                defaultHandler: {
                    title: 'Vorlage nicht gelöscht',
                    body: `Die Dokumentvorlage auf unseren Servern konnte nicht gelöscht werden. Bitte kontaktiere die <a href="/Hilfe" target="_blank">Hotline</a>.`,
                },
            });
        }
    }

    public async resetHeaderAndFooterTemplate(): Promise<void> {
        try {
            await this.headerAndFooterTemplateService.resetDefault(this.headerAndFooterTemplateId);

            // Update the hash so that cached documents get re-rendered (hash is taken from the demo account / initial header and footer template)
            this.documentLayoutConfig.headerAndFooterTemplateHash = 'initial-T0EryDXxWrIS';
            this.emitChangeEvent();

            this.toastService.success('Kopf- & Fußzeile zurückgesetzt');
        } catch (error) {
            this.toastService.error(
                'Zurücksetzen fehlgeschlagen',
                'Bitte versuche es erneut. Sollte das Problem wiederholt auftreten, melde dich bei der <a href="/Hilfe" target="_blank">Hotline</a>.',
            );
        }
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Remove or Reset Header & Footer Templates
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Style (Modern, Classic, Based on Header and Footer)
    //****************************************************************************/
    public setStyle(style: DocumentLayoutConfig['documentTemplateStyle']): void {
        this.documentLayoutConfig.documentTemplateStyle = style;
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Style (Modern, Classic, Based on Header and Footer)
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Default
    //****************************************************************************/
    public getSetAsDefaultTooltip(): string {
        const tooltip = this.documentLayoutConfig.isDefault ? '' : 'Als Standard setzen.\n\n';

        return `${tooltip}Die Standardvorlage gilt grundsätzlich für alle Dokumente, denen kein Briefpapier explizit zugewiesen wurde.`;
    }

    public setAsDefault() {
        this.documentLayoutConfig.isDefault = true;
        /**
         * Ensure that previously set associations between document order configs and this document layout config are
         * removed so that other document layout configs which have an association with those document order configs
         * can be chosen.
         */
        this.documentLayoutConfig.associatedDocumentOrderConfigIds = [];
        this.emitChangeEvent();

        // Allow the parent component to remove the default flags from all other layout configs.
        this.default.emit(this.documentLayoutConfig);
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Default
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Events
    //****************************************************************************/
    public emitChangeEvent(): void {
        this.change.emit(this.documentLayoutConfig);
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Events
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Handle Dialog Types Dialog
    //****************************************************************************/
    /**
     * In this component, the child components need to be styled with some opacity but the
     * child components also contain the document types dialog which should not receive a
     * lower opacity when leaving the browser window.
     */
    public onShowDocumentTypesDialog(): void {
        this.isDocumentTypeDialogOpen = true;
    }

    public onCloseDocumentTypesDialog() {
        this.isDocumentTypeDialogOpen = false;
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Handle Dialog Types Dialog
    /////////////////////////////////////////////////////////////////////////////*/
}

import { NgModule } from '@angular/core';
import { AnalyticsFilterButtonComponent } from '../shared/components/filter/analytics-filter-button/analytics-filter-button.component';
import { AnalyticsFilterComponent } from '../shared/components/filter/analytics-filter/analytics-filter.component';
import { AssessorsFeeAnalyticsService } from '../shared/services/analytics/assessors-fee-analytics.service';
import { AssociatedReportsService } from '../shared/services/analytics/associated-reports.service';
import { DamageTotalAnalyticService } from '../shared/services/analytics/damage-total-analytic.service';
import { IntermediaryAnalyticsService } from '../shared/services/analytics/intermediary-analytics.service';
import { InvoicesWithReportsService } from '../shared/services/analytics/invoices-with-reports.service';
import { ReportsAnalyticsService } from '../shared/services/analytics/reports-analytics.service';
import { RevenueAnalyticsService } from '../shared/services/analytics/revenue-analytics.service';
import { RevenueByInvoiceItemAnalyticsService } from '../shared/services/analytics/revenue-by-invoice-item-analytics.service';
import { ShortPaymentAnalyticsService } from '../shared/services/analytics/short-payment-analytics.service';
import { SharedModule } from '../shared/shared.module';
import { AnalyticsListComponent } from './analytics-list/analytics-list.component';
import { AnalyticsSettingsMenuComponent } from './analytics-settings-menu/analytics-settings-menu.component';
import { AnalyticsComponent } from './analytics.component';
import { analyticsRouting } from './analytics.routing';
import { AssessorsFeeComponent } from './assessors-fee/assessors-fee.component';
import { ExportAssociatedReportsComponent } from './components/export-associated-reports-records/export-associated-reports.component';
import { ExportInvoiceAnalyticsRecordsIconComponent } from './components/export-invoice-analytics-records.component/export-invoice-analytics-records-icon.component';
import { DamageTotalComponent } from './damage-total/damage-total.component';
import { IntermediariesComponent } from './intermediaries/intermediaries.component';
import { RevenueByReportTypesComponent } from './report-types/revenue-by-report-types.component';
import { RevenueByAssessorComponent } from './revenue-by-assessor/revenue-by-assessor.component';
import { RevenueByCarManufacturerComponent } from './revenue-by-car-manufacturer/revenue-by-car-manufacturer.component';
import { RevenueByCityComponent } from './revenue-by-city/revenue-by-city.component';
import { RevenueByCustomerComponent } from './revenue-by-customer/revenue-by-customer.component';
import { RevenueByGarageComponent } from './revenue-by-garage/revenue-by-garage.component';
import { RevenueByInsuranceComponent } from './revenue-by-insurance/revenue-by-insurance.component';
import { RevenueByInvoiceItemComponent } from './revenue-by-invoice-item/revenue-by-invoice-item.component';
import { RevenueByLawyerComponent } from './revenue-by-lawyer/revenue-by-lawyer.component';
import { RevenueByMonthComponent } from './revenue-by-month/revenue-by-month.component';
import { ShortPaymentAnalyticsNavigationBarComponent } from './short-payments/analytics-dimension-navigation-bar/analytics-dimension-navigation-bar/short-payment-analytics-navigation-bar.component';
import { ShortPaymentPayersComponent } from './short-payments/short-payment-payers/short-payment-payers.component';
import { ShortPaymentQuotasComponent } from './short-payments/short-payment-quotas/short-payment-quotas.component';
import { ShortPaymentReasonsComponent } from './short-payments/short-payment-reasons/short-payment-reasons.component';
import { ShortPaymentResolutionsComponent } from './short-payments/short-payment-resolutions/short-payment-resolutions.component';
import { ShortPaymentsStatusComponent } from './short-payments/short-payment-status/short-payments-status.component';

@NgModule({
    declarations: [
        AnalyticsComponent,
        AnalyticsListComponent,
        AssessorsFeeComponent,
        DamageTotalComponent,
        ExportAssociatedReportsComponent,
        ExportInvoiceAnalyticsRecordsIconComponent,
        IntermediariesComponent,
        RevenueByReportTypesComponent,
        RevenueByAssessorComponent,
        RevenueByCarManufacturerComponent,
        RevenueByCityComponent,
        RevenueByCustomerComponent,
        RevenueByGarageComponent,
        RevenueByInvoiceItemComponent,
        RevenueByLawyerComponent,
        RevenueByInsuranceComponent,
        RevenueByMonthComponent,
        ShortPaymentQuotasComponent,
        ShortPaymentPayersComponent,
        ShortPaymentReasonsComponent,
        ShortPaymentResolutionsComponent,
        ShortPaymentsStatusComponent,
        ShortPaymentAnalyticsNavigationBarComponent,
        AnalyticsSettingsMenuComponent,
    ],
    imports: [SharedModule, analyticsRouting],
    providers: [
        AssessorsFeeAnalyticsService,
        AssociatedReportsService,
        DamageTotalAnalyticService,
        IntermediaryAnalyticsService,
        InvoicesWithReportsService,
        ReportsAnalyticsService,
        RevenueAnalyticsService,
        RevenueByInvoiceItemAnalyticsService,
        ShortPaymentAnalyticsService,
    ],
})
export class AnalyticsModule {}

import { DateTime } from 'luxon';

export function getLatestTimestamp(records: { createdAt: string }[], timestamp: 'createdAt'): DateTime;
export function getLatestTimestamp(records: { updatedAt: string }[], timestamp?: 'updatedAt'): DateTime;
export function getLatestTimestamp(
    records: { updatedAt?: string; createdAt?: string }[],
    timestamp: 'updatedAt' | 'createdAt' = 'updatedAt',
): DateTime {
    // Filter out records without an updatedAt timestamp.
    const recordsWithTimestamp = records.filter((record) => record[timestamp]);

    const timestamps: DateTime[] = recordsWithTimestamp.map((recordWithTimestamp) =>
        DateTime.fromISO(recordWithTimestamp[timestamp]),
    );

    // Sort by latest update.
    timestamps.sort((timestampA, timestampB) => {
        /**
         * DateTime.now() !== DateTime.now() but +DateTime.now() === +DateTime.now()
         */
        if (+timestampA === +timestampB) return 0;

        return timestampA < timestampB ? -1 : 1;
    });

    // Save timestamp of most recently updated/created record.
    return timestamps[timestamps.length - 1];
}

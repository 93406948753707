<mat-form-field class="mat-block time-input-container" [matTooltip]="inputType === 'text' ? 'Kürzel: j = jetzt' : ''">
    <input
        #timeInput
        matInput
        [type]="inputType"
        [placeholder]="placeholder"
        [ngModel]="formattedTimeForChild"
        (change)="parseInputValueFromTemplate($any($event.target).value)"
        [disabled]="disabled" />
</mat-form-field>

import { DocumentType } from '@autoixpert/models/documents/document-metadata';
import { Report } from '@autoixpert/models/reports/report';
import { SignatureElement } from '@autoixpert/models/signable-documents/signable-pdf-template-config';

/**
 * When loading signatures for signable PDF documents, use the file name (key on S3) generated by this method.
 */
export function getClaimantSignatureFileName({
    reportId,
    documentType,
    customDocumentOrderConfigId,
    signatureElementId,
}: {
    reportId: Report['_id'];
    documentType: DocumentType;
    customDocumentOrderConfigId?: string;
    // The signature slot on the PDF to which the signature belongs.
    signatureElementId: SignatureElement['_id'] | undefined;
}): string {
    return [reportId, documentType, customDocumentOrderConfigId, signatureElementId].filter(Boolean).join('-');
}

<div class="associated-invoice" (dblclick)="showInvoice($event, invoice)" *ngIf="invoice">
    <payment-status
        class="cursor-pointer"
        matTooltip="Rechnung öffnen"
        [invertedColors]="true"
        (click)="showInvoice($event, invoice)"
        (mousedown)="openInvoiceInNewTabOnMiddleClick($event)"
        [invoice]="invoice"></payment-status>
    <a
        class="number-and-date-container text-overflow-ellipsis"
        (click)="showInvoice($event, invoice)"
        (mousedown)="openInvoiceInNewTabOnMiddleClick($event)">
        <div class="number-container text-overflow-ellipsis" matTooltip="Rechnungsnummer: {{ invoice.number }}">
            {{ invoice.number }}
        </div>
        <div class="date-container label">{{ invoice.date | date }}</div>
    </a>
    <div class="total-net-container">
        <div
            matTooltip="Brutto. Netto: {{ invoice.totalNet | number: '1.2-2' }} €"
            [ngClass]="{ canceled: getPaymentStatus(invoice) === 'fullyCanceled' }">
            {{ invoice.totalGross | number: '1.2-2' }} €
        </div>
        <span
            *ngIf="getUnpaidAmount(invoice) > 0 && getUnpaidAmount(invoice) !== invoice.totalGross"
            class="unpaid-amount"
            matTooltip="Offener Betrag">
            {{ getUnpaidAmount(invoice) | number: '1.2-2' }} €
        </span>
    </div>
    <img
        class="associated-invoice-download-pending-icon"
        *ngIf="invoicePdfDownloading"
        src="/assets/images/icons/loading-indicator-white.svg"
        matTooltip="Rechnungs-PDF wird generiert..." />
    <mat-icon class="toolbar-icon" *ngIf="!invoicePdfDownloading" [matMenuTriggerFor]="invoiceSubmenu">
        more_vert
    </mat-icon>
    <mat-menu #invoiceSubmenu="matMenu">
        <a
            mat-menu-item
            class="menu-item-with-icon"
            (mousedown)="openInvoiceInNewTabOnMiddleClick($event)"
            (click)="showInvoice($event, invoice)">
            <mat-icon>visibility</mat-icon>
            Anzeigen
        </a>
        <a mat-menu-item class="menu-item-with-icon" (click)="downloadInvoice(invoice)">
            <mat-icon>file_download</mat-icon>
            Herunterladen
        </a>
    </mat-menu>
</div>

import { OfficialFeeTable } from '@autoixpert/models/reports/assessors-fee/fee-calculation';
import { round } from '../../lib/numbers/round';

// Array shape: [damageValue, ...[Fee groups 1-5]]
export const bvskFeeTable2022: OfficialFeeTable = {
    year: 2022,
    table: [
        [500, 131, 166, 282, 276, 230, 282],
        [750, 163, 197, 315, 309, 267, 315],
        [1_000, 202, 242, 370, 364, 312, 370],
        [1_250, 296, 322, 410, 404, 365, 410],
        [1_500, 330, 355, 445, 439, 396, 445],
        [1_750, 354, 380, 476, 469, 424, 476],
        [2_000, 380, 408, 503, 496, 450, 503],
        [2_250, 407, 433, 528, 521, 473, 528],
        [2_500, 429, 456, 554, 546, 497, 554],
        [2_750, 453, 475, 579, 572, 519, 579],
        [3_000, 475, 495, 601, 594, 540, 601],
        [3_250, 491, 514, 624, 616, 560, 624],
        [3_500, 509, 537, 647, 639, 583, 647],
        [3_750, 528, 558, 670, 662, 603, 670],
        [4_000, 546, 577, 693, 685, 623, 693],
        [4_250, 562, 594, 713, 704, 642, 713],
        [4_500, 579, 611, 735, 726, 662, 735],
        [4_750, 594, 628, 753, 743, 678, 753],
        [5_000, 607, 642, 772, 762, 695, 772],
        [5_250, 625, 659, 790, 781, 713, 790],
        [5_500, 650, 680, 810, 800, 732, 810],
        [5_750, 666, 697, 827, 817, 748, 827],
        [6_000, 682, 712, 848, 838, 766, 848],
        [6_500, 705, 736, 875, 865, 792, 875],
        [7_000, 727, 760, 904, 893, 818, 904],
        [7_500, 754, 787, 931, 920, 844, 931],
        [8_000, 780, 816, 965, 953, 872, 965],
        [8_500, 807, 844, 997, 985, 902, 997],
        [9_000, 834, 872, 1_027, 1_015, 934, 1_027],
        [9_500, 860, 900, 1_057, 1_044, 965, 1_057],
        [10_000, 887, 928, 1_087, 1_074, 992, 1_087],
        [10_500, 915, 958, 1_123, 1_110, 1_022, 1_123],
        [11_000, 939, 982, 1_148, 1_135, 1_052, 1_148],
        [11_500, 966, 1_010, 1_188, 1_174, 1_081, 1_188],
        [12_000, 994, 1_036, 1_217, 1_203, 1_108, 1_217],
        [12_500, 1_022, 1_065, 1_248, 1_234, 1_137, 1_248],
        [13_000, 1_049, 1_093, 1_282, 1_269, 1_169, 1_282],
        [13_500, 1_078, 1_121, 1_314, 1_301, 1_198, 1_314],
        [14_000, 1_101, 1_147, 1_343, 1_330, 1_224, 1_343],
        [14_500, 1_128, 1_175, 1_377, 1_364, 1_256, 1_377],
        [15_000, 1_156, 1_204, 1_412, 1_398, 1_288, 1_412],
        [16_000, 1_193, 1_249, 1_470, 1_454, 1_335, 1_470],
        [17_000, 1_230, 1_292, 1_528, 1_510, 1_386, 1_528],
        [18_000, 1_274, 1_335, 1_582, 1_562, 1_434, 1_582],
        [19_000, 1_326, 1_384, 1_644, 1_622, 1_486, 1_644],
        [20_000, 1_374, 1_430, 1_711, 1_681, 1_535, 1_711],
        [21_000, 1_425, 1_483, 1_775, 1_745, 1_597, 1_775],
        [22_000, 1_473, 1_537, 1_828, 1_799, 1_647, 1_828],
        [23_000, 1_523, 1_585, 1_885, 1_856, 1_698, 1_885],
        [24_000, 1_561, 1_630, 1_947, 1_914, 1_746, 1_947],
        [25_000, 1_611, 1_686, 1_974, 1_951, 1_804, 1_974],
        [26_000, 1_655, 1_732, 2_029, 2_006, 1_855, 2_029],
        [27_000, 1_716, 1_787, 2_085, 2_061, 1_906, 2_085],
        [28_000, 1_759, 1_834, 2_153, 2_128, 1_958, 2_153],
        [29_000, 1_798, 1_878, 2_207, 2_180, 2_009, 2_207],
        [30_000, 1_852, 1_938, 2_278, 2_252, 2_075, 2_278],
        [32_500, 1_954, 2_042, 2_421, 2_389, 2_196, 2_421],
        [35_000, 2_038, 2_141, 2_571, 2_538, 2_326, 2_571],
        [37_500, 2_117, 2_232, 2_729, 2_692, 2_427, 2_729],
        [40_000, 2_200, 2_336, 2_879, 2_840, 2_559, 2_879],
        [42_500, 2_368, 2_508, 3_069, 3_018, 2_740, 3_069],
        [45_000, 2_459, 2_621, 3_266, 3_208, 2_891, 3_266],
        [47_500, 2_562, 2_733, 3_433, 3_369, 3_013, 3_433],
        [50_000, 2_640, 2_820, 3_595, 3_523, 3_123, 3_595],
    ],
};

/**
 * Programmatically calculate the arithmetic mean of the HB5 corridor and append it to the fee table.
 * Array shape: [damageValue, ...[Fee groups 1-5], Mean of HB5 corridor]
 */
export const bvskFeeTable2022IncludingHB5Mean = bvskFeeTable2022.table.map((row) => {
    const hb5From = row.at(-2);
    const hb5To = row.at(-1);
    // Calculate the arithmetic mean within HB5 corridor. I guess this is not mathematically correct, but
    // should be sufficient for choosing a fee that lies in the middle of the HB5 corridor.
    row[row.length] = round(hb5From + (hb5To - hb5From) / 2);

    return row;
});

export const bvskAdditionalCosts = {
    axleMeasurement: 107,
    carBodyMeasurement: 219,
    errorLogReading: 54,
};

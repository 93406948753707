import { Component, EventEmitter, HostListener, Input, Output } from '@angular/core';
import { ResidualValueOffer } from '@autoixpert/models/reports/residual-value/residual-value-offer';
import { WinvalueUser } from '@autoixpert/models/user/user';

@Component({
    selector: 'winvalue-offer-connection-dialog',
    templateUrl: './winvalue-offer-connection-dialog.component.html',
    styleUrls: ['./winvalue-offer-connection-dialog.component.scss'],
})
export class WinvalueOfferConnectionDialogComponent {
    public winvalueOfferId: number;
    public offerReadyAt: string;

    @Input() winvalueUser: WinvalueUser;

    @Output() close: EventEmitter<void> = new EventEmitter();
    @Output() connectedOffer: EventEmitter<ResidualValueOffer> = new EventEmitter();

    public getSubmitButtonTooltip(): string {
        if (!this.winvalueOfferId) {
            return 'Bitte die WinValue Objekt-Nummer eingeben.';
        }
        if (!this.offerReadyAt) {
            return 'Bitte die Gebotsfrist eingeben.';
        }
        return '';
    }

    //*****************************************************************************
    //  Event Emitters
    //****************************************************************************/

    public closeDialog(): void {
        this.close.emit();
    }

    public emitObjectNumber() {
        // Our own Winvalue user can use their demo environment.
        const basePath: string =
            this.winvalueUser.customerNumber === '809155' ? 'stage.winvalue.de/wvrwb' : 'rb.winvalue.de';

        const winvalueOffer = new ResidualValueOffer();
        winvalueOffer.offerId = `${this.winvalueOfferId}`;
        winvalueOffer.readyAt = this.offerReadyAt;
        winvalueOffer.link = `https://${basePath}/rwb/offers/${this.winvalueOfferId}`;
        this.connectedOffer.emit(winvalueOffer);
        this.closeDialog();
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Event Emitters
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Close Dialog
    //****************************************************************************/
    public handleOverlayClick(event: MouseEvent): void {
        // Only close editor if the overlay has been clicked directly. Ignore bubbling events from the dialog.
        if (event.target === event.currentTarget) {
            this.closeDialog();
        }
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Close Dialog
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Window Event Listeners
    //****************************************************************************/
    @HostListener('window:keydown', ['$event'])
    public handleKeyboardShortcuts(event: KeyboardEvent): void {
        switch (event.key) {
            case 'Escape':
                this.closeDialog();
        }
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Window Event Listeners
    /////////////////////////////////////////////////////////////////////////////*/
}

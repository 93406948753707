import { Component, EventEmitter, Input, Output } from '@angular/core';
import { TaxationType } from '@autoixpert/models/reports/market-value/valuation';
import { getVatRateForTaxationType } from '@autoixpert/static-data/taxation-rates';

@Component({
    selector: 'net-and-gross-currency-input',
    templateUrl: './net-and-gross-currency-input.component.html',
    styleUrls: ['./net-and-gross-currency-input.component.scss'],
})
export class NetAndGrossCurrencyInputComponent {
    @Input() taxationType: TaxationType = 'full';

    @Input() lightMode: boolean = false;
    @Input() label: string = '';
    @Input() netValue: number;
    @Input() grossValue: number;
    @Output() netValueChange: EventEmitter<number> = new EventEmitter<number>();
    @Output() grossValueChange: EventEmitter<number> = new EventEmitter<number>();

    // UI states
    public amountConversionDirection: 'netToGross' | 'grossToNet';
    public netAndGrossLinked = true;

    /**
     * If gross and net are linked, the gross value is updated.
     */
    public handleChangeOnNetValue() {
        if (this.netAndGrossLinked) {
            this.grossValue = this.calculateGrossValue({ displayAnimation: true });
            this.grossValueChange.emit(this.grossValue);
        }

        this.netValueChange.emit(this.netValue);
    }
    /**
     * If neutral taxation is selected, the gross value is the same as the net value.
     * If gross and net are linked, the net value is updated.
     */
    public handleChangeOnGrossValue() {
        if (this.taxationType === 'neutral') {
            this.netValue = this.grossValue;
            this.netValueChange.emit(this.netValue);
        } else if (this.netAndGrossLinked) {
            this.netValue = this.calculateNetValue({ displayAnimation: true });
            this.netValueChange.emit(this.netValue);
        }

        this.grossValueChange.emit(this.grossValue);
    }

    public calculateGrossValue({ displayAnimation }: { displayAnimation: boolean } = { displayAnimation: false }) {
        // Null check necessary because multiplying the tax factor further down causes a type coercion from null to zero.
        if (this.netValue === null) {
            return null;
        }

        if (displayAnimation) {
            this.amountConversionDirection = 'netToGross';
            setTimeout(() => (this.amountConversionDirection = null), 800);
        }

        const taxFactor: number = 1 + getVatRateForTaxationType(this.taxationType);
        return this.netValue * taxFactor;
    }

    public calculateNetValue({ displayAnimation }: { displayAnimation: boolean } = { displayAnimation: false }) {
        // Null check necessary because multiplying the tax factor further down causes a type coercion from null to zero.
        if (this.grossValue === null) {
            return null;
        }

        if (displayAnimation) {
            this.amountConversionDirection = 'grossToNet';
            setTimeout(() => (this.amountConversionDirection = null), 800);
        }

        const taxFactor: number = 1 + getVatRateForTaxationType(this.taxationType);
        return this.grossValue / taxFactor;
    }
}

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { SwUpdate } from '@angular/service-worker';
import { AxError } from '@autoixpert/models/errors/ax-error';
import { SignablePdfTemplateConfig } from '@autoixpert/models/signable-documents/signable-pdf-template-config';
import { environment } from '../../../environments/environment';
import { FullSyncResult, OfflineSyncServiceBase } from '../libraries/database/offline-sync-service.base';
import { FrontendLogService } from './frontend-log.service';
import { NetworkStatusService } from './network-status.service';
import { SignablePdfTemplateImageService } from './signable-pdf-template-image.service';
import { SyncIssueNotificationService } from './sync-issue-notification.service';

@Injectable()
export class SignablePdfTemplateConfigService extends OfflineSyncServiceBase<SignablePdfTemplateConfig> {
    constructor(
        protected httpClient: HttpClient,
        protected networkStatusService: NetworkStatusService,
        protected syncIssueNotificationService: SyncIssueNotificationService,
        protected serviceWorker: SwUpdate,
        protected signablePdfTemplateImageService: SignablePdfTemplateImageService,
        protected frontendLogService: FrontendLogService,
    ) {
        super({
            serviceName: 'signablePdfTemplateConfig',
            httpClient,
            networkStatusService,
            syncIssueNotificationService,
            serviceWorker,
            frontendLogService,
            objectStoreAndIndexMigrations: undefined,
            recordMigrations: [],
        });
    }

    /**
     * Sync all config data and all image files which the assessor needs to have a claimant sign a PDF-based customer signature document.
     */
    public async fullSync(batchSize: number = this.batchSize): Promise<FullSyncResult> {
        const fullSyncResult: FullSyncResult = await super.fullSync(batchSize);

        const signablePdfTemplateConfigs: SignablePdfTemplateConfig[] = await this.localDb.findLocal();
        for (const signablePdfTemplateConfig of signablePdfTemplateConfigs) {
            for (let pageNumber = 1; pageNumber <= signablePdfTemplateConfig.pages.length; pageNumber++) {
                const imageForPageExists: boolean = await this.signablePdfTemplateImageService.localDb.existsLocal(
                    signablePdfTemplateConfig._id,
                );
                if (!imageForPageExists) {
                    const recordId = `${signablePdfTemplateConfig._id}-page-${pageNumber}`;
                    try {
                        /**
                         * Since this request is only started if the page does not exist locally, there cannot be a hash for the image page.
                         *
                         * Use await instead of loading all images in parallel to prevent high CPU usage on the client. That's a strategy that we use for all background syncs.
                         */
                        await this.signablePdfTemplateImageService.get(recordId, undefined);
                    } catch (error) {
                        // Show a notification to the user that there were sync issues so that the user may resolve them or contact the autoiXpert hotline.
                        // Only display on production. Development often doesn't have all binary files so this spams the error panel.
                        if (environment.production) {
                            this.syncIssueNotificationService.create({
                                heading: 'Download fehlgeschlagen',
                                reason: 'Die PDF-Seite für das Kundenunterschriftsdokument konnte nicht heruntergeladen werden.',
                                databaseService: this.signablePdfTemplateImageService,
                                recordId,
                                error: new AxError({
                                    code: 'DOWNLOADING_SIGNABLE_PDF_PAGE_FOR_OFFLINE_USE_FAILED',
                                    message:
                                        'Signable PDF documents must be downloaded to this client for offline use. This download failed. Have a look at the causedBy property.',
                                    data: {
                                        signablePdfTemplateConfigId: signablePdfTemplateConfig._id,
                                        pageNumber,
                                    },
                                    error,
                                }),
                            });
                        }
                    }
                }
            }
        }

        return fullSyncResult;
    }
}

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { apiBasePath } from '@autoixpert/external-apis/api-base-path';
import { BankAccountForSync } from '@autoixpert/models/bank-account-sync/bank-account-for-sync';

@Injectable()
export class GocardlessBankAccountConnectionService {
    constructor(private httpClient: HttpClient) {}

    public get({ requisitionId }: { requisitionId: string }): Observable<BankAccountConnectionGetResponse> {
        return this.httpClient.get<BankAccountConnectionGetResponse>(
            `${apiBasePath}/bankAccountSync/gocardlessBankAccountConnection/${requisitionId}`,
        );
    }

    public create({
        financialInstitutionId,
    }: {
        financialInstitutionId: string;
    }): Observable<BankAccountConnectionCreateResponse> {
        return this.httpClient.post<BankAccountConnectionCreateResponse>(
            `${apiBasePath}/bankAccountSync/gocardlessBankAccountConnection`,
            {
                financialInstitutionId,
            },
        );
    }

    public patch({
        accountId,
        requisitionId,
    }: {
        accountId: string;
        requisitionId: string;
    }): Observable<BankAccountConnectionPatchResponse> {
        return this.httpClient.patch<BankAccountConnectionPatchResponse>(
            `${apiBasePath}/bankAccountSync/gocardlessBankAccountConnection`,
            {
                accountId,
                requisitionId,
            },
        );
    }
}

export interface BankAccountConnectionCreateResponse {
    link: string;
    requisitionId: string;
}

export interface BankAccountConnectionPatchResponse {
    requisitionId: string;
    bankAccount: BankAccountForSync;
}

export interface BankAccountConnectionGetResponse {
    id: string;
    status: string;
    accounts: GocardlessRequisitionBankAccount[];
}

export interface GocardlessRequisitionBankAccount {
    id: string;
    name: string;
    iban: string;
    bic: string;
    bankName: string;
    ownerName: string;
}

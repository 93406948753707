<section id="template-management-section">
    <!--============================================-->
    <!-- Head Runner -->
    <!--============================================-->
    <div id="template-management-head-runner" class="card">
        <h2 class="no-margin">Vorlagen bearbeiten</h2>
        <button (click)="emitCloseEvent()">
            <mat-icon class="button-icon">check</mat-icon>
            Schließen
        </button>
    </div>
    <!--============================================-->
    <!-- END Head Runner -->
    <!--============================================-->

    <!--============================================-->
    <!-- Template List & Item List -->
    <!--============================================-->
    <div id="template-list-and-item-list">
        <!--============================================-->
        <!-- Template List -->
        <!--============================================-->
        <div id="template-list-card" class="card dark-card">
            <div id="template-list-header">
                <h2 class="no-margin">Leitfäden</h2>
            </div>

            <div
                class="template-list-item"
                *ngFor="let leaseReturnTemplate of leaseReturnTemplates"
                [ngClass]="{ selected: selectedTemplate === leaseReturnTemplate }"
                (click)="selectTemplate(leaseReturnTemplate)">
                <div class="template-list-item-title text-overflow-ellipsis">
                    {{ leaseReturnTemplate.title }}
                </div>
                <mat-icon class="toolbar-icon" [matMenuTriggerFor]="templateListItemSubmenu">more_vert</mat-icon>
                <mat-menu #templateListItemSubmenu>
                    <a mat-menu-item class="menu-item-with-icon" (click)="copyLeaseReturnTemplate(leaseReturnTemplate)">
                        <mat-icon>content_copy</mat-icon>
                        Kopieren
                    </a>
                    <a
                        mat-menu-item
                        class="menu-item-with-icon"
                        (click)="deleteLeaseReturnTemplate(leaseReturnTemplate)">
                        <mat-icon>delete</mat-icon>
                        Löschen
                    </a>
                </mat-menu>
            </div>

            <div id="create-new-template-button" class="text-align-center">
                <button class="flat light" (click)="createNewLeaseReturnTemplate()">
                    <mat-icon class="button-icon">add</mat-icon>
                    Neuer Leitfaden
                </button>
            </div>
        </div>
        <!--============================================-->
        <!-- END Template List -->
        <!--============================================-->

        <!--============================================-->
        <!-- Template Sections & Items -->
        <!--============================================-->
        <div id="template-sections-and-items" *ngIf="selectedTemplate">
            <!--============================================-->
            <!-- Template Header -->
            <!--============================================-->
            <div id="template-item-list-header" class="card edit-title-icon-parent">
                <!--============================================-->
                <!-- Template Title -->
                <!--============================================-->
                <!--********** Display **********-->
                <div id="template-header-title-container" *ngIf="!templateTitleInEditMode">
                    <h2
                        class="no-margin text-overflow-ellipsis"
                        (dblclick)="enterTitleEditMode()"
                        matTooltip="Der Titel bleibt intern und wird nicht abgedruckt. Doppelklicken zum Bearbeiten">
                        {{ selectedTemplate.title }}
                    </h2>
                    <mat-icon
                        class="edit-title-icon small-icon toolbar-icon"
                        (click)="enterTitleEditMode()"
                        matTooltip="Titel bearbeiten">
                        edit
                    </mat-icon>
                </div>

                <!--********** Input **********-->
                <mat-form-field id="template-header-form-field" *ngIf="templateTitleInEditMode">
                    <input
                        matInput
                        placeholder="Titel Prüfleitfaden"
                        [(ngModel)]="selectedTemplate.title"
                        (change)="saveTemplate(); sortTemplates()"
                        (keydown)="leaveTitleEditModeOnEnter($event)"
                        [axAutofocus]="true" />
                    <mat-icon
                        matSuffix
                        class="input-toolbar-icon"
                        (click)="leaveTitleEditMode(); saveTemplate()"
                        matTooltip="Bearbeitung beenden">
                        check
                    </mat-icon>
                </mat-form-field>
                <!--============================================-->
                <!-- END Template Title -->
                <!--============================================-->

                <!--********** Toolbar **********-->
                <div class="horizontal-toolbar">
                    <!--********** New Section **********-->
                    <mat-icon
                        class="toolbar-icon"
                        (click)="createSectionInTemplate(); saveTemplate()"
                        matTooltip="Neuer Abschnitt">
                        add
                    </mat-icon>

                    <!--********** Collapse All Sections **********-->
                    <mat-icon
                        class="toolbar-icon"
                        *ngIf="!sectionsWithItemsCollapsed.size"
                        (click)="collapseAllSections()"
                        matTooltip="Alle Abschnitte zuklappen">
                        unfold_less
                    </mat-icon>

                    <!--********** Expand All Sections **********-->
                    <mat-icon
                        class="toolbar-icon"
                        *ngIf="sectionsWithItemsCollapsed.size > 0"
                        (click)="expandAllSections()"
                        matTooltip="Alle Abschnitte aufklappen">
                        unfold_more
                    </mat-icon>

                    <mat-icon class="toolbar-icon" [matMenuTriggerFor]="headRunnerSubmenu">more_vert</mat-icon>
                    <mat-menu #headRunnerSubmenu>
                        <a
                            mat-menu-item
                            class="menu-item-with-icon"
                            (click)="deleteLeaseReturnTemplate(selectedTemplate)">
                            <mat-icon>delete</mat-icon>
                            Prüfleitfaden löschen
                        </a>
                    </mat-menu>
                </div>
            </div>
            <!--============================================-->
            <!-- END Template Header -->
            <!--============================================-->

            <!--============================================-->
            <!-- Item List -->
            <!--============================================-->
            <div
                id="section-list"
                cdkDropList
                cdkDropListLockAxis="y"
                (cdkDropListDropped)="handleSectionDrop($event); saveTemplate()">
                <section class="lease-return-section card" *ngFor="let section of selectedTemplate.sections" cdkDrag>
                    <!--********** Section Header **********-->
                    <div
                        class="section-header edit-title-icon-parent"
                        (dblclick)="toggleSectionItemsExpandedOnDoubleClick(section, $event)">
                        <mat-icon class="drag-handle medium-icon" cdkDragHandle>drag_indicator</mat-icon>

                        <!--============================================-->
                        <!-- Section Title -->
                        <!--============================================-->
                        <div class="section-title-container" *ngIf="!sectionTitlesInEditMode.has(section)">
                            <h2
                                class="no-margin text-overflow-ellipsis"
                                (dblclick)="enterEditModeForTemplateSection(section)">
                                {{ section.title || 'Neuer Abschnitt' }}
                            </h2>
                            <mat-icon
                                class="edit-title-icon small-icon toolbar-icon"
                                (click)="enterEditModeForTemplateSection(section)"
                                matTooltip="Titel bearbeiten">
                                edit
                            </mat-icon>
                            <div
                                class="number-of-collapsed-items label"
                                *ngIf="sectionsWithItemsCollapsed.has(section) && section.items.length">
                                <span
                                    class="link"
                                    (click)="expandSection(section)"
                                    matTooltip="Klicken, um die Positionen einzublenden">
                                    {{ section.items.length }}
                                    {{ section.items.length === 1 ? 'Position' : 'Positionen' }}
                                </span>
                            </div>
                        </div>
                        <!--********** Input **********-->
                        <mat-form-field class="section-title-form-field" *ngIf="sectionTitlesInEditMode.has(section)">
                            <input
                                matInput
                                placeholder="Abschnitt"
                                [(ngModel)]="section.title"
                                (change)="saveTemplate()"
                                (keydown)="leaveTemplateSectionEditModeOnEnter($event, section)"
                                [axAutofocus]="true" />
                            <mat-icon
                                matSuffix
                                class="input-toolbar-icon"
                                (click)="leaveEditModeForTemplateSection(section); saveTemplate()"
                                matTooltip="Bearbeitung beenden">
                                check
                            </mat-icon>
                        </mat-form-field>
                        <!--============================================-->
                        <!-- END Section Title -->
                        <!--============================================-->
                        <div class="section-toolbar">
                            <mat-icon
                                class="toolbar-icon"
                                (click)="createItemInTemplate(section); saveTemplate()"
                                matTooltip="Neue Prüfposition">
                                add
                            </mat-icon>
                            <!--********** Submenu **********-->
                            <mat-icon class="toolbar-icon" [matMenuTriggerFor]="itemSubmenu">more_vert</mat-icon>
                            <mat-menu #itemSubmenu>
                                <a
                                    mat-menu-item
                                    class="menu-item-with-icon"
                                    *ngIf="!sectionsWithItemsCollapsed.has(section)"
                                    (click)="collapseSection(section)">
                                    <mat-icon>unfold_less</mat-icon>
                                    Abschnitt zuklappen
                                </a>
                                <a
                                    mat-menu-item
                                    class="menu-item-with-icon"
                                    *ngIf="sectionsWithItemsCollapsed.has(section)"
                                    (click)="expandSection(section)">
                                    <mat-icon>unfold_more</mat-icon>
                                    Abschnitt aufklappen
                                </a>
                                <a
                                    mat-menu-item
                                    class="menu-item-with-icon"
                                    (click)="duplicateSection(section); saveTemplate()">
                                    <mat-icon>content_copy</mat-icon>
                                    Duplizieren
                                </a>
                                <a
                                    mat-menu-item
                                    class="menu-item-with-icon"
                                    (click)="deleteSection(section); saveTemplate()">
                                    <mat-icon>delete</mat-icon>
                                    Abschnitt löschen
                                </a>
                            </mat-menu>
                        </div>
                    </div>

                    <!--********** Items **********-->
                    <div
                        class="lease-return-item-list"
                        cdkDropList
                        cdkDropListLockAxis="y"
                        (cdkDropListDropped)="handleItemDrop(section, $event); saveTemplate()"
                        *ngIf="!sectionsWithItemsCollapsed.has(section)">
                        <!--********** Custom Items **********-->
                        <div
                            class="lease-return-item edit-title-icon-parent record"
                            *ngFor="let item of section.items"
                            [ngClass]="{ active: selectedItem === item }"
                            (click)="selectItem(item)"
                            cdkDrag>
                            <mat-icon class="drag-handle medium-icon" cdkDragHandle>drag_indicator</mat-icon>
                            <div
                                class="item-title"
                                *ngIf="!itemsInEditMode.has(item)"
                                (dblclick)="enterEditModeForTemplateItem(item)">
                                <span>{{ item.title || NEW_ITEM_TITLE }}</span>
                                <mat-icon
                                    class="edit-title-icon small-icon toolbar-icon"
                                    (click)="enterEditModeForTemplateItem(item)"
                                    matTooltip="Titel bearbeiten">
                                    edit
                                </mat-icon>
                            </div>
                            <div class="item-title" *ngIf="itemsInEditMode.has(item)">
                                <mat-form-field class="mat-block" floatLabel="never">
                                    <input
                                        matInput
                                        placeholder="Titel"
                                        [(ngModel)]="item.title"
                                        (change)="saveTemplate()"
                                        (keydown)="leaveTemplateItemEditModeOnEnter($event, item)"
                                        [axAutofocus]="true" />
                                    <mat-icon
                                        matSuffix
                                        class="input-toolbar-icon"
                                        (click)="leaveEditModeForTemplateItem(item)"
                                        matTooltip="Bearbeitung beenden">
                                        check
                                    </mat-icon>
                                </mat-form-field>
                            </div>
                            <div class="item-status-and-toolbar">
                                <!--********** Required Item **********-->
                                <mat-icon
                                    class="required-position-icon small-icon toolbar-icon"
                                    [ngClass]="{ active: item.isRequired }"
                                    (click)="item.isRequired = !item.isRequired; saveTemplate()"
                                    matTooltip="Pflichtposition">
                                    star
                                </mat-icon>

                                <!--********** Template Editor **********-->
                                <mat-icon class="toolbar-icon" (click)="selectItem(item); showTextTemplatesDialog()">
                                    playlist_add
                                </mat-icon>

                                <!--********** Submenu **********-->
                                <mat-icon class="toolbar-icon" [matMenuTriggerFor]="itemSubmenu">more_vert</mat-icon>
                                <mat-menu #itemSubmenu>
                                    <!--********** Duplicate Item **********-->
                                    <a
                                        mat-menu-item
                                        class="menu-item-with-icon"
                                        (click)="duplicateItem(item, section); saveTemplate()">
                                        <mat-icon>content_copy</mat-icon>
                                        Duplizieren
                                    </a>

                                    <!--********** Delete Item **********-->
                                    <a
                                        mat-menu-item
                                        class="menu-item-with-icon"
                                        (click)="deleteItem(item, section); saveTemplate()">
                                        <mat-icon>delete</mat-icon>
                                        Position löschen
                                    </a>
                                </mat-menu>
                            </div>
                        </div>

                        <!--********** New Item Button **********-->
                        <div
                            class="lease-return-item new-item-button cursor-pointer"
                            (click)="createItemInTemplate(section)">
                            + Neue Position
                        </div>
                    </div>
                </section>
            </div>

            <div id="new-section-button" class="card cursor-pointer" (click)="createSectionInTemplate()">
                + Neuer Abschnitt
            </div>
            <!--============================================-->
            <!-- END Template Item List -->
            <!--============================================-->
        </div>
        <!--============================================-->
        <!-- END Template Sections & Items -->
        <!--============================================-->

        <!--============================================-->
        <!-- Text Template Dialog -->
        <!--============================================-->
        <lease-return-text-template-dialog
            *ngIf="textTemplateDialogShown"
            [leaseReturnTemplates]="leaseReturnTemplates"
            [leaseReturnTemplateFromParent]="selectedTemplate"
            [textTemplates]="selectedItem.commentTemplates"
            [textTemplateTitle]="selectedItem.title"
            (close)="hideTextTemplatesDialog()"></lease-return-text-template-dialog>
        <!--============================================-->
        <!-- END Text Template Dialog -->
        <!--============================================-->
    </div>
    <!--============================================-->
    <!-- END Template List & Item List -->
    <!--============================================-->
</section>

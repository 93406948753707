import { removeFromArray } from '@autoixpert/lib/arrays/remove-from-array';
import { removeFromArrayById } from '@autoixpert/lib/arrays/remove-from-array-by-id';
import { DocumentMetadata } from '@autoixpert/models/documents/document-metadata';
import { DocumentOrder } from '@autoixpert/models/documents/document-order';

/**
 * Removes a document from the documents list and the given document orders.
 *
 * Document orders exist for each recipient on each report and invoice, so that the user may sort documents
 */
export function removeDocument({
    document,
    documentOrders,
    documents,
}: {
    document: DocumentMetadata;
    documentOrders: DocumentOrder[];
    documents: DocumentMetadata[];
}): void {
    /**
     * Remove document metadata object (if exists)
     */
    removeFromArray(document, documents);

    for (const documentOrder of documentOrders) {
        removeFromArrayById(document._id, documentOrder.items);
    }
}

import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { apiBasePath } from '@autoixpert/external-apis/api-base-path';

/**
 * Gocardless Financial Institutions Service. Retrieve a list of financial institutions for a country.
 * This is used to provide a list of financial institutions to the user when they want to connect their bank account.
 */
@Injectable()
export class FinancialInstitutionsService {
    constructor(private httpClient: HttpClient) {}

    public find({ country }: { country: 'DE' | 'AT' }): Observable<GocardlessFinancialInstitution[]> {
        let queryParams = new HttpParams();
        queryParams = queryParams.set('country', country);

        return this.httpClient.get<GocardlessFinancialInstitution[]>(
            `${apiBasePath}/bankAccountSync/financialInstitutions`,
            {
                params: queryParams,
            },
        );
    }
}

export interface GocardlessFinancialInstitution {
    id: string;
    name: string;
    bic: string;
    transaction_total_days: string;
    countries: string[];
    logo: string;
}

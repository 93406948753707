<div class="dialog-overlay" (mousedown)="closeSelectorWithClickOnOverlay($event)">
    <div class="dialog-container">
        <!--============================================-->
        <!-- Text Block Templates -->
        <!--============================================-->
        <div id="search-and-list-container" *ngIf="selectedTab === 'templateList'">
            <div id="heading-and-toolbar-container">
                <h2 matTooltip="Textvorlagen">{{ textTemplateTitle || 'Textvorlagen' }}</h2>
                <div class="heading-toolbar">
                    <mat-icon class="toolbar-icon" (click)="addTextTemplate()" matTooltip="Neue Textvorlage">
                        add
                    </mat-icon>
                    <mat-icon class="toolbar-icon" (click)="sortByTitle()" matTooltip="Nach Titel sortieren">
                        text_rotate_vertical
                    </mat-icon>
                    <mat-icon
                        class="toolbar-icon"
                        (click)="selectTab('selectTemplatesToCopy'); loadLeaseReturnTemplates()"
                        matTooltip="Textvorlagen zu anderer Prüfposition kopieren">
                        content_copy
                    </mat-icon>
                </div>
            </div>

            <div
                id="text-block-list-container"
                cdkDropList
                cdkDropListLockAxis="y"
                (cdkDropListDropped)="handleReorderingDrop($event)">
                <div
                    class="text-block-container"
                    [ngClass]="{ 'in-edit-mode': textTemplateInEditMode === textTemplate }"
                    *ngFor="let textTemplate of textTemplates"
                    (dblclick)="enterEditMode(textTemplate)"
                    cdkDrag
                    cdkDragBoundary="#text-block-list-container">
                    <mat-icon
                        class="text-block-drag-handle toolbar-icon"
                        cdkDragHandle
                        matTooltip="Per Drag & Drop verschieben">
                        drag_indicator
                    </mat-icon>

                    <!--============================================-->
                    <!-- Readonly mode -->
                    <!--============================================-->
                    <div class="text-block-display-container" *ngIf="textTemplateInEditMode !== textTemplate">
                        <!--********** Content **********-->
                        <div class="text-block-content-container" [quillContent]="textTemplate.body"></div>

                        <!--********** Use Text Icon **********-->
                        <div class="text-block-display-toolbar">
                            <mat-icon
                                class="toolbar-icon"
                                (click)="enterEditMode(textTemplate)"
                                matTooltip="Bearbeiten">
                                edit
                            </mat-icon>
                            <mat-icon class="toolbar-icon" [mat-menu-trigger-for]="textBlockSubmenu">
                                more_vert
                            </mat-icon>
                            <mat-menu #textBlockSubmenu="matMenu">
                                <a mat-menu-item class="menu-item-with-icon" (click)="deleteTextTemplate(textTemplate)">
                                    <mat-icon>delete</mat-icon>
                                    Löschen
                                </a>
                            </mat-menu>
                        </div>
                    </div>
                    <!--============================================-->
                    <!-- END Readonly mode -->
                    <!--============================================-->

                    <!--============================================-->
                    <!-- Edit Mode -->
                    <!--============================================-->
                    <div class="text-block-edit-container" *ngIf="textTemplateInEditMode === textTemplate">
                        <mat-form-field class="mat-block">
                            <mat-quill
                                [(ngModel)]="textTemplate.body"
                                placeholder="Vorlage"
                                (change)="saveTextTemplate(textTemplate)"
                                (keydown)="leaveEditModeOnShortcut(textTemplate, $event)"
                                [autofocus]="true"></mat-quill>
                        </mat-form-field>
                        <div class="text-template-buttons-container">
                            <button
                                (click)="
                                    leaveEditMode(textTemplate);
                                    saveLeaseReturnTemplate(this.leaseReturnTemplateFromParent)
                                ">
                                Bearbeitung beenden
                            </button>
                        </div>
                    </div>
                    <!--============================================-->
                    <!-- END Edit Mode -->
                    <!--============================================-->
                </div>
                <!-------------- "No text blocks found" container -->
                <div class="no-text-blocks-found" *ngIf="textTemplates.length === 0">
                    <img src="/assets/images/icons/no-data-to-display.png" alt="" />
                    <h3>Keine Vorlagen</h3>
                    <div>Lege jetzt individuelle Textvorlagen an.</div>
                    <button (click)="addTextTemplate()">Neue Vorlage</button>
                </div>
            </div>
            <!--============================================-->
            <!-- Dialog Buttons -->
            <!--============================================-->
            <div class="dialog-buttons-container">
                <button (click)="closeDialog()">Schließen</button>
            </div>
            <!--============================================-->
            <!-- END Dialog Buttons -->
            <!--============================================-->
        </div>
        <!--============================================-->
        <!-- END Text Block Templates -->
        <!--============================================-->

        <!--============================================-->
        <!-- Copy Dialog -->
        <!--============================================-->
        <!--============================================-->
        <!-- Select Templates to Copy -->
        <!--============================================-->
        <div id="select-templates-to-copy-tab" *ngIf="selectedTab === 'selectTemplatesToCopy'">
            <mat-icon class="back-arrow" (click)="selectTab('templateList')" matTooltip="Zurück">arrow_back</mat-icon>

            <!--********** Title **********-->
            <h2 class="copy-heading">Kopieren</h2>
            <div class="copy-heading-tagline text-align-center">
                Welche
                <strong>Textvorlagen</strong>
                sollen kopiert werden?
            </div>

            <div class="text-align-center margin-bottom-15">
                <button class="flat" (click)="selectAllTextTemplatesToCopy()">Alle auswählen</button>
            </div>

            <div id="list-of-templates-to-copy" class="copy-dialog-list-container">
                <div class="template-to-copy" *ngFor="let textTemplate of textTemplates">
                    <mat-checkbox
                        [ngModel]="selectedTemplatesToCopy.includes(textTemplate)"
                        (change)="toggleSelectTextTemplateToCopy(textTemplate)">
                        {{ textTemplate.body }}
                    </mat-checkbox>
                </div>
            </div>

            <!--============================================-->
            <!-- Dialog Buttons -->
            <!--============================================-->
            <div class="dialog-buttons-container">
                <button (click)="selectTab('selectTargetTemplates')">Weiter</button>
            </div>
            <!--============================================-->
            <!-- END Dialog Buttons -->
            <!--============================================-->
        </div>
        <!--============================================-->
        <!-- END Select Templates to Copy -->
        <!--============================================-->

        <!--============================================-->
        <!-- Target Templates -->
        <!--============================================-->
        <div id="select-target-templates-tab" *ngIf="selectedTab === 'selectTargetTemplates'">
            <mat-icon class="back-arrow" (click)="selectTab('selectTemplatesToCopy')" matTooltip="Zurück">
                arrow_back
            </mat-icon>

            <!--********** Title **********-->
            <h2 class="copy-heading">Kopieren</h2>
            <div class="copy-heading-tagline text-align-center">
                Zu welchem
                <strong>Leitfaden</strong>
                soll kopiert werden?
            </div>

            <div id="list-of-target-templates" class="copy-dialog-list-container">
                <div
                    class="large-selection-box"
                    *ngFor="let leaseReturnTemplate of leaseReturnTemplates"
                    [ngClass]="{ selected: selectedTargetTemplate === leaseReturnTemplate }"
                    (click)="selectLeaseReturnTemplate(leaseReturnTemplate)">
                    {{ leaseReturnTemplate.title }}
                    <div class="label" *ngIf="leaseReturnTemplate._id === leaseReturnTemplateFromParent._id">
                        Ausgewählter Leitfaden
                    </div>
                    <div class="large-selection-box-active-marker">
                        <mat-icon>check</mat-icon>
                    </div>
                </div>
            </div>

            <!--============================================-->
            <!-- Dialog Buttons -->
            <!--============================================-->
            <div class="dialog-buttons-container">
                <button (click)="selectTab('selectTargetItems')">Weiter</button>
            </div>
            <!--============================================-->
            <!-- END Dialog Buttons -->
            <!--============================================-->
        </div>
        <!--============================================-->
        <!-- END Target Templates -->
        <!--============================================-->

        <!--============================================-->
        <!-- Select Target Items -->
        <!--============================================-->
        <div id="select-target-items-tab" *ngIf="selectedTab === 'selectTargetItems'">
            <mat-icon class="back-arrow" (click)="selectTab('selectTemplatesToCopy')" matTooltip="Zurück">
                arrow_back
            </mat-icon>

            <!--********** Title **********-->
            <h2 class="copy-heading">Kopieren</h2>
            <div class="copy-heading-tagline text-align-center">
                Zu welchen
                <strong>Prüfpositionen</strong>
                soll kopiert werden?
            </div>

            <div id="list-of-target-items" class="copy-dialog-list-container">
                <div id="list-of-target-items-inner-wrapper">
                    <!--********** Section **********-->
                    <div
                        class="target-template-section-container"
                        *ngFor="let leaseReturnTemplateSection of selectedTargetTemplate.sections">
                        <div class="target-template-section">
                            <mat-checkbox
                                [checked]="areAllItemsInTemplateSelected(leaseReturnTemplateSection)"
                                [indeterminate]="
                                    !areAllItemsInTemplateSelected(leaseReturnTemplateSection) &&
                                    areSomeItemsInTemplateSelected(leaseReturnTemplateSection)
                                "
                                (change)="toggleSelectItemsOfSection(leaseReturnTemplateSection)">
                                {{ leaseReturnTemplateSection.title || 'Neuer Abschnitt' }}
                            </mat-checkbox>
                        </div>

                        <!--********** Items **********-->
                        <div
                            class="target-item-container"
                            *ngFor="let templateItem of leaseReturnTemplateSection.items">
                            <mat-checkbox
                                [checked]="selectedTargetTemplateItems.includes(templateItem)"
                                (change)="toggleSelectTemplateItem(templateItem)">
                                {{ templateItem.title || 'Neue Position' }}
                                <span class="number-of-existing-templates" matTooltip="Anzahl Vorlagen">
                                    {{ templateItem.commentTemplates.length }}
                                </span>
                            </mat-checkbox>
                        </div>
                    </div>
                </div>
            </div>

            <!--============================================-->
            <!-- Dialog Buttons -->
            <!--============================================-->
            <div class="dialog-buttons-container">
                <button (click)="selectTab('setOptions')">Weiter</button>
            </div>
            <!--============================================-->
            <!-- END Dialog Buttons -->
            <!--============================================-->
        </div>
        <!--============================================-->
        <!-- END Select Target Items -->
        <!--============================================-->

        <!--============================================-->
        <!-- Set Copy Options -->
        <!--============================================-->
        <div id="set-copy-options-tab" *ngIf="selectedTab === 'setOptions'">
            <mat-icon class="back-arrow" (click)="selectTab('selectTargetItems')" matTooltip="Zurück">
                arrow_back
            </mat-icon>

            <!--********** Title **********-->
            <h2 class="copy-heading">Kopieren</h2>

            <div class="copy-dialog-list-container">
                <div id="copy-options" class="text-align-center">
                    <h3>Optionen</h3>
                    <mat-checkbox [(ngModel)]="removeTemplatesInTargetItemsBeforeCopying">
                        Alte Vorlagen der Zielprüfpositionen entfernen
                    </mat-checkbox>
                </div>

                <div id="copy-summary" class="text-align-center">
                    <h3>Zusammenfassung</h3>
                    <p>
                        {{ selectedTemplatesToCopy.length }} Textvorlagen werden zu
                        {{ selectedTargetTemplateItems.length }} Prüfpositionen kopiert.
                    </p>
                </div>
            </div>

            <!--============================================-->
            <!-- Dialog Buttons -->
            <!--============================================-->
            <div class="dialog-buttons-container">
                <button (click)="copyTextTemplatesToTarget(); closeDialog()">Kopieren</button>
            </div>
            <!--============================================-->
            <!-- END Dialog Buttons -->
            <!--============================================-->
        </div>
        <!--============================================-->
        <!-- END Set Copy Options -->
        <!--============================================-->

        <!--============================================-->
        <!-- END Copy Dialog -->
        <!--============================================-->
    </div>
</div>

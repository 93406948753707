import { Component, EventEmitter, Input, Output } from '@angular/core';
import { getSelectedGarageFeeSet } from '@autoixpert/lib/contact-people/garages/get-selected-garage-fee-set';
import { GarageFeeSet } from '@autoixpert/models/contacts/garage-fee-set';
import { Garage } from '@autoixpert/models/reports/involved-parties/garage';
import { Report } from '@autoixpert/models/reports/report';

@Component({
    selector: 'garage-preview',
    templateUrl: './garage-preview.component.html',
    styleUrls: ['./garage-preview.component.scss'],
})
export class GaragePreviewComponent {
    @Input() report: Report;

    @Output() reportChanged: EventEmitter<void> = new EventEmitter();
    @Output() editGarageFeeSet: EventEmitter<GarageFeeSet> = new EventEmitter();

    get isReportLocked(): boolean {
        return this.report.state === 'done';
    }

    public handleEditGarageFeeSetClick($event: MouseEvent) {
        $event.stopPropagation();
        this.editGarageFeeSet.emit(this.getSelectedGarageFeeSet());
    }

    public getSelectedGarageFeeSet(): GarageFeeSet {
        return getSelectedGarageFeeSet(this.report.garage);
    }

    public removeGarageFromReport() {
        this.report.garage = new Garage();
        this.reportChanged.emit();
    }

    public saveReport() {
        this.reportChanged.emit();
    }
}

import { TaxationType, ValuationVehicleValueType } from '@autoixpert/models/reports/market-value/valuation';
import { Report } from '@autoixpert/models/reports/report';
import { getVatRateForTaxationType, marginTaxationRate } from '@autoixpert/static-data/taxation-rates';

export function getSelectedAudatexValuationResult({ report }: { report: Report }): {
    valueNet: number;
    valueGross: number;
    type: ValuationVehicleValueType;
    taxationType: TaxationType;
    audatexGrossValueWasCalculatedWithAutoixpertTaxRate: boolean;
    isDealerPurchase: boolean;
} {
    const audatexValuation = report.valuation.audatexValuation;

    let valueNet;
    let valueGross;
    let type: ValuationVehicleValueType;
    let audatexGrossValueWasCalculatedWithAutoixpertTaxRate;
    let isDealerPurchase = false;

    if (audatexValuation) {
        if (audatexValuation.replacementValueGross || audatexValuation.replacementValueNet) {
            valueNet = audatexValuation.replacementValueNet;
            valueGross = audatexValuation.replacementValueGross;
            type = 'replacementValue';
            isDealerPurchase = false;
        } else if (audatexValuation.replacementValueDifference) {
            valueNet = audatexValuation.replacementValueDifference / (1 + marginTaxationRate);
            valueGross = audatexValuation.replacementValueDifference;
            type = 'replacementValue';
            isDealerPurchase = false;
        } else if (audatexValuation.dealerPurchasePriceGross || audatexValuation.dealerPurchasePriceNet) {
            valueNet = audatexValuation.dealerPurchasePriceNet;
            valueGross = audatexValuation.dealerPurchasePriceGross;
            type = 'dealerPurchase';
            isDealerPurchase = true;
        } else if (audatexValuation.dealerSalesPriceGross || audatexValuation.dealerSalesPriceNet) {
            valueNet = audatexValuation.dealerSalesPriceNet;
            valueGross = audatexValuation.dealerSalesPriceGross;
            type = 'dealerSales';
            isDealerPurchase = false;
        } else if (audatexValuation.marketValueGross || audatexValuation.marketValueNet) {
            valueNet = audatexValuation.marketValueNet;
            valueGross = audatexValuation.marketValueGross;
            type = 'marketValue';
            isDealerPurchase = false;
        } else if (audatexValuation.presentValueGross || audatexValuation.presentValueNet) {
            valueNet = audatexValuation.presentValueNet;
            valueGross = audatexValuation.presentValueGross;
            type = 'presentValue';
            isDealerPurchase = true;
        }
    }

    /**
     * If Audatex sent only the net value, the user created a valuation without tax in Qapter. In that case, calculate the gross value with the tax defined in autoiXpert.
     */
    if (!valueGross && valueNet) {
        if (report.valuation.taxationType === 'neutral') {
            audatexGrossValueWasCalculatedWithAutoixpertTaxRate = false;
            valueGross = valueNet;
        } else {
            audatexGrossValueWasCalculatedWithAutoixpertTaxRate = true;
            valueGross = valueNet * (1 + getVatRateForTaxationType(report.valuation.taxationType));
        }
    }

    /**
     * This is a weird hack since we do not know the taxation of the audatex valuation.
     */
    let taxationType: TaxationType;
    const ratio = valueGross / valueNet;
    if (ratio > 1.1) {
        taxationType = 'full';
    } else if (ratio > 1.01) {
        taxationType = 'margin';
    } else {
        taxationType = 'neutral';
    }

    // The Audatex task could be imported but the user did not execute a valuation.
    return {
        valueNet,
        valueGross,
        type,
        isDealerPurchase,
        taxationType,
        audatexGrossValueWasCalculatedWithAutoixpertTaxRate,
    };
}

import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { removeFromArray } from '@autoixpert/lib/arrays/remove-from-array';
import { toggleValueInArray } from '@autoixpert/lib/arrays/toggle-value-in-array';
import { translateReportType } from '@autoixpert/lib/report/translate-report-type';
import { translateRecipientRole } from '@autoixpert/lib/translators/translate-recipient-role';
import { ReportRecipientRole, ReportType, reportTypes } from '@autoixpert/models/reports/report';
import { DefaultDocumentOrder } from '@autoixpert/models/teams/default-document-order/default-document-order';
import { DefaultDocumentOrderGroup } from '@autoixpert/models/teams/default-document-order/default-document-order-group';
import { Team } from '@autoixpert/models/teams/team';
import { trackById } from '../../../../../shared/libraries/track-by-id';
import { ToastService } from '../../../../../shared/services/toast.service';

@Component({
    selector: 'default-document-order-group',
    templateUrl: './default-document-order-group.component.html',
    styleUrls: ['./default-document-order-group.component.scss'],
})
export class DefaultDocumentOrderGroupComponent implements OnInit {
    constructor(private toastService: ToastService) {}

    public recipientRoles: ReportRecipientRole[] = ['claimant', 'garage', 'insurance', 'lawyer'];
    public availableRecipientRoles: ReportRecipientRole[] = [];

    // We store the full document config and the list of parts (depending on the document type)
    @Input() team: Team;
    @Input() defaultDocumentOrderGroup: DefaultDocumentOrderGroup;
    @Input() public selectedDefaultDocumentOrder: DefaultDocumentOrder;
    @Input() availableReportTypes: ReportType[];
    @Input() isDefault: boolean;

    @Output() defaultDocumentOrderGroupChanged = new EventEmitter<DefaultDocumentOrderGroup>();
    @Output() defaultDocumentOrderGroupDeleted = new EventEmitter<DefaultDocumentOrderGroup>();

    @Output() defaultDocumentOrderSelected = new EventEmitter<DefaultDocumentOrder>();

    public isReportTypeDialogOpen = false;
    public isTitleEditModeActive = false;
    public numberOfDisplayedTags = 3;

    public ngOnInit() {
        this.deriveAvailableRecipientRoles();
    }

    //*****************************************************************************
    //  Default Document Order Group
    //****************************************************************************/

    /**
     * Delete the whole full document config per report type.
     * This is not possible, if full document config is default.
     */
    public deleteDefaultDocumentOrderGroup() {
        if (this.defaultDocumentOrderGroup.types.includes('reportDefault')) {
            this.toastService.error('Standard nicht löschbar', 'Die Standardkonfiguration kann nicht gelöscht werden.');
        } else {
            this.defaultDocumentOrderGroupDeleted.emit(this.defaultDocumentOrderGroup);
        }
    }

    //*****************************************************************************
    //  Edit Title
    //****************************************************************************/

    public enterTitleEditMode() {
        this.isTitleEditModeActive = true;
    }
    public leaveTitleEditMode() {
        this.isTitleEditModeActive = false;
        this.defaultDocumentOrderGroupChanged.emit();
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Edit Title
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Associated Report Types
    //****************************************************************************/

    public onShowReportTypesDialog() {
        this.isReportTypeDialogOpen = true;
    }

    public onCloseReportTypesDialog() {
        this.isReportTypeDialogOpen = false;
    }

    public toggleReportType(reportType: ReportType) {
        if (this.isReportTypeDisabled(reportType)) {
            return;
        }
        toggleValueInArray(reportType, this.defaultDocumentOrderGroup.types);
        this.defaultDocumentOrderGroupChanged.emit();
    }

    public isReportTypeAssigned(reportType: ReportType): boolean {
        return this.defaultDocumentOrderGroup.types.includes(reportType);
    }

    /**
     * The option to select a ReportType is disabled, if the ReportTyoe is used in a different defaultDocumentOrderGroup and is not
     * assigned to the current config. The latter would require to disable the association.
     */
    public isReportTypeDisabled(reportType: ReportType): boolean {
        return (
            !this.availableReportTypes.find((available) => available === reportType) &&
            !this.isReportTypeAssigned(reportType)
        );
    }

    public getListOfAssignedReportTypes(): string {
        const reportTypesGerman: string[] = this.defaultDocumentOrderGroup.types.map(
            (defaultDocumentOrderGroupType) => {
                if (defaultDocumentOrderGroupType === 'reportDefault') {
                    return 'Standard';
                } else if (defaultDocumentOrderGroupType === 'repairConfirmation') {
                    return 'Reparaturbestätigung';
                } else if (defaultDocumentOrderGroupType === 'invoice') {
                    return 'Rechnung';
                } else {
                    return translateReportType(defaultDocumentOrderGroupType);
                }
            },
        );

        if (reportTypesGerman.length < 1) {
            return 'keine zugeordnet';
        }

        if (reportTypesGerman.length <= this.numberOfDisplayedTags) {
            return reportTypesGerman.join(', ');
        }
        if (reportTypesGerman.length === this.numberOfDisplayedTags + 1) {
            return reportTypesGerman.slice(0, this.numberOfDisplayedTags).join(', ') + ' + 1 weiteres';
        }

        if (reportTypesGerman.length > this.numberOfDisplayedTags + 1) {
            return (
                reportTypesGerman.slice(0, this.numberOfDisplayedTags).join(', ') +
                ` + ${reportTypesGerman.length - this.numberOfDisplayedTags} weitere`
            );
        }
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Associated Report Types
    /////////////////////////////////////////////////////////////////////////////*/

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Default Document Order Group
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Default Document Orders
    //****************************************************************************/
    public selectDefaultDocumentOrder(defaultDocumentOrder: DefaultDocumentOrder): void {
        this.selectedDefaultDocumentOrder = defaultDocumentOrder;
        this.defaultDocumentOrderSelected.emit(this.selectedDefaultDocumentOrder);
    }

    /**
     * Copy the default recipient config for a new recipient role.
     */
    public async addDefaultDocumentOrder(recipientRole: ReportRecipientRole) {
        const standardDefaultDocumentOrder: DefaultDocumentOrder = this.defaultDocumentOrderGroup.documentOrders.find(
            (documentOrder) => documentOrder.recipientRole === 'default',
        );
        const newDefaultDocumentOrder = new DefaultDocumentOrder({
            recipientRole,
            items: JSON.parse(JSON.stringify(standardDefaultDocumentOrder.items)),
        });
        this.defaultDocumentOrderGroup.documentOrders.push(newDefaultDocumentOrder);
        this.defaultDocumentOrderGroupChanged.emit();
        this.deriveAvailableRecipientRoles();
    }

    /**
     * Delete the default document order. That's the config per recipient.
     */
    public async deleteDefaultDocumentOrder(defaultDocumentOrder: DefaultDocumentOrder) {
        if (defaultDocumentOrder.recipientRole === 'default') {
            this.toastService.error('Standard nicht gelöscht', 'Die Standardkonfiguration kann nicht gelöscht werden.');
            return;
        }

        removeFromArray(defaultDocumentOrder, this.defaultDocumentOrderGroup.documentOrders);
        if (this.selectedDefaultDocumentOrder === defaultDocumentOrder) {
            this.selectDefaultDocumentOrder(this.defaultDocumentOrderGroup.documentOrders[0]);
        }
        this.deriveAvailableRecipientRoles();
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Default Document Orders
    /////////////////////////////////////////////////////////////////////////////*/

    /**
     * Get available recipients for full document config.
     * A user must not be able to create multiple overrides per recipient role.
     * Therefore check which recipient roles are already assigned for a report type.
     */
    public deriveAvailableRecipientRoles() {
        const availableRecipientRoles = JSON.parse(JSON.stringify(this.recipientRoles));
        for (const documentOrder of this.defaultDocumentOrderGroup.documentOrders) {
            removeFromArray(documentOrder.recipientRole, availableRecipientRoles);
        }
        this.availableRecipientRoles = availableRecipientRoles;
    }

    protected readonly trackById = trackById;
    protected readonly translateRecipientRole = translateRecipientRole;
    protected readonly reportTypes = reportTypes;
    protected readonly translateReportType = translateReportType;
}

<!--********** Autocompletion **********-->
<mat-autocomplete *ngIf="!disabled" #autocomplete class="min-width-autocomplete">
    <!--============================================-->
    <!-- AutoiXpert Contact Predictions -->
    <!--============================================-->
    <mat-option
        *ngFor="let contactPerson of contactAutocompletionPredictions"
        (mousedown)="$event.preventDefault()"
        class="autocomplete-option"
        [ngClass]="{
            'contains-brand-or-characteristics-icons':
                contactPerson.garageBrands?.length || contactPerson.garageCharacteristics?.length,
        }"
        (onSelectionChange)="insertContactAutocompletion(contactPerson)"
        [value]="contactPerson.organization">
        <div class="autocomplete-option-inner-container">
            <!--********** Organization Logo (currently insurances only) **********-->
            <div
                class="autocomplete-option-organization-logo-container"
                *ngIf="organizationTypeFilter?.length === 1 && organizationTypeFilter[0] === 'insurance'">
                <img
                    class="autocomplete-option-organization-logo"
                    *ngIf="insuranceLogoExists(contactPerson.organization)"
                    src="/assets/images/logos/insurances/{{ getIconNameForInsurance(contactPerson.organization) }}" />
            </div>
            <!--********** Organization Type Icon **********-->
            <div
                *ngIf="organizationTypeFilter?.length !== 1 || organizationTypeFilter[0] !== 'insurance'"
                class="list-item-icon-container"
                [ngSwitch]="contactPerson.organizationType">
                <mat-icon class="contact-type-icon" *ngSwitchCase="'claimant'" matTooltip="Anspruchsteller">
                    person
                </mat-icon>
                <mat-icon class="contact-type-icon" *ngSwitchCase="'garage'" matTooltip="Werkstatt">build</mat-icon>
                <img
                    class="contact-type-icon"
                    *ngSwitchCase="'lawyer'"
                    src="/assets/images/icons/lawyer.svg"
                    matTooltip="Anwalt" />
                <img
                    class="contact-type-icon"
                    *ngSwitchCase="'insurance'"
                    src="/assets/images/icons/insurance.svg"
                    matTooltip="Versicherung" />
                <mat-icon
                    class="contact-type-icon icon-emphasized"
                    *ngSwitchCase="'bidder'"
                    matTooltip="Restwertbieter">
                    gavel
                </mat-icon>
                <mat-icon
                    class="contact-type-icon icon-emphasized"
                    *ngSwitchCase="'residualValueRequestRecipient'"
                    matTooltip="Empfänger Restwertinserate">
                    gavel
                </mat-icon>
                <mat-icon
                    class="contact-type-icon"
                    *ngSwitchCase="'visitLocationFavorite'"
                    matTooltip="Besichtigungsfavorit">
                    star
                </mat-icon>
                <mat-icon class="contact-type-icon" *ngSwitchCase="'intermediary'" matTooltip="Vermittler">
                    record_voice_over
                </mat-icon>
                <mat-icon class="contact-type-icon" *ngSwitchCase="'leaseProvider'" matTooltip="Leasinggeber">
                    account_balance
                </mat-icon>
            </div>
            <!--********** Name and Address **********-->
            <div class="name-and-address-container">
                <div
                    class="autocomplete-option-organization"
                    *ngIf="contactPerson.organization"
                    [matTooltip]="contactPerson.organization">
                    {{ contactPerson.organization }}
                </div>
                <div
                    class="autocomplete-option-organization"
                    *ngIf="!contactPerson.organization"
                    [matTooltip]="contactPerson.firstName + ' ' + contactPerson.lastName"
                    [ngClass]="{ label: contactPerson.organization }">
                    {{ contactPerson.firstName }} {{ contactPerson.lastName }}
                </div>
                <div class="label text-overflow-ellipsis">
                    <span
                        *ngIf="
                            contactPerson.organization &&
                            showFirstAndLastName &&
                            (contactPerson.firstName || contactPerson.lastName)
                        ">
                        {{ contactPerson.firstName }} {{ contactPerson.lastName }} -
                    </span>
                    <!--********** Garage **********-->
                    <ng-container *ngIf="contactPerson.organizationType === 'garage'">
                        <!--********** Display date of last change of fee set **********-->
                        <span matTooltip="Datum des neuesten Kostensatzes">
                            {{ getGarageFeeSetDateLabel(contactPerson) }}
                        </span>
                        -
                    </ng-container>

                    <!--============================================-->
                    <!-- Address -->
                    <!--============================================-->
                    <ng-container>
                        <!--********** Address known **********-->
                        <span
                            *ngIf="
                                contactPerson.streetAndHouseNumberOrLockbox || contactPerson.zip || contactPerson.city
                            "
                            matTooltip="{{ contactPerson.streetAndHouseNumberOrLockbox }} - {{ contactPerson.zip }} {{
                                contactPerson.city
                            }}">
                            {{ contactPerson.streetAndHouseNumberOrLockbox }} - {{ contactPerson.city }}
                        </span>

                        <!--********** Address unknown **********-->
                        <span
                            *ngIf="
                                !(
                                    contactPerson.streetAndHouseNumberOrLockbox ||
                                    contactPerson.zip ||
                                    contactPerson.city
                                )
                            "
                            matTooltip="Adresse unbekannt">
                            keine Anschrift
                        </span>
                    </ng-container>
                    <!--============================================-->
                    <!-- END Address -->
                    <!--============================================-->

                    <ng-container *ngIf="contactPerson.organizationType === 'insurance'">
                        - {{ contactPerson.email }}
                    </ng-container>
                </div>
            </div>

            <div class="submenu-container" *ngIf="contactPerson.createdBy === 'from-open-report'">
                <mat-icon matTooltip="Kontakt existiert in einem anderen offenen Gutachten">assignment</mat-icon>
            </div>

            <!--********** Submenu **********-->
            <div
                class="submenu-container"
                *ngIf="
                    contactPerson.createdBy !== 'autoixpert-default' &&
                    contactPerson.createdBy !== 'from-open-report' &&
                    user.accessRights.editContacts &&
                    allowDeletion
                ">
                <mat-icon (click)="removeAutocompleteEntry(contactPerson); stopEvent($event)" matTooltip="Löschen">
                    delete
                </mat-icon>
            </div>
        </div>
        <!--============================================-->
        <!-- Garage Brands & Characteristics -->
        <!--============================================-->
        <div class="search-matches-container" *ngIf="showSearchMatches && searchMatchesMap.has(contactPerson._id)">
            <div
                class="search-match-entry"
                *ngIf="searchMatchesMap.has(contactPerson._id)"
                matTooltip="Die Eigenschaft &quot;{{
                    searchMatchesMap.get(contactPerson._id).propertyGerman
                }}&quot; enthält den Suchbegriff">
                {{ searchMatchesMap.get(contactPerson._id).propertyGerman }}
            </div>
        </div>
        <!--============================================-->
        <!-- END Garage Brands & Characteristics -->
        <!--============================================-->
    </mat-option>

    <!--********** Loading state. We need an mat-option in the empty state to trigger the initial load of suggestions. **********-->
    <mat-option
        class="autocompletion-loading-indicator"
        *ngIf="showSuggestionsIfEmpty && !loadedSuggestionsForEmptyState">
        <img src="/assets/images/icons/loading-indicator.svg" matTooltip="Lade Kontakte" />
    </mat-option>
    <div
        class="load-more-contacts-button"
        *ngIf="contactAutocompletionPredictionsFromServer.length > 0 && !recordsLimitReached && value?.length > 2"
        (click)="loadMoreContacts()">
        <div class="label text-align-center">Mehr anzeigen</div>
    </div>

    <!--============================================-->
    <!-- END Autoixpert Contact Predictions -->
    <!--============================================-->

    <!--============================================-->
    <!-- Address Predictions (OpenStreetMap) -->
    <!--============================================-->
    <mat-option
        *ngFor="let mapsPrediction of addressAutocompletionPredictions"
        class="autocomplete-option"
        [value]="formatStreetAndHouseNumber(mapsPrediction)"
        (onSelectionChange)="insertAddressAutocompletion(mapsPrediction)">
        <div class="autocomplete-option-inner-container">
            <!--********** Open Street Map Attribution **********-->
            <div class="list-item-icon-container">
                <mat-icon class="contact-type-icon" matTooltip="Aus OpenStreetMap">location_on</mat-icon>
            </div>
            <div class="name-and-address-container">
                <div>{{ formatStreetAndHouseNumber(mapsPrediction) }}</div>
                <div class="label">{{ mapsPrediction.zip }} {{ mapsPrediction.city }}</div>
            </div>
        </div>
    </mat-option>
    <!--============================================-->
    <!-- END Address Predictions (OpenStreetMap) -->
    <!--============================================-->

    <!--============================================-->
    <!-- Google Maps Predictions -->
    <!--============================================-->
    <div
        class="load-more-contacts-button"
        *ngIf="!organizationPredictionSessionToken && externalOrganizationAutocompletionEnabled && value?.length > 2"
        (click)="startExternalOrganizationSearch()">
        <div class="label text-align-center">Mit Google Maps suchen</div>
    </div>
    <mat-option
        *ngFor="let mapsPrediction of organizationAutocompletionPredictions"
        class="autocomplete-option"
        (onSelectionChange)="insertOrganizationAutocompletion(mapsPrediction)">
        <div class="autocomplete-option-inner-container">
            <!--********** Google Maps Attribution **********-->
            <div class="list-item-icon-container">
                <img
                    matTooltip="Vorschlag von Google Maps"
                    class="google-maps-attribution-logo"
                    src="/assets/images/logos/google-maps.png" />
            </div>
            <!--********** Name and Address **********-->
            <div class="name-and-address-container">
                <div
                    class="autocomplete-option-organization"
                    [title]="mapsPrediction.title"
                    [matTooltip]="mapsPrediction.title">
                    {{ mapsPrediction.title }}
                </div>
                <div class="label text-overflow-ellipsis" title="{{ mapsPrediction.description }}">
                    {{ mapsPrediction.description }}
                </div>
            </div>
        </div>
    </mat-option>
    <!--============================================-->
    <!-- END Google Maps Predictions -->
    <!--============================================-->
</mat-autocomplete>

import { Pipe, PipeTransform } from '@angular/core';

/**
 * Returns a boolean whether the user's settings currently display or hide bidders without bids
 * in the custom residual value sheet. It takes the settings from the report and team as primitive values
 * instead of the whole report/team objects so the pipe can be pure. This way we don't need to have function
 * calls like the getHideBiddersWithoutBidSetting in the template.
 */
@Pipe({
    name: 'shouldIncludeBiddersWithoutBid',
    pure: true,
})
export class ShouldIncludeBiddersWithoutBidPipe implements PipeTransform {
    transform(includeSettingReport: boolean, includeSettingTeam: boolean): boolean {
        // Check if the user wants to print the bidders with no bid. Preference in report overwrites preference in team.
        return includeSettingReport != null ? includeSettingReport : !!includeSettingTeam;
    }
}

<div
    class="tag-container"
    [ngClass]="{ disabled: disabled }"
    (click)="showDocumentTypesDialog()"
    [matTooltip]="getTooltip()">
    <mat-icon class="tag-icon">local_offer</mat-icon>
    <div class="label">
        <ng-container *ngIf="selectedDocumentOrderConfigIds?.length">
            {{ getHumanReadableDocumentList() }}
        </ng-container>
        <span class="placeholder" *ngIf="!selectedDocumentOrderConfigIds?.length">
            {{ placeholder }}
        </span>
    </div>
</div>

<document-types-dialog
    *ngIf="documentTypesDialogShown"
    [disallowPowerOfAttorney]="disallowPowerOfAttorney"
    [inputDocumentOrderConfigIds]="selectedDocumentOrderConfigIds"
    [allDocumentOrderConfigs]="allDocumentOrderConfigs"
    (close)="hideDocumentTypesDialog()"
    (save)="handleChange($event)"></document-types-dialog>

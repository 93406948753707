<div id="column-layout-container" *ngIf="report && report.repairConfirmation">
    <div id="report-summary-column" class="card card-with-padding dark-card">
        <!--============================================-->
        <!-- Report Summary -->
        <!--============================================-->
        <h2 id="report-summary-heading">Gutachten</h2>
        <div id="report-data-container">
            <!--********** Report Token **********-->
            <div class="report-data-item">
                <div class="report-data-icon-container">
                    <mat-icon class="report-data-icon">description</mat-icon>
                </div>
                <div class="report-data-values-container">
                    <div class="report-data-value-primary" matTooltip="Aktenzeichen">
                        {{ report.token || 'Kein Aktenzeichen' }}
                    </div>
                    <div
                        class="report-data-value-secondary label"
                        matTooltip="Erstellt am: {{ report.createdAt | moment: 'DD.MM.YYYY - HH:mm [Uhr]' }}">
                        {{ report.createdAt | moment }}
                    </div>
                </div>
            </div>
            <!--********** Manufacturer & Model **********-->
            <div class="report-data-item">
                <div
                    class="report-data-icon-container"
                    [ngClass]="{ 'white-background': iconForCarBrandExists(report.car.make) }">
                    <img
                        *ngIf="iconForCarBrandExists(report.car.make)"
                        class="report-data-icon"
                        [src]="iconFilePathForCarBrand(report.car.make, 'large')" />
                    <mat-icon *ngIf="!iconForCarBrandExists(report.car.make)" class="report-data-icon">
                        directions_car
                    </mat-icon>
                </div>
                <div class="report-data-values-container">
                    <div class="report-data-value-primary" matTooltip="Hersteller">
                        {{ report.car.make || 'Kein Hersteller' }}
                    </div>
                    <div class="report-data-value-secondary label" matTooltip="Modell">
                        {{ report.car.model || 'Kein Modell' }}
                    </div>
                </div>
            </div>
            <!--********** Insurance **********-->
            <div class="report-data-item">
                <div class="report-data-icon-container">
                    <img class="report-data-icon" src="/assets/images/icons/insurance.svg" />
                </div>
                <div class="report-data-values-container">
                    <div class="report-data-value-primary" matTooltip="Versicherung">
                        {{ report.insurance.contactPerson.organization || 'Keine Versicherung' }}
                    </div>
                    <div class="report-data-value-secondary label" matTooltip="Versicherungsnummer">
                        {{ report.authorOfDamage.insuranceNumber || 'Keine Versicherungsnummer' }}
                    </div>
                </div>
            </div>
        </div>
        <!--============================================-->
        <!-- END Report Summary -->
        <!--============================================-->

        <!--============================================-->
        <!-- Confirmation Photos -->
        <!--============================================-->
        <h2 id="confirmation-photos-heading">Bestätigungsfotos</h2>
        <div id="confirmation-photos-container" *ngIf="confirmationPhotos.length > 0">
            <div id="confirmation-photos" (click)="navigateToPhotos()" matTooltip="Zur Fotoübersicht">
                <img
                    [src]="getLocalThumbnailUrl(confirmationPhotos[0]._id)"
                    class="confirmation-photo-thumbnail"
                    id="confirmation-photo-background"
                    *ngIf="
                        confirmationPhotos && confirmationPhotos[0] && getLocalThumbnailUrl(confirmationPhotos[0]._id)
                    " />
                <img
                    [src]="getLocalThumbnailUrl(confirmationPhotos[1]._id)"
                    class="confirmation-photo-thumbnail"
                    id="confirmation-photo-foreground"
                    *ngIf="
                        confirmationPhotos && confirmationPhotos[1] && getLocalThumbnailUrl(confirmationPhotos[1]._id)
                    " />
            </div>
            <div id="number-of-confirmation-photos">
                <mat-icon class="number-of-confirmation-photos-icon" *ngIf="confirmationPhotos.length > 1">
                    photo_library
                </mat-icon>
                <mat-icon class="number-of-confirmation-photos-icon" *ngIf="confirmationPhotos.length === 1">
                    insert_photo
                </mat-icon>
                {{ confirmationPhotos.length }}
            </div>
        </div>
        <div id="no-confirmation-photos-placeholder-container" *ngIf="confirmationPhotos.length === 0">
            <div id="no-confirmation-photos-icon-container" (click)="navigateToPhotos()">
                <mat-icon id="no-confirmation-photos-icon">photo_camera</mat-icon>
            </div>
            <div id="no-confirmation-photos-placeholder-text" class="label">
                Keine Fotos vorhanden.
                <br />
                Lade sie in der Fotoübersicht hoch.
            </div>
            <button (click)="navigateToPhotos()">Zur Fotoübersicht</button>
        </div>
        <!--============================================-->
        <!-- END Confirmation Photos -->
        <!--============================================-->
    </div>
    <!--============================================-->
    <!-- Reinspection -->
    <!--============================================-->
    <div id="reinspection-column" class="card card-with-padding">
        <h2 id="reinspection-heading">Nachbesichtigung</h2>
        <mat-form-field id="assessor-select" class="mat-block">
            <mat-select
                [(ngModel)]="report.repairConfirmation.assessor"
                placeholder="{{ assessors.length > 1 ? 'Zuständiger Sachverständiger' : 'Sachverständiger' }}"
                (selectionChange)="saveReport()">
                <mat-option *ngFor="let assessor of assessors" [value]="assessor._id">
                    {{ getUsersFullName(assessor._id) }}
                </mat-option>
            </mat-select>
        </mat-form-field>
        <mat-form-field id="visiting-assessor-select" class="mat-block" *ngIf="assessors.length > 1">
            <mat-select
                [(ngModel)]="report.repairConfirmation.visitingAssessor"
                placeholder="Anwesender Sachverständiger"
                (selectionChange)="saveReport()">
                <mat-option *ngFor="let assessor of assessors" [value]="assessor._id">
                    {{ getUsersFullName(assessor._id) }}
                </mat-option>
            </mat-select>
        </mat-form-field>
        <div id="date-and-repair-duration-container">
            <date-input
                id="reinspection-date-input"
                [(date)]="report.repairConfirmation.date"
                placeholder="Besichtigung am"
                (dateChange)="saveReport()"></date-input>
            <div id="repair-duration-container">
                <mat-form-field id="repair-duration" class="mat-block">
                    <input
                        matInput
                        class="text-align-end"
                        placeholder="Reparaturdauer"
                        [(ngModel)]="report.repairConfirmation.actualReparationDowntime"
                        (change)="saveReport()" />
                    <span matSuffix>
                        {{ report.repairConfirmation.actualReparationDowntime === '1' ? 'Tag' : 'Tage' }}
                    </span>
                </mat-form-field>
                <mat-icon
                    id="repair-duration-calculator-icon"
                    *ngIf="report.damageCalculation.downtimeInWorkdaysDueToReparation"
                    (click)="fillInEstimatedDowntime()"
                    matTooltip="Reparaturdauer aus Kalkulation einfügen">
                    timer
                </mat-icon>
            </div>
        </div>

        <h3 id="result-of-reinspection-heading">Ergebnis der Nachbesichtigung</h3>

        <div id="repair-execution-types-container">
            <div
                class="repair-execution-type-container"
                *ngFor="let repairExecutionType of repairExecutionTypes"
                (click)="selectExecutionType(repairExecutionType.name)"
                [ngClass]="{ active: report.repairConfirmation.repairExecutionType === repairExecutionType.name }">
                <mat-icon class="repair-execution-type-icon">{{ repairExecutionType.iconName }}</mat-icon>
                <div class="repair-execution-type-label">{{ repairExecutionType.title }}</div>
            </div>
        </div>

        <date-input
            [(date)]="report.repairConfirmation.completionDate"
            matTooltip="Der Tag, auf den die Reparaturbestätigung datiert wird."
            placeholder="Fertigstellungsdatum"
            (dateChange)="saveReport()"></date-input>

        <mat-form-field class="mat-block">
            <mat-quill
                placeholder="Bemerkungen"
                [(ngModel)]="report.repairConfirmation.comment"
                (change)="saveReport()"></mat-quill>
            <mat-icon
                matSuffix
                class="input-toolbar-icon"
                (click)="showRepairConfirmationCommentTextTemplateSelector = true"
                matTooltip="Textvorlagen">
                playlist_add
            </mat-icon>
        </mat-form-field>
        <text-template-selector
            [(text)]="report.repairConfirmation.comment"
            (textChange)="saveReport()"
            [report]="report"
            *ngIf="showRepairConfirmationCommentTextTemplateSelector === true"
            placeholderForResultingText="Bemerkungen zur Besichtigung"
            (close)="showRepairConfirmationCommentTextTemplateSelector = false"
            [textTemplateType]="'repairConfirmationComment'"
            @runChildAnimations></text-template-selector>
        <div id="reinspection-buttons-container">
            <button class="secondary" (click)="downloadRepairConfirmationPreview()">
                <img
                    src="/assets/images/icons/loading-indicator.svg"
                    *ngIf="repairConfirmationPreviewDownloadPending"
                    class="pending-icon" />
                Vorschau
            </button>
        </div>
    </div>
    <!--============================================-->
    <!-- END Reinspection -->
    <!--============================================-->

    <!--============================================-->
    <!-- Invoice -->
    <!--============================================-->
    <div id="invoice-and-documents-column" class="card card-with-padding">
        <h2 id="invoice-heading">Rechnung</h2>
        <div class="card-submenu">
            <mat-icon [matMenuTriggerFor]="invoiceCardSubmenu" class="toolbar-icon">more_vert</mat-icon>
            <mat-menu #invoiceCardSubmenu="matMenu">
                <mat-option *ngIf="!isInvoiceLocked()" (click)="createInvoice()">Rechnung erstellen</mat-option>
                <mat-option *ngIf="isInvoiceLocked()" (click)="makeInvoiceEditable()">Rechnung bearbeiten</mat-option>
                <mat-option (click)="deleteRepairConfirmation()">Reparaturbestätigung löschen</mat-option>
            </mat-menu>
        </div>
        <div id="invoice-number-and-date-container">
            <div id="invoice-number-container">
                <mat-form-field>
                    <input
                        matInput
                        placeholder="Nummer"
                        [(ngModel)]="report.repairConfirmation.invoiceParameters.number"
                        (focus)="lastInvoiceNumber = report.repairConfirmation.invoiceParameters.number"
                        [disabled]="isInvoiceLocked()"
                        (change)="handleInvoiceNumberChange()" />
                </mat-form-field>
                <mat-icon
                    id="invoice-number-generator-icon"
                    *ngIf="!isInvoiceLocked()"
                    (click)="generateInvoiceNumber()"
                    matTooltip="Rechnungsnummer generieren">
                    file_download
                </mat-icon>
                <mat-icon
                    [editCounterAnchor]="report"
                    class="invoice-number-settings-icon outlined"
                    *ngIf="report.invoiceNumberConfig"
                    matTooltip="Rechnungszähler für dieses Gutachten bearbeiten">
                    more_vert
                </mat-icon>
            </div>
            <date-input
                id="invoice-date-container"
                [(date)]="report.repairConfirmation.invoiceParameters.date"
                (dateChange)="determineVat(); saveReport()"
                matTooltip="Kürzel: h = heute, g = gestern, v = vorgestern, 2t = in zwei Tagen, -3w = vor drei Wochen, 1m = in einem Monat"
                placeholder="Rechnungsdatum"
                [disabled]="isInvoiceLocked()"></date-input>
            <mat-form-field id="days-until-due-container">
                <input
                    matInput
                    placeholder="Zahlungsziel (Tage)"
                    number-input
                    [(number)]="report.repairConfirmation.invoiceParameters.daysUntilDue"
                    [maximumFractionDigits]="0"
                    [minimumFractionDigits]="0"
                    class="text-align-start"
                    (change)="saveReport()"
                    matTooltip="Wird vorbelegt mit dem Zahlungsziel der Gutachtenrechnung." />
            </mat-form-field>
            <!--***********************************************
            ** Electronic Invoice
            ************************************************-->
            <electronic-invoice-toggle
                [disabled]="isInvoiceLocked()"
                [invoiceDate]="report.repairConfirmation.invoiceParameters.date"
                [(isElectronicInvoiceEnabled)]="report.repairConfirmation.invoiceParameters.isElectronicInvoiceEnabled"
                [(isElectronicInvoiceStatusFrozen)]="
                    report.repairConfirmation.invoiceParameters.isElectronicInvoiceStatusFrozen
                "
                (isElectronicInvoiceEnabledChange)="saveReport()"
                (isElectronicInvoiceStatusFrozenChange)="saveReport()"
                [invoiceRecipientContactPerson]="report.claimant.contactPerson"></electronic-invoice-toggle>
            <!--***********************************************
            ** END Electronic Invoice
            ************************************************-->
        </div>

        <!--********** Line Items **********-->
        <h3 id="line-items-heading">Positionen</h3>
        <!--============================================-->
        <!-- Base Fee Line Item -->
        <!--============================================-->
        <div class="line-item" *ngIf="feeLineItem">
            <mat-checkbox
                [(ngModel)]="feeLineItem.active"
                class="line-item-active-marker"
                (change)="saveReport()"
                [disabled]="isInvoiceLocked()"></mat-checkbox>
            <mat-form-field class="line-item-description">
                <mat-quill
                    placeholder="Position"
                    [(ngModel)]="feeLineItem.description"
                    (change)="saveReport()"
                    [disabled]="isInvoiceLocked()"
                    name="invoice-item-description"></mat-quill>
            </mat-form-field>
            <mat-form-field class="line-item-quantity">
                <input
                    matInput
                    number-input
                    [minimumFractionDigits]="0"
                    placeholder="Menge"
                    [number]="feeLineItem.quantity"
                    disabled />
            </mat-form-field>
            <currency-input
                class="line-item-unit-price"
                [(value)]="feeLineItem.unitPrice"
                placeholder="Einzelpreis"
                (valueChange)="saveReport(); rememberRepairConfirmationFee($event)"
                [disabled]="isInvoiceLocked()"></currency-input>
            <currency-input
                totalPriceReadonlyHint
                class="line-item-total-price"
                [value]="(feeLineItem.unitPrice || 0) * (feeLineItem.quantity || 0)"
                placeholder="Gesamt"
                [readonly]="true"></currency-input>
        </div>
        <!--============================================-->
        <!-- END Base Fee Line Item -->
        <!--============================================-->

        <!--============================================-->
        <!-- Photos Line Item -->
        <!--============================================-->
        <div class="line-item" *ngIf="photoLineItem">
            <mat-checkbox
                [(ngModel)]="photoLineItem.active"
                class="line-item-active-marker"
                (change)="saveReport()"
                [disabled]="isInvoiceLocked()"></mat-checkbox>
            <mat-form-field class="line-item-description">
                <mat-quill
                    placeholder="Position"
                    [(ngModel)]="photoLineItem.description"
                    (change)="saveReport()"
                    [disabled]="isInvoiceLocked()"
                    name="invoice-item-description"></mat-quill>
            </mat-form-field>
            <mat-form-field class="line-item-quantity">
                <input
                    matInput
                    number-input
                    [minimumFractionDigits]="0"
                    placeholder="Menge"
                    [number]="photoLineItem.quantity"
                    disabled />
            </mat-form-field>
            <currency-input
                class="line-item-unit-price"
                [(value)]="photoLineItem.unitPrice"
                placeholder="Einzelpreis"
                [disabled]="isInvoiceLocked()"></currency-input>
            <currency-input
                totalPriceReadonlyHint
                class="line-item-total-price"
                [value]="(photoLineItem.unitPrice || 0) * (photoLineItem.quantity || 0)"
                placeholder="Gesamt"
                [readonly]="true"></currency-input>
        </div>
        <!--============================================-->
        <!-- END Photos Line Item -->
        <!--============================================-->

        <!--============================================-->
        <!-- Custom Line Items -->
        <!--============================================-->
        <div class="line-item" *ngFor="let lineItem of report.repairConfirmation.invoiceParameters.lineItems.slice(2)">
            <mat-checkbox
                [(ngModel)]="lineItem.active"
                class="line-item-active-marker"
                (change)="saveReport()"
                [disabled]="isInvoiceLocked()"></mat-checkbox>
            <mat-form-field class="line-item-description">
                <mat-quill
                    placeholder="Position"
                    [(ngModel)]="lineItem.description"
                    (change)="saveReport()"
                    [disabled]="isInvoiceLocked()"
                    name="invoice-item-description"></mat-quill>
            </mat-form-field>
            <mat-form-field class="line-item-quantity">
                <input
                    matInput
                    number-input
                    [minimumFractionDigits]="0"
                    placeholder="Menge"
                    [(number)]="lineItem.quantity"
                    [disabled]="isInvoiceLocked()"
                    (change)="saveReport()" />
            </mat-form-field>
            <currency-input
                class="line-item-unit-price"
                [(value)]="lineItem.unitPrice"
                placeholder="Einzelpreis"
                (valueChange)="saveReport()"
                [disabled]="isInvoiceLocked()"></currency-input>
            <currency-input
                totalPriceReadonlyHint
                class="line-item-total-price"
                [value]="(lineItem.unitPrice || 0) * (lineItem.quantity || 0)"
                placeholder="Gesamt"
                [readonly]="true"></currency-input>

            <mat-icon
                class="line-item-delete-icon"
                *ngIf="!isInvoiceLocked()"
                (click)="removeInvoiceLineItem(lineItem); saveReport()"
                matTooltip="Position löschen">
                delete
            </mat-icon>
        </div>
        <!--============================================-->
        <!-- END Custom Line Items -->
        <!--============================================-->

        <div id="add-line-item-container">
            <button
                class="flat"
                (click)="addInvoiceLineItem(); saveReport()"
                [ngClass]="{ disabled: isInvoiceLocked() }">
                + Position hinzufügen
            </button>
        </div>
        <div id="invoice-total-container">
            <div class="label">Summe</div>
            <div id="invoice-total">
                <span matTooltip="Netto">{{ getInvoiceTotalNet() | number: '1.2-2' }} €</span>
            </div>
            <div class="label">
                <span matTooltip="Brutto">{{ getInvoiceTotalGross() | number: '1.2-2' }} €</span>
            </div>
        </div>
        <div
            id="factoring-provider-container"
            *ngIf="user && (user.adeltaFinanzUser.username || user.kfzvsUser.customerNumber)">
            <mat-checkbox
                [(ngModel)]="report.repairConfirmation.invoiceParameters.factoringEnabled"
                matTooltip="Rechnung an {{ userPreferences.factoringProvider.contactPerson.organization }} abtreten"
                [disabled]="isInvoiceLocked()">
                Factoring aktivieren
            </mat-checkbox>
        </div>
        <div id="print-and-export-buttons-container">
            <button id="create-invoice-button" (click)="createInvoice()" *ngIf="!isInvoiceLocked()">
                <img
                    class="pending-icon"
                    src="/assets/images/icons/loading-indicator-white.svg"
                    *ngIf="invoiceCreationPending" />
                Rechnung erstellen
            </button>
            <button (click)="navigateToPrintAndTransmission()" *ngIf="isInvoiceLocked()">Druck & Versand</button>
            <button class="secondary" (click)="downloadInvoicePreview()">
                <img
                    src="/assets/images/icons/loading-indicator.svg"
                    class="pending-icon"
                    *ngIf="invoicePreviewDownloadPending" />
                Vorschau
            </button>
        </div>
    </div>
    <!--============================================-->
    <!-- END Invoice -->
    <!--============================================-->
</div>

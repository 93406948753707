import { OverlayRef } from '@angular/cdk/overlay';
import { Component, HostListener, Input, OnInit } from '@angular/core';
import { isReportLocked } from '@autoixpert/lib/report/is-report-locked';
import { Report } from '@autoixpert/models/reports/report';
import { Team } from '@autoixpert/models/teams/team';
import { InvoiceNumberJournalEntryService } from '../../services/invoice-number-journal-entry.service';
import { LoggedInUserService } from '../../services/logged-in-user.service';
import { ReportDetailsService } from '../../services/report-details.service';
import { ReportService } from '../../services/report.service';
import { ToastService } from '../../services/toast.service';

@Component({
    selector: 'edit-report-invoice-number-counter-dialog',
    templateUrl: './edit-report-invoice-number-counter-dialog.component.html',
    styleUrls: ['./edit-report-invoice-number-counter-dialog.component.scss'],
})
export class EditReportInvoiceNumberCounterDialogComponent implements OnInit {
    /**
     * This is the report that the user is currently editing and where this dialog was opened.
     */
    @Input() report: Report;
    protected team: Team;

    /**
     * This is the report whose invoice counter is used to generate invoice numbers. In case of an amendment report or invoice audit,
     * this is the original report, unless the user has decided to start a new counter for amendments and invoice audits (separateCounterForAmendmentReportsAndInvoiceAudits)
     */
    protected reportWhoseInvoiceCounterIsUsed: Report;

    protected previousCounterCount: number;

    constructor(
        private overlayRef: OverlayRef,
        private loggedInUserService: LoggedInUserService,
        private reportDetailsService: ReportDetailsService,
        private toastService: ToastService,
        private reportService: ReportService,
        private invoiceNumberJournalEntryService: InvoiceNumberJournalEntryService,
    ) {}

    async ngOnInit() {
        this.team = this.loggedInUserService.getTeam();

        // In case this is an amendment report or invoice audit linked to an original report and the user wants them to share
        // their counters with the original report -> we need to display and edit the counter of the original report
        const isAmendmentOrInvoiceAudit = this.report.originalReportId || this.report.invoiceAudit?.reportId;
        if (
            isAmendmentOrInvoiceAudit &&
            !this.report.invoiceNumberConfig.separateCounterForAmendmentReportsAndInvoiceAudits
        ) {
            const originalReportId = this.report.originalReportId || this.report.invoiceAudit?.reportId;
            this.reportWhoseInvoiceCounterIsUsed = await this.reportService.get(originalReportId);
        } else {
            this.reportWhoseInvoiceCounterIsUsed = this.report;
        }
    }

    protected async closeDialog() {
        this.overlayRef.detach();
    }

    protected inputValueChanged(value: string) {
        /**
         * Use || 0 to prevent NaN values, e.g. when the user deletes the input.
         */
        this.reportWhoseInvoiceCounterIsUsed.invoiceNumberConfig.count = parseInt(value, 10) || 0;
    }

    protected async handleCounterChange() {
        await this.saveReport();
        await this.invoiceNumberJournalEntryService.create({
            entryType: 'reportInvoiceSubCounterChanged',
            previousCounterCount: this.previousCounterCount,
            counterCount: this.reportWhoseInvoiceCounterIsUsed.invoiceNumberConfig.count,
            reportId: this.reportWhoseInvoiceCounterIsUsed._id,
        });
    }

    public async saveReport({ waitForServer }: { waitForServer?: boolean } = {}): Promise<Report> {
        if (isReportLocked(this.report)) {
            return;
        }

        try {
            return await this.reportDetailsService.patch(this.reportWhoseInvoiceCounterIsUsed, { waitForServer });
        } catch (error) {
            this.toastService.error('Fehler beim Sync', 'Bitte versuche es später erneut');
            console.error(
                'An error occurred while saving the report via the ReportService.',
                this.reportWhoseInvoiceCounterIsUsed,
                { error },
            );
            throw error;
        }
    }

    //*****************************************************************************
    //  Keyboard Shortcuts
    //****************************************************************************/
    @HostListener('window:keydown', ['$event'])
    protected closeEditorOnEscKey(event) {
        if (event.key === 'Escape') {
            // Make sure saving is triggered if the user is still inside an input.
            if (document.activeElement.nodeName === 'INPUT' || document.activeElement.nodeName === 'TEXTAREA') {
                (document.activeElement as HTMLElement).blur();
            }
            void this.closeDialog();
        }
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Keyboard Shortcuts
    /////////////////////////////////////////////////////////////////////////////*/

    protected readonly isReportLocked = isReportLocked;
}

<!--============================================-->
<!-- Heading -->
<!--============================================-->
<h2>Einrichtung & Individualisierung</h2>
<div class="subheading">
    Richte dir {{ productName }} ganz individuell ein. Alternativ kannst du an vielen Stellen auch ohne Einrichtung mit
    unserem professionellen Standard arbeiten.
</div>
<!--============================================-->
<!-- END Heading -->
<!--============================================-->

<mat-icon
    class="dialog-close-icon"
    (click)="hideSetupSteps()"
    matTooltip="Dauerhaft ausblenden.{{
        '\n\n'
    }}Die Einrichtung kannst du auch jederzeit über die Einstellungen abschließen.">
    close
</mat-icon>

<!-- For dev only -->
<!--<mat-icon style="position: absolute; bottom: 5px; right: 5px" (click)="resetSetupSteps()">undo</mat-icon>-->

<!--============================================-->
<!-- Content -->
<!--============================================-->
<div id="content-grid" *ngIf="team.userInterfaceStates.setupSteps">
    <!--============================================-->
    <!-- Header and Footer Template -->
    <!--============================================-->
    <div
        class="setup-item"
        [ngClass]="{ done: team.userInterfaceStates.setupSteps.headerAndFooterTemplate }"
        (click)="navigate('/Einstellungen#document-layout-container')">
        <!--********** Icon **********-->
        <div class="setup-icon-container">
            <mat-icon class="setup-icon outlined">description</mat-icon>
        </div>

        <!--********** Text **********-->
        <div class="setup-item-text-container">
            <div class="setup-item-heading">Briefpapier</div>
            <div class="setup-item-body">Kopf- & Fußzeile anpassen</div>
        </div>

        <!--********** Help Indicator **********-->
        <mat-icon
            class="help-icon medium-icon outlined"
            matTooltip="Nutze unser Standardbriefpapier - optional mit eigenem Logo und eigener Akzentfarbe - oder lade ein ganz eigenes Briefpapier hoch.">
            help
        </mat-icon>

        <!--********** Done Marker **********-->
        <div class="setup-done-marker" matTooltip="Erledigt">
            <mat-icon>check</mat-icon>
        </div>
    </div>
    <!--============================================-->
    <!-- END Header and Footer Template -->
    <!--============================================-->

    <!--============================================-->
    <!-- Signature & Stamp -->
    <!--============================================-->
    <div
        class="setup-item"
        [ngClass]="{ done: team.userInterfaceStates.setupSteps.signatureAndStamp }"
        (click)="navigate('/Einstellungen#signature-and-stamp-container')">
        <!--********** Icon **********-->
        <div class="setup-icon-container">
            <mat-icon class="setup-icon outlined">signature</mat-icon>
        </div>

        <!--********** Text **********-->
        <div class="setup-item-text-container">
            <div class="setup-item-heading">Unterschrift & Stempel</div>
            <div class="setup-item-body">Eigene Bilddatei hochladen</div>
        </div>

        <!--********** Help Indicator **********-->
        <mat-icon
            class="help-icon medium-icon outlined"
            matTooltip="Unterschrift & Stempel werden am Ende des Gutachtens abgedruckt und verleihen deinem Gutachten deine persönliche Handschrift.">
            help
        </mat-icon>

        <!--********** Done Marker **********-->
        <div class="setup-done-marker" matTooltip="Erledigt">
            <mat-icon>check</mat-icon>
        </div>
    </div>
    <!--============================================-->
    <!-- END Signature & Stamp -->
    <!--============================================-->

    <!--============================================-->
    <!-- Sender Email -->
    <!--============================================-->
    <div
        class="setup-item"
        [ngClass]="{ done: team.userInterfaceStates.setupSteps.emailCredentials }"
        (click)="navigate('/Einstellungen#email-settings-container')">
        <!--********** Icon **********-->
        <div class="setup-icon-container">
            <mat-icon class="setup-icon outlined">mail</mat-icon>
        </div>

        <!--********** Text **********-->
        <div class="setup-item-text-container">
            <div class="setup-item-heading">E-Mailversand</div>
            <div class="setup-item-body">Eigene Absenderadresse</div>
        </div>

        <!--********** Help Indicator **********-->
        <mat-icon
            class="help-icon medium-icon outlined"
            matTooltip="Versende Gutachten und andere Mails mit deiner eigenen Mailadresse direkt aus {{
                productName
            }}.{{ '\n\n' }}Ohne Zugangsdaten versendest du Mails mit einer unserer Mailadressen.">
            help
        </mat-icon>

        <!--********** Done Marker **********-->
        <div class="setup-done-marker" matTooltip="Erledigt">
            <mat-icon>check</mat-icon>
        </div>
    </div>
    <!--============================================-->
    <!-- END Sender Email -->
    <!--============================================-->

    <!--============================================-->
    <!-- Report Token & Invoice Number Pattern -->
    <!--============================================-->
    <div
        class="setup-item"
        [ngClass]="{ done: team.userInterfaceStates.setupSteps.reportTokenAndInvoiceNumberPattern }"
        (click)="navigate('/Einstellungen#report-token-container')">
        <!--********** Icon **********-->
        <div class="setup-icon-container">
            <mat-icon class="setup-icon outlined">123</mat-icon>
        </div>

        <!--********** Text **********-->
        <div class="setup-item-text-container">
            <div class="setup-item-heading">Nummernkreise</div>
            <div class="setup-item-body">Aktenzeichen & Rechnungen</div>
        </div>

        <!--********** Help Indicator **********-->
        <mat-icon
            class="help-icon medium-icon outlined"
            matTooltip="Nutze unser Muster für Aktenzeichen & Rechnungsnummern oder konfiguriere dir dein eigenes Muster, z. B. mit Platzhaltern für Jahr, Monat, Gutachtentyp und vielem mehr.">
            help
        </mat-icon>

        <!--********** Done Marker **********-->
        <div class="setup-done-marker" matTooltip="Erledigt">
            <mat-icon>check</mat-icon>
        </div>

        <!--********** Optional Marker **********-->
        <div
            class="optional-marker"
            matTooltip="Du kannst gerne mit unseren Standardmustern arbeiten. Du kannst die Muster auch später anpassen.">
            optional
        </div>
    </div>
    <!--============================================-->
    <!-- END Report Token & Invoice Number Pattern -->
    <!--============================================-->

    <!--============================================-->
    <!-- Declaration of Assignment -->
    <!--============================================-->
    <div
        class="setup-item"
        [ngClass]="{ done: team.userInterfaceStates.setupSteps.ownDeclarationOfAssignment }"
        (click)="navigate('/Einstellungen#signable-pdf-documents-container')">
        <!--********** Icon **********-->
        <div class="setup-icon-container">
            <mat-icon class="setup-icon outlined">event_list</mat-icon>
        </div>

        <!--********** Text **********-->
        <div class="setup-item-text-container">
            <div class="setup-item-heading">Abtretung & Co.</div>
            <div class="setup-item-body">Eigene PDF hochladen</div>
        </div>

        <!--********** Help Indicator **********-->
        <mat-icon
            class="help-icon medium-icon outlined"
            matTooltip="Du kannst deinen Kunden unsere Standard-Abtretung zur Unterschrift vorlegen oder deine eigene PDF-Datei hochladen, in die wir pro Gutachten dynamische Werte wie den Anspruchstellernamen drucken.">
            help
        </mat-icon>

        <!--********** Done Marker **********-->
        <div class="setup-done-marker" matTooltip="Erledigt">
            <mat-icon>check</mat-icon>
        </div>

        <!--********** Optional Marker **********-->
        <div
            class="optional-marker"
            matTooltip="Du kannst auch mit unserer Standardabtretung arbeiten oder später eine eigene Abtretung als PDF hochladen.">
            optional
        </div>
    </div>
    <!--============================================-->
    <!-- END Declaration of Assignment -->
    <!--============================================-->

    <!--============================================-->
    <!-- Imports -->
    <!--============================================-->
    <div
        class="setup-item"
        [ngClass]="{ done: team.userInterfaceStates.setupSteps.contactsOrLegacyReportsImported }"
        (click)="
            openInNewWindow(
                'https://wissen.autoixpert.de/hc/de/articles/21692000467730-Datenimport-Kompatibilit%C3%A4t-mit-Mitbewerbersystemen'
            )
        ">
        <!--********** Icon **********-->
        <div class="setup-icon-container">
            <mat-icon class="setup-icon outlined">upload</mat-icon>
        </div>

        <!--********** Text **********-->
        <div class="setup-item-text-container">
            <div class="setup-item-heading">Importe</div>
            <div class="setup-item-body">Kontakte, Kennz.-Prüfdatei</div>
        </div>

        <!--********** Help Indicator **********-->
        <mat-icon
            class="help-icon medium-icon outlined"
            matTooltip="Falls du vorher eine andere Gutachter-Software genutzt hast, kannst du Kontakte & die Kennzeichenprüfdatei importieren, die es {{
                productName
            }} erlaubt, dich zu warnen, wenn du ein Fahrzeug früher schon einmal besichtigt hast.">
            help
        </mat-icon>

        <!--********** Done Marker **********-->
        <div class="setup-done-marker" matTooltip="Erledigt">
            <mat-icon>check</mat-icon>
        </div>

        <!--********** Optional Marker **********-->
        <div
            class="optional-marker"
            matTooltip="Dieser Schritt muss nicht unbedingt eingerichtet werden. Das kannst du auch später nachholen.">
            optional
        </div>
    </div>
    <!--============================================-->
    <!-- END Imports -->
    <!--============================================-->
</div>
<!--============================================-->
<!-- END Content -->
<!--============================================-->

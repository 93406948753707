import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { GoogleMapsPlaceAddress } from '@autoixpert/external-apis/google-maps/places-response';

@Injectable({
    providedIn: 'root',
})
export class GoogleMapsGeocodeService {
    constructor(private httpClient: HttpClient) {}

    private apiPath = '/api/v0/geocode';

    public async convertGPSCoordinatesToAddress({
        latitude,
        longitude,
    }: {
        latitude: number;
        longitude: number;
    }): Promise<GoogleMapsPlaceAddress> {
        const queryParameters = new URLSearchParams();
        queryParameters.append('latitude', `${latitude}`);
        queryParameters.append('longitude', `${longitude}`);
        return await this.httpClient
            .get<GoogleMapsPlaceAddress>(`${this.apiPath}?${queryParameters.toString()}`)
            .toPromise();
    }
}

/**
 * Useful when trying for matching array entries from a large array. Especially when this matching happens
 * in a loop since accessing a map is faster than iterating over the entire array for every match.
 */
export function getMapFromRecords<Record extends ObjectWithId>(records: Record[]): Map<Record['_id'], Record> {
    const recordMap = new Map<Record['_id'], Record>();

    if (!records?.length) {
        return recordMap;
    }

    for (const record of records) {
        recordMap.set(record._id, record);
    }

    return recordMap;
}

type ObjectWithId = { _id: string };

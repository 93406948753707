<div id="layout-container" *ngIf="invoice">
    <!--============================================-->
    <!-- Invoice Page -->
    <!--============================================-->
    <div id="invoice-page-container" class="card card-with-padding">
        <div id="invoice-page-toolbar" class="card-toolbar">
            <mat-icon
                class="toolbar-icon"
                (click)="showInvoiceTemplateTitleDialog()"
                *ngIf="!invoiceTemplatesShown"
                matTooltip="Rechnung als Vorlage speichern">
                playlist_add_check
            </mat-icon>
            <mat-icon class="toolbar-icon" [matMenuTriggerFor]="invoiceMenu">more_vert</mat-icon>
            <mat-menu #invoiceMenu="matMenu">
                <!--********** Show Templates **********-->
                <a
                    mat-menu-item
                    class="menu-item-with-icon"
                    *ngIf="!invoiceTemplatesShown && !invoice.lockedAt"
                    (click)="showInvoiceTemplates()">
                    <mat-icon>list_alt</mat-icon>
                    Vorlagen anzeigen
                </a>

                <!--********** Invoice Preview **********-->
                <a
                    mat-menu-item
                    class="menu-item-with-icon"
                    (click)="downloadInvoicePreview()"
                    [disabled]="invoiceDownloadPending">
                    <mat-icon>visibility</mat-icon>
                    Vorschau
                </a>
            </mat-menu>
        </div>
        <h2 id="invoice-page-heading">
            {{ getInvoiceTypeGerman() }}
        </h2>
        <section id="invoice-head-section">
            <!--============================================-->
            <!-- Recipient -->
            <!--============================================-->
            <recipient-selector
                [contactPerson]="invoice.recipient.contactPerson"
                (contactPersonChange)="insertContactPersonIntoInvoice($event)"
                [autocompleteGroupNames]="['claimant', 'intermediary', 'insurance', 'garage', 'lawyer']"
                [hideIban]="true"
                [disabled]="!!invoice.lockedAt"></recipient-selector>
            <!--============================================-->
            <!-- END Recipient -->
            <!--============================================-->
            <!--============================================-->
            <!-- Invoice Dates -->
            <!--============================================-->
            <div id="invoice-dates-container">
                <!--********** Invoice Date **********-->
                <div
                    id="invoice-and-delivery-date-container"
                    [class.flex-direction-column]="invoice.isCollectiveInvoice">
                    <div class="date-and-assessor-container">
                        <date-input
                            id="invoice-date"
                            placeholder="Rechnungsdatum"
                            [(date)]="invoice.date"
                            (dateChange)="determineVatRate(); updateDueDate(); saveInvoice()"
                            [disabled]="isInvoiceLocked()"></date-input>

                        <!--********** Regular invoices have a date of supply, collective invoices display the period of supply in the next row **********-->
                        <date-input
                            *ngIf="!invoice.isCollectiveInvoice"
                            [placeholder]="'Leistungsdatum'"
                            [(date)]="invoice.dateOfSupply"
                            (dateChange)="determineVatRate(); saveInvoice()"
                            [disabled]="isInvoiceLocked()"
                            matTooltip="Falls leer, ist Leistungsdatum gleich Rechnungsdatum."></date-input>

                        <!--********** Assessor selection only for collective invoices **********-->
                        <mat-form-field
                            id="responsible-assessor-form-field"
                            class="mat-block"
                            *ngIf="invoice.isCollectiveInvoice">
                            <mat-select
                                placeholder="Sachverständiger"
                                [(ngModel)]="invoice.associatedAssessorId"
                                (selectionChange)="saveInvoice()"
                                [disabled]="isInvoiceLocked()"
                                matTooltip="Für die Sammelrechnung verantwortlicher Gutachter. Relevant für Umsatzauswertungen, da diesem Gutachter alle Umsätze von Sammelrechnungen zugeordnet werden.">
                                <mat-select-trigger>
                                    <ng-container *ngIf="getUsersFullName(invoice.associatedAssessorId)">
                                        {{ getUsersFullName(invoice.associatedAssessorId) }}
                                    </ng-container>
                                    <ng-container *ngIf="getDeactivatedUsersFullName(invoice.associatedAssessorId)">
                                        {{ getDeactivatedUsersFullName(invoice.associatedAssessorId) }} (deaktiviert)
                                    </ng-container>
                                    <ng-container
                                        *ngIf="
                                            !getUsersFullName(invoice.associatedAssessorId) &&
                                            !getDeactivatedUsersFullName(invoice.associatedAssessorId)
                                        ">
                                        Ehemaliger Nutzer ({{ invoice.associatedAssessorId }})
                                    </ng-container>
                                </mat-select-trigger>
                                <mat-option *ngFor="let assessor of assessors" [value]="assessor._id">
                                    <div
                                        class="autocomplete-option-wrapper autocomplete-option-wrapper-with-user-avatar">
                                        <user-avatar [userId]="assessor._id"></user-avatar>
                                        <span>{{ getUsersFullName(assessor._id) }}</span>
                                    </div>
                                </mat-option>
                                <!--********** If the assessor ID is a deactivated user **********-->
                                <mat-option
                                    *ngIf="
                                        invoice.associatedAssessorId &&
                                        getDeactivatedUsersFullName(invoice.associatedAssessorId)
                                    "
                                    [value]="invoice.associatedAssessorId"
                                    disabled>
                                    {{ getDeactivatedUsersFullName(invoice.associatedAssessorId) }} (deaktiviert)
                                </mat-option>

                                <!--********** If the assessor ID does not match a current user (deleted user) **********-->
                                <mat-option
                                    *ngIf="
                                        invoice.associatedAssessorId &&
                                        !getUsersFullName(invoice.associatedAssessorId) &&
                                        !getDeactivatedUsersFullName(invoice.associatedAssessorId)
                                    "
                                    [value]="invoice.associatedAssessorId"
                                    disabled>
                                    Ehemaliger Nutzer ({{ invoice.associatedAssessorId }})
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>

                    <div *ngIf="invoice.isCollectiveInvoice" class="date-of-supply-container">
                        <date-range-picker
                            placeholderDateFrom="Leistungszeitraum (von)"
                            [(dateFrom)]="invoice.supplyPeriodStart"
                            [(dateTo)]="invoice.dateOfSupply"
                            [stretch]="true"></date-range-picker>
                    </div>
                </div>

                <div id="invoice-number-and-payment-period-container">
                    <!--********** Invoice Number **********-->
                    <div id="invoice-number-container">
                        <mat-form-field class="mat-block">
                            <input
                                matInput
                                placeholder="Nummer"
                                [(ngModel)]="invoice.number"
                                (focus)="lastInvoiceNumber = invoice.number"
                                (change)="handleInvoiceNumberChange()"
                                [disabled]="isInvoiceLocked()" />
                        </mat-form-field>
                        <mat-icon
                            id="generate-invoice-number-icon"
                            (click)="getInvoiceNumber()"
                            *ngIf="!isInvoiceLocked() && !invoiceNumberGenerationPending"
                            matTooltip="Rechnungsnummer generieren">
                            file_download
                        </mat-icon>
                        <img
                            id="generate-invoice-number-pending-indicator"
                            src="/assets/images/icons/loading-indicator.svg"
                            alt=""
                            *ngIf="invoiceNumberGenerationPending" />
                    </div>

                    <!--********** Due Date **********-->
                    <mat-form-field>
                        <input
                            matInput
                            placeholder="Zahlungsziel (Tage)"
                            [(ngModel)]="invoice.daysUntilDue"
                            (change)="updateDueDate(); saveInvoice()"
                            [disabled]="isInvoiceLocked()" />
                        <div matSuffix id="due-date" *ngIf="invoice.dueDate" matTooltip="Berechnetes Zahlungsziel">
                            {{ invoice.dueDate | moment: 'DD.MM.YY' }}
                        </div>
                    </mat-form-field>
                </div>
                <!--============================================-->
                <!-- END Invoice Dates -->
                <!--============================================-->

                <div id="office-location-and-electronic-invoice-container">
                    <!--============================================-->
                    <!-- Office Location -->
                    <!--============================================-->
                    <mat-form-field
                        id="office-location-form-field"
                        class="mat-block"
                        *ngIf="
                            team && (team.officeLocations?.length > 1 || !getOfficeLocation(invoice.officeLocationId))
                        ">
                        <mat-select
                            placeholder="Standort"
                            [(ngModel)]="invoice.officeLocationId"
                            (selectionChange)="saveInvoice()"
                            [disabled]="isInvoiceLocked()"
                            matTooltip="Dieser Standort bestimmt, mit welchen Adressdaten die Platzhalter in deiner Kopf- & Fußzeile ersetzt werden.">
                            <mat-select-trigger>
                                <ng-container *ngIf="getOfficeLocation(invoice.officeLocationId)">
                                    {{ getOfficeLocation(invoice.officeLocationId).title }}
                                </ng-container>
                                <ng-container *ngIf="!getOfficeLocation(invoice.officeLocationId)">
                                    Ehemaliger Standort ({{ invoice.officeLocationId }})
                                </ng-container>
                            </mat-select-trigger>

                            <mat-option
                                *ngFor="let officeLocation of team.officeLocations"
                                [value]="officeLocation._id"
                                class="option-with-label">
                                <div class="autocomplete-option-wrapper">
                                    <!--********** Internal Title & Address **********-->
                                    <div>
                                        <div>{{ officeLocation.title }}</div>
                                        <div class="label">
                                            {{ officeLocation.streetAndHouseNumber }} - {{ officeLocation.zip }}
                                            {{ officeLocation.city }}
                                        </div>
                                    </div>
                                </div>
                            </mat-option>
                            <!--********** If the selected office location ID does not match an office location **********-->
                            <mat-option
                                *ngIf="invoice.officeLocationId && !getOfficeLocation(invoice.officeLocationId)"
                                [value]="invoice.officeLocationId"
                                [disabled]="true">
                                Ehemaliger Standort ({{ invoice.officeLocationId }})
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                    <!--============================================-->
                    <!-- END Office Location -->
                    <!--============================================-->
                    <!--***********************************************
                    ** Electronic Invoice
                    ************************************************-->
                    <electronic-invoice-toggle
                        [disabled]="isInvoiceLocked()"
                        [invoiceDate]="invoice.date"
                        [(isElectronicInvoiceEnabled)]="invoice.isElectronicInvoiceEnabled"
                        [(isElectronicInvoiceStatusFrozen)]="invoice.isElectronicInvoiceStatusFrozen"
                        (isElectronicInvoiceEnabledChange)="saveInvoice()"
                        (isElectronicInvoiceStatusFrozenChange)="saveInvoice()"
                        [invoiceRecipientContactPerson]="invoice.recipient.contactPerson"></electronic-invoice-toggle>
                    <!--***********************************************
                    ** END Electronic Invoice
                    ************************************************-->
                </div>
            </div>
        </section>

        <!--============================================-->
        <!-- Cancellation Marker -->
        <!--============================================-->
        <div
            class="cancellation-marker"
            *ngIf="invoice.isFullyCanceled"
            matTooltip="Diese Rechnung wurde vollständig storniert. Um das zu ändern, muss die Stornorechnung gelöscht werden.">
            Storniert
        </div>

        <!--********** Partially Canceled **********-->
        <div
            class="cancellation-marker"
            *ngIf="!invoice.isFullyCanceled && invoice.idsOfCancellationInvoices.length"
            matTooltip="Diese Rechnung wurde teilweise storniert. Um das zu ändern, müssen die Gutschriften gelöscht werden.">
            Teilstorniert
        </div>
        <!--============================================-->
        <!-- END Cancellation Marker -->
        <!--============================================-->

        <!--============================================-->
        <!-- Invoice Templates -->
        <!--============================================-->
        <ng-container *ngIf="invoiceTemplatesShown">
            <section id="invoice-templates-section">
                <h3>Vorlagen</h3>
                <record-template-selector
                    [templates]="invoiceTemplates"
                    [detailTextGetter]="getInvoiceTemplateTotal"
                    [deleteAllowed]="true"
                    (templateUsage)="useInvoiceTemplate($event); saveInvoice()"
                    (templateDeletion)="deleteInvoiceTemplate($event)"
                    (noTemplateUsage)="hideInvoiceTemplates()"></record-template-selector>
            </section>
        </ng-container>
        <!--============================================-->
        <!-- END Invoice Templates -->
        <!--============================================-->

        <!--============================================-->
        <!-- Line Items -->
        <!--============================================-->
        <ng-container *ngIf="!invoiceTemplatesShown">
            <mat-form-field id="intro-text" class="mat-block">
                <mat-quill
                    placeholder="Vortext"
                    [(ngModel)]="invoice.intro"
                    (change)="saveInvoice()"
                    [disabled]="isInvoiceLocked()"></mat-quill>
                <div matSuffix *ngIf="!isInvoiceLocked()">
                    <mat-icon
                        class="input-toolbar-icon"
                        matTooltip="Textvorlagen"
                        (click)="showInvoiceIntroTextTemplates = true">
                        playlist_add
                    </mat-icon>
                </div>
            </mat-form-field>
            <text-template-selector
                [(text)]="invoice.intro"
                (textChange)="saveInvoice()"
                [report]="connectedReports[0]"
                *ngIf="showInvoiceIntroTextTemplates"
                placeholderForResultingText="Vortext"
                (close)="showInvoiceIntroTextTemplates = false"
                [textTemplateType]="'invoiceIntro'"
                @runChildAnimations></text-template-selector>
            <section id="invoice-item-list" (change)="saveInvoice()">
                <div id="invoice-item-list-header">
                    <div class="invoice-item-description">Beschreibung</div>
                    <div class="invoice-item-second-row-mobile">
                        <div class="invoice-item-quantity-and-unit">#</div>
                        <div class="invoice-item-unit-price">Einzelpreis</div>
                        <div class="invoice-item-total-price">Gesamtpreis</div>
                    </div>
                </div>
                <div
                    id="invoice-items-container"
                    cdkDropList
                    (cdkDropListDropped)="reorderInvoiceLineItemsArray($event)">
                    <div class="invoice-item" *ngFor="let lineItem of invoice.lineItems; let index = index" cdkDrag>
                        <mat-icon
                            class="invoice-item-grab-handle"
                            matTooltip="Position verschieben"
                            *ngIf="!isInvoiceLocked()"
                            cdkDragHandle>
                            drag_indicator
                        </mat-icon>

                        <invoice-line-item-template-selector
                            class="invoice-item-description"
                            #descriptionInput
                            [invoiceLineItemTemplates]="invoiceLineItemTemplates"
                            (change)="saveInvoice()"
                            [disabled]="isInvoiceLocked()"
                            (invoiceLinItemTemplateSelected)="
                                insertInvoiceLineItemTemplate(lineItem, $event); calculateTotal()
                            "
                            [(ngModel)]="lineItem.description"></invoice-line-item-template-selector>
                        <div class="invoice-item-second-row-mobile">
                            <mat-form-field class="invoice-item-quantity" floatLabel="never">
                                <input
                                    matInput
                                    placeholder="Menge"
                                    number-input
                                    [(number)]="lineItem.quantity"
                                    [disabled]="isInvoiceLocked()"
                                    name="invoice-quantity"
                                    (change)="
                                        calculateTotal(); insertLineItemUnitBasedOnQuantity(lineItem, lineItem.unit)
                                    "
                                    [minimumFractionDigits]="0" />
                            </mat-form-field>
                            <mat-form-field class="invoice-item-unit" floatLabel="never">
                                <input
                                    matInput
                                    placeholder="Einheit"
                                    [(ngModel)]="lineItem.unit"
                                    [disabled]="isInvoiceLocked()"
                                    name="invoice-unit"
                                    (change)="calculateTotal()"
                                    (ngModelChange)="filterLineItemAutocomplete($event)"
                                    (focus)="filterLineItemAutocomplete('')"
                                    [matAutocomplete]="invoiceLineItemUnitAutocomplete" />
                                <mat-autocomplete
                                    #invoiceLineItemUnitAutocomplete
                                    (optionSelected)="
                                        insertLineItemUnitBasedOnQuantity(lineItem, $event.option.viewValue);
                                        saveInvoice()
                                    ">
                                    <mat-option
                                        value=""
                                        *ngFor="let filteredLineItemUnit of filteredLineItemUnits"
                                        (mousedown)="$event.preventDefault()">
                                        {{ filteredLineItemUnit.label }}
                                    </mat-option>
                                </mat-autocomplete>
                            </mat-form-field>
                            <div class="invoice-item-unit-price">
                                <currency-input
                                    placeholder="Einzelpreis"
                                    floatLabel="never"
                                    [(value)]="lineItem.unitPrice"
                                    (valueChange)="calculateTotal()"
                                    [disabled]="isInvoiceLocked()"
                                    (keydown.tab)="
                                        index === invoice.lineItems.length - 1 ? addLineItem() : null
                                    "></currency-input>
                            </div>
                            <div class="invoice-item-total-price">
                                <currency-input
                                    totalPriceReadonlyHint
                                    placeholder="Gesamtpreis"
                                    floatLabel="never"
                                    [readonly]="true"
                                    [tabindex]="-1"
                                    [value]="lineItem.quantity * lineItem.unitPrice || 0"></currency-input>
                            </div>

                            <mat-icon class="invoice-item-submenu-icon" [matMenuTriggerFor]="feePositionSubMenu">
                                more_vert
                            </mat-icon>

                            <mat-menu #feePositionSubMenu="matMenu">
                                <a
                                    mat-menu-item
                                    *ngIf="!lineItem.lineItemTemplateId"
                                    [ngClass]="{ disabled: !user?.accessRights.editTextsAndDocumentBuildingBlocks }"
                                    class="menu-item-with-icon"
                                    (click)="saveInvoiceLineItemTemplate(lineItem)"
                                    [matTooltip]="
                                        user?.accessRights.editTextsAndDocumentBuildingBlocks
                                            ? 'Rechnungsposition als Vorlage speichern'
                                            : 'Das Speichern von Rechnungspositionen als Vorlagen erfordert die Berechtigung, Texte und Textbausteine zu editieren.'
                                    ">
                                    <mat-icon class="outlined">bookmark</mat-icon>
                                    Als Vorlage speichern
                                </a>
                                <a
                                    mat-menu-item
                                    *ngIf="lineItem.lineItemTemplateId"
                                    [ngClass]="{ disabled: !user?.accessRights.editTextsAndDocumentBuildingBlocks }"
                                    class="menu-item-with-icon"
                                    (click)="updateInvoiceLineItemTemplate(lineItem)"
                                    [matTooltip]="
                                        user?.accessRights.editTextsAndDocumentBuildingBlocks
                                            ? 'Rechnungsposition als Vorlage speichern'
                                            : 'Das Speichern von Rechnungspositionen als Vorlagen erfordert die Berechtigung, Texte und Textbausteine zu editieren.'
                                    ">
                                    <mat-icon class="outlined">bookmark</mat-icon>
                                    Vorlage aktualisieren
                                </a>
                                <a
                                    mat-menu-item
                                    class="menu-item-with-icon"
                                    matTooltip="Position entfernen"
                                    (click)="removeInvoiceLineItem(lineItem)"
                                    *ngIf="!isInvoiceLocked()">
                                    <mat-icon class="outlined">delete</mat-icon>
                                    Löschen
                                </a>
                            </mat-menu>
                        </div>
                    </div>
                    <div id="new-invoice-item-button-container">
                        <button class="flat" (click)="addLineItem()" *ngIf="!isInvoiceLocked()">+ Neue Position</button>
                    </div>
                </div>
            </section>
            <section id="invoice-total-section">
                <div class="label">Rechnungsbetrag</div>
                <div id="invoice-total-net">
                    {{ invoice.totalNet || 0 | number: '1.2-2' }} €
                    <mat-icon id="invoice-total-submenu" class="toolbar-icon" [matMenuTriggerFor]="vatRateMenu">
                        more_vert
                    </mat-icon>
                    <mat-menu #vatRateMenu>
                        <a
                            mat-menu-item
                            [mat-menu-trigger-for]="zeroPercentVatSubMenu"
                            [ngClass]="{ 'color-blue': invoice.vatRate === 0 }">
                            0 % MwSt. - {{ translateVatExemptionReason(invoice.vatExemptionReason) }}
                            <mat-menu #zeroPercentVatSubMenu>
                                <a
                                    mat-menu-item
                                    [ngClass]="{
                                        'color-blue': invoice.vatExemptionReason === 'smallBusiness',
                                    }"
                                    (click)="setVatRate(0, 'smallBusiness'); saveInvoice()"
                                    matTooltip="Falls du beim Finanzamt als Kleinunternehmer geführt wirst, wähle diese Option. Kleinunternehmer haben eine vereinfachte Steuer, können aber keine Vorsteuer geltend machen.">
                                    Kleinunternehmer
                                </a>
                                <a
                                    mat-menu-item
                                    [ngClass]="{
                                        'color-blue': invoice.vatExemptionReason === 'reverseCharge',
                                    }"
                                    (click)="setVatRate(0, 'reverseCharge'); saveInvoice()"
                                    matTooltip="Wenn du Rechnungen an Unternehmen im EU-Ausland schreibst, kann du das Reverse-Charge-Verfahren anwenden. Dadurch führt der Rechnungsempfänger die MwSt. nach seinen nationalen Vorgaben ab.">
                                    Reverse Charge
                                </a>
                                <a
                                    mat-menu-item
                                    [ngClass]="{
                                        'color-blue': invoice.vatExemptionReason === 'companyInternalInvoice',
                                    }"
                                    (click)="setVatRate(0, 'companyInternalInvoice'); saveInvoice()"
                                    matTooltip="Wenn du Rechnungen an verbundene Unternehmen schreibst, die z. B. Teil eures Konzerns sind, muss keine MwSt. berechnet werden.">
                                    Innenumsatz Organschaft
                                </a>
                            </mat-menu>
                        </a>
                        <a
                            mat-menu-item
                            (click)="setVatRate(0.19); calculateTotal(); saveInvoice()"
                            [ngClass]="{ 'color-blue': invoice.vatRate === 0.19 }">
                            19 % MwSt. - Regelsatz Deutschland
                        </a>
                        <a
                            mat-menu-item
                            (click)="setVatRate(0.2); calculateTotal(); saveInvoice()"
                            [ngClass]="{ 'color-blue': invoice.vatRate === 0.2 }">
                            20 % MwSt. - Regelsatz Österreich
                        </a>
                    </mat-menu>
                </div>
                <div id="invoice-total-gross" class="label">{{ invoice.totalGross || 0 | number: '1.2-2' }} €</div>

                <!--***********************************************
                ** Note About Missing 0% VAT Reason
                ************************************************-->
                <div class="info-note" *ngIf="invoice.vatRate === 0 && !invoice.vatExemptionReason">
                    <div class="info-note-text">
                        Grund für 0 % MwSt. muss angegeben werden.
                        <span
                            class="help-indicator"
                            matTooltip="Derzeit ist noch kein Grund für die fehlende MwSt. angegeben. Bitte wähle den Grund aus, um eine gültige E-Rechnung schreiben zu können.">
                            ?
                        </span>
                        <div class="info-note-buttons-row">
                            <div
                                class="info-note-button-primary"
                                (click)="setVatRate(0, 'smallBusiness'); saveInvoice()"
                                matTooltip="Falls du beim Finanzamt als Kleinunternehmer geführt wirst, wähle diese Option. Kleinunternehmer haben eine vereinfachte Steuer, können aber keine Vorsteuer geltend machen.">
                                Kleinunternehmer
                            </div>
                            <div
                                class="info-note-button-primary"
                                (click)="setVatRate(0, 'reverseCharge'); saveInvoice()"
                                matTooltip="Wenn du Rechnungen an Unternehmen im EU-Ausland schreibst, kann du das Reverse-Charge-Verfahren anwenden. Dadurch führt der Rechnungsempfänger die MwSt. nach seinen nationalen Vorgaben ab.">
                                Reverse Charge
                            </div>
                            <div
                                class="info-note-button-primary"
                                (click)="setVatRate(0, 'companyInternalInvoice'); saveInvoice()"
                                matTooltip="Wenn du Rechnungen an verbundene Unternehmen schreibst, die z. B. Teil eures Konzerns sind, muss keine MwSt. berechnet werden.">
                                Innenumsatz Organschaft
                            </div>
                        </div>
                    </div>
                </div>
                <!--***********************************************
                ** END Note About Missing 0% VAT Reason
                ************************************************-->
            </section>
        </ng-container>
        <!--============================================-->
        <!-- END Line Items -->
        <!--============================================-->
    </div>
    <!--============================================-->
    <!-- END Invoice Page -->
    <!--============================================-->

    <!--============================================-->
    <!-- Status -->
    <!--============================================-->
    <div id="status-column">
        <div id="status-card" class="card card-with-padding">
            <h2 id="status-heading">Status</h2>

            <!--********** Payment Status **********-->
            <div id="payment-status-and-unpaid-amount-container">
                <div id="payment-status-container">
                    <payment-status [invoice]="invoice" (statusClick)="openPaymentsDialog()"></payment-status>
                    <quick-payment
                        *ngIf="unpaidAmount > 0"
                        [invoice]="invoice"
                        (remainderPayment)="saveInvoice()"></quick-payment>
                </div>
                <div id="open-amount-container" (click)="openPaymentsDialog()">
                    <div class="label">Offen</div>
                    <div
                        id="open-amount"
                        [ngClass]="{
                            'no-payment': unpaidAmount === invoice.totalGross,
                            'partial-payment': unpaidAmount > 0,
                        }">
                        {{ unpaidAmount | number: '1.2-2' }}
                        €
                    </div>
                </div>
            </div>

            <div id="status-buttons-container">
                <button
                    (click)="lockInvoice()"
                    [ngClass]="{ disabled: !isLockingAllowed() }"
                    *ngIf="!invoice.lockedAt"
                    [matTooltip]="getBookingTooltip()">
                    Festschreiben
                </button>
                <button (click)="openPaymentsDialog()" *ngIf="invoice.lockedAt">Zahlungen</button>
                <button
                    class="secondary filled"
                    *ngIf="!isCancellationInvoiceOrCancelled(invoice)"
                    (click)="openPaymentReminderDialog()">
                    Mahnen
                </button>
                <!--TODO Erstmal auskommentiert, weil unsicher ist, ob ADELTA diese Rechnungen überhaupt ankauft. Es gibt noch keine Schnittstelle von Steffens Seite. -->
                <!--<button id="adelta-button" class="secondary">ADELTA.FINANZ</button>-->
            </div>
            <div id="invoice-booked-notice" class="label" *ngIf="invoice.lockedAt">
                <span matTooltip="Abgeschlossen: {{ invoice.lockedAt | moment: 'DD.MM.YY - HH:mm [Uhr]' }}">
                    {{ getInvoiceLockedMessage() }}
                </span>
                <a
                    href="javascript:void(0)"
                    (click)="unbookInvoice()"
                    *ngIf="isUnbookingAllowed()"
                    matTooltip="Das Festschreiben kann innerhalb von 24 Stunden durch den festschreibenden Nutzer widerrufen werden">
                    Bearbeitung aktivieren
                </a>
            </div>
        </div>

        <!--============================================-->
        <!-- Credit Notes / Cancellation Invoices -->
        <!--============================================-->
        <div class="associated-invoices-container card dark-card" *ngIf="invoice.idsOfCancellationInvoices?.length">
            <h2>
                Gutschriften
                <span
                    class="help-indicator"
                    matTooltip="Diese Gutschriften stornieren Teile oder den Gesamtbetrag dieser Rechnung">
                    ?
                </span>
            </h2>
            <associated-invoice-list-item
                *ngFor="let associatedInvoiceId of invoice.idsOfCancellationInvoices"
                [invoiceId]="associatedInvoiceId"></associated-invoice-list-item>
        </div>
        <!--============================================-->
        <!-- END Credit Notes / Cancellation Invoices -->
        <!--============================================-->

        <!--============================================-->
        <!-- Canceled Invoices -->
        <!--============================================-->
        <div class="associated-invoices-container card dark-card" *ngIf="invoice.rootInvoiceId">
            <h2>
                Stornierte Rechnung
                <span
                    class="help-indicator"
                    matTooltip="Die folgende Rechnung wurde mit dieser Rechnung vollständig storniert.">
                    ?
                </span>
            </h2>
            <associated-invoice-list-item [invoiceId]="invoice.rootInvoiceId"></associated-invoice-list-item>
        </div>
        <!--============================================-->
        <!-- END Canceled Invoices -->
        <!--============================================-->

        <!--============================================-->
        <!-- Connected Report -->
        <!--============================================-->
        <div id="connected-report-card" class="card dark-card card-with-padding">
            <!--============================================-->
            <!-- Report Search Field -->
            <!--============================================-->
            <ng-container *ngIf="reportConnectionInputShown">
                <h2>Gutachten verknüpfen</h2>

                <mat-form-field class="mat-block search-field light-mode">
                    <mat-icon
                        matPrefix
                        id="report-search-icon"
                        matTooltip="Suche in Aktenzeichen, Firmen-, Vor- & Nachname sowie Nummernschild. Suche beginnt ab drei Zeichen">
                        search
                    </mat-icon>
                    <input
                        matInput
                        placeholder="Gutachten suchen"
                        [(ngModel)]="reportSearchTerm"
                        (ngModelChange)="updateReportSearchTerm($event); filterReportsAutocomplete()"
                        name="report-token"
                        [matAutocomplete]="reportSearchAutocomplete"
                        (focus)="filterReportsAutocomplete()" />
                    <img
                        matSuffix
                        src="/assets/images/icons/loading-indicator.svg"
                        alt=""
                        id="report-search-pending-indicator"
                        *ngIf="reportSearchPending"
                        matTooltip="Suche..." />
                </mat-form-field>

                <!--============================================-->
                <!-- Autocomplete -->
                <!--============================================-->
                <mat-autocomplete #reportSearchAutocomplete>
                    <mat-option
                        *ngFor="let previousReport of filteredReports"
                        class="previous-report-option"
                        (mousedown)="$event.preventDefault()"
                        (onSelectionChange)="connectReport(previousReport); saveInvoice()">
                        <div class="previous-report-option-inner-wrapper">
                            <div
                                class="previous-report-manufacturer-icon-container"
                                (click)="navigateToReport(previousReport)"
                                *ngIf="
                                    previousReport.car.make && iconForCarBrandExists(previousReport.car.make);
                                    else carMakeIconPlaceholder
                                ">
                                <img
                                    [src]="iconFilePathForCarBrand(previousReport.car.make, 'large')"
                                    alt=""
                                    class="car-manufacturer-logo"
                                    [matTooltip]="previousReport.car.make + ' ' + previousReport.car.model" />
                            </div>
                            <ng-template #carMakeIconPlaceholder>
                                <div class="car-make-icon-placeholder"></div>
                            </ng-template>
                            <div class="previous-report-name-and-date-container">
                                <div
                                    *ngIf="previousReport.claimant.contactPerson.organization"
                                    class="previous-report-name-container"
                                    [matTooltip]="previousReport.claimant.contactPerson.organization">
                                    {{ previousReport.claimant.contactPerson.organization }}
                                </div>
                                <div
                                    *ngIf="!previousReport.claimant.contactPerson.organization"
                                    class="previous-report-name-container"
                                    [matTooltip]="
                                        previousReport.claimant.contactPerson.firstName +
                                        ' ' +
                                        previousReport.claimant.contactPerson.lastName
                                    ">
                                    {{ previousReport.claimant.contactPerson.firstName }}
                                    {{ previousReport.claimant.contactPerson.lastName }}
                                </div>
                                <div class="label">
                                    <span
                                        *ngIf="previousReport.token"
                                        [matTooltip]="'Aktenzeichen ' + previousReport.token">
                                        {{ previousReport.token }} -
                                    </span>
                                    <span matTooltip="Erstellt am">
                                        {{ previousReport.createdAt | moment: 'DD.MM.YY' }}
                                    </span>
                                </div>
                            </div>
                            <div class="previous-report-open-in-new-container">
                                <mat-icon
                                    class="previous-report-open-in-new"
                                    (click)="handleOpenInNewClick(previousReport._id, $event)"
                                    matTooltip="In neuem Tab öffnen">
                                    open_in_new
                                </mat-icon>
                            </div>
                        </div>
                    </mat-option>
                </mat-autocomplete>
                <!--============================================-->
                <!-- END Autocomplete -->
                <!--============================================-->
            </ng-container>
            <!--============================================-->
            <!-- END Report Search Field -->
            <!--============================================-->

            <!--============================================-->
            <!-- Report Details -->
            <!--============================================-->
            <ng-container *ngIf="invoice.reportIds?.length > 0">
                <h2>{{ invoice.reportIds?.length > 1 ? invoice.reportIds.length + ' ' : '' }}Gutachten</h2>

                <!--********** Submenu **********-->
                <div class="card-toolbar" *ngIf="!invoice.isCollectiveInvoice">
                    <mat-icon class="toolbar-icon" [matMenuTriggerFor]="reportDetailsSubmenu">more_vert</mat-icon>
                    <mat-menu #reportDetailsSubmenu>
                        <a
                            mat-menu-item
                            class="menu-item-with-icon"
                            [routerLink]="['/Gutachten', invoice.reportIds[0]]">
                            <mat-icon>visibility</mat-icon>
                            Gutachten öffnen
                        </a>
                        <mat-option (click)="cutReportConnection(); saveInvoice()" [disabled]="isInvoiceLocked()">
                            <mat-icon>link_off</mat-icon>
                            Verknüpfung aufheben
                        </mat-option>
                    </mat-menu>
                </div>

                <!-- Collective invoices might have multiple associated reports. List them here -->
                <ng-container *ngIf="invoice.reportIds?.length > 1 && connectedReports">
                    <div
                        class="report-details-list"
                        *ngFor="let connectedReport of connectedReports; trackBy: trackById">
                        <div class="report-details-item">
                            <div class="report-details-icon-container">
                                <mat-icon>fingerprint</mat-icon>
                            </div>
                            <div class="report-details-item-value-container">
                                <div class="report-details-item-value">
                                    <a
                                        class="connected-report-token-link"
                                        matTooltip="Aktenzeichen"
                                        [routerLink]="'/Gutachten/' + connectedReport._id">
                                        {{ connectedReport.token ?? 'Kein Aktenzeichen' }}
                                    </a>
                                </div>
                                <div class="report-details-item-label" matTooltip="Kennzeichen oder VIN">
                                    {{
                                        connectedReport.car.licensePlate ||
                                            connectedReport.car.vin ||
                                            'Kein Kennzeichen'
                                    }}
                                </div>
                            </div>
                        </div>
                    </div>
                </ng-container>

                <!-- Regular invoices might belong to a report. Show details about that report here. -->
                <ng-container *ngIf="invoice.reportIds?.length === 1">
                    <!--********** Report Details **********-->
                    <div class="report-details-list">
                        <!--============================================-->
                        <!-- Report Header Data -->
                        <!--============================================-->
                        <div class="report-details-item">
                            <div class="report-details-icon-container">
                                <mat-icon>fingerprint</mat-icon>
                            </div>
                            <div class="report-details-item-value-container">
                                <div class="report-details-item-value">
                                    <a
                                        class="connected-report-token-link"
                                        [routerLink]="'/Gutachten/' + invoice.reportIds[0]"
                                        *ngIf="invoice.reportsData[0]?.token">
                                        {{ invoice.reportsData[0]?.token }}
                                    </a>
                                    <span *ngIf="!invoice.reportsData[0]?.token">-</span>
                                </div>
                                <div class="report-details-item-label">Aktenzeichen</div>
                            </div>
                        </div>
                        <!--============================================-->
                        <!-- END Report Header Data -->
                        <!--============================================-->

                        <!--============================================-->
                        <!-- Claimant -->
                        <!--============================================-->
                        <div class="report-details-item" *ngIf="invoice.claimant">
                            <div class="report-details-icon-container">
                                <mat-icon>person</mat-icon>
                            </div>
                            <div class="report-details-item-value-container">
                                <div class="report-details-item-value">
                                    <span
                                        *ngIf="invoice.claimant?.contactPerson.organization"
                                        [matTooltip]="invoice.claimant.contactPerson.organization">
                                        {{ invoice.claimant.contactPerson.organization }}
                                    </span>
                                    <span
                                        *ngIf="!invoice.claimant?.contactPerson.organization"
                                        [matTooltip]="
                                            invoice.claimant.contactPerson.firstName +
                                            ' ' +
                                            invoice.claimant.contactPerson.lastName
                                        ">
                                        {{ invoice.claimant.contactPerson.firstName }}
                                        {{ invoice.claimant.contactPerson.lastName }}
                                    </span>
                                </div>
                                <div class="report-details-item-label">Anspruchsteller</div>
                            </div>
                        </div>
                        <!--============================================-->
                        <!-- END Claimant -->
                        <!--============================================-->

                        <!--============================================-->
                        <!-- License Plate -->
                        <!--============================================-->
                        <div id="report-details-license-plate-container">
                            <a [routerLink]="'/Gutachten/' + invoice.reportIds[0]">
                                <license-plate
                                    *ngIf="invoice.reportsData[0]?.licensePlate"
                                    [licensePlate]="invoice.reportsData[0]?.licensePlate"></license-plate>
                            </a>
                        </div>
                        <!--============================================-->
                        <!-- END License Plate -->
                        <!--============================================-->
                    </div>

                    <!--============================================-->
                    <!-- Insurance -->
                    <!--============================================-->
                    <div id="connected-insurance-container" *ngIf="invoice.insurance">
                        <h3 class="unfoldable-contact-heading" (click)="unfoldInsurance()">
                            Versicherung
                            <mat-icon class="unfold-icon" *ngIf="!extendInsurance">unfold_more</mat-icon>
                            <mat-icon class="unfold-icon" *ngIf="extendInsurance">unfold_less</mat-icon>
                        </h3>

                        <div *ngIf="extendInsurance" @slideInAndOutVertically>
                            <!--********** Organization **********-->
                            <div class="report-details-item">
                                <div class="report-details-icon-container">
                                    <mat-icon class="medium-icon">shield</mat-icon>
                                </div>
                                <div class="report-details-item-value-container">
                                    <div
                                        class="report-details-item-value"
                                        [matTooltip]="invoice.insurance.contactPerson.organization">
                                        <span>{{ invoice.insurance.contactPerson.organization || '-' }}</span>
                                    </div>
                                    <div class="report-details-item-label">Versicherung</div>
                                </div>
                            </div>

                            <!--********** Phone **********-->
                            <div class="report-details-item">
                                <div class="report-details-icon-container">
                                    <mat-icon class="contact-item-icon medium-icon">phone</mat-icon>
                                </div>
                                <div class="report-details-item-value-container">
                                    <div
                                        class="report-details-item-value"
                                        [matTooltip]="invoice.insurance.contactPerson.phone">
                                        <span>{{ invoice.insurance.contactPerson.phone || '-' }}</span>
                                    </div>
                                    <div class="report-details-item-label">Telefonnummer</div>
                                </div>
                            </div>

                            <!--********** Email **********-->
                            <div class="report-details-item">
                                <div class="report-details-icon-container">
                                    <mat-icon class="contact-item-icon medium-icon">email</mat-icon>
                                </div>
                                <div class="report-details-item-value-container">
                                    <div
                                        class="report-details-item-value"
                                        [matTooltip]="invoice.insurance.contactPerson.email">
                                        <span>{{ invoice.insurance.contactPerson.email || '-' }}</span>
                                    </div>
                                    <div class="report-details-item-label">E-Mail</div>
                                </div>
                            </div>

                            <!--********** Damage Number **********-->
                            <div class="report-details-item">
                                <div class="report-details-icon-container">
                                    <mat-icon class="contact-item-icon medium-icon">tag</mat-icon>
                                </div>
                                <div class="report-details-item-value-container">
                                    <div
                                        class="report-details-item-value"
                                        [matTooltip]="invoice.reportsData[0]?.insuranceCaseNumber">
                                        <span>{{ invoice.reportsData[0]?.insuranceCaseNumber || '-' }}</span>
                                    </div>
                                    <div class="report-details-item-label">Schadennummer</div>
                                </div>
                            </div>

                            <!--********** Insurance Number **********-->
                            <div class="report-details-item">
                                <div class="report-details-icon-container">
                                    <mat-icon class="contact-item-icon medium-icon">description</mat-icon>
                                </div>
                                <div class="report-details-item-value-container">
                                    <div
                                        class="report-details-item-value"
                                        [matTooltip]="invoice.reportsData[0]?.insuranceContractNumber">
                                        <span>{{ invoice.reportsData[0]?.insuranceContractNumber || '-' }}</span>
                                    </div>
                                    <div class="report-details-item-label">Versicherungsscheinnummer</div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!--============================================-->
                    <!-- END Insurance -->
                    <!--============================================-->

                    <!--============================================-->
                    <!-- Lawyer -->
                    <!--============================================-->
                    <div class="connected-lawyer-container" *ngIf="invoice.lawyer">
                        <h3 class="unfoldable-contact-heading" (click)="unfoldLawyer()">
                            Anwalt
                            <mat-icon class="unfold-icon" *ngIf="!extendLawyer">unfold_more</mat-icon>
                            <mat-icon class="unfold-icon" *ngIf="extendLawyer">unfold_less</mat-icon>
                        </h3>

                        <div *ngIf="extendLawyer" @slideInAndOutVertically>
                            <!--********** Organization **********-->
                            <div class="report-details-item">
                                <div class="report-details-icon-container">
                                    <img class="medium-icon" src="/assets/images/icons/lawyer-white.svg" />
                                </div>

                                <div class="report-details-item-value-container">
                                    <div
                                        class="report-details-item-value"
                                        [matTooltip]="invoice.lawyer.contactPerson.organization">
                                        {{ invoice.lawyer.contactPerson.organization ?? '-' }}
                                    </div>
                                    <div class="report-details-item-label">Kanzlei</div>
                                </div>
                            </div>

                            <!--********** First & Last Name **********-->
                            <div class="report-details-item">
                                <div class="report-details-icon-container">
                                    <mat-icon class="contact-item-icon medium-icon">person</mat-icon>
                                </div>
                                <div class="report-insurance-icon-container"></div>
                                <div class="report-details-item-value-container">
                                    <div
                                        class="report-details-item-value"
                                        matTooltip="{{ invoice.lawyer.contactPerson.firstName || '' }} {{
                                            invoice.lawyer.contactPerson.lastName || ''
                                        }}">
                                        <span>
                                            {{ invoice.lawyer.contactPerson.firstName || '' }}
                                            {{ invoice.lawyer.contactPerson.lastName || '' }}
                                        </span>
                                    </div>
                                    <div class="report-details-item-label">Anwalt</div>
                                </div>
                            </div>

                            <!--********** Phone **********-->
                            <div class="report-details-item">
                                <div class="report-details-icon-container">
                                    <mat-icon class="contact-item-icon medium-icon">phone</mat-icon>
                                </div>
                                <div class="report-details-item-value-container">
                                    <div
                                        class="report-details-item-value"
                                        [matTooltip]="invoice.lawyer.contactPerson.phone">
                                        <span>{{ invoice.lawyer.contactPerson.phone ?? '-' }}</span>
                                    </div>
                                    <div class="report-details-item-label">Telefonnummer</div>
                                </div>
                            </div>

                            <!--********** Email **********-->
                            <div class="report-details-item">
                                <div class="report-details-icon-container">
                                    <mat-icon class="contact-item-icon medium-icon">email</mat-icon>
                                </div>
                                <div class="report-details-item-value-container">
                                    <div
                                        class="report-details-item-value"
                                        [matTooltip]="invoice.lawyer.contactPerson.email">
                                        <span>{{ invoice.lawyer.contactPerson.email ?? '-' }}</span>
                                    </div>
                                    <div class="report-details-item-label">E-Mail</div>
                                </div>
                            </div>

                            <!--********** Token **********-->
                            <div class="report-details-item">
                                <div class="report-details-icon-container">
                                    <mat-icon class="contact-item-icon medium-icon">tag</mat-icon>
                                </div>
                                <div class="report-details-item-value-container">
                                    <div
                                        class="report-details-item-value"
                                        matTooltip="{{ invoice.reportsData[0]?.lawyerCaseNumber ?? '-' }}">
                                        <span>{{ invoice.reportsData[0]?.lawyerCaseNumber ?? '-' }}</span>
                                    </div>
                                    <div class="report-details-item-label">Aktenzeichen</div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!--============================================-->
                    <!-- END Lawyer -->
                    <!--============================================-->
                </ng-container>
            </ng-container>
            <!--============================================-->
            <!-- END Report Details -->
            <!--============================================-->

            <ng-container *ngIf="!reportConnectionInputShown && !connectedReports">
                <h2>Gutachten nicht gefunden</h2>
                <div class="text-align-center">
                    <p>Das verknüpfte Gutachten konnte nicht gefunden werden. Möglicherweise wurde es gelöscht.</p>
                    <button (click)="cutReportConnection(); saveInvoice()">Verknüpfung lösen</button>
                </div>
            </ng-container>
        </div>
        <!--============================================-->
        <!-- END Connected Report -->
        <!--============================================-->
    </div>
    <!--============================================-->
    <!-- END Status -->
    <!--============================================-->
</div>

<!--============================================-->
<!-- Template Title Dialog -->
<!--============================================-->
<invoice-template-title-dialog
    *ngIf="invoiceTemplateTitleDialogShown"
    [title]="getInvoiceTemplateTitle()"
    (useTitle)="addInvoiceToTemplates($event)"
    (close)="invoiceTemplateTitleDialogShown = false"></invoice-template-title-dialog>
<!--============================================-->
<!-- END Template Title Dialog -->
<!--============================================-->

<payments-dialog
    *ngIf="paymentsDialogShown"
    [invoice]="invoice"
    (invoiceChange)="saveInvoice()"
    (close)="hidePaymentsDialog()"></payments-dialog>

<payment-reminder-dialog
    *ngIf="paymentReminderDialogShown"
    [invoice]="invoice"
    (close)="hidePaymentReminderDialog()"
    @runChildAnimations></payment-reminder-dialog>

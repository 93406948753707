import { HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable()
export class RequestRegistryService {
    /**
     * Registry of calls to the server to monitor if anything is still being synced.
     * Primary purpose is showing the sync icon in the head runner of the application as long as there are syncs in process.
     * @type {Map<string, any>}
     */
    public pendingRequestsRegistry: Map<string, HttpRequest<any>> = new Map<string, HttpRequest<any>>();

    public pendingRequestsRegistryLength: BehaviorSubject<number> = new BehaviorSubject<number>(0);
}

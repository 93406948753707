<div id="editor-and-preview-row">
    <div id="image-editor-column">
        <!--********** Drag & Drop Upload **********-->
        <div
            *ngIf="!uploadedImageDataUrl && !selectedUser.sealHash && !isExistingSealLoadingPending"
            class="upload-container"
            (dragover)="onDragOver($event)"
            (dragleave)="onDragLeave($event)"
            (drop)="onDrop($event)"
            [class.dragging]="isDragging">
            <div class="drop-zone">
                <p>Scanne oder fotografiere deine Unterschrift, ggf. mit Stempel.</p>
                <p class="label">JPG oder PNG. Falls du ein PDF hast, mache einen Screenshot.</p>

                <button class="file-input-button" (click)="triggerFileInput()">
                    <mat-icon class="outlined small-icon">upload</mat-icon>
                    Bild hochladen
                </button>
                <input #fileInput type="file" accept="image/*" (change)="onImageSelected($event)" hidden />
            </div>
        </div>

        <div *ngIf="isExistingSealLoadingPending" class="upload-container">
            <mat-spinner class="loading-spinner"></mat-spinner>
        </div>

        <!--********** Seal Editor **********-->
        <ng-container *ngIf="uploadedImageDataUrl">
            <div id="canvas-background">
                <canvas #imageCanvas></canvas>
            </div>

            <button class="flat crop-button" (click)="toggleCropMode()">
                <mat-icon class="small-icon">crop</mat-icon>
                <span>Zuschneiden{{ cropRect ? ' abbrechen' : '' }}</span>
            </button>
        </ng-container>

        <!--********** Info Note: Remove Background **********-->
        <div id="remove-background-info-note">
            <mat-icon class="outlined">lightbulb</mat-icon>
            <p class="label">
                Du kannst den Hintergrund mit
                <a href="https://www.remove.bg/">remove.bg</a>
                kostenfrei entfernen
                <br />
                und die Datei hier wieder hochladen.
            </p>
        </div>
    </div>

    <!--********** Preview **********-->
    <div *ngIf="uploadedImageDataUrl" id="seal-size-column" @slideInAndOutHorizontally>
        <h3>Vorschau</h3>
        <span class="label">Das tatsächliche Dokument sieht mit deiner Kopf- und Fußzeile etwas anders aus.</span>

        <!--********** Mock Page **********-->
        <div id="page-preview">
            <div class="page-preview-title">Gutachtenfertigstellung</div>
            <p>Lichtbilder wurden dem Originalgutachten sowie der Gutachtenkopie beigefügt.</p>
            <p>Dieses Gutachten umfasst:</p>
            <ul>
                <li>26 Seiten</li>
                <li>18 Fotos</li>
            </ul>
            <p>Nersingen, den 25.02.2025</p>
            <p>Der Sachverständig</p>
            <div id="seal-preview" [ngStyle]="{ width: widthInMillimeters + 'px' }"></div>
            <p>Stefan Brodbeck</p>
            <p>
                KFZ-Meister
                <br />
                Kfz-Sachverständiger nach ISO 27001:9001
            </p>
        </div>

        <!--********** Slider: set size **********-->
        <mat-slider
            thumbLabel
            [(ngModel)]="widthInMillimeters"
            [min]="30"
            [max]="150"
            (ngModelChange)="triggerRerenderPreview()"></mat-slider>
        <span class="label" style="margin-top: -10px">Breite: {{ widthInMillimeters }} mm</span>
    </div>
</div>

<div *ngIf="uploadedImageDataUrl" class="canvas-buttons">
    <button id="save-user-seal-button" (click)="uploadSealImage()">
        <mat-icon class="button-icon small-icon" *ngIf="!signatureUploadPending">done</mat-icon>
        <img
            class="pending-icon"
            src="/assets/images/icons/loading-indicator-white.svg"
            *ngIf="signatureUploadPending" />
        Hochladen
    </button>
    <button class="flat" (click)="resetUserSeal()">
        <mat-icon class="small-icon margin-right-5">delete</mat-icon>
        Löschen
    </button>
    <button class="flat" (click)="abortEdit()">Abbrechen</button>
</div>

import { IsoDate } from '../../../lib/date/iso-date.types';
import { TaxationType } from './valuation';

export class DatValuation {
    // The taxation type the user selected in autoiXpert
    requestedTaxationType: TaxationType;

    // Weather the user wants to see VAT included in the amount in the DAT valuation document?
    requestedVatHandling: 'net' | 'detectFromCarOwnerVatStatus' | 'gross' = 'detectFromCarOwnerVatStatus';

    // The taxation type DAT returns
    // This is usually the same as the requested taxation type, but DAT does not return a flag for neutral taxation.
    vatDisplayType: 'relativeToSalesPrice' | 'relativeToMargin' = 'relativeToSalesPrice';

    // Weather VAT is included in the amount in the DAT valuation document.
    includeVat: boolean;

    // The vehicle value type the user selected in autoiXpert.
    // This value does not change the amount of the DAT valuation.
    // But the label is printed on the DAT valuation document.
    vehicleValueType: DatVehicleValueType = 'replacementValue';
    priceOrigin: 'dat' | 'assessor' = 'dat';

    // DAT returns all the values and autoiXpert maps which value is used depending on the vehicle value type and taxation.
    originalPriceWithoutEquipmentNet: number;
    originalPriceWithoutEquipmentGross: number;
    originalPriceWithEquipmentNet: number;
    originalPriceWithEquipmentGross: number;
    dealerPurchasePrice: number;
    dealerPurchasePriceGross: number;
    dealerSalesPrice: number;
    dealerSalesPriceGross: number;

    // WebScan results
    webscanNet: number;
    webscanGross: number;

    // On liability reports, the assessor may hide the DAT value and use values from other market analysis providers.
    corridorHiddenOnPrintout: boolean;

    includedDocuments: DatValuationIncludedDocuments = new DatValuationIncludedDocuments();
    dossierId: number;
    referenceDate: IsoDate;
    documentHash: string;

    // Values retrieved
    // Before this flag, we checked the vehicle values if greater than 0 to determine, if we already retrieved the valuation results.
    // This is not correct, because the vehicle value may be 0 (e.g. if the "Instandsetzungskosten" in the DAT-Valuation are higher than the vehicle value).
    valuesRetrieved: boolean;
}

export class DatValuationIncludedDocuments {
    valuation: boolean = true;
    protocol: boolean = false;
    equipments: boolean = true;
    repairCosts: boolean = true;
    repairCostsDetails: boolean = true;
    diminishedValue: boolean = true;
    increasedValue: boolean = true;
    increasedValueDetails: boolean = true;
}

export type DatVehicleValueType =
    | 'dealerPurchase'
    | 'dealerSales'
    | 'replacementValue'
    | 'presentValue'
    | 'marketValue';

import { DocumentOrderConfig, DocumentTemplateType } from '../../models/documents/document-order-config';
import { AxError } from '../../models/errors/ax-error';
import { sortDocumentOrderConfigsAlphabetically } from './sort-document-order-configs-alphabetically';

export interface DocumentTypeGroup {
    name: DocumentTypeGroupName;
    documentOrderConfigs: DocumentOrderConfig[];
}

export type DocumentTypeGroupName = 'report' | 'reportAttachment' | 'invoice' | 'signature' | 'other';

/**
 * These groups are used to cluster document types in categories that the user may easily understand.
 */
interface DefaultDocumentTypeGroup {
    name: DocumentTypeGroupName;
    documentTypes: DocumentTemplateType[];
}

/**
 * The document types create a sensible default to which custom documents will be added.
 */
const defaultDocumentTypeGroups: DefaultDocumentTypeGroup[] = [
    {
        name: 'report',
        documentTypes: [
            'fullKasko',
            'invoiceAudit',
            'leaseReturn',
            'liability',
            'oldtimerValuationSmall',
            'partialKasko',
            'shortAssessment',
            'usedVehicleCheck',
            'valuation',
        ],
    },
    {
        name: 'reportAttachment',
        documentTypes: [
            'customResidualValueBidList',
            'diminishedValueProtocol',
            'expertStatement',
            'garageInformation',
            'manualCalculation',
            'photoPortfolio',
            'repairConfirmation',
        ],
    },
    {
        name: 'invoice',
        documentTypes: [
            'invoice',
            'paymentReminderLevel0',
            'paymentReminderLevel1',
            'paymentReminderLevel2',
            'paymentReminderLevel3',
        ],
    },
    {
        name: 'signature',
        documentTypes: ['consentDataProtection', 'declarationOfAssignment', 'powerOfAttorney', 'revocationInstruction'],
    },
    {
        name: 'other',
        documentTypes: ['letter'],
    },
];

export function getDocumentTypeGroupsFromDocumentOrderConfigs({
    documentOrderConfigs,
}: {
    documentOrderConfigs: DocumentOrderConfig[];
}): DocumentTypeGroup[] {
    const documentTypeGroups: DocumentTypeGroup[] = [];

    for (const defaultDocumentTypeGroup of defaultDocumentTypeGroups) {
        documentTypeGroups.push({
            name: defaultDocumentTypeGroup.name,
            documentOrderConfigs: [],
        });
    }

    /**
     * Add all document order configs to the document type groups.
     *
     * Custom documents are used to create either
     * - a new report attachment such as an info sheet for an attorney or the author of the damage for his insurance.
     * - a signable document such as an order form for having the car repaired in a specific garage.
     */
    for (const documentOrderConfig of documentOrderConfigs) {
        let documentTypeGroupName: DocumentTypeGroupName;
        if (documentOrderConfig.type === 'customDocument') {
            /**
             * Custom documents may be added to either the report attachment or the signature group.
             */
            documentTypeGroupName = documentOrderConfig.customDocumentConfig.documentTypeGroup;
        } else {
            /**
             * Regular documents are always part of the default document type groups.
             */
            for (const defaultDocumentTypeGroup of defaultDocumentTypeGroups) {
                if (defaultDocumentTypeGroup.documentTypes.includes(documentOrderConfig.type)) {
                    documentTypeGroupName = defaultDocumentTypeGroup.name;
                    break;
                }
            }
        }

        const documentTypeGroup: DocumentTypeGroup = documentTypeGroups.find(
            (documentTypeGroup) => documentTypeGroup.name === documentTypeGroupName,
        );

        if (documentTypeGroup) {
            documentTypeGroup.documentOrderConfigs.push(documentOrderConfig);
        } else {
            throw new AxError({
                code: 'DOCUMENT_TYPE_GROUP_NOT_FOUND',
                message: `The given document type group does not exist. Please add the document type group to the default document type groups.`,
                data: {
                    documentOrderConfigId: documentOrderConfig._id,
                    documentTypeGroupInDocumentOrderConfig: documentOrderConfig.customDocumentConfig.documentTypeGroup,
                },
            });
        }
    }

    // Ensure alphabetical order of German translations
    for (const documentTypeGroup of documentTypeGroups) {
        documentTypeGroup.documentOrderConfigs.sort(sortDocumentOrderConfigsAlphabetically);
    }

    return documentTypeGroups;
}

import { CommunicationRecipient } from '@autoixpert/models/reports/involved-parties/communication-recipient';
import { communicationRecipientRoles } from '@autoixpert/models/reports/involved-parties/involved-party';
import { Report } from '@autoixpert/models/reports/report';

export function getCommunicationRecipientsFromReport({ report }: { report: Report }): CommunicationRecipient[] {
    const communicationRecipients: CommunicationRecipient[] = [];

    for (const communicationRecipientRole of communicationRecipientRoles) {
        const communicationRecipient: CommunicationRecipient = report[communicationRecipientRole];
        if (communicationRecipient) {
            communicationRecipients.push(communicationRecipient);
        }
    }

    return communicationRecipients;
}

<div id="layout-container">
    <!--============================================-->
    <!-- Graph -->
    <!--============================================-->
    <div id="graph-container" class="card card-with-padding">
        <!--============================================-->
        <!-- Graph Header -->
        <!--============================================-->
        <div id="graph-header">
            <!--********** Report Name **********-->
            <div id="report-name-container">
                <mat-icon
                    class="toolbar-icon"
                    matTooltip="Zurück zur Übersicht"
                    (click)="navigateBackToAnalyticsList()">
                    arrow_back
                </mat-icon>
                <!-- Prevent prettier from adding a space between chart title and the help indicator. -->
                <!-- prettier-ignore -->
                <h2>
                    {{ chartTitle }}<span class="help-indicator" matTooltip='Umsatz abgeschlossener Gutachten nach Rechnungspositionen. Es werden die Standardnebenkosten (z. B. Fotos und Schreibkosten) und alle gemerkten eigenen Rechnungspositionen angezeigt. Über die Option "Alle Positionen" können alle weiteren Positionen eingeblendet werden.{{"\n\n"}} Diese Auswertung beinhaltet auch die Umsätze stornierter Gutachten (deren Rechnung teilweise oder vollständig storniert wurde). Gutachten ohne Rechnung werden ausgeschlossen. Negative Rechnungsbeträge werden von der Summe der Position abgezogen.'>?</span>
                </h2>
            </div>

            <!--********** Other invoice items **********-->
            <div id="report-settings">
                <mat-checkbox
                    [(ngModel)]="includeOtherFees"
                    matTooltip='Neben den Standard-Nebenkosten (z. B. Fotos und Schreibkosten) und eigenen gemerkten Rechnungspositionen auch alle weiteren eigenen Rechnungspositionen auflisten, die sonst in der Kategorie "Sonstige" zusammengefasst sind.'
                    (ngModelChange)="refreshLocalData()">
                    Alle Positionen
                </mat-checkbox>
            </div>

            <analytics-filter-button [filterRef]="filter"></analytics-filter-button>

            <!--********** Date Filter **********-->
            <date-range-picker
                matTooltip="Nach Fertigstellungsdatum filtern"
                [(dateFrom)]="filterAnalyticsFrom"
                [(dateTo)]="filterAnalyticsTo"
                (dateRangeChange)="updateAnalytics(); rememberDateRange()"></date-range-picker>
        </div>

        <!--============================================-->
        <!-- END Graph Header -->
        <!--============================================-->

        <analytics-filter
            (filterChange)="updateAnalytics()"
            (initialized)="filtersLoaded()"
            [showInvoiceLabelFilter]="false"></analytics-filter>

        <div class="chart-container">
            <div class="chart-placeholder" *ngIf="analyticsPending">
                <img class="loading-indicator" src="/assets/images/icons/loading-indicator.svg" alt="" />
                Lade Auswertung ...
            </div>
            <div class="chart-placeholder" *ngIf="!analyticsPending && filteredRecordsForChart.length === 0">
                <img class="no-records-to-display-image" src="/assets/images/icons/no-data-to-display.png" />
                <div class="no-data-found-message">Keine Daten gefunden</div>
                Probiere doch andere Filter.
            </div>
            <div #chart id="chart" *ngIf="!analyticsPending && filteredRecordsForChart.length"></div>
        </div>

        <!--============================================-->
        <!-- Graph Summary -->
        <!--============================================-->
        <div id="graph-summary-container" class="card">
            <!--********** Total **********-->
            <div
                class="graph-summary-item-container"
                matTooltip="Summe der Umsätze (netto) aller angezeigten Rechnungspositionen">
                <!--********** Label **********-->
                <div class="graph-summary-item-label">
                    Umsatz
                    <span class="series-color analytics-series-1"></span>
                </div>

                <!--********** Total fees **********-->
                <div class="graph-summary-item-value">{{ totalNet | number: '1.0-0' }} €</div>
            </div>

            <!--********** Number of reports **********-->
            <div class="graph-summary-item-container" *ngIf="summary">
                <!--********** Label **********-->
                <div
                    class="graph-summary-item-label"
                    matTooltip="Anzahl der Gutachten, die in die dargestellte Auswertung einfließen.">
                    Anzahl Gutachten
                </div>

                <!--********** Value **********-->
                <div class="graph-summary-item-value">
                    {{ summary.totalNumberOfReports }}
                </div>
            </div>

            <!--********** Total revenue of all reports **********-->
            <div
                *ngIf="summary"
                class="graph-summary-item-container"
                matTooltip="Gesamtumsatz (netto) aller Gutachten, die unter die aktuellen Filterkriterien fallen. Betrachtet den gesamten Rechnungsbetrag inklusive Honorar.">
                <!--********** Label **********-->
                <div class="graph-summary-item-label">Gesamtumsatz</div>

                <!--********** Value **********-->
                <div class="graph-summary-item-value">
                    {{ summary.totalRevenueNetAllReports | convertToEuro }}
                </div>
            </div>
        </div>
        <!--============================================-->
        <!-- END Graph Summary -->
        <!--============================================-->
    </div>
    <!--============================================-->
    <!-- END Graph -->
    <!--============================================-->

    <!--============================================-->
    <!-- Series List -->
    <!--============================================-->
    <div id="series-list-card" class="card card-with-padding">
        <div id="series-list-toolbar">
            <mat-icon
                class="toolbar-icon"
                [matMenuTriggerFor]="exportMenu"
                matTooltip="Auswertungen als CSV herunterladen">
                file_download
            </mat-icon>
            <mat-menu #exportMenu>
                <!--********** Download Single Records **********-->
                <a
                    mat-menu-item
                    class="menu-item-with-icon"
                    (click)="downloadSingleRecordsAsCsv()"
                    matTooltip="Alle einzelnen Datensätze, die der Auswertung zugrunde liegen.">
                    <mat-icon>checklist_rtl</mat-icon>
                    Alle Datensätze
                </a>

                <!--********** Download Summary **********-->
                <a
                    mat-menu-item
                    class="menu-item-with-icon"
                    (click)="downloadSummaryAsCsv()"
                    matTooltip="Zusammenfassung in Gruppen, so wie in diesem Screen sichtbar.">
                    <mat-icon>summarize</mat-icon>
                    Zusammenfassung
                </a>
            </mat-menu>
        </div>
        <div id="series-list-table-container">
            <table id="series-list">
                <thead>
                    <tr>
                        <th class="series-entry-title-heading">Rechnungsposition</th>
                        <th
                            class="series-entry-value-heading"
                            matTooltip="Wie häufig diese Position abgerechnet wurde.">
                            Anzahl
                        </th>
                        <th class="series-entry-value-heading">Umsatz (netto)</th>
                        <th
                            class="series-entry-value-heading"
                            matTooltip="Durchschnittlicher Betrag der Position pro Vorkommnis in einer Rechnung.">
                            ø Umsatz
                        </th>
                    </tr>
                </thead>
                <tbody>
                    <tr
                        class="series-entry-row"
                        *ngFor="let record of records"
                        [ngClass]="{ hidden: isRecordHidden(record._id) }">
                        <td class="series-entry-title-cell">
                            <div class="series-entry-title-inner-container">
                                <div
                                    class="flex-grow text-overflow-ellipsis"
                                    [matTooltip]="stripHtml(record.invoiceItemDescription)">
                                    {{ stripHtml(record.invoiceItemDescription) }}
                                </div>
                                <mat-icon
                                    class="toolbar-icon toggle-hide-record-icon"
                                    matTooltip="Diesen Datensatz im Diagramm ausblenden"
                                    *ngIf="!isRecordHidden(record._id)"
                                    (click)="toggleHideRecord(record._id); refreshLocalData()">
                                    visibility
                                </mat-icon>
                                <mat-icon
                                    class="toolbar-icon toggle-hide-record-icon"
                                    matTooltip="Diesen Datensatz im Diagramm einblenden"
                                    *ngIf="isRecordHidden(record._id)"
                                    (click)="toggleHideRecord(record._id); refreshLocalData()">
                                    visibility_off
                                </mat-icon>
                            </div>
                        </td>
                        <td class="series-entry-value">{{ record.numberOfReports | number: '1.0-0' }}</td>
                        <td class="series-entry-value">{{ record.totalRevenueNet | number: '1.0-0' }} €</td>
                        <td class="series-entry-value">{{ record.averageRevenuePerReport | number: '1.0-0' }} €</td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
    <!--============================================-->
    <!-- END Series List -->
    <!--============================================-->
</div>

import { Component, EventEmitter, HostListener, Input, Output, SimpleChanges, ViewChild } from '@angular/core';
import { AutogrowTextareaComponent } from '@autoixpert/components/autogrow-textarea/autogrow-textarea.component';
import { parseEstimatedDuration } from '../../../libraries/tasks/parse-estimated-duration';
import { ToastService } from '../../../services/toast.service';

@Component({
    selector: 'task-estimated-duration',
    templateUrl: './task-estimated-duration.component.html',
    styleUrls: ['./task-estimated-duration.component.scss'],
})
export class TaskEstimatedDurationComponent {
    constructor(private toastService: ToastService) {}

    @Input() estimatedDuration: number;
    @Output() estimatedDurationChange = new EventEmitter<number>();

    @ViewChild('durationInput') durationInput: AutogrowTextareaComponent;

    estimatedDurationFormatted: string = '';
    estimatedDurationInputVisible = false;

    ngOnInit() {
        this.formatEstimatedDuration(this.estimatedDuration);
    }

    ngOnChanges(simpleChanges: SimpleChanges) {
        if (simpleChanges['estimatedDuration' satisfies keyof this]) {
            this.formatEstimatedDuration(this.estimatedDuration);
        }
    }

    enterEstimatedDurationEditMode() {
        this.estimatedDurationInputVisible = true;
    }

    handleEnterKeyPress() {
        this.durationInput.blur();
    }

    handleEstimatedDurationBlur({ value }: { value: string }) {
        this.estimatedDurationInputVisible = false;

        let parsedEstimatedDuration: number;
        try {
            parsedEstimatedDuration = parseEstimatedDuration(value);
        } catch (error) {
            this.toastService.error(
                'Geschätzte Dauer kann nicht verarbeitet werden',
                `Bitte gib die geschätzte Dauer im Format "1h" oder "30m" ein.

Deine Eingabe: ${value}`,
            );
            return;
        }

        this.estimatedDuration = parsedEstimatedDuration;

        // Update the UI
        this.formatEstimatedDuration(parsedEstimatedDuration);
        this.estimatedDurationChange.emit(this.estimatedDuration);
    }

    formatEstimatedDuration(estimatedDuration: number) {
        let formattedValue: string;

        if (!estimatedDuration) {
            formattedValue = '';
        } else if (estimatedDuration > 59) {
            formattedValue = `${Math.round(estimatedDuration / 6) / 10} h`;
        } else {
            formattedValue = `${estimatedDuration} m`;
        }

        this.estimatedDurationFormatted = formattedValue;
    }

    @HostListener('click', ['$event'])
    public handleHostClick() {
        if (!this.estimatedDuration && !this.estimatedDurationInputVisible) {
            this.enterEstimatedDurationEditMode();
        }

        setTimeout(() => {
            // Wait for the input field to be rendered (after *ngIf condition gets set to true above)
            if (!this.durationInput.isFocused) {
                this.durationInput.focus();
            }
        }, 0);
    }
}

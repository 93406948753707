<!--============================================-->
<!-- Input Mode -->
<!--============================================-->
<div class="custom-fields-input-mode-container" *ngIf="!fieldGroupConfigService.isEditModeEnabled">
    <!--********** Custom Field **********-->
    <div class="custom-field-in-input-mode" *ngFor="let customField of customFields; trackBy: trackById">
        <!--********** Single Line **********-->
        <mat-form-field
            class="mat-block"
            *ngIf="getFieldConfig(customField)?.type === 'singleLineText'"
            matTooltip="Individuelles Feld: {{ customField.name }}{{
                '\n\n'
            }}Zum Anpassen der Felder den Editiermodus über das Menü oben rechts in der Prozessleiste starten.">
            <input
                matInput
                [(ngModel)]="customField.value"
                (change)="emitReportChange()"
                [placeholder]="customField.name || DEFAULT_FIELD_NAME"
                [disabled]="disabled" />
            <span matSuffix *ngIf="getFieldConfig(customField).suffix">{{ getFieldConfig(customField).suffix }}</span>
        </mat-form-field>

        <!--********** Multi Line **********-->
        <mat-form-field
            class="mat-block"
            *ngIf="getFieldConfig(customField)?.type === 'multiLineText'"
            matTooltip="Individuelles Feld: {{ customField.name }}{{
                '\n\n'
            }}Zum Anpassen der Felder den Editiermodus über das Menü oben rechts in der Prozessleiste starten.">
            <mat-quill
                [(ngModel)]="customField.value"
                (change)="emitReportChange()"
                [placeholder]="customField.name || DEFAULT_FIELD_NAME"
                [disabled]="disabled"></mat-quill>
            <span matSuffix *ngIf="getFieldConfig(customField).suffix">{{ getFieldConfig(customField).suffix }}</span>
            <mat-icon matSuffix class="input-toolbar-icon" (click)="showTextTemplates(customField.fieldConfigId)">
                playlist_add
            </mat-icon>
        </mat-form-field>
        <text-template-selector
            *ngIf="textTemplateSelectorShown === customField.fieldConfigId"
            [(text)]="customField.value"
            [report]="report"
            [placeholderForResultingText]="customField.name || DEFAULT_FIELD_NAME"
            [textTemplateType]="'customField'"
            [customFieldConfigId]="customField.fieldConfigId"
            (textChange)="emitReportChange()"
            (close)="hideTextTemplates()"
            @runChildAnimations></text-template-selector>

        <!--********** Number **********-->
        <mat-form-field
            class="mat-block"
            *ngIf="getFieldConfig(customField)?.type === 'number'"
            matTooltip="Individuelles Feld: {{ customField.name }}{{
                '\n\n'
            }}Zum Anpassen der Felder den Editiermodus über das Menü oben rechts in der Prozessleiste starten.">
            <input
                matInput
                number-input
                [(number)]="customField.value"
                (change)="emitReportChange()"
                [placeholder]="customField.name || DEFAULT_FIELD_NAME"
                [disabled]="disabled" />
            <span matSuffix *ngIf="getFieldConfig(customField).suffix">{{ getFieldConfig(customField).suffix }}</span>
        </mat-form-field>

        <!--********** Dropdown **********-->
        <mat-form-field
            class="mat-block"
            *ngIf="getFieldConfig(customField)?.type === 'select'"
            matTooltip="Individuelles Feld: {{ customField.name }}{{
                '\n\n'
            }}Zum Anpassen der Felder den Editiermodus über das Menü oben rechts in der Prozessleiste starten.">
            <mat-select
                [(ngModel)]="customField.value"
                (selectionChange)="emitReportChange()"
                [placeholder]="customField.name || DEFAULT_FIELD_NAME"
                [disabled]="disabled">
                <mat-option [value]="null"></mat-option>
                <mat-option *ngFor="let option of getFieldConfig(customField)?.dropdownOptions" [value]="option.value">
                    <span *ngIf="option.value">{{ option.value }}</span>
                    <span *ngIf="!option.value" class="label italic">Leer</span>
                </mat-option>
            </mat-select>
            <span matSuffix *ngIf="getFieldConfig(customField).suffix">{{ getFieldConfig(customField).suffix }}</span>
        </mat-form-field>

        <!--********** Autocomplete **********-->
        <mat-form-field
            class="mat-block"
            *ngIf="getFieldConfig(customField)?.type === 'autocomplete'"
            matTooltip="Individuelles Feld: {{ customField.name }}{{
                '\n\n'
            }}Zum Anpassen der Felder den Editiermodus über das Menü oben rechts in der Prozessleiste starten.">
            <input
                matInput
                [(ngModel)]="customField.value"
                (change)="emitReportChange()"
                [placeholder]="customField.name || DEFAULT_FIELD_NAME"
                [disabled]="disabled"
                [matAutocomplete]="customAutocomplete" />
            <span matSuffix *ngIf="getFieldConfig(customField).suffix">{{ getFieldConfig(customField).suffix }}</span>
            <mat-autocomplete #customAutocomplete (optionSelected)="emitReportChange()">
                <mat-option
                    *ngFor="let option of getFieldConfig(customField)?.dropdownOptions"
                    [value]="option.value"
                    (mousedown)="$event.preventDefault()">
                    <span *ngIf="option.value">{{ option.value }}</span>
                    <span *ngIf="!option.value" class="label italic">Leer</span>
                </mat-option>
            </mat-autocomplete>
        </mat-form-field>

        <!--********** Currency **********-->
        <currency-input
            *ngIf="getFieldConfig(customField)?.type === 'currency'"
            [(value)]="customField.value"
            (change)="emitReportChange()"
            [placeholder]="customField.name || DEFAULT_FIELD_NAME"
            [disabled]="disabled"></currency-input>

        <!--********** Date **********-->
        <date-input
            *ngIf="getFieldConfig(customField)?.type === 'date'"
            [(date)]="customField.value"
            (change)="emitReportChange()"
            [placeholder]="customField.name || DEFAULT_FIELD_NAME"
            [disabled]="disabled"></date-input>

        <!--********** Checkbox **********-->
        <div class="checkbox-group" *ngIf="getFieldConfig(customField)?.type === 'boolean'">
            <mat-checkbox [(ngModel)]="customField.value" (change)="emitReportChange()" [disabled]="disabled">
                {{ customField.name }}
            </mat-checkbox>
        </div>

        <!--********** Tristate Checkbox **********-->
        <div class="checkbox-group" *ngIf="getFieldConfig(customField)?.type === 'tristate'">
            <tri-state-checkbox [(ngModel)]="customField.value" (change)="emitReportChange()" [disabled]="disabled">
                {{ customField.name }}
            </tri-state-checkbox>
        </div>

        <!--********** Button **********-->
        <div class="custom-field-button-container" *ngIf="getFieldConfig(customField)?.type === 'button'">
            <button
                class="button-with-loading-indicator"
                mat-raised-button
                (click)="handleButtonClick(customField)"
                [disabled]="disabled">
                <!-- Keep icon and name to preserve the button width -->
                <mat-icon
                    *ngIf="getFieldConfig(customField).googleMaterialIconName"
                    class="button-icon"
                    [style.opacity]="buttonIsLoading || showButtonSuccess ? 0 : 1">
                    {{ getFieldConfig(customField).googleMaterialIconName }}
                </mat-icon>

                <span [style.opacity]="buttonIsLoading || showButtonSuccess ? 0 : 1">{{ customField.name }}</span>
                <mat-spinner *ngIf="buttonIsLoading" diameter="20"></mat-spinner>
                <mat-icon *ngIf="showButtonSuccess" class="success-icon outlined">check_circle</mat-icon>
            </button>
        </div>
    </div>
</div>
<!--============================================-->
<!-- END Input Mode -->
<!--============================================-->

<!--============================================-->
<!-- Management Mode -->
<!--============================================-->
<div class="custom-fields-management-mode-container" *ngIf="fieldGroupConfigService.isEditModeEnabled">
    <!--********** Existing Fields **********-->
    <div
        class="custom-field-in-management-mode"
        *ngFor="let customField of customFields"
        (click)="editCustomFieldConfig(customField)">
        <!--============================================-->
        <!-- Input Type Icon -->
        <!--============================================-->
        <!--********** Single Line **********-->
        <mat-icon
            class="field-type-icon"
            *ngIf="getFieldConfig(customField)?.type === 'singleLineText'"
            matTooltip="Einzeiliges Textfeld">
            title
        </mat-icon>
        <!--********** Multi Line **********-->
        <mat-icon
            class="field-type-icon"
            *ngIf="getFieldConfig(customField)?.type === 'multiLineText'"
            matTooltip="Mehrzeiliges Textfeld">
            text_fields
        </mat-icon>
        <!--********** Number **********-->
        <mat-icon
            class="field-type-icon"
            *ngIf="getFieldConfig(customField)?.type === 'number'"
            matTooltip="Zahlenfeld">
            123
        </mat-icon>
        <!--********** Dropdown **********-->
        <mat-icon
            class="field-type-icon"
            *ngIf="getFieldConfig(customField)?.type === 'select'"
            matTooltip="Mehrfachauswahl (Dropdown)">
            list
        </mat-icon>
        <!--********** Autocomplete **********-->
        <mat-icon
            class="field-type-icon"
            *ngIf="getFieldConfig(customField)?.type === 'autocomplete'"
            matTooltip="Autovervollständigung (Vorschläge beim Tippen)">
            text_rotation_none
        </mat-icon>
        <!--********** Currency **********-->
        <mat-icon
            class="field-type-icon"
            *ngIf="getFieldConfig(customField)?.type === 'currency'"
            matTooltip="Währungsfeld">
            euro_symbol
        </mat-icon>
        <!--********** Date **********-->
        <mat-icon class="field-type-icon" *ngIf="getFieldConfig(customField)?.type === 'date'" matTooltip="Datumsfeld">
            today
        </mat-icon>
        <!--********** Checkbox **********-->
        <mat-icon class="field-type-icon" *ngIf="getFieldConfig(customField)?.type === 'boolean'" matTooltip="Checkbox">
            check_box
        </mat-icon>
        <!--********** Tristate Checkbox **********-->
        <mat-icon
            class="field-type-icon"
            *ngIf="getFieldConfig(customField)?.type === 'tristate'"
            matTooltip="Checkbox Ja/Nein/Unbekannt">
            help_center
        </mat-icon>

        <!--********** Fallback: No Global Config Available **********-->
        <mat-icon
            class="field-type-icon error outlined"
            *ngIf="!getFieldConfig(customField)"
            matTooltip="Das Feld wurde global gelöscht. Der Wert bleibt in diesem Gutachten erhalten, bis du ihn hier manuell über das Dreipunktmenü löschst.">
            error
        </mat-icon>
        <!--============================================-->
        <!-- END Input Type Icon -->
        <!--============================================-->

        <!--********** Field Name **********-->
        <div class="field-name" matTooltip="Individuelles Feld: {{ customField.name }}">
            <ng-container *ngIf="customField.name">{{ customField.name }}</ng-container>
            <span *ngIf="!customField.name" class="italic label">Eigenes Feld ohne Titel</span>
        </div>

        <!--********** Submenu **********-->
        <mat-icon class="toolbar-icon" [matMenuTriggerFor]="submenu" (click)="$event.stopPropagation()">
            more_vert
        </mat-icon>
        <mat-menu #submenu>
            <!--********** Edit **********-->
            <a
                mat-menu-item
                class="menu-item-with-icon"
                *ngIf="getFieldConfig(customField)"
                (click)="editCustomFieldConfig(customField)">
                <mat-icon>edit</mat-icon>
                Bearbeiten
            </a>

            <!--********** Delete Custom Field Config **********-->
            <a
                mat-menu-item
                class="menu-item-with-icon"
                *ngIf="getFieldConfig(customField)"
                (click)="deleteFieldConfig(customField)"
                matTooltip="Feld aus allen Gutachten löschen">
                <mat-icon>delete</mat-icon>
                Löschen
            </a>

            <!--********** Delete Custom Field (only available if config has been deleted) **********-->
            <a
                mat-menu-item
                class="menu-item-with-icon"
                *ngIf="!getFieldConfig(customField)"
                (click)="deleteCustomField(customField)"
                matTooltip="Feld aus diesem Gutachten entfernen">
                <mat-icon>delete</mat-icon>
                Löschen
            </a>
        </mat-menu>
    </div>

    <!--********** Add new field **********-->
    <div
        class="add-new-field-button dashed-button"
        (click)="createFieldConfig()"
        matTooltip="Neues eigenes Feld hinzufügen">
        + Neues eigenes Feld
    </div>

    <!--********** Leave Management Mode **********-->
    <div class="buttons-container">
        <button
            class="flat"
            (click)="fieldGroupConfigService.isEditModeEnabled = false"
            matTooltip="Bearbeitungsmodus für eigene Felder beenden">
            Bearbeitung beenden
        </button>
    </div>
</div>
<!--============================================-->
<!-- END Management Mode -->
<!--============================================-->

<custom-field-editor-dialog
    *ngIf="isFieldConfigDialogShown"
    [fieldLocation]="fieldLocation"
    [reportType]="report.type"
    [fieldGroupConfig]="fieldGroupConfig"
    [selectedFieldConfig]="fieldConfigForEditDialog"
    (close)="closeCustomFieldConfigDialog()"
    (fieldConfigChange)="syncGlobalFieldsToReport(); saveFieldGroupConfig()"
    (sortOrderChange)="syncGlobalFieldsToReport()"
    @runChildAnimations></custom-field-editor-dialog>

import { DateTime } from 'luxon';
import { generateId } from '../../lib/generate-id';
import { DataTypeBase } from '../indexed-db/database.types';

export class CustomAutocompleteEntry implements DataTypeBase {
    constructor(template: Partial<CustomAutocompleteEntry> = {}) {
        if (!template.type) {
            throw Error('AUTOCOMPLETE_ENTRY_TYPE_REQUIRED');
        }

        Object.assign(this, template);
    }

    _id: string = generateId();
    type: CustomAutocompleteEntryType;
    value: string;
    tooltip: string; // If empty, the custom autocomplete component uses the value as tooltip
    sortIndex: number;
    createdBy: string;
    teamId: string;
    // Use time zone "UTC" to ensure the string has a Z at the end. That's the format, the autoiXpert backend uses, too.
    updatedAt: string = DateTime.now().setZone('UTC').toISO();
    createdAt: string = DateTime.now().setZone('UTC').toISO();

    _documentVersion: number = 0;
    _schemaVersion = 1 as const;
}

export const customAutocompleteEntryTypes = [
    'autobodyCondition',
    'batteryOwner',
    'carAssemblyState',
    'customMarketAnalysisSource',
    'dataFieldLabel',
    'emergencyRepairState',
    'emergencyRepairWorkItem',
    'engineConfiguration',
    'generalCondition',
    'interiorCondition',
    'invoiceAuditCorrectionItemComment',
    'invoiceAuditCorrectionItemTitle',
    'kaskoDamageType',
    'manualCalculationItemDescription',
    'newEquipment',
    'orderingCustomer',
    'paintCondition',
    'paymentPayer',
    'paymentInternalNote',
    'shortPaymentPayer',
    'shortPaymentReason',
    'shortPaymentResolution',
    'photoDescription',
    'replacementValueDecreaseDescription',
    'replacementValueIncreaseDescription',
    'roadworthiness',
    'spareTireComment',
    'tireComment',
    'undercarriageCondition',
    'visitViewingConditions',
    'auxiliaryDevices',
    'otherPeoplePresent',
    'repairRisks',
    'policeDepartment',
    'axBillingPosition',
    'sourceOfTechnicalData',
    'sourceOfNumberOfPreviousOwners',
    'amendmentReason',
    'skipWritingInvoiceReason',
] as const;
export type CustomAutocompleteEntryType = (typeof customAutocompleteEntryTypes)[number];

<div id="residual-value-toolbar">
    <mat-icon
        id="residual-value-import-icon"
        class="toolbar-icon"
        (click)="!residualValueExchangesImportPending && importAllBids()"
        matTooltip="Gebote abrufen"
        *ngIf="
            !isReportLocked() &&
            (report.valuation.autoonlineResidualValueOffer?.offerId ||
                report.valuation.winvalueResidualValueOffer?.offerId ||
                report.valuation.cartvResidualValueOffer?.offerId ||
                report.valuation.autoixpertResidualValueOffer?.closingDate)
        ">
        file_download
    </mat-icon>
    <mat-icon class="toolbar-icon" (click)="openBidSelector()" matTooltip="Alle Gebote anzeigen">view_list</mat-icon>

    <!--********** Submenu **********-->
    <mat-icon id="residual-value-submenu-icon" class="toolbar-icon" [matMenuTriggerFor]="residualValueSubmenu">
        more_vert
    </mat-icon>
    <mat-menu #residualValueSubmenu="matMenu">
        <a
            mat-menu-item
            class="menu-item-with-icon outlined"
            (click)="toggleResidualValueRequestComment()"
            matTooltip="Restwertkäufern besondere Umstände wie ein Reparaturrisiko mitteilen">
            <mat-icon>chat_bubble</mat-icon>
            Hinweis für Restwertkäufer
        </a>
    </mat-menu>
</div>

<!--============================================-->
<!-- Residual Value Settings - Read Mode -->
<!--============================================-->
<div
    id="residual-value-data-display-container"
    *ngIf="!residualValueDataInEditMode"
    [class.disabled]="isReportLocked()"
    (click)="enterResidualValueDataEditMode()">
    <!--********** Target Date **********-->
    <div class="flex-center-content margin-right-15" matTooltip="Gebotsfrist für Inserate">
        <mat-icon class="residual-value-data-icon medium-icon">today</mat-icon>
        <span *ngIf="residualValueTargetDate">
            {{ residualValueTargetDate | moment: 'DD.MM.YYYY [-] HH:mm [Uhr]' }}
        </span>
        <span *ngIf="!residualValueTargetDate" class="residual-value-offer-date-missing">Datum fehlt</span>
    </div>

    <!--********** Car Location **********-->
    <div class="flex-center-content margin-right-15" matTooltip="PLZ des Fahrzeugstandorts">
        <mat-icon class="residual-value-data-icon medium-icon">place</mat-icon>
        {{ getCarLocationZip() || '-' }}
    </div>

    <!--********** Residual Value Search Radius **********-->
    <div
        class="flex-center-content"
        *ngIf="report.valuation.residualValueRegionalRadius"
        matTooltip="{{ getResidualValueRadiusTooltip() }}">
        <mat-icon class="residual-value-data-icon medium-icon">radar</mat-icon>
        {{ getResidualValueRadiusLabel() }}
    </div>

    <mat-icon id="residual-value-edit-icon" matTooltip="Gebotsfrist & Standort einstellen">edit</mat-icon>
</div>
<!--============================================-->
<!-- END Residual Value Settings - Read Mode -->
<!--============================================-->

<!--============================================-->
<!-- Residual Value Data - Edit Mode -->
<!--============================================-->
<div id="residual-value-target-date-edit-container" *ngIf="residualValueDataInEditMode">
    <!--============================================-->
    <!-- Residual Value Target Date -->
    <!--============================================-->
    <div id="residual-value-time-offset-container">
        <mat-form-field id="residual-value-time-offset-amount">
            <input
                matInput
                type="number"
                placeholder="Dauer"
                [(ngModel)]="userPreferences.residualValueTimeOffsetAmount"
                [disabled]="isReportLocked()"
                (change)="
                    unfreezeResidualValueTargetDateOnReport(); calculateResidualValueTargetDate(); saveReport()
                " />
        </mat-form-field>
        <mat-form-field id="residual-value-time-offset-unit">
            <mat-select
                placeholder="Einheit"
                [(ngModel)]="userPreferences.residualValueTimeOffsetUnit"
                [disabled]="isReportLocked()"
                (selectionChange)="
                    unfreezeResidualValueTargetDateOnReport(); calculateResidualValueTargetDate(); saveReport()
                ">
                <mat-option value="businessDays">Werktage (Mo-Fr)</mat-option>
                <mat-option value="calendarDays">Kalendertage</mat-option>
                <mat-option value="hours">Stunden</mat-option>
            </mat-select>
        </mat-form-field>
    </div>
    <div id="residual-value-target-date-and-time-container">
        <date-input
            id="residual-value-target-date"
            [(date)]="residualValueTargetDate"
            (dateChange)="freezeResidualValueTargetDateOnReport(); saveReport()"
            [disabled]="isReportLocked()"></date-input>
        <time-input
            id="residual-value-target-time"
            [(time)]="residualValueTargetDate"
            (timeChange)="freezeResidualValueTargetDateOnReport(); saveReport()"
            [disabled]="isReportLocked()"></time-input>
        <mat-icon
            id="recalculate-residual-value-target-date-icon"
            class="toolbar-icon"
            [matMenuTriggerFor]="residualValueTargetTimeSubMenu">
            more_vert
        </mat-icon>
        <mat-menu #residualValueTargetTimeSubMenu>
            <a
                mat-menu-item
                class="menu-item-with-icon"
                (click)="rememberResidualValueTargetTime()"
                matTooltip="Restwertinserate stets zur gleichen Uhrzeit enden lassen, z. B. immer um 16 Uhr.">
                <mat-icon>schedule</mat-icon>
                Uhrzeit merken
            </a>
            <a
                mat-menu-item
                class="menu-item-with-icon"
                (click)="unfreezeResidualValueTargetDateOnReport(); calculateResidualValueTargetDate(); saveReport()"
                matTooltip="Zieldatum neu berechnen">
                <mat-icon>refresh</mat-icon>
                Zieldatum neu berechnen
            </a>
        </mat-menu>
    </div>
    <!--============================================-->
    <!-- END Residual Value Target Date -->
    <!--============================================-->

    <!--============================================-->
    <!-- Car Location & Radius Search -->
    <!--============================================-->
    <div id="car-location-and-regional-radius-container">
        <div id="car-location-container">
            <mat-form-field class="mat-block">
                <!--********** Maxlength 8 honors ZIP codes from Brazil. **********-->
                <input
                    matInput
                    placeholder="PLZ Fahrzeug"
                    [(ngModel)]="report.valuation.vehicleLocationZip"
                    [disabled]="isReportLocked()"
                    zip-city-input
                    matTooltip="Die Postleitzahl des Standorts der Fahrzeugs.{{
                        '\n\n'
                    }}Von hier muss der Restwerthändler die Abholung kalkulieren."
                    [queryCities]="false"
                    maxlength="8"
                    (change)="saveReport()" />
            </mat-form-field>

            <!--********** Input Toolbar **********-->
            <div class="input-toolbar" matSuffix>
                <!--********** Claimant **********-->
                <svg
                    class="input-toolbar-icon"
                    matTooltip="PLZ des Anspruchstellers einfügen"
                    (click)="insertClaimantsZip(); saveReport()"
                    *ngIf="!isReportLocked() && report.claimant?.contactPerson?.zip"
                    version="1.1"
                    xmlns="http://www.w3.org/2000/svg"
                    x="0px"
                    y="0px"
                    width="32px"
                    height="32px"
                    viewBox="0 0 32 32"
                    enable-background="new 0 0 32 32"
                    xml:space="preserve">
                    <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M4.109,26.636c0,0-0.106-3.104,1.031-3.883c1.139-0.777,5.557-2.646,7.732-4.159
                                c0.293-0.203,0.53-1.097,0.078-1.738c-0.527-0.746-0.85-2.034-1.024-3.447c-0.564-0.014-0.579-1.432-0.094-1.453
                                c-0.104-1.672-0.061-3.187,0.098-4.206c0.258-1.669,2.792-2.284,4.242-2.284c1.451,0,3.294,0.632,3.659,2.284
                                c0.225,1.015,0.287,2.679,0.16,4.354c0.267,0,0.338,1.259-0.137,1.268c-0.194,1.407-0.536,2.703-1.044,3.482
                                c-0.428,0.659-0.216,1.535,0.076,1.738c2.177,1.513,6.594,3.382,7.733,4.159c1.139,0.778,1.031,3.883,1.031,3.883H4.109z">
                        Anspruchsteller Icon
                    </path>
                </svg>

                <!--********** Owner of Claimant's Car **********-->
                <mat-icon
                    *ngIf="
                        !isReportLocked() && !report.claimant.isOwner && report.ownerOfClaimantsCar?.contactPerson?.zip
                    "
                    class="input-toolbar-icon medium-icon"
                    (click)="insertOwnerOfClaimantsCarZip()"
                    matTooltip="PLZ des Fahrzeughalters einfügen">
                    account_circle
                </mat-icon>

                <!--********** Last Visit **********-->
                <mat-icon
                    class="input-toolbar-icon medium-icon"
                    *ngIf="!isReportLocked() && getLastVisitZip()"
                    (click)="insertLastVisitZip()"
                    matTooltip="PLZ der letzten Besichtigung einfügen">
                    assignment
                </mat-icon>

                <!--********** Garage **********-->
                <mat-icon
                    class="input-toolbar-icon medium-icon"
                    *ngIf="!isReportLocked() && report.garage?.contactPerson?.zip"
                    (click)="insertGarageZip()"
                    matTooltip="PLZ der Werkstatt einfügen">
                    build
                </mat-icon>
            </div>
        </div>

        <div id="residual-value-regional-distance-container">
            <mat-form-field
                id="residual-value-regional-distance"
                matTooltip="Die Restwertbörsen stufen Gebote als regional ein, wenn der Händler in diesem Radius vom Fahrzeugstandort liegt.">
                <mat-select
                    placeholder="Umkreis regional"
                    [(ngModel)]="report.valuation.residualValueRegionalRadius"
                    [disabled]="isReportLocked()"
                    (selectionChange)="handleResidualValueRegionalRadiusChange($event)">
                    <mat-option [value]="null"></mat-option>
                    <mat-option [value]="200">200 km</mat-option>
                    <mat-option [value]="100">100 km</mat-option>
                    <mat-option [value]="50">50 km</mat-option>
                    <mat-option [value]="30">30 km</mat-option>
                    <mat-option *ngIf="hasCartvAsResidualValueExchange()" [value]="'national'">bundesweit</mat-option>
                    <mat-option *ngIf="hasCartvAsResidualValueExchange()" [value]="'international'">
                        international
                    </mat-option>
                </mat-select>
            </mat-form-field>

            <!--********** Submenu **********-->
            <mat-icon
                class="toolbar-icon"
                id="residual-value-regional-radius-submenu"
                [matMenuTriggerFor]="residualValueRegionalRadiusSubMenu">
                more_vert
            </mat-icon>
            <mat-menu #residualValueRegionalRadiusSubMenu="matMenu">
                <a
                    mat-menu-item
                    class="menu-item-with-icon"
                    (click)="
                        userPreferences.residualValueRegionalRadius = report.valuation.residualValueRegionalRadius
                    ">
                    <mat-icon>radar</mat-icon>
                    Umkreis als Standard merken
                </a>
            </mat-menu>
        </div>
    </div>
    <!--============================================-->
    <!-- END Car Location & Radius Search -->
    <!--============================================-->

    <div id="residual-value-target-date-buttons-container">
        <button (click)="leaveResidualValueDataEditMode()">Schließen</button>
    </div>
</div>
<!--============================================-->
<!-- END Residual Value Data - Edit Mode -->
<!--============================================-->

<!--============================================-->
<!-- Residual Value Comment -->
<!--============================================-->
<div
    id="residual-value-request-comment-container"
    *ngIf="residualValueRequestCommentShown || report.valuation.residualValueOfferRemark">
    <mat-form-field class="mat-block">
        <mat-quill
            [(ngModel)]="report.valuation.residualValueOfferRemark"
            placeholder="Hinweis für Restwertkäufer"
            [disabled]="isReportLocked()"
            #residualValueRequestComment
            (change)="saveReport()"></mat-quill>
        <mat-icon
            matSuffix
            *ngIf="!isReportLocked()"
            class="input-toolbar-icon"
            (click)="residualValueRequestCommentTextTemplateSelectorShown = true"
            matTooltip="Textvorlagen">
            playlist_add
        </mat-icon>
    </mat-form-field>
    <text-template-selector
        [(text)]="report.valuation.residualValueOfferRemark"
        (textChange)="saveReport()"
        [report]="report"
        *ngIf="residualValueRequestCommentTextTemplateSelectorShown"
        placeholderForResultingText="Kommentar Restwertinserat"
        (close)="residualValueRequestCommentTextTemplateSelectorShown = false"
        [textTemplateType]="'residualValueRequestComment'"
        @runChildAnimations></text-template-selector>
</div>
<!--============================================-->
<!-- END Residual Value Comment -->
<!--============================================-->

<!--============================================-->
<!-- Use insurance discount -->
<!--============================================-->
<div
    *ngIf="
        residualValueExchangesWithInsuranceDiscountNames.length || report.valuation.useInsuranceDiscountForResidualValue
    "
    id="use-insurance-discount-container"
    matTooltip="Du hast mit folgenden Restwertbörsen eine Vereinbarung, dass du die Restwertinserate über die Versicherung abrechnest: {{
        residualValueExchangesWithInsuranceDiscountNames.join(', ')
    }}. Wenn du das aktivierst, übermittelt autoiXpert die Versicherung mit an die Restwertbörse.">
    <div class="insurance-discount-icon-container">
        <mat-icon class="insurance-discount-icon">redeem</mat-icon>
    </div>
    <span style="flex: 1">Abrechnung über Versicherung</span>
    <mat-slide-toggle
        [(ngModel)]="report.valuation.useInsuranceDiscountForResidualValue"
        [disabled]="isReportLocked()"
        (change)="saveReport()"></mat-slide-toggle>
</div>
<!--============================================-->
<!-- END Use insurance discount -->
<!--============================================-->

<div
    class="residual-value-exchange-container"
    *ngFor="let residualValueExchange of filteredResidualValueExchangesForUi">
    <div class="residual-value-exchange-header">
        <div class="residual-value-exchange-logo-container">
            <img
                class="residual-value-exchange-logo"
                src="/assets/images/logos/{{ residualValueExchange.logoFileName }}"
                alt=""
                (click)="residualValueExchange.openResidualValueOffer()"
                [ngClass]="{ clickable: !!residualValueExchange.residualValueOffer?.link }" />
            <div id="carcasion-logo-label" *ngIf="residualValueExchange.name === 'carcasion'">car.casion</div>

            <!--********** Duration Indicator **********-->
            <div
                class="residual-value-pending-indicator-icon-and-label"
                *ngIf="isResidualValueRequestOpen(residualValueExchange)"
                [matTooltip]="getResidualValueExchangeTooltip(residualValueExchange)">
                <mat-icon class="residual-value-pending-indicator small-icon outlined">access_time</mat-icon>
                <div class="label">
                    {{ getDateRelativeFromNow(residualValueExchange.residualValueOffer.readyAt) }}
                </div>
            </div>

            <!--********** Residual Value Closing Date Reached **********-->
            <mat-icon
                class="residual-value-done-indicator small-icon"
                *ngIf="
                    residualValueExchange.residualValueOffer?.readyAt &&
                    !isResidualValueRequestOpen(residualValueExchange)
                "
                [matTooltip]="getResidualValueExchangeTooltip(residualValueExchange)">
                check
            </mat-icon>
        </div>

        <!--********** Warning about a missing insurance discount **********-->
        <mat-icon
            class="warning insurance-discount-icon"
            *ngIf="
                !residualValueExchange.residualValueOffer.offerId &&
                report.valuation.useInsuranceDiscountForResidualValue &&
                !residualValueExchange.isDiscountForInsuranceAvailable
            "
            matTooltip="Du hast mit dieser Restwertbörse keine Einstellungsvereinbarung mit der Versicherung {{
                report.insurance.contactPerson.organization
            }}. Die Einstellung bei {{
                translateResidualValueProvider(residualValueExchange.name)
            }} wird regulär abgerechnet.">
            warning
        </mat-icon>
        <!--********** Warning about WinValue's missing radius feature. Displayed only before offer creation. **********-->
        <mat-icon
            class="toolbar-icon warning"
            *ngIf="
                residualValueExchange.name === 'winvalue' &&
                report.valuation.residualValueRegionalRadius &&
                report.valuation.residualValueRegionalRadius !== 'national' &&
                report.valuation.residualValueRegionalRadius !== 'international' &&
                !residualValueExchange.residualValueOffer.offerId
            "
            (click)="openHelpcenterArticleOnRegionalRadiusWinvalue()"
            matTooltip="WinValue-Schnittstelle unterstützt regionalen Umkreis nicht. Klicke, um eine Anleitung zu erhalten, den regionalen Radius über deine WinValue-Einstellungen zu setzen.">
            warning
        </mat-icon>
        <div
            class="number-of-bids"
            (click)="openBidSelector(false, residualValueExchange.name)"
            *ngIf="residualValueExchange.residualValueOffer?.retrievedAt && !residualValueExchange.importPending"
            matTooltip="Gebote anzeigen">
            {{ residualValueExchange.residualValueOffer.bids?.length }}
            {{ residualValueExchange.residualValueOffer.bids?.length === 1 ? 'Gebot' : 'Gebote' }}
        </div>
        <div class="residual-value-exchange-toolbar has-submenu">
            <img
                class="residual-value-pending-icon pending-icon toolbar-icon"
                src="/assets/images/icons/loading-indicator.svg"
                *ngIf="residualValueExchange.importPending"
                matTooltip="Importiere..." />
            <img
                class="residual-value-pending-icon pending-icon toolbar-icon"
                src="/assets/images/icons/loading-indicator.svg"
                *ngIf="residualValueExchange.exportPending"
                matTooltip="Exportiere..." />
            <mat-icon
                class="residual-value-opener toolbar-icon"
                [class.disabled]="
                    !residualValueExchange.areCredentialsComplete() ||
                    !residualValueExchange.isResidualValueOfferAllowed() ||
                    isReportLocked()
                "
                (click)="residualValueExchange.createResidualValueOffer()"
                *ngIf="!residualValueExchange.residualValueOffer?.offerId && !residualValueExchange.exportPending"
                [matTooltip]="residualValueExchange.getTooltipForExportIcon()">
                open_in_new
            </mat-icon>

            <!--********** Submenu **********-->
            <mat-icon class="toolbar-icon" [matMenuTriggerFor]="residualValueExchangeSubMenu">more_vert</mat-icon>
            <mat-menu #residualValueExchangeSubMenu="matMenu">
                <a
                    mat-menu-item
                    class="menu-item-with-icon"
                    (click)="residualValueExchange.openResidualValueOffer()"
                    *ngIf="
                        residualValueExchange.residualValueOffer?.offerId &&
                        residualValueExchange.residualValueOffer?.link
                    ">
                    <mat-icon>open_in_new</mat-icon>
                    Inserat öffnen
                </a>
                <a
                    mat-menu-item
                    class="menu-item-with-icon"
                    (click)="residualValueExchange.importBids()"
                    *ngIf="residualValueExchange.residualValueOffer?.offerId"
                    [disabled]="isReportLocked()">
                    <mat-icon>file_download</mat-icon>
                    Gebote abfragen
                </a>
                <a
                    mat-menu-item
                    class="menu-item-with-icon"
                    (click)="residualValueExchange.downloadBidList({ regional: false })"
                    *ngIf="residualValueExchange.residualValueOffer?.offerId">
                    <mat-icon>description</mat-icon>
                    Gebotsblatt alle
                </a>
                <a
                    mat-menu-item
                    class="menu-item-with-icon"
                    (click)="residualValueExchange.downloadBidList({ regional: true })"
                    *ngIf="residualValueExchange.residualValueOffer?.offerId">
                    <mat-icon>description</mat-icon>
                    Gebotsblatt regional
                </a>
                <a
                    mat-menu-item
                    class="menu-item-with-icon"
                    *ngIf="residualValueExchange.residualValueOffer?.offerId"
                    (click)="residualValueExchange.resetResidualValueOffer(); saveReport()"
                    [disabled]="isReportLocked()">
                    <mat-icon>link_off</mat-icon>
                    Inserat entkoppeln
                </a>
                <!--********** car.casion does not allow deleting  **********-->
                <a
                    mat-menu-item
                    class="menu-item-with-icon"
                    *ngIf="residualValueExchange.residualValueOffer?.offerId"
                    (click)="deleteResidualValueOfferWithOptionalConfirmDialog(residualValueExchange.name)"
                    [disabled]="isReportLocked()">
                    <mat-icon>delete</mat-icon>
                    Inserat löschen
                </a>
                <a
                    mat-menu-item
                    class="menu-item-with-icon"
                    *ngIf="!residualValueExchange.residualValueOffer?.offerId"
                    (click)="residualValueExchange.openConnectResidualValueOfferDialog()"
                    [disabled]="isReportLocked()"
                    matTooltip="Verknüpfe ein {{
                        translateResidualValueProvider(residualValueExchange.name)
                    }}-Inserat mit autoiXpert.">
                    <mat-icon>link</mat-icon>
                    {{ translateResidualValueProvider(residualValueExchange.name) }}-Inserat verknüpfen
                </a>
            </mat-menu>
        </div>
    </div>
</div>
<!--============================================-->
<!-- Custom Bids -->
<!--============================================-->
<div id="custom-bids-container" class="residual-value-exchange-container">
    <div class="residual-value-exchange-header">
        <div class="residual-value-exchange-logo-container">
            Eigene
            <div
                class="residual-value-pending-indicator-icon-and-label"
                *ngIf="
                    report.valuation.autoixpertResidualValueOffer.closingDate &&
                    !liesInThePast(report.valuation.autoixpertResidualValueOffer.closingDate)
                "
                matTooltip="Gebotsfrist endet am {{
                    report.valuation.autoixpertResidualValueOffer.closingDate | moment: 'DD.MM.YYYY - HH:mm'
                }} Uhr.">
                <mat-icon class="residual-value-pending-indicator small-icon outlined">access_time</mat-icon>
                <div class="label">
                    {{ getDateRelativeFromNow(report.valuation.autoixpertResidualValueOffer.closingDate) }}
                </div>
            </div>
            <mat-icon
                class="residual-value-done-indicator small-icon"
                *ngIf="
                    report.valuation.autoixpertResidualValueOffer.closingDate &&
                    liesInThePast(report.valuation.autoixpertResidualValueOffer.closingDate)
                "
                matTooltip="Gebotsfrist am {{
                    report.valuation.autoixpertResidualValueOffer.closingDate | moment: 'DD.MM.YYYY - HH:mm'
                }} Uhr abgelaufen">
                check
            </mat-icon>
        </div>

        <div class="residual-value-exchange-toolbar">
            <!--********** Export Pending **********-->
            <img
                class="residual-value-pending-icon pending-icon toolbar-icon"
                src="/assets/images/icons/loading-indicator.svg"
                *ngIf="residualValueRequestExportPending"
                matTooltip="Exportiere..." />

            <!--********** Import Pending **********-->
            <img
                class="residual-value-pending-icon pending-icon toolbar-icon"
                src="/assets/images/icons/loading-indicator.svg"
                *ngIf="residualValueRequestImportPending"
                matTooltip="Importiere..." />

            <!--********** Number of bids **********-->
            <div
                class="number-of-bids"
                (click)="openBidSelector(false, 'own')"
                *ngIf="
                    (report.valuation.customResidualValueBids.length > 0 ||
                        report.valuation.autoixpertResidualValueOffer.closingDate) &&
                    !residualValueRequestImportPending
                "
                matTooltip="Eigene Gebote anzeigen">
                {{ report.valuation.customResidualValueBids | customBidsLabel }}
            </div>

            <!--********** Manually add bid **********-->
            <mat-icon
                class="toolbar-icon"
                *ngIf="
                    !isReportLocked() &&
                    !report.valuation.customResidualValueBids.length &&
                    !report.valuation.autoixpertResidualValueOffer.closingDate
                "
                (click)="openBidSelector(true)"
                matTooltip="Neues Gebot">
                add
            </mat-icon>

            <!--********** Invite Bidders **********-->
            <mat-icon
                class="residual-value-opener toolbar-icon"
                *ngIf="!residualValueRequestExportPending && !report.valuation.autoixpertResidualValueOffer.closingDate"
                [ngClass]="{ disabled: !isResidualValueOfferAllowed() || isReportLocked() }"
                (click)="openResidualValueRequestDialog()"
                [matTooltip]="getResidualValueRequestTooltipForExportIcon()">
                open_in_new
            </mat-icon>

            <!--********** More Options **********-->
            <mat-icon class="toolbar-icon" [matMenuTriggerFor]="residualValueExchangeSubMenu">more_vert</mat-icon>
            <mat-menu #residualValueExchangeSubMenu="matMenu">
                <a
                    mat-menu-item
                    class="menu-item-with-icon"
                    (click)="openResidualValueRequestDialog()"
                    *ngIf="
                        report.valuation.autoixpertResidualValueOffer &&
                        !report.valuation.autoixpertResidualValueOffer.closingDate
                    ">
                    <mat-icon>open_in_new</mat-icon>
                    Lokale Restwertanfrage stellen
                </a>
                <a
                    mat-menu-item
                    class="menu-item-with-icon"
                    (click)="openResidualValueRequestDialog()"
                    *ngIf="report.valuation.autoixpertResidualValueOffer.closingDate">
                    <mat-icon>mail</mat-icon>
                    Bieterliste
                </a>
                <a
                    mat-menu-item
                    class="menu-item-with-icon"
                    (click)="importResidualValueRequestBids()"
                    [ngClass]="{
                        disabled: doesAutoixpertResidualValueOfferBelongToOtherReport() || isReportLocked(),
                    }"
                    [matTooltip]="
                        doesAutoixpertResidualValueOfferBelongToOtherReport()
                            ? 'Du hast die Restwertgebote aus einem anderen Gutachten kopiert. Um neue Restwertgebote einzuholen, lösche die Anfrage und stelle eine neue Anfrage für dieses Gutachten.'
                            : ''
                    "
                    *ngIf="report.valuation.autoixpertResidualValueOffer.closingDate">
                    <mat-icon>file_download</mat-icon>
                    Gebote abfragen
                </a>
                <a
                    mat-menu-item
                    class="menu-item-with-icon"
                    (click)="openResidualValueRequestPreview()"
                    *ngIf="report.valuation.autoixpertResidualValueOffer.closingDate">
                    <mat-icon>visibility</mat-icon>
                    Inserat einsehen
                </a>
                <a
                    mat-menu-item
                    *ngIf="report.valuation.autoixpertResidualValueOffer.closingDate"
                    class="menu-item-with-icon"
                    (click)="deleteResidualValueRequest()"
                    [ngClass]="{
                        disabled: isReportLocked(),
                    }"
                    [matTooltip]="isReportLocked() ? 'Gutachten bereits abgeschlossen.' : ''">
                    <mat-icon>delete</mat-icon>
                    Anfrage löschen
                </a>
            </mat-menu>
        </div>
    </div>
</div>
<!--============================================-->
<!-- END Custom Bids -->
<!--============================================-->

<!--============================================-->
<!-- Selected Bids -->
<!--============================================-->
<div id="selected-bids-container" *ngIf="!showValuationResult && selectedBids.length > 0">
    <span (click)="openBidSelector(false)" matTooltip="Ausgewählte Gebote. Werden im Gutachten abgedruckt.">
        <mat-icon class="outlined">check_circle</mat-icon>
        {{ selectedBids | selectedBidsSummaryLabel }}
    </span>
</div>

<div id="valuation-result-container" *ngIf="showValuationResult && allBids.length > 0">
    <div id="highest-bid" *ngIf="report.valuation.residualValue">
        <div
            (click)="!isReportLocked() && openBidSelector(false)"
            [ngClass]="{ 'cursor-pointer': !isReportLocked() }"
            matTooltip="Ausgewählte Gebote. Werden im Gutachten abgedruckt.">
            <span class="highest-bid-label label">Höchstgebot</span>
            <span *ngIf="selectedBids.length > 0" class="label">
                {{ selectedBids.length }} {{ selectedBids.length === 1 ? 'Gebot' : 'Gebote' }} ausgewählt
            </span>
            <span *ngIf="selectedBids.length === 0 && allBids.length > 0" class="label">
                {{ allBids.length }} {{ allBids.length === 1 ? 'Gebot' : 'Gebote' }}
            </span>
        </div>
        <div
            class="value"
            (click)="
                setVehicleValueToHighestBid(selectedBids.length > 1 ? selectedBids[0].origin : 'custom'); saveReport()
            "
            [matTooltip]="!isReportLocked() && report.type === 'valuation' ? 'Fahrzeugwert übernehmen' : ''"
            [ngClass]="{ 'cursor-pointer': !isReportLocked() && report.type === 'valuation' }">
            {{ report.valuation.residualValue | number: '1.2-2' }}
            &euro;
        </div>
    </div>
</div>
<!--============================================-->
<!-- END Selected Bids -->
<!--============================================-->

<!--============================================-->
<!-- Residual Value Results -->
<!--============================================-->
<!--============================================-->
<!-- END Residual Value Results -->
<!--============================================-->

<!--============================================-->
<!-- Custom Fields -->
<!--============================================-->
<custom-field-display
    fieldLocation="damage-calculation_residual-value"
    [report]="report"
    (reportChange)="saveReport()"
    [disabled]="isReportLocked()"></custom-field-display>
<!--============================================-->
<!-- END Custom Fields -->
<!--============================================-->

<bid-selector
    *ngIf="bidSelectorShown"
    [report]="report"
    (close)="hideBidSelector()"
    (highestBidSelected)="processHighestSelectedBid($event); saveReport()"
    [startInCreationMode]="bidSelectorStartInCreationMode"
    [initialFilter]="bidSelectorFilter"
    [(useRegionalBidsOnly)]="report.valuation.useRegionalBidsOnly"
    (useRegionalBidsOnlyChange)="saveReport()"
    [disabled]="isReportLocked()"
    @runChildAnimations></bid-selector>

<residual-value-request-dialog
    *ngIf="residualValueRequestDialogShown"
    [report]="report"
    [residualValueTargetDate]="this.report.valuation.residualValueInquiryTargetDate || this.residualValueTargetDate"
    (reportChange)="saveReport()"
    (close)="hideResidualValueRequestDialog()"
    (deleteResidualValueExchange)="deleteResidualValueRequest()"></residual-value-request-dialog>

<winvalue-offer-connection-dialog
    *ngIf="winvalueOfferConnectionDialogShown"
    [winvalueUser]="user.winvalueUser"
    (close)="closeWinvalueOfferConnectionDialog()"
    (connectedOffer)="connectWinvalueOffer($event)"></winvalue-offer-connection-dialog>

<cartv-offer-connection-dialog
    *ngIf="cartvOfferConnectionDialogShown"
    (close)="closeCartvOfferConnectionDialog()"
    (connectedOffer)="connectCartvOffer($event)"></cartv-offer-connection-dialog>

<autoonline-offer-connection-dialog
    *ngIf="autoonlineOfferConnectionDialogShown"
    (close)="closeAutoonlineOfferConnectionDialog()"
    (connectedOffer)="connectAutoonlineOffer($event)"></autoonline-offer-connection-dialog>

<carcasion-offer-connection-dialog
    *ngIf="carcasionOfferConnectionDialogShown"
    (close)="closeCarcasionOfferConnectionDialog()"
    (connectedOffer)="connectCarcasionOffer($event)"></carcasion-offer-connection-dialog>

<div class="dialog-overlay">
    <div class="dialog-container">
        <mat-icon class="dialog-close-icon" (click)="closeDialog()">close</mat-icon>
        <h2>Vorlagentitel</h2>
        <form>
            <mat-form-field class="mat-block">
                <input
                    matInput
                    placeholder="Vorlagentitel"
                    [(ngModel)]="title"
                    [ngModelOptions]="{ standalone: true }" />
            </mat-form-field>
            <div id="buttons-container">
                <button type="submit" (click)="emitUseTitleEvent()">Übernehmen</button>
                <button class="secondary" (click)="closeDialog()">Abbrechen</button>
            </div>
        </form>
    </div>
</div>

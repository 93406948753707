import { generateId } from '../../../lib/generate-id';
import { DataTypeBase } from '../../indexed-db/database.types';

export class CarEquipmentPosition {
    constructor(template: Partial<CarEquipmentPosition> = {}) {
        Object.assign(this, template);
    }

    _id: string = generateId();
    description: string = '';
    type: 'series' | 'special' | 'additional' = 'additional';
    containedEquipmentPositions: CarEquipmentPosition[];
    datEquipmentId?: string;
    datEquipmentGroup?: string;
    // Some equipment positions are used (and shown in the respective DAT user interface) only by one of the 2 DAT systems. Make that transprent to the
    // user so he does not wonder why a certain equipment position is missing.
    isDatValuationOnly?: boolean;
    isDatCalculationOnly?: boolean;

    audatexEquipmentId?: string;
    gtmotiveEquipmentId?: string;
}

export class CarEquipment implements DataTypeBase {
    constructor(template: Partial<CarEquipment> = {}) {
        Object.assign(this, template);
    }

    _id: string = generateId();
    equipmentPositions: CarEquipmentPosition[] = [];
    customPossibleEquipmentPositions: CarEquipmentPosition[] = []; // If the user added custom equipments and moved them to the possible equipments array (left side in the UI), they are saved here.
    reportId: string; // To which report does this car equipment belong?
    _documentVersion: number = 0;
    _schemaVersion = 1 as const;
    createdBy: string;
    teamId: string;
    createdAt: string;
    updatedAt: string;
}

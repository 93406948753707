import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { DateAdapter, MAT_DATE_FORMATS } from '@angular/material/core';
import { MatDatepicker, MatDatepickerInputEvent } from '@angular/material/datepicker';
import { Moment } from 'moment';
import { toIsoDate } from '@autoixpert/lib/date/iso-date';
import { IsoDate } from '@autoixpert/lib/date/iso-date.types';
import { AxDateAdapter } from '../date-input/ax-date-adapter';
import { AX_MOMENT_MONTH_FORMATS } from './ax-moment-month-formats';

@Component({
    selector: 'month-input',
    templateUrl: 'month-input.component.html',
    providers: [
        {
            provide: DateAdapter,
            useClass: AxDateAdapter,
        },
        {
            provide: MAT_DATE_FORMATS,
            useValue: AX_MOMENT_MONTH_FORMATS,
        },
    ],
})
export class MonthInputComponent {
    @Input('date') dateString: string = '';
    @Output() dateChange: EventEmitter<IsoDate> = new EventEmitter();
    @Output() focus: EventEmitter<FocusEvent> = new EventEmitter();
    @Input() placeholder: string = 'Datum';
    @Input() hint: string;
    @Input() warning: string;
    @Input() disabled: boolean = false;
    @Input() autofocus: boolean = false;

    @ViewChild('datepicker', { static: true }) datepicker: MatDatepicker<Moment>;

    public handleInputMonthSelection(event: MatDatepickerInputEvent<Moment>): void {
        if (event.value && event.value.isValid()) {
            this.dateChange.emit(toIsoDate(event.value.startOf('month').format()));
        } else {
            this.dateChange.emit(null);
        }
        this.datepicker.close();
    }

    /**
     * Called when the user selects a month on the datepicker.
     * @param event
     */
    public handleDatepickerMonthSelection(event: Moment): void {
        if (event && event.isValid()) {
            this.dateChange.emit(toIsoDate(event.format('YYYY-MM-DD')));
        } else {
            this.dateChange.emit(null);
        }
        this.datepicker.close();
    }

    public emitFocusEvent(event: FocusEvent): void {
        this.focus.emit(event);
    }
}

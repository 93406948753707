import { HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { EMPTY as observableEMPTY, throwError as observableThrowError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { AxError } from '@autoixpert/models/errors/ax-error';
import { logoutAfterNotAuthenticatedError } from '../libraries/logout-after-not-authenticated-error';
import { sendJwtToOtherAutoixpertDomain } from '../libraries/send-jwt-to-other-autoixpert-domain';
import { ApiErrorService } from '../services/api-error.service';
import { AuthenticationService } from '../services/authentication.service';
import { LoggedInUserService } from '../services/logged-in-user.service';

/**
 * This Interceptor makes sure that the user gets logged out immediately if he does not have a valid jwt anymore.
 */
@Injectable()
export class JwtVerificationInterceptor implements HttpInterceptor {
    constructor(
        private loggedInUserService: LoggedInUserService,
        private authenticationService: AuthenticationService,
        private apiErrorService: ApiErrorService,
        private router: Router,
    ) {}

    /**
     * Log out a user if he is unauthorized.
     *
     * That is the case if, e.g.
     * - the JWT expired
     * - the JTW has been deleted
     *
     * If the user requested an image file from the server, the Angular HttpClient
     * is configured to return a blob response. In that case, the error is inside
     * a Blob too. Therefore,
     * 1) If it is a blob, extract its content
     * 2) Check the error code
     * 3) Log out user if unauthorized
     */
    intercept(request: HttpRequest<any>, next: HttpHandler) {
        return next.handle(request).pipe(
            catchError((error: AxError) => {
                // Check for authentication
                if (error.code === 'AX_NOT_AUTHENTICATED') {
                    // If the user's authenticity cannot be verified, redirect him to the login screen
                    logoutAfterNotAuthenticatedError({
                        loggedInUserService: this.loggedInUserService,
                    });
                    throw error;
                }
                //*****************************************************************************
                //  Production-Beta-Link
                //****************************************************************************/
                else if (
                    [
                        'ACCOUNT_IS_ACTIVE_IN_PRODUCTION_ENVIRONMENT_NOT_BETA',
                        'ACCOUNT_IS_ACTIVE_IN_BETA_ENVIRONMENT_NOT_PRODUCTION',
                    ].includes(error.code)
                ) {
                    /**
                     * If multiple requests failed, there is no need for multiple info toasts.
                     * Complete the subscribers to the original HttpClient call
                     */
                    if ((window as any).isEnvironmentChangeActive) return observableEMPTY;

                    (window as any).isEnvironmentChangeActive = true;

                    this.apiErrorService.handleAndRethrow({
                        axError: error,
                        handlers: {
                            ACCOUNT_IS_ACTIVE_IN_PRODUCTION_ENVIRONMENT_NOT_BETA: {
                                title: 'Umgebungswechsel',
                                body: 'Du wirst gleich auf app.autoixpert.de weitergeleitet.',
                                toastType: 'info',
                                // Don't close this message.
                                timeout: 0,
                                action: async () => {
                                    try {
                                        await Promise.all([
                                            sendJwtToOtherAutoixpertDomain({
                                                domain: 'app.autoixpert.de',
                                                jwt: this.authenticationService.getLocalJwt(),
                                            }),
                                            // Wait for some time so that the user has the chance to read the message.
                                            new Promise((resolve) => setTimeout(resolve, 3000)),
                                        ]);
                                    } catch (error) {
                                        console.error('Error redirecting the user to app.autoixpert.de', { error });
                                    }
                                },
                                toastClick: () => {
                                    window.location.href = `https://app.autoixpert.de/Login?useCrossDomainLogin=true&forwardUrl=${encodeURIComponent(
                                        // In case the URI was previously encoded, decode it first. Otherwise, it would be double-encoded.
                                        decodeURIComponent(this.router.url),
                                    )}`;
                                },
                            },
                            ACCOUNT_IS_ACTIVE_IN_BETA_ENVIRONMENT_NOT_PRODUCTION: {
                                title: 'Neu: Offline-Fähigkeit & Live-Sync 🎉',
                                body: 'Du bist schon für die neue Version unter offline.autoixpert.de freigeschaltet, wo auch schon deine Daten liegen 🚀<br><br>In den nächsten Wochen werden auch die anderen autoiXpert-Nutzer Stück für Stück umgestellt. Danach ist die neue autoiXpert-Version wieder wie bisher über app.autoixpert.de erreichbar.<br><br>Klicke, um auf <strong>offline.autoixpert.de</strong> zu gelangen.',
                                toastType: 'info',
                                // Don't close this message.
                                timeout: 0,
                                action: async () => {
                                    try {
                                        await Promise.all([
                                            sendJwtToOtherAutoixpertDomain({
                                                domain: 'offline.autoixpert.de',
                                                jwt: this.authenticationService.getLocalJwt(),
                                            }),
                                            // Wait for some time so that the user has the chance to read the message.
                                            new Promise((resolve) => setTimeout(resolve, 5000)),
                                        ]);
                                    } catch (error) {
                                        console.error('Error redirecting the user to offline.autoixpert.de', { error });
                                    }
                                },
                                toastClick: () => {
                                    window.location.href = `https://offline.autoixpert.de/Login?useCrossDomainLogin=true&forwardUrl=${encodeURIComponent(
                                        // In case the URI was previously encoded, decode it first. Otherwise, it would be double-encoded.
                                        decodeURIComponent(this.router.url),
                                    )}`;
                                },
                            },
                        },
                        defaultHandler: {
                            title: 'Unbekannter Fehler bei Authentifizierung',
                            body: 'Bitte melde dich bei der <a href="/Hilfe" target="_blank">Hotline</a>.',
                        },
                    });
                }
                /////////////////////////////////////////////////////////////////////////////*/
                //  END Production-Beta-Link
                /////////////////////////////////////////////////////////////////////////////*/
                else {
                    /**
                     * Let the subscribers know that they failed. They should swallow this error since they should not handle logging out. Swallowing is done automatically if
                     * there is an ApiErrorService.handle() call in the catch block. That should be the case everywhere.
                     */
                    return observableThrowError(error);
                }
            }),
        );
    }
}

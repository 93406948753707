import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Router } from '@angular/router';
import { dialogEnterAndLeaveAnimation } from '@autoixpert/animations/dialog-enter-and-leave.animation';
import { todayIso } from '@autoixpert/lib/date/iso-date';
import { getUnpaidAmount } from '@autoixpert/lib/invoices/get-unpaid-amount';
import { getFullRecipientName } from '@autoixpert/lib/placeholder-values/get-full-recipient-name';
import { ContactPerson } from '@autoixpert/models/contacts/contact-person';
import { Invoice } from '@autoixpert/models/invoices/invoice';
import { LineItem } from '@autoixpert/models/invoices/line-item';
import { Payment } from '@autoixpert/models/invoices/payment';
import { ApiErrorService } from '../../shared/services/api-error.service';
import { InvoiceCancellationService } from '../../shared/services/invoice-cancellation.service';
import { InvoiceService } from '../../shared/services/invoice.service';
import { LoggedInUserService } from '../../shared/services/logged-in-user.service';
import { ToastService } from '../../shared/services/toast.service';
import { UserPreferencesService } from '../../shared/services/user-preferences.service';

@Component({
    selector: 'partial-invoice-cancellation-dialog',
    templateUrl: './partial-invoice-cancellation-dialog.component.html',
    styleUrls: ['./partial-invoice-cancellation-dialog.component.scss'],
    animations: [dialogEnterAndLeaveAnimation()],
})
export class PartialInvoiceCancellationDialogComponent {
    constructor(
        public userPreferences: UserPreferencesService,
        private invoiceService: InvoiceService,
        private invoiceCancellationService: InvoiceCancellationService,
        private apiErrorService: ApiErrorService,
        private toastService: ToastService,
        private loggedInUserService: LoggedInUserService,
        private router: Router,
    ) {}

    @Input() originalInvoice: Invoice;
    @Input() initialGrossValue: number;

    @Output() cancellationInvoiceCreated: EventEmitter<Invoice> = new EventEmitter();
    @Output() close: EventEmitter<void> = new EventEmitter();

    public netValue: number;
    public grossValue: number;
    public invoiceLineItemTitle: string;
    public internalNote: string;
    public isShortPayment: string;

    // Loading states
    public amountConversionDirection: 'netToGross' | 'grossToNet';
    public isPartialCancellationInvoiceBeingGenerated: boolean;

    public isInternalNoteTextTemplateSelectorVisible: boolean;

    ngOnInit() {
        this.fillCancellationInvoiceAmounts();

        this.invoiceLineItemTitle = this.userPreferences.partialCancellationInvoiceLineItemTitle;
    }

    //*****************************************************************************
    //  Original Invoice Recipient
    //****************************************************************************/
    public getShortRecipientName(contactPerson: ContactPerson): string {
        return contactPerson.organization
            ? contactPerson.organization
            : `${contactPerson.firstName || ''} ${contactPerson.lastName || ''}`.trim();
    }

    public getFullRecipientName(contactPerson: ContactPerson): string {
        return getFullRecipientName({ contactPerson: contactPerson }).join(' | ');
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Original Invoice Recipient
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Translate Net & Gross
    //****************************************************************************/
    /**
     * From the original invoice, we can derive the amount this cancellation invoice should cancel out.
     */
    public fillCancellationInvoiceAmounts() {
        this.grossValue = this.initialGrossValue;

        const unsettledAmount: number = getUnpaidAmount(this.originalInvoice);

        // Don't insert the full invoice total. If a user has not recorded a payment yet, it's very unlikely he wants to cancel out the full invoice amount.
        if (!this.grossValue && unsettledAmount !== this.originalInvoice.totalGross) {
            this.grossValue = unsettledAmount;
        }

        if (this.grossValue) {
            this.translateGrossToNet({ displayAnimation: false });
        }
    }

    public translateNetToGross({ displayAnimation }: { displayAnimation: boolean }) {
        if (!this.netValue) {
            this.grossValue = null;
            return;
        }

        this.grossValue = this.netValue * (1 + this.originalInvoice.vatRate);

        if (displayAnimation) {
            this.amountConversionDirection = 'netToGross';
            window.setTimeout(() => (this.amountConversionDirection = null), 800);
        }
    }
    public translateGrossToNet({ displayAnimation }: { displayAnimation: boolean }) {
        if (!this.grossValue) {
            this.netValue = null;
            return;
        }

        this.netValue = this.grossValue / (1 + this.originalInvoice.vatRate);
        if (displayAnimation) {
            this.amountConversionDirection = 'grossToNet';
            window.setTimeout(() => (this.amountConversionDirection = null), 800);
        }
    }
    /////////////////////////////////////////////////////////////////////////////*/
    //  END Translate Net & Gross
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  User Preferences
    //****************************************************************************/
    public rememberTitleOfInvoiceLineItem() {
        this.userPreferences.partialCancellationInvoiceLineItemTitle = this.invoiceLineItemTitle;
        this.toastService.success(
            'Titel gemerkt',
            'Der Titel wird bei der nächsten Teilgutschrift automatisch vorbelegt.',
        );
    }
    /////////////////////////////////////////////////////////////////////////////*/
    //  END User Preferences
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Graph - Percentage of Original Invoice
    //****************************************************************************/

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Graph - Percentage of Original Invoice
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Create Partial Cancellation Invoice
    //****************************************************************************/
    public async createPartialCancellationInvoice() {
        if (!this.netValue) {
            this.toastService.error('Betrag fehlt', 'Der Betrag ist erforderlich für eine Stornorechnung.');
            return;
        }

        this.isPartialCancellationInvoiceBeingGenerated = true;

        //*****************************************************************************
        //  Generate and Send to Server a Credit Note / Cancellation Invoice
        //****************************************************************************/
        let cancellationInvoice: Invoice;
        try {
            cancellationInvoice = await this.invoiceCancellationService.createPartialCancellationInvoice({
                rootInvoice: this.originalInvoice,
                lineItems: [
                    new LineItem({
                        quantity: 1,
                        unitPrice: Math.abs(this.netValue) * -1,
                        unit: 'Pauschal',
                        position: 1,
                        active: true,
                        description: this.invoiceLineItemTitle || 'Gutschrift',
                    }),
                ],
            });
        } catch (error) {
            this.apiErrorService.handleAndRethrow({
                axError: error,
                handlers: {},
                defaultHandler: {
                    title: `Teilgutschrift nicht angelegt`,
                    body: `Bitte versuche es erneut oder kontaktiere die <a href='/Hilfe'>Hotline</a>.`,
                },
            });
        }
        /////////////////////////////////////////////////////////////////////////////*/
        //  END Generate and Send to Server a Credit Note / Cancellation Invoice
        /////////////////////////////////////////////////////////////////////////////*/

        //*****************************************************************************
        //  Add Cancellation Payment to Original Invoice
        //****************************************************************************/
        const cancellationPayment: Payment = new Payment({
            type: this.isShortPayment ? 'shortPayment' : 'partialCancellation',
            shortPaymentStatus: this.isShortPayment ? 'writtenOff' : null,
            // Invoice total is negative in a cancellation invoice. The payment must be positive though to take the place of a payment, marking the amount as not outstanding any more.
            amount: Math.abs(cancellationInvoice.totalGross),
            notes: this.internalNote,
            cancellationInvoiceId: cancellationInvoice._id,
            date: todayIso(),
            createdBy: this.loggedInUserService.getUser().createdBy,
        });

        try {
            await this.invoiceService.addPaymentToInvoice({
                targetInvoice: this.originalInvoice,
                newPayment: cancellationPayment,
            });
        } catch (error) {
            this.apiErrorService.handleAndRethrow({
                axError: error,
                handlers: {},
                defaultHandler: {
                    title: `Zahlung auf ursprünglicher Rechnung nicht hinzugefügt`,
                    body: `Bei Teilgutschriften sollte auch auf der originalen Rechnung ein Vermerk in der Zahlungsliste hinzugefügt werden. Das ist gescheitert. Bitte kontaktiere die <a href='/Hilfe'>Hotline</a>.`,
                },
            });
        }

        /////////////////////////////////////////////////////////////////////////////*/
        //  END Add Cancellation Payment to Original Invoice
        /////////////////////////////////////////////////////////////////////////////*/

        this.isPartialCancellationInvoiceBeingGenerated = false;
        const toast = this.toastService.success('Gutschrift erzeugt', 'Klicke, um sie zu öffnen.');
        toast.click.subscribe({
            next: () => {
                this.router.navigate(['Rechnungen', cancellationInvoice._id]);
            },
        });
        this.cancellationInvoiceCreated.emit(cancellationInvoice);

        this.closeDialog();
    }
    /////////////////////////////////////////////////////////////////////////////*/
    //  END Create Partial Cancellation Invoice
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Navigation
    //****************************************************************************/
    public openInvoice(invoiceId: string): void {
        window.open(`/Rechnungen/${invoiceId}`);
    }
    /////////////////////////////////////////////////////////////////////////////*/
    //  END Navigation
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Events
    //****************************************************************************/
    public handleOverlayClick(event: MouseEvent): void {
        // Only close editor if the overlay has been clicked directly. Ignore bubbling events from the dialog.
        if (event.target === event.currentTarget) {
            this.closeDialog();
        }
    }

    public closeDialog() {
        this.close.emit();
    }
    /////////////////////////////////////////////////////////////////////////////*/
    //  END Events
    /////////////////////////////////////////////////////////////////////////////*/
}

import { isAudatexUserComplete } from '@autoixpert/lib/users/is-audatex-user-complete';
import { Report } from '@autoixpert/models/reports/report';
import { User } from '@autoixpert/models/user/user';
import { isAudatexValuationInputDataComplete } from './is-audatex-valuation-input-data-complete';
import { isAudatexValuationPossible } from './is-audatex-valuation-possible';

export function getAudatexValuationExportTooltip({ report, user }: { report: Report; user: User }): string {
    if (!isAudatexUserComplete(user)) {
        return 'Die Zugangsdaten müssen erst in den Einstellungen eingegeben werden.';
    }

    if (report.state === 'done') {
        return 'Das Gutachten ist abgeschlossen';
    }

    if (!report.audatexTaskId) {
        return 'Bitte zuerst eine VIN-Abfrage mit Audatex durchführen.';
    }

    if (!isAudatexValuationInputDataComplete(report)) {
        const missingInformation: string[] = [];

        if (!report.car.firstRegistration) {
            missingInformation.push('Erstzulassung');
        }
        if (!(report.car.mileage || report.car.mileageMeter || report.car.mileageAsStated)) {
            missingInformation.push('Laufleistung');
        }

        let missingInformationText: string;
        if (missingInformation.length === 1) {
            missingInformationText = missingInformation[0];
        } else {
            missingInformationText = `${missingInformation.slice(0, -1).join(', ')} & ${
                missingInformation[missingInformation.length - 1]
            }`;
        }
        if (missingInformation.includes('DAT-€-Code') || missingInformation.includes('DAT-Marktindex')) {
            return `Bitte ${missingInformationText} angeben. DAT-Codes bekommst du durch eine VIN-Abfrage bei der DAT.`;
        }

        return `Bitte ${missingInformationText} angeben.`;
    }

    if (!isAudatexValuationPossible(report)) {
        return 'Audatex bietet für dieses Fahrzeug keine Bewertung an.';
    }

    return 'Fahrzeugbewertung öffnen';
}

import { PointGroup } from 'signature_pad';

export function transformSignaturePadDataPoints(pointGroups: PointGroup[], scalingFactor: number): PointGroup[] {
    return (pointGroups ?? []).map((pointGroup) => ({
        ...pointGroup,
        points: pointGroup.points.map((point) => ({
            // Not scaling the time and pressure leads to a smoother line.
            time: point.time,
            pressure: point.pressure,
            x: point.x * scalingFactor,
            y: point.y * scalingFactor,
        })),
        minWidth: pointGroup.minWidth * scalingFactor,
        maxWidth: pointGroup.maxWidth * scalingFactor,
        // When transforming the dot size as well, the signature may effectively be missing some segments.
        // dotSize: pointGroup.dotSize * scalingFactor,
    }));
}

import moment, { Moment } from 'moment';
import { Invoice } from '../../models/invoices/invoice';
import { PaymentStatus } from '../../models/invoices/payment-status';
import { todayIso } from '../date/iso-date';
import { IsoDate } from '../date/iso-date.types';
import { round } from '../numbers/round';
import { getCanceledAmount } from './get-canceled-amount';
import { getPaidAmount } from './get-paid-amount';
import { getWrittenOffAmount } from './get-written-off-amount';

/**
 * Get the payment status displayed in the invoice list.
 */
export function getPaymentStatus(
    invoice: Pick<
        Invoice,
        | 'type'
        | 'totalGross'
        | 'idsOfCancellationInvoices'
        | 'payments'
        | 'documents'
        | 'dueDate'
        | 'isFullCancellationInvoice'
        | 'hasPaymentReminder'
    >,
    // Improve performance by passing the current date as an argument
    todayAsIsoDate?: IsoDate,
): PaymentStatus {
    if (!todayAsIsoDate) {
        todayAsIsoDate = todayIso();
    }

    /**
     * Handle special cases first (invoices with negative amount)
     */
    if (invoice.isFullCancellationInvoice) {
        return 'fullCancellation';
    }
    if (invoice.type === 'creditNote') {
        return 'creditNote';
    }

    const invoiceTotal: number = round(invoice.totalGross ?? 0);
    const canceledAmount: number = getCanceledAmount(invoice);

    // Fully canceled invoice
    if (invoice.idsOfCancellationInvoices.length) {
        if (canceledAmount >= invoiceTotal) {
            return 'fullyCanceled';
        }
    }

    const writtenOffAmount: number = getWrittenOffAmount(invoice);
    const totalAfterCancellations: number = round(invoiceTotal - canceledAmount - writtenOffAmount);

    // Paid amount
    const paidAmount: number = getPaidAmount(invoice);

    // Paid status
    if (paidAmount === totalAfterCancellations) return 'paid';

    // Overpaid
    if (paidAmount > 0 && paidAmount > totalAfterCancellations) return 'overpaid';

    // Short pay
    // if (invoice.payments.some(payment => payment.isShortPay)) return 'shortened';

    //*****************************************************************************
    //  Payment Reminders
    //****************************************************************************/
    if (invoice.hasPaymentReminder) {
        if (invoice.documents.find((document) => document.type === 'paymentReminderLevel3'))
            return 'paymentReminderLevel3';
        if (invoice.documents.find((document) => document.type === 'paymentReminderLevel2'))
            return 'paymentReminderLevel2';
        if (invoice.documents.find((document) => document.type === 'paymentReminderLevel1'))
            return 'paymentReminderLevel1';
        if (invoice.documents.find((document) => document.type === 'paymentReminderLevel0'))
            return 'paymentReminderLevel0';
    }
    /////////////////////////////////////////////////////////////////////////////*/
    //  END Payment Reminders
    /////////////////////////////////////////////////////////////////////////////*/

    // Partially paid
    if (paidAmount > 0 && paidAmount < totalAfterCancellations) return 'partiallyPaid';

    // (Open) due or overdue
    if (paidAmount <= 0) {
        // Due date lies in the past
        if (invoice.dueDate < todayAsIsoDate) return 'overdue';

        // due date lies in the future
        return 'due';
    }
}

import {
    AfterViewInit,
    ChangeDetectorRef,
    Component,
    ElementRef,
    EventEmitter,
    HostListener,
    Input,
    NgZone,
    OnChanges,
    OnDestroy,
    OnInit,
    Output,
    SimpleChange,
    SimpleChanges,
    ViewChild,
} from '@angular/core';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { Moment } from 'moment';
import { FileUploader } from 'ng2-file-upload';
import { toDataURL } from 'qrcode';
import { Subject, fromEvent, timer } from 'rxjs';
import { distinctUntilChanged, map, switchMap, take, takeUntil } from 'rxjs/operators';
import SignaturePad, { PointGroup } from 'signature_pad';
import { fadeInAndOutAnimation } from '@autoixpert/animations/fade-in-and-out.animation';
import { base64toBlob } from '@autoixpert/lib/base64-to-blob';
import { detectBrowser } from '@autoixpert/lib/browser/detect-browser';
import { dataUrlToBase64 } from '@autoixpert/lib/data-url-to-base64';
import { toIsoDate, todayIso } from '@autoixpert/lib/date/iso-date';
import { IsoDate } from '@autoixpert/lib/date/iso-date.types';
import { deviceHasSmallScreen } from '@autoixpert/lib/device-detection/device-has-small-screen';
import { getImageDimensions } from '@autoixpert/lib/images/get-image-dimensions';
import { scaleImageToFit } from '@autoixpert/lib/images/scale-image-to-fit';
import { getClaimantSignatureFileName } from '@autoixpert/lib/signature-pad/get-claimant-signature-file-name';
import { transformSignaturePadDataPoints } from '@autoixpert/lib/signature-pad/transform-data-points';
import { simpleHash } from '@autoixpert/lib/simple-hash';
import { translateDocumentType } from '@autoixpert/lib/translators/translate-document-type';
import { Report } from '@autoixpert/models/reports/report';
import { ClaimantSignature } from '@autoixpert/models/signable-documents/claimant-signature';
import { AbstractApiErrorService } from '../../abstract-services/api-error.abstract.service';
import { AbstractClaimantSignatureFileService } from '../../abstract-services/claimant-signature-file.abstract.service';
import { AbstractToastService } from '../../abstract-services/toast.abstract.service';
import { AxError } from '../../models/errors/ax-error';

/**
 * Adds a canvas to capture a signature.
 *
 * Loads an existing signature in read-only mode.
 */
@Component({
    selector: 'signature-pad',
    templateUrl: './signature-pad.component.html',
    styleUrls: ['./signature-pad.component.scss'],
    exportAs: 'signaturePad',
    animations: [fadeInAndOutAnimation()],
})
export class SignaturePadComponent implements OnInit, OnChanges, AfterViewInit, OnDestroy {
    /**
     * File name of the signature on S3 is ${reportId}-${documentType} (text block document) or ${reportId}-${documentType}-${signatureSlot} (signable PDF templates).
     * Hence, the prefix is the reportId for now.
     */
    @Input() reportId: Report['_id'];
    @Input() signerName: string;
    @Input() signerOrganization: string;

    @Input() signatureDate: ClaimantSignature['date'];
    @Output() signatureDateChange: EventEmitter<ClaimantSignature['date']> = new EventEmitter();
    /**
     * All claimant signatures for which the content of the canvas shall be uploaded.
     * Usually, this is only one document. But the user may sign multiple documents at once too.
     */
    @Input() claimantSignatures: ClaimantSignature[];
    @Input() displayOrientationLine: boolean = true; // Whether to display a horizontal orientation line for the signer.

    // Dimensions in pixels
    @Input() canvasWidth: number = 375;
    @Input() canvasHeight: number = 150;

    // Shall the frame (border) of the signature pad be highlighted for better visibility?
    @Input() frameHighlighted: boolean;

    // Disabled mode
    /**
     * The signaturePad may be disabled. No interaction will be possible (no signing, no deletion, no changing dates).
     * The reason will be displayed to the user so that they know how to proceed (e.g., sign all documents at once later on).
     */
    @Input() disabledReason: 'reportLocked' | 'signAllAtOnceActive' | 'deadlinePassed' | 'signatureSubmitted'; // If there's a central signature pad, others on PDF templates may be disabled with a note to sign on the central signature pad.
    @Input() signaturePadFullscreenMode: 'allOptions' | 'signatureOnly' = 'signatureOnly';
    @Input() disableSignatureTransfer = false;

    @Output() signaturePadCreated: EventEmitter<SignaturePadComponent> = new EventEmitter();
    @Output() signatureSaved: EventEmitter<ClaimantSignature[]> = new EventEmitter();
    @Output() signatureEndStroke: EventEmitter<ClaimantSignature[]> = new EventEmitter();
    @Output() signatureFilesDeleted: EventEmitter<ClaimantSignature[]> = new EventEmitter();
    @Output() signaturePadFullscreenChange: EventEmitter<'draw' | 'upload' | 'smartphone' | false> = new EventEmitter();
    @Output() transferSignature = new EventEmitter<{ signaturePad: SignaturePadComponent }>();

    @ViewChild('signatureCanvasSmall', { static: true }) public signatureCanvasSmall: ElementRef = null;
    @ViewChild('signatureCanvasOffscreen', { static: true }) public signatureCanvasOffscreen: ElementRef = null;
    @ViewChild('signatureCanvasFullscreen') public signatureCanvasFullscreen: ElementRef = null; // Use an almost full screen signature pad to place a signature on the assignment declaration.
    public signaturePadSmall: SignaturePad;
    public signaturePadOffscreen: SignaturePad;
    public signatureScalingFactor: number = 2;
    public signaturePadFullscreen: SignaturePad;
    public signaturePadFullscreenShown: 'draw' | 'upload' | 'smartphone' | false = false;
    public savingClaimantSignaturePending: boolean = false;
    public signatureDownloadPending: boolean = false;
    public signatureImageBinaryFileAsDataUrl: string; // Data URL. PNG that's loaded from the server or has been added through drag and drop.

    // Has a new signature been drawn or dropped?
    public signatureImageHasChanged: boolean;

    // Drag & Drop Signature
    public fileOverSignaturePadTimeoutCache: number;
    public fileIsOverSignaturePad: boolean;

    // Uploader
    public uploader = new FileUploader({
        url: undefined,
    });

    // Detect browser to provide specific error message if upload fails
    private browser: string;

    // Touch UI of datepicker
    public isTablet: boolean = deviceHasSmallScreen();

    // QR-Code smartphone hand-off
    protected qrCodeDataUrl: SafeUrl;

    /**
     * Subject used to subscribe to observables with takeUntil only until
     * this subject gets destroyed (in onDestroy lifecycle method or manually).
     */
    private destroyWindowResizeListener: Subject<void> = new Subject<void>();

    protected toolbarShown: boolean = false;

    constructor(
        private domSanitizer: DomSanitizer,
        public readonly elRef: ElementRef<HTMLElement>,
        private claimantSignatureFileService: AbstractClaimantSignatureFileService,
        private apiErrorService: AbstractApiErrorService,
        private toastService: AbstractToastService,
        private changeDetectorRef: ChangeDetectorRef,
        private ngZone: NgZone,
        private readonly elementRef: ElementRef,
    ) {}

    @HostListener('document:click', ['$event'])
    @HostListener('document:touchstart', ['$event'])
    handleOutsideClick(event) {
        if (!this.elementRef.nativeElement.contains(event.target)) {
            this.toolbarShown = false;
        }
    }

    ngOnInit() {
        // Signature Pad
        this.initializeSignaturePadSmall();

        // Detect browser to provide specific error message if upload fails
        this.browser = detectBrowser().browser;

        // Use the signature's date or now.
        this.signatureDate = this.claimantSignatures[0]?.date || todayIso();
    }

    ngOnChanges(
        simpleChanges: SimpleChanges & {
            canvasWidth: SimpleChange;
            canvasHeight: SimpleChange;
            claimantSignatures: SimpleChange;
        },
    ) {
        if (simpleChanges.claimantSignatures) {
            this.loadExistingSignatureFile();
        }
        if (simpleChanges.canvasWidth || simpleChanges.canvasHeight) {
            if (!this.signaturePadSmall) return;

            // To resize the signature pad:
            // - store previous stroke data / loaded image
            // - re-initialize pad with new dimensions
            // - insert stroke data / loaded image
            let strokeData: PointGroup[];
            const wasSignatureDrawnManually: boolean = !!this.signaturePadSmall.toData().length;

            if (this.wasSignatureLoadedFromServer()) {
                // Image loaded from server will be stored on component because it doesn't change. Exporting and importing it from the canvas on each zoom will reduce its quality.
            } else {
                strokeData = this.signaturePadSmall.toData();
            }

            // Let the canvas resize before re-initializing the signature. Resizing a canvas always clears its contents.
            this.changeDetectorRef.detectChanges();

            this.initializeSignaturePadSmall();

            const scalingFactor: number =
                simpleChanges.canvasWidth.currentValue / simpleChanges.canvasWidth.previousValue;

            if (this.wasSignatureLoadedFromServer()) {
                this.drawOnSignaturePadSmall(this.signatureImageBinaryFileAsDataUrl);
            } else if (wasSignatureDrawnManually) {
                const transformedDataPoints = transformSignaturePadDataPoints(strokeData, scalingFactor);
                this.signaturePadSmall.fromData(transformedDataPoints);
            } else {
                // Signature should have been loaded from the server but hasn't arrived yet.
            }
        }
    }

    //*****************************************************************************
    //  Signatures
    //****************************************************************************/
    /**
     * If a claimant signature exists, load it from the server.
     */
    public async loadExistingSignatureFile(): Promise<void> {
        this.signatureImageBinaryFileAsDataUrl = null;

        // May not have been initialized when the first ngOnChanges cycle runs.
        if (!this.signaturePadSmall) return;

        this.signaturePadSmall.clear();

        // We always use the first existing signature.
        const targetClaimantSignature: ClaimantSignature = this.claimantSignatures.filter(
            (claimantSignature) => claimantSignature?.hash,
        )[0];
        if (!targetClaimantSignature) return;

        const fileName = this.getClaimantSignatureFileName(targetClaimantSignature);

        this.signatureDownloadPending = true;

        let claimantSignatureBlob: Blob;
        try {
            claimantSignatureBlob = await this.claimantSignatureFileService.get(fileName, targetClaimantSignature.hash);
        } catch (error) {
            // Mark the pad as drawn on so that the user may delete the missing signature
            this.drawOnSignaturePadSmall('');

            this.apiErrorService.handleAndRethrow({
                axError: error,
                handlers: {},
                defaultHandler: {
                    title: 'Unterschrift fehlt',
                    body: `Die Unterschrift des Anspruchstellers für das Dokument ${translateDocumentType(
                        targetClaimantSignature.documentType,
                    )} konnte nicht geladen werden.`,
                },
            });
        } finally {
            this.signatureDownloadPending = false;
        }

        // Use a FileReader instance to convert between Blob and data URL.
        const fileReader = new FileReader();
        fileReader.addEventListener('load', () => {
            const dataUrl: string = fileReader.result as string;
            this.drawOnSignaturePadSmall(dataUrl);
        });
        fileReader.readAsDataURL(claimantSignatureBlob);
        this.signatureDownloadPending = false;
    }

    public async removeSignatureFile() {
        this.signaturePadSmall.clear();

        // Remove signature files from the server. The ClaimantSignature objects from the report must be removed by the parent.
        for (const claimantSignature of this.claimantSignatures) {
            try {
                const claimantSignatureFilename = this.getClaimantSignatureFileName(claimantSignature);
                await this.claimantSignatureFileService.delete(claimantSignatureFilename);
            } catch (error) {
                this.apiErrorService.handleAndRethrow({
                    axError: error,
                    handlers: {},
                    defaultHandler: {
                        title: 'Unterschrift nicht gelöscht',
                        body: 'Die Unterschrift des Anspruchstellers konnte nicht gelöscht werden.',
                    },
                });
            }

            claimantSignature.hash = null;
        }

        // Clear cached file.
        this.signatureImageBinaryFileAsDataUrl = null;

        this.signatureFilesDeleted.emit(this.claimantSignatures);
    }

    public async transferSignatureFile() {
        this.transferSignature.emit({ signaturePad: this });
    }

    public getClaimantSignatureFileName(claimantSignature: ClaimantSignature) {
        return getClaimantSignatureFileName({
            reportId: this.reportId,
            documentType: claimantSignature.documentType,
            customDocumentOrderConfigId: claimantSignature.customDocumentOrderConfigId,
            signatureElementId: claimantSignature.signatureSlotId,
        });
    }

    //*****************************************************************************
    //  Signature Pad Small
    //****************************************************************************/
    private initializeSignaturePadSmall(): void {
        if (!this.signatureCanvasSmall) return;

        // Exclude the pointer and mousemove events from triggering change detection of Angular.
        this.ngZone.runOutsideAngular(() => {
            this.signaturePadSmall = new SignaturePad(this.signatureCanvasSmall.nativeElement, {
                // Sign in a dark blue. For details, see https://github.com/szimek/signature_pad.
                penColor: '#000F55',
                // Too thin strokes may look a bit fuzzy.
                minWidth: 0.5,
                // A thinner pen line looks more elegant. Default: 2.5
                maxWidth: 2,
                // Our clients' devices are usually very modern, so disable throttling & minimum distance to allow for very smooth signatures.
                throttle: 0,
                // Using the default of 5 makes the signature look slightly smoother.
                minDistance: 5,
            });
        });

        // Make Angular notice finishing a signature.
        // Otherwise, the proceed button in the customer signature dialog would only activate after a third-party event like scroll or mousemove.
        this.signaturePadSmall.addEventListener('endStroke', () => {
            // Set flag to require an upload of the binary file since the user drew a new signature line.
            this.signatureImageHasChanged = true;

            this.changeDetectorRef.detectChanges();
            this.signatureEndStroke.emit(this.claimantSignatures);
        });

        // After initializing, always load existing signatures.
        this.loadExistingSignatureFile();
    }

    private async drawOnSignaturePadSmall(dataUrl: string) {
        const { width: imageWidth, height: imageHeight } = await getImageDimensions(dataUrl);
        const canvasWidth = this.signatureCanvasSmall.nativeElement.width;
        const canvasHeight = this.signatureCanvasSmall.nativeElement.height;

        const { containedWidth, containedHeight } = scaleImageToFit({
            imageWidth,
            imageHeight,
            containerWidth: canvasWidth,
            containerHeight: canvasHeight,
        });

        this.signaturePadSmall.fromDataURL(dataUrl, {
            width: containedWidth,
            height: containedHeight,
        });

        this.signatureImageBinaryFileAsDataUrl = dataUrl;
    }

    /**
     * If filled, but without data points, the signature must have been loaded from the server and inserted as an image. Don't allow altering it.
     */
    public wasSignatureLoadedFromServer(): boolean {
        const wasSignatureDrawnManually: boolean = !!this.signaturePadSmall.toData().length;
        const hasFileBeenLoaded: boolean = !!this.signatureImageBinaryFileAsDataUrl;
        return (this.signaturePadSmall.isEmpty() === false && !wasSignatureDrawnManually) || hasFileBeenLoaded;
    }

    public warnUserAboutDisabledSignaturePad(): void {
        let heading: string;
        let content: string;

        switch (this.disabledReason) {
            case 'reportLocked':
                heading = 'Gutachten abgeschlossen';
                content = 'Wenn du eine neue Unterschrift hinterlegen möchtest, schließe zuerst das Gutachten auf.';
                break;
            case 'signAllAtOnceActive':
                heading = '"Alle auf einmal unterschreiben" aktiv';
                content = 'Wenn du eine neue Unterschrift hinterlegen möchtest, lösche zuerst die im letzten Reiter.';
                break;

            case 'deadlinePassed':
                heading = 'Frist abgelaufen';
                content = 'Die Frist für die Unterschrift ist abgelaufen.';
                break;

            case 'signatureSubmitted':
                heading = 'Unterschrift eingereicht';
                content = 'Die Unterschrift wurde bereits eingereicht.';
                break;

            default:
                if (this.wasSignatureLoadedFromServer()) {
                    heading = 'Unterschrift schreibgeschützt';
                    content = 'Wenn du eine neue Unterschrift hinterlegen möchtest, lösche zuerst diese.';
                }
        }

        this.toastService.warn(heading, content);
    }

    public isEmpty(): boolean {
        return this.signaturePadSmall.isEmpty() && !this.signatureImageBinaryFileAsDataUrl;
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Signature Pad Small
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Signature Pad Fullscreen
    //****************************************************************************/
    private initializeSignaturePadFullscreen(): void {
        if (!this.signaturePadFullscreen) {
            // Exclude the pointer and mousemove events from triggering change detection of Angular.
            this.ngZone.runOutsideAngular(() => {
                this.signaturePadFullscreen = new SignaturePad(this.signatureCanvasFullscreen.nativeElement, {
                    // Sign in a dark blue
                    penColor: '#000F55',
                });
            });

            // Make Angular notice finishing a signature.
            // Otherwise, the button toolbar would only activate after a third-party event like scroll or mousemove.
            this.signaturePadFullscreen.addEventListener('endStroke', () => {
                this.changeDetectorRef.detectChanges();
            });

            // Listen for window size changes, because the signature pad
            // in fullscreen needs to be adjusted to the available space
            fromEvent(window, 'resize')
                .pipe(
                    takeUntil(this.destroyWindowResizeListener),
                    map(() => window.innerWidth),
                    distinctUntilChanged(),
                    // Under iOS, the resize needs to happen multiple times to get the correct size.
                    switchMap(() => timer(0, 250).pipe(take(6))),
                )
                .subscribe(() => {
                    if (this.signaturePadFullscreenShown) {
                        this.updatePositionSignaturePadFullscreen();
                    }
                });
        }
    }

    public openSignaturePadFullscreen(): void {
        this.signaturePadFullscreenShown = 'draw';
        this.signaturePadFullscreenChange.emit(this.signaturePadFullscreenShown);

        // Show the canvas container to allow calculating its size.
        this.changeDetectorRef.detectChanges();

        this.updatePositionSignaturePadFullscreen();
        this.initializeSignaturePadFullscreen();
    }

    /**
     * Rescales the given signature strokes so that we can fit them in a smaller or larger canvas
     * after the user resized the window. Taken from: https://github.com/szimek/signature_pad/issues/32#issuecomment-1406826837
     */
    private rescaleSignature(lines: PointGroup[], scale: number): void {
        lines.forEach((line) => {
            line.points.forEach((point) => {
                // Same scale to avoid warping
                point.x *= scale;
                point.y *= scale;
            });
        });
    }

    protected updatePositionSignaturePadFullscreen(): void {
        // Ensure that the user can still zoom on a smartphone using pinch zoom by applying the viewport scale.
        const viewportScale = visualViewport.scale || 1;

        const canvas = this.signatureCanvasFullscreen.nativeElement;
        // Get the current canvas contents and width, so we can rescale them later (in case there was already a signature)
        const lines = this.signaturePadFullscreen?.toData();
        const previousWidth = canvas.width;

        // Remove previously applied styles so that they don't influence the next size calculation
        canvas.parentNode.style.removeProperty('height');
        canvas.parentNode.style.removeProperty('max-height');
        canvas.parentNode.style.removeProperty('width');
        canvas.parentNode.style.removeProperty('margin');

        const ratioOfSmallCanvas =
            this.signatureCanvasSmall.nativeElement.height / this.signatureCanvasSmall.nativeElement.width;
        canvas.width = canvas.parentNode.offsetWidth * viewportScale;

        // Get parent node dimensions to set the maximum height of the canvas
        const parentHeight = Math.min(window.innerHeight - 130, canvas.parentNode.offsetHeight);
        const preferredHeight = Math.round(canvas.width * ratioOfSmallCanvas); // Same ratio as the canvas of the small signature
        canvas.height = Math.min(parentHeight, preferredHeight) * viewportScale;

        if (parentHeight < preferredHeight) {
            // In case the vertical space is limited and does not allow to use the aspect ratio, decrease the width
            // That way we don't clip parts of the signature pad that the user is not able to see/draw
            canvas.width = Math.min(window.innerWidth - 60, canvas.height / ratioOfSmallCanvas) * viewportScale;
        }

        // The parent node cannot scale automatically because the canvas must be absolutely positioned for the signer data to show through
        canvas.parentNode.style.setProperty('height', canvas.height + 'px');
        canvas.parentNode.style.setProperty('max-height', canvas.height + 'px');
        // Adjust width of parent so that the signature line and date do not exceed the signature canvas
        canvas.parentNode.style.setProperty('width', canvas.width + 'px');
        canvas.parentNode.style.setProperty('margin', 'auto'); // Horizontally center inside parent

        // If there was a signature drawn before resize -> also resize it
        if (this.signaturePadFullscreen && lines) {
            // Simply updating the size of the canvas deletes all contents. Because we don't want that (user looses
            // signature when rotating device or nav bar fades in), we need to first scale the signature (so that
            // it fits into the new canvas) and then reset the contents of the signature pad.
            const scale = canvas.width / previousWidth;
            this.rescaleSignature(lines, scale);
            // Load the adjusted canvas contents
            this.signaturePadFullscreen.fromData(lines);
        }
    }

    public saveAndCloseSignaturePadFullscreen(): void {
        this.signaturePadSmall.clear();

        // Use data points instead of data url to keep vector paths
        const pointGroups = this.signaturePadFullscreen.toData();
        const scalingFactor =
            this.signatureCanvasSmall.nativeElement.width / this.signatureCanvasFullscreen.nativeElement.width;
        this.signaturePadSmall.fromData(transformSignaturePadDataPoints(pointGroups, scalingFactor));

        // Set flag to require an upload of the binary file.
        this.signatureImageHasChanged = true;

        this.closeSignaturePadFullscreen();
    }

    public closeSignaturePadFullscreen(): void {
        if (!this.signaturePadFullscreen) return;
        this.signaturePadFullscreenShown = false;
        this.signaturePadFullscreen.clear();
        this.signaturePadFullscreen = null;
        this.signaturePadFullscreenChange.emit(this.signaturePadFullscreenShown);
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Signature Pad Fullscreen
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Signature Pad Fullscreen (Alternative)
    //****************************************************************************/
    switchFullscreenTab(tab: 'draw' | 'upload' | 'smartphone') {
        this.signaturePadFullscreenShown = tab;
        this.signaturePadFullscreenChange.emit(this.signaturePadFullscreenShown);
    }

    private getQrCodeUrl() {
        const url = new URL(window.location.href);
        const signatureId = this.claimantSignatures[0]?._id;
        if (signatureId) {
            url.searchParams.set('signature', signatureId);
        }
        return url.toString();
    }

    protected async generateQrCodeDataUrl() {
        // Check if we can re-use an existing QR code data URL.
        if (this.qrCodeDataUrl) return;

        const url = this.getQrCodeUrl();
        const qrCodeDataUrl = await toDataURL(url.toString(), {
            width: 150,
            margin: 0,
            scale: 1,
            color: { dark: '#000000', light: '#ffffff' },
            type: 'image/png',
        });
        this.qrCodeDataUrl = this.domSanitizer.bypassSecurityTrustUrl(qrCodeDataUrl);
    }

    protected copyQrCodeUrlToClipboard() {
        const url = this.getQrCodeUrl();

        try {
            navigator.clipboard.writeText(url.toString());
            this.toastService.info('Link kopiert', 'Der Link zur Unterschrift wurde in die Zwischenablage kopiert.');
        } catch (error) {
            this.toastService.error(
                'Link konnte nicht kopiert werden',
                'Bitte scanne den QR-Code mit deinem Smartphone.',
            );
        }
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Signature Pad Fullscreen (Alternative)
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Drag & Drop Signature
    //****************************************************************************/
    /**
     * Mark the signature pad as being dragged
     */
    public onFileOverSignaturePad(isFileOver: boolean) {
        // If the user's mouse and the dragged file left the dropzone, hide the dropzone.
        if (!isFileOver) {
            this.fileOverSignaturePadTimeoutCache = window.setTimeout(() => {
                this.fileIsOverSignaturePad = false;
            }, 500);
        } else {
            if (this.fileOverSignaturePadTimeoutCache) {
                clearTimeout(this.fileOverSignaturePadTimeoutCache);
            }
            this.fileIsOverSignaturePad = true;
        }
    }

    public onSignatureDrop(fileList: File[]): void {
        this.fileIsOverSignaturePad = false;

        if (fileList.length > 1) {
            this.toastService.error(
                'Nur eine Datei erlaubt',
                'Nur eine Datei kann pro Dokument-Typ hochgeladen werden.',
            );
            return;
        }

        const file = fileList[0];

        if (file.type !== 'image/png' && this.displayOrientationLine) {
            this.toastService.warn(
                'Transparente PNGs optimal',
                `Für beste Ergebnisse konvertiere die Datei zuerst zum PNG-Format.<br>Der Hintergrund sollte transparent sein, damit die Unterschriftslinie durchscheint und die Unterschrift real wirkt. Das kann mit Photoshop erreicht werden, z. B. durch deinen Grafik-Designer.`,
            );
        }

        // Blob -> data url
        const fileReader = new FileReader();
        fileReader.addEventListener('loadend', () => {
            if (!this.signaturePadOffscreen) {
                this.calculateSignatureScalingFactor();
                this.initializeSignaturePadOffscreen(this.signatureScalingFactor);
            }

            this.signaturePadOffscreen.clear();

            // Find best dimensions
            const image = new Image();
            image.onload = async () => {
                const ratio = image.width / image.height;
                const canvasWidth = this.signatureCanvasOffscreen.nativeElement.width;
                const canvasHeight = this.signatureCanvasOffscreen.nativeElement.height;
                let downsizedWidth = Math.min(image.width, canvasWidth);
                let downsizedHeight = downsizedWidth / ratio;

                // If the image is too high, try downsizing the image to height of canvas
                if (downsizedHeight > canvasHeight) {
                    downsizedHeight = Math.min(image.height, canvasHeight);
                    downsizedWidth = downsizedHeight * ratio;
                }

                await this.signaturePadOffscreen.fromDataURL(fileReader.result as string, {
                    width: downsizedWidth,
                    height: downsizedHeight,
                });
                await this.drawOnSignaturePadSmall(this.signaturePadOffscreen.toDataURL('image/png'));

                this.signatureEndStroke.emit(this.claimantSignatures);
            };
            image.src = fileReader.result as string;
        });
        fileReader.readAsDataURL(file);

        // Set flag to require an upload of the binary file.
        this.signatureImageHasChanged = true;

        // Close fullscreen mode if active
        this.closeSignaturePadFullscreen();
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Drag & Drop Signature
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Upload signature file
    //****************************************************************************/
    public async handleSignatureUpload(event: Event) {
        const file = (event.target as HTMLInputElement).files[0];
        this.onSignatureDrop([file]);
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Upload signature file
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Save Signatures
    //****************************************************************************/
    /**
     * Meant to be called from the parent as soon as the user clicks on "next" or "upload" and the like.
     *
     * - Uploads signature file
     * - sets hash & date on ClaimantSignature object
     */
    public async saveSignature(): Promise<ClaimantSignature[]> {
        if (!this.signaturePadSmall) return;

        const uploadedSignatures: ClaimantSignature[] = [];
        let changeEventShouldBeEmitted: boolean;

        // Don't upload empty signatures.
        // Empty = no data points and no binary image.
        if (!this.signaturePadSmall.isEmpty()) {
            const signatureBlob = this.getSignatureBlob();

            if (!signatureBlob) {
                this.toastService.warn(
                    'Unterschrift leer',
                    "Das sollte so nicht sein. Bitte kontaktiere die <a href='/Hilfe'>Hotline</a>.",
                );
                console.warn('Signature is empty. Upload aborted. Claimant Signatures: ', this.claimantSignatures);
                return;
            }

            if (this.signatureImageHasChanged) {
                const signatureHash = this.getSignatureHash();

                // Upload the signature image to all claimant signature objects provided.
                // Multiple signatures per document are possible with PDF documents.
                // Our generated documents only take a single claimant signature object.
                await Promise.all(
                    this.claimantSignatures.map(async (claimantSignature) => {
                        if (!claimantSignature) {
                            this.toastService.error(
                                'Es ist ein Fehler aufgetreten',
                                'Die Signature konnte nicht gespeichert werden. Bitte versuche es erneut oder kontaktiere uns.',
                            );

                            this.apiErrorService.handleAndRethrow({
                                axError: new AxError({
                                    code: 'MISSING_CLAIMANT_SIGNATURE',
                                    message: `Claimant signature object is missing.`,
                                    data: { claimantSignatures: this.claimantSignatures },
                                }),
                                handlers: {},
                                defaultHandler: {
                                    title: 'Es ist ein Fehler aufgetreten',
                                    body: 'Die Signature konnte nicht gespeichert werden. Bitte versuche es erneut oder kontaktiere uns.',
                                },
                            });
                        }
                        if (claimantSignature?.hash === signatureHash) return;

                        this.savingClaimantSignaturePending = true;

                        //*****************************************************************************
                        //  Upload
                        //****************************************************************************/
                        try {
                            await this.claimantSignatureFileService.create({
                                _id: this.getClaimantSignatureFileName(claimantSignature),
                                blob: signatureBlob,
                                blobContentHash: signatureHash,
                            });
                        } catch (error) {
                            this.apiErrorService.handleAndRethrow({
                                axError: error,
                                handlers: {
                                    /**
                                     * Mobile Safari in incognito mode does not allow to store blobs in IndexedDB.
                                     */
                                    SAVING_BLOB_ON_INDEXEDDB_FAILED: () => {
                                        return this.browser === 'Safari'
                                            ? {
                                                  title: 'Unterschreiben im Safari im privaten Modus nicht möglich',
                                                  body: 'Dein Browser hat die Unterschriftsdatei abgelehnt. Stelle sicher, dass autoiXpert nicht in einem privaten Tab oder im Inkognito-Modus geöffnet ist. Bei weiteren Fragen kontaktiere die <a href="/Hilfe">Hotline</a>.',
                                              }
                                            : {
                                                  title: 'Unterschrift nicht gespeichert',
                                                  body: 'Dein Browser hat die Unterschriftsdatei abgelehnt. Stelle sicher, dass autoiXpert nicht in einem privaten Tab oder im Inkognito-Modus geöffnet ist. Bei weiteren Fragen kontaktiere die <a href="/Hilfe">Hotline</a>.',
                                              };
                                    },
                                },
                                defaultHandler: {
                                    title: `Unterschrift nicht gespeichert`,
                                    body: `Bitte versuche es erneut oder kontaktiere die <a href='/Hilfe'>Hotline</a>.`,
                                },
                            });
                        } finally {
                            this.savingClaimantSignaturePending = false;
                        }
                        /////////////////////////////////////////////////////////////////////////////*/
                        //  END Upload
                        /////////////////////////////////////////////////////////////////////////////*/

                        claimantSignature.hash = signatureHash;

                        // Collect the signatures we uploaded to emit them to the parent component.
                        uploadedSignatures.push(claimantSignature);

                        // Let the parent component know through event emission that changes have been made.
                        changeEventShouldBeEmitted = true;
                    }),
                );
                this.savingClaimantSignaturePending = false;
            }
        }

        // Set the date on all signatures. This is necessary if the user has not changed the date explicitly
        // which would trigger the method setAndSaveDateOnAllSignatures below.
        const changedSignatures: ClaimantSignature[] = this.setDateOnAllSignatures(this.signatureDate);

        if (changedSignatures.length) {
            changeEventShouldBeEmitted = true;
        }

        if (changeEventShouldBeEmitted) {
            this.signatureSaved.emit(uploadedSignatures);
        }
        return uploadedSignatures;
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Save Signatures
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Update Signature Date
    //****************************************************************************/
    /**
     * Set this.signatureDate locally.
     * Also, set the selected date on all signatures.
     */
    public handleDateSelection(event: MatDatepickerInputEvent<Moment>) {
        this.signatureDate = toIsoDate(event.value.format());

        const changedSignatures: ClaimantSignature[] = this.setDateOnAllSignatures(this.signatureDate);

        if (changedSignatures.length) {
            this.signatureSaved.emit(changedSignatures);
        }
    }

    /**
     * Loop over all signatures and set the given date.
     *
     * Returns the signatures that have been changed.
     */
    private setDateOnAllSignatures(date: IsoDate) {
        const changedSignatures: ClaimantSignature[] = [];

        // Set the date on all given signatures in case we're on the "signAll" tab.
        for (const claimantSignature of this.claimantSignatures) {
            if (!claimantSignature) continue;
            if (!claimantSignature.date || claimantSignature.date !== date) {
                claimantSignature.date = date;
                changedSignatures.push(claimantSignature);
            }
        }

        return changedSignatures;
    }
    /////////////////////////////////////////////////////////////////////////////*/
    //  END Update Signature Date
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Signature Blob
    //****************************************************************************/
    public getSignatureBlob(): Blob {
        let blob: Blob;

        /**
         * The signature may consist of a vector graphic (points) when the user drew on the signature pad manually.
         * Scale the image up before sending it to server to achieve higher image quality for print.
         * TODO Check if it's necessary to scale the signature up and down. Currently, this process seems to reduce the line thickness of the signature.
         *
         * It may also consist of a rendered image (PNG) if the user uploaded a signature image via drag'n'drop. In that
         * case, we do not need to rescale since that would not improve the image quality.
         */
        if (this.signaturePadSmall.toData().length) {
            this.calculateSignatureScalingFactor();
            if (!this.signaturePadOffscreen) {
                this.initializeSignaturePadOffscreen(this.signatureScalingFactor);
            }
            this.signaturePadOffscreen.fromData(
                transformSignaturePadDataPoints(this.signaturePadSmall.toData(), this.signatureScalingFactor),
            );

            blob = base64toBlob(dataUrlToBase64(this.signaturePadOffscreen.toDataURL()), 'image/png');

            this.signaturePadOffscreen.clear();
        } else {
            // For binary images inserted via drag'n'drop.
            blob = base64toBlob(dataUrlToBase64(this.signaturePadSmall.toDataURL()), 'image/png');
        }

        return blob;
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Signature Blob
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Signature Hash
    //****************************************************************************/
    /**
     * A hash being present on the ClaimantSignature object marks that a binary file exists.
     * At the same time, the PDF renderer knows that if the hash remains the same, cached PDFs can be used.
     */
    private getSignatureHash(): string {
        return simpleHash(dataUrlToBase64(this.signaturePadSmall.toDataURL()));
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Signature Hash
    /////////////////////////////////////////////////////////////////////////////*/

    /**
     * If the scaling factor is wrong, the signature will look blurry and have a stroke width that is too thin (factor too high) or too thick (factor too low).
     * We therefore choose a scaling factor that makes the offscreen canvas have a width of 300px, which produces nice looking signatures.
     */
    private calculateSignatureScalingFactor() {
        const signatureCanvasSmallWidth = this.signatureCanvasSmall.nativeElement.width;
        const signatureCanvasOffscreenWidth = 300;
        const scalingFactor = signatureCanvasOffscreenWidth / signatureCanvasSmallWidth;
        this.signatureScalingFactor = scalingFactor;
    }
    /**
     * The canvas off the screen is used to iteratively determine the right size of dropped signature images.
     */
    private initializeSignaturePadOffscreen(scalingFactor: number): void {
        this.signatureCanvasOffscreen.nativeElement.width =
            this.signatureCanvasSmall.nativeElement.width * scalingFactor;
        this.signatureCanvasOffscreen.nativeElement.height =
            this.signatureCanvasSmall.nativeElement.height * scalingFactor;

        // Exclude the pointer and mousemove events from triggering change detection of Angular.
        this.ngZone.runOutsideAngular(() => {
            this.signaturePadOffscreen = new SignaturePad(this.signatureCanvasOffscreen.nativeElement, {
                // Sign in a dark blue
                penColor: this.signaturePadSmall.penColor,
                minWidth: this.signaturePadSmall.minWidth * scalingFactor, // Without this, the stroke width after scaling would be too narrow because of the default maxWidth of 2.5
                maxWidth: this.signaturePadSmall.maxWidth * scalingFactor, // Without this, the stroke width after scaling would be too narrow because of the default maxWidth of 2.5
            });
        });
    }

    //*****************************************************************************
    //  Signature Date
    //****************************************************************************/
    public emitSignatureDateChange(event: MatDatepickerInputEvent<Moment>) {
        this.signatureDateChange.emit(toIsoDate(event.value.format()));
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Signature Date
    /////////////////////////////////////////////////////////////////////////////*/

    ngAfterViewInit() {
        this.signaturePadCreated.emit(this);
    }

    ngOnDestroy() {
        this.destroyWindowResizeListener.next();
        this.destroyWindowResizeListener.unsubscribe();
    }
}

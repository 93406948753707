import { DocumentBuildingBlockCondition } from '../../models/documents/document-building-block-condition';
import { DocumentBuildingBlockConditionGroup } from '../../models/documents/document-building-block-condition-group';

export function isDocumentBuildingBlockConditionGroup(
    condition: DocumentBuildingBlockCondition | DocumentBuildingBlockConditionGroup | undefined | null,
): condition is DocumentBuildingBlockConditionGroup {
    if (!condition) return false;

    return (
        Array.isArray((condition as DocumentBuildingBlockConditionGroup).conditions) &&
        'conditionsOperator' in condition
    );
}

export function isDocumentBuildingBlockCondition(
    condition: DocumentBuildingBlockCondition | DocumentBuildingBlockConditionGroup | undefined | null,
): condition is DocumentBuildingBlockCondition {
    if (!condition) return false;

    return 'propertyPath' in condition && 'operator' in condition && 'comparisonValue' in condition;
}

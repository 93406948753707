import { generateId } from '@autoixpert/lib/generate-id';
import { ReportType } from '@autoixpert/models/reports/report';
import { DefaultDocumentOrder } from '@autoixpert/models/teams/default-document-order/default-document-order';

/**
 * A list connected to an involved party that sorts and activates the documents in the report.documents array for
 * sending emails or downloading the full PDF.
 */
export class DefaultDocumentOrderGroup {
    constructor(template?: Partial<DefaultDocumentOrderGroup>) {
        if (template) {
            Object.assign(this, template);
        }
    }

    _id: string = generateId();

    title: string;
    /**
     * A group may be the default for one of these:
     * - report.documentOrders depending on the report type
     * - report.repairConfirmation.documentOrders
     * - invoice.documentOrders
     *
     * The repair confirmation is managed separately because the print-and-transmission component allows displaying only those documents associated with the
     * repair confirmation.
     *
     * The invoice is managed separately because it has its own independent document order in the invoice-list component.
     */
    types: DefaultDocumentOrderGroupType[] = [];

    documentOrders: DefaultDocumentOrder[] = [];
}

export type DefaultDocumentOrderGroupType = ReportType | 'reportDefault' | 'repairConfirmation' | 'invoice';

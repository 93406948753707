<div
    class="estimated-duration-container"
    matTooltip="Zeitschätzung. Nützlich, um die Größe der Aufgabe abzuschätzen, und für deine Kapazitätsplanung in der Aufgabenliste.{{
        '\n\n'
    }}Beispieleingaben:
3 h (3 Stunden)
30m (30 Minuten)">
    <mat-icon class="outlined" *ngIf="!estimatedDuration && !estimatedDurationInputVisible">timer</mat-icon>

    <!--********** Estimated Duration AutoGrow **********-->
    <autogrow-textarea
        *ngIf="estimatedDuration || estimatedDurationInputVisible"
        #durationInput
        class="task-duration-input"
        [style.min-width.px]="20"
        [value]="estimatedDurationFormatted"
        [singleLine]="true"
        (enterPressed)="handleEnterKeyPress()"
        (valueChange)="estimatedDurationFormatted = $event.value"
        (blurred)="handleEstimatedDurationBlur($event)"></autogrow-textarea>
</div>

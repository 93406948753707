import { FabricImage, FabricObject, StaticCanvas, util } from 'fabric';
import { applyFilters } from './apply-filters';
import { rescaleObjects } from './rescale-objects';

/**
 * Render shapes and the watermark image onto the photo.
 *
 * This function can be used both on the server and in the browser.
 */
export async function render({
    canvas,
    image,
    fabricJsInformation,
    watermarkImage,
    originalImageDimensions,
}: {
    /**
     * The canvas object may be null in Node.js environments. In the browser, this needs to be a HTMLCanvasElement.
     */
    canvas: HTMLCanvasElement | null;
    image: HTMLImageElement;
    fabricJsInformation: { [key: string]: any };
    watermarkImage: HTMLImageElement;
    originalImageDimensions: {
        height: number;
        width: number;
    };
}): Promise<StaticCanvas> {
    const fabricCanvas = new StaticCanvas(canvas, {
        width: originalImageDimensions.width,
        height: originalImageDimensions.height,
        enableRetinaScaling: false,
    });

    // Add original photo to canvas
    const fabricPhoto = new FabricImage(image);
    fabricCanvas.add(fabricPhoto);

    /**
     * If no fabric.js information is present, the image does not need any rendering of shapes/filters. Return the canvas with the image on it.
     * That's usually the case when new photos' thumbnails are rendered.
     */
    if (!fabricJsInformation) {
        // Render the photo onto the canvas.
        fabricCanvas.renderAll();
        return fabricCanvas;
    }

    const savedFilters = fabricJsInformation.axFilters || {};
    const enlivenedImages = [];
    let photo: any = null;

    // Save CPU power by only rendering once, not once per object.
    fabricCanvas.renderOnAddRemove = false;

    // Iterate over all objects and save the image.
    const savedObjects = fabricJsInformation.objects.map((object) => {
        if (object.type.toLowerCase() === 'image' && object.data && object.data.axType === 'photo') {
            photo = object;

            // Recover the angle with which the photo was saved.
            fabricPhoto.rotate(photo.angle);
            resizeCanvas(fabricCanvas, fabricPhoto);

            return undefined;
        }
        // The watermark image
        else if (object.type.toLowerCase() === 'image' && object.data && object.data.axType === 'watermarkImage') {
            const watermarkFabricPhoto = new FabricImage(watermarkImage);
            watermarkFabricPhoto.set({
                top: object.top,
                left: object.left,
                scaleX: object.scaleX,
                scaleY: object.scaleY,
                originX: object.originX,
                originY: object.originY,
                angle: object.angle,
                opacity: object.opacity,
            });
            enlivenedImages.push(watermarkFabricPhoto);

            // This image is no shape, so return undefined. undefined values will later be filtered.
            return undefined;
        } else {
            return object;
        }
    });

    const savedObjectsWithoutImages = [];

    savedObjects.forEach((object) => {
        // undefined in case of an image, a serialized canvas object otherwise
        if (object) {
            savedObjectsWithoutImages.push(object);
        }
    });

    // Rescale and reposition
    rescaleObjects(savedObjectsWithoutImages, photo, fabricPhoto);

    const enlivenedObjects = await util.enlivenObjects<FabricObject>(savedObjectsWithoutImages);
    enlivenedObjects.forEach((enlivenedObject) => {
        fabricCanvas.add(enlivenedObject);
    });

    // Rescale and reposition the images (e.g. for the watermark)
    rescaleObjects(enlivenedImages, photo, fabricPhoto);
    // Add images to canvas
    enlivenedImages.forEach((enlivenedImage) => {
        fabricCanvas.add(enlivenedImage);
    });

    const filters = {};
    // Restore the filters' values to the sliders. axFilters is a property we add when saving the image.
    Object.keys(savedFilters).forEach((filterName) => {
        const filter = savedFilters[filterName];
        // Jot down the value. It will be applied in the applyFilters function.
        filters[filter.id] = {
            value: filter.value,
        };
    });
    applyFilters(fabricPhoto, filters);

    fabricCanvas.renderOnAddRemove = true;
    // Render filters
    fabricCanvas.renderAll();

    return fabricCanvas;
}

/**
 * Used to rescale a rotated photo correctly.
 * @param canvas
 * @param fabricPhoto
 */
function resizeCanvas(canvas, fabricPhoto) {
    const fabricPhotoBeforeRescale = {
        left: fabricPhoto.left,
        top: fabricPhoto.top,
        width: fabricPhoto.width,
        height: fabricPhoto.height,
        scaleX: fabricPhoto.scaleX,
        scaleY: fabricPhoto.scaleY,
    };
    let scaleFactor = 1;

    // If the photo is either not rotated or rotated by 0, 180, 360, ... degrees, compare the photo's height
    // with the canvas' height
    if (!photoLiesSidewards(fabricPhoto)) {
        // If the image is too large, scale it down to fit
        if (fabricPhotoBeforeRescale.height > canvas.height || fabricPhotoBeforeRescale.width > canvas.width) {
            scaleFactor = canvas.height / fabricPhotoBeforeRescale.height;
            // Check if the width would still be too large if we scale the image down to the canvas height.
            if (fabricPhotoBeforeRescale.width * scaleFactor > canvas.width) {
                // Scale the image to the canvas width because the scale factor (if scaling to height) was too small.
                // Since this makes the image smaller than scaling to height, the height fits as well.
                scaleFactor = canvas.width / fabricPhotoBeforeRescale.width;
            }
            fabricPhoto.scale(scaleFactor);
        }
    }
    // If the photo is rotated so that its height axis lies on the canvas' width axis, compare photo height with canvas width
    else {
        // If the image is too large, scale it down to fit
        if (fabricPhotoBeforeRescale.width > canvas.height || fabricPhotoBeforeRescale.height > canvas.width) {
            scaleFactor = canvas.height / fabricPhotoBeforeRescale.width;
            // Check if the height would still be too large if we scale the image down to the canvas height.
            if (fabricPhotoBeforeRescale.height * scaleFactor > canvas.width) {
                // Scale the image to the canvas width because the scale factor (if scaling to height) was too small.
                // Since this makes the image smaller than scaling to height, the height fits as well.
                scaleFactor = canvas.width / fabricPhotoBeforeRescale.height;
            }
            fabricPhoto.scale(scaleFactor);
        }
    }

    // Scale the canvas so that it is as big as the photo - without any black space at the sides.
    const fabricPhotoBoundingRect = fabricPhoto.getBoundingRect();
    const canvasDimensions = {
        width: fabricPhotoBoundingRect.width,
        height: fabricPhotoBoundingRect.height,
    };
    canvas.setDimensions(canvasDimensions);

    canvas.centerObject(fabricPhoto);
    fabricPhoto.setCoords();
    canvas.renderAll();
}

/**
 * Determines if a photo is rotated by 90, 270, 450, ... degrees and lies therefore on its side. Then we must compare height
 * with the x axis and width with the y axis
 * @param fabricPhoto
 * @return {boolean}
 */
function photoLiesSidewards(fabricPhoto) {
    // If the photo is shifted by 90, 270, 450, ... degrees, it is actually shifted to lie sidwards. We then have
    // to compare its height with the canvas' width, its width with the canvas' height to make it fit into the canvas.
    return !!((Math.round(fabricPhoto.angle) / 90) % 2);
}

import { CdkConnectedOverlay } from '@angular/cdk/overlay';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { NgIf } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacyProgressSpinnerModule as MatProgressSpinnerModule } from '@angular/material/legacy-progress-spinner';
import { MatLegacyTooltipModule } from '@angular/material/legacy-tooltip';
import { ClaimantSignatureModule } from '@autoixpert/components/claimant-signature/claimant-signature.module';
import { ConfirmDialogComponent } from '@autoixpert/components/confirm-dialog/confirm-dialog.component';
import { IconPickerModule } from '@autoixpert/components/icon-picker/icon-picker.module';
import { SignaturePadModule } from '@autoixpert/components/signature-pad/signature-pad.module';
import { WhiteButtonWithShadowModule } from '@autoixpert/components/white-button-with-shadow/white-button-with-shadow.module';
import { CleanBaseModelPipe } from '@autoixpert/pipes/clean-base-model.pipe';
import { DefaultDocumentOrderGroupComponent } from '../reports/details/print-and-transmission/default-document-order-dialog/default-document-order-group/default-document-order-group.component';
import { PromptDialogComponent } from '../shared/components/prompt-dialog/prompt-dialog.component';
import { VehicleRequiresTwoOrMoreAxlesPipe } from '../shared/libraries/car/vehicle-requires-two-or-more-axles';
import { AdeltaLiquidityService } from '../shared/services/adelta-liquidity.service';
import { AfzzertProcessesService } from '../shared/services/afzzert-processes.service';
import { ReportsAnalyticsService } from '../shared/services/analytics/reports-analytics.service';
import { AudatexCalculationTextService } from '../shared/services/audatex-calculation-text.service';
import { AudatexKbaCodeService } from '../shared/services/audatex/audatex-kba-code.service';
import { AudatexTaskService } from '../shared/services/audatex/audatex-task.service';
import { AudatexVinService } from '../shared/services/audatex/audatex-vin.service';
import { CarEquipmentTemplateService } from '../shared/services/car-equipment-template.service';
import { CarEquipmentService } from '../shared/services/car-equipment.service';
import { CarRegistrationScannerService } from '../shared/services/car-registration-recognition.service';
import { ClaimantSignatureFileService } from '../shared/services/claimant-signature-file.service';
import { ClaimantSignaturesFormDraftService } from '../shared/services/claimant-signature-form-draft.service';
import { CopyClaimantSignaturesToReportService } from '../shared/services/copy/copy-claimant-signatures-to-report.service';
import { CopyDatDossierService } from '../shared/services/copy/copy-dat-dossier.service';
import { CopyMarketAnalysisDocumentsToReportService } from '../shared/services/copy/copy-market-analysis-documents-to-report.service';
import { CopyPhotosToReportService } from '../shared/services/copy/copy-photos-to-report.service';
import { CopyResidualValueDocumentsToReportService } from '../shared/services/copy/copy-residual-value-documents-to-report.service';
import { CopyUserUploadedDocumentsToReportService } from '../shared/services/copy/copy-user-uploaded-documents-to-report.service';
import { CustomAutocompleteEntriesService } from '../shared/services/custom-autocomplete-entries.service';
import { CustomFeeSetService } from '../shared/services/custom-fee-set.service';
import { CustomFieldButtonTriggerService } from '../shared/services/custom-field-button-trigger.service';
import { DatAuthenticationService } from '../shared/services/dat-authentication.service';
import { DatDamageCalculationService } from '../shared/services/dat-damage-calculation.service';
import { DatDossierListService } from '../shared/services/dat-dossier-list.service';
import { DatValuationService } from '../shared/services/dat-valuation.service';
import { DatVehicleIdentificationService } from '../shared/services/dat-vehicle-identification.service';
import { DocxWatermarkTemplateService } from '../shared/services/docx-watermark-template.service';
import { EmailService } from '../shared/services/email.service';
import { FieldGroupConfigService } from '../shared/services/field-group-config.service';
import { GdvResponsibleInsuranceService } from '../shared/services/gdv/gdv-responsible-insurance.service';
import { GtmotiveEstimateService } from '../shared/services/gtmotive/gtmotive-estimate.service';
import { GtmotiveVinService } from '../shared/services/gtmotive/gtmotive-vin.service';
import { InvoiceNumberJournalEntryService } from '../shared/services/invoice-number-journal-entry.service';
import { InvoiceNumberOrReportTokenCounterService } from '../shared/services/invoice-number-or-report-token-counter.service';
import { InvoiceNumberService } from '../shared/services/invoice-number.service';
import { LeaseReturnTemplateService } from '../shared/services/lease-return-template.service';
import { LegacyReportService } from '../shared/services/legacy-report.service';
import { LicensePlateRedactionService } from '../shared/services/license-plate-redaction/license-plate-redaction.service';
import { ManufacturerService } from '../shared/services/manufacturer.service';
import { OriginalPhotoService } from '../shared/services/original-photo.service';
import { OriginalRenderedDocumentBuildingBlocksService } from '../shared/services/original-rendered-document-building-blocks.service';
import { OutgoingMessageService } from '../shared/services/outgoing-message.service';
import { PersaldoService } from '../shared/services/persaldo.service';
import { PhotoBlobUrlCacheService } from '../shared/services/photo-blob-url-cache.service';
import { RenderedPhotoFileService } from '../shared/services/rendered-photo-file.service';
import { ReportDetailsService } from '../shared/services/report-details.service';
import { ReportProgressConfigService } from '../shared/services/report-progress-config.service';
import { ReportRealtimeEditorService } from '../shared/services/report-realtime-editor.service';
import { ReportTokenAndInvoiceNumberService } from '../shared/services/report-token-and-invoice-number.service';
import { ReportTokenService } from '../shared/services/report-token.service';
import { ReportRAMCacheService } from '../shared/services/report.cache.service';
import { ReportService } from '../shared/services/report.service';
import { ResidualValueBidderGroupService } from '../shared/services/residual-value-bidder-group.service';
import { ResidualValueInvitationService } from '../shared/services/residual-value-invitation.service';
import { ResidualValueRequestService } from '../shared/services/residual-value-request.service';
import { RestorePhotosFromReportService } from '../shared/services/restore-photos-from-report.service';
import { TemplatePlaceholderValuesService } from '../shared/services/template-placeholder-values.service';
import { TextTemplateService } from '../shared/services/textTemplate.service';
import { WatermarkImageFileService } from '../shared/services/watermark-image-file.service';
import { SharedModule } from '../shared/shared.module';
import { CopyReportDialogComponent } from './copy-report-dialog/copy-report-dialog.component';
import { AccidentDataComponent } from './details/accident-data/accident-data.component';
import { CustomerSignaturesCardComponent } from './details/accident-data/customer-signatures-card/customer-signatures-card.component';
import { CustomerSignaturesDialogComponent } from './details/accident-data/customer-signatures-dialog/customer-signatures-dialog.component';
import { RemoteSignatureDialogComponent } from './details/accident-data/remote-signature-dialog/remote-signature-dialog.component';
import { CarConditionComponent } from './details/car-condition/car-condition.component';
import { DamageCheckboxComponent } from './details/car-condition/damage-checkbox/damage-checkbox.component';
import { OldtimerGradesComponent } from './details/car-condition/oldtimer-grades/oldtimer-grades.component';
import { PaintThicknessInputComponent } from './details/car-condition/paint-thickness/paint-thickness-input/paint-thickness-input.component';
import { PaintThicknessRangeLabelPipe } from './details/car-condition/paint-thickness/paint-thickness-range-label.pipe';
import { PaintThicknessRangeValuePipe } from './details/car-condition/paint-thickness/paint-thickness-range-value.pipe';
import { PaintThicknessScaleEditDialogComponent } from './details/car-condition/paint-thickness/paint-thickness-scale-edit-dialog/paint-thickness-scale-edit-dialog.component';
import { PaintThicknessScaleSelectorComponent } from './details/car-condition/paint-thickness/paint-thickness-scale/paint-thickness-scale-selector.component';
import { PaintThicknessComponent } from './details/car-condition/paint-thickness/paint-thickness/paint-thickness.component';
import { SpareTireDetailsDialogComponent } from './details/car-condition/spare-tire-details-dialog/spare-tire-details-dialog.component';
import { CarDataComponent } from './details/car-data/car-data.component';
import { CarSelectorComponent } from './details/car-data/car-selector/car-selector.component';
import { CustomCarPropertiesComponent } from './details/car-data/custom-car-properties/custom-car-properties.component';
import { CustomCarPropertyInputTitleComponent } from './details/car-data/custom-car-properties/custom-car-property-input-title/custom-car-property-input-title.component';
import { CustomCarPropertyInputValueComponent } from './details/car-data/custom-car-properties/custom-car-property-input-value/custom-car-property-input-value.component';
import { EquipmentBulkImportDialogComponent } from './details/car-data/equipment-bulk-import-dialog/equipment-bulk-import-dialog.component';
import { EquipmentSelectorComponent } from './details/car-data/equipment-selector/equipment-selector.component';
import { MultipleCarsIdentificationHelperComponent } from './details/car-data/multiple-cars-identification-helper/multiple-cars-identification-helper.component';
import { PreviousOwnersComponent } from './details/car-data/previous-owners/previous-owners.component';
import { BidSelectorComponent } from './details/damage-calculation/bid-selector/bid-selector.component';
import { DamageCalculationComponent } from './details/damage-calculation/damage-calculation.component';
import { DiminishedValueCalculatorComponent } from './details/damage-calculation/diminished-value-calculator/diminished-value-calculator.component';
import { OldVehicleCertificatesOverviewComponent } from './details/damage-calculation/old-vehicle-certificates-overview/old-vehicle-certificates-overview.component';
import { SpecialCostsDialogComponent } from './details/damage-calculation/special-costs-dialog/special-costs-dialog.component';
import { ExpertStatementComponent } from './details/expert-statement/expert-statement.component';
import { CustomFeeSetDialogComponent } from './details/fees/custom-fee-set-dialog.component/custom-fee-set-dialog.component';
import { FeesComponent } from './details/fees/fees.component';
import { InvoiceAuditComponent } from './details/invoice-audit/invoice-audit.component';
import { LeaseReturnTextTemplateDialog } from './details/lease-return/lease-return-text-template-dialog/lease-return-text-template-dialog.component';
import { LeaseReturnComponent } from './details/lease-return/lease-return.component';
import { LeaseReturnTemplateManagementComponent } from './details/lease-return/template-management/lease-return-template-management.component';
import { OldtimerValuationLargeConditionComponent } from './details/oldtimer-valuation-large-condition/oldtimer-valuation-large-condition.component';
import { OldtimerValuationComponent } from './details/oldtimer-valuation/oldtimer-valuation.component';
import { DefaultPhotoDescriptionsEditorComponent } from './details/photos/default-photo-descriptions-editor/default-photo-descriptions-editor.component';
import { LicensePlateRedactionInfoDialogComponent } from './details/photos/license-plate-redaction-info-dialog/license-plate-redaction-info-dialog.component';
import { PhotoEditorComponent } from './details/photos/photo-editor/photo-editor.component';
import { PhotosComponent } from './details/photos/photos.component';
import { DefaultDocumentOrderDialogComponent } from './details/print-and-transmission/default-document-order-dialog/default-document-order-dialog.component';
import { DocxWatermarkSettingsCardComponent } from './details/print-and-transmission/docx-watermark-settings-dialog/docx-watermark-settings-card/docx-watermark-settings-card.component';
import { DocxWatermarkSettingsDialogComponent } from './details/print-and-transmission/docx-watermark-settings-dialog/docx-watermark-settings-dialog.component';
import { NumberOfReportsCongratsDialogComponent } from './details/print-and-transmission/number-of-reports-congrats-dialog/number-of-reports-congrats-dialog.component';
import { OverwriteDocumentBuildingBlocksComponent } from './details/print-and-transmission/overwrite-document-building-blocks/overwrite-document-building-blocks.component';
import { PrintAndTransmissionComponent } from './details/print-and-transmission/print-and-transmission.component';
import { RepairConfirmationComponent } from './details/repair-confirmation/repair-confirmation.component';
import { ReportDetailsGuard } from './details/report-details-guard.service';
import { ReportDetailsComponent } from './details/report-details.component';
import { AudatexCalculationInsertionDialog } from './details/shared/audatex-calculation-insertion-dialog/audatex-calculation-insertion-dialog.component';
import { AutoonlineOfferConnectionDialogComponent } from './details/shared/autoonline-offer-connection-dialog/autoonline-offer-connection-dialog.component';
import { CancelResidualValueOfferDialogComponent } from './details/shared/cancel-residual-value-offer-dialog/cancel-residual-value-offer-dialog.component';
import { CarRegistrationScannerDialogComponent } from './details/shared/car-registration-scanner-dialog/car-registration-scanner-dialog.component';
import { CarcasionOfferConnectionDialogComponent } from './details/shared/carcasion-offer-connection-dialog/carcasion-offer-connection-dialog.component';
import { CartvOfferConnectionDialogComponent } from './details/shared/cartv-offer-connection-dialog/cartv-offer-connection-dialog.component';
import { ConnectAudatexTaskDialogComponent } from './details/shared/connect-audatex-task-dialog/connect-audatex-task-dialog.component';
import { ConnectDatDossierDialogComponent } from './details/shared/connect-dat-dossier-dialog/connect-dat-dossier-dialog.component';
import { ConnectGtmotiveEstimateDialogComponent } from './details/shared/connect-gtmotive-estimate-dialog/connect-gtmotive-estimate-dialog.component';
import { CostListDialogComponent } from './details/shared/cost-list-dialog/cost-list-dialog.component';
import { CustomFieldDisplayComponent } from './details/shared/custom-field-display/custom-field-display.component';
import { CustomFieldEditorDialogComponent } from './details/shared/custom-field-editor-dialog/custom-field-editor-dialog.component';
import { CustomMarketAnalysisDialogComponent } from './details/shared/custom-market-analysis-dialog/custom-market-analysis-dialog.component';
import { DamageCalculationProvidersComponent } from './details/shared/damage-calculation-providers/damage-calculation-providers.component';
import { DatValuationSettingsDialogComponent } from './details/shared/dat-valuation-settings-dialog/dat-valuation-settings-dialog.component';
import { FeeSetHasChangedDialogComponent } from './details/shared/fee-set-has-changed-dialog/fee-set-has-changed-dialog.component';
import { GeneralInspectionInputComponent } from './details/shared/general-inspection-input/general-inspection-input.component';
import { GradeSelectorComponent } from './details/shared/grade-selector/grade-selector.component';
import { ManualCalculationDialogComponent } from './details/shared/manual-calculation/manual-calculation-dialog.component';
import { ManualCalculationImportDialogComponent } from './details/shared/manual-calculation/manual-calculation-import-dialog/manual-calculation-import-dialog.component';
import { ManualCalculationInputOverlayComponent } from './details/shared/manual-calculation/manual-calculation-input-overlay/manual-calculation-input-overlay.component';
import { MarketValueOverviewComponent } from './details/shared/market-value-overview/market-value-overview.component';
import { PhotoGridComponent } from './details/shared/photos-grid/photo-grid.component';
import { RealtimeEditIndicatorComponent } from './details/shared/realtime-edit-indicator/realtime-edit-indicator.component';
import { ReplacementValueCorrectionDecreasesIncreasesComponent } from './details/shared/replacement-value-corrections-dialog/replacement-value-correction-decreases-increases/replacement-value-correction-decreases-increases.component';
import { ReplacementValueCorrectionLineComponent } from './details/shared/replacement-value-corrections-dialog/replacement-value-correction-line/replacement-value-correction-line.component';
import { ReplacementValueCorrectionsDialogComponent } from './details/shared/replacement-value-corrections-dialog/replacement-value-corrections-dialog/replacement-value-corrections-dialog.component';
import { ReportProgressIndicatorIconComponent } from './details/shared/report-progress/report-progress-indicator-icon/report-progress-indicator-icon.component';
import { ReportProgressPanelDirective } from './details/shared/report-progress/report-progress-panel.directive';
import { ReportProgressPanelComponent } from './details/shared/report-progress/report-progress-panel/report-progress-panel.component';
import { ResidualValueOverviewComponent } from './details/shared/residual-value-overview/residual-value-overview.component';
import { ResidualValueRequestDialogComponent } from './details/shared/residual-value-request-dialog/residual-value-request-dialog.component';
import { RestorationDeductionsDialogComponent } from './details/shared/restoration-deductions-dialog/restoration-deductions-dialog.component';
import { SafetyTestInputComponent } from './details/shared/safety-test-input/safety-test-input.component';
import { SpareTireEquipmentEditorComponent } from './details/shared/spare-tire-equipment-editor/spare-tire-equipment-editor.component';
import { TiresEditorComponent } from './details/shared/tires-editor/tires-editor.component';
import { ValuepilotEquipmentDialogComponent } from './details/shared/valuepilot-equipment-dialog/valuepilot-equipment-dialog.component';
import { VinInputComponent } from './details/shared/vin-input/vin-input.component';
import { MarketAnalysisConnectionDialogComponent } from './details/shared/winvalue-market-analysis-connection-dialog/market-analysis-connection-dialog.component';
import { WinvalueOfferConnectionDialogComponent } from './details/shared/winvalue-offer-connection-dialog/winvalue-offer-connection-dialog.component';
import { DatOrAudatexValuationResultRowComponent } from './details/valuation/dat-or-audatex-valuation/dat-or-audatex-valuation-result-row/dat-or-audatex-valuation-result-row.component';
import { DatOrAudatexValuationComponent } from './details/valuation/dat-or-audatex-valuation/dat-or-audatex-valuation.component';
import { GaragePreviewComponent } from './details/valuation/valuation-calculation/garage-preview/garage-preview.component';
import { ValuationCalculationComponent } from './details/valuation/valuation-calculation/valuation-calculation.component';
import { ValuationRoundDecreaseConfigurationComponent } from './details/valuation/valuation-calculation/valuation-round-decrease-configuration/valuation-round-decrease-configuration.component';
import { ValuationResultEditBaseValuePopoverComponent } from './details/valuation/valuation-result/valuation-result-edit-base-value-popover/valuation-result-edit-base-value-popover.component';
import { ValuationResultEditOriginalPricePopoverComponent } from './details/valuation/valuation-result/valuation-result-edit-original-price-popover/valuation-result-edit-original-price-popover.component';
import { ValuationResultComponent } from './details/valuation/valuation-result/valuation-result.component';
import { ValuationComponent } from './details/valuation/valuation.component';
import { LegacyReportsImportDialogComponent } from './legacy-reports-import-dialog/legacy-reports-import-dialog.component';
import { RepairConfirmationCreationInstructionsDialogComponent } from './repair-confirmation-creation-instructions-dialog/repair-confirmation-creation-instructions-dialog.component';
import { ReportListRowComponent } from './report-list/report-list-row/report-list-row.component';
import { ReportListComponent } from './report-list/report-list.component';
import { SetupStepsComponent } from './report-list/setup-steps/setup-steps.component';
import { ReportsComponent } from './reports.component';
import { reportsRouting } from './reports.routing';

@NgModule({
    imports: [
        SharedModule,
        reportsRouting,
        MatProgressSpinnerModule,
        MatIconModule,
        MatLegacyTooltipModule,
        ClaimantSignatureModule,
        SignaturePadModule,
        NgIf,
        CleanBaseModelPipe,
        VehicleRequiresTwoOrMoreAxlesPipe,
        MatIconModule,
        ScrollingModule,
        CdkConnectedOverlay,
        WhiteButtonWithShadowModule,
        IconPickerModule,
    ],
    declarations: [
        AccidentDataComponent,
        AudatexCalculationInsertionDialog,
        AutoonlineOfferConnectionDialogComponent,
        BidSelectorComponent,
        CancelResidualValueOfferDialogComponent,
        CarcasionOfferConnectionDialogComponent,
        CarConditionComponent,
        CarDataComponent,
        CarRegistrationScannerDialogComponent,
        CarSelectorComponent,
        CartvOfferConnectionDialogComponent,
        ConfirmDialogComponent,
        ConnectAudatexTaskDialogComponent,
        ConnectDatDossierDialogComponent,
        ConnectGtmotiveEstimateDialogComponent,
        CopyReportDialogComponent,
        CostListDialogComponent,
        CustomCarPropertiesComponent,
        CustomCarPropertyInputTitleComponent,
        CustomCarPropertyInputValueComponent,
        CustomerSignaturesCardComponent,
        CustomerSignaturesDialogComponent,
        CustomFeeSetDialogComponent,
        CustomFieldDisplayComponent,
        CustomFieldEditorDialogComponent,
        CustomMarketAnalysisDialogComponent,
        DamageCalculationComponent,
        DamageCalculationProvidersComponent,
        DamageCheckboxComponent,
        DatOrAudatexValuationComponent,
        DatOrAudatexValuationResultRowComponent,
        DatValuationSettingsDialogComponent,
        DefaultDocumentOrderDialogComponent,
        DefaultDocumentOrderGroupComponent,
        DefaultPhotoDescriptionsEditorComponent,
        DiminishedValueCalculatorComponent,
        DocxWatermarkSettingsDialogComponent,
        DocxWatermarkSettingsCardComponent,
        EquipmentBulkImportDialogComponent,
        EquipmentSelectorComponent,
        ExpertStatementComponent,
        FeesComponent,
        FeeSetHasChangedDialogComponent,
        GaragePreviewComponent,
        GeneralInspectionInputComponent,
        GradeSelectorComponent,
        InvoiceAuditComponent,
        LeaseReturnComponent,
        LeaseReturnTemplateManagementComponent,
        LeaseReturnTextTemplateDialog,
        LegacyReportsImportDialogComponent,
        ManualCalculationDialogComponent,
        ManualCalculationImportDialogComponent,
        MarketAnalysisConnectionDialogComponent,
        MarketValueOverviewComponent,
        MultipleCarsIdentificationHelperComponent,
        NumberOfReportsCongratsDialogComponent,
        OldtimerValuationComponent,
        OldtimerValuationLargeConditionComponent,
        OldVehicleCertificatesOverviewComponent,
        OverwriteDocumentBuildingBlocksComponent,
        PaintThicknessComponent,
        PaintThicknessInputComponent,
        PaintThicknessRangeLabelPipe,
        PaintThicknessRangeValuePipe,
        PaintThicknessScaleEditDialogComponent,
        PaintThicknessScaleSelectorComponent,
        PhotoEditorComponent,
        PhotoGridComponent,
        PhotosComponent,
        PreviousOwnersComponent,
        PrintAndTransmissionComponent,
        PromptDialogComponent,
        RealtimeEditIndicatorComponent,
        RemoteSignatureDialogComponent,
        RepairConfirmationComponent,
        RepairConfirmationCreationInstructionsDialogComponent,
        ReplacementValueCorrectionDecreasesIncreasesComponent,
        ReplacementValueCorrectionLineComponent,
        ReplacementValueCorrectionsDialogComponent,
        ReportDetailsComponent,
        ReportListComponent,
        ReportListRowComponent,
        ReportProgressIndicatorIconComponent,
        ReportProgressPanelComponent,
        ReportProgressPanelDirective,
        ReportsComponent,
        ResidualValueOverviewComponent,
        ResidualValueRequestDialogComponent,
        RestorationDeductionsDialogComponent,
        SafetyTestInputComponent,
        SetupStepsComponent,
        SpareTireDetailsDialogComponent,
        SpareTireEquipmentEditorComponent,
        SpecialCostsDialogComponent,
        TiresEditorComponent,
        ValuationCalculationComponent,
        ValuationComponent,
        ValuationResultComponent,
        ValuationResultEditOriginalPricePopoverComponent,
        ValuationResultEditBaseValuePopoverComponent,
        ValuationRoundDecreaseConfigurationComponent,
        ValuepilotEquipmentDialogComponent,
        VinInputComponent,
        WinvalueOfferConnectionDialogComponent,
        LicensePlateRedactionInfoDialogComponent,
        ManualCalculationInputOverlayComponent,
        OldtimerGradesComponent,
    ],
    providers: [
        AdeltaLiquidityService,
        AfzzertProcessesService,
        AudatexCalculationTextService,
        AudatexKbaCodeService,
        AudatexTaskService,
        AudatexTaskService,
        AudatexVinService,
        CarEquipmentService,
        CarEquipmentTemplateService,
        CarRegistrationScannerService,
        ClaimantSignatureFileService,
        ClaimantSignaturesFormDraftService,
        CopyClaimantSignaturesToReportService,
        CopyDatDossierService,
        CopyMarketAnalysisDocumentsToReportService,
        CopyPhotosToReportService,
        CopyResidualValueDocumentsToReportService,
        CopyUserUploadedDocumentsToReportService,
        CustomAutocompleteEntriesService,
        CustomFeeSetService,
        DatAuthenticationService,
        DatDamageCalculationService,
        DatDossierListService,
        DatValuationService,
        DatVehicleIdentificationService,
        DocxWatermarkTemplateService,
        EmailService,
        FieldGroupConfigService,
        GdvResponsibleInsuranceService,
        GtmotiveEstimateService,
        GtmotiveVinService,
        InvoiceNumberOrReportTokenCounterService,
        InvoiceNumberService,
        LeaseReturnTemplateService,
        LegacyReportService,
        ManufacturerService,
        OriginalPhotoService,
        OriginalRenderedDocumentBuildingBlocksService,
        PersaldoService,
        PhotoBlobUrlCacheService,
        RenderedPhotoFileService,
        ReportDetailsService,
        ReportProgressConfigService,
        ReportRAMCacheService,
        ReportRealtimeEditorService,
        ReportsAnalyticsService,
        ReportService,
        ReportTokenAndInvoiceNumberService,
        InvoiceNumberJournalEntryService,
        ReportTokenService,
        CustomFieldButtonTriggerService,
        ResidualValueBidderGroupService,
        ResidualValueInvitationService,
        ResidualValueRequestService,
        RestorePhotosFromReportService,
        TemplatePlaceholderValuesService,
        TextTemplateService,
        WatermarkImageFileService,
        LicensePlateRedactionService,
        ReportDetailsGuard,
        OutgoingMessageService,
    ],
})
export class ReportsModule {}

import { Report } from '../../models/reports/report';
import {
    fullTaxationRate,
    fullTaxationRateSecondHalfOf2020,
    marginTaxationRate,
    marginTaxationRateSecondHalfOf2020,
    neutralTaxationRate,
} from '../../static-data/taxation-rates';

/**
 * The replacement value used to compare repair costs with differs for private persons (gross value)
 * and companies (net value; amount depends on taxation type).
 */
export function getApplicableReplacementValue(report: Report): number {
    // Claimant = private person
    if (!report.claimant.contactPerson.mayDeductTaxes) {
        return report.valuation.vehicleValueGross;
    }
    // Claimant = company able to deduct taxes
    else {
        let taxRate: number = 0;
        switch (report.valuation.taxationType) {
            case 'full':
                taxRate = fullTaxationRate;
                break;
            case 'full_16':
                taxRate = fullTaxationRateSecondHalfOf2020;
                break;
            case 'margin_reduced':
                taxRate = marginTaxationRateSecondHalfOf2020;
                break;
            case 'margin':
                taxRate = marginTaxationRate;
                break;
            case 'neutral':
                taxRate = neutralTaxationRate;
                break;
        }
        // Calculate the net value
        return report.valuation.vehicleValueGross / (1 + taxRate);
    }
}

<ng-container *ngIf="contactPerson">
    <div class="info-panel-row cursor-pointer" (click)="toggleContactDetails()">
        <!--============================================-->
        <!-- Icon -->
        <!--============================================-->
        <div class="info-panel-row_icon-container" [ngSwitch]="involvedPartyRole">
            <mat-icon *ngSwitchCase="'claimant'">person</mat-icon>
            <mat-icon *ngSwitchCase="'garage'">build</mat-icon>
            <img *ngSwitchCase="'lawyer'" src="/assets/images/icons/lawyer-white.svg" />

            <!--********** Insurance Icons **********-->
            <ng-container *ngSwitchCase="'insurance'">
                <!--********** Insurance Logo **********-->
                <div class="insurance-logo-container" *ngIf="insuranceLogoExists(contactPerson.organization)">
                    <img class="organization-logo" src="/assets/images/logos/insurances/{{ iconNameForInsurance }}" />
                </div>

                <!--********** Fallback Icon **********-->
                <mat-icon *ngIf="!insuranceLogoExists(contactPerson.organization)">shield</mat-icon>
            </ng-container>
            <img
                class="checkmark checkmark-email"
                *ngIf="hasReceivedEmail"
                (click)="navigateToPrintAndTransmissionPage()"
                src="/assets/images/icons/checkmark-blue_16.png"
                matTooltip="hat E-Mail empfangen"
                @fadeInAndOut />
            <img
                class="checkmark checkmark-letter"
                *ngIf="hasReceivedLetter && !hasReceivedEmail"
                (click)="navigateToPrintAndTransmissionPage()"
                src="/assets/images/icons/sent-by-mail_16.png"
                matTooltip="hat Brief empfangen"
                @fadeInAndOut />
        </div>
        <!--============================================-->
        <!-- END Icon -->
        <!--============================================-->

        <!--********** Text **********-->
        <div class="info-panel-row_text-container">
            <div class="info-panel-row_value" [matTooltip]="organizationOrName">
                {{ organizationOrName }}
            </div>
            <div class="label">{{ involvedPartyTypeGerman }}</div>
        </div>

        <!--********** Action Button **********-->
        <mat-icon class="toolbar-icon" *ngIf="!contactDetailsVisible">unfold_more</mat-icon>
        <mat-icon class="toolbar-icon" *ngIf="contactDetailsVisible">unfold_less</mat-icon>
    </div>

    <div class="contact-details-container" *ngIf="contactDetailsVisible" @slideInAndOutVertically>
        <!--********** First Name **********-->
        <div *ngIf="organizationOrName !== firstAndLastName">
            {{ firstAndLastName }}
        </div>

        <!--********** Phone **********-->
        <div>
            <ng-container *ngIf="contactPerson.phone">
                <a href="tel:{{ contactPerson.phone }}">{{ contactPerson.phone }}</a>
            </ng-container>
            <span *ngIf="!contactPerson.phone" class="info-panel-row_no-value">Kein Telefon</span>
        </div>

        <!--********** Phone 2 **********-->
        <div *ngIf="contactPerson.phone2">
            <a href="tel:{{ contactPerson.phone2 }}">{{ contactPerson.phone2 }}</a>
        </div>

        <!--********** Email **********-->
        <div>
            <ng-container *ngIf="contactPerson.email">
                <a href="mailto:{{ contactPerson.email }}">{{ contactPerson.email }}</a>
            </ng-container>
            <span *ngIf="!contactPerson.email" class="info-panel-row_no-value">Keine E-Mail</span>
        </div>

        <!--********** Additional Information **********-->
        <ng-content></ng-content>

        <!--        &lt;!&ndash;********** Damage Number **********&ndash;&gt;-->
        <!--        <div class="report-details-item">-->
        <!--            <div class="report-details-icon-container">-->
        <!--                <mat-icon class="contact-item-icon medium-icon">tag</mat-icon>-->
        <!--            </div>-->
        <!--            <div class="report-details-item-value-container">-->
        <!--                <div class="report-details-item-value" [matTooltip]="invoice.reportData.insuranceCaseNumber">-->
        <!--                    <span>{{ invoice.reportData.insuranceCaseNumber || '-' }}</span>-->
        <!--                </div>-->
        <!--                <div class="report-details-item-label">Schadennummer</div>-->
        <!--            </div>-->
        <!--        </div>-->

        <!--        &lt;!&ndash;********** Insurance Number **********&ndash;&gt;-->
        <!--        <div class="report-details-item">-->
        <!--            <div class="report-details-icon-container">-->
        <!--                <mat-icon class="contact-item-icon medium-icon">description</mat-icon>-->
        <!--            </div>-->
        <!--            <div class="report-details-item-value-container">-->
        <!--                <div class="report-details-item-value" [matTooltip]="invoice.reportData.insuranceContractNumber">-->
        <!--                    <span>{{ invoice.reportData.insuranceContractNumber || '-' }}</span>-->
        <!--                </div>-->
        <!--                <div class="report-details-item-label">Versicherungsscheinnummer</div>-->
        <!--            </div>-->
        <!--        </div>-->
    </div>
</ng-container>

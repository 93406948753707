import { Pipe, PipeTransform } from '@angular/core';
import moment from 'moment';
import 'moment/locale/de';
import { GERMAN_DATE_FULL } from '@autoixpert/lib/date-and-time/date-formats';

@Pipe({
    name: 'moment',
})
export class MomentPipe implements PipeTransform {
    transform(valueToBeTransformed: string | Date, momentFormat: string = GERMAN_DATE_FULL): string {
        return moment(valueToBeTransformed).format(momentFormat);
    }
}

@Pipe({
    name: 'momentCalendar',
})
export class MomentCalendarPipe implements PipeTransform {
    transform(valueToBeTransformed: string, granularity?: 'day'): string {
        let calendarFormat;
        if (granularity === 'day') {
            calendarFormat = {
                sameDay: '[heute]',
                sameElse: 'L',
                nextDay: '[morgen]',
                nextWeek: 'dddd',
                lastDay: '[gestern]',
                lastWeek: '[letzten] dddd',
            };
        }
        return moment(valueToBeTransformed).calendar(calendarFormat);
    }
}

@Pipe({
    name: 'momentFromNow',
})
export class MomentFromNowPipe implements PipeTransform {
    transform(valueToBeTransformed: string, withoutSuffix = false): string {
        return moment(valueToBeTransformed).fromNow(withoutSuffix);
    }
}

/**
 * The only difference between this pipe and the standard moment pipe is that this pipe resets the utcOffset to zero.
 * This comes in handy for durations. A duration of 24 hours would be 1 day and one hour with an offset of +01:00 as we have it in continental Europe.
 */
@Pipe({
    name: 'momentWithoutUtcOffset',
})
export class MomentWithoutUtcOffsetPipe implements PipeTransform {
    transform(valueToBeTransformed: string, momentFormat: string = 'DD.MM.YYYY'): string {
        return moment(valueToBeTransformed).utcOffset(0).format(momentFormat);
    }
}

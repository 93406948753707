import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import * as Sentry from '@sentry/angular';
import { ApiErrorHandleParameters, ApiErrorHandler } from '@autoixpert/abstract-services/api-error.abstract.service';
import { flattenErrors } from '@autoixpert/lib/errors/flatten-errors';
import {
    ImmediateActionAndError,
    ToastClickAndError,
    registerActionAndClickHandlers,
} from '@autoixpert/lib/errors/register-action-and-click-handlers';
import { resolveErrorHandlerObjectOrFunction } from '@autoixpert/lib/errors/resolve-handler-object-or-function';
import { AxError } from '@autoixpert/models/errors/ax-error';
import { AxAngularErrorHandlerData } from '../libraries/error-handlers/ax-angular-error-handler-data';
import { consoleLogAxError } from '../libraries/error-handlers/console-log-ax-error';
import { getAccessRightsErrorHandlers } from '../libraries/error-handlers/get-access-rights-error-handlers';
import { getDataRelocationErrorHandlers } from '../libraries/error-handlers/get-data-relocation-error-handlers';
import { getDocumentsApiErrorHandlers } from '../libraries/error-handlers/get-documents-api-error-handlers';
import { getEmailApiErrorHandlers } from '../libraries/error-handlers/get-email-api-error-handlers';
import { getOfflineErrorHandlers } from '../libraries/error-handlers/get-offline-error-handlers';
import { getDatabaseErrorHandlers } from '../libraries/get-database-error-handlers';
import { ToastService } from './toast.service';

@Injectable()
export class ApiErrorService {
    constructor(
        private toastService: ToastService,
        private router: Router,
    ) {}

    /**
     * Returns the first error in the error chain that has the given error code.
     */
    public getErrorByCode({ axError, code }: { axError: AxError; code: string }): AxError | undefined {
        // Special case (TODO once necessary): Handle special case: Array of Errors
        const flatErrors = flattenErrors(axError);
        return flatErrors.find((error) => error.code === code);
    }

    public handleAndRethrow({ axError, handlers = {}, defaultHandler }: ApiErrorHandleParameters): never {
        //*****************************************************************************
        //  Special Case: Array of Errors
        //****************************************************************************/
        if (Array.isArray(axError)) {
            const axErrors: AxError[] = axError;
            const rethrownErrors: AxError[] = [];

            for (const axError of axErrors) {
                try {
                    this.createToastAndRethrow({ axError, handlers, defaultHandler });
                } catch (error) {
                    rethrownErrors.push(error);
                }
            }

            const unhandledRethrownErrors: AxError[] = [];
            const handledRethrownErrors: AxError[] = [];
            for (const rethrownError of rethrownErrors) {
                if ((rethrownError.data as AxAngularErrorHandlerData)?.didFrontendHandleError) {
                    handledRethrownErrors.push(rethrownError);
                } else {
                    unhandledRethrownErrors.push(rethrownError);
                }
            }

            /**
             * If all errors were handled but one, throw the unhandled error to the global Angular error handler. That makes
             * clustering in Sentry easier than having the very generic error code "MULTIPLE_API_ERRORS" in Sentry.
             */
            if (unhandledRethrownErrors.length === 1) {
                throw unhandledRethrownErrors[0];
            } else {
                throw new AxError({
                    code: 'MULTIPLE_API_ERRORS',
                    message: `There were multiple API errors which were displayed to the user separately via the ToastService and summarized in this error for global Angular error handling which requires an error object instead of an array of errors. Have a look at the data property.`,
                    data: {
                        unhandledRethrownErrors,
                        handledRethrownErrors,
                        ...({
                            didFrontendHandleError: unhandledRethrownErrors.length === 0,
                        } as AxAngularErrorHandlerData),
                    },
                });
            }
        }
        /////////////////////////////////////////////////////////////////////////////*/
        //  END Special Case: Array of Errors
        /////////////////////////////////////////////////////////////////////////////*/
        //*****************************************************************************
        //  Default Case: 1 Error
        //****************************************************************************/
        else {
            this.createToastAndRethrow({ axError, handlers, defaultHandler });
        }
        /////////////////////////////////////////////////////////////////////////////*/
        //  END Default Case: 1 Error
        /////////////////////////////////////////////////////////////////////////////*/
    }

    private createToastAndRethrow({ axError, handlers = {}, defaultHandler }: CreateToastAndRethrowParameters): never {
        /**
         * Specific error handlers = handle a specific error code.
         *     Multiple specific error handlers may be executed if there is a caused-by-chain that contains
         *     the error codes of multiple specific error handlers.
         *
         * Default error handler = handle all errors that are not handled by a specific error handler.
         */
        const specificErrorHandlers = {
            /**
             * Add these custom handlers to the default handlers that catch very common errors that happen throughout various API endpoints.
             */
            ...getEmailApiErrorHandlers(),
            ...getDatabaseErrorHandlers(),
            ...getDocumentsApiErrorHandlers(this.router),
            ...getOfflineErrorHandlers(),
            ...getAccessRightsErrorHandlers(),
            ...getDataRelocationErrorHandlers(),

            ...handlers,
        };

        // Executed as soon as the error code is matched.
        const immediateActionsAndErrors: ImmediateActionAndError[] = [];
        // Executed when the user clicks on the toast.
        const toastClicksAndErrors: ToastClickAndError[] = [];

        //*****************************************************************************
        //  Get First Matching Specific Error Handler
        //****************************************************************************/
        let isErrorHandled: boolean;

        // The first handler in the chain of handlers. The chain is built along the causedBy-line.
        let firstHandler: ApiErrorHandler;

        /**
         * Contains a list of HTML strings which are added to the toast message to explain the reasons for the error.
         *
         * Example:
         * - top handler: "The document could not be rendered."
         * - reason 1: "The placeholder values could not be generated."
         * - reason 2: "The field 'Schadenbeschreibung' contains an invalid character."
         */
        let htmlToastMessageReasons = [];

        const specificErrorCodes = Object.keys(specificErrorHandlers);
        const flattenedErrors = flattenErrors(axError);
        const firstSpecificallyMatchedError = flattenedErrors.find((error) => specificErrorCodes.includes(error.code));
        if (firstSpecificallyMatchedError) {
            firstHandler = resolveErrorHandlerObjectOrFunction(
                specificErrorHandlers[firstSpecificallyMatchedError.code],
                firstSpecificallyMatchedError,
            );
            // If the top handler has a callback function, add it to the array of functions to be executed.
            registerActionAndClickHandlers({
                handler: firstHandler,
                error: firstSpecificallyMatchedError,
                immediateActionsAndErrors,
                toastClicksAndErrors,
            });

            /**
             * We assume that the error was handled by a specific handler and don't want to log it so that
             * it does not spam the console and Sentry.
             */
            isErrorHandled = firstHandler.forceConsiderErrorHandled ?? true;

            //*****************************************************************************
            //  Construct Error Toast Reason Chain
            //****************************************************************************/
            // If the error was caused by another error, show the reasons to the user.
            if (
                typeof firstSpecificallyMatchedError === 'object' &&
                'causedBy' in firstSpecificallyMatchedError &&
                !firstHandler.stopReasonChain
            ) {
                const causedByErrors = flattenErrors(firstSpecificallyMatchedError.causedBy);
                for (const error of causedByErrors) {
                    const handler = resolveErrorHandlerObjectOrFunction(specificErrorHandlers[error.code], error);
                    // Only add reasons or callback actions if there is a registered handler for this API error.
                    if (handler) {
                        registerActionAndClickHandlers({
                            handler,
                            error,
                            toastClicksAndErrors,
                            immediateActionsAndErrors,
                        });
                        if (handler.forceDisplayAsTopHandler) {
                            firstHandler = handler;
                            htmlToastMessageReasons = [];
                            break;
                        }
                        if (handler.stopReasonChain) {
                            break;
                        }
                        // Add the reason HTML.
                        htmlToastMessageReasons.push(
                            `<div class="reason"><span class="reason-label">Grund:</span> <span class="reason-title">${
                                handler.title ? handler.title + '<br>' : ''
                            }</span>${handler.body}</div>`,
                        );
                    }
                }
            }
            /////////////////////////////////////////////////////////////////////////////*/
            //  END Construct Error Toast Reason Chain
            /////////////////////////////////////////////////////////////////////////////*/
        }
        /////////////////////////////////////////////////////////////////////////////*/
        //  END Get First Matching Specific Error Handler
        /////////////////////////////////////////////////////////////////////////////*/
        //*****************************************************************************
        //  Default Handler
        //****************************************************************************/
        /**
         * No specific error handler found. Use the default handler.
         *
         * Idea: It's best to tell the user WHY a document could not be rendered. But it's better to tell a user
         *       THAT a document failed to render instead of showing a generic message that something went wrong.
         *       That's why we introduced default (catch-all) handlers.
         */
        else {
            firstHandler = resolveErrorHandlerObjectOrFunction(defaultHandler, axError);
            // If the top handler has a callback function, add it to the array of functions to be executed.
            registerActionAndClickHandlers({
                handler: firstHandler,
                error: axError,
                toastClicksAndErrors,
                immediateActionsAndErrors,
            });

            /**
             * Since default error handlers are catch-all error handlers, we want to be notified about them through Sentry
             * so that we can make the error message more precise.
             */
            isErrorHandled = firstHandler.forceConsiderErrorHandled ?? false;
        }
        /////////////////////////////////////////////////////////////////////////////*/
        //  END Default Handler
        /////////////////////////////////////////////////////////////////////////////*/

        // Execute all actions defined by the handlers as soon as the error occurs.
        for (const immediateActionAndError of immediateActionsAndErrors) {
            immediateActionAndError.action(immediateActionAndError.error);
        }

        //*****************************************************************************
        //  Error Toast
        //****************************************************************************/
        const toastTitle = firstHandler.title || '';
        let toastBody = firstHandler.body || '';

        /**
         * We can prevent a toast from being shown if the toast title and body are empty. Usually only relevant
         * if displaying a toast depends on the axError.data object. That can usually only be determined in an
         * ApiErrorHandlerCreator.
         */
        if (toastTitle || toastBody) {
            // Add the reasons to the toast HTML body.
            if (htmlToastMessageReasons.length) {
                toastBody += htmlToastMessageReasons.join('');
            }

            // Add timeout and progress bar
            const firstHandlerTimeout =
                firstHandler.timeout != undefined
                    ? {
                          timeOut: firstHandler.timeout,
                          showProgressBar: !!firstHandler.timeout,
                      }
                    : undefined;

            /**
             * Display errors of partners with their logo.
             */
            let toast: ReturnType<ToastService['partnerError']>;
            if (firstHandler.partnerLogo) {
                toast = this.toastService.partnerError(
                    toastTitle,
                    toastBody,
                    firstHandler.partnerLogo,
                    firstHandlerTimeout,
                );
            } else {
                toast = this.toastService[firstHandler.toastType || 'error'](
                    toastTitle,
                    toastBody,
                    firstHandlerTimeout,
                );
            }

            // Register click event handlers.
            if (toastClicksAndErrors.length) {
                const toastSubscription = toast.click.subscribe(() => {
                    for (const toastClickAndError of toastClicksAndErrors) {
                        toastClickAndError.toastClick(toastClickAndError.error);
                    }
                    // Prevent memory leaks.
                    toastSubscription.unsubscribe();
                });
            }
        }
        /////////////////////////////////////////////////////////////////////////////*/
        //  END Error Toast
        /////////////////////////////////////////////////////////////////////////////*/

        //*****************************************************************************
        //  Rethrow Error
        //****************************************************************************/
        /**
         * Errors are marked as handled instead of swallowed in the ApiErrorService because we still want them to
         * break the control flow of the function where ApiErrorService.handle() is called. That ensures no
         * further action is taken after the error was handled except there is another explicit catch block.
         */
        if (!axError.data) {
            axError.data = {};
        }
        (axError.data as AxAngularErrorHandlerData).didFrontendHandleError = isErrorHandled;

        throw axError;
        /////////////////////////////////////////////////////////////////////////////*/
        //  END Rethrow Error
        /////////////////////////////////////////////////////////////////////////////*/
    }

    /**
     * Log an error to the console and to Sentry without breaking control flow. This might be important
     * when the error should not break the control flow of the function where the error occurred.
     *
     * Should be used sparingly. Usually, errors should be rethrown to the global Angular error handler because we usually
     * want the control flow to end when an error occurs.
     */
    public logErrorSilently(error: Error | AxError): void {
        /**
         * This is what the AxAngularErrorHandler does.
         */
        Sentry.captureException(error);
        if (typeof error === 'object' && 'type' in error && error.type === 'AxError') {
            consoleLogAxError(error);
        } else {
            console.error(error);
        }
    }
}

export {
    ApiErrorHandleParameters,
    ApiErrorHandlerCreator,
    ApiErrorHandler,
} from '@autoixpert/abstract-services/api-error.abstract.service';

type CreateToastAndRethrowParameters = Omit<ApiErrorHandleParameters, 'axError'> & {
    axError: AxError;
};

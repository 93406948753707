import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { SwUpdate } from '@angular/service-worker';
import { OutgoingMessage } from '@autoixpert/models/outgoing-message';
import { LiveSyncWithInMemoryCacheServiceBase } from '../libraries/database/live-sync-with-in-memory-cache.service-base';
import { FeathersSocketioService } from './feathers-socketio.service';
import { FrontendLogService } from './frontend-log.service';
import { NetworkStatusService } from './network-status.service';
import { SyncIssueNotificationService } from './sync-issue-notification.service';

@Injectable({
    providedIn: 'root',
})
export class OutgoingMessageService extends LiveSyncWithInMemoryCacheServiceBase<OutgoingMessage> {
    constructor(
        protected httpClient: HttpClient,
        protected networkStatusService: NetworkStatusService,
        protected frontendLogService: FrontendLogService,
        protected syncIssueNotificationService: SyncIssueNotificationService,
        protected serviceWorker: SwUpdate,
        protected feathersSocketioService: FeathersSocketioService,
    ) {
        super({
            serviceName: 'outgoingMessage',
            httpClient,
            networkStatusService,
            syncIssueNotificationService,
            serviceWorker,
            frontendLogService,
            feathersSocketioClient: feathersSocketioService,
            recordMigrations: [],
        });
    }
}

import { Payment } from '../../models/invoices/payment';
import { GERMAN_DATE_FORMAT, makeLuxonDateTime } from '../ax-luxon';
import { convertToEuro } from './convert-to-euro';
import { CurrencyGerman } from './placeholder-values.types';

export function getPaymentPlaceholderValuesGerman(payment: Payment): PaymentGerman {
    return {
        Betrag: payment?.amount ? convertToEuro(payment.amount) : '',
        Datum: payment?.date ? makeLuxonDateTime(payment.date).toLocaleString(GERMAN_DATE_FORMAT) : '',
        Zahler: payment?.payer || '',
        InterneBemerkungen: payment?.notes || '',
        istKürzung: payment?.type === 'shortPayment',
        EmpfängerIBAN: payment?.iban,
    };
}

export interface PaymentGerman {
    Betrag: CurrencyGerman;
    Datum: string;
    Zahler: string;
    InterneBemerkungen: string;
    istKürzung: boolean;
    EmpfängerIBAN: string;
}

import { ReportTokenConfig } from '@autoixpert/models/teams/invoice-or-report-counter-config';

/**
 * In case the user chose to start the first report invoice without a counter (e.g. for report GA-009 -> first invoice number GA-009, then GA-009/1 etc.)
 * we need to strip the invoice number sub counter from the pattern. Because the user might place some delimiter between the report token and sub counter placeholder,
 * we need to filter out any reasonable special character that the user might used to separate the token and sub counter.
 *
 * Example input: "RE-{AZ}/{R}" -> "RE-{AZ}"
 *                "RE-{AZ}-{R}" -> "RE-{AZ}"
 *                "RE-{AZ}{R}" -> "RE-{AZ}"
 */
export function getReportInvoiceNumberPatternWithoutSubCounter(
    reportInvoiceNumberConfig: Pick<ReportTokenConfig, 'reportInvoiceNumberPattern'>,
): string {
    // Regex replaces the string "<optional delimiter>{R}", where the delimiter is one of the special characters listed in the character class below.
    return reportInvoiceNumberConfig.reportInvoiceNumberPattern?.replace(/[-\/_:#+.<>|\\]?{R}/g, '') ?? '';
}

<div id="edit-text-panel" class="card">
    <!--********** Close Icon **********-->
    <mat-icon class="dialog-close-icon" (click)="closePanel()" matTooltip="Schließen">close</mat-icon>

    <!--********** Textarea **********-->
    <placeholder-autocomplete-input
        [(text)]="textElement.content"
        [useTextarea]="true"
        [axAutofocus]="true"
        placeholder="Text (Platzhalter möglich)"
        (change)="emitChange()"
        (keydown)="leaveEditModeOnCtrlEnter($event)"></placeholder-autocomplete-input>

    <div class="label">Platzhalter mit geschweiften Klammern beginnen &lcub; &rcub;</div>
</div>

import { Component, EventEmitter, Input, Output } from '@angular/core';
import { joinList } from '@autoixpert/lib/arrays/join-list';
import { translateDocumentOrderConfigName } from '@autoixpert/lib/translators/translate-document-order-config-name';
import { translateDocumentType } from '@autoixpert/lib/translators/translate-document-type';
import { DocumentOrderConfig, DocumentTemplateType } from '@autoixpert/models/documents/document-order-config';
import { DocumentOrderConfigAssociationChange } from '../../../preferences/document-types-dialog/document-types-dialog.component';

@Component({
    selector: 'document-type-tag-list',
    templateUrl: './document-type-tag-list.component.html',
    styleUrls: ['./document-type-tag-list.component.scss'],
})
export class DocumentTypeTagListComponent {
    @Input() disabled: boolean = false;
    @Input({ required: true }) selectedDocumentOrderConfigIds: DocumentOrderConfig['_id'][] = [];
    /**
     * This contains all document order configs, not only the associated ones.
     * This is used as an input instead of using a service call when this component is initialized
     * to avoid unnecessary API calls when there are many tag lists, such as in the document building
     * block list.
     */
    @Input({ required: true }) allDocumentOrderConfigs: DocumentOrderConfig[];

    @Input() tooltipPrefix: string = 'In diesen Dokumenten verwendet:\n';
    @Input() placeholder: string = '-';
    @Input() numberOfDisplayedTags: number = 2;

    // We won't render the power of attorney with a specific template since the document doesn't belong to the assessor's CI.
    // If customers want to use a custom power of attorney, they have to use a custom signable document.
    @Input() disallowPowerOfAttorney: boolean = false;

    @Output() change = new EventEmitter<DocumentOrderConfigAssociationChange[]>();
    @Output() showDialog = new EventEmitter<void>();
    @Output() closeDialog = new EventEmitter<void>();

    public documentTypesDialogShown: boolean = false;

    //*****************************************************************************
    //  Document Types Dialog
    //****************************************************************************/
    public showDocumentTypesDialog(): void {
        if (this.disabled) return;

        this.documentTypesDialogShown = true;
        this.showDialog.emit();
    }

    public hideDocumentTypesDialog() {
        this.documentTypesDialogShown = false;
        this.closeDialog.emit();
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Document Types Dialog
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Tooltip
    //****************************************************************************/
    public getTooltip(): string {
        return `${this.tooltipPrefix}${joinList(this.getGermanDocumentList(), '\n', '\n')}`;
    }

    private getGermanDocumentList(): string[] {
        const associatedDocumentOrderConfigs: DocumentOrderConfig[] = this.allDocumentOrderConfigs.filter(
            (documentOrderConfig) => {
                return this.selectedDocumentOrderConfigIds?.includes(documentOrderConfig._id);
            },
        );

        return associatedDocumentOrderConfigs.map((documentOrderConfig) =>
            translateDocumentOrderConfigName({ documentOrderConfig }),
        );
    }

    public getHumanReadableDocumentList(): string {
        const documentTypesGerman: string[] = this.getGermanDocumentList();

        if (documentTypesGerman.length <= this.numberOfDisplayedTags) {
            return documentTypesGerman.join(', ');
        }
        if (documentTypesGerman.length === this.numberOfDisplayedTags + 1) {
            return documentTypesGerman.slice(0, this.numberOfDisplayedTags).join(', ') + ' + 1 weiteres';
        }

        if (documentTypesGerman.length > this.numberOfDisplayedTags + 1) {
            return (
                documentTypesGerman.slice(0, this.numberOfDisplayedTags).join(', ') +
                ` + ${documentTypesGerman.length - this.numberOfDisplayedTags} weitere`
            );
        }
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Tooltip
    /////////////////////////////////////////////////////////////////////////////*/

    public handleChange(documentTypeChanges: DocumentOrderConfigAssociationChange[]) {
        this.change.emit(documentTypeChanges);
    }
}

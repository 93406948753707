<div class="dialog-overlay" (mousedown)="handleOverlayClick($event)" @dialogEnterAndLeave>
    <div class="dialog-container contains-scrollable-area">
        <!-- Close icon -->
        <mat-icon class="close-icon toolbar-icon" (click)="close.emit()">close</mat-icon>

        <div class="dialog-headline-container">
            <!-- Headline -->
            <h2 class="dialog-headline">Online unterschreiben</h2>

            <!-- Sub headline -->
            <div class="dialog-sub-headline">
                Sende deinem Kunden einen Link, mit dem er Auftragsdokumente unterschreiben kann.
            </div>
        </div>

        <!-- Deadline badge & link badge row -->
        <div *ngIf="report.remoteSignatureConfig" class="badge-row">
            <!-- Deadline badge -->
            <div class="deadline-badge">
                <!-- Deadline badge calendar icon -->
                <mat-icon class="deadline-badge-calendar-icon">today</mat-icon>

                <!-- Deadline badge headline & date container -->
                <div>
                    <!-- Deadline badge headline -->
                    <div class="deadline-badge-headline">Zu unterschreiben bis</div>

                    <!-- Deadline badge date & time-->
                    <div class="deadline-badge-date">
                        <!-- Deadline badge date -->
                        <date-input
                            #dateInput
                            id="invoice-date-container"
                            class="deadline-hidden-input"
                            [showDatepickerIcon]="false"
                            [date]="report.remoteSignatureConfig.signatureDeadlineAt"
                            (dateChange)="handleSignatureDeadlineChange($event)"
                            [disabled]="isReportLocked(report)"></date-input>

                        <span
                            class="deadline-date-link"
                            [ngClass]="{ expired: hasSignatureDeadlinePassed() }"
                            [matTooltip]="'Datum ändern'"
                            (click)="dateInput.matDatepicker.open()">
                            {{ report.remoteSignatureConfig.signatureDeadlineAt | date: 'dd.MM.yyyy' }}
                        </span>

                        <span>&nbsp;-&nbsp;</span>

                        <!-- Deadline badge time -->
                        <span
                            class="deadline-date-link"
                            [ngClass]="{ expired: hasSignatureDeadlinePassed() }"
                            [matTooltip]="'Uhrzeit ändern'"
                            [matMenuTriggerFor]="deadlineTimePickerMenu"
                            (menuClosed)="saveReport()">
                            {{ report.remoteSignatureConfig.signatureDeadlineAt | date: "HH:mm 'Uhr'" }}
                        </span>
                    </div>

                    <!-- Deadline expired warning -->
                    <div class="deadline-badge-expired-warning" *ngIf="hasSignatureDeadlinePassed()">
                        Abgelaufen.
                        <a class="link" (click)="setDeadlineToSettingsDate()">Verlängern?</a>
                    </div>
                </div>

                <!-- Deadline badge time picker -->
                <mat-menu
                    #deadlineTimePickerMenu="matMenu"
                    class="deadline-time-picker-menu mat-menu-no-min-height mat-menu-content-no-padding">
                    <div
                        class="deadline-time-picker-container"
                        (click)="$event.preventDefault(); $event.stopPropagation()">
                        <time-input
                            #deadlineTimeInput
                            class="deadline-time-input"
                            [time]="signatureDeadlineTime"
                            (timeChange)="setSignatureDeadlineTime($event)"
                            (elementCreated)="deadlineTimeInput.focus()"
                            [disabled]="isReportLocked(report)"></time-input>
                    </div>
                </mat-menu>

                <!-- Deadline badge divider -->
                <div class="deadline-badge-divider"></div>

                <!-- Deadline badge settings icon -->
                <mat-icon
                    [matMenuTriggerFor]="deadlineSettingsMenu"
                    [matTooltip]="'Einstellungen'"
                    (menuClosed)="setDeadlineToSettingsDate()"
                    class="deadline-badge-settings-icon toolbar-icon">
                    settings
                </mat-icon>

                <!-- Deadline badge settings menu -->
                <mat-menu #deadlineSettingsMenu="matMenu" class="deadline-settings-menu mat-menu-content-no-padding">
                    <div (click)="$event.preventDefault(); $event.stopPropagation()">
                        <!-- Title -->
                        <h3 class="deadline-settings-headline">Unterschriftsfrist konfigurieren</h3>

                        <div class="deadline-settings-offset-container">
                            <!-- Deadline offset amount -->
                            <mat-form-field class="deadline-offset-amount">
                                <input
                                    matInput
                                    type="number"
                                    placeholder="Dauer"
                                    [value]="userPreferences.remoteSignatureDeadlineOffsetAmount"
                                    (blur)="setDeadlineOffsetAmount($event)"
                                    [disabled]="isReportLocked(report)" />
                            </mat-form-field>

                            <!-- Deadline offset unit -->
                            <div class="deadline-offset-unit">
                                <!-- Business days -->
                                <div
                                    [ngClass]="{
                                        'selection-bar-item': true,
                                        selected: userPreferences.remoteSignatureDeadlineOffsetUnit === 'businessDays',
                                    }"
                                    (click)="userPreferences.remoteSignatureDeadlineOffsetUnit = 'businessDays'">
                                    Werktage (Mo-Fr)
                                </div>

                                <!-- Calendar days -->
                                <div
                                    [ngClass]="{
                                        'selection-bar-item': true,
                                        selected: userPreferences.remoteSignatureDeadlineOffsetUnit === 'calendarDays',
                                    }"
                                    (click)="userPreferences.remoteSignatureDeadlineOffsetUnit = 'calendarDays'">
                                    Kalendertage
                                </div>

                                <!-- Hours -->
                                <div
                                    [ngClass]="{
                                        'selection-bar-item': true,
                                        selected: userPreferences.remoteSignatureDeadlineOffsetUnit === 'hours',
                                    }"
                                    (click)="userPreferences.remoteSignatureDeadlineOffsetUnit = 'hours'">
                                    Stunden
                                </div>
                            </div>
                        </div>

                        <!-- Deadline menu footer-->
                        <div class="deadline-settings-footer">
                            <div>Frist:</div>
                            <div>{{ getDeadlineSettingsDate() | date: "EEEE, dd.MM.yyyy - HH:mm 'Uhr'" }}</div>
                        </div>
                    </div>
                </mat-menu>
            </div>

            <!-- Link badge -->
            <div class="link-badge">
                <!-- Link badge link icon -->
                <mat-icon class="link-badge-link-icon">link</mat-icon>

                <!-- Link badge headline -->
                <div class="link-badge-headline">
                    <a class="link" [href]="remoteSignatureLink" target="_blank">Kunden-Link</a>
                </div>

                <!-- Link badge divider -->
                <div class="link-badge-divider"></div>

                <div class="link-badge-icon-row">
                    <!-- Link badge open link icon -->
                    <!-- 
                    <a [href]="remoteSignatureLink" target="_blank">
                        <mat-icon
                            class="link-badge-open-link-icon toolbar-icon"
                            [matTooltip]="'Link in neuem Tab öffnen'">
                            open_in_new
                        </mat-icon>
                    </a>
                    -->

                    <!-- Link badge copy link icon -->
                    <mat-icon
                        class="link-badge-copy-link-icon toolbar-icon"
                        [matTooltip]="'Link kopieren, um ihn zum Beispiel per WhatsApp zu verschicken.'"
                        (click)="copyLinkToClipboard()">
                        content_copy
                    </mat-icon>

                    <!-- Link badge link settings icon -->
                    <mat-icon class="link-badge-settings-icon toolbar-icon" [matMenuTriggerFor]="linkSettingsMenu">
                        more_vert
                    </mat-icon>

                    <!-- Link badge link settings menu -->
                    <mat-menu #linkSettingsMenu="matMenu">
                        <!-- Generate new link -->
                        <a
                            mat-menu-item
                            [matTooltip]="
                                'Neuen Link generieren, falls der Anspruchsteller nochmal neu unterschreiben möchte. Der alte Link wird ungültig.'
                            "
                            (click)="promptResetAuthenticationToken()">
                            <mat-icon class="small-icon">refresh</mat-icon>
                            Neuen Link generieren
                        </a>
                    </mat-menu>
                </div>
            </div>
        </div>

        <!-- Message history container -->
        <div
            class="message-history-container"
            *ngIf="outgoingMessages?.length && !isComposingNewMessage && !emailJustSentInfo">
            <!-- Message history heading -->
            <h3>Versendet</h3>

            <!-- Message history rows -->
            <div class="message-history-line-top"></div>
            <outgoing-messages-list
                [outgoingMessages]="outgoingMessages"
                (editAsNew)="editAsNew($event)"></outgoing-messages-list>

            <!-- New message button -->
            <button class="flat new-message-button" (click)="isComposingNewMessage = true">+ Neue Nachricht</button>
        </div>

        <!-- Compose new message-->
        <div
            class="compose-message-container"
            *ngIf="email && !emailJustSentInfo && (!outgoingMessages?.length || isComposingNewMessage)">
            <email-editor
                [recipient]="selectedRecipient"
                (recipientChange)="saveReport()"
                [email]="email"
                (emailChange)="saveReport()"
                (documentChange)="saveReport()"
                [report]="report"
                [activeDocuments]="[]"
                (emailSend)="sendEmail($event)"
                [emailTransmissionPending]="emailTransmissionPending"
                [emailJustSentInfo]="emailJustSentInfo"
                [templateType]="'remoteSignatureEmail'"
                [selectedDocumentGroup]="'report'"
                [ccAndBccOptIn]="true"
                [showHeadline]="false"
                [showCardFrame]="false"
                [showAttachments]="false"></email-editor>
        </div>

        <div id="email-just-sent-container" *ngIf="emailJustSentInfo" @fadeIn>
            <mat-icon id="email-just-sent-icon">{{ emailJustSentInfo.icon }}</mat-icon>
            <span>{{ emailJustSentInfo.label }}</span>
        </div>
    </div>
</div>

<message-template-selector
    *ngIf="templateSelectorShown"
    [report]="report"
    [recipientRole]="selectedRecipient.role"
    templateType="remoteSignatureEmail"
    (close)="hideMessageTemplateSelector()"
    (templateSelection)="insertTemplateText($event)"
    @runChildAnimations></message-template-selector>

import { Invoice } from '../../models/invoices/invoice';
import { Payment } from '../../models/invoices/payment';
import { reduceByProperty } from '../arrays/reduce-by-property';
import { round } from '../numbers/round';

/**
 * Returns the sum of all written-off short payments (the assessor decided he won't ever get the short payments).
 */
export function getOutstandingShortPaymentsAmount(invoice: Pick<Invoice, 'payments'>): number {
    const outstandingShortPayments: Payment[] = invoice.payments.filter(
        (payment) => payment.type === 'shortPayment' && payment.shortPaymentStatus === 'outstandingClaim',
    );
    const outstandingAmount: number = reduceByProperty(outstandingShortPayments, 'amount');
    return round(outstandingAmount);
}

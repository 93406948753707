import { IsoDate } from '../../lib/date/iso-date.types';
import { generateId } from '../../lib/generate-id';
import { SignableDocumentType } from '../documents/document-metadata';

export class ClaimantSignature {
    constructor(template: Partial<ClaimantSignature> = {}) {
        Object.assign(this, template);
    }

    _id: string = generateId();
    documentType: SignableDocumentType;
    customDocumentOrderConfigId: string;
    date: IsoDate;
    /**
     * The hash of the signature image.
     * Null until the signature has been uploaded.
     * A new hash causes the template engine to re-render the document.
     */
    hash: string;
    /**
     * There may be multiple customer signatures in a PDF-based customer signature document. That's why each signature must be assigned to a "slot"
     * in those documents.
     */
    signatureSlotId?: string;
}

import { Pipe, PipeTransform } from '@angular/core';
import moment from 'moment';
import { IsoDate } from '../lib/date/iso-date.types';

/**
 * Format the date as a relative date string.
 */
@Pipe({
    name: 'compareTaskWithToday',
    pure: false,
})
export class CompareTaskWithToday implements PipeTransform {
    transform(task: { dueDate: IsoDate | Date; dueTime: string }): 'today' | 'future' | 'past' | '' {
        if (!task?.dueDate) {
            return '';
        }

        const today = moment();

        if (task.dueTime) {
            const givenDate = moment(task.dueDate + 'T' + task.dueTime);

            if (today.isAfter(givenDate)) {
                return 'past';
            }

            if (today.isSame(givenDate, 'day')) {
                return 'today';
            }

            return 'future';
        }

        const givenDate = moment(task.dueDate);

        if (today.isSame(givenDate, 'day')) {
            return 'today';
        }

        if (today.isBefore(givenDate, 'day')) {
            return 'future';
        }

        return 'past';
    }
}

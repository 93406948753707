import { DateTime } from 'luxon';
import { BadRequest } from '../../models/errors/ax-error';
import { Invoice } from '../../models/invoices/invoice';
import { Payment } from '../../models/invoices/payment';
import { Team } from '../../models/teams/team';
import { User } from '../../models/user/user';
import { sortByProperty } from '../arrays/sort-by-property';
import { makeLuxonDateTime, toGermanDate } from '../ax-luxon';
import { getPaidAmount } from '../invoices/get-paid-amount';
import { getShortPaidAmount } from '../invoices/get-short-paid-amount';
import { getUnpaidAmount } from '../invoices/get-unpaid-amount';
import { concatReportCategories } from '../report/concat-report-categories';
import { convertToCurrencyString } from './convert-to-currency-string';
import { convertToPercent } from './convert-to-percent';
import { formatNumberToGermanLocale } from './format-number-to-german-locale';
import { getContactPersonPlaceholderObject } from './get-contact-person-placeholder-object';
import { getPaymentPlaceholderValuesGerman } from './get-payment-placeholder-values-german';
import { getUserPlaceholderObject } from './get-user-placeholder-object';
import { InvoicePositionGerman, PlaceholderValuesInvoice } from './placeholder-values.types';
import { Translator } from './translator';

/**
 * Returns the placeholder values concerning invoice-related data.
 *
 * This is used by the invoice DOCX generation service and by the template
 * placeholder value service that delivers the entire placeholder tree to the frontend.
 */
export async function getTemplatePlaceholderValuesInvoice({
    invoice,
    canceledInvoice,
    user,
    team,
}: {
    invoice: Invoice;
    canceledInvoice?: Invoice;
    user: User;
    team: Team;
}): Promise<PlaceholderValuesInvoice> {
    //*****************************************************************************
    //  Parameter Validation
    //****************************************************************************/
    if (!user) {
        throw new BadRequest({
            code: 'MISSING_USER',
            message:
                "Defining placeholders does not work without the user parameter. Please make sure you are logged in and don't do any forbidden stuff.",
        });
    }

    if (!team) {
        throw new BadRequest({
            code: 'MISSING_TEAM',
            message: 'Defining placeholders does not work without the team.',
        });
    }
    /////////////////////////////////////////////////////////////////////////////*/
    //  END Parameter Validation
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Payments
    //****************************************************************************/
    const paidAmount: number = getPaidAmount(invoice);
    const shortPaidAmount: number = getShortPaidAmount(invoice);
    const unpaidAmount: number = getUnpaidAmount(invoice);
    /////////////////////////////////////////////////////////////////////////////*/
    //  END Payments
    /////////////////////////////////////////////////////////////////////////////*/
    const invoiceDate: string = toGermanDate(invoice.date || DateTime.now());
    const dateOfSupply: string = toGermanDate(invoice.dateOfSupply || invoice.date || DateTime.now());
    const dueDate: string = toGermanDate(invoice.dueDate || computeDueDate(invoice) || DateTime.now());

    const paymentsByDate: Payment[] = [...(invoice.payments || [])].sort(sortByProperty('date'));
    const firstPayment: Payment = paymentsByDate.at(0);
    const lastPayment: Payment = paymentsByDate.at(-1);

    let collectiveInvoiceCustomLineItemsTitle: string;
    if (invoice.isCollectiveInvoice) {
        collectiveInvoiceCustomLineItemsTitle =
            invoice.collectiveInvoiceCustomInvoiceLineItemTitle ?? concatReportCategories(invoice.reportsData);
    }

    //*****************************************************************************
    //  Define Placeholders & Replacements
    //****************************************************************************/
    return {
        Rechnung: {
            // TODO Add the sender to the invoice object. If there are multiple users in one account, re-generating an invoice might cause a different document to be printed.
            Absender: getUserPlaceholderObject({
                user: user,
                team,
                officeLocationId: invoice.officeLocationId,
            }),
            Empfänger: {
                ...getContactPersonPlaceholderObject(invoice.recipient.contactPerson),
                Typ: Translator.invoiceInvolvedPartyType(invoice.recipient.role),
            },
            Nummer: invoice.number || `[keine Nummer]`,
            Einleitung: invoice.intro || '',
            // SL 2018-10-11: Both this flag or the property "Gutachten" currently need to be truthy for the invoice to print the report summary. In the future, the client
            // will create a regular invoice for each report instead of the server. Then, each invoice object needs to know itself whether it belongs to a report.
            // Side note: Both the report and the repair confirmation have a link to the report through invoice.reportIds, so that field cannot be used.
            GutachtenzusammenfassungDrucken: invoice.printReportSummary,
            Datum: invoiceDate,
            Leistungsdatum: dateOfSupply,
            istLeistungsdatumGleichRechnungsdatum: invoiceDate === dateOfSupply,
            BeginnLeistungszeitraum: invoice.supplyPeriodStart ? toGermanDate(invoice.supplyPeriodStart) : null,
            istSammelrechnung: invoice.isCollectiveInvoice,
            SammelrechnungZusammenfassungAlsAnhang: team.preferences.collectiveInvoiceSummaryAppendix,
            SammelrechnungTitelRechnungsposition: collectiveInvoiceCustomLineItemsTitle,
            // The due date usually does not exist in previews for expert statement invoices or repair confirmation invoices.
            // CreditNotes do not have a due date
            fälligAm: invoice.type === 'invoice' ? dueDate : '',
            // CreditNotes do have the daysUntilDue to be able to recalculate the due date if a user converts a credit note to an invoice by changing the total net amount
            Zahlungsziel: invoice.daysUntilDue == null ? 14 : invoice.daysUntilDue,
            Einheit: invoice.currencyCode,
            Positionen: invoice.lineItems
                // Show only active line items. All line items are active whose checkbox is enabled in the frontend.
                .filter((lineItem) => lineItem.active === true)
                .map((lineItem, index): InvoicePositionGerman => {
                    /**
                     * Since browsers interpreting "<p>Test<br></p>" do not display a break after the "Test" in the paragraph because line breaks before
                     * closing </p> tags are ignored (See https://stackoverflow.com/a/62523690/1027464 why that is by design), we need to enter two
                     * line breaks for the user to continue typing on a new line if the user hits Shift+Enter at the end of a paragraph via Quill in the frontend.
                     *
                     * Since Microsoft Word displays line-breaks in front of the closing paragraph tag, this statement removes those line-breaks which were invisible in HTML.
                     */
                    const description = lineItem.description?.replace(/<br class="ql-line-break"><\/p>/g, '</p>');

                    return {
                        // Index is 0-based, positions here should be 1-based
                        Nummer: index + 1,
                        Beschreibung: description,
                        /**
                         * "1" remains "1"
                         * "1.5" becomes "1,50".
                         * "6.99999999999" becomes "7" (JavaScript number precision issue)
                         */
                        Menge: lineItem.quantity?.toString()?.includes('.')
                            ? formatNumberToGermanLocale(lineItem.quantity, { omitDecimalsIfZero: true })
                            : `${lineItem.quantity || ''}`,
                        Einheit: lineItem.unit || '',
                        Einzelpreis: convertToCurrencyString(lineItem.unitPrice, invoice.currencyCode),
                        Gesamtpreis: convertToCurrencyString(
                            lineItem.unitPrice * lineItem.quantity,
                            invoice.currencyCode,
                        ),
                    };
                }),
            // Outside of Germany but within the EU
            istEuLieferung: invoice.euDelivery,
            istERechnung: invoice.isElectronicInvoiceEnabled,
            GesamtNetto: convertToCurrencyString(invoice.totalNet, invoice.currencyCode),
            MwSt: convertToCurrencyString(invoice.totalGross - invoice.totalNet, invoice.currencyCode),
            MwStProzentsatz: convertToPercent(invoice.vatRate),
            GrundFürNullProzentMwSt: invoice.vatExemptionReason
                ? Translator.vatExemptionReason(invoice.vatExemptionReason)
                : invoice.vatExemptionReason,
            GesamtBrutto: convertToCurrencyString(invoice.totalGross, invoice.currencyCode),
            bezahlterBetrag: convertToCurrencyString(paidAmount, invoice.currencyCode),
            gekürzterBetrag: convertToCurrencyString(shortPaidAmount, invoice.currencyCode),
            offenerBetrag: convertToCurrencyString(unpaidAmount, invoice.currencyCode),
            ersteZahlung: getPaymentPlaceholderValuesGerman(firstPayment),
            letzteZahlung: getPaymentPlaceholderValuesGerman(lastPayment),
            istStornoRechnung: invoice.isFullCancellationInvoice,
            stornierteRechnung: canceledInvoice
                ? {
                      Nummer: canceledInvoice.number || '',
                      Datum: canceledInvoice.date ? toGermanDate(makeLuxonDateTime(canceledInvoice.date)) : '',
                  }
                : null,
        },
    };
    //*****************************************************************************
    //  Define Placeholders & Replacements
    //****************************************************************************/
}

export function computeDueDate(invoice: Invoice): DateTime {
    return makeLuxonDateTime(invoice.date || DateTime.now()).plus({ days: invoice.daysUntilDue });
}

import { Overlay, OverlayRef } from '@angular/cdk/overlay';
import { ComponentPortal } from '@angular/cdk/portal';
import { Directive, ElementRef, EventEmitter, HostListener, Injector, Input, Output } from '@angular/core';
import { Subject } from 'rxjs';
import { PANEL_CLOSE_SUBJECT_TOKEN } from '@autoixpert/injection-tokens/common-overlay-injection-tokens';
import { TextElement } from '@autoixpert/models/signable-documents/signable-pdf-template-config';
import {
    PdfTextElementInputPanelComponent,
    TEXT_ELEMENT_CHANGE_SUBJECT_TOKEN,
    TEXT_ELEMENT_TOKEN,
} from './pdf-text-element-input-panel.component';

/**
 * If the user double-clicks the host element, open an overlay with a textarea
 * to edit the text content of the PDF text element.
 */
@Directive({
    selector: '[pdfTextElementInputAnchor]',
    exportAs: 'pdfTextElementInputAnchor',
})
export class PdfTextElementInputAnchorDirective {
    constructor(
        private elementRef: ElementRef,
        private overlayService: Overlay,
        private injector: Injector,
    ) {}

    @Input() textElement: TextElement;
    @Input() readonly: boolean;

    @Output() change: EventEmitter<TextElement[]> = new EventEmitter<TextElement[]>();
    @Output() panelOpen: EventEmitter<void> = new EventEmitter();
    @Output() panelClose: EventEmitter<void> = new EventEmitter();

    private overlayRef: OverlayRef;

    //*****************************************************************************
    //  Anchor Double-Click Handler
    //****************************************************************************/
    @HostListener('dblclick', ['$event'])
    public openTextEditPanel() {
        // Avoid duplicate panels.
        if (this.overlayRef) return;

        if (this.readonly) return;

        // Configure overlay
        this.overlayRef = this.overlayService.create({
            hasBackdrop: true,
            backdropClass: 'panel-transparent-backdrop',
            positionStrategy: this.overlayService
                .position()
                .flexibleConnectedTo(this.elementRef)
                .withPositions([
                    {
                        originX: 'center',
                        originY: 'bottom',
                        overlayX: 'center',
                        overlayY: 'top',
                    },
                ])
                .withPush(true)
                .withViewportMargin(10),
            scrollStrategy: this.overlayService.scrollStrategies.noop(),
            // Make the same width as the host
            width: Math.max(this.elementRef.nativeElement.clientWidth, 500),
        });

        // Close panel when clicking the backdrop.
        this.overlayRef.detachments().subscribe(() => {
            this.overlayRef = null;
        });

        /**
         * We must hear from the overlay when the text changes to trigger saving the record
         * within the parent of this anchor directive.
         */
        const changeListener = new Subject<TextElement[]>();
        changeListener.subscribe((textElements) => this.change.emit(textElements));

        /**
         * Also listen for when the user clicks the close icon within the overlay.
         */
        const panelCloseListener = new Subject<void>();
        panelCloseListener.subscribe(() => this.panelClose.emit());

        //*****************************************************************************
        //  Injector
        //****************************************************************************/
        const injector = Injector.create({
            parent: this.injector,
            providers: [
                {
                    provide: TEXT_ELEMENT_CHANGE_SUBJECT_TOKEN,
                    useValue: changeListener,
                },
                {
                    provide: PANEL_CLOSE_SUBJECT_TOKEN,
                    useValue: panelCloseListener,
                },
                {
                    provide: TEXT_ELEMENT_TOKEN,
                    useValue: this.textElement,
                },
                {
                    provide: OverlayRef,
                    useValue: this.overlayRef,
                },
            ],
        });
        /////////////////////////////////////////////////////////////////////////////*/
        //  END Injector
        /////////////////////////////////////////////////////////////////////////////*/

        //*****************************************************************************
        //  Component Portal
        //****************************************************************************/
        // Instantiate the portal component.
        const componentPortal = new ComponentPortal<PdfTextElementInputPanelComponent>(
            PdfTextElementInputPanelComponent,
            null,
            injector,
        );
        /////////////////////////////////////////////////////////////////////////////*/
        //  END Component Portal
        /////////////////////////////////////////////////////////////////////////////*/

        //*****************************************************************************
        //  Attach Component to Portal Outlet
        //****************************************************************************/
        this.overlayRef.attach(componentPortal);
        /////////////////////////////////////////////////////////////////////////////*/
        //  END Attach Component to Portal Outlet
        /////////////////////////////////////////////////////////////////////////////*/

        this.panelOpen.emit();
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Anchor Double-Click Handler
    /////////////////////////////////////////////////////////////////////////////*/
}

import { Injectable } from '@angular/core';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { Observable, from, of } from 'rxjs';
import { map, mapTo, switchMap } from 'rxjs/operators';
import { ConfirmDialogComponent } from '@autoixpert/components/confirm-dialog/confirm-dialog.component';
import { PhotosComponent } from './photos/photos.component';
import { ReportDetailsComponent } from './report-details.component';

@Injectable()
export class ReportDetailsGuard {
    constructor(public dialog: MatDialog) {}

    canDeactivate(component: PhotosComponent): Observable<boolean> | boolean {
        return from(component.canDeactivate()).pipe(
            switchMap((canDeactivate) => {
                if (canDeactivate) {
                    return of(true);
                }

                const dialogRef = this.dialog.open(ConfirmDialogComponent, {
                    data: {
                        heading: 'Operationen ausstehend',
                        content:
                            'Es laufen noch Operationen (z. B. Kennzeichen abdecken), die beim Verlassen der Maske abgebrochen werden. Möchtest du trotzdem fortfahren?',
                        confirmLabel: 'Ja, abbrechen',
                        cancelLabel: "Ich überleg's mir nochmal.",
                        confirmColorRed: true,
                    },
                });

                return dialogRef.afterClosed().pipe(
                    switchMap((result) => {
                        if (result) {
                            return from(component.forceDeactivate()).pipe(mapTo(true));
                        } else {
                            return of(false);
                        }
                    }),
                );
            }),
        );
    }
}

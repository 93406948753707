import { Component, EventEmitter, HostListener, Input, Output } from '@angular/core';
import { AccountStatus } from '@autoixpert/models/teams/account-status';
import { Team } from '@autoixpert/models/teams/team';
import { AxLicenseService } from 'src/app/shared/services/ax-license.service';

@Component({
    selector: 'order-audatex-addon-dialog',
    templateUrl: './order-audatex-addon-dialog.component.html',
    styleUrls: ['./order-audatex-addon-dialog.component.scss'],
})
export class OrderAudatexAddonDialogComponent {
    constructor(private axLicenseService: AxLicenseService) {}

    private accountStatus: AccountStatus;
    public userCount: number;

    @Input() team: Team;
    @Output() placeOrder: EventEmitter<void> = new EventEmitter();
    @Output() close: EventEmitter<void> = new EventEmitter();

    public PRICE_PER_TEAM = 30;
    public PRICE_PER_USER = 10;

    ngOnInit() {
        this.accountStatus = this.team.accountStatus;
        this.fetchUserCount();
    }

    private async fetchUserCount() {
        this.userCount = await this.axLicenseService.getCurrentNumberOfPaidAdditionalUsers(this.team);
    }

    /**
     * Emit event to order Audatex Addon.
     */
    public orderAudatexAddon() {
        this.placeOrder.emit();
    }

    public isTestOrPartnerAccount(): boolean {
        return this.accountStatus === 'test' || this.accountStatus === 'partner';
    }

    public handleOverlayClick(event: MouseEvent): void {
        if (event.target === event.currentTarget) {
            this.emitCloseEvent();
        }
    }

    //*****************************************************************************
    //  Events
    //****************************************************************************/
    public emitCloseEvent(): void {
        this.close.emit();
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Events
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Keyboard Shortcuts
    //****************************************************************************/
    @HostListener('window:keydown', ['$event'])
    public handleKeyboardShortcuts(event: KeyboardEvent) {
        switch (event.key) {
            case 'Escape':
                this.emitCloseEvent();
                break;
            case 'Enter':
                this.orderAudatexAddon();
        }
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Keyboard Shortcuts
    /////////////////////////////////////////////////////////////////////////////*/
}

import { InvolvedParty } from './involved-party';

export class AuthorOfDamage extends InvolvedParty {
    constructor(template: Partial<AuthorOfDamage> = {}) {
        super('authorOfDamage');
        if (template) {
            Object.assign(this, template);
        }
    }

    isOwner: boolean = true; // Often, the author of damage may also be the owner of the car
    insuranceNumber: string; // The insurance number of the author of damage's insurance. The accident case number is located at insurance.caseNumber.
    licensePlate: string;
}

import { ApiErrorHandler, ApiErrorHandlerCreator } from '../../services/api-error.service';
import { getDekraErrorHandlers } from './get-dekra-error-handlers';
import { getRepairCalculationErrorHandlers } from './get-repair-calculation-error-handlers';

export function getDatErrorHandlers(): { [key: string]: ApiErrorHandler | ApiErrorHandlerCreator } {
    return {
        ...getRepairCalculationErrorHandlers(),
        ...getDekraErrorHandlers(),
        //*****************************************************************************
        //  General
        //****************************************************************************/
        UNKNOWN_DAT_ERROR: (error) => ({
            title: 'Unbekannter DAT-Fehler',
            body:
                `Ein unbekannter Fehler ist in der DAT-Schnittstelle aufgetreten.` +
                (error.data?.datErrorMessage ? `<br><br>Fehlermeldung der DAT: ${error.data.datErrorMessage}` : ''),
        }),
        DAT_INTERNAL_SERVER_ERROR: (error) => ({
            title: 'DAT-interner Fehler',
            body:
                `Die DAT konnte die Anfrage nicht verarbeiten. Starte die Anfrage in ein paar Sekunden erneut.` +
                (error.data?.datErrorMessage ? `<br><br>Fehlermeldung der DAT: ${error.data.datErrorMessage}` : ''),
            partnerLogo: 'dat',
        }),
        INVALID_ADDRESS: (err) => ({
            title: 'Ungültige Straße',
            body: `Die DAT erfordert eine gültige Straße oder keine. Aktuelle Straße des Anspruchstellers: "${err.data.invalidAddress}"`,
        }),
        MICROSERVICE_NOT_RUNNING: {
            title: 'DAT-Server nicht erreichbar',
            body: 'Versuche es am besten später noch einmal oder melde dich bei der DAT.\n\nTritt der Fehler dauerhaft auf, probiere die VIN direkt in der DAT-Oberfläche abzufragen.',
            partnerLogo: 'dat',
        },
        EXTERNAL_SERVICE_UNAVAILABLE_FOR_MULTIPLE_REQUESTS_IN_A_SHORT_PERIOD: {
            title: 'DAT hat Server-Probleme',
            body: 'Die DAT konnte in den letzten Minuten mehrere Server-Anfragen nicht beantworten und hat wahrscheinlich Server-Probleme. Kontaktiere die DAT-Hotline.',
            partnerLogo: 'dat',
        },
        DAT_DOSSIER_ID_MISSING: {
            title: 'DAT-Vorgangs-ID fehlt',
            body: 'Bitte versuche es erneut. Bleibt das Problem bestehen, trenne den DAT-Vorgang und verknüpfe ihn neu.',
        },
        DAT_CONTRACT_NOT_FOUND: {
            title: 'Vorgang nicht gefunden',
            body: 'Die DAT konnte den Vorgang nicht finden. Bitte die Schadenskalkulation / Bewertung zurücksetzen und neu anlegen.',
            partnerLogo: 'dat',
        },
        /////////////////////////////////////////////////////////////////////////////*/
        //  END General
        /////////////////////////////////////////////////////////////////////////////*/

        //*****************************************************************************
        //  Authentication/Authorization
        //****************************************************************************/
        INCOMPLETE_DAT_USER: {
            title: 'Zugangsdaten unvollständig',
            body: 'Bitte vervollständige deine DAT-Zugangsdaten in den <a href="Einstellungen#calculation-providers-section">Einstellungen</a>.',
        },
        NO_DAT_USER: {
            title: 'DAT-Nutzer-Objekt fehlt',
            body: 'Das ist ein technisches Problem. Bitte kontaktiere die <a href="/Hilfe" target="_blank">Hotline</a>.',
        },
        DAT_UNAUTHORIZED: {
            title: 'Zugangsdaten ungültig',
            body: 'Bitte überprüfe deine DAT-Zugangsdaten in den <a href="Einstellungen#calculation-providers-section">Einstellungen</a>.<br><br>Ob deine DAT-Zugangsdaten aktuell sind, kannst du auf <a href="https://www.dat.de" target="_blank" rel=”noopener”>DAT.de</a> testen.',
        },
        CREATING_DAT_AUTHENTICATION_TOKEN_FAILED: {
            title: 'DAT-Login fehlgeschlagen',
            body: 'Der DAT-Token zur Authentifizierung konnte nicht generiert werden. Das ist vermutlich ein Problem seitens der DAT. Bitte kontaktiere die <a href="/Hilfe" target="_blank">Hotline</a>.',
            partnerLogo: 'dat',
        },
        DAT_TEST_ACCOUNT_EXPIRED: {
            title: 'DAT-Test-Account ausgelaufen',
            body: 'Dein DAT-Test-Account ist abgelaufen. Bitte verwende deine eigenen Zugangsdaten.',
        },
        // This is required in the preferences screen in case the user entered his DAT credentials but is missing the VIN agreement.
        DAT_VIN_SERVICE_UNLICENSED: {
            title: 'Fehlende VIN-Zusatzvereinbarung',
            body: `Zur Nutzung der VIN-Abfrage benötigst du eine bestimmte DAT-Einstellung, die dir noch nicht zugeordnet wurde (Stichwort "VIN-Zusatzvereinbarung"). Wende dich dazu bitte an deinen DAT-Ansprechpartner oder den DAT-Vertrieb unter der Tel: 0711/4503-140 oder via E-Mail an <a href="mailto:vertrieb@dat.de" target="_blank" rel="noopener">vertrieb@dat.de</a>.`,
        },
        DAT_VALUATION_SERVICE_UNLICENSED: {
            title: 'Fehlende Bewertungs-Lizenz',
            body: `Zur Nutzung der DAT-Bewertung benötigst du eine separate Lizenz von der DAT. Wende dich dazu bitte an deinen DAT-Ansprechpartner oder den DAT-Vertrieb unter der Tel: 0711/4503-140 oder via E-Mail an <a href="mailto:vertrieb@dat.de" target="_blank" rel=”noopener”>vertrieb@dat.de</a>.`,
        },
        NOT_AVAILABLE_FOR_DAT_TEST_ACCOUNT: {
            title: 'Bitte eigenen DAT-Account eintragen',
            body: "Diese Funktion ist verfügbar, sobald du deinen eigenen DAT-Account in den <a href='Einstellungen#calculation-providers-section'>Einstellungen</a> hinterlegt hast.",
        },
        /////////////////////////////////////////////////////////////////////////////*/
        //  END Authentication/Authorization
        /////////////////////////////////////////////////////////////////////////////*/

        DAT_DOSSIER_NOT_FOUND: {
            title: 'DAT-Vorgang fehlt',
            body: 'Die DAT konnte den Vorgang nicht finden. Bitte den DAT-Vorgang löschen und neu anlegen.',
        },

        //*****************************************************************************
        //  DAT€Code
        //****************************************************************************/
        DAT_E_CODE_OR_VIN_REQUIRED: {
            title: 'DAT€Code oder VIN benötigt',
            body: 'Bitte gib einen DAT€Code oder eine VIN in der Fahrzeugauswahl an.',
        },
        DAT_E_CODE_LENGTH_INVALID: {
            title: 'DAT€Code oder Marktindex ungültig',
            body: 'Bitte prüfe die Länge des DAT€Codes und des Marktindexes. Den Marktindex kannst du über die manuelle Fahrzeugauswahl beim DAT€Code-Feld eingeben.',
        },
        DAT_E_CODE_INVALID: {
            title: 'DAT€Code oder Marktindex ungültig',
            body: 'Bitte prüfe den DAT€Code und den Marktindex. Den Marktindex kannst du über die manuelle Fahrzeugauswahl beim DAT€Code-Feld eingeben.',
        },
        DAT_E_CODE_CONTAINER_INVALID: {
            title: 'Marktindex ungültig',
            body: 'Bitte prüfe den Marktindex des DAT€Codes. Den Marktindex kannst du über die manuelle Fahrzeugauswahl beim DAT€Code-Feld eingeben.',
        },
        DAT_E_CODE_NO_SPECIAL_CHARACTERS: (error) => ({
            title: 'DAT€Code enthält ungültige Zeichen',
            body: `Bitte prüfe den DAT€Code auf ungültige Zeichen oder Leerzeichen.<br>DAT€Code: ${error.data.datEuropaCode}`,
        }),
        DAT_E_CODE_INVALID_OPTIONS: {
            title: 'Ungültige DAT€Code-Parameter',
            body: 'Der DAT€Code konnte mit den gewählten Optionen nicht erstellt werden. Bitte wähle andere Fahrzeugmerkmale.',
        },
        DAT_BASE_TYPE_CODE_NOT_FOUND: {
            title: 'DAT-Haupttyp nicht gefunden',
            body: 'Die DAT kann den Haupttyp nicht finden. Bitte wähle einen anderen Haupttyp.',
            partnerLogo: 'dat',
        },
        DAT_E_CODE_NOT_FOUND_FOR_THIS_CONSTRUCTION_TIME: {
            title: 'DAT€Code nicht gefunden',
            body: 'Die DAT hat keine Daten für das Fahrzeug mit dem angegebenen Bauzeitraum. Ohne DAT€Code kann die DAT keine verfügbaren Ausstattungen anzeigen.',
            partnerLogo: 'dat',
        },
        /////////////////////////////////////////////////////////////////////////////*/
        //  END DAT€Code
        /////////////////////////////////////////////////////////////////////////////*/

        //*****************************************************************************
        //  VIN
        //****************************************************************************/
        VIN_INVALID: {
            title: 'VIN  ungültig',
            body: 'Bitte prüfe die VIN im Reiter Fahrzeugauswahl.',
        },
        VIN_LENGTH_INVALID: (error) => ({
            title: 'Länge der VIN ungültig',
            body: `Bitte prüfe die Länge der VIN (${(error.data.vin || '').length} Zeichen).`,
        }),
        VIN_MISSING_DAT_E_CODE: {
            title: 'DAT€Code in der VIN fehlt',
            body: 'Die DAT findet zwar Daten auf den Hersteller-Servern, aber nicht in ihrem eigenen Datenbestand. Bitte wähle ein vergleichbares Fahrzeug in der DAT-Oberfläche (Phantom-Bewertung oder Phantom-Kalkulation).',
        },
        VIN_MISSING_CAR_DATA: {
            title: 'DAT hat keine Daten',
            body: 'Leider hat die DAT keine Daten zu diesem Fahrzeug.',
            partnerLogo: 'dat',
        },
        /////////////////////////////////////////////////////////////////////////////*/
        //  END VIN
        /////////////////////////////////////////////////////////////////////////////*/

        //*****************************************************************************
        //  KBA Code
        //****************************************************************************/
        INVALID_KBA_CODE_FORMAT: (error) => ({
            title: 'Ungültiger KBA-Code',
            body:
                `Der KBA-Code hat ein ungültiges Format.` +
                (error.data?.datErrorMessage ? `<br><br>Fehlermeldung der DAT: ${error.data.datErrorMessage}` : ''),
        }),
        /////////////////////////////////////////////////////////////////////////////*/
        //  END KBA Code
        /////////////////////////////////////////////////////////////////////////////*/

        //*****************************************************************************
        //  Calculation
        //****************************************************************************/
        UNFINISHED_CALCULATION: {
            title: 'Kalkulation unfertig',
            body: 'Bitte die Kalkulation abschließen, um die Kalkulationsergebnisse zu importieren.',
        },
        DAT_DAMAGE_CALCULATION_DOCUMENT_EMPTY: {
            title: 'Kalkulationsdokument fehlt',
            body: 'Die DAT hat kein Kalkulationsdokument übermittelt. Bei einem erneuten Import löst sich das Problem normalerweise von selbst.',
        },
        FORMATTING_TIRE_DIMENSION_FAILED: {
            title: 'Reifendimension ungültig',
            body: 'Bitte überprüfe, ob die Dimensionen aller Reifen vollständig und korrekt formatiert sind.',
        },
        DAT_DAMAGE_CALCULATION_DOCX_FILE_MISSING: {
            title: 'DOCX-Datei fehlt',
            body: 'Die Microsoft-Word-Datei der DAT-Kalkulation fehlte in der DAT-Antwort.<br><br>Probiere es erneut. Sollte das Problem bestehen bleiben, kontaktiere die <a href="/Hilfe" target="_blank">Hotline</a.',
            partnerLogo: 'dat',
        },
        INVALID_DAT_DAMAGE_CALCULATION_DOCX_FILE: {
            title: 'DOCX-Datei nicht verarbeitbar',
            body: 'Die Microsoft-Word-Datei der DAT-Kalkulation kann nicht verarbeitet werden, weil die Datei defekt ist.<br><br>Probiere es erneut. Sollte das Problem bestehen bleiben, kontaktiere die <a href="/Hilfe" target="_blank">Hotline</a.',
            partnerLogo: 'dat',
        },
        VXS_EXPORT_ONLY_POSSIBLE_WITH_DAT_CALCULATION: {
            title: 'VXS-Export nur mit DAT-Kalkulation möglich',
            body: 'Bitte deaktiviere den E-Mail-Anhang "VXS-Export" oder führe eine DAT-Kalkulation durch.',
            partnerLogo: 'dat',
        },
        /////////////////////////////////////////////////////////////////////////////*/
        //  END Calculation
        /////////////////////////////////////////////////////////////////////////////*/

        //*****************************************************************************
        //  Valuation
        //****************************************************************************/
        DAT_VALUATION_LOCKED: {
            title: 'DAT-Vorgang gesperrt',
            body: 'Der DAT-Voragang für diese Bewertung ist laut DAT temporär gesperrt. Rufe die Ergebnisse einfach nochmal ab, dann sollte es klappen.',
            toastType: 'warn',
        },
        WRONG_VALUATION_TAX_TYPE_COMBINATION: {
            title: 'Ungültige Steuerart',
            body: 'Bitte stelle sicher, dass die Steuerart kompatibel ist.',
        },
        TAXATION_TYPE_MISMATCH_DAT_AND_AX: {
            title: 'Steuerart in autoiXpert & DAT unterschiedlich',
            body: 'Bitte stelle sicher, dass die Steuerart (regelbesteuert, differenzbesteuert, steuerneutral) in der DAT-Oberfläche der Steuerart in autoiXpert entspricht.',
        },
        DAT_VALUATION_IMPOSSIBLE_INVALID_CONSTRUCTION_YEAR: (error) => ({
            title: 'Fahrzeug zu alt',
            body: `Die DAT konnte für dieses Fahrzeug keine Bewertung erstellen. Mögliche Gründe:<br>• Baujahr "${error.data.constructionYear}" zu weit in der Vergangenheit (DAT bewertet max. 20 Jahre alte Fahrzeuge).<br>• Erstzulassung mehr als ein Jahr nach Ende des Bauzeitraums des Modells/Untermodells.`,
        }),
        DAT_FIRST_REGISTRATION_IN_THE_FUTURE: {
            title: 'Erstzulassung in Zukunft',
            body: `Die DAT konnte für dieses Fahrzeug keine Bewertung erstellen, weil die Erstzulassung in der Zukunft liegt. Bitte korrigiere die Erstzulassung.`,
        },
        DAT_FIRST_REGISTRATION_TOO_FAR_FROM_MARKET_INDEX_CONSTRCUTION_TIME: {
            title: 'Erstzulassung außerhalb des DAT-Bauzeitraums',
            body: `Die DAT konnte für dieses Fahrzeug keine Bewertung erstellen, weil die Erstzulassung mehr als 1 Jahr außerhalb des Bauzeitraums laut DAT-Marktindex liegt.<br><br>Bitte wähle einen anderen DAT-Marktindex oder passe die Erstzulassung an.`,
        },
        DAT_VALUATION_NOT_UP_TO_DATE: {
            title: 'DAT-Bewertung neu ausführen',
            body: `Die Bewertungsgrundlage für dieses Fahrzeug hat sich geändert, weshalb die DAT die Abfrage der Ergebnisse über die Schnittstelle nicht mehr zulässt. Bitte führe die Bewertung über die DAT-Oberfläche erneut aus.`,
        },
        DAT_INVALID_CONSTRCUTION_TIME: {
            title: 'Ungültige Bauzeit',
            body: `Die DAT-Bewertung für dieses Fahrzeug kann nicht durchgeführt werden, weil die Bauzeitangabe ungültig ist.`,
        },
        MILEAGE_MISSING: {
            title: 'Laufleistung fehlt',
            body: `Die DAT-Bewertung erfordert eine gültige Laufleistung.`,
        },
        DAT_CONSTRUCTION_TIME_MISSING: {
            title: 'Bauzeit fehlt',
            body: `Bitte gib das Baujahr für dieses Fahrzeug im Reiter "Fahrzeugauswahl" an.`,
        },
        DAT_VALUATION_INVALID_CONTAINER: {
            title: 'DAT: "Ungültiger Container"',
            body: `Die DAT hat keine Bewertungsdaten für dieses Fahrzeug. Bitte kontaktiere die DAT.`,
            partnerLogo: 'dat',
        },
        DAT_VALUATION_PDF_NOT_PROVIDED: {
            title: 'Bewertungsdokument nicht verfügbar',
            body: `Die DAT stellt für diesen Vorgang keine schriftliche Bewertung (PDF) bereit. Dafür ist eine Lizenz für calculateExpert oder myClaim notwendig. Bitte kontaktiere die DAT.`,
        },
        /////////////////////////////////////////////////////////////////////////////*/
        //  END Valuation
        /////////////////////////////////////////////////////////////////////////////*/
    };
}

import { Car, CarShape } from '@autoixpert/models/reports/car-identification/car';

export function isAxleConfigurationRequired(car: Car): boolean {
    const carShapedRequiringAxleConfigurator: CarShape[] = [
        'bus',
        'truck',
        'semiTruck',
        'semiTrailer',
        'transporter', // Some transporters, e.g. Mercedes Sprinter, may have twin tires on the rear axle
        'motorHome', // Some motor homes have twin tires on the rear axle or more than 2 axles
    ];

    const hasTwinTires: boolean = !!car.axles.find((axle) => axle.outerLeftTire || axle.outerRightTire);
    const isTrailerWithTwoOrMoreAxles: boolean = car.shape === 'trailer' && car.numberOfAxles >= 2;

    return (
        carShapedRequiringAxleConfigurator.includes(car.shape) ||
        car.numberOfAxles >= 3 ||
        isTrailerWithTwoOrMoreAxles ||
        hasTwinTires
    );
}

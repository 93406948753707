import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
    selector: 'password-input',
    templateUrl: 'password-input.component.html',
    styleUrls: ['password-input.component.scss'],
})
export class PasswordInputComponent {
    @Input('password') public passwordFromParent: string = null;
    @Output() public passwordChange: EventEmitter<string> = new EventEmitter<string>();

    @Input() public placeholder: string = 'Passwort';
    // Set this to "new-password" if autocomplete for this password field should be disabled.
    @Input() public autocomplete: string = '';
    @Input() public name: string = '';
    @Input() public disabled: boolean = false;
    @Input() public type: 'text' | 'password' = 'password';

    public newPassword: string = '';

    public passwordVisible: boolean = false;

    public passwordChanged: boolean = false;

    public togglePasswordVisibility(): void {
        this.passwordVisible = !this.passwordVisible;
    }

    public determineChangeStatus(event: Event): void {
        const currentInputValue = (event.target as HTMLInputElement).value;
        this.passwordChanged = currentInputValue !== this.passwordFromParent;
    }

    public removePlaceholder(event: Event): void {
        const eventTarget = event.target as HTMLInputElement;
        const currentInputValue = eventTarget.value;
        const stringToRemove = '(unverändert)';

        // Don't even run a regex if the input's value is shorter than the placeholder string
        if (currentInputValue.length < stringToRemove.length) {
            return;
        }

        eventTarget.value = currentInputValue.replace(stringToRemove, '');
    }

    public setNewPassword(event: Event): void {
        this.newPassword = (event.target as HTMLInputElement).value;
        this.passwordChange.emit(this.newPassword);
    }
}

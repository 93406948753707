export function jsonStringifySorted(objectToSerialize: any[]): string {
    function replacer(key, value) {
        /**
         * Don't sort arrays. This would break the order of the array.
         */
        if (value instanceof Object && !(value instanceof Array)) {
            return Object.keys(value)
                .sort()
                .reduce((sorted, key) => {
                    sorted[key] = value[key];
                    return sorted;
                }, {});
        } else {
            return value;
        }
    }

    return JSON.stringify(objectToSerialize, replacer);
}

import { CdkDragDrop } from '@angular/cdk/drag-drop';
import { Component, EventEmitter, HostListener, OnInit, Output } from '@angular/core';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import {
    MatLegacySlideToggleChange,
    MatLegacySlideToggleChange as MatSlideToggleChange,
} from '@angular/material/legacy-slide-toggle';
import { dialogEnterAndLeaveAnimation } from '@autoixpert/animations/dialog-enter-and-leave.animation';
import { ConfirmDialogComponent } from '@autoixpert/components/confirm-dialog/confirm-dialog.component';
import { removeFromArray } from '@autoixpert/lib/arrays/remove-from-array';
import { adaptDefaultDocumentOrder } from '@autoixpert/lib/documents/adapt-default-document-order';
import { translateDocumentType } from '@autoixpert/lib/translators/translate-document-type';
import { isAdmin } from '@autoixpert/lib/users/is-admin';
import { DocumentMetadata } from '@autoixpert/models/documents/document-metadata';
import { DocumentOrderConfig } from '@autoixpert/models/documents/document-order-config';
import { ReportType, reportTypes } from '@autoixpert/models/reports/report';
import { DefaultDocumentOrder } from '@autoixpert/models/teams/default-document-order/default-document-order';
import { DefaultDocumentOrderGroup } from '@autoixpert/models/teams/default-document-order/default-document-order-group';
import { DefaultDocumentOrderItem } from '@autoixpert/models/teams/default-document-order/default-document-order-item';
import { Team } from '@autoixpert/models/teams/team';
import { TeamPreferences } from '@autoixpert/models/teams/team-preferences';
import { User } from '@autoixpert/models/user/user';
import { fadeInAndSlideAnimation } from '../../../../shared/animations/fade-in-and-slide.animation';
import { trackById } from '../../../../shared/libraries/track-by-id';
import { hasAccessRight } from '../../../../shared/libraries/user/has-access-right';
import { DocumentOrderConfigService } from '../../../../shared/services/document-order-config.service';
import { LoggedInUserService } from '../../../../shared/services/logged-in-user.service';
import { TeamService } from '../../../../shared/services/team.service';
import { ToastService } from '../../../../shared/services/toast.service';

@Component({
    selector: 'default-document-order-dialog',
    templateUrl: './default-document-order-dialog.component.html',
    styleUrls: ['./default-document-order-dialog.component.scss'],
    animations: [dialogEnterAndLeaveAnimation(), fadeInAndSlideAnimation()],
})
export class DefaultDocumentOrderDialogComponent implements OnInit {
    constructor(
        private teamService: TeamService,
        private toastService: ToastService,
        private dialog: MatDialog,
        private loggedInUserService: LoggedInUserService,
        private documentOrderConfigService: DocumentOrderConfigService,
    ) {}

    @Output() close: EventEmitter<void> = new EventEmitter();

    public user: User;
    public team: Team;

    // Shift + click on toggle activates / deactivates all documents.
    private userClickedOnDocumentToggleWithShift = false;

    public reportTypes = reportTypes;
    public availableReportTypes: ReportType[] = [];

    /**
     * Store the document order configs so we can display the title of custom documents.
     */
    private documentOrderConfigs: DocumentOrderConfig[];

    /**
     * The group and the order define which documents are displayed on the right and where changes to those documents
     * will be saved.
     *
     * Why is the group needed if the document order items exist in the document order and the document order is identified? The group
     * is relevant when all document orders need to be sorted at the same time because the other document order can only be
     * derived from the group.
     */
    public selectedDefaultDocumentOrderGroup: DefaultDocumentOrderGroup;
    public selectedDefaultDocumentOrder: DefaultDocumentOrder;

    // Allow reorder is only allowed on default configs or if team.preference.fullDocumentConfig.allowCustomDocumentOrderPerRecipient
    public allowReorder = true;

    // Shorthand property.
    public defaultDocumentOrderGroups: TeamPreferences['defaultDocumentOrderGroups'];

    async ngOnInit() {
        this.user = this.loggedInUserService.getUser();
        this.team = this.loggedInUserService.getTeam();

        /**
         * This shorthand-property is easier to use than to access the object at the team preferences path.
         */
        this.defaultDocumentOrderGroups = this.team.preferences.defaultDocumentOrderGroups;

        this.selectStandardDefaultDocumentOrder();

        this.getAvailableReportTypes();

        this.documentOrderConfigs =
            await this.documentOrderConfigService.getAllFromInMemoryCacheAndPopulateIfNecessary();
    }

    /**
     * This selects the standard group and within it the standard document order. Relevant when initializing the component or when deleting a selected group.
     */
    public selectStandardDefaultDocumentOrder() {
        /**
         * We only handle the report default, not the invoice or repair confirmation default since the latter are currently
         * not configurable. They only exist so that the structure is the same of all document lists. That allows a reuse
         * of logic und functions.
         */
        const standardDefaultDocumentOrderGroup: DefaultDocumentOrderGroup = this.defaultDocumentOrderGroups.find(
            (defaultDocumentGroup) => defaultDocumentGroup.types.includes('reportDefault'),
        );
        const standardDefaultDocumentOrder: DefaultDocumentOrder =
            standardDefaultDocumentOrderGroup.documentOrders.find(
                (defaultDocumentOrder) => defaultDocumentOrder.recipientRole === 'default',
            );
        this.selectDefaultDocumentOrder(standardDefaultDocumentOrderGroup, standardDefaultDocumentOrder);
    }

    public selectDefaultDocumentOrder(
        selectDefaultDocumentOrderGroup: DefaultDocumentOrderGroup,
        defaultDocumentOrder: DefaultDocumentOrder,
    ) {
        this.selectedDefaultDocumentOrderGroup = selectDefaultDocumentOrderGroup;
        this.selectedDefaultDocumentOrder = defaultDocumentOrder;
        this.allowReorder =
            defaultDocumentOrder.recipientRole === 'default' ||
            this.team.preferences.allowCustomDocumentOrderPerRecipient;

        //*****************************************************************************
        //  Remove Obsolete Items
        //****************************************************************************/
        /**
         * If a user added and removed permanent uploaded documents, there may be stale old entries that must be removed.
         *
         * Identify those entries and delete them later to prevent an array over which we loop to be modified in the process.
         */
        const defaultDocumentOrderItemsToBeDeleted: DefaultDocumentOrderItem[] = [];
        for (const defaultDocumentOrderItem of this.selectedDefaultDocumentOrder.items) {
            if (defaultDocumentOrderItem.documentType !== 'manuallyUploadedPdf') {
                continue;
            }
            const permanentUploadedDocument: DocumentMetadata = this.team.permanentUploadedDocuments.find(
                (manuallyUploadedPdf) =>
                    manuallyUploadedPdf.uploadedDocumentId === defaultDocumentOrderItem.uploadedDocumentId,
            );
            // If the permanent uploaded document does not exist anymore, no need to keep there reference for sorting.
            if (!permanentUploadedDocument) {
                defaultDocumentOrderItemsToBeDeleted.push(defaultDocumentOrderItem);
            }
        }

        if (defaultDocumentOrderItemsToBeDeleted.length) {
            for (const defaultDocumentOrderItem of defaultDocumentOrderItemsToBeDeleted) {
                removeFromArray(defaultDocumentOrderItem, this.selectedDefaultDocumentOrder.items);
            }
            this.saveTeam();
        }
        /////////////////////////////////////////////////////////////////////////////*/
        //  END Remove Obsolete Items
        /////////////////////////////////////////////////////////////////////////////*/
    }

    public shouldGroupBeDisplayed(defaultDocumentOrderGroup: DefaultDocumentOrderGroup): boolean {
        return (
            !defaultDocumentOrderGroup.types.includes('invoice') &&
            !defaultDocumentOrderGroup.types.includes('repairConfirmation')
        );
    }

    //*****************************************************************************
    //  Default Document Order Group CRUD
    //****************************************************************************/
    public addDefaultDocumentOrderGroup() {
        const defaultOrderGroup: DefaultDocumentOrderGroup = this.defaultDocumentOrderGroups.find(
            (defaultDocumentOrder) => defaultDocumentOrder.types.includes('reportDefault'),
        );

        this.defaultDocumentOrderGroups.push(
            new DefaultDocumentOrderGroup({
                title: 'Neue Konfiguration',
                documentOrders: JSON.parse(JSON.stringify(defaultOrderGroup.documentOrders)),
            }),
        );
        this.onDefaultDocumentOrderGroupChange();
    }

    public deleteDefaultDocumentOrderGroup(defaultDocumentOrderGroup: DefaultDocumentOrderGroup) {
        removeFromArray(defaultDocumentOrderGroup, this.defaultDocumentOrderGroups);
        if (defaultDocumentOrderGroup === this.selectedDefaultDocumentOrderGroup) {
            this.selectStandardDefaultDocumentOrder();
        }
        this.onDefaultDocumentOrderGroupChange();
    }

    public onDefaultDocumentOrderGroupChange(): void {
        this.getAvailableReportTypes();
        void this.saveTeam();
    }
    /////////////////////////////////////////////////////////////////////////////*/
    //  END Default Document Order Group CRUD
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Associated Report Types
    //****************************************************************************/

    /**
     * A user must not be able to create multiple overrides per report type. Therefore check which report types are already assigned.
     */
    public getAvailableReportTypes() {
        const availableReportTypes = JSON.parse(JSON.stringify(reportTypes));
        for (const defaultDocumentOrderGroup of this.defaultDocumentOrderGroups) {
            for (const defaultDocumentOrderGroupType of defaultDocumentOrderGroup.types) {
                removeFromArray(defaultDocumentOrderGroupType, availableReportTypes);
            }
        }
        this.availableReportTypes = availableReportTypes;
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Associated Report Types
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Translate Document Titles
    //****************************************************************************/
    /**
     * "letter" and "report" have different meanings depending on report type and recipient, therefore we use the generic name.
     */
    public getGermanDocumentTitle(defaultDocumentOrderItem: DefaultDocumentOrderItem): string {
        let permanentUploadedDocument: DocumentMetadata;
        switch (defaultDocumentOrderItem.documentType) {
            // 'letter' has a different meaning depending on recipient, use a generic name.
            case 'letter':
                return 'Anschreiben';

            // 'report' has different meaning depending on report type, use a generic name.
            case 'report':
                return 'Gutachten';

            // Manually uploaded documents have a title attribute in the team preferences.
            case 'manuallyUploadedPdf':
                permanentUploadedDocument = this.team.permanentUploadedDocuments.find(
                    (manuallyUploadedPdf) =>
                        manuallyUploadedPdf.uploadedDocumentId === defaultDocumentOrderItem.uploadedDocumentId,
                );
                if (permanentUploadedDocument) {
                    return permanentUploadedDocument.title;
                }
                // No fallthrough since translateDocumentType throws error
                return 'Unbekanntes Dokument';
            case 'customDocument':
                const customDocumentOrderConfig = this.documentOrderConfigs?.find(
                    (config) => config._id === defaultDocumentOrderItem.customDocumentOrderConfigId,
                );

                if (customDocumentOrderConfig) {
                    return customDocumentOrderConfig.titleLong;
                }

                // No fallthrough since translateDocumentType throws error
                return 'Unbekanntes Dokument';
            // For other document types we use the existing translator
            default:
                return (
                    translateDocumentType(defaultDocumentOrderItem.documentType) ??
                    `unbekannt (${defaultDocumentOrderItem.documentType})`
                );
        }
    }
    /////////////////////////////////////////////////////////////////////////////*/
    //  END Translate Document Titles
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Allow Order per Recipients
    //****************************************************************************/
    /**
     * When disabling reorder, we ask the user for confirmation and reset all document orders to the standard document order
     * from the same group.
     */
    public async onAllowCustomDocumentOrderPerRecipientChange(event: MatLegacySlideToggleChange) {
        // // Prevent toggle from "jumping"
        // event.source.checked = this.team.preferences.allowCustomDocumentOrderPerRecipient;

        /**
         * Allowed before, now changed to disallowed.
         */
        if (!this.team.preferences.allowCustomDocumentOrderPerRecipient) {
            const resetOrderPerRecipient = await this.dialog
                .open(ConfirmDialogComponent, {
                    data: {
                        heading: 'Reihenfolgen zusammenführen',
                        content: `Alle Reihenfolgen, die in diesem Dialog eingestellt wurden, werden an den Standard der Gruppe angeglichen.
                    Die Einstellung, ob ein Dokument bei einem Empfänger aktiv ist, bleibt weiterhin pro Empfänger erhalten.
                    \n
                    Deine bereits erstellten Gutachten werden erstmal nicht verändert. Falls du aber zukünftig die Reihenfolge der Dokumente in einem Gutachten änderst, wird diese für alle Empfänger in dem Gutachten angeglichen.`,
                        confirmLabel: 'Zusammenführen',
                        cancelLabel: 'Stopp! Abbruch!',
                    },
                })
                .afterClosed()
                .toPromise();
            if (!resetOrderPerRecipient) {
                // Reset mat-slide-toggle
                this.team.preferences.allowCustomDocumentOrderPerRecipient = false;
                return;
            }

            /**
             * Copy the standard default document order to all other recipients' document orders for all groups.
             */
            for (const defaultDocumentOrderGroup of this.defaultDocumentOrderGroups) {
                const standardDefaultDocumentOrder: DefaultDocumentOrder =
                    defaultDocumentOrderGroup.documentOrders.find(
                        (documentOrder) => documentOrder.recipientRole === 'default',
                    );

                for (const defaultDocumentOrder of defaultDocumentOrderGroup.documentOrders) {
                    adaptDefaultDocumentOrder({
                        templateOrder: standardDefaultDocumentOrder,
                        orderToBeSorted: defaultDocumentOrder,
                    });
                }
            }
        }
        this.saveTeam();
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Allow Order per Recipients
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Handle Reordering Documents
    //****************************************************************************/
    /**
     * If team preference 'allowCustomDocumentOrderPerRecipient' is not set, all document orders are sorted in the same way.
     */
    public onDocumentOrderItemReordered(event: CdkDragDrop<string[]>): void {
        if (event.previousIndex === event.currentIndex) {
            return;
        }

        const movedDocument = this.selectedDefaultDocumentOrder.items.splice(event.previousIndex, 1)[0];
        this.selectedDefaultDocumentOrder.items.splice(event.currentIndex, 0, movedDocument);

        // Copy current full document order to all recipients
        const sortAllRecipients = !this.team.preferences.allowCustomDocumentOrderPerRecipient;
        if (sortAllRecipients) {
            for (const documentOrder of this.selectedDefaultDocumentOrderGroup.documentOrders) {
                if (documentOrder === this.selectedDefaultDocumentOrder) {
                    continue;
                }
                adaptDefaultDocumentOrder({
                    templateOrder: this.selectedDefaultDocumentOrder,
                    orderToBeSorted: documentOrder,
                });
            }
        }

        // Save the new order back to the server.
        this.onDefaultDocumentOrderGroupChange();

        if (!this.team.administrators.includes(this.user._id)) {
            console.log('Current user is not a team admin. Do not save the document order as default.');
            return;
        }
    }
    /////////////////////////////////////////////////////////////////////////////*/
    //  END Handle Reordering Documents
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Include/Exclude Document
    //****************************************************************************/

    public includeAllDocumentsInFullDocument() {
        for (const defaultDocumentOrderItem of this.selectedDefaultDocumentOrder.items) {
            defaultDocumentOrderItem.includedInFullDocument = true;
        }
        this.onDefaultDocumentOrderGroupChange();
    }

    public excludeAllDocumentsFromFullDocument() {
        for (const defaultDocumentOrderItem of this.selectedDefaultDocumentOrder.items) {
            defaultDocumentOrderItem.includedInFullDocument = false;
        }
        this.onDefaultDocumentOrderGroupChange();
    }

    /**
     * Shift + click allows user to include / exclude all documents.
     * Using the change event to track changes instead of the click event allows the user to use the keyboard. Since we do not
     * want to trigger the change twice, the shift click is written into this variable and will be processed in the change handler.
     * The change event would not know whether the user held the shift key, so this property is required.
     */
    public handleToggleClick(event: MouseEvent) {
        this.userClickedOnDocumentToggleWithShift = event.shiftKey;
    }

    public handleToggleChange(event: MatSlideToggleChange) {
        if (this.userClickedOnDocumentToggleWithShift) {
            // This toggle was activated --> activate all documents.
            if (event.checked) {
                this.includeAllDocumentsInFullDocument();
            } else {
                this.excludeAllDocumentsFromFullDocument();
            }
            /**
             * If the user clicked with shift this marked should be reset. If the user later would toggle a document
             * with his keyboard, the click event would not fire. If this marker would not be reset, toggling via the
             * keyboard would still activate/deactivate all documents. That's unexpected behavior.
             */
            this.userClickedOnDocumentToggleWithShift = false;
        }
        // Activating or deactivating all documents already causes a report save. This line must therefore only be executed if a single toggle is changed.
        else {
            this.onDefaultDocumentOrderGroupChange();
        }
    }
    /////////////////////////////////////////////////////////////////////////////*/
    //  END Include/Exclude Document
    /////////////////////////////////////////////////////////////////////////////*/

    public async saveTeam(): Promise<void> {
        try {
            await this.teamService.put(this.team);
        } catch (error) {
            this.toastService.error('Team konnte nicht gespeichert werden');
            console.error('TEAM_COULD_NOT_BE_SAVED', error);
        }
    }

    //*****************************************************************************
    //  Events
    //****************************************************************************/

    public async closeFullDocumentConfigurationDialog() {
        this.close.emit();
    }

    public handleOverlayClick(event: MouseEvent): void {
        // Only close editor if the overlay has been clicked directly. Ignore bubbling events from the dialog.
        if (event.target === event.currentTarget) {
            this.closeFullDocumentConfigurationDialog();
        }
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Events
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Keyboard Shortcuts
    //****************************************************************************/
    @HostListener('window:keydown', ['$event'])
    public closeEditorOnEscKey(event) {
        if (event.key === 'Escape') {
            // Make sure saving is triggered if the user is still inside an input.
            if (document.activeElement.nodeName === 'INPUT' || document.activeElement.nodeName === 'TEXTAREA') {
                (document.activeElement as HTMLElement).blur();
            }
            this.closeFullDocumentConfigurationDialog();
        }
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Keyboard Shortcuts
    /////////////////////////////////////////////////////////////////////////////*/
    protected readonly trackById = trackById;
    protected readonly translateDocumentType = translateDocumentType;
    protected readonly isAdmin = isAdmin;
    protected readonly hasAccessRight = hasAccessRight;
}

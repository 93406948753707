import { Report } from '../../models/reports/report';
import { getApplicableRepairCosts } from './get-applicable-repair-costs';

/**
 * How much does it cost the insurance to repair the vehicle?
 * German: "Wiederherstellungswert"
 *
 * Value increase is explicitly not included because then, a previously more severely damaged car
 * (higher value increase after repair) would be more likely to be repaired below the 130% rule.
 */
export function getRestorationValue(report: Report): number {
    return getApplicableRepairCosts(report) + (report.valuation.diminishedValue || 0);
}

<div class="dialog-overlay" (mousedown)="closeSelectorWithClickOnOverlay($event)" @dialogEnterAndLeave>
    <div class="dialog-container" (mousedown)="closeSelectorWithClickOnOverlay($event)">
        <!--============================================-->
        <!-- Text Block Templates -->
        <!--============================================-->
        <div id="search-and-list-container" class="card">
            <div id="heading-and-toolbar-container">
                <h2>Textvorlagen</h2>
                <!--============================================-->
                <!-- Search Field -->
                <!--============================================-->
                <mat-form-field id="search-field-container" class="search-field mat-block">
                    <mat-icon matPrefix>search</mat-icon>
                    <input
                        matInput
                        placeholder="Suchen"
                        type="search"
                        [(ngModel)]="searchTerm"
                        (ngModelChange)="applySearchFilter()"
                        [axAutofocus]="true" />
                </mat-form-field>
                <!--============================================-->
                <!-- END Search Field -->
                <!--============================================-->
                <div class="heading-toolbar">
                    <mat-icon
                        class="toolbar-icon add-template-icon"
                        [ngClass]="{ disabled: !user?.accessRights.editTextsAndDocumentBuildingBlocks }"
                        (click)="addTextTemplate()"
                        matTooltip="Neue Textvorlage">
                        add
                    </mat-icon>
                    <mat-icon
                        class="toolbar-icon"
                        (click)="sortByTitle()"
                        [ngClass]="{ disabled: !user?.accessRights.editTextsAndDocumentBuildingBlocks }"
                        matTooltip="Nach Titel sortieren"
                        *ngIf="!isSmallScreen()">
                        text_rotate_vertical
                    </mat-icon>
                </div>
            </div>

            <div
                id="text-block-list-container"
                cdkDropList
                cdkDropListLockAxis="y"
                (cdkDropListDropped)="handleReorderingDrop($event)">
                <div
                    class="text-block-container"
                    [ngClass]="{ 'in-edit-mode': textTemplateInEditMode === textTemplate }"
                    *ngFor="let textTemplate of textTemplatesFiltered"
                    (dblclick)="insertTextTemplate(textTemplate)"
                    (mousedown)="textTemplateInEditMode !== textTemplate ? preventLosingFocus($event) : null"
                    cdkDrag
                    cdkDragBoundary="#text-block-list-container"
                    [cdkDragDisabled]="!!searchTerm || !user?.accessRights.editTextsAndDocumentBuildingBlocks">
                    <mat-icon
                        class="text-block-drag-handle toolbar-icon"
                        cdkDragHandle
                        [matTooltip]="
                            user?.accessRights.editTextsAndDocumentBuildingBlocks
                                ? 'Per Drag & Drop verschieben'
                                : getMissingAccessRightTooltip('editTextsAndDocumentBuildingBlocks')
                        ">
                        drag_indicator
                    </mat-icon>

                    <!--============================================-->
                    <!-- Readonly mode -->
                    <!--============================================-->
                    <div class="text-block-display-container" *ngIf="textTemplateInEditMode !== textTemplate">
                        <!--********** Content **********-->
                        <div class="text-block-content-container">
                            <h3 class="text-block-heading">{{ textTemplate.title }}</h3>
                            <div class="text-block-body" [quillContent]="textTemplate.body"></div>
                        </div>

                        <!--********** ID & Toolbar **********-->
                        <div class="text-block-id-and-tools-column">
                            <!--********** Use Text Icon **********-->
                            <div class="text-block-display-toolbar">
                                <mat-icon
                                    class="toolbar-icon"
                                    matTooltip="Text verwenden"
                                    (click)="insertTextTemplate(textTemplate); $event.stopImmediatePropagation()">
                                    note_add
                                </mat-icon>
                                <mat-icon
                                    class="toolbar-icon"
                                    *ngIf="user?.accessRights.editTextsAndDocumentBuildingBlocks"
                                    [mat-menu-trigger-for]="textBlockSubmenu">
                                    more_vert
                                </mat-icon>
                                <mat-menu #textBlockSubmenu="matMenu">
                                    <mat-option (click)="startEditMode(textTemplate)">Bearbeiten</mat-option>
                                    <mat-option (click)="deleteTextTemplate(textTemplate)">Löschen</mat-option>
                                </mat-menu>
                            </div>
                        </div>
                    </div>
                    <!--============================================-->
                    <!-- END Readonly mode -->
                    <!--============================================-->

                    <!--============================================-->
                    <!-- Edit Mode -->
                    <!--============================================-->
                    <div class="text-block-edit-container" *ngIf="textTemplateInEditMode === textTemplate">
                        <mat-form-field class="heading-input mat-block">
                            <input
                                matInput
                                placeholder="Überschrift"
                                [(ngModel)]="textTemplate.title"
                                (change)="saveTextTemplate(textTemplate)"
                                (keydown)="leaveEditModeOnShortcut(textTemplate, $event)"
                                [axAutofocus]="true"
                                matTooltip="Wird nicht abgedruckt, ist aber nützlich zur Orientierung und Suche." />
                        </mat-form-field>
                        <placeholder-autocomplete-quill
                            placeholder="Textkörper"
                            [(ngModel)]="textTemplate.body"
                            (change)="saveTextTemplate(textTemplate)"
                            (keydown)="leaveEditModeOnShortcut(textTemplate, $event)"></placeholder-autocomplete-quill>
                        <div class="text-template-buttons-container">
                            <button (click)="leaveEditMode(textTemplate)">Bearbeitung beenden</button>
                        </div>
                    </div>
                    <!--============================================-->
                    <!-- END Edit Mode -->
                    <!--============================================-->
                </div>
                <!-------------- "No text blocks found" container -->
                <div id="no-text-blocks-found" *ngIf="textTemplatesFiltered.length === 0">
                    <div id="no-texts-placeholder-container">
                        <div id="no-texts-placeholder-image-container">
                            <img src="/assets/images/icons/quill_blue.svg" />
                        </div>
                        <div class="semibold">Kreativität an!</div>
                        <div>Hier ist Platz für deine Texte.</div>
                    </div>
                    <button
                        class="add-template-icon"
                        [ngClass]="{ disabled: !user?.accessRights.editTextsAndDocumentBuildingBlocks }"
                        (click)="addTextTemplate()">
                        Neue Vorlage
                    </button>
                </div>
            </div>
        </div>
        <!--============================================-->
        <!-- END Text Block Templates -->
        <!--============================================-->

        <!--============================================-->
        <!-- Preview with Placeholders Replaced -->
        <!--============================================-->
        <div
            id="text-with-placeholders-replaced"
            class="card"
            *ngIf="textTemplateInEditMode && textTemplateContainsPlaceholders(textTemplateInEditMode)"></div>
        <!--============================================-->
        <!-- END Preview with Placeholders Replaced -->
        <!--============================================-->

        <!--============================================-->
        <!-- Right Column -->
        <!--============================================-->
        <div id="text-field-container" class="card">
            <mat-icon class="dialog-close-icon" (click)="closeTextTemplateSelector()">close</mat-icon>

            <ng-container
                *ngIf="
                    textTemplateInEditMode && textTemplateContainsPlaceholders(textTemplateInEditMode);
                    then replacedTextPreview;
                    else resultingText
                "></ng-container>

            <!--============================================-->
            <!-- Replaced Text Preview -->
            <!--============================================-->
            <ng-template #replacedTextPreview>
                <h2>
                    Vorschau
                    <span class="help-indicator" matTooltip="Platzhalterwerte basieren auf dem aktuellen Gutachten">
                        ?
                    </span>
                </h2>
                <h3 *ngIf="textTemplateInEditMode.subject">{{ textTemplateInEditMode.subject }}</h3>
                <div id="replaced-text-preview" [innerHTML]="replacePlaceholders(textTemplateInEditMode)"></div>
            </ng-template>
            <!--============================================-->
            <!-- END Replaced Text Preview -->
            <!--============================================-->

            <!--============================================-->
            <!-- Resulting Text -->
            <!--============================================-->
            <ng-template #resultingText>
                <h2>{{ placeholderForResultingText }}</h2>
                <mat-form-field id="result-form-field" class="mat-block autogrow-vertically">
                    <mat-quill id="result-input" [(ngModel)]="text" placeholder="Ergebnis" #resultQuill></mat-quill>
                </mat-form-field>
                <div id="result-buttons-container">
                    <button (click)="acceptText(text)">Text übernehmen</button>
                    <button
                        #saveStandardTextButton
                        class="secondary"
                        *ngIf="associatedUserPreference || associatedTeamPreference"
                        [disabled]="associatedTeamPreference && !user.accessRights.editTextsAndDocumentBuildingBlocks"
                        [matTooltip]="
                            saveStandardTextButton.disabled
                                ? getMissingAccessRightTooltip('editTextsAndDocumentBuildingBlocks')
                                : ''
                        "
                        (click)="setStandardText(text)">
                        Als Standardtext setzen
                    </button>
                </div>
            </ng-template>
            <!--============================================-->
            <!-- END Resulting Text -->
            <!--============================================-->
        </div>
        <!--============================================-->
        <!-- END Right Column -->
        <!--============================================-->
    </div>
</div>

import { Invoice } from '../../models/invoices/invoice';
import { Payment } from '../../models/invoices/payment';
import { reduceByProperty } from '../arrays/reduce-by-property';
import { round } from '../numbers/round';

/**
 * Adds up all cancellations.
 */
export function getCanceledAmount(invoice: Pick<Invoice, 'totalGross' | 'payments'>): number {
    const cancellationPayments: Payment[] = invoice.payments.filter(
        (payment) => payment.type === 'partialCancellation',
    );
    const canceledAmount: number = reduceByProperty(cancellationPayments, 'amount');
    return round(canceledAmount);
}

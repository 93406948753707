import { Injectable } from '@angular/core';
import { CounterPattern } from '@autoixpert/lib/counters/counter-pattern';
import { Report } from '@autoixpert/models/reports/report';
import { OfficeLocation } from '@autoixpert/models/teams/office-location';
import { User } from '@autoixpert/models/user/user';
import { FieldGroupConfigService } from './field-group-config.service';
import { InvoiceNumberOrReportTokenCounterService } from './invoice-number-or-report-token-counter.service';
import { InvoiceNumberService } from './invoice-number.service';
import { LoggedInUserService } from './logged-in-user.service';
import { ReportTokenService } from './report-token.service';
import { TemplatePlaceholderValuesService } from './template-placeholder-values.service';
import { TutorialStateService } from './tutorial-state.service';
import { UserService } from './user.service';

/**
 * This service returns both the
 * - report token
 * - invoice number.
 * It's used if the team wants to keep its report tokens and invoice numbers synchronous.
 */
@Injectable()
export class ReportTokenAndInvoiceNumberService {
    constructor(
        private loggedInUserService: LoggedInUserService,
        private userService: UserService,
        private counterService: InvoiceNumberOrReportTokenCounterService,
        private invoiceNumberService: InvoiceNumberService,
        private reportTokenService: ReportTokenService,
        private tutorialStateService: TutorialStateService,
        private templatePlaceholderValuesService: TemplatePlaceholderValuesService,
        private fieldGroupConfigService: FieldGroupConfigService,
    ) {}
    //*****************************************************************************
    //  Generate Report Token & Invoice Number Together
    //****************************************************************************/
    public async generateReportTokenAndInvoiceNumber(report: Report): Promise<ReportTokenAndInvoiceNumberMap> {
        const user: User = this.loggedInUserService.getUser();
        if (!user) {
            return;
        }

        const invoiceNumberConfig = this.invoiceNumberService.getInvoiceNumberConfig(report.officeLocationId);
        const reportTokenConfig = this.reportTokenService.getReportTokenConfig(report.officeLocationId);

        const reportTokenPattern = new CounterPattern(reportTokenConfig.pattern);
        const invoiceNumberPattern = new CounterPattern(invoiceNumberConfig.pattern);

        const invoiceNumberCounter = await this.counterService.getAndIncreaseCount(invoiceNumberConfig._id);

        /**
         * Get responsible assessor in case the reportToken or invoiceNumber contains initials.
         */
        let associatedUser: User;
        if (reportTokenPattern.includesUserInitials() || invoiceNumberPattern.includesUserInitials()) {
            associatedUser = this.userService.getTeamMemberFromCache(report.responsibleAssessor);
        }

        /**
         * Get the officeLocation in case the token contains office location initials.
         */
        let associatedOfficeLocation: OfficeLocation;
        if (
            reportTokenPattern.includesOfficeLocationInitials() ||
            invoiceNumberPattern.includesOfficeLocationInitials()
        ) {
            const team = this.loggedInUserService.getTeam();
            associatedOfficeLocation = team.officeLocations.find(
                (officeLocation) => officeLocation._id === report.officeLocationId,
            );
        }

        const placeholderValues = await this.templatePlaceholderValuesService.getReportValues({
            reportId: report._id,
        });
        const fieldGroupConfigs = await this.fieldGroupConfigService.getAllFromInMemoryCacheAndPopulateIfNecessary();

        return {
            invoiceNumber: invoiceNumberPattern.fill(invoiceNumberCounter, {
                reportType: report.type,
                associatedUser,
                associatedOfficeLocation,
                reportId: report._id,
                placeholderValues,
                fieldGroupConfigs,
            }),
            reportToken: reportTokenPattern.fill(invoiceNumberCounter, {
                reportType: report.type,
                associatedUser,
                associatedOfficeLocation,
                reportId: report._id,
                placeholderValues,
                fieldGroupConfigs,
            }),
        };
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Generate Report Token & Invoice Number Together
    /////////////////////////////////////////////////////////////////////////////*/

    /**
     * Utility function to write the report token and the invoice number into a report object.
     */
    public writeToReport(report: Report, reportToken?: string, invoiceNumber?: string): void {
        if (!report.token && reportToken) {
            report.token = reportToken;
        }
        if (!report.feeCalculation.invoiceParameters.number && invoiceNumber) {
            report.feeCalculation.invoiceParameters.number = invoiceNumber;
        }
        this.tutorialStateService.markUserTutorialStepComplete('reportTokenRetrieved');
        this.tutorialStateService.markUserTutorialStepComplete('invoiceNumberRetrieved');
    }
}

export type ReportTokenAndInvoiceNumberMap = {
    invoiceNumber: string;
    reportToken: string;
};

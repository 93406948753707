import { Component, Inject, Input } from '@angular/core';
import { MatLegacyChipInputEvent } from '@angular/material/legacy-chips';
import {
    MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA,
    MatLegacyDialogRef as MatDialogRef,
} from '@angular/material/legacy-dialog';
import { removeFromArray } from '@autoixpert/lib/arrays/remove-from-array';
import { DamagedPart } from '@autoixpert/models/reports/damage-calculation/damaged-part';
import { Report } from '@autoixpert/models/reports/report';
import { Billing } from '@autoixpert/models/teams/team';
import {
    generateDamageDescriptionFromCalculation,
    generateRepairInstructionsFromCalculation,
} from '../../libraries/damage-calculation/translate-damaged-parts';
import { getProductName } from '../../libraries/get-product-name';
import { ToastService } from '../../services/toast.service';
import { UserPreferencesService } from '../../services/user-preferences.service';

const MOCK_DAMAGED_PARTS: DamagedPart[] = [
    {
        description: 'Motorhaube',
        repairType: 'instandsetzen',
        comment: 'ist verkratzt',
    },
    {
        description: 'Seitenwand h. l.',
        repairType: 'ersetzen',
    },
    {
        description: 'Kennzeichen-Aufhängung vorne',
        repairType: 'ersetzen',
    },
];

@Component({
    selector: 'description-from-repair-calculation-dialog',
    templateUrl: './description-from-repair-calculation-dialog.component.html',
    styleUrls: ['./description-from-repair-calculation-dialog.component.scss'],
})
export class DescriptionFromRepairCalculationDialogComponent {
    constructor(
        public userPreferences: UserPreferencesService,
        private toastService: ToastService,
        private dialog: MatDialogRef<
            DescriptionFromRepairCalculationDialogComponent,
            DescriptionFromRepairCalculationDialogResult
        >,
        @Inject(MAT_DIALOG_DATA) private data: DescriptionFromRepairCalculationDialogData,
    ) {}

    public report: Report;
    public licenseValidUntil: string;
    public expirationDate: string;
    public paymentCycle: Billing['paymentCycle'];

    public context = this.data.context;
    public exampleDamagedParts: DamagedPart[];
    public useMockData = false;

    public async ngOnInit() {
        if (this.data.damagedParts?.length) {
            this.exampleDamagedParts = this.data.damagedParts.slice(0, 5);
            this.useMockData = false;
        } else {
            this.exampleDamagedParts = MOCK_DAMAGED_PARTS;
            this.useMockData = true;
        }
        this.report = this.data.report;
    }

    public productName = getProductName();

    get exampleDamageDescription(): string {
        return this.generateDescriptionFromCalculation(this.exampleDamagedParts);
    }

    private generateDescriptionFromCalculation(damagedParts: DamagedPart[]): string {
        switch (this.context) {
            case 'damageDescriptionFromRepairParts':
                return generateDamageDescriptionFromCalculation({
                    userPreferences: this.userPreferences,
                    damagedParts,
                });
            case 'repairInstructionsFromRepairParts':
                return generateRepairInstructionsFromCalculation({
                    userPreferences: this.userPreferences,
                    damagedParts,
                });
        }
    }

    public addToBlacklist(chipInputEvent: MatLegacyChipInputEvent) {
        const word: string = (chipInputEvent.value || '').trim();

        // Clear input
        chipInputEvent.input.value = '';

        // Add if non-empty. Otherwise, hitting enter a few times would add empty elements.
        if (word) {
            this.userPreferences[this.context + '_blacklistedTokens'].push(word);
            // Trigger save, since the change is not detected by the userPreferences service automatically
            this.userPreferences[this.context + '_blacklistedTokens'] =
                this.userPreferences[this.context + '_blacklistedTokens'];
        }
    }
    public removeFromBlacklist(word: string) {
        removeFromArray(word, this.userPreferences[this.context + '_blacklistedTokens']);
        // Trigger save, since the change is not detected by the userPreferences service automatically
        this.userPreferences[this.context + '_blacklistedTokens'] =
            this.userPreferences[this.context + '_blacklistedTokens'];
    }

    public closeAndInsert(): void {
        if (this.useMockData) {
            this.toastService.error(
                'Keine Teile verfügbar',
                'Im Gutachten ist keine Kalkulation verknüpft oder die Kalkulation enthält keine Ersatzteile und Arbeitspositionen. Erstelle eine Kalkulation oder importiere die Kalkulation erneut.',
            );
            return;
        }

        this.dialog.close({
            generatedText: this.generateDescriptionFromCalculation(this.data.damagedParts),
        });
    }

    public close(): void {
        this.dialog.close(null);
    }
}

export interface DescriptionFromRepairCalculationDialogData {
    damagedParts: DamagedPart[];
    report: Report;
    context: 'damageDescriptionFromRepairParts' | 'repairInstructionsFromRepairParts';
}

export interface DescriptionFromRepairCalculationDialogResult {
    generatedText: string;
}

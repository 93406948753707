import { CdkVirtualScrollViewport } from '@angular/cdk/scrolling';
import { Component, EventEmitter, Input, Output, ViewChild, ViewEncapsulation, forwardRef } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { MatLegacyOptionSelectionChange as MatOptionSelectionChange } from '@angular/material/legacy-core';
import {
    filterDocumentPlaceholders,
    getAvailablePlaceholders,
    placeholderDetectionRegexString,
} from '@autoixpert/lib/document-building-blocks/get-available-placeholders';
import { FieldGroupConfig } from '@autoixpert/models/custom-fields/field-group-config';
import { DocumentBuildingBlock } from '@autoixpert/models/documents/document-building-block';
import { FieldGroupConfigService } from '../../services/field-group-config.service';
import { MatQuillComponent } from '../mat-quill/mat-quill.component';

/**
 * A rich text textarea with support for autocompleting placeholders by typing "{" (curly brace).
 */
@Component({
    selector: 'placeholder-autocomplete-quill',
    templateUrl: 'placeholder-autocomplete-quill.component.html',
    styleUrls: ['placeholder-autocomplete-quill.component.scss'],
    encapsulation: ViewEncapsulation.None,
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => PlaceholderAutocompleteQuill),
            multi: true,
        },
    ],
})
export class PlaceholderAutocompleteQuill implements ControlValueAccessor {
    constructor(private fieldGroupConfigService: FieldGroupConfigService) {}

    /**
     * Used for filtering which placeholders will be available in the autocomplete.
     */
    @Input() documentBuildingBlockPlaceholder: DocumentBuildingBlock['placeholder'] = null;
    /**
     * Limit the available placeholders to those that can be used within this document building block. Example: {Besichtigung.xxx} can only be used
     * within the document building block "BeschreibungProBesichtigung".
     */
    private availablePlaceholders;
    public filteredPlaceholders: string[] = [];
    private autocompleteCaretPosition: number;
    private placeholderMatch: string;

    /**
     * This is not the document building block placeholder but the placeholder sent to Quill to display a hint to the user what to enter into this field.
     */
    @Input() placeholder: string = 'Text';
    @Input() isEmailQuill: boolean = false;

    @Output() input: EventEmitter<string> = new EventEmitter<string>();
    @Output() change: EventEmitter<string> = new EventEmitter<string>();
    @Output() blur: EventEmitter<string> = new EventEmitter<string>();
    @Output() focus: EventEmitter<string> = new EventEmitter<string>();

    @ViewChild('matQuillComponent') private matQuillComponent: MatQuillComponent;
    @ViewChild(CdkVirtualScrollViewport) private virtualScrollViewport?: CdkVirtualScrollViewport;

    autocompleteItemHeight: number = 30.66;
    autocompleteHeight: string = `${this.autocompleteItemHeight * 10}`;

    // Custom Fields
    private fieldGroupConfigs: FieldGroupConfig[] = [];

    //*****************************************************************************
    //  Initialization
    //****************************************************************************/
    async ngOnInit() {
        this.fieldGroupConfigs = await this.fieldGroupConfigService.getAllFromInMemoryCacheAndPopulateIfNecessary();
        this.availablePlaceholders = getAvailablePlaceholders({
            buildingBlockPlaceholder: this.documentBuildingBlockPlaceholder,
            fieldGroupConfigs: this.fieldGroupConfigs,
        });
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Initialization
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  NgModel Implementation
    //****************************************************************************/
    // Private event handlers registered for NgModel integration.
    private ngModelOnChangeHandler: (changeEvent: any) => void;
    private ngModelOnBlurHandler: (blurEvent: any) => void;
    public disabled: boolean;

    public value: string;

    writeValue(value: any): void {
        this.value = value;
    }

    registerOnChange(onChangeHandler: (onChangeEvent: any) => void): void {
        this.ngModelOnChangeHandler = onChangeHandler;
    }

    /**
     * Registers a callback function that is called by the forms API on initialization to update the form model on blur.
     */
    registerOnTouched(onTouchedHandler: (onChangeEvent: any) => void): void {
        this.ngModelOnBlurHandler = onTouchedHandler;
    }

    setDisabledState?(isDisabled: boolean): void {
        this.disabled = isDisabled;
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END NgModel Implementation
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Event Handlers
    //****************************************************************************/
    handleQuillNgModelChange(quillContent) {
        if (this.ngModelOnChangeHandler) {
            this.ngModelOnChangeHandler(quillContent);
        }

        //*****************************************************************************
        //  Determine Placeholder Search Term
        //****************************************************************************/
        /**
         * Increase performance by only checking for autocomplete entries if there is a "{" in the text. That's required
         * for placeholders to be inserted.
         */
        if (quillContent?.includes('{')) {
            this.autocompleteCaretPosition = this.matQuillComponent.quillInstance.getSelection()?.index;
            // Quill can't determine the caret position within the HTML code, so we need to work with plain text instead. That has the benefit that we need not care about HTML tags.
            const quillText = this.matQuillComponent.quillInstance.getText();
            /**
             * The text left of the cursor may contain a placeholder because the user typed "{Anspruch" or similar strings. Check for those placeholders here.
             */
            const textBeforeCaret = this.autocompleteCaretPosition
                ? quillText.substr(0, this.autocompleteCaretPosition)
                : quillText;

            /**
             * Configure the RegExp to match only the placeholder at the end of the string (close to the caret).
             * (</[^>]+>)? means: Allow some closing HTML tags after the cursor.
             */
            const placeholderMatchesResult: RegExpMatchArray = textBeforeCaret.match(
                new RegExp(`${placeholderDetectionRegexString}$`),
            );
            this.placeholderMatch = placeholderMatchesResult?.[1];

            this.filterPlaceholders(this.placeholderMatch);
        }
        /////////////////////////////////////////////////////////////////////////////*/
        //  END Determine Placeholder Search Term
        /////////////////////////////////////////////////////////////////////////////*/

        this.input.emit(quillContent);
    }

    handleQuillChange(quillContent) {
        this.change.emit(quillContent);
    }

    handleQuillBlur() {
        if (this.ngModelOnBlurHandler) {
            this.ngModelOnBlurHandler(true);
        }
        this.blur.emit();
    }

    handleQuillFocus() {
        this.focus.emit();
    }

    initializeVirtualScrollViewport() {
        /**
         * This is necessary to fix the following bug:
         * 1. User opens overlay and scrolls down through the list
         * 2. User closes and re-opens the overlay
         * 3. Sometimes the list is completely empty and the user has to scroll once for the items to be rendered
         */
        this.virtualScrollViewport?.checkViewportSize();
    }

    /**
     * The user clicks on an option or hits enter after activating it with the arrow keys.
     */
    handlePlaceholderAutocompleteSelection(event: MatOptionSelectionChange) {
        if (this.autocompleteCaretPosition === undefined || this.placeholderMatch === undefined) {
            return;
        }

        const selectedPlaceholder = event.source.value;
        // Only add closing bracket if none is present.
        console.log({ selectedPlaceholder });

        const caretPositionOfPlaceholderMatch: number =
            this.autocompleteCaretPosition - (this.placeholderMatch?.length || 0);

        // Check if closing bracket is present or needs to be added.
        const closingBracket = this.matQuillComponent.quillInstance.getText(
            caretPositionOfPlaceholderMatch + (this.placeholderMatch?.length || 0),
            1,
        );

        const isClosingBracketPresent = closingBracket === '}';
        const placeholderWithClosingBracket = isClosingBracketPresent ? selectedPlaceholder : `${selectedPlaceholder}}`;

        // Delete the placeholder match
        this.matQuillComponent.quillInstance.deleteText(
            caretPositionOfPlaceholderMatch,
            this.placeholderMatch?.length || 0,
            'api',
        );

        // Insert the selected placeholder with or without closing bracket.
        this.matQuillComponent.quillInstance.insertText(
            caretPositionOfPlaceholderMatch,
            placeholderWithClosingBracket,
            'user',
        );

        event.source.value = this.matQuillComponent.value;

        // Let the change handlers know that the text has been modified.
        this.matQuillComponent.change.emit();

        /**
         * MatAutocomplete writes the value of the selected option into Quill but that's not what we want. Since we can't disable this behavior,
         * set the value back to what it was before MatAutocomplete overwrote it. We use setTimeout to do this after all other change detection
         * chains are complete.
         */
        const newCaretPositionAfterInsertedPlaceholder: number =
            caretPositionOfPlaceholderMatch + placeholderWithClosingBracket.length;

        setTimeout(() => {
            this.matQuillComponent.quillInstance.setSelection(newCaretPositionAfterInsertedPlaceholder, 0);
        });
    }

    ///**
    // * The user activates an option with the arrow keys. This does not mean that the user selects the option.
    // */
    //handleAutocompleteOptionActivated(event: MatAutocompleteActivatedEvent) {
    //    /**
    //     * Every time the user selects an option with the arrow keys, ensure the cursor is gone in Quill. Otherwise, the user may hit enter and
    //     * Quill inserts a line break.
    //     */
    //    this.matQuillComponent.quillInstance.blur();
    //}

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Event Handlers
    /////////////////////////////////////////////////////////////////////////////*/

    public filterPlaceholders(searchTerm: string): void {
        /**
         * If no search term is provided, the user has not entered a placeholder at the cursor position. In that case, don't
         * show the autocomplete.
         */
        this.filteredPlaceholders = searchTerm
            ? filterDocumentPlaceholders(this.availablePlaceholders, searchTerm)
            : [];
        this.autocompleteHeight = `${Math.min(250, this.filteredPlaceholders.length * this.autocompleteItemHeight)}px`;
    }
}

import { IsoDate } from '../../lib/date/iso-date.types';

export class AfzzertProcess {
    constructor(template: Partial<AfzzertProcess> = {}) {
        Object.assign(this, template);
    }

    certificateDate: IsoDate = null; // YYYY-MM-DD

    loginUrl: string = null;
    loginUrlExpiresAt: string = null; // The login URL is only valid for 30 minutes.

    certificateImportedAt: string = null; // If this is set, we can assume that a certificate was imported.
}

import { MatDateFormats } from '@angular/material/core';

export const AX_MOMENT_YEAR_FORMATS: MatDateFormats = {
    display: {
        dateInput: 'YYYY',
        dateA11yLabel: 'YYYY',
        monthYearA11yLabel: 'YYYY',
        monthYearLabel: 'YYYY',
    },
    parse: {
        dateInput: ['YYYY', 'YY'],
    },
};

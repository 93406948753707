<!-- Dropdown -->
<div
    [class]="['user-selection-dropdown', theme]"
    [style.cursor]="isDisabled ? undefined : 'pointer'"
    userSelectorInputAnchor
    [theme]="theme"
    (userSelected)="emitUserSelection($event)">
    <!-- User avatar -->
    <user-avatar [userId]="selectedUser?._id"></user-avatar>

    <!-- User info Container-->
    <div *ngIf="showUserInfoContainer" class="selected-user-info-container">
        <!-- User selected -->
        <ng-container *ngIf="selectedUser; else noUserSelected">
            <!-- Full name -->
            <div
                class="selected-user-full-name"
                [matTooltip]="
                    'Wähle den Nutzer, dessen Einstellungen du bearbeiten möchtest.\n\nAktuell ausgewählt: ' +
                    getUserFullName(selectedUser)
                ">
                {{ getUserFullName(selectedUser) }}
            </div>

            <!-- Role -->
            <!--********** Will be implemented as soon as we made roles generally available. **********-->
            <div *ngIf="false" class="selected-user-role" [matTooltip]="'Sachverständiger'">Sachverständiger</div>
        </ng-container>

        <ng-template #noUserSelected>
            <div class="selected-user-full-name">Nutzer auswählen</div>
        </ng-template>
    </div>

    <!-- Dropdown indicator -->
    <mat-icon class="dropdown-indicator" *ngIf="showDropdownArrow && !isDisabled">arrow_drop_down</mat-icon>
</div>

import { CustomField } from '../../models/custom-fields/custom-field';
import { FieldConfig } from '../../models/custom-fields/field-config';
import { FieldGroupConfig } from '../../models/custom-fields/field-group-config';
import { ServerError, UnprocessableEntity } from '../../models/errors/ax-error';
import { Report } from '../../models/reports/report';
import { toGermanDate } from '../ax-luxon';
import { extractAllCustomFieldsFromGroups } from '../custom-fields/extract-all-custom-fields-from-groups';
import { extractAllFieldConfigsFromGroups } from '../custom-fields/extract-all-field-configs-from-groups';
import { convertToEuro } from './convert-to-euro';
import { formatNumberToGermanLocale } from './format-number-to-german-locale';
import { PlaceholderValuesCustomFields } from './placeholder-values.types';
import { Translator } from './translator';

/**
 * Returns the placeholder values for custom fields. Custom fields add data to a report that is so specific
 * to individual assessors/assessor teams that they are not standardized in autoiXpert. The user can configure
 * them himself.
 */
export async function getTemplatePlaceholderValuesCustomFields({
    report,
    fieldGroupConfigs,
}: {
    report: Report;
    fieldGroupConfigs: FieldGroupConfig[];
}): Promise<PlaceholderValuesCustomFields> {
    // If the report does not contain any custom fields, no need to start mapping custom field values.
    if (!report.customFieldGroups?.length) {
        return {};
    }

    //*****************************************************************************
    //  Parameter Validation
    //****************************************************************************/
    if (!fieldGroupConfigs) {
        throw new ServerError({
            code: 'MISSING_CUSTOM_FIELD_CONFIGS',
            message:
                'Defining placeholder values for custom fields does not work without the custom field configs parameter. This is a technical issue.',
        });
    }
    /////////////////////////////////////////////////////////////////////////////*/
    //  END Parameter Validation
    /////////////////////////////////////////////////////////////////////////////*/

    /**
     * All custom fields the user defines are in this collection.
     */
    const customFieldConfigs: FieldConfig[] = extractAllFieldConfigsFromGroups(fieldGroupConfigs);
    /**
     * These are the fields that are present in the given report object.
     * Some fields may not be present in old reports because the user may have defined newer custom fields but has not yet opened an old report so that those newer
     * fields were not automatically added by the autoiXpert frontend.
     */
    const customFieldsOnReport: CustomField[] = extractAllCustomFieldsFromGroups(report.customFieldGroups);

    const customFieldPlaceholdersAndValues: [CustomField['name'], CustomField['value']][] = [];
    for (const customFieldConfig of customFieldConfigs) {
        /**
         * This custom field may or may not exist within the report because some fields are only present on old reports if the user opened the old report after defining the field.
         */
        const customField: CustomField | undefined = customFieldsOnReport.find(
            (customField) => customField.fieldConfigId === customFieldConfig._id,
        );

        const placeholder = customFieldConfig.placeholder;
        let value: any;

        try {
            switch (customFieldConfig.type) {
                case 'singleLineText':
                case 'multiLineText':
                case 'select':
                case 'autocomplete':
                    value = Translator.emptyValueToEmptyString(
                        customField?.value as string,
                        addSuffix(customField?.value as string, customFieldConfig.suffix),
                    );
                    break;
                case 'boolean':
                    /**
                     * Add one field that contains "Ja" or "Nein" so that the user can use this placeholder for printing within DOCX partials (DOCX templates).
                     * The placeholder without the "JaNein" suffix is better suited for checking variant conditions of a document building block or use it in texts outside of partials.
                     */
                    customFieldPlaceholdersAndValues.push([`${placeholder}JaNein`, customField?.value ? 'Ja' : 'Nein']);
                    value = Boolean(customField?.value);
                    break;
                case 'tristate':
                    value = Translator.tristate(customField?.value as boolean);
                    break;
                case 'number':
                    value = Translator.emptyValueToEmptyString(
                        customField?.value as number,
                        addSuffix(
                            formatNumberToGermanLocale(customField?.value as number, { decimalPlaces: 0 }),
                            customFieldConfig.suffix,
                        ),
                    );
                    break;
                case 'currency':
                    value = Translator.emptyValueToEmptyString(
                        customField?.value as number,
                        addSuffix(convertToEuro(customField?.value as number), customFieldConfig.suffix),
                    );
                    break;
                case 'date':
                    value = customField?.value ? toGermanDate(customField.value as string) : '';
                    break;
            }
        } catch (error) {
            throw new UnprocessableEntity({
                code: 'CUSTOM_FIELD_VALUE_DOES_NOT_MATCH_CUSTOM_FIELD_TYPE',
                message: `The custom field value cannot be converted to a placeholder value because it cannot be handled by the type-specific conversion function.`,
                data: {
                    customField,
                    customFieldConfig,
                },
                error,
            });
        }
        customFieldPlaceholdersAndValues.push([placeholder, value]);
    }

    //*****************************************************************************
    //  Define Placeholders & Replacements
    //****************************************************************************/
    return {
        ...Object.fromEntries(customFieldPlaceholdersAndValues),
    };
    //*****************************************************************************
    //  Define Placeholders & Replacements
    //****************************************************************************/
}

function addSuffix(value: string, suffix: string) {
    if (!value || !suffix) {
        return value;
    }
    return `${value} ${suffix}`;
}

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { OfflineSyncBlobServiceBase } from '../libraries/database/offline-sync-blob-service.base';
import { NetworkStatusService } from './network-status.service';
import { SyncIssueNotificationService } from './sync-issue-notification.service';

@Injectable()
export class SignablePdfTemplateService extends OfflineSyncBlobServiceBase {
    constructor(
        protected httpClient: HttpClient,
        protected networkStatusService: NetworkStatusService,
        protected syncIssueNotificationService: SyncIssueNotificationService,
    ) {
        super({
            serviceName: 'signablePdfTemplate',
            httpClient,
            networkStatusService,
            syncIssueNotificationService,
            formDataBlobFieldName: 'document',
        });
    }
}

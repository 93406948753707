import { DocumentMetadata } from '@autoixpert/models/documents/document-metadata';
import { DocumentOrder } from '@autoixpert/models/documents/document-order';
import { Invoice } from '@autoixpert/models/invoices/invoice';
import { getDocumentOrdersOfInvolvedPartiesFromInvoice } from './get-document-orders-of-involved-parties-from-invoice';
import { removeDocument } from './remove-document';

/**
 * Remove the document metadata object from invoice.documents and remove its reference from all involved parties' document orders.
 */
export function removeDocumentFromInvoice({
    document,
    invoice,
}: {
    document: DocumentMetadata;
    invoice: Invoice;
}): void {
    const documentOrders: DocumentOrder[] = getDocumentOrdersOfInvolvedPartiesFromInvoice({ invoice });

    removeDocument({
        document: document,
        documents: invoice.documents,
        documentOrders,
    });
}

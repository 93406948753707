import { Pipe, PipeTransform } from '@angular/core';

/**
 * Format the date as a relative date string.
 */
@Pipe({
    name: 'weekdayGerman',
    pure: true,
    standalone: true,
})
export class WeekdayPipe implements PipeTransform {
    transform(weekdayEnglish: Weekday, short: boolean): string {
        switch (weekdayEnglish) {
            case 'monday':
                return short ? 'Mo' : 'Montag';
            case 'tuesday':
                return short ? 'Di' : 'Dienstag';
            case 'wednesday':
                return short ? 'Mi' : 'Mittwoch';
            case 'thursday':
                return short ? 'Do' : 'Donnerstag';
            case 'friday':
                return short ? 'Fr' : 'Freitag';
            case 'saturday':
                return short ? 'Sa' : 'Samstag';
            case 'sunday':
                return short ? 'So' : 'Sonntag';
        }
    }
}

export type Weekday = 'monday' | 'tuesday' | 'wednesday' | 'thursday' | 'friday' | 'saturday' | 'sunday';

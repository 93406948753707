import { Component, EventEmitter, HostListener, Input, Output } from '@angular/core';
import { dialogEnterAndLeaveAnimation } from '@autoixpert/animations/dialog-enter-and-leave.animation';
import { ContactPerson } from '@autoixpert/models/contacts/contact-person';
import { OfficeLocation } from '@autoixpert/models/teams/office-location';

@Component({
    selector: 'office-location-edit-dialog',
    templateUrl: 'office-location-edit-dialog.component.html',
    styleUrls: ['office-location-edit-dialog.component.scss'],
    animations: [dialogEnterAndLeaveAnimation()],
})
export class OfficeLocationEditDialogComponent {
    constructor() {}

    @Input() officeLocation: OfficeLocation;

    @Output() officeLocationChange: EventEmitter<OfficeLocation> = new EventEmitter();
    @Output() close: EventEmitter<void> = new EventEmitter();

    public emitChange() {
        this.officeLocationChange.emit(this.officeLocation);
    }

    public closeDialog(): void {
        this.close.emit();
    }

    public insertCityIntoModel(city: string) {
        // Don't insert an empty city.
        if (!city) return;

        this.officeLocation.city = city;
    }

    public insertAddressIntoModel(address: Partial<ContactPerson>) {
        this.officeLocation.streetAndHouseNumber = address.streetAndHouseNumberOrLockbox;
        this.officeLocation.zip = address.zip;
        this.officeLocation.city = address.city;
        this.emitChange();
    }

    public handleOverlayClick(event: MouseEvent): void {
        if (event.target === event.currentTarget) {
            this.closeDialog();
        }
    }

    @HostListener('window:keydown', ['$event'])
    public handleKeyboardShortcuts(event: KeyboardEvent) {
        switch (event.key) {
            case 'Escape':
                this.closeDialog();
                break;
        }
    }
}

import { Injectable } from '@angular/core';
import { Invoice } from '@autoixpert/models/invoices/invoice';
import { InvoiceService } from './invoice.service';

/**
 * This service is necessary to share the invoice of the invoice-details component
 * between the invoice editor and the invoice-print-and-send component that are
 * both integrated through the
 *
 * If all components within the invoice details component would query their own invoice via the original InvoiceService,
 * changes to one copy would not be reflected to the other copy in the other component.
 */

@Injectable()
export class InvoiceDetailsService {
    constructor(private invoiceService: InvoiceService) {}

    private currentInvoice: Invoice = null;

    /**
     * Return the currently saved invoice if the invoiceId param matches the currently saved invoice. Otherwise, get that invoice
     */
    public async get(invoiceId: string): Promise<Invoice> {
        if (!invoiceId) return;

        if (this.currentInvoice?._id === invoiceId) {
            return this.currentInvoice;
        }

        // Clear the currently held invoice
        this.clear();

        // Get the right invoice
        this.currentInvoice = await this.invoiceService.get(invoiceId);

        return this.currentInvoice;
    }

    public clear(): void {
        this.currentInvoice = null;
    }
}

import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Subscription } from 'rxjs';
import { OfficeLocation } from '@autoixpert/models/teams/office-location';
import { trackById } from '../../libraries/track-by-id';
import { LoggedInUserService } from '../../services/logged-in-user.service';

@Component({
    selector: 'office-locations-picker',
    templateUrl: './office-locations-picker.component.html',
    styleUrls: ['./office-locations-picker.component.scss'],
})
export class OfficeLocationsPickerComponent implements OnInit {
    constructor(private loggedInUserService: LoggedInUserService) {}

    readonly trackById = trackById;
    protected officeLocations: OfficeLocation[] = [];

    private subscriptions: Subscription[] = [];

    @Input() officeLocationIds: OfficeLocation['_id'][] = [];
    @Input() showWithSingleLocation = false;
    @Output() officeLocationIdsChange = new EventEmitter<OfficeLocation['_id'][]>();

    ngOnInit() {
        this.subscriptions.push(
            this.loggedInUserService.getTeam$().subscribe((team) => {
                this.officeLocations = team?.officeLocations ?? [];
            }),
        );
    }

    protected getOfficeLocationById(officeLocationId: OfficeLocation['_id']): OfficeLocation | undefined {
        return this.officeLocations.find((officeLocation) => officeLocation._id === officeLocationId);
    }

    protected getOfficeLocationTitleById(officeLocationId: OfficeLocation['_id']): string {
        return this.getOfficeLocationById(officeLocationId)?.title || 'Unbenannter Standort';
    }

    protected toggleOfficeLocationId(officeLocationId: OfficeLocation['_id']): void {
        const officeLocationIds = this.officeLocationIds;
        const index = officeLocationIds.indexOf(officeLocationId);
        if (index === -1) {
            officeLocationIds.push(officeLocationId);
        } else {
            officeLocationIds.splice(index, 1);
        }

        this.officeLocationIdsChange.emit(officeLocationIds);
    }

    protected clearOfficeLocations() {
        this.officeLocationIds = [];
        this.officeLocationIdsChange.emit(this.officeLocationIds);
    }

    ngOnDestroy() {
        for (const subscription of this.subscriptions) {
            subscription.unsubscribe();
        }
    }
}

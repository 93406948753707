import { CdkVirtualScrollViewport } from '@angular/cdk/scrolling';
import { Component, ElementRef, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { MatLegacyMenuTrigger as MatMenuTrigger } from '@angular/material/legacy-menu';
import { Router } from '@angular/router';
import { Subject, Subscription } from 'rxjs';
import { debounceTime, filter, switchMap, take, tap } from 'rxjs/operators';
import { iconForCarBrandExists, iconNameForCarBrand } from '@autoixpert/lib/car/icon-for-car-brand-exists';
import { Invoice } from '@autoixpert/models/invoices/invoice';
import { trackById } from 'src/app/shared/libraries/track-by-id';
import { InvoiceService } from 'src/app/shared/services/invoice.service';
import { ToastService } from 'src/app/shared/services/toast.service';

/**
 * Dropdown for selecting an example invoice.
 * This example invoice is used to:
 *   - Preview the evaluation of the conditions of a document building block or a document building block variant.
 *   - Preview the text with the placeholders replaced by the example data.
 */
@Component({
    selector: 'select-invoice-dropdown',
    templateUrl: './select-invoice-dropdown.component.html',
    styleUrls: ['./select-invoice-dropdown.component.scss'],
    host: { '[class.dark]': 'theme === "dark"', '[class.light]': 'theme === "light"' },
})
export class SelectInvoiceDropdownComponent {
    constructor(
        private router: Router,
        private toastService: ToastService,
        private invoiceService: InvoiceService,
    ) {}

    @ViewChild('exampleInvoiceDropdownTrigger') exampleInvoiceDropdownTrigger?: MatMenuTrigger;
    @ViewChild('searchInput') searchInput?: ElementRef<HTMLInputElement>;
    @ViewChild(CdkVirtualScrollViewport) virtualScroll?: CdkVirtualScrollViewport;

    @Input() reportId: string | undefined = undefined;
    @Input() invoice: Invoice | undefined = undefined;
    @Input() theme: 'dark' | 'light' = 'dark';
    @Output() invoiceSelected = new EventEmitter<Invoice | null>();

    public invoices: Invoice[] = [];
    public filteredInvoices: Invoice[] = [];
    public initialSearchResults: boolean = false;

    protected searchTerm = '';
    public searchTermSubscription: Subscription;
    public searchTerm$: Subject<string> = new Subject<string>();
    public searchPending: boolean = false;

    readonly trackById = trackById;
    public iconNameForCarBrand = iconNameForCarBrand;
    public iconForCarBrandExists = iconForCarBrandExists;

    focusInput() {
        this.searchInput?.nativeElement?.focus();
    }

    handleSearchInputChange($event) {
        const searchQuery: string = ($event.target.value || '').trim();
        this.updateSearchTerm(searchQuery);
        this.filterInvoicesAutocomplete();
    }

    public initializeInvoiceConnectionInput(): void {
        this.searchTermSubscription = this.searchTerm$
            .pipe(
                filter((searchTerm) => this.hasValidSearchTerm(searchTerm)),
                tap(() => {
                    this.searchPending = true;
                }),
                debounceTime(300),
                switchMap((searchTerm) =>
                    this.invoiceService.find({
                        $search: searchTerm,
                    }),
                ),
            )
            .subscribe({
                next: (invoices) => {
                    this.searchPending = false;
                    this.invoices = invoices;
                    this.sortInvoices();
                    this.filterInvoicesAutocomplete();
                },
                error: () => {
                    this.toastService.error(
                        'Fehler bei Suche',
                        "Bitte kontaktiere die <a href='/Hilfe' target='_blank'>Hotline</a>.",
                    );
                    this.searchPending = false;
                },
            });
    }

    public filterInvoicesAutocomplete(): void {
        const searchTerms = (this.searchTerm || '').split(' ');

        this.filteredInvoices = this.invoices.filter((invoice) => {
            const propertiesToBeSearched = [
                (invoice?.number || '').toLowerCase(),
                (invoice?.claimant?.contactPerson?.organization || '').toLowerCase(),
                (invoice?.claimant?.contactPerson?.firstName || '').toLowerCase(),
                (invoice?.claimant?.contactPerson?.lastName || '').toLowerCase(),
            ];

            return searchTerms.every((searchTerm) => {
                searchTerm = (searchTerm || '').toLowerCase();
                return propertiesToBeSearched.some((property) => property.includes(searchTerm));
            });
        });
    }

    public sortInvoices(): void {
        this.invoices.sort((invoiceA, invoiceB) => {
            const tokenA = (invoiceA._id || '').toLowerCase().trim();
            const tokenB = (invoiceB._id || '').toLowerCase().trim();

            return tokenA.localeCompare(tokenB);
        });
    }

    public updateSearchTerm(searchTerm: string): void {
        this.searchTerm = searchTerm;
        this.searchTerm$.next(searchTerm);
    }

    public navigateToInvoice(invoiceId: string): void {
        this.router.navigateByUrl(`/Rechnungen/${invoiceId}/Editor`);
    }

    public handleOpenInNewClick(invoiceId: string, event: MouseEvent): void {
        window.open(`/Rechnungen/${invoiceId}`);
        // Prevent the click from selecting the autocomplete entry
        event.stopPropagation();
    }

    public clearSearchInput() {
        if (this.searchInput.nativeElement) {
            this.searchInput.nativeElement.value = '';
        }

        this.searchTerm = '';
        this.updateSearchTerm('');
        this.filterInvoicesAutocomplete();
    }

    public handleInvoiceClick(invoice: Invoice) {
        this.exampleInvoiceDropdownTrigger?.closeMenu();
        this.invoiceSelected.emit(invoice);
    }

    public handleRemoveInvoiceClick(event: MouseEvent) {
        event.stopPropagation();
        event.preventDefault();
        this.exampleInvoiceDropdownTrigger?.closeMenu();
        this.invoiceSelected.emit(null);
    }

    public getInvoiceNumberWithFallback(invoice: Invoice) {
        return invoice?.number || 'Rechnung';
    }

    /**
     * Ensure that a search term has 3 or more characters. Otherwise, searches like "a" are so unspecific
     * that our backend server collapses under the load because for large customer accounts, thousand of invoices
     * are loaded into memory.
     *
     * Three characters allow for searches like "BMW" oder "Audi".
     *
     * Also allow searches if there are two or more search words separated by space. That allows to search for license plates
     * like "PB SL".
     */
    public hasValidSearchTerm(searchTerm: string) {
        if (!searchTerm || typeof searchTerm !== 'string') return;

        // Prevent strings like "PB " or "PB  T " to count as multiple search terms.
        const searchTermParts = searchTerm
            .trim()
            .split(' ')
            .filter((searchTerm) => !!searchTerm.trim());
        return searchTermParts.some((searchTermPart) => searchTermPart.length >= 3);
    }

    initializeOverlay() {
        this.searchPending = false;
        this.clearSearchInput();
        this.focusInput();
        this.virtualScroll?.checkViewportSize();

        // Initial search results once
        if (!this.initialSearchResults) {
            this.initialSearchResults = true;
            this.searchPending = true;
            this.invoiceService
                .find({
                    $limit: 20,
                    $sort: { createdAt: -1 },
                })
                .pipe(take(1))
                .subscribe((invoices) => {
                    this.searchPending = false;
                    this.invoices = invoices;
                    this.sortInvoices();
                    this.filterInvoicesAutocomplete();
                });
        }
    }

    ngAfterViewInit() {
        this.initializeInvoiceConnectionInput();
    }
}

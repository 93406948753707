import { RequireSome } from '../../helper-types/require-some';
import { generateId } from '../../lib/generate-id';
import { User } from '../user/user';
import { LabelConfig } from './label-config';

/**
 * A label attached to a record, e.g. invoice or report.
 *
 * This is typically derived from a LabelConfig.
 * If a label config is changed, all labels attached to a record that are instances
 * of that label config should be updated by the application logic.
 */
export class Label {
    constructor(template: RequireSome<Label, 'labelConfigId' | 'name' | 'color'>) {
        Object.assign(this, template);
    }

    _id: string = generateId();
    labelConfigId: LabelConfig['_id'];
    name: LabelConfig['name'];
    color: LabelConfig['color'];

    createdBy: User['_id'];
    updatedAt: string;
    createdAt: string;
}

<div
    class="{{ childClass }}"
    *ngIf="applicableDowntimeNotifications.length > 0"
    id="context-sensitive-downtime-notification-container"
    @fadeInAndSlide>
    <mat-icon class="warning outlined">warning_amber</mat-icon>
    <div class="notification-heading">
        Störung bei der DAT
        <span id="open-overlay-button" (click)="openDetailsOverlay($event)" #overlayAnchor>Details</span>
    </div>
</div>

import { Axle } from '../../models/reports/car-identification/axle';
import { Tire } from '../../models/reports/car-identification/tire';
import { getAllTiresOnAxle } from './get-all-tires-on-axle';

export function getAllTiresOfVehicle(axles: Axle[]): Tire[] {
    return axles.reduce((tires, axle) => {
        tires.push(...getAllTiresOnAxle(axle));
        return tires;
    }, []);
}

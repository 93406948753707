<div class="card">
    <mat-form-field *ngIf="reportWhoseInvoiceCounterIsUsed" class="notes mat-block margin-top-10">
        <input
            matInput
            number-input
            [maximumFractionDigits]="0"
            [minimumFractionDigits]="0"
            placeholder="Rechnungszähler"
            [ngModel]="reportWhoseInvoiceCounterIsUsed.invoiceNumberConfig.count"
            (focus)="previousCounterCount = reportWhoseInvoiceCounterIsUsed.invoiceNumberConfig.count"
            (ngModelChange)="inputValueChanged($event)"
            (change)="handleCounterChange()" />
    </mat-form-field>
</div>

import { DocumentMetadata } from '@autoixpert/models/documents/document-metadata';
import { DocumentOrder } from '@autoixpert/models/documents/document-order';
import { DocumentOrderItem } from '@autoixpert/models/documents/document-order-item';

/**
 * Includes a document for sending it via email or downloading it through the full report PDF/full invoice PDF.
 *
 * Document orders exist for each recipient on each report and invoice, so that the user may sort documents
 */
export function setDocumentInclusionStatus({
    document,
    documentOrders,
    includedInFullDocument,
}: {
    document: DocumentMetadata;
    documentOrders: DocumentOrder[];
    includedInFullDocument: DocumentOrderItem['includedInFullDocument'];
}): void {
    for (const documentOrder of documentOrders) {
        const documentOrderItem = documentOrder.items.find(
            (documentOrderItem) => documentOrderItem._id === document._id,
        );
        documentOrderItem.includedInFullDocument = includedInFullDocument;
    }
}

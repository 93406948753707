import { DateTime } from 'luxon';
import { generateId } from '@autoixpert/lib/generate-id';
import { DocumentMetadata } from './documents/document-metadata';
import { DataTypeBase } from './indexed-db/database.types';
import { InvoiceInvolvedPartyRole } from './invoices/invoice';
import { EmailRecipient } from './reports/document-email';
import { DocumentGroup } from './reports/document-group';
import { CommunicationRecipientRole, InvolvedPartyRole } from './reports/involved-parties/involved-party';
import { EmailSchedulingOption } from './teams/email-scheduling-option';

export type OutgoingMessageType = 'document-email' | 'standard-email' | 'whatsapp-api' | 'whatsapp-link';
export type OutgoingMessageStatus = 'read' | 'delivered' | 'sent' | 'failed' | 'scheduled' | 'draft';
export type OutgoingMessageSource = 'report' | 'invoice' | 'remoteSignature';

export type OutgoingMessageSchedule = Omit<EmailSchedulingOption, 'id' | 'name'>;

export class OutgoingMessage implements DataTypeBase {
    constructor(template?: Partial<OutgoingMessage>) {
        if (template) {
            Object.assign(this, template);
        }
    }

    // Required by DataTypeBase
    _documentVersion: number = 0;
    _schemaVersion: number = 1;

    _id: string = generateId();
    type: OutgoingMessageType;

    // Content
    subject?: string;
    body: string;

    // Status
    scheduledAt?: string;
    sentAt?: string;
    deliveredAt?: string;
    failedAt?: string;
    readAt?: string;

    // Source
    source: OutgoingMessageSource;
    documentGroup?: DocumentGroup;
    recipientType: CommunicationRecipientRole | InvoiceInvolvedPartyRole | InvolvedPartyRole;
    reportId?: string;
    invoiceId?: string;

    // Attachments
    multiplePdfAttachments?: boolean = null;
    isDatVxsAttached?: boolean = null;
    attachedDocuments?: DocumentMetadata[] = [];
    areAttachmentsSeparated?: boolean = null;
    isElectronicInvoiceEnabled?: boolean = null;

    // Error
    error: {
        code?: string;
        message?: string;
        data?: any;
    } = {};

    // Email-specific information
    email?: OutgoingMessageEmailInfo;

    // Metadata
    updatedAt: string = DateTime.now().setZone('UTC').toISO();
    createdAt: string = DateTime.now().setZone('UTC').toISO();
    createdBy: string;
    teamId: string;
}

export interface OutgoingMessageEmailInfo {
    toRecipients: EmailRecipient[];
    ccRecipients: EmailRecipient[];
    bccRecipients: EmailRecipient[];
}

export class OutgoingEmailMessage extends OutgoingMessage {
    constructor(template?: Partial<OutgoingEmailMessage>) {
        super(template);
        if (!this.email) {
            this.email = {
                toRecipients: [],
                ccRecipients: [],
                bccRecipients: [],
            };
        }
    }

    declare email: OutgoingMessageEmailInfo;
}

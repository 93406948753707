import { todayIso } from '@autoixpert/lib/date/iso-date';
import { Task } from '@autoixpert/models/tasks/task';
import { getOpenTasks } from '../../../libraries/tasks/get-open-tasks';

export function getTooltipForTaskOverlayAnchor(tasks: Task[]): string {
    const today = todayIso();
    const openTasks = getOpenTasks(tasks);
    if (openTasks.some((task) => task.dueDate <= today)) {
        return 'Einige Aufgaben sind überfällig';
    } else if (openTasks.length) {
        return 'Einige Aufgaben sind noch zu erledigen';
    } else {
        return 'Alle Aufgaben sind erledigt';
    }
}

import { DataTypeBase, PatchedEvent } from '../../models/indexed-db/database.types';
import { ObjectDifference, getListOfDifferences } from './get-list-of-differences';
import { insertChangesIntoRecord } from './insert-changes-into-record';
import { threeWayMerge } from './three-way-merge';

export function applyOfflineSyncPatchEventToLocalRecord<DataType extends DataTypeBase>({
    localRecord,
    patchedEvent,
}: {
    localRecord: DataType;
    patchedEvent: PatchedEvent<DataType>;
}): { nonConflictingChanges: ObjectDifference[]; conflictingChanges: ObjectDifference[]; localRecord: DataType } {
    //*****************************************************************************
    //  Merge has happened somewhere else -> Overwrite
    //****************************************************************************/
    // The merge may already have happened in the sync service.
    if (patchedEvent.eventSource === 'merge') {
        const changes = getListOfDifferences(localRecord, patchedEvent.patchedRecord);
        insertChangesIntoRecord<DataType>(localRecord, changes);
        return {
            nonConflictingChanges: changes,
            conflictingChanges: [],
            localRecord,
        };
    }
    /////////////////////////////////////////////////////////////////////////////*/
    //  END Merge has happened somewhere else -> Overwrite
    /////////////////////////////////////////////////////////////////////////////*/

    return threeWayMerge({
        localRecord,
        serverShadow: patchedEvent.serverShadow,
        remoteRecord: patchedEvent.patchedRecord,

        // We can safely assume the user _wants_ a change that he made in another browser tab on this device.
        forceMergeConflictingChanges: patchedEvent.eventSource === 'localBroadcastChannel',
    });
}

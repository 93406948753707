import { Component } from '@angular/core';
import { MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';

@Component({
    selector: 'fee-set-has-changed-dialog',
    templateUrl: 'fee-set-has-changed-dialog.component.html',
    styleUrls: ['fee-set-has-changed-dialog.component.scss'],
})
export class FeeSetHasChangedDialogComponent {
    constructor(private dialog: MatDialogRef<FeeSetHasChangedDialogComponent>) {}

    public confirmRetransmission(): void {
        this.dialog.close({
            openCalculation: true,
            retransmitFeeSet: true,
        });
    }

    public openWithoutRetransmission(): void {
        this.dialog.close({
            openCalculation: true,
            retransmitFeeSet: false,
        });
    }

    public cancel(): void {
        this.dialog.close({
            openCalculation: false,
            retransmitFeeSet: false,
        });
    }
}

export interface FeeSetHasChangedDialogDecision {
    openCalculation: boolean;
    retransmitFeeSet: boolean;
}

export type FeeSetHasChangedDialogResult = FeeSetHasChangedDialogDecision | false;

<div
    id="intro-videos-panel-container"
    class="card"
    *ngIf="user?.userInterfaceStates.introVideosVisible"
    @fadeInAndSlide
    (@fadeInAndSlide.done)="handleFullPanelAnimationEnd($event)">
    <section>
        <h2 id="intro-videos-headline">Dein Start mit {{ productName }}</h2>
        <div id="intro-videos-description">Schau dir diese Videos an, um das Beste aus deinem Test herauszuholen.</div>

        <div class="card-toolbar">
            <mat-icon
                class="toolbar-icon white-icon"
                (click)="closePanel()"
                matTooltip="Videos ausblenden.{{
                    '\n\n'
                }}Du kannst sie jederzeit über das Fragezeichen oben rechts in der Kopfleiste wieder einblenden.">
                close
            </mat-icon>
        </div>
        <!--============================================-->
        <!-- Intro Videos -->
        <!--============================================-->
        <div id="videos-container" class="header-items-container">
            <!--********** Intro Video **********-->
            <div
                class="header-card video-card"
                (click)="
                    openVideo(
                        'Überblick',
                        'https://www.youtube.com/embed/FJxXNBzSBdM?si=KxJOimfOjrCkzmku?autoplay=1&rel=0'
                    )
                ">
                <div class="video-thumbnail-container">
                    <img
                        class="video-thumbnail"
                        src="/assets/images/video-thumbnails/tour-video-thumbnail.png"
                        alt="Tour Video" />
                    <img class="video-play-icon" src="/assets/images/icons/play.svg" alt="Play" />
                </div>
                <div class="video-title">Überblick</div>
                <div class="video-duration">2:08 min</div>
            </div>

            <!--********** Full Liability Video **********-->
            <div
                class="header-card video-card"
                (click)="
                    openVideo(
                        'Haftpflichtgutachten im Schnelldurchlauf',
                        'https://www.youtube.com/embed/LumGQxMi_P4?si=euBb0vl0o-hR8brU?autoplay=1&rel=0'
                    )
                ">
                <div class="video-thumbnail-container">
                    <img
                        class="video-thumbnail"
                        src="/assets/images/video-thumbnails/tour-video-thumbnail.png"
                        alt="Tour Video" />
                    <img class="video-play-icon" src="/assets/images/icons/play.svg" alt="Play" />
                </div>
                <div class="video-title">Gutachten</div>
                <div class="video-duration">3:49 min</div>
            </div>
        </div>
        <!--============================================-->
        <!-- END Intro Videos -->
        <!--============================================-->
    </section>
</div>

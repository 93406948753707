import { AxError } from '@autoixpert/models/errors/ax-error';

/**
 * Dependency Injection Token for ApiErrorService
 * Needed for shared components, as the implementation is different in the frontend and claimant-signature-forms
 */
export abstract class AbstractApiErrorService {
    public abstract handleAndRethrow({ axError, handlers, defaultHandler }: ApiErrorHandleParameters): void;
}

export interface ApiErrorHandleParameters {
    axError: AxError | AxError[];
    // An object with error codes such as "INVALID_ADDRESS" as keys and handler definitions as values.
    handlers?: { [key: string]: ApiErrorHandler | ApiErrorHandlerCreator };
    defaultHandler: ApiErrorHandler | ApiErrorHandlerCreator;
}

export type ErrorHandlerAction = (axError?: AxError) => any;

/**
 * This function allows error messages to contain extra data returned within axError.data.
 */
export type ApiErrorHandlerCreator = (axError: AxError) => ApiErrorHandler;

export interface ApiErrorHandler {
    title?: string;
    body?: string;
    /**
     * Don't show any other error in the error chain after this error handler was used.
     */
    stopReasonChain?: boolean;
    /**
     * Ignore all handlers that came before this handler in the error chain. Useful if this error is the most useful one
     * and the errors that came before are too generic.
     */
    forceDisplayAsTopHandler?: boolean;
    /**
     * Execute this function as soon as the error is handled.
     */
    action?: ErrorHandlerAction;
    /**
     * Let the notification show a countdown-style progress bar.
     */
    timeout?: number;
    /**
     * By default, all specific error handlers mark the error as handled while generic ones don't. The global Angular
     * error handler will log unhandled errors to Sentry.
     * In case a default error handler suffices and the error should not be logged to Sentry, set this to true.
     */
    forceConsiderErrorHandled?: boolean;
    partnerLogo?: ToastPartnerLogos;
    toastClick?: ErrorHandlerAction;
    toastType?: 'error' | 'warn' | 'info' | 'offline';
}

export type ToastPartnerLogos =
    | 'dat'
    | 'audatex'
    | 'gtmotive'
    | 'winvalue'
    | 'cartv'
    | 'autoonline'
    | 'dekra'
    | 'afzzert';

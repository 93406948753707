import { CdkDragDrop } from '@angular/cdk/drag-drop';
import {
    Component,
    ElementRef,
    EventEmitter,
    HostListener,
    Input,
    Output,
    QueryList,
    ViewChildren,
} from '@angular/core';
import { captureException } from '@sentry/angular';
import moment from 'moment';
import { dialogEnterAndLeaveAnimation } from '@autoixpert/animations/dialog-enter-and-leave.animation';
import { audatexCalculationConfigCodeGroups } from '@autoixpert/external-apis/audatex/audatex-calculation-config-codes';
import { isRemappingRequiredForAudatexWageRates } from '@autoixpert/external-apis/audatex/get-audatex-wage-rates';
import { isAudatexCalculationConfigCodeTransmittedAutomatically } from '@autoixpert/external-apis/audatex/is-audatex-calculation-config-code-transmitted-automatically';
import { removeFromArray } from '@autoixpert/lib/arrays/remove-from-array';
import { todayIso } from '@autoixpert/lib/date/iso-date';
import { IsoDate } from '@autoixpert/lib/date/iso-date.types';
import { isEqualAx } from '@autoixpert/lib/is-equal-ax';
import { round } from '@autoixpert/lib/numbers/round';
import { AudatexCalculationConfigCode } from '@autoixpert/models/contacts/audatex-calculation-config-code';
import { ContactPerson } from '@autoixpert/models/contacts/contact-person';
import {
    AudatexCalculationConfigCodeAndValue,
    CustomCalculationItem,
    GarageFeeSet,
    GarageFeeSetTransport,
    PaintMaterialSurcharge,
} from '@autoixpert/models/contacts/garage-fee-set';
import { WorkFractionUnit } from '@autoixpert/models/contacts/work-fraction-unit';
import { CustomFieldDropdownOption } from '@autoixpert/models/custom-fields/custom-field-dropdown-option';
import { AxError } from '@autoixpert/models/errors/ax-error';
import { Car } from '@autoixpert/models/reports/car-identification/car';
import { Team } from '@autoixpert/models/teams/team';
import { User } from '@autoixpert/models/user/user';
import { fadeInAndSlideAnimation } from '../../animations/fade-in-and-slide.animation';
import { ContactPersonService } from '../../services/contact-person.service';
import { LoggedInUserService } from '../../services/logged-in-user.service';
import { ToastService } from '../../services/toast.service';
import { UserPreferencesService } from '../../services/user-preferences.service';

@Component({
    selector: 'garage-fees',
    templateUrl: 'garage-fees.component.html',
    styleUrls: ['garage-fees.component.scss'],
    animations: [dialogEnterAndLeaveAnimation(), fadeInAndSlideAnimation()],
})
export class GarageFeesComponent {
    constructor(
        private toastService: ToastService,
        private contactPersonService: ContactPersonService,
        private loggedInUserService: LoggedInUserService,
        public userPreferences: UserPreferencesService,
    ) {}

    @Input() garageContact: ContactPerson;
    // The currently selected fee set in the parent component.
    @Input() selectedFeeSetId: GarageFeeSet['_id'];

    // Used to determine whether to display the Audatex wage rate translation note.
    @Input() car: Car;

    @Input() disabled: boolean = false;
    @Input() askToUpdateContact = false;

    @Output() close: EventEmitter<void> = new EventEmitter();
    @Output() contactPersonChange: EventEmitter<ContactPerson> = new EventEmitter();
    @Output() selectedFeeSetIdChange: EventEmitter<GarageFeeSet['_id']> = new EventEmitter();

    @ViewChildren('customCalculationItemDescriptionInput')
    customCalculationItemDescriptionInputs: QueryList<ElementRef>;

    protected user: User;
    private team: Team;

    public selectedFeeSet: GarageFeeSet;

    // Wage Levels
    public secondWageLevelShown: boolean = false;
    public thirdWageLevelShown: boolean = false;

    // Audatex Config Codes
    public possibleAudatexConfigCodeGroups = audatexCalculationConfigCodeGroups;

    // Track changes
    private sourceFeeSets: GarageFeeSet[] = [];
    public existingGarageContact: ContactPerson;
    private validFromDateChanges = new Map<
        GarageFeeSet['_id'],
        { formerDate: IsoDate; showIcon: boolean; preventAutomaticReset: boolean }
    >();

    ngOnInit() {
        this.user = this.loggedInUserService.getUser();
        this.team = this.loggedInUserService.getTeam();

        // A new record does not have a garageContact fee set, so...
        if (!this.garageContact.garageFeeSets?.length) {
            this.initializeFeeSet();
        }

        if (this.selectedFeeSetId) {
            this.selectFeeSetById(this.selectedFeeSetId);
        } else {
            this.selectFeeSet(this.garageContact.garageFeeSets[0]);
        }

        this.sourceFeeSets = JSON.parse(JSON.stringify(this.garageContact.garageFeeSets)) ?? [];

        if (this.askToUpdateContact) {
            // Load existing garage contact to allow synchronous close
            this.loadExistingGarageContact();
        }
    }

    //*****************************************************************************
    //  Track changes
    //****************************************************************************/

    public getChangedFeeSets(): { id: GarageFeeSet['_id']; feeSet?: GarageFeeSet }[] {
        const changedFeeSets: { id: GarageFeeSet['_id']; feeSet?: GarageFeeSet }[] = [];
        if (!this.sourceFeeSets) return [];

        // Add modified and deleted feeSets
        this.sourceFeeSets.forEach((sourceFeeSet) => {
            const currentFeeSet = this.garageContact.garageFeeSets.find((feeSet) => feeSet._id === sourceFeeSet._id);
            if (currentFeeSet && !isEqualAx(sourceFeeSet, currentFeeSet)) {
                // modified fee sets
                changedFeeSets.push({ id: sourceFeeSet._id, feeSet: currentFeeSet });
            } else if (!currentFeeSet) {
                // deleted fee sets
                changedFeeSets.push({ id: sourceFeeSet._id });
            }
        });

        // Add new feeSets
        this.garageContact.garageFeeSets.forEach((currentFeeSet) => {
            if (!this.sourceFeeSets.some((feeSet) => currentFeeSet._id === feeSet._id)) {
                changedFeeSets.push({ id: currentFeeSet._id, feeSet: currentFeeSet });
            }
        });
        return changedFeeSets;
    }

    private async loadExistingGarageContact() {
        const query = {
            // If any of the properties isn't set within the report, let duplicates be found if their field value is either null or empty string.
            organizationType: this.garageContact.organizationType,
            organization: this.garageContact.organization || { $in: [null, ''] },
            zip: this.garageContact.zip || { $in: [null, ''] },
            streetAndHouseNumberOrLockbox: this.garageContact.streetAndHouseNumberOrLockbox || { $in: [null, ''] },
            firstName: this.garageContact.firstName || { $in: [null, ''] },
            lastName: this.garageContact.lastName || { $in: [null, ''] },
        };

        const possibleGarageContacts = (await this.contactPersonService.find(query).toPromise()) ?? [];
        this.existingGarageContact = possibleGarageContacts[0];
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Track changes
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Reset Valid From Date
    //****************************************************************************/
    public setValidFromDateToToday() {
        // When opening a new garage fee set, there may not be any fee sets at first.
        if (!this.selectedFeeSet) return;

        // Do date reset only once
        if (this.validFromDateChanges.get(this.selectedFeeSet._id)?.preventAutomaticReset) {
            return;
        }

        // Do date reset only if values were changed
        const sourceFeeSet = this.sourceFeeSets.find((feeSet) => feeSet._id === this.selectedFeeSet._id);
        if (!sourceFeeSet || isEqualAx(sourceFeeSet, this.selectedFeeSet)) {
            return;
        }

        // Do date reset only if date was not same date
        if (moment(this.selectedFeeSet.validFrom).isSame(moment(), 'date')) {
            this.validFromDateChanges.set(this.selectedFeeSet._id, {
                formerDate: sourceFeeSet.validFrom,
                showIcon: false,
                preventAutomaticReset: true,
            });
            return;
        }

        this.selectedFeeSet.validFrom = todayIso();
        this.validFromDateChanges.set(this.selectedFeeSet._id, {
            formerDate: sourceFeeSet.validFrom,
            showIcon: true,
            preventAutomaticReset: true,
        });
    }

    public isIconForValidFromDateVisible() {
        return !!this.validFromDateChanges.get(this.selectedFeeSet._id)?.showIcon;
    }

    public getValidFromDateFormerValue() {
        return this.validFromDateChanges.get(this.selectedFeeSet._id)?.formerDate;
    }

    /**
     * If user sets the date value back to former value,
     * we must not automatically override it anymore.
     */
    public setValidFromDateToFormerValue() {
        const formerDate = this.getValidFromDateFormerValue();
        if (formerDate) {
            this.selectedFeeSet.validFrom = formerDate;
            this.validFromDateChanges.set(this.selectedFeeSet._id, {
                formerDate: null,
                showIcon: false,
                preventAutomaticReset: true,
            });
            this.emitDataChange();
        }
    }

    /**
     * If user sets the date value manually,
     * we must not automatically override it anymore.
     */
    public handleValidFromDateUserChange() {
        this.validFromDateChanges.set(this.selectedFeeSet._id, {
            formerDate: null,
            showIcon: false,
            preventAutomaticReset: true,
        });
        this.emitDataChange();
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Reset Valid From Date
    /////////////////////////////////////////////////////////////////////////////*/

    ////*****************************************************************************
    ////  Garage Header Data
    ////****************************************************************************/
    ///**
    // * What brands does this garage service?
    // * @param brand
    // */
    //public addBrand(brand: string) {
    //    if (!this.garageContact.garageBrands) {
    //        this.garageContact.garageBrands = [];
    //    }
    //    this.garageContact.garageBrands.push(brand);
    //    this.emitDataChange();
    //}
    //
    ///////////////////////////////////////////////////////////////////////////////*/
    ////  END Garage Header Data
    ///////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Fee Set List
    //****************************************************************************/
    public selectFeeSet(feeSet: GarageFeeSet) {
        if (!feeSet) {
            const axError = new AxError({
                code: 'NO_FEE_SET_IN_GARAGE_FEES_COMPONENT',
                message: 'No feeSet available in garage-fees component',
                data: {
                    feeSet,
                    garageContact: this.garageContact,
                },
            });
            captureException(axError);
            return;
        }

        this.selectedFeeSet = feeSet;
        this.selectedFeeSetIdChange.emit(feeSet._id);

        /**
         * Show the additional levels if they contain values. If the values are already displayed, do not hide them. This ensures that the user interface does not "jump" when switching garage fees.
         * This keeps information present if needed and keeps the interface clean it if the additional fields are not relevant. In practice, the second and third level are rarely used.
         */
        const secondLevelHasData =
            this.selectedFeeSet.mechanics.secondLevel != null ||
            this.selectedFeeSet.electrics.secondLevel != null ||
            this.selectedFeeSet.carBody.secondLevel != null;
        const thirdLevelHasData =
            this.selectedFeeSet.mechanics.thirdLevel != null ||
            this.selectedFeeSet.electrics.thirdLevel != null ||
            this.selectedFeeSet.carBody.thirdLevel != null;
        // Show the second level if the third must be displayed, too. Otherwise, the user interface looks like it has a hole.
        this.secondWageLevelShown = this.secondWageLevelShown || secondLevelHasData || thirdLevelHasData;
        this.thirdWageLevelShown = this.thirdWageLevelShown || thirdLevelHasData;
    }

    private selectFeeSetById(feeSetId: GarageFeeSet['_id']) {
        const feeSet = this.garageContact.garageFeeSets.find((feeSet) => feeSet._id === feeSetId);
        this.selectFeeSet(feeSet);
    }

    public markFeeSetAsFavorite(feeSet: GarageFeeSet) {
        // Unmark all others.
        this.garageContact.garageFeeSets.forEach((feeSet) => (feeSet.isDefault = false));

        feeSet.isDefault = true;

        this.emitDataChange();
    }

    /**
     * Create the first fee set and mark it as default.
     */
    public initializeFeeSet(): void {
        this.garageContact.garageFeeSets = [];
        this.garageContact.garageFeeSets.push(
            new GarageFeeSet({
                title: 'Standardsätze',
                isDefault: true,
            }),
        );
        this.emitDataChange();
    }

    public createFeeSet() {
        if (this.disabled) return;

        const newFeeSet = new GarageFeeSet();
        this.garageContact.garageFeeSets.push(newFeeSet);
        this.selectFeeSet(newFeeSet);

        this.emitDataChange();
    }

    public copyFeeSet(feeSet: GarageFeeSet) {
        if (this.disabled) return;

        const copy: GarageFeeSet = JSON.parse(JSON.stringify(feeSet));

        delete copy._id;

        const newFeeSet: GarageFeeSet = new GarageFeeSet({
            ...copy,
            title: `${copy.title} (Kopie)`,
            isDefault: false,
        });
        this.garageContact.garageFeeSets.push(newFeeSet);
        this.selectFeeSet(newFeeSet);

        this.emitDataChange();
    }

    /**
     * Remove fee set. If this was the last one, initialize a new one.
     */
    public deleteFeeSet(feeSet: GarageFeeSet) {
        if (this.disabled) return;
        removeFromArray(feeSet, this.garageContact.garageFeeSets);

        // Never leave the list empty.
        if (this.garageContact.garageFeeSets.length === 0) {
            this.initializeFeeSet();
            this.toastService.info(
                'Leere Kostensätze angelegt',
                'Die alten Sätze wurden gelöscht, aber es wurde eine neue Datenstruktur angelegt.',
            );
        }

        // Never leave the list without a default.
        if (!this.garageContact.garageFeeSets.some((feeSet) => feeSet.isDefault)) {
            this.markFeeSetAsFavorite(this.garageContact.garageFeeSets[0]);
        }

        // If the deleted fee set was the selected on, select another.
        if (this.selectedFeeSet === feeSet) {
            this.selectFeeSet(this.garageContact.garageFeeSets[0]);
        }

        this.emitDataChange();
    }

    //*****************************************************************************
    //  Drag & Drop
    //****************************************************************************/
    public reorderGarageFeeSets(event: CdkDragDrop<CustomFieldDropdownOption[]>): void {
        // Shorthand
        const garageFeeSets: GarageFeeSet[] = this.garageContact.garageFeeSets;

        const movedLineItem = garageFeeSets.splice(event.previousIndex, 1)[0];
        // Add the item back at the new position
        garageFeeSets.splice(event.currentIndex, 0, movedLineItem);

        // Save the new order back to the server.
        this.emitDataChange();
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Drag & Drop
    /////////////////////////////////////////////////////////////////////////////*/

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Fee Set List
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Fee Set Editor Column
    //****************************************************************************/

    //*****************************************************************************
    //  Work Fraction Units
    //****************************************************************************/
    /**
     * Convert the fee set to reflect a different work fraction unit.
     *
     * First normalize the fee set and then apply the final work fraction unit.
     * This accounts for previous conversions, i. e. the fee set was already in
     * the state of e.g. 100 work fraction units.
     */
    public selectWorkFractionUnit(newWorkFractionUnit: WorkFractionUnit): void {
        if (this.disabled) return;

        // Bring the fee set back to normal.
        // Example: To convert the value of one AW (base 10) back to an hourly rate, multiply it by ten.
        this.multiplyFactorToFeeSet(this.selectedFeeSet, this.selectedFeeSet.selectedWorkFractionUnit);

        // Apply the divisor that the user just clicked. In order to divide, multiply the reciprocal value.
        this.multiplyFactorToFeeSet(this.selectedFeeSet, 1 / newWorkFractionUnit);

        this.selectedFeeSet.selectedWorkFractionUnit = newWorkFractionUnit;

        this.emitDataChange();
    }

    /**
     * Multiply every wage value of the fee set by a given value.
     */
    private multiplyFactorToFeeSet(feeSet: GarageFeeSet, factor: number): void {
        // Round the values so JavaScript rounding errors (180.00000000000002 instead of 180.00) are prevented.
        feeSet.mechanics.firstLevel = round((feeSet.mechanics.firstLevel ?? null) * factor);
        feeSet.mechanics.secondLevel = round((feeSet.mechanics.secondLevel ?? null) * factor);
        feeSet.mechanics.thirdLevel = round((feeSet.mechanics.thirdLevel ?? null) * factor);
        feeSet.electrics.firstLevel = round((feeSet.electrics.firstLevel ?? null) * factor);
        feeSet.electrics.secondLevel = round((feeSet.electrics.secondLevel ?? null) * factor);
        feeSet.electrics.thirdLevel = round((feeSet.electrics.thirdLevel ?? null) * factor);
        feeSet.carBody.firstLevel = round((feeSet.carBody.firstLevel ?? null) * factor);
        feeSet.carBody.secondLevel = round((feeSet.carBody.secondLevel ?? null) * factor);
        feeSet.carBody.thirdLevel = round((feeSet.carBody.thirdLevel ?? null) * factor);
        feeSet.carPaint.wage = round((feeSet.carPaint.wage ?? null) * factor);
        feeSet.dentsWage = round((feeSet.dentsWage ?? null) * factor);
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Work Fraction Units
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Wage Levels
    //****************************************************************************/
    /**
     * Add a wage level for mechanics, electrics and car body.
     */
    public showNextWageLevel() {
        if (this.disabled) return;

        if (!this.secondWageLevelShown) {
            this.secondWageLevelShown = true;
            return;
        }

        if (!this.thirdWageLevelShown) {
            this.thirdWageLevelShown = true;
        }
    }

    /**
     * Hide and clear the highest visible wage level of mechanics, electrics and car body.
     */
    public hideLastWageLevel() {
        if (this.thirdWageLevelShown) {
            this.thirdWageLevelShown = false;
            this.selectedFeeSet.mechanics.thirdLevel = null;
            this.selectedFeeSet.electrics.thirdLevel = null;
            this.selectedFeeSet.carBody.thirdLevel = null;
            this.emitDataChange();
            return;
        }

        if (this.secondWageLevelShown) {
            this.secondWageLevelShown = false;
            this.selectedFeeSet.mechanics.secondLevel = null;
            this.selectedFeeSet.electrics.secondLevel = null;
            this.selectedFeeSet.carBody.secondLevel = null;
            this.emitDataChange();
        }
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Wage Levels
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Transport
    //****************************************************************************/

    public selectTransportCalculationType(calculationType: GarageFeeSetTransport['calculationType']) {
        if (this.disabled) return;

        this.selectedFeeSet.transport.calculationType = calculationType;
        this.emitDataChange();
    }

    public getTransportCalculationTypeCategory(): 'none' | 'fixedPrice' | 'onTimeBasis' {
        switch (this.selectedFeeSet.transport.calculationType) {
            case 'none':
                return 'none';
            case 'fixedPrice':
                return 'fixedPrice';
            case 'mechanics':
            case 'electrics':
            case 'carBody':
                return 'onTimeBasis';
        }
    }

    public getTransportCosts(): number {
        // If the user has not yet selected an hourly rate, return 0
        if (!this.selectedFeeSet.transport.calculationType) {
            return 0;
        }

        if (this.selectedFeeSet.transport.calculationType === 'none') {
            return 0;
        }

        if (this.selectedFeeSet.transport.calculationType === 'fixedPrice') {
            return this.selectedFeeSet.transport.fixedPrice;
        }

        const hourlyRate = round(
            this.selectedFeeSet[this.selectedFeeSet.transport.calculationType].firstLevel *
                this.selectedFeeSet.selectedWorkFractionUnit,
        );
        return this.selectedFeeSet.transport.timeRequired * hourlyRate;
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Transport
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Paint Wage
    //****************************************************************************/
    public selectPaintSystem(paintSystem: GarageFeeSet['carPaint']['paintSystem']) {
        if (this.disabled) return;

        this.selectedFeeSet.carPaint.paintSystem = paintSystem;

        // AZT allows neither material points nor percentages.
        if (paintSystem === 'allianzCenterForTechnology') {
            if (this.selectedFeeSet.carPaint.materialSurchargeUnit === 'materialPointsOrUnits') {
                this.selectPaintMaterialSurchargeUnit('materialIndex');
            }
        }

        // The Manufacturer paint system doesn't support materialindex.
        if (paintSystem === 'manufacturer' && this.selectedFeeSet.carPaint.materialSurchargeUnit === 'materialIndex') {
            this.selectPaintMaterialSurchargeUnit('percent');
        }

        this.emitDataChange();
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Paint Wage
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Paint Material
    //****************************************************************************/
    public selectPaintMaterialSurchargeUnit(surchargeUnit: GarageFeeSet['carPaint']['materialSurchargeUnit']) {
        if (this.disabled) return;

        if (this.selectedFeeSet.carPaint.materialSurchargeUnit === surchargeUnit) return;

        if (
            this.selectedFeeSet.carPaint.paintSystem === 'allianzCenterForTechnology' &&
            surchargeUnit === 'materialPointsOrUnits'
        ) {
            this.toastService.warn(
                'Materialpunkte für AZT nicht verfügbar',
                'Aufschläge können nur in den übrigen Einheiten angegeben werden.',
            );
            return;
        }

        this.selectedFeeSet.carPaint.materialSurchargeUnit = surchargeUnit;

        //*****************************************************************************
        //  Default Values
        //****************************************************************************/
        // Only overwrite if out of range.
        // shorthand
        let value = this.selectedFeeSet.carPaint.materialSurchargeDefault;
        switch (surchargeUnit) {
            case 'percent':
                if (!value || value > 80) {
                    value = 0;
                }
                break;
            case 'materialIndex':
                if (!value || value < 80) {
                    value = 100;
                }
                break;
            case 'materialPointsOrUnits':
                if (!value || value > 50) {
                    value = null;
                }
                break;
            case 'flatFee':
                break;
        }
        this.selectedFeeSet.carPaint.materialSurchargeDefault = value;
        /////////////////////////////////////////////////////////////////////////////*/
        //  END Default Values
        /////////////////////////////////////////////////////////////////////////////*/

        this.emitDataChange();
    }

    public getSuffixForMaterialSurchargeUnit(): string {
        switch (this.selectedFeeSet.carPaint.materialSurchargeUnit) {
            case 'percent':
            case 'materialIndex':
            case null:
            default:
                return '%';
            case 'materialPointsOrUnits':
                if (this.selectedFeeSet.carPaint.paintSystem === 'manufacturer') {
                    return '€/ME';
                }
                return '€/MP';
            case 'flatFee':
                return '€';
        }
    }

    public addMaterialSurchargeForPaintType() {
        if (this.disabled) return;

        // There are only three options, so don't allow adding more rows than that.
        if (this.selectedFeeSet.carPaint.materialSurchargesByPaintType.length >= 3) {
            this.toastService.info('Bereits 3 Lackarten konfiguriert', 'Du kannst die vorhandenen Zeilen nutzen.');
            return;
        }

        this.selectedFeeSet.carPaint.materialSurchargesByPaintType.push(
            new PaintMaterialSurcharge({
                paintType: 'uni',
            }),
        );
        this.emitDataChange();
    }

    public removeMaterialSurchargeForPaintType(materialSurcharge: PaintMaterialSurcharge) {
        removeFromArray(materialSurcharge, this.selectedFeeSet.carPaint.materialSurchargesByPaintType);
        this.emitDataChange();
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Paint Material
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Custom Calculation Items
    //****************************************************************************/
    public initializeCustomCalculationItems() {
        if (this.disabled) return;

        this.selectedFeeSet.customCalculationItems = [new CustomCalculationItem()];
        this.emitDataChange();
    }

    public hideCustomCalculationItems() {
        this.selectedFeeSet.customCalculationItems = null;
        this.emitDataChange();
    }

    public addCustomCalculationItem() {
        this.selectedFeeSet.customCalculationItems.push(new CustomCalculationItem());
        this.emitDataChange();

        // Wait for the new row (including the description input field) to be rendered, then focus it
        setTimeout(() => {
            this.customCalculationItemDescriptionInputs.last.nativeElement.focus();
        }, 0);
    }

    public removeCustomCalculationItem(customCalculationItem: CustomCalculationItem) {
        removeFromArray(customCalculationItem, this.selectedFeeSet.customCalculationItems);

        if (!this.selectedFeeSet.customCalculationItems.length) {
            this.hideCustomCalculationItems();
        }

        this.emitDataChange();
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Custom Calculation Items
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Audatex Configuration Codes
    //****************************************************************************/
    public initializeAudatexConfigCodes() {
        if (this.disabled) return;

        this.selectedFeeSet.audatexConfigCodes = [new AudatexCalculationConfigCodeAndValue()];
        this.emitDataChange();
    }

    public hideAudatexConfigCodes() {
        this.selectedFeeSet.audatexConfigCodes = null;
        this.emitDataChange();
    }

    public addAudatexCalculationConfigurationCode() {
        this.selectedFeeSet.audatexConfigCodes.push(new AudatexCalculationConfigCodeAndValue());
        this.emitDataChange();
    }

    public removeAudatexCalculationConfigurationCode(
        audatexCalculationConfigCode: AudatexCalculationConfigCodeAndValue,
    ) {
        removeFromArray(audatexCalculationConfigCode, this.selectedFeeSet.audatexConfigCodes);

        if (!this.selectedFeeSet.audatexConfigCodes.length) {
            this.hideAudatexConfigCodes();
        }

        this.emitDataChange();
    }

    /**
     * Set an element (= row) of the Audatex config code list to the selected option.
     */
    public selectConfigCode(
        audatexConfigCodeRow: AudatexCalculationConfigCodeAndValue,
        sourceConfigCode: AudatexCalculationConfigCode,
    ) {
        // Prevent selection of RF codes that autoiXpert already transmits automatically.
        if (isAudatexCalculationConfigCodeTransmittedAutomatically(sourceConfigCode.numericalCode)) {
            this.toastService.info(
                'RF-Code wird automatisch übertragen',
                'Deshalb kannst du ihn nicht auswählen. Falls du den Wert ändern möchtest, nutze das dafür vorgesehene Feld weiter oben.',
            );
            return;
        }

        // Prevent duplicates
        if (this.configCodeExistsAlreadyInOtherRow(audatexConfigCodeRow)) {
            this.toastService.info('Bereits vorhanden', 'Diesen Code gibt es bereits.');
        }

        audatexConfigCodeRow.numericalCode = sourceConfigCode.numericalCode;
        audatexConfigCodeRow.title = sourceConfigCode.title;
        audatexConfigCodeRow.valueType = sourceConfigCode.valueType;
        this.emitDataChange();
    }

    public configCodeExistsAlreadyInOtherRow(configCode: AudatexCalculationConfigCode): boolean {
        const otherConfigCodes = this.selectedFeeSet.audatexConfigCodes.filter(
            (existingConfigCode) => existingConfigCode !== configCode,
        );

        return !!otherConfigCodes.find((otherConfigCode) => otherConfigCode.numericalCode === configCode.numericalCode);
    }

    public isAudatexUser(): boolean {
        return this.team.audatexFeatures.hasAudatexAddon;
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Audatex Configuration Codes
    /////////////////////////////////////////////////////////////////////////////*/

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Fee Set Editor Column
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Plausibility Check
    //****************************************************************************/
    /**
     * A fee set is implausible if at least one of the values exceeds the order of
     * magnitude common for a work fraction unit.
     */
    public areGarageFeesImplausiblyHigh(): boolean {
        switch (this.selectedFeeSet.selectedWorkFractionUnit) {
            case 1:
                return this.doesAnyValueInFeeSetPassThreshold(500, 'exceed');
            case 10:
            case 12:
                return this.doesAnyValueInFeeSetPassThreshold(50, 'exceed');
            case 100:
                return this.doesAnyValueInFeeSetPassThreshold(5, 'exceed');
            default:
                // No valid work fraction unit -> no plausibility check
                return false;
        }
    }

    public areGarageFeesImplausiblyLow(): boolean {
        switch (this.selectedFeeSet.selectedWorkFractionUnit) {
            case 1:
                return this.doesAnyValueInFeeSetPassThreshold(30, 'fallShort');
            case 10:
            case 12:
                return this.doesAnyValueInFeeSetPassThreshold(5, 'fallShort');
            case 100:
                return this.doesAnyValueInFeeSetPassThreshold(0.5, 'fallShort');
            default:
                // No valid work fraction unit -> no plausibility check
                return false;
        }
    }

    private doesAnyValueInFeeSetPassThreshold(threshold: number, direction: 'exceed' | 'fallShort'): boolean {
        const feeSet = this.selectedFeeSet;
        const valuesToCompare: number[] = [
            feeSet.mechanics.firstLevel,
            feeSet.mechanics.secondLevel,
            feeSet.mechanics.thirdLevel,
            feeSet.electrics.firstLevel,
            feeSet.electrics.secondLevel,
            feeSet.electrics.thirdLevel,
            feeSet.carBody.firstLevel,
            feeSet.carBody.secondLevel,
            feeSet.carBody.thirdLevel,
            feeSet.carPaint.wage,
            feeSet.dentsWage,
        ];

        if (direction === 'exceed') {
            return valuesToCompare.some((value) => value > threshold);
        }
        if (direction === 'fallShort') {
            return valuesToCompare.some((value) => value && value < threshold);
        }
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Plausibility Check
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Events
    //****************************************************************************/
    public emitDataChange(): void {
        // If data is changed, we update the valid from date automatically to today's date
        this.setValidFromDateToToday();
        this.contactPersonChange.emit(this.garageContact);
    }

    /**
     * Close garage fee dialog with syncing changes to contact management.
     */

    public async closeGarageFeesDialogWithSyncing() {
        if (!this.askToUpdateContact || !this.sourceFeeSets || !this.existingGarageContact) {
            this.close.emit();
            return;
        }

        const changedFeeSets = this.getChangedFeeSets();
        if (changedFeeSets.length < 1) {
            this.close.emit();
            return;
        }

        // We assume that if a fee set has been updated, the brands and characteristics are up-to-date as well.
        const mergedContactPerson: ContactPerson = Object.assign<ContactPerson, Partial<ContactPerson>>(
            this.existingGarageContact,
            {
                garageBrands: this.garageContact.garageBrands,
                isBrandCertified: this.garageContact.isBrandCertified,
                garageCharacteristics: this.garageContact.garageCharacteristics,
            },
        );
        // Update only changed fee sets
        changedFeeSets.forEach((changedFeeSet) => {
            const index = mergedContactPerson.garageFeeSets.findIndex((feeSet) => feeSet._id === changedFeeSet.id);
            if (index >= 0) {
                if (changedFeeSet.feeSet) {
                    // Update modified fee set
                    mergedContactPerson.garageFeeSets[index] = changedFeeSet.feeSet;
                } else {
                    // Delete removed fee set
                    mergedContactPerson.garageFeeSets.splice(index, 1);
                }
            } else {
                // Add new fee set
                mergedContactPerson.garageFeeSets.push(changedFeeSet.feeSet);
            }
        });
        // Display errors async to allow UI to respond fast
        this.contactPersonService.put(mergedContactPerson).catch((error) => {
            console.error(error);
            this.toastService.error(
                'Werkstatt nicht aktualisiert',
                `Die Kostensätze konnten nicht für die Werkstatt übernommen werden. Bitte aktualisiere die Kostensätze nochmal im <a href="/Kontakte">Kontaktmanagement</a>`,
            );
        });
        this.close.emit();
    }

    /**
     * Close garage fee dialog without syncing changes to contact management.
     */
    public async closeGarageFeesDialog() {
        this.close.emit();
    }

    public handleOverlayClick(event: MouseEvent): void {
        // Only close editor if the overlay has been clicked directly. Ignore bubbling events from the dialog.
        if (event.target === event.currentTarget) {
            this.closeGarageFeesDialog();
        }
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Events
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Keyboard Shortcuts
    //****************************************************************************/
    @HostListener('window:keydown', ['$event'])
    public closeEditorOnEscKey(event) {
        if (event.key === 'Escape') {
            // Make sure saving is triggered if the user is still inside an input.
            if (document.activeElement.nodeName === 'INPUT' || document.activeElement.nodeName === 'TEXTAREA') {
                (document.activeElement as HTMLElement).blur();
            }
            this.closeGarageFeesDialog();
        }
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Keyboard Shortcuts
    /////////////////////////////////////////////////////////////////////////////*/
    isAudatexCalculationConfigCodeTransmittedAutomatically = isAudatexCalculationConfigCodeTransmittedAutomatically;
    protected readonly isRemappingRequiredForAudatexWageRates = isRemappingRequiredForAudatexWageRates;
}

import { RouterModule, Routes } from '@angular/router';
import { AccessRights } from '@autoixpert/models/user/user';
import { AccessRightGuard } from '../shared/services/access-right-guard.service';
import { AuthGuard } from '../shared/services/auth-guard.service';
import { AnalyticsListComponent } from './analytics-list/analytics-list.component';
import { AnalyticsComponent } from './analytics.component';
import { AssessorsFeeComponent } from './assessors-fee/assessors-fee.component';
import { DamageTotalComponent } from './damage-total/damage-total.component';
import { IntermediariesComponent } from './intermediaries/intermediaries.component';
import { RevenueByReportTypesComponent } from './report-types/revenue-by-report-types.component';
import { RevenueByAssessorComponent } from './revenue-by-assessor/revenue-by-assessor.component';
import { RevenueByCarManufacturerComponent } from './revenue-by-car-manufacturer/revenue-by-car-manufacturer.component';
import { RevenueByCityComponent } from './revenue-by-city/revenue-by-city.component';
import { RevenueByCustomerComponent } from './revenue-by-customer/revenue-by-customer.component';
import { RevenueByGarageComponent } from './revenue-by-garage/revenue-by-garage.component';
import { RevenueByInsuranceComponent } from './revenue-by-insurance/revenue-by-insurance.component';
import { RevenueByInvoiceItemComponent } from './revenue-by-invoice-item/revenue-by-invoice-item.component';
import { RevenueByLawyerComponent } from './revenue-by-lawyer/revenue-by-lawyer.component';
import { RevenueByMonthComponent } from './revenue-by-month/revenue-by-month.component';
import { ShortPaymentPayersComponent } from './short-payments/short-payment-payers/short-payment-payers.component';
import { ShortPaymentQuotasComponent } from './short-payments/short-payment-quotas/short-payment-quotas.component';
import { ShortPaymentReasonsComponent } from './short-payments/short-payment-reasons/short-payment-reasons.component';
import { ShortPaymentResolutionsComponent } from './short-payments/short-payment-resolutions/short-payment-resolutions.component';
import { ShortPaymentsStatusComponent } from './short-payments/short-payment-status/short-payments-status.component';

const reportingRoutes: Routes = [
    {
        path: 'Auswertungen',
        component: AnalyticsComponent,
        canActivate: [AuthGuard],
        children: [
            {
                path: '',
                component: AnalyticsListComponent,
                pathMatch: 'full',
                data: {
                    requiredAccessRight: 'reporting',
                } satisfies { requiredAccessRight: keyof AccessRights },
                canActivate: [AccessRightGuard],
            },
            {
                path: 'Schadenhöhe',
                component: DamageTotalComponent,
            },
            {
                path: 'Umsatz-pro-Monat',
                component: RevenueByMonthComponent,
            },
            {
                path: 'Umsatz-pro-Sachverständigem',
                component: RevenueByAssessorComponent,
            },
            {
                path: 'Umsatz-pro-Vermittler',
                component: IntermediariesComponent,
            },
            {
                path: 'Honorar-pro-Sachverständigem',
                component: AssessorsFeeComponent,
            },
            {
                path: 'Umsatz-nach-Ort',
                component: RevenueByCityComponent,
            },
            {
                path: 'Umsatz-nach-Gutachtentyp',
                component: RevenueByReportTypesComponent,
            },
            {
                path: 'Umsatz-pro-Kunde',
                component: RevenueByCustomerComponent,
            },
            {
                path: 'Umsatz-pro-Fahrzeughersteller',
                component: RevenueByCarManufacturerComponent,
            },
            {
                path: 'Umsatz-pro-Versicherung',
                component: RevenueByInsuranceComponent,
            },
            {
                path: 'Umsatz-pro-Rechnungsposition',
                component: RevenueByInvoiceItemComponent,
            },
            //*****************************************************************************
            //  Short Payments
            //****************************************************************************/
            {
                path: 'Kürzungsquoten',
                component: ShortPaymentQuotasComponent,
            },
            {
                path: 'Kürzungsstatus',
                component: ShortPaymentsStatusComponent,
            },
            {
                path: 'Kürzungsgründe',
                component: ShortPaymentReasonsComponent,
            },
            {
                path: 'Kürzungen-Lösungen',
                component: ShortPaymentResolutionsComponent,
            },
            {
                path: 'Kürzungen-Zahler',
                component: ShortPaymentPayersComponent,
            },
            /////////////////////////////////////////////////////////////////////////////*/
            //  END Short Payments
            /////////////////////////////////////////////////////////////////////////////*/
            {
                path: 'Umsatz-pro-Werkstatt',
                component: RevenueByGarageComponent,
            },
            {
                path: 'Umsatz-pro-Anwalt',
                component: RevenueByLawyerComponent,
            },
        ],
    },
];

export const analyticsRouting = RouterModule.forChild(reportingRoutes);

import { ReportData } from '../../models/invoices/report-data';
import { Report } from '../../models/reports/report';

export function getInvoiceReportData({ report }: { report: Report }): ReportData {
    return {
        token: report.token || null,
        licensePlate: report.car?.licensePlate || null,
        lawyerCaseNumber: report.lawyer?.caseNumber,
        insuranceCaseNumber: report.insurance?.caseNumber,
        insuranceContractNumber: report.authorOfDamage?.insuranceNumber,
        dateOfAccident: report.accident?.date,
        carMake: report.car?.make,
        carModel: report.car?.model,
        type: report.type,
    };
}

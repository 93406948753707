import { AfterViewInit, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import {
    MatLegacyAutocomplete as MatAutocomplete,
    MatLegacyAutocompleteSelectedEvent as MatAutocompleteSelectedEvent,
    MatLegacyAutocompleteTrigger as MatAutocompleteTrigger,
} from '@angular/material/legacy-autocomplete';
import { MatLegacyChipInputEvent as MatChipInputEvent } from '@angular/material/legacy-chips';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { MatMenuTrigger } from '@angular/material/menu';
import { ActivatedRoute, Router } from '@angular/router';
import { Subject, Subscription } from 'rxjs';
import {
    doesVehicleHaveBatteryElectricEngine,
    doesVehicleHaveInternalCombustionEngine,
    doesVehicleShapeHaveEngine,
} from '@autoixpert/lib/car/get-engine-type';
import { BatteryDamageGerman } from '@autoixpert/lib/placeholder-values/translator';
import { getAllTiresOfVehicle } from '@autoixpert/lib/tires/get-all-tires-of-vehicle';
import { isTireInfoComplete } from '@autoixpert/lib/tires/is-tire-info-complete';
import { Axle } from '@autoixpert/models/reports/car-identification/axle';
import { Car, CarShape } from '@autoixpert/models/reports/car-identification/car';
import { SecondTire, Tire } from '@autoixpert/models/reports/car-identification/tire';
import { Damage } from '@autoixpert/models/reports/damage-description/damage';
import { Report } from '@autoixpert/models/reports/report';
import { Team } from '@autoixpert/models/teams/team';
import { CustomAutocompleteEntry } from '@autoixpert/models/text-templates/custom-autocomplete-entry';
import { User } from '@autoixpert/models/user/user';
import {
    DescriptionFromRepairCalculationDialogComponent,
    DescriptionFromRepairCalculationDialogResult,
} from 'src/app/shared/components/description-from-repair-calculation-dialog/description-from-repair-calculation-dialog.component';
import { MatQuillComponent } from 'src/app/shared/components/mat-quill/mat-quill.component';
import { generateDamageDescriptionFromCalculation } from 'src/app/shared/libraries/damage-calculation/translate-damaged-parts';
import { blockChildAnimationOnLoad } from '../../../shared/animations/block-child-animation-on-load.animation';
import { fadeInAndOutAnimation } from '../../../shared/animations/fade-in-and-out.animation';
import { fadeInAndSlideAnimation } from '../../../shared/animations/fade-in-and-slide.animation';
import { runChildAnimations } from '../../../shared/animations/run-child-animations.animation';
import { getCarContourImageName } from '../../../shared/libraries/car/get-car-contour-image-name';
import { getCarTireImageName } from '../../../shared/libraries/car/get-car-tire-image-name';
import { isAxleConfigurationRequired } from '../../../shared/libraries/car/is-axle-configuration-required';
import { isBicycleOrPedelec } from '../../../shared/libraries/car/is-bicycle-or-pedelec';
import { isUtilityVehicle } from '../../../shared/libraries/car/is-utility-vehicle';
import { getMissingAccessRightTooltip } from '../../../shared/libraries/get-missing-access-right-tooltip';
import { scrollToTheBottom } from '../../../shared/libraries/scroll-to-the-bottom';
import { triggerClickEventOnSpaceBarPress } from '../../../shared/libraries/trigger-click-event-on-space-bar-press';
import { hasAccessRight } from '../../../shared/libraries/user/has-access-right';
import { ApiErrorService } from '../../../shared/services/api-error.service';
import { CustomAutocompleteEntriesService } from '../../../shared/services/custom-autocomplete-entries.service';
import { LoggedInUserService } from '../../../shared/services/logged-in-user.service';
import { ReportDetailsService } from '../../../shared/services/report-details.service';
import { ReportRealtimeEditorService } from '../../../shared/services/report-realtime-editor.service';
import { TeamService } from '../../../shared/services/team.service';
import { ToastService } from '../../../shared/services/toast.service';
import { UserPreferencesService } from '../../../shared/services/user-preferences.service';
import { sedanDamages } from '../../../shared/static-data/damages/sedan-damages';
import { CAR_REGISTRATION_SCAN_PHOTO_TITLE } from '../../../shared/static-data/default-photo-titles';
import { hasPaintThicknessMeasurement } from './paint-thickness/has-paint-thickness-measurement';
import { isPaintThicknessMeasurementComplete } from './paint-thickness/is-paint-thickness-measurement-complete';

@Component({
    selector: 'car-condition',
    templateUrl: 'car-condition.component.html',
    styleUrls: ['car-condition.component.scss'],
    animations: [fadeInAndOutAnimation(), fadeInAndSlideAnimation(), blockChildAnimationOnLoad(), runChildAnimations()],
})
export class CarConditionComponent implements OnInit, AfterViewInit, OnDestroy {
    constructor(
        private route: ActivatedRoute,
        private router: Router,
        public userPreferences: UserPreferencesService,
        private toastService: ToastService,
        private reportDetailsService: ReportDetailsService,
        private customAutocompleteEntriesService: CustomAutocompleteEntriesService,
        private apiErrorService: ApiErrorService,
        private reportRealtimeEditorService: ReportRealtimeEditorService,
        private loggedInUserService: LoggedInUserService,
        private dialogService: MatDialog,
        private teamService: TeamService,
    ) {}

    private subscriptions: Subscription[] = [];

    protected team: Team;
    protected user: User;

    // Set properties for UI. Keep these up to date with DAT's possible paint types.
    paintTypes: string[] = paintTypes;
    filteredPaintTypes: string[] = [...this.paintTypes];

    public standardBatteryDamageOptions: { value: Car['batteryDamage']; label: BatteryDamageGerman | '' }[] = [
        {
            label: '',
            value: null,
        },
        {
            label: 'Sichtprüfung nicht möglich',
            value: 'visualInspectionNotPossible',
        },
        {
            label: 'keine Beschädigung festgestellt',
            value: 'noVisualDamage',
        },
        {
            label: 'sichtbare Beschädigung festgestellt',
            value: 'visualDamage',
        },
    ];

    emissionGroups: number[] = [1, 2, 3, 4];
    emissionStandards: string[] = ['Euro 1', 'Euro 2', 'Euro 3', 'Euro 4', 'Euro 5', 'Euro 6'];
    filteredEmissionStandards: string[] = [];
    utilityVehicleEmissionStandards: string[] = ['Euro I', 'Euro II', 'Euro III', 'Euro IV', 'Euro V', 'Euro VI'];
    filteredUtilityVehicleEmissionStandards: string[] = [];

    // Emergency Repair
    public availableEmergencyRepairWorkItems: CustomAutocompleteEntry[] = [];
    public filteredEmergencyRepairWorkItems: CustomAutocompleteEntry[] = [];
    @ViewChild('emergencyRepairWorkItemsAutocomplete', { static: false })
    emergencyRepairWorkItemsAutocomplete: MatAutocomplete;
    @ViewChild('emergencyRepairWorkItemsAutocompleteTrigger', { static: false })
    emergencyRepairWorkItemsAutocompleteTrigger: MatAutocompleteTrigger;

    // Mileage
    public mileageAsStatedShown: boolean;
    public mileageCommentTextTemplateSelectorShown = false;
    public mileageCommentShown: boolean;

    // Special Equipment
    public filteredSpecialEquipment: string[] = [];
    public specialEquipment: string[] = [
        'Elektrische Fensterheber',
        'H-Kennzeichen',
        'Hardtop',
        'Klimaanlage',
        'Lederausstattung',
        'Radio',
        'Rechtslenker',
        'Schiebedach',
        'Sonderräder',
        'Sportsitze',
    ];
    @ViewChild('specialEquipmentAutocomplete', { static: false }) specialEquipmentAutocomplete: MatAutocomplete;

    // Damages
    public selectedVehicleSketchTab: 'damageSketch' | 'axleSketch' | 'paintThicknessSketch' | 'oldtimerGrades' =
        'damageSketch';
    public shownDamageDescriptions: Map<Damage['position'], boolean> = new Map();
    public damagePositions: Damage['position'][] = [];

    // Paint Thickness
    protected paintThicknessCommentShown = false;
    protected paintThicknessHidden = false;

    // Battery
    public batteryDamageCommentShown = false;
    @ViewChild('batteryDamageCommentRemark') batteryDamageCommentRemark: MatQuillComponent;
    public batteryDamageCommentTemplatesShown = false;

    public batteryStateOfHealthCommentShown = false;
    @ViewChild('batteryStateOfHealthCommentRemark') batteryStateOfHealthCommentRemark: MatQuillComponent;
    public batteryStateOfHealthCommentTemplatesShown = false;

    // Axles & Tires
    public activeTires = new WeakMap<Tire, boolean>();
    public hoveredTires = new WeakMap<Tire, boolean>();
    public lastFrontAxle: Axle;
    public firstRearAxle: Axle;
    public lastRearAxle: Axle;
    public updateTireEditorTires$: Subject<void> = new Subject();

    /**
     * Flags for the text block selectors.
     */
    showCarCommentTextTemplates: boolean = false;
    showVehicleDescriptionTextTemplateSelectorShown: boolean = false;
    showDamageDescriptionTextTemplates: boolean = false;
    showDamageCircumstancesTextTemplates: boolean = false;
    showDamagePlausibilityTextTemplates: boolean = false;
    showDamageCompatibilityTextTemplates: boolean = false;
    showPreviousDamageTextTemplateSelector: boolean = false;
    showUnrepairedDamageTextTemplateSelector: boolean = false;
    showMeantimeDamageTextTemplateSelector: boolean = false;

    tires: Tire[] = [];

    //*****************************************************************************
    //  Properties for binding
    //****************************************************************************/
    reportId: string;
    report: Report;
    selectedTire: Tire;

    @ViewChild('generateDamageDescriptionMenuTrigger') generateDamageDescriptionMenuTrigger: MatMenuTrigger;

    selectedDamageTab: 'previousDamages' | 'damageDescription' = 'previousDamages';
    /////////////////////////////////////////////////////////////////////////////*/
    //  END Properties for binding
    /////////////////////////////////////////////////////////////////////////////*/

    ngOnInit() {
        this.user = this.loggedInUserService.getUser();
        this.subscriptions.push(this.loggedInUserService.getTeam$().subscribe((team) => (this.team = team)));
        this.subscriptions.push(this.route.parent.params.subscribe((params) => (this.reportId = params['reportId'])));
        const subscription = this.reportDetailsService.get(this.reportId).subscribe((report) => {
            this.report = report;

            // The autocomplete entries require some report properties.
            this.retrieveCustomAutocompleteEntries('emergencyRepairWorkItem');

            // Mileage
            this.showMileageAsStated();
            this.showMileageCommentIfFilled();

            // Battery
            this.batteryStateOfHealthCommentShown = !!this.report.car.batteryStateOfHealthComment;
            this.batteryDamageCommentShown = !!this.report.car.batteryDamageComment;

            // Display Oldtimer Grades
            if (this.report.type === 'oldtimerValuationSmall') {
                this.selectedVehicleSketchTab = 'oldtimerGrades';
            }

            // Damages
            this.setUpDamagePositions();

            // Tires
            this.setUpTires();
            this.selectTire(this.tires[0]);

            // Paint Thickness
            this.setupPaintThickness();

            // Realtime Edit Session
            this.joinAsRealtimeEditor();
        });
        this.subscriptions.push(subscription);
    }

    ngAfterViewInit() {
        this.scrollToBottomIfRequested();
    }

    /**
     * In case the route contains a query parameter "scrollToBottom" set to true, we scroll down
     * to the bottom of the page. E.g. this is used to jump to the previous damages section from another page.
     */
    private scrollToBottomIfRequested(): void {
        const scrollToBottom = this.route.snapshot.queryParamMap.get('scrollToBottom') === 'true';
        if (scrollToBottom) {
            // Remove the query parameter, so that a page reload does not trigger the scroll again
            this.router.navigate([], {
                relativeTo: this.route,
                queryParams: { scrollToBottom: null },
                queryParamsHandling: 'merge',
            });

            scrollToTheBottom();
        }
    }

    //*****************************************************************************
    //  Custom Autocomplete Entries
    //****************************************************************************/

    public retrieveCustomAutocompleteEntries(type: 'emergencyRepairWorkItem'): void {
        const autocompleteEntriesSubscription = this.customAutocompleteEntriesService.find({ type }).subscribe({
            next: (entries) => {
                const group: CustomAutocompleteEntry[] = this.getCustomConditions(type);
                // Remove any old entries
                group.length = 0;

                this.sortCustomAutocompleteEntries(entries);
                group.push(...entries);

                if (type === 'emergencyRepairWorkItem') {
                    this.filterEmergencyRepairWorkItemsAutocomplete('');
                }
            },
            error: (error) => {
                this.apiErrorService.handleAndRethrow({
                    axError: error,
                    handlers: {},
                    defaultHandler: {
                        title: 'Eigene Autocomplete-Werte konnten nicht geholt werden',
                        body: "Bitte kontaktiere die <a href='/Hilfe'>Hotline</a>.",
                    },
                });
            },
        });

        this.subscriptions.push(autocompleteEntriesSubscription);
    }

    private getCustomConditions(type: 'emergencyRepairWorkItem'): CustomAutocompleteEntry[] {
        switch (type) {
            case 'emergencyRepairWorkItem':
                return this.availableEmergencyRepairWorkItems;
        }
    }

    private sortCustomAutocompleteEntries(entries: CustomAutocompleteEntry[]): void {
        entries.sort((entryA, entryB) => entryA.value.localeCompare(entryB.value));
    }

    public isUtilityVehicle(carShape: Car['shape']): boolean {
        return isUtilityVehicle(carShape);
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Custom Autocomplete Entries
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Emergency Repair Required Work Chip List
    //****************************************************************************/
    /**
     * On blur or hitting certain keys (enter, comma), trigger adding a work item as a chip.
     *
     * @param chipInputEvent
     */
    public enterEmergencyRepairWorkItem(chipInputEvent: MatChipInputEvent) {
        if (this.emergencyRepairWorkItemsAutocomplete.isOpen) {
            return;
        }

        const inputValue = (chipInputEvent.value || '').trim();

        this.addEmergencyRepairWorkItem(inputValue);

        // Clear input
        this.clearEmergencyRepairWorkItemInputAndResetAutocomplete(chipInputEvent.chipInput.inputElement);
    }

    /**
     * Add a work item as a chip unless the same work item has already been added.
     *
     * @param workItem
     */
    public addEmergencyRepairWorkItem(workItem: string): void {
        // Don't add duplicates
        if (this.report.damageCalculation.emergencyRepair.workItems.includes(workItem)) {
            this.toastService.info(`Wert '${workItem}' bereits vorhanden`);
            return;
        }

        // Add chip if non-empty
        if (workItem) {
            this.report.damageCalculation.emergencyRepair.workItems.push(workItem);
        }
    }

    public removeWorkItem(auxiliaryDevice: string): void {
        const index = this.report.damageCalculation.emergencyRepair.workItems.indexOf(auxiliaryDevice);
        this.report.damageCalculation.emergencyRepair.workItems.splice(index, 1);
    }

    public selectEmergencyRepairWorkItemsFromAutocomplete(
        event: MatAutocompleteSelectedEvent,
        inputElement: HTMLInputElement,
        autocompleteTrigger: MatAutocompleteTrigger,
    ): void {
        this.addEmergencyRepairWorkItem(event.option.value);
        this.clearEmergencyRepairWorkItemInputAndResetAutocomplete(inputElement);
        setTimeout(() => {
            autocompleteTrigger.openPanel();
        }, 0);
    }

    /**
     * Reduce the entries of the autocomplete for emergency repair work items
     * to the ones containing the search term.
     *
     * @param searchTerm
     */
    public filterEmergencyRepairWorkItemsAutocomplete(searchTerm: string) {
        /**
         * If the report is not yet available, try again later.
         */
        if (!this.report) {
            window.setTimeout(() => {
                this.filterEmergencyRepairWorkItemsAutocomplete(searchTerm);
            }, 100);
            return;
        }

        // e.g. within a valuation
        if (!this.report.damageCalculation?.emergencyRepair) {
            return;
        }

        const inputValue = (searchTerm || '').trim().toLowerCase();

        // Shown entries = Not yet selected && matching search term
        this.filteredEmergencyRepairWorkItems = this.availableEmergencyRepairWorkItems.filter(
            (workItemEntry) =>
                !this.report.damageCalculation.emergencyRepair.workItems.includes(workItemEntry.value) &&
                workItemEntry.value.toLowerCase().includes(inputValue),
        );
    }

    public clearEmergencyRepairWorkItemInputAndResetAutocomplete(input: HTMLInputElement): void {
        input.value = '';
        this.filterEmergencyRepairWorkItemsAutocomplete('');
    }

    public rememberEmergencyRepairWorkItem(workItem: string): void {
        // Don't remember empty items
        if (!(workItem || '').trim()) return;

        // Don't remember duplicates
        if (this.availableEmergencyRepairWorkItems.find((autocompleteEntry) => autocompleteEntry.value === workItem))
            return;

        const newAutocompleteEntry = new CustomAutocompleteEntry({
            type: 'emergencyRepairWorkItem',
            value: workItem,
        });

        this.availableEmergencyRepairWorkItems.push(newAutocompleteEntry);

        this.customAutocompleteEntriesService.create(newAutocompleteEntry);
    }

    public customEmergencyRepairWorkAutocompleteEntryExists(entry: string): boolean {
        return !!this.availableEmergencyRepairWorkItems.find((workItem) => workItem.value === entry);
    }

    public forgetEmergencyRepairWorkItem(workItem: CustomAutocompleteEntry): void {
        this.availableEmergencyRepairWorkItems.splice(this.availableEmergencyRepairWorkItems.indexOf(workItem), 1);

        // We cannot just call the filter method because we don't know the filter term. Relative removal is the way to go here.
        this.filteredEmergencyRepairWorkItems.splice(this.filteredEmergencyRepairWorkItems.indexOf(workItem), 1);

        this.customAutocompleteEntriesService.delete(workItem._id);
    }

    public addCurrentEmergencyRepairWorkToAutocomplete(
        emergencyRepairWork: Report['damageCalculation']['emergencyRepair'],
    ) {
        const newItems = emergencyRepairWork.workItems.filter(
            (workItem) => !this.customEmergencyRepairWorkAutocompleteEntryExists(workItem),
        );

        if (!newItems.length) {
            this.toastService.success('Alle Elemente bereits in Vorschlagsliste', 'Es wurden keine neuen hinzugefügt.');
            return;
        }

        for (const newItem of newItems) {
            this.rememberEmergencyRepairWorkItem(newItem);
        }
        this.toastService.success(
            `${newItems.length} ${newItems.length === 1 ? 'Vorschlag' : 'Vorschläge'} gemerkt`,
            `Neu: ${newItems.join(', ')}`,
        );
    }

    public rememberDefaultEmergencyRepairWorkItems(items: string[]): void {
        this.userPreferences.emergencyRepairWorkDefault = [...items];
        this.toastService.success(
            'Notwendige Reparaturen gemerkt',
            'Im nächsten Gutachten werden sie automatisch eingefügt.',
        );
    }

    public resetEmergencyRepair() {
        // Rest emergency repair if it is not visible in the UI anymore (user confused otherwise).
        if (
            !this.report.damageCalculation.emergencyRepair.state ||
            !(
                this.report.car.roadworthiness &&
                this.report.car.roadworthiness !== 'verkehrssicher' &&
                this.report.damageCalculation
            )
        ) {
            // Reset emergency repair to default values
            this.report.damageCalculation.emergencyRepair.state = null;
            this.report.damageCalculation.emergencyRepair.comment = null;
            this.report.damageCalculation.emergencyRepair.costs = null;
            this.report.damageCalculation.emergencyRepair.workItems = [];
        }
    }

    /**
     * Copy the default emergency work items into the corresponding field if its still empty.
     */
    public insertDefaultEmergencyRepairWorkIfEmpty() {
        if (!this.report.damageCalculation.emergencyRepair.workItems.length) {
            this.insertDefaultEmergencyRepairWork();
        }
    }

    /**
     * Copy default items into report.
     */
    public insertDefaultEmergencyRepairWork(): void {
        this.report.damageCalculation.emergencyRepair.workItems = [...this.userPreferences.emergencyRepairWorkDefault];
        this.saveReport();
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Emergency Repair Required Work Chip List
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Mileage
    //****************************************************************************/
    public showMileageAsStated(): void {
        if (this.report.car.mileageAsStated) {
            this.mileageAsStatedShown = true;
        }
    }

    public toggleMileageAsStated(): void {
        // If the field is about to be hidden, clear its value.
        if (this.mileageAsStatedShown) {
            this.report.car.mileageAsStated = null;
            this.saveReport();
        } else {
            this.report.car.mileage = null;
            this.saveReport();
        }
        this.mileageAsStatedShown = !this.mileageAsStatedShown;
    }

    private showMileageCommentIfFilled(): void {
        if (this.report.car.mileageComment) {
            this.mileageCommentShown = true;
        }
    }

    public toggleMileageComment(): void {
        this.mileageCommentShown = !this.mileageCommentShown;
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Mileage
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Special Equipment (Oldtimer valuation)
    //****************************************************************************/
    public filterSpecialEquipmentAutocomplete(searchTerm: string): void {
        this.filteredSpecialEquipment = [...this.specialEquipment]
            // Don't show items already selected
            .filter((equipment) => !this.report.car.specialEquipment.includes(equipment));

        if (!searchTerm) return;

        const searchTerms: string[] = searchTerm.toLowerCase().split(' ');

        // All search terms must be included in the equipment name to match
        this.filteredSpecialEquipment = this.specialEquipment.filter((equipment) =>
            searchTerms.every((searchTerm) => equipment.toLowerCase().includes(searchTerm)),
        );
    }

    /**
     * On blur or hitting certain keys (enter, comma, space), trigger adding a device as a chip.
     *
     * @param chipInputEvent
     */
    public enterSpecialEquipment(chipInputEvent: MatChipInputEvent) {
        if (this.specialEquipmentAutocomplete.isOpen) {
            return;
        }

        const inputValue = (chipInputEvent.value || '').trim();

        this.addSpecialEquipment(inputValue);

        // Clear input
        this.clearSpecialEquipmentInputAndResetAutocomplete(chipInputEvent.input);
    }

    /**
     * Add a device as a chip unless the same device has already been added.
     *
     * @param specialEquipment
     */
    public addSpecialEquipment(specialEquipment: string): void {
        // Don't add duplicates
        if (this.report.car.specialEquipment.includes(specialEquipment)) {
            this.toastService.info(`Wert '${specialEquipment}' bereits vorhanden`);
            return;
        }

        // Add chip if non-empty
        if (specialEquipment) {
            this.report.car.specialEquipment.push(specialEquipment);
        }
    }

    /**
     * Select autocomplete option for special equipment.
     * @param event
     * @param inputElement
     * @param autocompleteTrigger
     */
    public selectSpecialEquipmentFromAutocomplete(
        event: MatAutocompleteSelectedEvent,
        inputElement: HTMLInputElement,
        autocompleteTrigger: MatAutocompleteTrigger,
    ): void {
        this.addSpecialEquipment(event.option.value);
        this.clearSpecialEquipmentInputAndResetAutocomplete(inputElement);
        // Re-open panel
        setTimeout(() => {
            autocompleteTrigger.openPanel();
        }, 0);
    }

    /**
     * Clear the chip input and re-filter its autocomplete.
     * @param input
     */
    public clearSpecialEquipmentInputAndResetAutocomplete(input: HTMLInputElement): void {
        input.value = '';
        this.filterSpecialEquipmentAutocomplete('');
    }

    public removeSpecialEquipment(equipment: string): void {
        this.report.car.specialEquipment.splice(this.report.car.specialEquipment.indexOf(equipment), 1);
    }
    /////////////////////////////////////////////////////////////////////////////*/
    //  END Special Equipment (Oldtimer valuation)
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Damage Sketch
    //****************************************************************************/

    public selectVehicleSketchTab(tab: this['selectedVehicleSketchTab']): void {
        this.selectedVehicleSketchTab = tab;

        if (this.selectedVehicleSketchTab === 'axleSketch') {
            this.findLastFrontAndFirstRearAxle();
        }
    }

    public isAxleConfiguratorRequired(car: Car): boolean {
        if (!car) {
            return false;
        }

        return isAxleConfigurationRequired(car);
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Damage Sketch
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Axle Sketch
    //****************************************************************************/
    public switchToAxleSketchIfAvailable(): void {
        if (!this.isAxleConfiguratorRequired(this.report.car)) return;

        this.selectedVehicleSketchTab = 'axleSketch';
    }

    /**
     * The first rear axle has a margin-top to create a distance from the front axles.
     *
     * Also, only the last front axle and the first rear axle have controls to be switched to the other position (front/rear)
     */
    public findLastFrontAndFirstRearAxle(): void {
        const frontAxles = this.report.car.axles.filter((axle) => axle.axlePosition === 'front');
        this.lastFrontAxle = frontAxles[frontAxles.length - 1];

        const rearAxles = this.report.car.axles.filter((axle) => axle.axlePosition === 'rear');
        this.firstRearAxle = rearAxles[0];
        this.lastRearAxle = rearAxles[rearAxles.length - 1];
    }

    public addTwinTires(axle: Axle): void {
        if (this.isReportLocked()) {
            return;
        }

        axle.outerLeftTire = JSON.parse(JSON.stringify(axle.leftTire));
        axle.outerRightTire = JSON.parse(JSON.stringify(axle.rightTire));

        axle.outerLeftTire.position = 'outerLeft';
        axle.outerRightTire.position = 'outerRight';

        // Make sure the UI tires include the new tire
        this.setUpTires();
        this.updateTireEditor();
    }

    public removeTwinTires(axle: Axle): void {
        if (this.isReportLocked()) {
            return;
        }

        axle.outerLeftTire = null;
        axle.outerRightTire = null;

        // Make sure the UI tires don't include the removed tire
        this.setUpTires();
        this.updateTireEditor();

        /**
         * If a vehicle usually does not have twin tires (e.g. transporter) but is configured with twin tires through
         * changing to semi-truck and configuring twin tires there and changing back to transporter, removing the twin tires
         * again should close the axle configuration since that's obsolete now.
         */
        if (!isAxleConfigurationRequired(this.report.car)) {
            this.selectVehicleSketchTab('damageSketch');
        }
    }

    /**
     * Make an axle a rear axle.
     * This is only possible for the last front axle.
     */
    public moveAxleToRear(axle: Axle): void {
        axle.axlePosition = 'rear';
        this.findLastFrontAndFirstRearAxle();
        this.updateTireEditor();
    }

    /**
     * Make an axle a front axle.
     * This is only possible for the first rear axle.
     */
    public moveAxleToFront(axle: Axle): void {
        axle.axlePosition = 'front';
        this.findLastFrontAndFirstRearAxle();
        this.updateTireEditor();
    }

    public toggleSteerability(axle: Axle): void {
        axle.isSteerable = !axle.isSteerable;
    }

    public vehicleShapeHasEngine() {
        return doesVehicleShapeHaveEngine(this.report.car.shape);
    }

    // Display battery specific fields only if the vehicle has a battery electric engine.
    public doesVehicleHaveBatteryElectricEngine = doesVehicleHaveBatteryElectricEngine;

    private updateTireEditor(): void {
        this.updateTireEditorTires$.next();
    }

    //*****************************************************************************
    //  Tire State
    //****************************************************************************/
    public isTireInfoComplete(tire: Tire): boolean {
        return isTireInfoComplete(tire);
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Tire State
    /////////////////////////////////////////////////////////////////////////////*/

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Axle Sketch
    /////////////////////////////////////////////////////////////////////////////*/

    public insertPreviousDamageStandardText(): void {
        this.report.car.repairedPreviousDamage = this.team.preferences.previousDamageStandardText || '';
        this.saveReport();
    }

    public insertUnrepairedDamageStandardText(): void {
        this.report.car.unrepairedPreviousDamage = this.team.preferences.unrepairedDamageStandardText || '';
        this.saveReport();
    }

    public insertMeantimeDamageStandardText(): void {
        this.report.car.meantimeDamage = this.team.preferences.meantimeDamageStandardText || '';
        this.saveReport();
    }

    //*****************************************************************************
    //  Damage Descriptions
    //****************************************************************************/
    public insertDamageDescriptionStandardText(): void {
        this.report.car.damageDescription = this.team.preferences.damageDescriptionStandardText || '';
        this.saveReport();
    }

    /**
     * List all photo descriptions describing damages in the damage description.
     *
     * Many assessors document the damage on their photos. This method lists all photo descriptions under these
     * conditions:
     * - photo must be active
     * - the description must not be within the default descriptions in `userPreferences.defaultPhotoDescriptions`
     *   because those are usually not damage descriptions but rather static descriptions like view angles.
     * - prevent duplicates
     * - prevent empty descriptions
     */
    public generateDamageDescriptionFromPhotoDescriptions() {
        if (!this.report.photos.some((photo) => photo.versions.report.included && photo.description)) {
            this.toastService.error(
                'Keine Fotos vorhanden',
                'In der Fotogruppe Gutachten gibt es keine aktiven Fotos mit einer Beschreibung.',
            );
            return;
        }

        const nonDamageDescriptions: string[] = [
            // Descriptions added through out automatic feature are usually no damage descriptions -> Filter them out.
            ...this.userPreferences.defaultPhotoDescriptions.map(
                (defaultPhotoDescription) => defaultPhotoDescription.title,
            ),
            // Filter out typical descriptions that are not damage descriptions. This collection may grow over time.
            // Inserted by the car registration scanner.
            CAR_REGISTRATION_SCAN_PHOTO_TITLE,
            'Armaturenbrett',
            'Instrumententafel',
            'Altschaden',
            'Typenschild',
        ];

        const photoDescriptions = this.report.photos
            .filter(
                (photo) =>
                    // must be active in report group
                    photo.versions.report.included &&
                    // must have a description.
                    photo.description &&
                    // must not be a non-damage description.
                    !nonDamageDescriptions.includes(photo.description),
            )
            .map((photo) => photo.description);

        if (!photoDescriptions.length) {
            this.toastService.error(
                'Keine Fotobeschreibungen',
                'Keines der Fotos in der Fotogruppe Gutachten beinhaltet eine Beschreibung.',
            );
            return;
        }

        // Existing bullet points shall not be duplicated.
        const existingBulletDescriptions = [
            ...(this.report.car.damageDescription || '').matchAll(/<li>(.*?)<\/li>/g),
        ].map(
            // Use the first subgroup instead of the full match ("Stoßfänger verkratzt" rather than "<li>Stoßfänger verkratzt</li>")
            (match) => match[1],
        );

        const newDescriptions: string[] = [...new Set([...photoDescriptions]).values()].filter(
            (photoDescription) => !existingBulletDescriptions.includes(photoDescription),
        );

        // Format as bullet list.
        const htmlWithBullets: string =
            // Prevent duplicates with a Set.
            newDescriptions.map((photoDescription) => `<li>${photoDescription}</li>`).join('');

        this.report.car.damageDescription = (this.report.car.damageDescription || '') + `<ul>${htmlWithBullets}</ul>`;
        this.saveReport();
    }

    public generateDamageDescriptionFromCalculation() {
        if (this.report.damageCalculation?.repair?.calculationProvider === 'gtmotive') {
            this.toastService.error(
                'Für GTmotive nicht verfügbar',
                'Die Schadenbeschreibung kann nur aus einer Audatex- oder DAT-Kalkulation befüllt werden.',
            );
            return;
        }

        if (
            this.report.damageCalculation?.repair?.calculationProvider !== 'audatex' &&
            this.report.damageCalculation?.repair?.calculationProvider !== 'dat'
        ) {
            this.toastService.error(
                'Nur mit Audatex- oder DAT-Kalkulation',
                'Die Schadenbeschreibung kann nur aus einer Audatex- oder DAT-Kalkulation befüllt werden. Erstelle eine Kalkulation.',
            );
            return;
        }

        if (!this.report.damageCalculation?.repair?.damagedParts?.length) {
            this.toastService.error(
                'Keine Teile verfügbar',
                'Stelle sicher, dass die Kalkulation Teile und Arbeitspositionen enthält und importiere die Kalkulation erneut.',
            );
            return;
        }

        this.report.car.damageDescription =
            (this.report.car.damageDescription || '') +
            generateDamageDescriptionFromCalculation({
                userPreferences: this.userPreferences,
                damagedParts: this.report.damageCalculation.repair.damagedParts,
            });
        this.saveReport();
    }

    public async openDamageDescriptionFromCalculationConfigurationDialog() {
        const dialogRef = this.dialogService.open(DescriptionFromRepairCalculationDialogComponent, {
            panelClass: 'dialog-without-padding',
            data: {
                report: this.report,
                damagedParts: this.report.damageCalculation.repair.damagedParts,
                context: 'damageDescriptionFromRepairParts',
            },
        });
        const dialogResult: DescriptionFromRepairCalculationDialogResult = await dialogRef.afterClosed().toPromise();
        if (dialogResult?.generatedText) {
            this.report.car.damageDescription = (this.report.car.damageDescription || '') + dialogResult.generatedText;
            this.saveReport();
        }
    }
    /////////////////////////////////////////////////////////////////////////////*/
    //  END Damage Descriptions
    /////////////////////////////////////////////////////////////////////////////*/

    public insertCircumstancesStandardText(): void {
        this.report.accident.circumstances = this.team.preferences.circumstancesStandardText || '';
        this.saveReport();
    }

    public insertPlausibilityStandardText(): void {
        this.report.accident.plausibility = this.team.preferences.plausibilityStandardText || '';
        this.saveReport();
    }

    public insertCompatibilityStandardText(): void {
        this.report.accident.compatibility = this.team.preferences.compatibilityStandardText || '';
        this.saveReport();
    }

    /**
     * Set an emission group on the VariableCarInformation object. Remove it if the same group is selected again.
     * @param emissionGroup
     */
    public selectEmissionGroup(emissionGroup: number): void {
        // If the emission group had already been selected, remove selection.
        if (this.report.car.emissionGroup === emissionGroup) {
            this.report.car.emissionGroup = null;
            this.saveReport();
            return;
        }

        // Else, assign the new emission group
        this.report.car.emissionGroup = emissionGroup;
        this.saveReport();
    }

    //*****************************************************************************
    //  Emission Standards
    //****************************************************************************/
    public filterEmissionStandards(): void {
        this.filteredEmissionStandards = [...this.emissionStandards];
        this.filteredUtilityVehicleEmissionStandards = [...this.utilityVehicleEmissionStandards];

        const fullSearchTerm: string = this.report.car.emissionStandard;

        if (!fullSearchTerm) return;

        const searchTerms: string[] = fullSearchTerm.split(' ');

        this.filteredEmissionStandards = this.filteredEmissionStandards.filter((emissionStandard) =>
            searchTerms.every((searchTerm) => emissionStandard.includes(searchTerm)),
        );
        this.filteredUtilityVehicleEmissionStandards = this.filteredUtilityVehicleEmissionStandards.filter(
            (emissionStandard) => searchTerms.every((searchTerm) => emissionStandard.includes(searchTerm)),
        );
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Emission Standards
    /////////////////////////////////////////////////////////////////////////////*/
    /**
     * Toggle the damage property of a DamageForUI object to the report object.
     *
     * For each damage position, the component holds a DamageForUI object. There is
     * not a damage object on the report for each possible position. Therefore we must
     * copy the damage object from the DamageForUI object to the report when it's
     * activated or remove it when it's deactivated.
     * @param damagePosition
     */
    public toggleDamageOnReport(damagePosition: Damage['position']) {
        let damage: Damage = this.report.car.damages.find((damage) => damage.position === damagePosition);

        // If the damage exists, remove it.
        if (damage) {
            const damageIndex: number = this.report.car.damages.indexOf(damage);
            this.report.car.damages.splice(damageIndex, 1);
            return;
        } else {
            damage = new Damage(damagePosition);
            damage.active = true;
            this.report.car.damages.push(damage);
        }
    }

    public getDamageByPosition(damagePosition: Damage['position']): Damage {
        return this.report.car.damages.find((existingDamage) => existingDamage.position === damagePosition);
    }

    public isDamageDescriptionShown(damagePosition: Damage['position']): boolean {
        return this.shownDamageDescriptions.has(damagePosition);
    }

    public closeAllDescriptionsButThisOne(damagePosition: Damage['position']): void {
        this.shownDamageDescriptions = new Map();
        this.shownDamageDescriptions.set(damagePosition, true);
    }

    public closeDamageDescription(damagePosition: Damage['position']) {
        this.shownDamageDescriptions.delete(damagePosition);
    }

    public selectDamageTab(damageTab: 'previousDamages' | 'damageDescription'): void {
        this.selectedDamageTab = damageTab;
    }

    public previousDamagesPartiallyComplete(): boolean {
        return !!(
            this.report.car.repairedPreviousDamage ||
            this.report.car.unrepairedPreviousDamage ||
            this.report.car.meantimeDamage
        );
    }

    public damageDescriptionPartiallyComplete(): boolean {
        return !!(
            this.report.car.damageDescription ||
            this.report.accident?.circumstances ||
            this.report.accident?.plausibility
        );
    }

    //*****************************************************************************
    //  Tires
    //****************************************************************************/
    public selectTire(tire: Tire) {
        this.selectedTire = this.tires.find(
            (tireForUi) => tireForUi.position === tire.position && tireForUi.axle === tire.axle,
        );
    }

    private bounceTire(tire: Tire): void {
        this.activeTires.set(tire, true);
        setTimeout(() => this.activeTires.delete(tire), 400);
    }

    public bounceAllTires(): void {
        this.tires.forEach((tire) => this.bounceTire(tire));
    }

    public bounceAllTiresOnAxle(): void {
        // Bounce the copyTargets. There is no need to bounce the active tire; it's already expanded since it is selected.
        this.tires
            .filter((tire) => tire.axle === this.selectedTire.axle)
            .forEach((tireOnSameAxle) => {
                this.bounceTire(tireOnSameAxle);
            });
    }

    public isTireActive(tire: Tire): boolean {
        return this.activeTires.has(tire);
    }

    public markTireAsBeingHovered(tire: Tire): void {
        this.hoveredTires.set(tire, true);
    }

    public unmarkTireAsBeingHovered(tire: Tire): void {
        this.hoveredTires.delete(tire);
    }

    public isTireBeingHovered(tire: Tire): boolean {
        return this.hoveredTires.has(tire);
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Tires
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Emergency Tire Equipment
    //****************************************************************************/

    get carShapeHasSpareTires(): boolean {
        const carShapesWithoutSeasonalTires: CarShape[] = ['bicycle', 'e-bike', 'pedelec'];
        return !carShapesWithoutSeasonalTires.includes(this.report.car.shape);
    }

    public hideSpareTireEquipmentSection() {
        this.userPreferences.spareTireEquipmentShown = false;
        if (this.report.car.spareTireEquipment.type || !this.areSpareTireDetailsEmpty()) {
            this.toastService.info(
                'Ausblenden ab nächstem Gutachten',
                'Wenn Daten für Notbereifung vorhanden sind, werden sie trotzdem angezeigt.',
            );
        }
    }

    /**
     * Are all details except type empty?
     *
     * Type is excluded because we need this method mainly to determine if the data entered in the dialog is empty.
     */
    public areSpareTireDetailsEmpty(): boolean {
        // Shorthand
        const spareTire = this.report.car.spareTireEquipment;

        // The season does not matter because it won't be shown in the preview.
        return !spareTire.dimension && !spareTire.manufacturer && !spareTire.treadInMm;
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Emergency Tire Equipment
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Second Tire Set
    //****************************************************************************/

    public addSecondTireSet() {
        this.report.car.hasSecondTireSet = true;
        getAllTiresOfVehicle(this.report.car.axles).forEach((tire) => {
            tire.secondTireSet = new SecondTire();
        });
        this.saveReport();
    }

    public deleteSecondTireSet() {
        this.report.car.hasSecondTireSet = false;
        getAllTiresOfVehicle(this.report.car.axles).forEach((tire) => {
            delete tire.secondTireSet;
        });
        this.saveReport();
    }
    /////////////////////////////////////////////////////////////////////////////*/
    //  END Second Tire Set
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Damage Checkboxes
    //****************************************************************************/

    /**
     * Determine which damage positions to show to the user.
     *
     * Activating one adds it to the report.
     */
    public setUpDamagePositions(): void {
        switch (this.report.car.shape) {
            case 'bicycle':
            case 'e-bike':
            case 'pedelec':
            case 'motorcycle':
                this.damagePositions = [
                    'frontLeft',
                    'frontCenter',
                    'frontRight',
                    'centerLeft',
                    'windshield',
                    'centerRight',
                    'rearLeft',
                    'rearCenter',
                    'rearRight',
                ];
                break;
            case 'trailer':
            case 'caravanTrailer':
                this.damagePositions = [
                    'frontLeft',
                    'frontCenter',
                    'frontRight',
                    'centerLeft',
                    'centerRight',
                    'rearLeft',
                    'rearCenter',
                    'rearRight',
                ];
                break;
            case 'motorHome':
                this.damagePositions = [
                    'frontLeft',
                    'frontCenter',
                    'frontRight',
                    'fenderFrontLeft',
                    'hood',
                    'fenderFrontRight',
                    'doorDriver',
                    'windshield',
                    'doorFrontPassenger',
                    'doorBackPassengerLeft',
                    'roof',
                    'doorBackPassengerRight',
                    'fenderRearLeft',
                    'fenderRearRight',
                    'rearLeft',
                    'roofRear',
                    'rearCenter',
                    'rearRight',
                ];
                break;
            case 'truck':
                this.damagePositions = [
                    // Front
                    'frontLeft',
                    'frontCenter',
                    'frontRight',
                    // Driver's cabin
                    'roof',
                    'doorDriver',
                    'doorFrontPassenger',
                    // Platform
                    'leftWallFront',
                    'leftWallCenter',
                    'leftWallRear',
                    'ceilingFront',
                    'ceilingCenter',
                    'ceilingRear',
                    'rightWallFront',
                    'rightWallCenter',
                    'rightWallRear',
                    // Rear
                    'rearLeft',
                    'rearCenter',
                    'rearRight',
                ];
                break;
            case 'semiTruck':
                this.damagePositions = [
                    'frontLeft',
                    'frontCenter',
                    'frontRight',
                    'doorDriver',
                    'doorFrontPassenger',
                    'roof',
                    'frameLeft',
                    'frameRight',
                    'rearLeft',
                    'rearCenter',
                    'rearRight',
                ];
                break;
            case 'semiTrailer':
                this.damagePositions = [
                    // Front
                    'frontLeft',
                    'frontCenter',
                    'frontRight',
                    // Center
                    'leftWallFront',
                    'leftWallCenter',
                    'leftWallRear',
                    'ceilingFront',
                    'ceilingCenter',
                    'ceilingRear',
                    'rightWallFront',
                    'rightWallCenter',
                    'rightWallRear',
                    // Rear
                    'rearLeft',
                    'rearCenter',
                    'rearRight',
                ];
                break;
            case 'bus':
                this.damagePositions = [
                    // Front
                    'frontLeft',
                    'frontCenter',
                    'frontRight',
                    'doorDriver',
                    'doorFrontPassenger',
                    // Center
                    'roof',
                    'leftWallCenter',
                    'leftWallRear',
                    'rightWallCenter',
                    'rightWallRear',
                    // Rear
                    'rearLeft',
                    'rearCenter',
                    'rearRight',
                ];
                break;
            default:
                this.damagePositions = [...sedanDamages];
        }
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Damage Checkboxes
    /////////////////////////////////////////////////////////////////////////////*/

    /**
     * Determine if the paint thickness tab should be shown and if the comment field needs to be displayed initially.
     */
    private setupPaintThickness(): void {
        // Hide the paint thickness measurement for bicycles/pedelecs -> It is very unlikely that there will be anyone measuring the paint thickness
        if (isBicycleOrPedelec(this.report.car)) {
            this.paintThicknessHidden = true;
            this.report.car.paintThicknessMeasurements = [];
            this.report.car.paintThicknessSelectedScaleId = null;
            void this.saveReport();
            return;
        }

        // Initially show the comment field only, if there is already a comment
        if (this.report.car.paintThicknessMeasurementComment) {
            this.paintThicknessCommentShown = true;
        }
    }

    protected togglePaintThicknessExcludeClassification(): void {
        if (!hasAccessRight(this.user, 'editTextsAndDocumentBuildingBlocks')) {
            return;
        }
        this.team.preferences.paintThicknessMeasurementExcludeClassification =
            !this.team.preferences.paintThicknessMeasurementExcludeClassification;
    }

    /**
     * Attach UI properties (such as selection) to the tires from the report
     */
    private setUpTires() {
        this.tires = getAllTiresOfVehicle(this.report.car.axles);
    }

    //*****************************************************************************
    //  Realtime Editors
    //****************************************************************************/
    public joinAsRealtimeEditor() {
        this.reportRealtimeEditorService.joinAsEditor({
            recordId: this.report._id,
            currentTab: 'carCondition',
        });
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Realtime Editors
    /////////////////////////////////////////////////////////////////////////////*/

    public isReportLocked(): boolean {
        return this.report.state === 'done';
    }

    //*****************************************************************************
    //  Save Methods
    //****************************************************************************/
    /**
     * Save reports to the server.
     */
    public async saveReport(): Promise<void> {
        try {
            await this.reportDetailsService.patch(this.report);
        } catch (error) {
            this.toastService.error('Fehler beim Sync', 'Bitte versuche es später erneut');
            console.error('An error occurred while saving the report via the ReportService.', this.report, { error });
        }
    }

    protected async saveTeam(): Promise<void> {
        try {
            await this.teamService.put(this.team);
        } catch (error) {
            this.apiErrorService.handleAndRethrow({
                axError: error,
                handlers: {},
                defaultHandler: {
                    title: 'Team nicht gespeichert',
                    body: "Bitte kontaktiere die <a href='/Hilfe'>Hotline</a>.",
                },
            });
        }
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Save Methods
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Filter for Autocompletes
    //****************************************************************************/
    public filterPaintTypesAutocomplete(searchTerm: string): void {
        searchTerm = (searchTerm || '').toLowerCase();

        this.filteredPaintTypes = this.paintTypes.filter((entry) => {
            // Search only at the beginning of each word inside the searched entry
            return entry.toLowerCase().startsWith(searchTerm);
        });
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Filter for Autocompletes
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Keyboard event handlers
    //****************************************************************************/
    /**
     * Get a reference to the trigger a click event on pressing the space bar from the
     * shared module.
     *
     * @type {(event:KeyboardEvent)=>void}
     */
    public triggerClickEventOnSpaceBarPress = triggerClickEventOnSpaceBarPress;
    /////////////////////////////////////////////////////////////////////////////*/
    //  END Keyboard event handlers
    /////////////////////////////////////////////////////////////////////////////*/

    ngOnDestroy() {
        this.subscriptions.forEach((subscription) => {
            subscription.unsubscribe();
        });
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  Toggles
    /////////////////////////////////////////////////////////////////////////////*/

    public toggleCompatibilityShown() {
        this.userPreferences.compatibilityShown = !this.userPreferences.compatibilityShown;
    }

    public toggleBatteryStateOfHealthCommentShown() {
        this.batteryStateOfHealthCommentShown = !this.batteryStateOfHealthCommentShown;
    }

    public focusBatteryStateOfHealthCommentRemark(): void {
        setTimeout(() => {
            if (this.batteryStateOfHealthCommentRemark) {
                this.batteryStateOfHealthCommentRemark.quillInstance.focus();
            }
        }, 0);
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Toggles
    /////////////////////////////////////////////////////////////////////////////*/
    protected readonly doesVehicleHaveInternalCombustionEngine = doesVehicleHaveInternalCombustionEngine;
    protected readonly hasPaintThicknessMeasurement = hasPaintThicknessMeasurement;
    protected readonly getCarTireImageName = getCarTireImageName;
    protected readonly getCarContourImageName = getCarContourImageName;
    protected readonly isPaintThicknessMeasurementComplete = isPaintThicknessMeasurementComplete;
    protected readonly hasAccessRight = hasAccessRight;
    protected readonly getMissingAccessRightTooltip = getMissingAccessRightTooltip;
}

export const paintTypes = [
    '2-K uni (1-Schicht)',
    'Uni (2-Schicht)',
    'Metallic (2-Schicht)',
    'Mineraleffekt (2-Schicht)',
    'Uni (3-Schicht)',
    'Metallic (3-Schicht)',
    '2-Farben (met/met. 3-Schicht)',
    '2-Farben (uni/met. 3-Schicht)',
    '2-Farben (uni/uni 2-Schicht)',
    '2-Farben (uni/met. 2-Schicht)',
    '2-Farben (met./met.2-Schicht)',
    'Perleffekt (2-Schicht)',
    'Perlcolor-Effekt (2-Schicht)',
    'Perlmutt-Effekt (3-Schicht)',
    'Irisier. Glanzlack (3-Schicht)',
    'Mica (2-Schicht)',
    'Mica (3-Schicht)',
    'Effekt-Lack (4-Schicht)',
    'Uni (kratzfesterer Klarlack)',
    'Metallic (kratzfesterer Klarlack)',
    'Perleffekt kratzfest 2-Schicht',
    'Perleffekt kratzfest 3-Schicht',
    'Uni (Multifunktions-Basislack)',
    'Metallic (Multifunktions-Basislack)',
    'Sonderlackierung',
];

import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { Component, Input } from '@angular/core';
import { duplicateDocumentBuildingBlockCondition } from '@autoixpert/lib/document-building-blocks/duplicate-document-building-block-condition';
import { isDocumentBuildingBlockConditionGroup } from '@autoixpert/lib/document-building-blocks/is-document-building-block-condition-group';
import { PlaceholderValueTree } from '@autoixpert/lib/placeholder-values/get-placeholder-value-tree';
import { PlaceholderValues } from '@autoixpert/lib/placeholder-values/get-placeholder-values';
import { FieldGroupConfig } from '@autoixpert/models/custom-fields/field-group-config';
import { DocumentBuildingBlockCondition } from '@autoixpert/models/documents/document-building-block-condition';
import { DocumentBuildingBlockConditionGroup } from '@autoixpert/models/documents/document-building-block-condition-group';
import { trackById } from 'src/app/shared/libraries/track-by-id';
import { ToastService } from '../../services/toast.service';

/**
 * Component for editing a document building block condition group (building block or building block variant).
 *
 * Both
 * - document building block
 * - document building block variant
 * use the same condition group editor as they fulfill the DocumentBuildingBlockConditionGroup interface.
 */
@Component({
    selector: 'document-building-block-condition-group-editor',
    templateUrl: 'document-building-block-condition-group-editor.component.html',
    styleUrls: ['document-building-block-condition-group-editor.component.scss'],
})
export class DocumentBuildingBlockConditionGroupEditorComponent {
    constructor(private toastService: ToastService) {}

    readonly trackById = trackById;
    readonly isDocumentBuildingBlockConditionGroup = isDocumentBuildingBlockConditionGroup;

    // The condition group to edit (building block or building block variant)
    @Input() conditionGroup: DocumentBuildingBlockConditionGroup;

    // Placeholder values for the selected report or invoice, used to show values in property path selection overlay
    @Input() placeholderValues: PlaceholderValues | undefined;

    @Input() fieldGroupConfigs: FieldGroupConfig[] = [];
    @Input() placeholderValueTree: PlaceholderValueTree;
    @Input() availableDocumentPlaceholders: string[] = [];

    //*****************************************************************************
    //  View Handlers
    //****************************************************************************/
    public addCondition(): void {
        const newCondition = new DocumentBuildingBlockCondition();
        this.conditionGroup.conditions ??= [];
        this.conditionGroup.conditions.push(newCondition);
    }

    public addConditionGroup(): void {
        const newConditionGroup = new DocumentBuildingBlockConditionGroup();
        newConditionGroup.conditions = [new DocumentBuildingBlockCondition()];
        this.conditionGroup.conditions ??= [];
        this.conditionGroup.conditions.push(newConditionGroup);
    }

    public handleRemoveChildCondition(
        condition: DocumentBuildingBlockCondition | DocumentBuildingBlockConditionGroup,
    ): void {
        const index = this.conditionGroup.conditions.indexOf(condition);
        const [deletedCondition] = this.conditionGroup.conditions.splice(index, 1);

        // Restore deleted condition toast
        const toast = this.toastService.info('Bedingung gelöscht', 'Klicken, um sie wiederherzustellen.', {
            showProgressBar: true,
            timeOut: 10000,
        });

        toast.click.subscribe(() => {
            this.conditionGroup.conditions.splice(index, 0, deletedCondition);
        });
    }

    public handleDuplicateChildCondition(
        condition: DocumentBuildingBlockCondition | DocumentBuildingBlockConditionGroup,
    ): void {
        const index = this.conditionGroup.conditions.indexOf(condition);
        const newCondition = duplicateDocumentBuildingBlockCondition(condition);
        this.conditionGroup.conditions.splice(index + 1, 0, newCondition);
    }

    public handleTurnIntoConditionGroup(condition: DocumentBuildingBlockCondition) {
        const index = this.conditionGroup.conditions.indexOf(condition);
        const newConditionGroup = new DocumentBuildingBlockConditionGroup();
        newConditionGroup.conditions = [condition];
        this.conditionGroup.conditions.splice(index, 1, newConditionGroup);
    }

    public setConditionGroupOperator(
        conditionGroup: DocumentBuildingBlockConditionGroup,
        operator: DocumentBuildingBlockConditionGroup['conditionsOperator'],
    ): void {
        conditionGroup.conditionsOperator = operator;
    }

    public hanldeConditionDrop(
        event: CdkDragDrop<DocumentBuildingBlockCondition | DocumentBuildingBlockConditionGroup>,
    ): void {
        moveItemInArray(this.conditionGroup.conditions, event.previousIndex, event.currentIndex);
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END View Handlers
    /////////////////////////////////////////////////////////////////////////////*/
}

import { Component, Input } from '@angular/core';
import moment from 'moment';
import { Subscription } from 'rxjs';
import { IsoDate } from '@autoixpert/lib/date/iso-date.types';
import { round } from '@autoixpert/lib/numbers/round';
import { WorkdaySettings } from '@autoixpert/models/user/preferences/workweek-settings';
import { User } from '@autoixpert/models/user/user';
import { LoggedInUserService } from 'src/app/shared/services/logged-in-user.service';
import { TaskService } from 'src/app/shared/services/task.service';

@Component({
    selector: 'task-capacity-preview',
    templateUrl: './task-capacity-preview.component.html',
    styleUrls: ['./task-capacity-preview.component.scss'],
})
export class TaskCapacityPreviewComponent {
    constructor(
        private taskService: TaskService,
        private loggedInUserService: LoggedInUserService,
    ) {}

    @Input() day: IsoDate;
    @Input() workdaySettings: WorkdaySettings;

    givenDayMoment: moment.Moment;

    private usedCapacityInMinutes: number;
    private usedCapacityRelative: number;

    // Returns a string with the relative used capacity in percent (includes the % sign).
    public get capacityPercentage(): string {
        return `${Math.round(this.usedCapacityRelative * 100)}%`;
    }

    /**
     * Helper function to convert a duration in minutes to a human-readable string, including units.
     */
    public formatMinutes(durationInMinutes: number): string {
        const hours = Math.floor(durationInMinutes / 60);
        const minutes = Math.round(durationInMinutes % 60);
        if (hours && minutes) {
            return `${hours}:${minutes > 9 ? minutes : '0' + minutes} h`;
        }
        if (!hours && minutes) {
            return `${minutes} min`;
        }
        return `${hours} h`;
    }

    // Gets the used capacity in hours, including units.
    get formattedUsedCapacity(): string {
        return this.formatMinutes(this.usedCapacityInMinutes);
    }

    // Gets the total capacity in hours, including units.
    get formattedCapacity(): string {
        return this.formatMinutes(this.workdaySettings?.workingMinutes ?? 480);
    }

    /**
     * Get the tooltip for the capacity preview.
     */
    get capacityTooltip(): string {
        return `${this.labelForDate}: ${this.formattedUsedCapacity} von ${this.formattedCapacity} geplant (${round(
            (this.usedCapacityInMinutes / this.workdaySettings.workingMinutes) * 100,
            0,
        )} %)`;
    }

    public get isOverCapacity(): boolean {
        return this.usedCapacityRelative > 1;
    }

    // 'heute', 'morgen' or the weekday of the given date, for usage in tooltip
    public labelForDate: string;
    // 'heute', 'morgen' or the shortened weekday of the given date, for usage in the preview
    public labelForDateShort: string;

    // The weekday of the given date in German
    public givenWeekdayGerman: string;

    private user: User;
    private subscriptions: Subscription[] = [];

    //*****************************************************************************
    //  Initialization
    //****************************************************************************/
    async ngOnInit() {
        this.givenDayMoment = moment(this.day);
        this.user = this.loggedInUserService.getUser();
        await this.calculateUsedCapacity();
        this.initializeLabelForDate();
        this.subscribeToTaskChanges();
    }

    private initializeLabelForDate() {
        const todayDate = moment().hours(12).minutes(0).seconds(0);
        this.givenWeekdayGerman = this.givenDayMoment.format('dddd');

        // moment.diff does not include the current day, therefore we check isSameDay before.
        if (todayDate.isSame(this.givenDayMoment, 'day')) {
            this.labelForDate = 'heute';
            this.labelForDateShort = 'heute';
            return;
        }

        const diffInDays = this.givenDayMoment.diff(todayDate, 'days');
        // moment.diff does not include the current day, therefore we check isSameDay before.
        // Here we know that 0 means the next day.
        if (diffInDays === 0) {
            this.labelForDate = 'morgen';
            this.labelForDateShort = 'morgen';
            return;
        }
        // Show the weekday if the date is within the next seven days
        this.labelForDate = this.givenWeekdayGerman;
        this.labelForDateShort = this.givenDayMoment.format('dd');
    }

    /**
     * Recalculate the used capacity, if a task is created, changed or deleted.
     */
    private subscribeToTaskChanges() {
        this.subscriptions.push(
            this.taskService.createdInLocalDatabase$.subscribe(async (task) => {
                // Calculation is only necessary, if the task is due on the components day
                if (task.assigneeId === this.user?._id && task.dueDate === this.day && task.estimatedDuration > 0) {
                    await this.calculateUsedCapacity();
                }
            }),
        );

        this.subscriptions.push(
            this.taskService.patchedInLocalDatabase$.subscribe(async ({ patchedRecord, serverShadow }) => {
                if (
                    patchedRecord.dueDate === this.day ||
                    patchedRecord.assigneeId === this.user?._id ||
                    serverShadow?.dueDate === this.day ||
                    serverShadow?.assigneeId === this.user?._id
                ) {
                    await this.calculateUsedCapacity();
                }
            }),
        );

        this.subscriptions.push(
            // Since we do not know, which task was deleted, we have to recalculate the used capacity
            this.taskService.deletedInLocalDatabase$.subscribe(async () => {
                await this.calculateUsedCapacity();
            }),
        );
    }
    /////////////////////////////////////////////////////////////////////////////*/
    //  END Initialization
    /////////////////////////////////////////////////////////////////////////////*/

    /**
     * Calculate the used capacity for the current day.
     * This function is called each time, a task that may impact the capacity is created, changed or deleted.
     *
     * To avoid network and memory overhead, the function only uses the local database.
     */
    private async calculateUsedCapacity() {
        const dueTasks = await this.taskService.localDb.findLocal({
            dueDate: this.day,
            assigneeId: this.user?._id,
        });
        this.usedCapacityInMinutes = dueTasks
            .filter((task) => !isNaN(task.estimatedDuration))
            .reduce((sum, task) => sum + task.estimatedDuration, 0);
        const availableCapacityInMinutes = (this.workdaySettings.active && this.workdaySettings.workingMinutes) ?? 0;
        this.usedCapacityRelative = availableCapacityInMinutes
            ? this.usedCapacityInMinutes / availableCapacityInMinutes
            : 0;
    }

    ngOnDestroy() {
        this.subscriptions.forEach((subscription) => subscription.unsubscribe());
    }
}

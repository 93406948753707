import { Car } from '../../models/reports/car-identification/car';
import { DamageType } from '../../models/reports/damage-calculation/damage-calculation';
import { TaxationType } from '../../models/reports/market-value/valuation';
import { Report } from '../../models/reports/report';
import { matchesGermanLicensePlateFormat } from '../car/matches-german-license-plate-format';
import { formatNumberToGermanLocale } from './format-number-to-german-locale';

/**
 * Formats license plate in a human-readable format.
 *
 * - Example: "NU-AX-2015" -> "NU-AX 2015"
 * - Foreign (non-German) license plate: "W12 3456" -> same value is returned
 *
 * @param licensePlate
 * @param citySeparatorGermany
 */
export function formatLicensePlate(
    licensePlate: string,
    {
        citySeparatorGermany = ' ',
    }: {
        citySeparatorGermany?: ' ' | ':';
    } = {},
): string {
    if (!licensePlate) {
        return '';
    }

    if (!matchesGermanLicensePlateFormat(licensePlate)) {
        return licensePlate;
    }

    const licensePlateParts = licensePlate.split('-');

    /**
     * The EU license plate (introduced in Germany in 1994) does not contain a dash between the city and the rest of the license plate.
     * Remove all dashes from the saved format.
     * Source: https://www.adac.de/infotestrat/adac-im-einsatz/motorwelt/kennzeichen_bindestrich.aspx
     */
    return `${licensePlateParts[0]}${citySeparatorGermany}${licensePlateParts[1]} ${licensePlateParts[2]}`;
}

/**
 * Returns a string of power sources (Diesel, Gas, Electricity, ...) joined with an ampersand (&)
 * @param car
 * @return {string}
 */
export function getPowerSources(car: Car) {
    const powerSources = [];

    if (car.runsOnDiesel) powerSources.push('Diesel');
    if (car.runsOnElectricity) powerSources.push('Elektro');
    if (car.runsOnGasoline) powerSources.push('Benzin');
    if (car.runsOnLPG) powerSources.push('Autogas');
    if (car.runsOnNaturalGasoline) powerSources.push('Erdgas');
    if (car.runsOnSomethingElse) {
        powerSources.push(car.runsOnSomethingElse);
    }

    return powerSources.join(' & ');
}

export function convertToMillimeters(
    number,
    {
        decimalPlaces = 1,
        omitDecimalsIfZero = true,
    }: { decimalPlaces?: number; omitDecimalsIfZero?: boolean; minDecimalPlaces?: number } = {},
) {
    number = number || 0;

    return `${formatNumberToGermanLocale(number, { decimalPlaces, omitDecimalsIfZero })} mm`;
}

export function isTotalLossDamage(report: Report) {
    return new Array<DamageType>('economicTotalLoss', 'totalLoss').includes(report.damageCalculation?.damageType);
}

/**
 * Useful to standardize the calculation of vehicle values depending on the taxation type.
 */
export function getTaxByTaxationType(
    baseValue: number,
    taxationType: TaxationType,
): {
    netValue: number;
    taxAbsolute: number;
    taxPercentHumanReadable: string;
    taxPercent: number;
} {
    let taxAbsolute: number = null;
    let taxPercent: number = null;
    let taxPercentHumanReadable: string = null;
    let netValue: number = null;

    if (baseValue == null) {
        return { netValue, taxAbsolute, taxPercentHumanReadable, taxPercent };
    }

    switch (taxationType) {
        case 'full':
            netValue = baseValue / 1.19;
            taxAbsolute = netValue * 0.19;
            taxPercent = 19;
            taxPercentHumanReadable = '19%';
            break;
        case 'full_16':
            netValue = baseValue / 1.16;
            taxAbsolute = netValue * 0.16;
            taxPercent = 16;
            taxPercentHumanReadable = '16%';
            break;
        case 'margin':
            netValue = baseValue / 1.024;
            taxAbsolute = netValue * 0.024;
            taxPercent = 2.4;
            taxPercentHumanReadable = '2,4%';
            break;
        case 'margin_reduced':
            netValue = baseValue / 1.02;
            taxAbsolute = netValue * 0.02;
            taxPercentHumanReadable = '2%';
            break;
        case 'neutral':
        default:
            netValue = baseValue;
            taxAbsolute = 0;
            taxPercent = 0;
            taxPercentHumanReadable = '0%';
            break;
    }

    return { netValue, taxAbsolute, taxPercentHumanReadable, taxPercent };
}

/**
 * Read a CSV string either as latin1 or utf-8 depending on the presence of a byte order mark.
 * @param file
 */
export async function readCsvStringFromFile(file: File): Promise<string> {
    // Try to read as latin1 first.
    let csvString = await new Promise<string>((resolve, reject) => {
        const fileReader = new FileReader();
        fileReader.addEventListener('load', (event) => {
            resolve(event.target.result.toString());
        });
        fileReader.readAsText(file, 'latin1');
    });

    // If Byte Order Mark is present, the file was meant to be interpreted as UTF-8.
    if (csvString.startsWith('ï»¿')) {
        // The File API is promise-based and always uses utf-8. That's why we use it here (simplicity) but can't use it with latin1.
        csvString = (await file.text())
            // Remove Byte Order Mark which is an invisible character and breaks property access of the first column.
            .replace(/^\uFEFF/, '');
    }

    return csvString;
}

import { HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AppVersionService } from '../services/app-version.service';

@Injectable()
export class SetFrontendVersionHeaderInterceptor implements HttpInterceptor {
    constructor(private appVersionUpdateService: AppVersionService) {}

    intercept(request: HttpRequest<any>, next: HttpHandler) {
        let versionHeader = '';

        const currentVersion = this.appVersionUpdateService.currentVersion;
        if (currentVersion) {
            versionHeader += currentVersion?.appData?.commit;
        }

        const latestVersion = this.appVersionUpdateService.latestVersion;
        if (latestVersion) {
            versionHeader += `; latest=${latestVersion?.appData?.commit}`;
        }

        if (!versionHeader) {
            return next.handle(request);
        }

        // The request shall be immutable. Therefore clone it and assign it new headers.
        const transformedRequest = request.clone({
            setHeaders: {
                ['autoixpert-version']: versionHeader,
            },
        });

        return next.handle(transformedRequest);
    }
}

<div
    id="three-columns-layout-container"
    *ngIf="report"
    [ngClass]="{ 'justify-content-center': report.type === 'shortAssessment' }">
    <!--============================================-->
    <!-- Left Column -->
    <!--============================================-->
    <div id="current-car-condition-container" class="one-third-data-column card card-with-padding">
        <h2>Zustand</h2>
        <!--********** Paint Type **********-->
        <mat-form-field class="mat-block">
            <input
                matInput
                placeholder="Lackart"
                [(ngModel)]="report.car.paintType"
                [matAutocomplete]="paintTypeAutocomplete"
                [disabled]="isReportLocked()"
                (ngModelChange)="filterPaintTypesAutocomplete(report.car.paintType)"
                (change)="saveReport()"
                (focus)="filterPaintTypesAutocomplete(report.car.paintType)" />
        </mat-form-field>
        <mat-autocomplete #paintTypeAutocomplete (optionSelected)="saveReport()">
            <mat-option
                *ngFor="let paintType of filteredPaintTypes"
                [value]="paintType"
                (mousedown)="$event.preventDefault()">
                {{ paintType }}
            </mat-option>
        </mat-autocomplete>

        <!--********** Paint Color **********-->
        <mat-form-field class="mat-block">
            <input
                matInput
                [(ngModel)]="report.car.paintColor"
                placeholder="Lackfarbe"
                [matTooltip]="report.car.paintColorCode ? 'Farbcode: ' + report.car.paintColorCode : ''"
                (change)="saveReport()"
                [disabled]="isReportLocked()" />
        </mat-form-field>

        <ng-container *ngIf="report.type !== 'shortAssessment' && report.type !== 'oldtimerValuationSmall'">
            <!--********** Paint Condition **********-->
            <autocomplete-with-memory
                placeholder="Lackzustand"
                [(ngModel)]="report.car.paintCondition"
                (change)="saveReport()"
                [disabled]="isReportLocked()"
                autocompleteEntryType="paintCondition"></autocomplete-with-memory>

            <!--********** Overall Conditions **********-->
            <autocomplete-with-memory
                placeholder="Allgemeinzustand"
                [(ngModel)]="report.car.generalCondition"
                (change)="saveReport()"
                [disabled]="isReportLocked()"
                autocompleteEntryType="generalCondition"></autocomplete-with-memory>

            <!--********** Car Body Condition **********-->
            <autocomplete-with-memory
                placeholder="Karosseriezustand"
                [(ngModel)]="report.car.autobodyCondition"
                (change)="saveReport()"
                [disabled]="isReportLocked()"
                autocompleteEntryType="autobodyCondition"></autocomplete-with-memory>

            <!--********** Interior Condition **********-->
            <autocomplete-with-memory
                placeholder="Innenraumzustand"
                [(ngModel)]="report.car.interiorCondition"
                (change)="saveReport()"
                [disabled]="isReportLocked()"
                autocompleteEntryType="interiorCondition"></autocomplete-with-memory>

            <!--********** Undercarriage Condition **********-->
            <autocomplete-with-memory
                placeholder="Fahrwerkszustand"
                *ngIf="isUtilityVehicle(report.car.shape)"
                [(ngModel)]="report.car.undercarriageCondition"
                (change)="saveReport()"
                [disabled]="isReportLocked()"
                autocompleteEntryType="undercarriageCondition"></autocomplete-with-memory>

            <!--********** Roadworthiness **********-->
            <autocomplete-with-memory
                placeholder="Fahrfähigkeit"
                autocompleteEntryType="roadworthiness"
                [(ngModel)]="report.car.roadworthiness"
                (change)="resetEmergencyRepair(); saveReport()"
                [disabled]="isReportLocked()"></autocomplete-with-memory>

            <!--============================================-->
            <!-- Emergency Repair -->
            <!--============================================-->
            <section
                id="emergency-repair-section"
                *ngIf="
                    report.car.roadworthiness &&
                    report.car.roadworthiness !== 'verkehrssicher' &&
                    report.damageCalculation
                ">
                <h3>Notreparatur</h3>

                <div class="section-icon-container">
                    <mat-icon id="emergency-repair-section-icon">handyman</mat-icon>
                </div>

                <!--********** State **********-->
                <autocomplete-with-memory
                    placeholder="Status"
                    autocompleteEntryType="emergencyRepairState"
                    [(ngModel)]="report.damageCalculation.emergencyRepair.state"
                    (change)="saveReport(); resetEmergencyRepair(); insertDefaultEmergencyRepairWorkIfEmpty()"
                    [disabled]="isReportLocked()"></autocomplete-with-memory>

                <ng-container *ngIf="report.damageCalculation.emergencyRepair.state">
                    <!--********** Required Work **********-->
                    <div class="emergency-repair-work-container">
                        <mat-form-field class="mat-block">
                            <mat-chip-list
                                #emergencyRepairWorkItemsChipList
                                matTooltip="Einzelne Arbeiten mit Enter oder Komma trennen">
                                <mat-chip
                                    class="mat-chip-light"
                                    *ngFor="let workItem of report.damageCalculation.emergencyRepair.workItems"
                                    [removable]="!isReportLocked()"
                                    (removed)="removeWorkItem(workItem); saveReport()"
                                    [disabled]="isReportLocked()">
                                    {{ workItem }}
                                    <mat-icon matChipRemove *ngIf="!isReportLocked()">close</mat-icon>
                                </mat-chip>
                                <!-- The input should be inside the list or else the chips are rendered below the input. -->
                                <input
                                    placeholder="Notwendige Arbeiten"
                                    #emergencyRepairWorkItemsInput
                                    #emergencyRepairWorkItemsAutocompleteTrigger="matAutocompleteTrigger"
                                    [matAutocomplete]="emergencyRepairWorkItemsAutocomplete"
                                    [matChipInputFor]="emergencyRepairWorkItemsChipList"
                                    (input)="
                                        filterEmergencyRepairWorkItemsAutocomplete(emergencyRepairWorkItemsInput.value)
                                    "
                                    (focus)="
                                        filterEmergencyRepairWorkItemsAutocomplete(emergencyRepairWorkItemsInput.value)
                                    "
                                    (matChipInputTokenEnd)="enterEmergencyRepairWorkItem($event); saveReport()"
                                    [matChipInputAddOnBlur]="true"
                                    [disabled]="isReportLocked()" />
                            </mat-chip-list>
                            <mat-autocomplete
                                #emergencyRepairWorkItemsAutocomplete
                                (optionSelected)="
                                    selectEmergencyRepairWorkItemsFromAutocomplete(
                                        $event,
                                        emergencyRepairWorkItemsInput,
                                        emergencyRepairWorkItemsAutocompleteTrigger
                                    );
                                    saveReport()
                                ">
                                <mat-option
                                    *ngFor="let workItem of filteredEmergencyRepairWorkItems"
                                    [value]="workItem.value"
                                    (mousedown)="$event.preventDefault()">
                                    <div class="autocomplete-option-wrapper">
                                        <div>{{ workItem.value }}</div>
                                        <mat-icon
                                            (click)="forgetEmergencyRepairWorkItem(workItem); $event.stopPropagation()"
                                            matTooltip="Löschen">
                                            delete
                                        </mat-icon>
                                    </div>
                                </mat-option>
                            </mat-autocomplete>
                        </mat-form-field>
                        <mat-icon
                            class="emergency-repair-work-submenu-icon"
                            *ngIf="
                                user?.accessRights.editTextsAndDocumentBuildingBlocks ||
                                userPreferences.otherPeoplePresentDefault.length
                            "
                            [matMenuTriggerFor]="otherPeoplePresentSubmenu">
                            more_vert
                        </mat-icon>
                        <mat-menu #otherPeoplePresentSubmenu>
                            <!--********** Insert Standard Other People Present **********-->
                            <a
                                mat-menu-item
                                [class.disabled]="isReportLocked()"
                                class="menu-item-with-icon"
                                (click)="!isReportLocked() && insertDefaultEmergencyRepairWork()"
                                matTooltip="Zuvor als Standard gesetzte Arbeiten erneut einfügen">
                                <mat-icon>get_app</mat-icon>
                                Standardarbeiten einfügen
                            </a>

                            <!--********** Remember Standard Other People Present **********-->
                            <a
                                mat-menu-item
                                class="menu-item-with-icon"
                                *ngIf="user?.accessRights.editTextsAndDocumentBuildingBlocks"
                                (click)="
                                    rememberDefaultEmergencyRepairWorkItems(
                                        report.damageCalculation.emergencyRepair.workItems
                                    )
                                "
                                matTooltip="Die aktuell ausgewählten Arbeiten bei neuen Gutachten vorbelegen">
                                <mat-icon>auto_fix_high</mat-icon>
                                Notwendige Arbeiten immer einfügen
                            </a>

                            <!--********** Remember Other People Present as Autocomplete Entries **********-->
                            <a
                                mat-menu-item
                                class="menu-item-with-icon"
                                *ngIf="user?.accessRights.editTextsAndDocumentBuildingBlocks"
                                (click)="
                                    addCurrentEmergencyRepairWorkToAutocomplete(
                                        report.damageCalculation.emergencyRepair
                                    )
                                "
                                matTooltip="Arbeiten der Vorschlagsliste hinzufügen für schnellere Auswahl">
                                <mat-icon class="outlined">bookmark_border</mat-icon>
                                Notwendige Arbeiten als Vorschläge merken
                            </a>
                        </mat-menu>
                    </div>
                    <!--********** Costs **********-->
                    <currency-input
                        [(value)]="report.damageCalculation.emergencyRepair.costs"
                        (valueChange)="saveReport()"
                        placeholder="Kosten Notreparatur"
                        matTooltip="Brutto"
                        [disabled]="isReportLocked()"></currency-input>

                    <!--********** Comment **********-->
                    <mat-form-field class="mat-block">
                        <mat-quill
                            [(ngModel)]="report.damageCalculation.emergencyRepair.comment"
                            (change)="saveReport()"
                            placeholder="Kommentar Notreparatur"
                            [disabled]="isReportLocked()"></mat-quill>
                    </mat-form-field>
                </ng-container>
            </section>
            <!--============================================-->
            <!-- END Emergency Repair -->
            <!--============================================-->

            <!--============================================-->
            <!-- Battery  -->
            <!--============================================-->
            <section *ngIf="doesVehicleHaveBatteryElectricEngine(report)" id="battery-section">
                <h3>Hochvoltbatterie</h3>

                <div class="section-icon-container">
                    <mat-icon id="battery-section-icon">electric_bolt</mat-icon>
                </div>

                <!--********** State Of Health **********-->
                <mat-form-field class="mat-block">
                    <input
                        matInput
                        number-input
                        placeholder="Batterieerhaltungszustand"
                        [(number)]="report.car.batteryStateOfHealth"
                        [disabled]="isReportLocked()"
                        [maximumFractionDigits]="0"
                        [minimumFractionDigits]="0"
                        (change)="saveReport()"
                        matTooltip="Nutzbare Kapazität der Batterie. Wird auch als 'Batteriegesundheit' bzw. 'Battery State of Health' bezeichnet" />
                    <div matSuffix>
                        %&nbsp;
                        <mat-icon
                            class="input-toolbar-icon outlined"
                            matTooltip="Kommentar zum Batterieerhaltungszustand"
                            (click)="
                                toggleBatteryStateOfHealthCommentShown(); focusBatteryStateOfHealthCommentRemark()
                            ">
                            chat_bubble
                        </mat-icon>
                    </div>
                </mat-form-field>

                <!--********** Battery State of Health Comment **********-->
                <mat-form-field class="mat-block" *ngIf="batteryStateOfHealthCommentShown">
                    <mat-quill
                        placeholder="Kommentar Batterieerhaltungszustand"
                        [(ngModel)]="report.car.batteryStateOfHealthComment"
                        (change)="saveReport()"
                        #batteryStateOfHealthCommentRemark
                        [disabled]="isReportLocked()"></mat-quill>
                    <div matSuffix *ngIf="!isReportLocked()">
                        <mat-icon
                            class="input-toolbar-icon"
                            matTooltip="Textvorlagen"
                            (click)="batteryStateOfHealthCommentTemplatesShown = true">
                            playlist_add
                        </mat-icon>
                    </div>
                </mat-form-field>
                <text-template-selector
                    [(text)]="report.car.batteryStateOfHealthComment"
                    (textChange)="saveReport()"
                    [report]="report"
                    *ngIf="batteryStateOfHealthCommentTemplatesShown"
                    placeholderForResultingText="Kommentar Batterieerhaltungszustand"
                    (close)="batteryStateOfHealthCommentTemplatesShown = false"
                    [textTemplateType]="'batteryStateOfHealth'"
                    @runChildAnimations></text-template-selector>

                <!--********** Battery Damage **********-->
                <mat-form-field class="mat-block">
                    <mat-select
                        placeholder="Beschädigung Hochvoltbatterie"
                        [(ngModel)]="report.car.batteryDamage"
                        (selectionChange)="saveReport()"
                        [disabled]="isReportLocked()">
                        <mat-option *ngFor="let option of standardBatteryDamageOptions" [value]="option.value">
                            {{ option.label }}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
                <!--********** Battery Damage Comment **********-->
                <mat-form-field class="mat-block">
                    <mat-quill
                        placeholder="Hinweise zum Batterieschaden"
                        [(ngModel)]="report.car.batteryDamageComment"
                        (change)="saveReport()"
                        [disabled]="isReportLocked()"></mat-quill>
                    <div matSuffix *ngIf="!isReportLocked()">
                        <mat-icon
                            class="input-toolbar-icon"
                            matTooltip="Textvorlagen"
                            (click)="batteryDamageCommentTemplatesShown = true">
                            playlist_add
                        </mat-icon>
                    </div>
                </mat-form-field>
                <text-template-selector
                    [(text)]="report.car.batteryDamageComment"
                    (textChange)="saveReport()"
                    [report]="report"
                    *ngIf="batteryDamageCommentTemplatesShown"
                    placeholderForResultingText="Kommentar Batterieschaden"
                    (close)="batteryDamageCommentTemplatesShown = false"
                    [textTemplateType]="'batteryDamage'"
                    @runChildAnimations></text-template-selector>
            </section>
            <!--============================================-->
            <!-- END Battery  -->
            <!--============================================-->

            <!--********** Special Feature **********-->
            <mat-form-field id="special-feature" class="mat-block">
                <mat-quill
                    placeholder="Besonderheiten"
                    [(ngModel)]="report.car.specialFeature"
                    (change)="saveReport()"
                    [disabled]="isReportLocked()"
                    matTooltip="z. B. Tuning-Umbauten, Folierungen"></mat-quill>
            </mat-form-field>
        </ng-container>

        <!--============================================-->
        <!-- Mileage -->
        <!--============================================-->
        <div id="mileage-container">
            <!--********** Mileage read **********-->
            <mat-form-field class="mat-block mileage-input-container">
                <input
                    matInput
                    [(number)]="report.car.mileageMeter"
                    placeholder="Laufleistung abgelesen"
                    (change)="saveReport()"
                    [disabled]="isReportLocked()"
                    matTooltip="Aktueller Wert des Tachos"
                    number-input
                    [minimumFractionDigits]="0"
                    [maximumFractionDigits]="0" />
            </mat-form-field>

            <!--********** Mileage Estimated **********-->
            <mat-form-field class="mat-block mileage-input-container" *ngIf="!mileageAsStatedShown">
                <input
                    matInput
                    placeholder="Laufleistung geschätzt"
                    [(number)]="report.car.mileage"
                    (change)="saveReport()"
                    [disabled]="isReportLocked()"
                    matTooltip="Weicht ab, falls z. B. der Tacho getauscht wurde."
                    number-input
                    [minimumFractionDigits]="0"
                    [maximumFractionDigits]="0" />
            </mat-form-field>

            <!--********** Mileage as Stated By Customer **********-->
            <mat-form-field class="mat-block mileage-input-container" *ngIf="mileageAsStatedShown">
                <input
                    matInput
                    placeholder="Laufleistung angegeben"
                    [(number)]="report.car.mileageAsStated"
                    (change)="saveReport()"
                    [disabled]="isReportLocked()"
                    matTooltip="Relevant, falls der Tacho nicht mehr abgelesen werden kann, z. B. bei Diebstahl oder besonders schweren Unfällen."
                    number-input
                    [minimumFractionDigits]="0"
                    [maximumFractionDigits]="0" />
            </mat-form-field>

            <!--********** Mileage Unit **********-->
            <mat-form-field id="mileage-unit-select">
                <mat-select
                    [(ngModel)]="report.car.mileageUnit"
                    placeholder="Einheit"
                    (selectionChange)="saveReport()"
                    [disabled]="isReportLocked()">
                    <mat-option value="km" matTooltip="Kilometer">km</mat-option>
                    <mat-option value="mi" matTooltip="Meilen">mi</mat-option>
                    <mat-option value="h" matTooltip="Betriebsstunden">h</mat-option>
                </mat-select>
            </mat-form-field>

            <!--********** Submenu **********-->
            <mat-icon
                *ngIf="!isReportLocked()"
                id="mileage-submenu-icon"
                class="toolbar-icon"
                [matMenuTriggerFor]="mileageSubmenu">
                more_vert
            </mat-icon>
        </div>
        <mat-menu #mileageSubmenu>
            <a mat-menu-item (click)="toggleMileageAsStated()">
                Laufleistung wurde {{ mileageAsStatedShown ? 'geschätzt' : 'angegeben' }}
            </a>
            <a mat-menu-item (click)="toggleMileageComment()">
                Kommentarfeld {{ mileageCommentShown ? 'ausblenden' : 'einblenden' }}
            </a>
        </mat-menu>

        <mat-form-field class="mat-block" *ngIf="mileageCommentShown">
            <mat-quill
                [(ngModel)]="report.car.mileageComment"
                placeholder="Kommentar Laufleistung"
                [disabled]="isReportLocked()"
                #mileageRemark
                (change)="saveReport()"></mat-quill>
            <mat-icon
                matSuffix
                *ngIf="!isReportLocked()"
                class="input-toolbar-icon"
                (click)="mileageCommentTextTemplateSelectorShown = true"
                matTooltip="Textvorlagen">
                playlist_add
            </mat-icon>
        </mat-form-field>

        <text-template-selector
            [(text)]="report.car.mileageComment"
            (textChange)="saveReport()"
            [report]="report"
            *ngIf="mileageCommentTextTemplateSelectorShown"
            placeholderForResultingText="Kommentar Laufleistung"
            (close)="mileageCommentTextTemplateSelectorShown = false"
            [textTemplateType]="'mileageRemark'"
            @runChildAnimations></text-template-selector>
        <!--============================================-->
        <!-- END Mileage -->
        <!--============================================-->

        <!--********** General Inspection **********-->
        <general-inspection-input
            [(nextGeneralInspection)]="report.car.nextGeneralInspection"
            (nextGeneralInspectionChange)="saveReport()"
            [disabled]="isReportLocked()"></general-inspection-input>
        <!--********** Safety Inspection - Utility Vehicles Only **********-->
        <safety-test-input
            *ngIf="
                isUtilityVehicle(report.car.shape) &&
                (report.car.maximumTotalWeight >= 7500 || !report.car.maximumTotalWeight)
            "
            [(nextSafetyTest)]="report.car.nextSafetyTest"
            (nextSafetyTestChange)="saveReport()"
            [disabled]="isReportLocked()"></safety-test-input>

        <!--***********************************************
        ** Last Service
        ************************************************-->
        <ng-container *ngIf="report.type === 'leaseReturn' || report.type === 'usedVehicleCheck'">
            <h3 id="last-service-heading">Letzter Service</h3>
            <div id="last-service-container" class="multiple-inputs-row">
                <date-input
                    placeholder="Datum"
                    [(date)]="report.car.lastServiceDate"
                    (dateChange)="saveReport()"
                    [disabled]="isReportLocked()"></date-input>

                <mat-form-field class="mat-block" id="last-service-mileage">
                    <input
                        matInput
                        [(number)]="report.car.lastServiceMileage"
                        placeholder="Laufleistung"
                        (change)="saveReport()"
                        [disabled]="isReportLocked()"
                        matTooltip="Der Kilometerstand des Fahrzeugs beim letzten Service"
                        number-input
                        [minimumFractionDigits]="0"
                        [maximumFractionDigits]="0" />
                    <span matSuffix *ngIf="report.car.mileageUnit">{{ report.car.mileageUnit }}</span>
                </mat-form-field>
            </div>
        </ng-container>
        <!--***********************************************
        ** END Last Service
        ************************************************-->

        <!--============================================-->
        <!-- Special Equipment (only oldtimer valuations) -->
        <!--============================================-->
        <mat-form-field class="mat-block" *ngIf="report.type === 'oldtimerValuationSmall'">
            <mat-chip-list #specialEquipmentChipList>
                <mat-chip
                    *ngFor="let specialEquipment of report.car.specialEquipment"
                    (removed)="removeSpecialEquipment(specialEquipment); saveReport()"
                    [removable]="report.state !== 'done'"
                    [disabled]="report.state === 'done'">
                    {{ specialEquipment }}
                    <mat-icon matChipRemove *ngIf="report.state !== 'done'">close</mat-icon>
                </mat-chip>
                <!-- The input should be inside the list or else the chips are rendered below the input. -->
                <input
                    placeholder="Wertbeeinflussende Faktoren"
                    #specialEquipmentInput
                    #specialEquipmentAutocompleteTrigger="matAutocompleteTrigger"
                    [matAutocomplete]="specialEquipmentAutocomplete"
                    [matChipInputFor]="specialEquipmentChipList"
                    (input)="filterSpecialEquipmentAutocomplete($any($event.target).value)"
                    (focus)="filterSpecialEquipmentAutocomplete($any($event.target).value)"
                    (matChipInputTokenEnd)="enterSpecialEquipment($event); saveReport()"
                    [disabled]="report.state === 'done'" />
            </mat-chip-list>
            <mat-autocomplete
                #specialEquipmentAutocomplete="matAutocomplete"
                (mousedown)="$event.preventDefault()"
                (optionSelected)="
                    selectSpecialEquipmentFromAutocomplete(
                        $event,
                        specialEquipmentInput,
                        specialEquipmentAutocompleteTrigger
                    );
                    saveReport()
                ">
                <mat-option
                    *ngFor="let specialEquipmentItem of filteredSpecialEquipment"
                    [value]="specialEquipmentItem">
                    {{ specialEquipmentItem }}
                </mat-option>
            </mat-autocomplete>
        </mat-form-field>
        <!--============================================-->
        <!-- END Special Equipment (only oldtimer valuations) -->
        <!--============================================-->

        <div
            *ngIf="report.type !== 'oldtimerValuationSmall'"
            class="checkbox-group"
            [ngClass]="{ centered: report.type === 'shortAssessment' }">
            <tri-state-checkbox
                [(ngModel)]="report.car.serviceBookComplete"
                (change)="saveReport()"
                [disabled]="isReportLocked()"
                matTooltip="Wurden alle Wartungsarbeiten bei einer Vertragswerkstatt durchgeführt?

            Falls ja, können die Werkstattkostensätze von Markenwerkstätten zugrunde gelegt werden.">
                Scheckheftgepflegt
            </tri-state-checkbox>
        </div>
        <ng-container *ngIf="report.type !== 'shortAssessment' && report.type !== 'oldtimerValuationSmall'">
            <div class="checkbox-group">
                <mat-checkbox
                    [(ngModel)]="report.testDriveCarriedOut"
                    (change)="saveReport()"
                    [disabled]="isReportLocked()">
                    Probefahrt durchgeführt
                </mat-checkbox>
            </div>

            <div class="checkbox-group">
                <mat-checkbox
                    [(ngModel)]="report.errorLogReadOut"
                    (change)="saveReport()"
                    [disabled]="isReportLocked()">
                    Fehlerspeicher ausgelesen
                </mat-checkbox>
            </div>
            <div
                *ngIf="!['trailer', 'motorcycle', 'bicycle', 'e-bike', 'pedelec'].includes(report.car.shape)"
                class="checkbox-group">
                <mat-checkbox
                    [(ngModel)]="report.car.airbagsReleased"
                    (change)="saveReport()"
                    [disabled]="isReportLocked()">
                    Airbags ausgelöst
                </mat-checkbox>
            </div>
            <mat-form-field *ngIf="report.car.airbagsReleased" class="mat-block">
                <mat-quill
                    placeholder="Kommentar Airbags"
                    [(ngModel)]="report.car.airbagsComment"
                    (change)="saveReport()"
                    [disabled]="isReportLocked()"></mat-quill>
            </mat-form-field>

            <div
                id="emission-groups-section"
                *ngIf="!['trailer', 'motorcycle', 'bicycle', 'e-bike', 'pedelec'].includes(report.car.shape)">
                <h3>Schadstoffgruppen</h3>
                <div id="emission-groups-container" class="options-row">
                    <div
                        class="emission-group-container option-container"
                        *ngFor="let emissionGroup of emissionGroups"
                        [ngClass]="{
                            active: emissionGroup === report.car.emissionGroup,
                            unselectable: isReportLocked(),
                        }"
                        (click)="!isReportLocked() && selectEmissionGroup(emissionGroup)"
                        (keydown)="triggerClickEventOnSpaceBarPress($event)"
                        tabindex="0">
                        <img
                            class="option-image"
                            src="/assets/images/icons/emission-group-{{ emissionGroup }}.png"
                            alt="Emissionsgruppe {{ emissionGroup }}" />
                        <div class="option-title">Gruppe {{ emissionGroup }}</div>
                    </div>
                </div>
            </div>
            <mat-form-field
                *ngIf="doesVehicleHaveInternalCombustionEngine(report) || report.car.emissionStandard"
                class="mat-block">
                <input
                    matInput
                    [(ngModel)]="report.car.emissionStandard"
                    (ngModelChange)="filterEmissionStandards()"
                    (focus)="filterEmissionStandards()"
                    placeholder="Abgasnorm"
                    [matAutocomplete]="emissionClassAutocomplete"
                    (change)="saveReport()"
                    [disabled]="isReportLocked()" />
                <mat-autocomplete #emissionClassAutocomplete (optionSelected)="saveReport()">
                    <mat-option
                        class="compact-option"
                        [value]="emissionStandard"
                        *ngFor="let emissionStandard of filteredEmissionStandards"
                        (mousedown)="$event.preventDefault()">
                        {{ emissionStandard }}
                    </mat-option>
                    <mat-optgroup label="LKW & Bus" *ngIf="filteredUtilityVehicleEmissionStandards.length">
                        <mat-option
                            *ngFor="let utilityVehicleEmissionStandards of filteredUtilityVehicleEmissionStandards"
                            class="compact-option"
                            [value]="utilityVehicleEmissionStandards"
                            (mousedown)="$event.preventDefault()">
                            {{ utilityVehicleEmissionStandards }}
                        </mat-option>
                    </mat-optgroup>
                </mat-autocomplete>
            </mat-form-field>
        </ng-container>

        <ng-container *ngIf="report.type !== 'oldtimerValuationSmall'">
            <h3>Bemerkungen</h3>
            <mat-form-field class="notes mat-block">
                <mat-quill
                    [(ngModel)]="report.car.comment"
                    (change)="saveReport()"
                    [disabled]="isReportLocked()"></mat-quill>
                <mat-icon
                    matSuffix
                    *ngIf="!isReportLocked()"
                    class="input-toolbar-icon"
                    (click)="showCarCommentTextTemplates = true"
                    matTooltip="Textvorlagen">
                    playlist_add
                </mat-icon>
            </mat-form-field>
            <text-template-selector
                [(text)]="report.car.comment"
                (textChange)="saveReport()"
                [report]="report"
                *ngIf="showCarCommentTextTemplates"
                placeholderForResultingText="Bemerkungen zum Fahrzeug"
                (close)="showCarCommentTextTemplates = false"
                [textTemplateType]="'carComment'"
                @runChildAnimations></text-template-selector>
        </ng-container>

        <ng-container *ngIf="report.type === 'oldtimerValuationSmall'">
            <!--********** Vehicle description **********-->
            <mat-form-field class="mat-block">
                <mat-quill
                    placeholder="Fahrzeugbeschreibung"
                    [(ngModel)]="report.car.comment"
                    (change)="saveReport()"></mat-quill>
                <mat-icon
                    matSuffix
                    class="input-toolbar-icon"
                    (click)="showVehicleDescriptionTextTemplateSelectorShown = true">
                    playlist_add
                </mat-icon>
            </mat-form-field>
            <text-template-selector
                [(text)]="report.car.comment"
                (textChange)="saveReport()"
                [report]="report"
                *ngIf="showVehicleDescriptionTextTemplateSelectorShown"
                placeholderForResultingText="Fahrzeugbeschreibung"
                (close)="showVehicleDescriptionTextTemplateSelectorShown = false"
                [textTemplateType]="'oldtimerValuationVehicleDescription'"
                @runChildAnimations></text-template-selector>
        </ng-container>
        <!--============================================-->
        <!-- Custom Fields -->
        <!--============================================-->
        <custom-field-display
            fieldLocation="car-condition_condition"
            [report]="report"
            (reportChange)="saveReport()"
            [disabled]="isReportLocked()"></custom-field-display>
        <!--============================================-->
        <!-- END Custom Fields -->
        <!--============================================-->
    </div>
    <!--============================================-->
    <!-- END Left Column -->
    <!--============================================-->

    <!--============================================-->
    <!-- Center And Right Column -->
    <!--============================================-->
    <div class="two-thirds-data-column" (change)="saveReport()" *ngIf="report.type !== 'shortAssessment'">
        <div
            id="damages-and-tires-row"
            [ngClass]="{ 'do-not-enforce-same-height': report.type === 'oldtimerValuationSmall' }">
            <!-------------- Damages container -->
            <div id="damages-container" class="one-third-column card card-with-padding">
                <!--============================================-->
                <!-- Paint Thickness Toolbar -->
                <!--============================================-->
                <div class="card-toolbar" *ngIf="selectedVehicleSketchTab === 'paintThicknessSketch'">
                    <mat-icon class="toolbar-icon" [matMenuTriggerFor]="paintThicknessMenu">more_vert</mat-icon>
                    <mat-menu #paintThicknessMenu>
                        <a
                            mat-menu-item
                            *ngIf="!paintThicknessCommentShown && !isReportLocked()"
                            (click)="paintThicknessCommentShown = true">
                            Kommentar anzeigen
                        </a>
                        <a
                            mat-menu-item
                            class="menu-item-with-icon"
                            (click)="togglePaintThicknessExcludeClassification(); saveTeam()"
                            [matTooltip]="
                                hasAccessRight(user, 'editTextsAndDocumentBuildingBlocks')
                                    ? 'Mit dieser Option steuerst du, ob die farbigen Icons im Gutachten neben den Messwerten abgedruckt werden.'
                                    : getMissingAccessRightTooltip('editTextsAndDocumentBuildingBlocks')
                            "
                            [disabled]="!hasAccessRight(user, 'editTextsAndDocumentBuildingBlocks')">
                            Legende und Farben abdrucken
                            <mat-slide-toggle
                                class="slide-toggle-small margin-left-15"
                                [ngModel]="!team.preferences.paintThicknessMeasurementExcludeClassification"
                                (ngModelChange)="
                                    team.preferences.paintThicknessMeasurementExcludeClassification = !$event;
                                    saveTeam()
                                "
                                [disabled]="!hasAccessRight(user, 'editTextsAndDocumentBuildingBlocks')"
                                (click)="$event.stopPropagation()"></mat-slide-toggle>
                        </a>
                    </mat-menu>
                </div>
                <!--============================================-->
                <!-- END Paint Thickness Toolbar -->
                <!--============================================-->

                <!--============================================-->
                <!-- Tabs -->
                <!--============================================-->
                <div class="tabs-container">
                    <h2
                        class="tab-heading"
                        [ngClass]="{ selected: selectedVehicleSketchTab === 'oldtimerGrades' }"
                        *ngIf="report.type === 'oldtimerValuationSmall'"
                        (click)="selectVehicleSketchTab('oldtimerGrades')">
                        Benotung
                    </h2>
                    <h2
                        class="tab-heading"
                        [ngClass]="{ selected: selectedVehicleSketchTab === 'damageSketch' }"
                        *ngIf="report.type !== 'oldtimerValuationSmall'"
                        (click)="selectVehicleSketchTab('damageSketch')">
                        Schäden
                    </h2>
                    <h2
                        class="tab-heading"
                        [ngClass]="{ selected: selectedVehicleSketchTab === 'axleSketch' }"
                        (click)="selectVehicleSketchTab('axleSketch')"
                        *ngIf="isAxleConfiguratorRequired(report.car)">
                        Achsen
                    </h2>
                    <h2
                        *ngIf="!paintThicknessHidden"
                        id="tab-heading-paint-thickness"
                        class="tab-heading"
                        [ngClass]="{ selected: selectedVehicleSketchTab === 'paintThicknessSketch' }"
                        (click)="selectVehicleSketchTab('paintThicknessSketch')">
                        Lack

                        <ng-container *ngIf="hasPaintThicknessMeasurement(report)">
                            <!--********** Complete **********-->
                            <!-- Use SVG instead of font because it's rendered clearer. -->
                            <mat-icon
                                id="paint-thickness-complete-icon"
                                class="small-icon paint-thickness-progress-icon"
                                *ngIf="isPaintThicknessMeasurementComplete(report)"
                                matTooltip="Lackschichtdicke vollständig ausgefüllt">
                                check
                            </mat-icon>

                            <!--********** Partially Complete **********-->
                            <mat-icon
                                class="small-icon paint-thickness-progress-icon"
                                *ngIf="!isPaintThicknessMeasurementComplete(report)"
                                matTooltip="Lackschichtdicke teilweise ausgefüllt">
                                rule
                            </mat-icon>
                        </ng-container>
                    </h2>
                </div>
                <!--============================================-->
                <!-- END Tabs -->
                <!--============================================-->
                <!--============================================-->
                <!-- Damage Checkboxes -->
                <!--============================================-->
                <ng-container *ngIf="selectedVehicleSketchTab === 'damageSketch'">
                    <div class="car-contour-vertical-alignment-wrapper">
                        <div class="car-contour-container {{ report.car.shape }}">
                            <!-------------- Real Tires, no pseudo elements -->
                            <div class="tire-positioning-anchor" *ngIf="!isAxleConfiguratorRequired(report.car)">
                                <div
                                    *ngFor="let tire of tires"
                                    class="tire axle-{{ tire.axle }} position-{{ tire.position }}"
                                    [ngClass]="{
                                        hovered: isTireBeingHovered(tire),
                                        active: tire === selectedTire || isTireActive(tire),
                                    }">
                                    <img
                                        src="/assets/images/icons/{{ getCarTireImageName(report.car.shape) }}"
                                        class="tire-image" />
                                </div>
                            </div>

                            <!-------------- Drawing of the car -->
                            <img
                                class="car-contour"
                                [src]="
                                    '/assets/images/car-contours/' + getCarContourImageName(report.car.shape) + '.png'
                                " />

                            <!-------------- Pseudo elements that, on mouseover, trigger the tiresForUI to move -->
                            <div
                                class="tire-positioning-anchor tire-pseudo-positioning-anchor"
                                *ngIf="!isAxleConfiguratorRequired(report.car)">
                                <div
                                    *ngFor="let tire of tires"
                                    (mouseenter)="markTireAsBeingHovered(tire)"
                                    (mouseout)="unmarkTireAsBeingHovered(tire)"
                                    (click)="selectTire(tire)"
                                    class="tire pseudo-tire axle-{{ tire.axle }} position-{{ tire.position }}"
                                    id="pseudo-{{ tire.position }}-tire"
                                    [ngClass]="{
                                        hovered: isTireBeingHovered(tire),
                                        active: tire === selectedTire || isTireActive(tire),
                                    }"></div>
                            </div>

                            <!-------------- Checkboxen -->
                            <div id="damage-checkboxes-positioning-anchor" [class]="report.car.shape || 'sedan'">
                                <damage-checkbox
                                    class="damage-checkbox"
                                    id="{{ damagePosition }}"
                                    *ngFor="let damagePosition of damagePositions"
                                    [damagePosition]="damagePosition"
                                    [damage]="getDamageByPosition(damagePosition)"
                                    [descriptionShown]="isDamageDescriptionShown(damagePosition)"
                                    [disabled]="isReportLocked()"
                                    (damageToggle)="toggleDamageOnReport(damagePosition); saveReport()"
                                    (damageChange)="saveReport()"
                                    (descriptionOpened)="closeAllDescriptionsButThisOne(damagePosition)"
                                    (descriptionClosed)="closeDamageDescription(damagePosition)"></damage-checkbox>
                            </div>
                        </div>
                    </div>

                    <!--============================================-->
                    <!-- Custom Fields -->
                    <!--============================================-->
                    <custom-field-display
                        class="margin-top-20"
                        fieldLocation="car-condition_damage-sketch"
                        [report]="report"
                        (reportChange)="saveReport()"
                        [disabled]="isReportLocked()"></custom-field-display>
                    <!--============================================-->
                    <!-- END Custom Fields -->
                    <!--============================================-->
                </ng-container>
                <!--============================================-->
                <!-- END Damage Checkboxes -->
                <!--============================================-->

                <!--============================================-->
                <!-- Axles for Utility Vehicles -->
                <!--============================================-->
                <div
                    id="axles-sketch-tab-content"
                    *ngIf="selectedVehicleSketchTab === 'axleSketch'"
                    @blockChildAnimationOnLoad>
                    <div id="axles-container">
                        <!--********** Power Train **********-->
                        <div id="power-train"></div>

                        <!--********** Gearbox **********-->
                        <img
                            id="gearbox-image"
                            src="/assets/images/axle-sketch/gearbox.png"
                            alt=""
                            *ngIf="vehicleShapeHasEngine()" />

                        <div
                            class="axle-container"
                            *ngFor="let axle of report.car.axles"
                            [ngClass]="{ 'first-rear-axle': axle === firstRearAxle, steerable: axle.isSteerable }">
                            <!--********** Outer Left **********-->
                            <div
                                class="tire-container"
                                *ngIf="axle.outerLeftTire"
                                [ngClass]="{
                                    selected: selectedTire === axle.outerLeftTire || isTireActive(axle.outerLeftTire),
                                    complete: isTireInfoComplete(axle.outerLeftTire),
                                }"
                                (click)="selectTire(axle.outerLeftTire)"
                                @fadeInAndSlide>
                                <img src="/assets/images/icons/tire-truck.png" alt="" />
                                <!--********** Edit Pen / Checkmark **********-->
                                <mat-icon
                                    class="tire-filled-out-icon"
                                    *ngIf="isTireInfoComplete(axle.outerLeftTire)"
                                    @fadeInAndOut>
                                    check
                                </mat-icon>
                            </div>

                            <!--********** Left **********-->
                            <div
                                class="tire-container"
                                *ngIf="axle.leftTire"
                                [ngClass]="{
                                    selected: selectedTire === axle.leftTire || isTireActive(axle.leftTire),
                                    complete: isTireInfoComplete(axle.leftTire),
                                }"
                                (click)="selectTire(axle.leftTire)">
                                <img src="/assets/images/icons/tire-truck.png" alt="" />
                                <!--********** Edit Pen / Checkmark **********-->
                                <mat-icon
                                    class="tire-filled-out-icon"
                                    *ngIf="isTireInfoComplete(axle.leftTire)"
                                    @fadeInAndOut>
                                    check
                                </mat-icon>
                            </div>

                            <!--********** Move Axle to Rear **********-->
                            <mat-icon
                                class="toolbar-icon move-axle-to-rear-icon"
                                *ngIf="axle === lastFrontAxle && !isReportLocked()"
                                (click)="moveAxleToRear(axle); saveReport()"
                                matTooltip="Zu Hinterachse machen">
                                arrow_downward
                            </mat-icon>

                            <!--********** Axle Image **********-->
                            <div class="axle-image-container">
                                <img class="axle-image" src="/assets/images/axle-sketch/axle.png" alt="" />
                                <img
                                    class="steering-wheel toolbar-icon"
                                    src="/assets/images/axle-sketch/steering-wheel.svg"
                                    alt=""
                                    *ngIf="!isReportLocked()"
                                    (click)="toggleSteerability(axle); saveReport()"
                                    [matTooltip]="
                                        axle.isSteerable
                                            ? 'Achse als nicht lenkbar markieren'
                                            : 'Achse als lenkbar markieren'
                                    " />
                            </div>

                            <!--********** Move Axle to Front **********-->
                            <!-- It should not be possible to move the last rear axle to the front. That config is not possible. -->
                            <mat-icon
                                class="toolbar-icon move-axle-to-front-icon"
                                *ngIf="axle === firstRearAxle && axle !== lastRearAxle && !isReportLocked()"
                                (click)="moveAxleToFront(axle); saveReport()"
                                matTooltip="Zu Vorderachse machen">
                                arrow_upward
                            </mat-icon>

                            <!--********** Right **********-->
                            <div
                                class="tire-container"
                                *ngIf="axle.rightTire"
                                [ngClass]="{
                                    selected: selectedTire === axle.rightTire || isTireActive(axle.rightTire),
                                    complete: isTireInfoComplete(axle.rightTire),
                                }"
                                (click)="selectTire(axle.rightTire)">
                                <img src="/assets/images/icons/tire-truck.png" alt="" />
                                <!--********** Edit Pen / Checkmark **********-->
                                <mat-icon
                                    class="tire-filled-out-icon"
                                    *ngIf="isTireInfoComplete(axle.rightTire)"
                                    @fadeInAndOut>
                                    check
                                </mat-icon>
                            </div>

                            <!--********** Outer Right **********-->
                            <div
                                class="tire-container"
                                *ngIf="axle.outerRightTire"
                                [ngClass]="{
                                    selected: selectedTire === axle.outerRightTire || isTireActive(axle.outerRightTire),
                                    complete: isTireInfoComplete(axle.outerRightTire),
                                }"
                                (click)="selectTire(axle.outerRightTire)"
                                @fadeInAndSlide>
                                <img src="/assets/images/icons/tire-truck.png" alt="" />
                                <!--********** Edit Pen / Checkmark **********-->
                                <mat-icon
                                    class="tire-filled-out-icon"
                                    *ngIf="isTireInfoComplete(axle.outerRightTire)"
                                    @fadeInAndOut>
                                    check
                                </mat-icon>
                            </div>

                            <!--********** Add Outer Tires **********-->
                            <mat-icon
                                class="toolbar-icon add-outer-tires-icon"
                                *ngIf="!axle.outerLeftTire && !axle.outerRightTire && !isReportLocked()"
                                (click)="addTwinTires(axle); saveReport()"
                                matTooltip="Doppelbereifung hinzufügen">
                                add
                            </mat-icon>

                            <!--********** Remove Outer Tires **********-->
                            <mat-icon
                                class="toolbar-icon remove-outer-tires-icon"
                                *ngIf="(axle.outerLeftTire || axle.outerRightTire) && !isReportLocked()"
                                (click)="removeTwinTires(axle); saveReport()"
                                matTooltip="Doppelbereifung entfernen">
                                delete
                            </mat-icon>
                        </div>
                    </div>
                </div>
                <!--============================================-->
                <!-- END Axles for Utility Vehicles -->
                <!--============================================-->

                <!--============================================-->
                <!-- Paint Thickness -->
                <!--============================================-->
                <paint-thickness
                    *ngIf="selectedVehicleSketchTab === 'paintThicknessSketch'"
                    [report]="report"
                    [tires]="tires"
                    [paintThicknessCommentShown]="paintThicknessCommentShown"></paint-thickness>
                <!--============================================-->
                <!-- END Paint Thickness -->
                <!--============================================-->

                <!--============================================-->
                <!-- Oldtimer Grades -->
                <!--============================================-->
                <oldtimer-grades
                    *ngIf="selectedVehicleSketchTab === 'oldtimerGrades'"
                    [report]="report"
                    (gradesChange)="saveReport()"></oldtimer-grades>
                <!--============================================-->
                <!-- END Oldtimer Grades -->
                <!--============================================-->
            </div>

            <!-------------- Tire Details -->
            <div id="tires-container" class="one-third-column card card-with-padding">
                <!--********** Card Toolbar **********-->
                <div class="card-toolbar" *ngIf="carShapeHasSpareTires">
                    <mat-icon
                        (click)="addSecondTireSet()"
                        class="toolbar-icon"
                        *ngIf="!report.car.hasSecondTireSet && !isReportLocked()"
                        matTooltip="Zweiten Reifensatz hinzufügen">
                        add
                    </mat-icon>
                    <mat-icon *ngIf="!isReportLocked()" class="toolbar-icon" [matMenuTriggerFor]="tireCardSubmenu">
                        more_vert
                    </mat-icon>
                    <mat-menu #tireCardSubmenu>
                        <!--********** Show Spare Tire **********-->
                        <a
                            mat-menu-item
                            class="menu-item-with-icon"
                            *ngIf="!userPreferences.spareTireEquipmentShown"
                            (click)="userPreferences.spareTireEquipmentShown = true">
                            <mat-icon>visibility</mat-icon>
                            Notbereifung einblenden
                        </a>

                        <!--********** Hide Spare Tire **********-->
                        <a
                            mat-menu-item
                            class="menu-item-with-icon"
                            *ngIf="userPreferences.spareTireEquipmentShown"
                            (click)="hideSpareTireEquipmentSection()">
                            <mat-icon>visibility_off</mat-icon>
                            Notbereifung ausblenden
                        </a>

                        <!--********** Delete second tire set **********-->
                        <a
                            mat-menu-item
                            class="menu-item-with-icon"
                            *ngIf="report.car.hasSecondTireSet"
                            (click)="deleteSecondTireSet()">
                            <mat-icon>delete</mat-icon>
                            Zweiten Reifensatz löschen
                        </a>
                    </mat-menu>
                </div>

                <tires-editor
                    [car]="report.car"
                    [selectedTireRef]="selectedTire"
                    (tireChange)="saveReport()"
                    (copyToAll)="bounceAllTires(); switchToAxleSketchIfAvailable()"
                    (copyOnAxle)="bounceAllTiresOnAxle(); switchToAxleSketchIfAvailable()"
                    (tireSelect)="selectTire($event)"
                    (axleSwitchesUsed)="switchToAxleSketchIfAvailable()"
                    (tireTabsUsed)="switchToAxleSketchIfAvailable()"
                    [initializeAxles$]="updateTireEditorTires$"
                    [disabled]="isReportLocked()"></tires-editor>

                <spare-tire-equipment-editor
                    [userPreferences]="userPreferences"
                    [report]="report"
                    (dataChange)="saveReport()"
                    [disabled]="isReportLocked()"></spare-tire-equipment-editor>

                <!--============================================-->
                <!-- Custom Fields -->
                <!--============================================-->
                <custom-field-display
                    fieldLocation="car-condition_tires"
                    [report]="report"
                    (reportChange)="saveReport()"
                    [disabled]="isReportLocked()"></custom-field-display>
                <!--============================================-->
                <!-- END Custom Fields -->
                <!--============================================-->
            </div>
        </div>

        <div id="damage-details-container" *ngIf="report.type !== 'oldtimerValuationSmall'">
            <div id="damage-details-tabs-container" class="card dark-card">
                <div
                    class="damage-details-tab"
                    [ngClass]="{ active: selectedDamageTab === 'previousDamages' }"
                    (click)="selectDamageTab('previousDamages')">
                    <div class="damage-details-tab-icon-container">
                        <mat-icon class="damage-details-tab-icon">build</mat-icon>
                        <mat-icon
                            *ngIf="previousDamagesPartiallyComplete()"
                            class="damage-details-tab-completed-icon"
                            @fadeInAndOut
                            matTooltip="Bearbeitet">
                            check
                        </mat-icon>
                    </div>
                    <div>
                        Vor- &
                        <br />
                        Altschäden
                    </div>
                </div>
                <div
                    class="damage-details-tab"
                    [ngClass]="{ active: selectedDamageTab === 'damageDescription' }"
                    (click)="selectDamageTab('damageDescription')">
                    <div class="damage-details-tab-icon-container">
                        <mat-icon class="damage-details-tab-icon">description</mat-icon>
                        <mat-icon
                            *ngIf="damageDescriptionPartiallyComplete()"
                            class="damage-details-tab-completed-icon"
                            @fadeInAndOut
                            matTooltip="Bearbeitet">
                            check
                        </mat-icon>
                    </div>
                    <div>
                        Schaden-
                        <br />
                        beschreibung
                    </div>
                </div>
            </div>
            <div id="damage-details-inputs-container" class="card card-with-padding">
                <div class="card-toolbar" *ngIf="selectedDamageTab === 'damageDescription' && report.accident">
                    <mat-icon class="toolbar-icon" [matMenuTriggerFor]="damageDescriptionMenu">more_vert</mat-icon>
                    <mat-menu #damageDescriptionMenu>
                        <mat-option (click)="toggleCompatibilityShown()">
                            Kompatibilität {{ userPreferences.compatibilityShown ? 'verbergen' : 'anzeigen' }}
                        </mat-option>
                    </mat-menu>
                </div>

                <!--============================================-->
                <!-- Previous Damages Tab -->
                <!--============================================-->
                <ng-container *ngIf="selectedDamageTab === 'previousDamages'">
                    <h3>Vor- & Altschäden</h3>

                    <!--********** Repaired Damages **********-->
                    <div class="input-with-standard-text-button">
                        <mat-form-field id="previous-damages" class="mat-block">
                            <mat-quill
                                placeholder="Vorschäden (repariert)"
                                [(ngModel)]="report.car.repairedPreviousDamage"
                                (change)="saveReport()"
                                [disabled]="isReportLocked()"></mat-quill>
                            <mat-icon
                                matSuffix
                                *ngIf="!isReportLocked()"
                                class="input-toolbar-icon"
                                (click)="showPreviousDamageTextTemplateSelector = true"
                                matTooltip="Textvorlagen">
                                playlist_add
                            </mat-icon>
                        </mat-form-field>
                        <div
                            class="standard-text-button-container"
                            *ngIf="
                                team.preferences.previousDamageStandardText &&
                                !report.car.repairedPreviousDamage &&
                                !isReportLocked()
                            ">
                            <button
                                class="secondary"
                                (click)="insertPreviousDamageStandardText()"
                                matTooltip="Standardtext einfügen">
                                Standardtext
                            </button>
                        </div>
                        <text-template-selector
                            [(text)]="report.car.repairedPreviousDamage"
                            (textChange)="saveReport()"
                            [report]="report"
                            associatedTeamPreference="previousDamageStandardText"
                            *ngIf="showPreviousDamageTextTemplateSelector"
                            placeholderForResultingText="Vorschäden (repariert)"
                            (close)="showPreviousDamageTextTemplateSelector = false"
                            [textTemplateType]="'repairedPreviousDamage'"
                            @runChildAnimations></text-template-selector>
                    </div>

                    <!--********** Unrepaired Damages **********-->
                    <div class="input-with-standard-text-button">
                        <mat-form-field id="unrepaired-damages" class="mat-block">
                            <mat-quill
                                placeholder="Altschäden (nicht repariert)"
                                (change)="saveReport()"
                                [(ngModel)]="report.car.unrepairedPreviousDamage"
                                [disabled]="isReportLocked()"></mat-quill>
                            <div matSuffix *ngIf="!isReportLocked()">
                                <mat-icon
                                    class="input-toolbar-icon"
                                    (click)="showUnrepairedDamageTextTemplateSelector = true"
                                    matTooltip="Textvorlagen">
                                    playlist_add
                                </mat-icon>
                            </div>
                        </mat-form-field>
                        <div
                            class="standard-text-button-container"
                            *ngIf="
                                team.preferences.unrepairedDamageStandardText &&
                                !report.car.unrepairedPreviousDamage &&
                                !isReportLocked()
                            ">
                            <button
                                class="secondary"
                                (click)="insertUnrepairedDamageStandardText()"
                                matTooltip="Standardtext einfügen">
                                Standardtext
                            </button>
                        </div>
                        <text-template-selector
                            [(text)]="report.car.unrepairedPreviousDamage"
                            (textChange)="saveReport()"
                            associatedTeamPreference="unrepairedDamageStandardText"
                            [report]="report"
                            *ngIf="showUnrepairedDamageTextTemplateSelector"
                            placeholderForResultingText="Altschäden (nicht repariert)"
                            (close)="showUnrepairedDamageTextTemplateSelector = false"
                            [textTemplateType]="'unrepairedPreviousDamage'"
                            @runChildAnimations></text-template-selector>
                    </div>

                    <!--********** Meantime Damage **********-->
                    <div
                        class="input-with-standard-text-button"
                        *ngIf="report.type !== 'leaseReturn' && report.type !== 'usedVehicleCheck'">
                        <mat-form-field id="meantime-damages" class="mat-block">
                            <mat-quill
                                placeholder="Nachschäden (zwischen Unfall & Besichtigung geschehen)"
                                (change)="saveReport()"
                                [(ngModel)]="report.car.meantimeDamage"
                                [disabled]="isReportLocked()"></mat-quill>
                            <div matSuffix *ngIf="!isReportLocked()">
                                <mat-icon
                                    class="input-toolbar-icon"
                                    (click)="showMeantimeDamageTextTemplateSelector = true"
                                    matTooltip="Textvorlagen">
                                    playlist_add
                                </mat-icon>
                            </div>
                        </mat-form-field>
                        <div
                            class="standard-text-button-container"
                            *ngIf="
                                team.preferences.meantimeDamageStandardText &&
                                !report.car.meantimeDamage &&
                                !isReportLocked()
                            ">
                            <button
                                class="secondary"
                                (click)="insertMeantimeDamageStandardText()"
                                matTooltip="Standardtext einfügen">
                                Standardtext
                            </button>
                        </div>
                        <text-template-selector
                            [(text)]="report.car.meantimeDamage"
                            (textChange)="saveReport()"
                            [report]="report"
                            *ngIf="showMeantimeDamageTextTemplateSelector"
                            associatedTeamPreference="meantimeDamageStandardText"
                            placeholderForResultingText="Nachschäden (zwischen Unfall & Besichtigung geschehen)"
                            (close)="showMeantimeDamageTextTemplateSelector = false"
                            [textTemplateType]="'meantimeDamage'"
                            @runChildAnimations></text-template-selector>
                    </div>

                    <!--============================================-->
                    <!-- Custom Fields -->
                    <!--============================================-->
                    <custom-field-display
                        fieldLocation="car-condition_previous-and-unrepaired-damages"
                        [report]="report"
                        (reportChange)="saveReport()"
                        [disabled]="isReportLocked()"></custom-field-display>
                    <!--============================================-->
                    <!-- END Custom Fields -->
                    <!--============================================-->
                </ng-container>
                <!--============================================-->
                <!-- END Previous Damages Tab -->
                <!--============================================-->

                <!--============================================-->
                <!-- Damage Description Tab -->
                <!--============================================-->
                <ng-container *ngIf="selectedDamageTab === 'damageDescription'">
                    <h3 *ngIf="report.type !== 'leaseReturn' && report.type !== 'usedVehicleCheck'">
                        Schadenbeschreibung & -hergang
                    </h3>
                    <h3 *ngIf="report.type === 'leaseReturn' || report.type === 'usedVehicleCheck'">
                        Schadenbeschreibung
                    </h3>

                    <!--********** Damage Description **********-->
                    <div class="input-with-standard-text-button">
                        <mat-form-field class="mat-block">
                            <mat-quill
                                placeholder="Schadenbeschreibung"
                                [(ngModel)]="report.car.damageDescription"
                                (change)="saveReport()"
                                [disabled]="isReportLocked()"></mat-quill>
                            <div matSuffix *ngIf="!isReportLocked()" class="align-center">
                                <!--********** Generate Based on Photo Descriptions or Damage Calculation **********-->
                                <mat-icon
                                    class="input-toolbar-icon medium-icon"
                                    matTooltip="Mit Daten aus der Schadenkalkulation oder den Fotobeschreibungen vorbefüllen, um dir die Ausformulierung zu erleichtern."
                                    [matMenuTriggerFor]="damageDescriptionSubmenu"
                                    #generateDamageDescriptionMenuTrigger="matMenuTrigger">
                                    auto_fix_high
                                </mat-icon>

                                <!--********** Templates **********-->
                                <mat-icon
                                    class="input-toolbar-icon"
                                    matTooltip="Textvorlagen"
                                    (click)="showDamageDescriptionTextTemplates = true">
                                    playlist_add
                                </mat-icon>
                            </div>
                        </mat-form-field>

                        <mat-menu #damageDescriptionSubmenu="matMenu">
                            <!--********** Generate Based on Photo Descriptions **********-->
                            <a
                                mat-menu-item
                                class="menu-item-with-icon"
                                matTooltip="Mit Daten aus den Fotobeschreibungen vorbefüllen, um dir die Ausformulierung zu erleichtern{{
                                    '\n\n'
                                }}Erstellt eine Liste aus den Schadenbeschreibungen der Fotos. Typische Fotobeschreibungen, die keine Schadenbeschreibung sind, werden herausgefiltert."
                                (click)="generateDamageDescriptionFromPhotoDescriptions()">
                                <mat-icon>photo_library</mat-icon>
                                Aus Fotobeschreibungen
                            </a>
                            <!--********** Generate Based on Calculation **********-->
                            <a
                                mat-menu-item
                                [ngClass]="{ disabled: !report.damageCalculation?.repair?.damagedParts?.length }"
                                class="menu-item-with-icon"
                                matTooltip="Mit Daten aus der Schadenkalkulation vorbefüllen, um dir die Ausformulierung zu erleichtern{{
                                    '\n\n'
                                }}Erstellt eine Liste aus den Ersatzteilen und Reparaturarbeiten der Kalkulation. Du kannst konfigurieren, welche Inhalte in die Schadenbeschreibung übernommen werden. {{
                                    report.damageCalculation?.repair?.calculationProvider !== 'audatex' &&
                                    report.damageCalculation?.repair?.calculationProvider !== 'dat'
                                        ? '\n\nDiese Funktion ist nur in Verbindung mit einer DAT- oder Audatex-Kalkulation verfügbar. Erstelle eine Kalkulation mit einem der beiden Anbieter.'
                                        : !report.damageCalculation?.repair?.damagedParts?.length
                                          ? '\n\nDie Kalkulation enthält keine Ersatzteile und Arbeitspositionen. Führe die Kalkulation durch oder importiere die Kalkulation erneut.'
                                          : ''
                                }}"
                                (click)="generateDamageDescriptionFromCalculation()">
                                <mat-icon>build</mat-icon>
                                <span style="flex: 1">Aus Kalkulation</span>
                                <mat-icon
                                    class="damage-description-configuration-icon"
                                    (click)="
                                        $event.stopPropagation();
                                        generateDamageDescriptionMenuTrigger.closeMenu();
                                        openDamageDescriptionFromCalculationConfigurationDialog()
                                    ">
                                    settings
                                </mat-icon>
                            </a>
                        </mat-menu>
                        <div
                            class="standard-text-button-container"
                            *ngIf="
                                team.preferences.damageDescriptionStandardText &&
                                !report.car.damageDescription &&
                                !isReportLocked()
                            ">
                            <button
                                class="secondary"
                                (click)="insertDamageDescriptionStandardText()"
                                matTooltip="Standardtext einfügen">
                                Standardtext
                            </button>
                        </div>
                        <text-template-selector
                            [(text)]="report.car.damageDescription"
                            (textChange)="saveReport()"
                            [report]="report"
                            *ngIf="showDamageDescriptionTextTemplates"
                            associatedTeamPreference="damageDescriptionStandardText"
                            placeholderForResultingText="Schadenbeschreibung"
                            (close)="showDamageDescriptionTextTemplates = false"
                            [textTemplateType]="'damageDescription'"
                            @runChildAnimations></text-template-selector>
                    </div>

                    <!--********** Damage Circumstances **********-->
                    <div
                        class="input-with-standard-text-button"
                        *ngIf="report.accident && report.type !== 'leaseReturn' && report.type !== 'usedVehicleCheck'">
                        <mat-form-field class="mat-block">
                            <mat-quill
                                id="accident-circumstances"
                                placeholder="Schadenhergang"
                                (change)="saveReport()"
                                [(ngModel)]="report.accident.circumstances"
                                [disabled]="isReportLocked()"></mat-quill>
                            <div matSuffix *ngIf="!isReportLocked()">
                                <mat-icon
                                    class="input-toolbar-icon"
                                    matTooltip="Textvorlagen"
                                    (click)="showDamageCircumstancesTextTemplates = true">
                                    playlist_add
                                </mat-icon>
                            </div>
                        </mat-form-field>
                        <div
                            class="standard-text-button-container"
                            *ngIf="
                                team.preferences.circumstancesStandardText &&
                                !report.accident.circumstances &&
                                !isReportLocked()
                            ">
                            <button
                                class="secondary"
                                (click)="insertCircumstancesStandardText()"
                                matTooltip="Standardtext einfügen">
                                Standardtext
                            </button>
                        </div>
                        <text-template-selector
                            [(text)]="report.accident.circumstances"
                            (textChange)="saveReport()"
                            [report]="report"
                            *ngIf="showDamageCircumstancesTextTemplates"
                            associatedTeamPreference="circumstancesStandardText"
                            placeholderForResultingText="Schadenhergang"
                            (close)="showDamageCircumstancesTextTemplates = false"
                            [textTemplateType]="'damageCircumstances'"
                            @runChildAnimations></text-template-selector>
                    </div>

                    <!--********** Plausibility **********-->
                    <div
                        class="input-with-standard-text-button"
                        *ngIf="report.accident && report.type !== 'leaseReturn' && report.type !== 'usedVehicleCheck'">
                        <mat-form-field class="mat-block">
                            <mat-quill
                                id="plausibility"
                                placeholder="Plausibilität"
                                (change)="saveReport()"
                                [(ngModel)]="report.accident.plausibility"
                                [disabled]="isReportLocked()"></mat-quill>
                            <div matSuffix *ngIf="!isReportLocked()">
                                <mat-icon
                                    class="input-toolbar-icon"
                                    matTooltip="Textvorlagen"
                                    (click)="showDamagePlausibilityTextTemplates = true">
                                    playlist_add
                                </mat-icon>
                            </div>
                        </mat-form-field>
                        <div
                            class="standard-text-button-container"
                            *ngIf="
                                team.preferences.plausibilityStandardText &&
                                !report.accident.plausibility &&
                                !isReportLocked()
                            ">
                            <button
                                class="secondary"
                                (click)="insertPlausibilityStandardText()"
                                matTooltip="Standardtext einfügen">
                                Standardtext
                            </button>
                        </div>
                        <text-template-selector
                            [(text)]="report.accident.plausibility"
                            (textChange)="saveReport()"
                            [report]="report"
                            *ngIf="showDamagePlausibilityTextTemplates"
                            associatedTeamPreference="plausibilityStandardText"
                            placeholderForResultingText="Plausibilität"
                            (close)="showDamagePlausibilityTextTemplates = false"
                            [textTemplateType]="'plausibility'"
                            @runChildAnimations></text-template-selector>
                    </div>

                    <!--********** Compatibility **********-->
                    <!--********** Plausibility describes whether what the claimant said (!) is true. Compatibility is more fact-based and describes whether the damages on the involved cars are compatible. **********-->
                    <div
                        class="input-with-standard-text-button"
                        *ngIf="report.accident && userPreferences.compatibilityShown">
                        <mat-form-field
                            class="mat-block"
                            matTooltip="Die Kompatibilität beschreibt, inwiefern die Schadenbilder an den beteiligten Fahrzeugen zueinander passen.
Die Plausibilität beschreibt hingegen, ob der vom Anspruchsteller geschilderte Schadenhergang realistisch klingt.">
                            <mat-quill
                                id="compatibility"
                                placeholder="Kompatibilität"
                                (change)="saveReport()"
                                [(ngModel)]="report.accident.compatibility"
                                [disabled]="isReportLocked()"></mat-quill>
                            <div matSuffix *ngIf="!isReportLocked()">
                                <mat-icon
                                    class="input-toolbar-icon"
                                    matTooltip="Textvorlagen"
                                    (click)="showDamageCompatibilityTextTemplates = true">
                                    playlist_add
                                </mat-icon>
                            </div>
                        </mat-form-field>
                        <div
                            class="standard-text-button-container"
                            *ngIf="
                                team.preferences.compatibilityStandardText &&
                                !report.accident.compatibility &&
                                !isReportLocked()
                            ">
                            <button
                                class="secondary"
                                (click)="insertCompatibilityStandardText()"
                                matTooltip="Standardtext einfügen">
                                Standardtext
                            </button>
                        </div>
                        <text-template-selector
                            [(text)]="report.accident.compatibility"
                            (textChange)="saveReport()"
                            [report]="report"
                            *ngIf="showDamageCompatibilityTextTemplates"
                            associatedTeamPreference="compatibilityStandardText"
                            placeholderForResultingText="Kompatibilität"
                            (close)="showDamageCompatibilityTextTemplates = false"
                            [textTemplateType]="'compatibility'"
                            @runChildAnimations></text-template-selector>
                    </div>

                    <!--============================================-->
                    <!-- Custom Fields -->
                    <!--============================================-->
                    <custom-field-display
                        class="margin-top-20"
                        fieldLocation="car-condition_damage-description"
                        [report]="report"
                        (reportChange)="saveReport()"
                        [disabled]="isReportLocked()"></custom-field-display>
                    <!--============================================-->
                    <!-- END Custom Fields -->
                    <!--============================================-->
                </ng-container>
                <!--============================================-->
                <!-- END Damage Description Tab -->
                <!--============================================-->
            </div>
        </div>
    </div>
    <!--============================================-->
    <!-- END Center And Right Column -->
    <!--============================================-->
</div>

import { Component, EventEmitter, HostListener, Output } from '@angular/core';
import { ResidualValueOffer } from '@autoixpert/models/reports/residual-value/residual-value-offer';

@Component({
    selector: 'cartv-offer-connection-dialog',
    templateUrl: './cartv-offer-connection-dialog.component.html',
    styleUrls: ['./cartv-offer-connection-dialog.component.scss'],
})
export class CartvOfferConnectionDialogComponent {
    public cartvOfferId: number;
    public offerReadyAt: string;

    @Output() close: EventEmitter<void> = new EventEmitter();
    @Output() connectedOffer: EventEmitter<ResidualValueOffer> = new EventEmitter();

    public getSubmitButtonTooltip(): string {
        if (!this.cartvOfferId) {
            return 'Bitte die CarTV Nummer eingeben.';
        }
        if (!this.offerReadyAt) {
            return 'Bitte die Gebotsfrist eingeben.';
        }
        return '';
    }

    //*****************************************************************************
    //  Event Emitters
    //****************************************************************************/

    public closeDialog(): void {
        this.close.emit();
    }

    public emitObjectNumber() {
        const cartvOffer = new ResidualValueOffer();
        cartvOffer.offerId = `${this.cartvOfferId}`;
        cartvOffer.readyAt = this.offerReadyAt;
        cartvOffer.link = `https://webgate.cartv.de/`;
        this.connectedOffer.emit(cartvOffer);
        this.closeDialog();
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Event Emitters
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Close Dialog
    //****************************************************************************/
    public handleOverlayClick(event: MouseEvent): void {
        // Only close editor if the overlay has been clicked directly. Ignore bubbling events from the dialog.
        if (event.target === event.currentTarget) {
            this.closeDialog();
        }
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Close Dialog
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Window Event Listeners
    //****************************************************************************/
    @HostListener('window:keydown', ['$event'])
    public handleKeyboardShortcuts(event: KeyboardEvent): void {
        switch (event.key) {
            case 'Escape':
                this.closeDialog();
        }
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Window Event Listeners
    /////////////////////////////////////////////////////////////////////////////*/
}

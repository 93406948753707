<h2>Eigener WBW-Korridor</h2>

<mat-icon class="dialog-close-icon" (click)="closeDialog()">close</mat-icon>
<!--============================================-->
<!-- Source -->
<!--============================================-->
<section id="source-section">
    <h3>Datenquelle</h3>

    <div class="display-flex flex-align-center">
        <autocomplete-with-memory
            class="mat-block"
            [defaultAutocompleteEntries]="sourcesWithLogo"
            [(ngModel)]="customMarketAnalysis.title"
            placeholder="Titel der Quelle"
            [disabled]="disabled"
            autocompleteEntryType="customMarketAnalysisSource"
            matTooltip="Wenn du eine andere Quelle oder eine Kombination von Quellen verwendet hast, nenne sie hier."></autocomplete-with-memory>

        <!--********** Logo of selected Source **********-->
        <div
            id="logo-of-selected-source-container"
            [ngSwitch]="customMarketAnalysis.title"
            *ngIf="customMarketAnalysisSourceHasIcon()">
            <img *ngSwitchCase="'DAT-Webscan'" src="/assets/images/logos/dat-logo-no-text.png" alt="" />
            <img *ngSwitchCase="'AutoScout24'" src="/assets/images/logos/autoscout24-logo.png" alt="" />
            <img *ngSwitchCase="'mobile.de'" src="/assets/images/logos/mobilede-logo.png" alt="" />
            <img *ngSwitchCase="'pkw.de'" src="/assets/images/logos/pkw_de.png" alt="" />
        </div>
    </div>
</section>
<!--============================================-->
<!-- END Source -->
<!--============================================-->

<!--============================================-->
<!-- Values -->
<!--============================================-->
<section id="values-section">
    <h3>Ergebnis</h3>

    <!--********** Maximum **********-->
    <div class="value-row display-flex flex-align-center">
        <div class="value-icon-container margin-right-10 colored-icon-on-light-background-container">
            <mat-icon class="value-icon medium-icon">north_east</mat-icon>
        </div>
        <currency-input
            class="mat-block"
            [disabled]="disabled"
            [(value)]="customMarketAnalysis.maxCorridor"
            placeholder="Maximum"></currency-input>
    </div>

    <!--********** Durchschnitt **********-->
    <div class="value-row display-flex flex-align-center">
        <div class="value-icon-container margin-right-10 colored-icon-on-light-background-container">
            <mat-icon class="value-icon medium-icon">east</mat-icon>
        </div>
        <currency-input
            class="mat-block"
            [disabled]="disabled"
            [(value)]="customMarketAnalysis.averagePrice"
            placeholder="Durchschnitt"></currency-input>
    </div>

    <!--********** Minimum **********-->
    <div class="value-row display-flex flex-align-center">
        <div class="value-icon-container margin-right-10 colored-icon-on-light-background-container">
            <mat-icon class="value-icon medium-icon">south_east</mat-icon>
        </div>
        <currency-input
            class="mat-block"
            [disabled]="disabled"
            [(value)]="customMarketAnalysis.minCorridor"
            placeholder="Minimum"></currency-input>
    </div>

    <!--********** Date **********-->
    <div class="value-row display-flex flex-align-center">
        <div class="value-icon-container margin-right-10 colored-icon-on-light-background-container">
            <mat-icon class="value-icon medium-icon">today</mat-icon>
        </div>
        <date-input
            class="mat-block"
            [disabled]="disabled"
            [(date)]="customMarketAnalysis.retrievedAt"
            placeholder="Werte ermittelt am"></date-input>
    </div>
</section>
<!--============================================-->
<!-- END Values -->
<!--============================================-->

<div class="dialog-buttons-container">
    <button (click)="closeDialog()">Schließen</button>
</div>

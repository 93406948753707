import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable()
export class CopyClaimantSignaturesToReportService {
    constructor(private httpClient: HttpClient) {}

    private apiPath = '/api/v0';

    public copyClaimantSignatures({
        sourceReportId,
        targetReportId,
        sourceAndTargetClaimantSignatureIds,
    }: {
        sourceReportId: string;
        targetReportId: string;
        sourceAndTargetClaimantSignatureIds: ClaimantSignatureCopyMap[];
    }): Promise<ClaimantSignatureCopyResponse> {
        return this.httpClient
            .post<ClaimantSignatureCopyResponse>(`${this.apiPath}/reports/${sourceReportId}/claimantSignatureCopy`, {
                targetReportId,
                sourceAndTargetClaimantSignatureIds,
            })
            .toPromise();
    }
}

export interface ClaimantSignatureCopyMap {
    sourceClaimantSignatureId: string;
    targetClaimantSignatureId: string;
}

export interface ClaimantSignatureCopyResponse {
    success: boolean;
    numberOfCopiedClaimantSignatures: number;
}

import { Component, EventEmitter, HostListener, Input, OnInit, Output } from '@angular/core';
import { dialogEnterAndLeaveAnimation } from '@autoixpert/animations/dialog-enter-and-leave.animation';
import { getAllTiresOfVehicle } from '@autoixpert/lib/tires/get-all-tires-of-vehicle';
import { Axle } from '@autoixpert/models/reports/car-identification/axle';
import { SpareTireEquipment } from '@autoixpert/models/reports/car-identification/spare-tire-equipment';
import { TireSeason } from '@autoixpert/models/reports/car-identification/tire';
import { fadeInAndSlideAnimation } from '../../../../shared/animations/fade-in-and-slide.animation';
import { triggerClickEventOnSpaceBarPress } from '../../../../shared/libraries/trigger-click-event-on-space-bar-press';
import { tireManufacturers } from '../../../../shared/static-data/tire-manufacturers';

@Component({
    selector: 'spare-tire-details-dialog',
    templateUrl: 'spare-tire-details-dialog.component.html',
    styleUrls: ['spare-tire-details-dialog.component.scss'],
    animations: [dialogEnterAndLeaveAnimation(), fadeInAndSlideAnimation()],
})
export class SpareTireDetailsDialogComponent implements OnInit {
    @Input() spareTireEquipment: SpareTireEquipment;
    @Input() axles: Axle[];
    @Input() disabled: boolean;
    @Output() spareTireEquipmentChange: EventEmitter<SpareTireEquipment> = new EventEmitter();
    @Output() close: EventEmitter<void> = new EventEmitter();

    private tireManufacturers = tireManufacturers;
    public filteredTireManufacturers: string[] = [];

    // Custom tire type (season)
    protected customTireTypeShown: boolean = false;

    ngOnInit() {
        this.setupCustomTireTypeInput();
    }

    //*****************************************************************************
    //  Copy Tire Info
    //****************************************************************************/
    public copyTireInfoFromFirstTire() {
        const firstTire = getAllTiresOfVehicle(this.axles)[0];
        this.spareTireEquipment.dimension = firstTire.type;
        this.spareTireEquipment.manufacturer = firstTire.manufacturer;
        this.spareTireEquipment.season = firstTire.season;
        this.spareTireEquipment.customType = firstTire.customType;

        this.setupCustomTireTypeInput();
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Copy Tire Info
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Season
    //****************************************************************************/
    public selectTireSeason(season: TireSeason): void {
        // Select
        if (this.spareTireEquipment.season !== season) {
            this.spareTireEquipment.season = season;
        }
        // De-select
        else {
            this.spareTireEquipment.season = null;
        }

        // Hide and clear the custom season
        this.customTireTypeShown = false;
        this.spareTireEquipment.customType = null;

        this.emitChange();
    }

    /**
     * User wants to enter a custom tire type.
     */
    protected selectCustomTireType(): void {
        this.spareTireEquipment.season = 'custom';
        this.emitChange();
    }

    /**
     * Shows the custom tire type input field if there was
     * a value set before.
     */
    protected setupCustomTireTypeInput(): void {
        if (this.spareTireEquipment.customType) {
            this.customTireTypeShown = true;
        }
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Season
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Tire Manufacturer
    //****************************************************************************/
    public filterTireManufacturerAutocomplete(searchTerm: string): void {
        if (!searchTerm) {
            this.filteredTireManufacturers = [...this.tireManufacturers];
            return;
        }

        searchTerm = searchTerm.toLowerCase();

        this.filteredTireManufacturers = this.tireManufacturers.filter((element: string) => {
            // Make the search case-insensitive
            element = (element || '').toLowerCase();

            // Search only at the beginning of each word inside the searched element
            return element.search('\\b' + searchTerm) > -1;
        });
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Tire Manufacturer
    /////////////////////////////////////////////////////////////////////////////*/

    public emitChange() {
        this.spareTireEquipmentChange.emit(this.spareTireEquipment);
    }

    public handleOverlayClick(event: MouseEvent): void {
        // Only close editor if the overlay has been clicked directly. Ignore bubbling events from the dialog.
        if (event.target === event.currentTarget) {
            this.closeDialog();
        }
    }

    public closeDialog() {
        this.close.emit();
    }

    //*****************************************************************************
    //  Keyboard Events
    //****************************************************************************/
    public triggerClickEventOnSpaceBarPress = triggerClickEventOnSpaceBarPress;

    @HostListener('window:keydown', ['$event'])
    public handleKeyboardShortcuts(event: KeyboardEvent) {
        switch (event.key) {
            case 'Escape':
                this.closeDialog();
                break;
        }
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Keyboard Events
    /////////////////////////////////////////////////////////////////////////////*/
}

import { ScrollingModule } from '@angular/cdk/scrolling';
import { NgIf } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacyMenuModule as MatMenuModule } from '@angular/material/legacy-menu';
import {
    MAT_LEGACY_TOOLTIP_DEFAULT_OPTIONS as MAT_TOOLTIP_DEFAULT_OPTIONS,
    MatLegacyTooltipModule as MatTooltipModule,
} from '@angular/material/legacy-tooltip';
import { BrowserModule } from '@angular/platform-browser';
import { SharedDirectivesModule } from '../../directives/shared-directives.module';
import { WhiteButtonWithShadowModule } from '../white-button-with-shadow/white-button-with-shadow.module';
import { IconPickerOverlayComponent } from './icon-picker-overlay/icon-picker-overlay.component';
import { IconPickerComponent } from './icon-picker.component';
import { IconPickerService } from './icon-picker.service';

@NgModule({
    imports: [
        BrowserModule,
        ScrollingModule,
        MatIconModule,
        MatTooltipModule,
        MatMenuModule,
        SharedDirectivesModule,
        WhiteButtonWithShadowModule,
    ],
    declarations: [IconPickerComponent, IconPickerOverlayComponent],
    exports: [IconPickerComponent, IconPickerOverlayComponent],
    providers: [
        IconPickerService,
        {
            provide: MAT_TOOLTIP_DEFAULT_OPTIONS,
            useValue: {
                /**
                 * Prevent the tooltip text to be selectable with the mouse pointer. This way, it does not stand in the way when the user moves the mouse to click a button currently covered by the tooltip.
                 * Details: https://github.com/angular/components/issues/25173
                 */
                disableTooltipInteractivity: true,
                showDelay: 1000,
            },
        },
    ],
})
export class IconPickerModule {}

import { Injectable } from '@angular/core';
import { createInvoiceFromReport } from '@autoixpert/lib/invoices/create-invoice-from-report';
import { PlaceholderValues, getPlaceholderValues } from '@autoixpert/lib/placeholder-values/get-placeholder-values';
import { removeLineBreaksFromDocxPlaceholders } from '@autoixpert/lib/placeholder-values/remove-line-breaks-from-docx-placeholders';
import { FieldGroupConfig } from '@autoixpert/models/custom-fields/field-group-config';
import { DocumentMetadata } from '@autoixpert/models/documents/document-metadata';
import { AxError } from '@autoixpert/models/errors/ax-error';
import { Invoice } from '@autoixpert/models/invoices/invoice';
import { CustomFeeSet } from '@autoixpert/models/reports/assessors-fee/custom-fee-set';
import { CarEquipment } from '@autoixpert/models/reports/car-identification/car-equipment';
import { Report } from '@autoixpert/models/reports/report';
import { Team } from '@autoixpert/models/teams/team';
import { User } from '@autoixpert/models/user/user';
import { CarEquipmentService } from './car-equipment.service';
import { CustomFeeSetService } from './custom-fee-set.service';
import { FieldGroupConfigService } from './field-group-config.service';
import { InvoiceService } from './invoice.service';
import { LoggedInUserService } from './logged-in-user.service';
import { ReportService } from './report.service';
import { UserService } from './user.service';

@Injectable()
export class TemplatePlaceholderValuesService {
    constructor(
        private reportService: ReportService,
        private invoiceService: InvoiceService,
        private carEquipmentService: CarEquipmentService,
        private userService: UserService,
        private loggedInUserService: LoggedInUserService,
        private fieldGroupConfigService: FieldGroupConfigService,
        private customFeeSetService: CustomFeeSetService,
    ) {}

    public async getReportValues({
        reportId,
        letterDocument,
    }: {
        reportId: Report['_id'];
        // By providing a DocumentMetadata object, you enrich the placeholder value tree with data for
        // auxiliary documents, such as letters or expert statements.
        letterDocument?: DocumentMetadata;
    }): Promise<PlaceholderValues> {
        const user: User = this.loggedInUserService.getUser();
        const team: Team = this.loggedInUserService.getTeam();
        const teamMembers: User[] = this.userService.getAllTeamMembersFromCache();

        //*****************************************************************************
        //  Report
        //****************************************************************************/
        let report: Report;
        try {
            report = await this.reportService.get(reportId);
        } catch (error) {
            throw new AxError({
                code: 'GETTING_REPORT_FOR_RENDERING_PLACEHOLDER_VALUES_FAILED',
                message: `The report could not be retrieved for generating the placeholder values belonging to that report.`,
                data: {
                    reportId,
                },
                error,
            });
        }
        /////////////////////////////////////////////////////////////////////////////*/
        //  END Report
        /////////////////////////////////////////////////////////////////////////////*/

        //*****************************************************************************
        //  Car Equipment
        //****************************************************************************/
        let carEquipment: CarEquipment;
        try {
            const carEquipmentArray = await this.carEquipmentService.find({ reportId }).toPromise();
            carEquipment = carEquipmentArray[0];
        } catch (error) {
            throw new AxError({
                code: 'GETTING_CAR_EQUIPMENT_FOR_RENDERING_PLACEHOLDER_VALUES_FAILED',
                message: `The car equipment could not be retrieved for generating the placeholder values belonging to a report.`,
                data: {
                    reportId,
                },
                error,
            });
        }
        /////////////////////////////////////////////////////////////////////////////*/
        //  END Car Equipment
        /////////////////////////////////////////////////////////////////////////////*/

        //*****************************************************************************
        //  Custom Field Configs
        //****************************************************************************/
        let fieldGroupConfigs: FieldGroupConfig[];
        // Custom field configs need to be loaded only if the report has at least one custom field. Only then will the custom fields influence the placeholder values.
        if (report?.customFieldGroups?.length) {
            fieldGroupConfigs = await this.fieldGroupConfigService.getAllFromInMemoryCacheAndPopulateIfNecessary();
        }
        /////////////////////////////////////////////////////////////////////////////*/
        //  END Custom Field Configs
        /////////////////////////////////////////////////////////////////////////////*/

        //*****************************************************************************
        //  Custom Fee Sets
        //****************************************************************************/
        let customFeeSets: CustomFeeSet[];
        try {
            customFeeSets = await this.customFeeSetService.find().toPromise();
        } catch (error) {
            throw new AxError({
                code: 'GETTING_CUSTOM_FEE_SETS_FOR_RENDERING_PLACEHOLDER_VALUES_FAILED',
                message: 'Custom fee sets could not be fetched when generating placeholders.',
                data: {},
                error,
            });
        }
        /////////////////////////////////////////////////////////////////////////////*/
        //  END Custom Fee Sets
        /////////////////////////////////////////////////////////////////////////////*/

        // If no specific documentMetadata has been passed in, we just generate the placeholder values for the report itself.
        // The option would be to enrich the report placeholder values with data for auxiliary documents like
        // letters or expert statements.
        if (!letterDocument) {
            letterDocument = report.documents.find((documentMetadata) => documentMetadata.type === 'report');
        }

        let invoice: Invoice;
        try {
            invoice = createInvoiceFromReport({
                report,
                user,
                team,
            });
        } catch (error) {
            throw new AxError({
                code: 'CREATING_INVOICE_FROM_REPORT_FAILED_FOR_PLACEHOLDER_VALUES',
                message:
                    'The invoice could not be derived from the report. This is required for generating placeholder values.',
                data: {
                    report,
                },
                error,
            });
        }

        const templatePlaceholderValues = await getPlaceholderValues({
            report,
            carEquipment,
            fieldGroupConfigs,
            user,
            // Use all team members here since that's available in the frontend anyway.
            teamMembers,
            team,
            documentMetadata: letterDocument,
            invoice,
            customFeeSets,
        });

        removeLineBreaksFromDocxPlaceholders(templatePlaceholderValues);

        return templatePlaceholderValues;
    }

    public async getInvoiceValues({
        invoiceId,
        letterDocument,
        report,
    }: {
        invoiceId: Invoice['_id'];
        // By providing a DocumentMetadata object, you enrich the placeholder value tree with data for
        // auxiliary documents, such as letters or expert statements.
        letterDocument?: DocumentMetadata;
        /**
         * The report used for retrieving the placeholder value tree may be overridden manually.
         * If not passed, the report referenced by the invoice is automatically fetched.
         */
        report?: Report;
    }): Promise<PlaceholderValues> {
        const user: User = this.loggedInUserService.getUser();
        const team: Team = this.loggedInUserService.getTeam();
        const teamMembers: User[] = this.userService.getAllTeamMembersFromCache();

        const invoice: Invoice = await this.invoiceService.get(invoiceId);

        let carEquipment: CarEquipment;
        let fieldGroupConfigs: FieldGroupConfig[];

        // If no report is passed, fetch the referenced report.
        if (!report && invoice.reportIds?.length > 0) {
            try {
                report = await this.reportService.get(invoice.reportIds?.[0]);
            } catch (error) {
                throw new AxError({
                    code: 'GETTING_REPORT_FOR_RENDERING_PLACEHOLDER_VALUES_FAILED',
                    message: `The report could not be retrieved for generating the placeholder values belonging to that report.`,
                    data: {
                        reportId: invoice.reportIds,
                    },
                    error,
                });
            }
        }

        if (report) {
            try {
                const carEquipmentArray = await this.carEquipmentService
                    .find({ reportId: invoice.reportIds?.[0] })
                    .toPromise();
                carEquipment = carEquipmentArray[0];
            } catch (error) {
                throw new AxError({
                    code: 'GETTING_CAR_EQUIPMENT_FOR_RENDERING_PLACEHOLDER_VALUES_FAILED',
                    message: `The car equipment could not be retrieved for generating the placeholder values belonging to a report.`,
                    data: {
                        reportId: invoice.reportIds?.[0],
                    },
                    error,
                });
            }

            // Custom field configs need to be loaded only if the report has at least one custom field. Only then will the custom fields influence the placeholder values.
            if (report?.customFieldGroups?.length) {
                fieldGroupConfigs = await this.fieldGroupConfigService.getAllFromInMemoryCacheAndPopulateIfNecessary();
            }
        }

        //*****************************************************************************
        //  Custom Fee Sets
        //****************************************************************************/
        let customFeeSets: CustomFeeSet[];
        try {
            customFeeSets = await this.customFeeSetService.find().toPromise();
        } catch (error) {
            throw new AxError({
                code: 'GETTING_CUSTOM_FEE_SETS_FOR_RENDERING_PLACEHOLDER_VALUES_FAILED',
                message: 'Custom fee sets could not be fetched when generating placeholders.',
                data: {},
                error,
            });
        }
        /////////////////////////////////////////////////////////////////////////////*/
        //  END Custom Fee Sets
        /////////////////////////////////////////////////////////////////////////////*/

        // An invoice does not necessarily have a letter document. This may be created later.
        // In order to get the placeholder values for the letter (e.g. Anschreiben.Empfänger), we need to pass in the letter document.
        // We need this placeholders e.g. for displaying the message template selector.
        if (!letterDocument) {
            letterDocument = new DocumentMetadata({
                type: 'letter',
                recipientRole: invoice.recipient.role,
                createdBy: user._id,
            });
        }

        //*****************************************************************************
        //  Fetch Canceled Invoice from DB
        //****************************************************************************/
        /**
         * On cancellation invoices, we must print the number and date of the canceled invoices.
         */
        let canceledInvoice: Invoice;
        if (invoice?.rootInvoiceId) {
            canceledInvoice = await this.invoiceService.get(invoice.rootInvoiceId);
        }
        /////////////////////////////////////////////////////////////////////////////*/
        //  END Fetch Canceled Invoice from DB
        /////////////////////////////////////////////////////////////////////////////*/

        const templatePlaceholderValues = await getPlaceholderValues({
            invoice,
            canceledInvoice,
            report,
            carEquipment,
            fieldGroupConfigs,
            user,
            // Use all team members here since that's available in the frontend anyway.
            teamMembers,
            team,
            documentMetadata: letterDocument,
            customFeeSets,
        });

        removeLineBreaksFromDocxPlaceholders(templatePlaceholderValues);

        return templatePlaceholderValues;
    }
}

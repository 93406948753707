import { animate, group, query, style, transition, trigger } from '@angular/animations';

/**
 * This animation should be applied to the element with .dialog-overlay
 *
 * Its host must have the @runChildAnimations
 */
export function dialogEnterAndLeaveAnimation() {
    return trigger('dialogEnterAndLeave', [
        // Note: The `enter` animation transitions to `transform: none`, because for some reason
        // specifying the transform explicitly, causes IE both to blur the dialog content and
        // decimate the animation performance. Leaving it as `none` solves both issues.
        transition(':enter', [
            group([
                //*****************************************************************************
                //  Show Overlay
                //****************************************************************************/
                style({
                    opacity: 0,
                }),
                animate(
                    '150ms ease-in-out',
                    style({
                        opacity: 1,
                    }),
                ),
                /////////////////////////////////////////////////////////////////////////////*/
                //  END Show Overlay
                /////////////////////////////////////////////////////////////////////////////*/
                //*****************************************************************************
                //  Show Dialog Container
                //****************************************************************************/
                query(
                    '.dialog-overlay > *',
                    [
                        style({
                            opacity: 0,
                            transform: 'translateY(50px)',
                        }),
                        animate(
                            '150ms ease-in-out',
                            style({
                                transform: 'none',
                                opacity: 1,
                            }),
                        ),
                    ],
                    { optional: true },
                ),
                /////////////////////////////////////////////////////////////////////////////*/
                //  END Show Dialog Container
                /////////////////////////////////////////////////////////////////////////////*/
            ]),
        ]),
        transition(':leave', [
            group([
                query(
                    '.dialog-overlay > *',
                    [
                        animate(
                            '150ms ease-in-out',
                            style({
                                opacity: 0,
                                transform: 'translateY(50px)',
                            }),
                        ),
                    ],
                    { optional: true },
                ),
                //*****************************************************************************
                //  Hide Overlay
                //****************************************************************************/
                animate(
                    '150ms ease-in-out',
                    style({
                        opacity: 0,
                    }),
                ),
                /////////////////////////////////////////////////////////////////////////////*/
                //  END Hide Overlay
                /////////////////////////////////////////////////////////////////////////////*/
            ]),
        ]),
    ]);
}

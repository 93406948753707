import { ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { fadeInAndOutAnimation } from '@autoixpert/animations/fade-in-and-out.animation';
import { mayCarOwnerDeductTaxes } from '@autoixpert/lib/report/may-car-owner-deduct-taxes';
import { createInvolvedParty } from '@autoixpert/lib/reports/change-report-type';
import { calculateValuationValues } from '@autoixpert/lib/valuation/calculate-valuation-values';
import { ReportTabName } from '@autoixpert/models/realtime-editing/report-tab-name';
import { MarketAnalysisProvider, Valuation } from '@autoixpert/models/reports/market-value/valuation';
import { ReplacementValueCorrectionConfig } from '@autoixpert/models/reports/replacement-value-corrections/replacement-value-correction-config';
import { ReplacementValueCorrectionItem } from '@autoixpert/models/reports/replacement-value-corrections/replacement-value-correction-item';
import { Report } from '@autoixpert/models/reports/report';
import { ResidualValueBid } from '@autoixpert/models/reports/residual-value/residual-value-bid';
import { Team } from '@autoixpert/models/teams/team';
import { User } from '@autoixpert/models/user/user';
import { hasAccessRight } from 'src/app/shared/libraries/user/has-access-right';
import { ApiErrorService } from 'src/app/shared/services/api-error.service';
import { AudatexTaskService } from 'src/app/shared/services/audatex/audatex-task.service';
import { LoggedInUserService } from 'src/app/shared/services/logged-in-user.service';
import { ReportDetailsService } from 'src/app/shared/services/report-details.service';
import { ReportRealtimeEditorService } from 'src/app/shared/services/report-realtime-editor.service';
import { TeamService } from 'src/app/shared/services/team.service';
import { ToastService } from 'src/app/shared/services/toast.service';
import { UserPreferencesService } from 'src/app/shared/services/user-preferences.service';
import { UserService } from '../../../shared/services/user.service';
import { ValuationResultComponent } from './valuation-result/valuation-result.component';

@Component({
    selector: 'valuation',
    templateUrl: './valuation.component.html',
    styleUrls: ['./valuation.component.scss'],
    animations: [fadeInAndOutAnimation()],
})
export class ValuationComponent implements OnInit {
    constructor(
        private route: ActivatedRoute,
        private router: Router,
        public changeDetectorRef: ChangeDetectorRef,
        private toastService: ToastService,
        private loggedInUserService: LoggedInUserService,
        private teamService: TeamService,
        private reportDetailsService: ReportDetailsService,
        public userPreferences: UserPreferencesService,
        private reportRealtimeEditorService: ReportRealtimeEditorService,
        public audatexTaskService: AudatexTaskService,
        private apiErrorService: ApiErrorService,
        private userService: UserService,
    ) {}

    @ViewChild('valuationResultComponent') valuationResultComponent: ValuationResultComponent;

    public reportId: string;
    public report: Report;
    public user: User;
    public team: Team;
    private subscriptions: Subscription[] = [];

    // UI States
    public marketValueCardExpanded = true;
    public residualValueCardExpanded = false;

    //*****************************************************************************
    //  Initialization
    //****************************************************************************/
    ngOnInit(): void {
        this.team = this.loggedInUserService.getTeam();
        this.user = this.loggedInUserService.getUser();

        const routeSubscription = this.route.parent.params.subscribe((params) => {
            this.reportId = params['reportId'];
        });
        const reportSubscription = this.reportDetailsService.get(this.reportId).subscribe((report) => {
            this.report = report;

            if (this.report.type !== 'valuation') {
                // Valuation reports do not have the damage calculation tab.
                // Redirect to the valuation tab instead.
                this.router.navigate(['Gutachten', report._id, 'Schadenskalkulation']);
            }

            this.joinAsRealtimeEditor();

            let reportNeedsSaving = false;
            // Create the object for all correction-related things on the report if it does not exist yet
            if (!this.report.valuation.replacementValueCorrectionConfig) {
                this.report.valuation.replacementValueCorrectionConfig = new ReplacementValueCorrectionConfig();
                reportNeedsSaving = true;
            }

            // Initialize the decreases list with one empty line
            if (!this.report.valuation.replacementValueCorrectionConfig.decreases.length) {
                this.report.valuation.replacementValueCorrectionConfig.decreases.push(
                    new ReplacementValueCorrectionItem(),
                );
                reportNeedsSaving = true;
            }

            // Initialize garage
            if (!this.report.garage) {
                createInvolvedParty(this.report, this.team, 'garage');
                reportNeedsSaving = true;
            }

            if (reportNeedsSaving) {
                this.saveReport();
            }
        });

        this.subscriptions.push(routeSubscription, reportSubscription);
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Initialization
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  UI States
    //****************************************************************************/
    public expandMarketValueCard() {
        this.marketValueCardExpanded = true;
    }
    public expandResidualValueCard() {
        this.residualValueCardExpanded = true;
    }

    get mayCarOwnerDeductTaxes() {
        return mayCarOwnerDeductTaxes(this.report);
    }

    /**
     * Overall increase: positive number.
     * Overall decrease: negative number.
     */
    get correctionSumGross(): number {
        return (
            (this.report.valuation.decreaseFromDamageGross || 0) * -1 +
            (this.report.valuation.replacementValueCorrectionConfig.totalDecrease || 0) * -1 +
            (this.report.valuation.replacementValueCorrectionConfig.totalIncrease || 0)
        );
    }
    /////////////////////////////////////////////////////////////////////////////*/
    //  END UI States
    /////////////////////////////////////////////////////////////////////////////*/

    /**
     * Valuation reports contain the valuation providers document in the report itself.
     * If a user changes the vehicle value in autoiXpert, he may want to exclude the DAT valuation.
     *
     * Do not exclude the valuation automatically since a user may want to round the values manually and keep the original document in the report.
     * Do not delete the original valuation since a user may want to switch back to it.
     */
    public excludeDatOrAudatexValuationFromReport() {
        if (this.isReportLocked()) {
            return;
        }
        if (this.report.type === 'valuation' && !this.isReportLocked()) {
            this.report.valuation.valuationProvider = 'custom';
            this.saveReport();
        }
    }

    public handleMarketValueChange({
        vehicleValueGross,
        provider,
    }: {
        vehicleValueGross: number;
        provider: MarketAnalysisProvider;
    }) {
        this.setBaseValue({
            salesGross: vehicleValueGross,
            valueType: 'marketValue',
            dealerSalesTaxationType: this.report.valuation.taxationType,
            salesValueProvider: provider,
        });
        this.saveReport();
    }

    public handleResidualValueChange({
        valueNet,
        valueGross,
        taxationType,
        exchangeName,
    }: {
        valueNet: number;
        valueGross: number;
        exchangeName: ResidualValueBid['origin'];
        taxationType: Valuation['taxationType'];
    }) {
        this.setBaseValue({
            purchaseGross: valueGross,
            purchaseNet: valueNet,
            valueType: 'residualValue',
            dealerSalesTaxationType: taxationType,
            purchaseValueProvider: exchangeName,
        });
        this.saveReport();
    }

    /**
     * Set a new base value (purchase, sales or both)
     * Trigger recalculation
     */
    public setBaseValue({
        salesNet,
        salesGross,
        purchaseNet,
        purchaseGross,
        dealerSalesTaxationType,
        referenceDate,
        valueType,
        purchaseValueProvider,
        salesValueProvider,
    }: SetBaseValuePayload) {
        const hasSalesPrice = salesNet || salesGross;
        const hasPurchasePrice = purchaseNet || purchaseGross;

        const formerValuation = JSON.parse(JSON.stringify(this.report.valuation));

        // Sales Price is available for all providers except residual value and some Audatex codes.
        if (hasSalesPrice) {
            this.report.valuation.baseValueDealerSalesNet = salesNet;
            this.report.valuation.baseValueDealerSalesGross = salesGross;
            this.report.valuation.taxationType = dealerSalesTaxationType;
            this.report.valuation.baseValueDealerSalesProvider = salesValueProvider;
        }

        // Purchase prices are available for DAT, residual value exchanges and some Audatex codes
        if (hasPurchasePrice) {
            this.report.valuation.baseValueDealerPurchaseNet = purchaseNet;
            this.report.valuation.baseValueDealerPurchaseGross = purchaseGross;
            this.report.valuation.baseValueDealerPurchaseProvider = purchaseValueProvider;
        }

        if (hasSalesPrice && hasPurchasePrice) {
            if (!this.report.valuation.hasTradeMarginBeenChangedManually) {
                this.calculateTradeMarginFromVehicleBaseValue();
            }
        }

        // Activate the relevant value type
        switch (valueType) {
            case 'dealerPurchase':
            case 'residualValue':
            case 'presentValue':
                this.report.valuation.vehicleValuePurchasePriceActive = true;
                this.report.valuation.vehicleValueType = valueType;
                break;

            case 'dealerSales':
            case 'marketValue':
            case 'replacementValue':
            case 'restorationValue':
                this.report.valuation.vehicleValueSalesPriceActive = true;
                this.report.valuation.secondVehicleValueType = valueType;
                break;
            default:
                // Keep the initial value types
                if (hasSalesPrice) {
                    this.report.valuation.vehicleValueSalesPriceActive = true;
                } else {
                    this.report.valuation.vehicleValuePurchasePriceActive = true;
                }
        }

        if (referenceDate) {
            this.report.valuation.referenceDate = referenceDate;
        }

        // Check if the base value has changed
        if (
            formerValuation.baseValueDealerPurchaseGross !== this.report.valuation.baseValueDealerPurchaseGross ||
            formerValuation.baseValueDealerPurchaseNet !== this.report.valuation.baseValueDealerPurchaseNet ||
            formerValuation.baseValueDealerSalesGross !== this.report.valuation.baseValueDealerSalesGross ||
            formerValuation.baseValueDealerSalesNet !== this.report.valuation.baseValueDealerSalesNet
        ) {
            this.valuationResultComponent.highlightBaseValueRow();
            this.calculateFinalVehicleValues();
        }
    }

    /**
     * Calculate the final vehicle value, including corrections.
     *
     * Depending on which vehicle value (purchase or sales price) is the main value (i. e. the corrections are applied to
     * that base value to receive that final value), the other will be calculated by means of the gross trade margin.
     */
    public calculateFinalVehicleValues() {
        const formerValuation = JSON.parse(JSON.stringify(this.report.valuation));

        calculateValuationValues(this.report);

        //*****************************************************************************
        //  Highlight Result Row
        //****************************************************************************/
        if (
            formerValuation.vehicleValueGross !== this.report.valuation.vehicleValueGross ||
            formerValuation.vehicleValueNet !== this.report.valuation.vehicleValueNet ||
            formerValuation.secondVehicleValueGross !== this.report.valuation.secondVehicleValueGross ||
            formerValuation.secondVehicleValueNet !== this.report.valuation.secondVehicleValueNet
        ) {
            this.valuationResultComponent.highlightResultRow();
        }
        /////////////////////////////////////////////////////////////////////////////*/
        //  END Highlight Result Row
        /////////////////////////////////////////////////////////////////////////////*/

        this.saveReport();
    }

    /**
     * The trade margin is used to convert a corrected (value increases or decreases) purchase or sales price
     * to the respective other value.
     *
     * We use the gross trade margin because the value conversion uses the gross values first, and then converts
     * to net afterwards.
     */
    protected calculateTradeMarginFromVehicleBaseValue() {
        const salesPrice = this.report.valuation.baseValueDealerSalesGross;
        const purchasePrice = this.report.valuation.baseValueDealerPurchaseGross;
        this.report.valuation.tradeMarginGrossPercent = ((salesPrice - purchasePrice) / salesPrice) * 100;

        this.saveReport();
    }

    //*****************************************************************************
    //  Realtime Editors
    //****************************************************************************/
    public joinAsRealtimeEditor() {
        let currentTab: ReportTabName;
        switch (this.report.type) {
            case 'valuation':
            case 'oldtimerValuationSmall':
                currentTab = 'valuation';
                break;
            default:
                currentTab = 'damageCalculation';
        }

        this.reportRealtimeEditorService.joinAsEditor({
            recordId: this.report._id,
            currentTab,
        });
    }
    /////////////////////////////////////////////////////////////////////////////*/
    //  END Realtime Editors
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Util
    //****************************************************************************/
    protected readonly hasAccessRight = hasAccessRight;

    public isReportLocked(): boolean {
        return this.report.state === 'done';
    }

    /**
     * Save report to the server.
     */
    public saveReport({ waitForServer }: { waitForServer?: boolean } = {}): Promise<Report> {
        if (this.isReportLocked()) {
            return;
        }

        return this.reportDetailsService.patch(this.report, { waitForServer }).catch((error) => {
            this.toastService.error('Fehler beim Sync', 'Bitte versuche es später erneut');
            console.error('An error occurred while saving the report via the ReportService.', this.report, { error });
            throw error;
        });
    }

    /**
     * Save team to the server.
     */
    public async saveTeam(): Promise<void> {
        try {
            await this.teamService.put(this.team);
        } catch (error) {
            this.apiErrorService.handleAndRethrow({
                axError: error,
                handlers: {},
                defaultHandler: {
                    title: 'Team nicht gespeichert',
                    body: "Bitte kontaktiere die <a href='/Hilfe'>Hotline</a>.",
                },
            });
        }
    }

    protected async saveUser(): Promise<void> {
        try {
            await this.userService.put(this.user);
        } catch (error) {
            this.apiErrorService.handleAndRethrow({
                axError: error,
                handlers: {},
                defaultHandler: {
                    title: 'Benutzer nicht gespeichert',
                    body: "Bitte kontaktiere die <a href='/Hilfe'>Hotline</a>.",
                },
            });
        }
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Util
    /////////////////////////////////////////////////////////////////////////////*/
}

export type SetBaseValuePayload = {
    purchaseNet?: number;
    purchaseGross?: number;
    salesNet?: number;
    salesGross?: number;
    valueType?: Valuation['vehicleValueType'];
    dealerSalesTaxationType?: Valuation['taxationType'];
    referenceDate?: Valuation['referenceDate'];
    purchaseValueProvider?: Valuation['baseValueDealerPurchaseProvider'];
    salesValueProvider?: Valuation['baseValueDealerSalesProvider'];
};

import { escapeRegExp } from 'lodash-es';

/**
 * What properties shall be searched with a search term?
 * @param searchTerm
 * @protected
 */
export function get$SearchMongoQueryInvoices(searchTerm: string): [string, any] {
    const searchTerms: string[] = searchTerm.split(' ');
    // An array equal to the number of search terms given in the input field.
    const searchesPerSearchTerm = [];

    // All search terms must match at least one of the specified document fields.
    // Tech: Create an individual $and condition for every search term. The search term again consists of conditions linked with $or.
    for (const searchTerm of searchTerms) {
        const mongodbRegex = { $regex: new RegExp(escapeRegExp(searchTerm), 'i') };
        //*****************************************************************************
        //  Query for invoice total
        //****************************************************************************/
        // If the client searches for a float (including a period decimal delimiter) -> Search for the exact float
        // If he searches for an integer -> find all records with a total whose integer is the same (401.50 is found when searching 401)
        let invoiceTotalQuery;
        // search for exact float
        if (searchTerm.includes('.')) {
            if (!isNaN(+searchTerm)) {
                invoiceTotalQuery = Math.round(+searchTerm * 100) / 100;
            } else {
                invoiceTotalQuery = undefined;
            }
        } else {
            const searchTermInteger = parseInt(searchTerm);
            // invalid number format
            if (isNaN(searchTermInteger)) {
                invoiceTotalQuery = undefined;
            }
            // valid number format
            else {
                // compare the invoices' total number as integer
                invoiceTotalQuery = {
                    // Removing VAT -> query for net total
                    $gte: Math.round(searchTermInteger * 100) / 100,
                    $lt: Math.round(searchTermInteger * 100) / 100 + 1,
                };
            }
        }
        /////////////////////////////////////////////////////////////////////////////*/
        //  END Query for invoice total
        /////////////////////////////////////////////////////////////////////////////*/

        //*****************************************************************************
        //  Assemble complete query
        //****************************************************************************/
        searchesPerSearchTerm.push({
            $or: [
                { 'recipient.contactPerson.lastName': mongodbRegex },
                { 'recipient.contactPerson.firstName': mongodbRegex },
                { 'recipient.contactPerson.organization': mongodbRegex },
                { number: mongodbRegex },
                { totalGross: invoiceTotalQuery },
            ],
        });
        /////////////////////////////////////////////////////////////////////////////*/
        //  END Assemble complete query
        /////////////////////////////////////////////////////////////////////////////*/
    }

    return ['$and', searchesPerSearchTerm];
}

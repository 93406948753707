import { NumberOfDowntimeCompensationClassReductions } from '../../../lib/damage-calculation-values/downtime-compensation/determine-number-of-downtime-compensation-class-reductions-according-to-age';
import { EmergencyRepair } from '../damage-description/emergency-repair';
import { Repair } from '../damage-description/repair';
import { AdditionalSpecialCosts } from '../market-value/additional-special-costs';
import { RemainingFuel } from '../market-value/remaining-fuel';
import { Retrofit } from '../market-value/retrofit-item';

export class DamageCalculation {
    //*****************************************************************************
    //  Repair
    //****************************************************************************/
    repair: Repair = new Repair();
    emergencyRepair: EmergencyRepair = new EmergencyRepair();
    /////////////////////////////////////////////////////////////////////////////*/
    //  END Repair
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Downtime Compensation
    //****************************************************************************/
    downtimeInWorkdaysDueToReparation: string;
    replacementTimeInWorkdays: string;
    downtimeCompensationPerWorkday: number;
    downtimeCompensationGroup: DowntimeCompensationGroup | string;
    /**
     * The original group before decreases due to the car's age.
     */
    originalDowntimeCompensationGroup: DowntimeCompensationGroup | string;
    /**
     * By how many group levels has the downtime compensation group been decreased due to the car's age?
     */
    numberOfDowntimeCompensationClassReductions: NumberOfDowntimeCompensationClassReductions;
    downtimeCompensationComment: string;

    /**
     * German "Mietwagenklasse"
     * A claimant might choose to get a rental car instead of being paid downtime compensation because the claimant needs a car
     * for getting to work or otherwise being mobile.
     */
    rentalCarClass: number;
    rentalCarClassCostsPerDay: number;
    /**
     * German "Vorhaltekosten"
     * Costs that exist because a company (bus, taxi, cargo transportation, ...) has one more vehicle to compensate for a damaged vehicle.
     * These costs used to be calculated more often. However, nowadays, those companies can usually rent a vehicle instead and get costs
     * for the rent back from the insurance company.
     */
    useProvisioningCosts: boolean;
    provisioningCosts: number;
    /////////////////////////////////////////////////////////////////////////////*/
    //  END Downtime Compensation
    /////////////////////////////////////////////////////////////////////////////*/

    /**
     * German "Sonderkosten"
     * Non-repair related costs incurred by overhauling the car.
     * ATTENTION: These costs are not added to the damage value. Instead, the claimant may ask the insurance to reimburse him
     * when he sends the invoices to the insurance. "Fiktive Abrechnung" is usually not possible with these costs.
     */
    remainingFuel?: RemainingFuel;
    /**
     * Some vehicles are customized with special equipments retroactively after leaving the manufacturer.
     * The replacement value of such vehicles must be increased by the costs it takes to re-apply these
     * customizations.
     * Examples:
     * - craftsmen's cars have special shelves
     * - disabled people may have some extra levers to help them reach the gas pedal despite not being able to use their legs.
     */
    retrofit?: Retrofit;
    disposalCosts?: number;
    deregistrationAndRegistrationCosts?: number;
    additionalCosts?: AdditionalSpecialCosts[] = [];

    /**
     * German "Schadensklasse"
     * The conclusion the assessor draws from the damage calculation vs. the replacement value and the claimant's intention (130 % percent rule: yes or no?). Should the vehicle be repaired?
     */
    damageType: DamageType;
}

export const damageTypes = [
    'repairableDamage',
    'repairableDamageTo130Percent',
    'economicTotalLoss',
    'totalLoss',
    null,
] as const;
export type DamageType = (typeof damageTypes)[number];

// Eurotax-Schwacke classes
export const downtimeCompensationGroups = [
    /**
     * Null needs to be typecast as "null" to prevent the entire type to become type "any".
     */
    null as null,
    'A',
    'B',
    'C',
    'D',
    'E',
    'F',
    'G',
    'H',
    'J',
    'K',
    'L',
    '1',
    '2',
    '3',
    '4',
    '5',
    '6',
    '7',
    '8',
    '9',
] as const;
export type DowntimeCompensationGroup = (typeof downtimeCompensationGroups)[number];

import { HttpClient } from '@angular/common/http';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import moment from 'moment';
import { EMPTY as emptyObservable } from 'rxjs';
import { iconForCarBrandExists, iconNameForCarBrand } from '@autoixpert/lib/car/icon-for-car-brand-exists';
import { IsoDate } from '@autoixpert/lib/date/iso-date.types';
import { Car } from '@autoixpert/models/reports/car-identification/car';
import {
    BaseModel,
    Manufacturer,
    SubModel,
    SubModelOption,
} from '@autoixpert/models/reports/car-identification/dat-models';
import { SelectedClassificationGroupOptions } from '@autoixpert/models/reports/car-identification/dat-vehicle-identification';
import { User } from '@autoixpert/models/user/user';
import { getDatErrorHandlers } from '../../../../shared/libraries/error-handlers/get-dat-error-handlers';
import { ApiErrorService } from '../../../../shared/services/api-error.service';
import { DatAuthenticationService } from '../../../../shared/services/dat-authentication.service';
import { ManufacturerService } from '../../../../shared/services/manufacturer.service';
import { ToastService } from '../../../../shared/services/toast.service';

declare type ClassificationGroup =
    | 'motor'
    | 'autobody'
    | 'wheelbase'
    | 'propulsion'
    | 'driversCab'
    | 'tonnage'
    | 'build'
    | 'suspension'
    | 'numberOfAxles'
    | 'equipmentLine'
    | 'gearbox';

@Component({
    selector: 'car-selector',
    templateUrl: 'car-selector.component.html',
    styleUrls: ['car-selector.component.scss'],
})
export class CarSelectorComponent {
    constructor(
        private httpClient: HttpClient,
        private toastService: ToastService,
        private manufacturerService: ManufacturerService,
        private datAuthenticationService: DatAuthenticationService,
        private apiErrorService: ApiErrorService,
    ) {}

    @Input() public car: Car;
    @Input() public user: User;
    // These options are basically available for drilldown. They will be filtered according to the autocomplete value.
    @Input() manufacturers: Manufacturer[];

    @Output() public close: EventEmitter<void> = new EventEmitter<void>();
    @Output() public carChange: EventEmitter<void> = new EventEmitter<void>();

    // TODO Add motorcycle, semitruck, and bus.
    public selectedVehicleType: Car['datIdentification']['vehicleType'] = null;

    public constructionTime: IsoDate = null;
    public datECode: string = '';

    // Selected labels. We can derive the DAT key by searching through the manufacturers > base model > sub model structure.
    private manufacturer: Manufacturer = null;
    public selectedManufacturerName: string = '';
    public selectedBaseModelName: string = '';
    public selectedSubModelName: string = '';
    // The market index is chosen of one or more possible market indexes that can be derived from a DAT€Code.
    public selectedMarketIndex: string = '';

    public filteredManufacturers: string[] = [];
    public filteredBaseModels: BaseModel[] = [];
    public filteredSubModels: string[] = [];
    // The DAT key for the market index is not a number but a string, e.g. "DE001"
    public filteredMarketIndexes: { datKey: string; name: string }[] = [];

    //*****************************************************************************
    //  Classification Groups
    //****************************************************************************/
    private classificationGroupNumbersToLabels = {
        1: 'motor',
        2: 'autobody',
        3: 'wheelbase',
        4: 'propulsion',
        5: 'driversCab',
        6: 'tonnage',
        7: 'build',
        8: 'suspension',
        9: 'numberOfAxles',
        10: 'equipmentLine',
        11: 'gearbox',
    };
    public classificationGroups: ClassificationGroup[] = [
        'motor',
        'autobody',
        'build',
        'gearbox',
        'wheelbase',
        'propulsion',
        'numberOfAxles',
        'driversCab',
        'tonnage',
        'suspension',
        'equipmentLine',
    ];
    // Used to loop over the classification groups.null
    public classificationGroupLabels: { [key in ClassificationGroup]: string } = {
        motor: 'Motor',
        autobody: 'Karosserie',
        build: 'Bauart',
        gearbox: 'Getriebe',
        wheelbase: 'Radstand',
        propulsion: 'Antriebsart',
        numberOfAxles: 'Anzahl Achsen',
        driversCab: 'Fahrerhaus',
        tonnage: 'Tonnage',
        suspension: 'Federungsart',
        equipmentLine: 'Ausstattungslinie',
    };

    // Contains the possible classification group options. Changes with every change in sub model or any selected classification group option.
    public filteredClassificationGroupOptions: { [key: string]: SubModelOption[] } = {
        motor: [], // Motor
        autobody: [], // Karosserie
        build: [], // Bauart
        gearbox: [], // Getriebe
        wheelbase: [], // Radstand
        propulsion: [], // Antriebsart
        numberOfAxles: [], // Anzahl Achsen
        driversCab: [], // Fahrerhaus
        tonnage: [], // Tonnage/Tragfähigkeit
        suspension: [], // Federungsart
        equipmentLine: [], // Ausstattungslinie
    };
    // Contains the selected group option's name
    public selectedClassificationGroupOptions: { [key: string]: string } = {
        motor: null, // Motor
        autobody: null, // Karosserie
        build: null, // Bauart
        gearbox: null, // Getriebe
        wheelbase: null, // Radstand
        propulsion: null, // Antriebsart
        numberOfAxles: null, // Anzahl Achsen
        driversCab: null, // Fahrerhaus
        tonnage: null, // Tonnage/Tragfähigkeit
        suspension: null, // Federungsart
        equipmentLine: null, // Ausstattungslinie
    };

    // Contains whether a certain classification group shall be shown or hidden.
    public showClassificationGroups: { [key: string]: boolean } = {
        motor: false, // Motor
        autobody: false, // Karosserie
        build: false, // Bauart
        gearbox: false, // Getriebe
        wheelbase: false, // Radstand
        propulsion: false, // Antriebsart
        numberOfAxles: false, // Anzahl Achsen
        driversCab: false, // Fahrerhaus
        tonnage: false, // Tonnage/Tragfähigkeit
        suspension: false, // Federungsart
        equipmentLine: false, // Ausstattungslinie
    };
    // Set to the currently loading classification group. Null otherwise.
    public loadingClassificationGroup: ClassificationGroup = null;
    // The type variant results from the selected classification groups.
    public typeVariant: string;
    /////////////////////////////////////////////////////////////////////////////*/
    //  END Classification Groups
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Initialization
    //****************************************************************************/
    ngOnInit() {
        this.copyFromOriginalCar();
    }

    private async copyFromOriginalCar(): Promise<void> {
        if (!this.car) {
            throw Error(
                'MISSING_CAR_FROM_PARENT_COMPONENT: The calling component did not supply a car to the building block editor.',
            );
        }
        this.constructionTime = this.car.productionYear;
        this.selectVehicleType(this.car.datIdentification.vehicleType || '1');

        // Manufacturer
        this.setFilteredManufacturers();
        if (!this.car.make) return;
        this.selectedManufacturerName = this.car.make;
        const manufacturer = this.getManufacturerByName(this.selectedManufacturerName);
        if (!manufacturer) {
            this.toastService.error('Hersteller nicht gefunden', 'Bitte wähle einen Hersteller aus der Liste.');
            return;
        }
        await this.setFilteredBaseModels();

        // Base model
        if (!this.car.model) return;
        this.selectedBaseModelName = this.car.model;
        const baseModel = this.getBaseModelByName(this.selectedBaseModelName);
        if (!baseModel) {
            this.toastService.error('Haupttyp nicht gefunden', 'Bitte wähle einen Haupttyp aus der Liste.');
            return;
        }
        this.setFilteredSubModels();

        // Sub model
        if (!this.car.submodel) return;
        this.selectedSubModelName = this.car.submodel;
        const subModel: SubModel = this.getSubModelByName(baseModel, this.selectedSubModelName);
        if (!subModel) {
            this.toastService.error('Untertyp nicht gefunden', 'Bitte wähle einen Untertyp aus der Liste.');
            return;
        }

        // Which classification groups are shown depends on the selected sub model.
        await this.showRequiredClassificationGroups();

        if (this.car.datIdentification.selectedClassificationGroupOptions) {
            // Set all selected classification groups. This works even without the <select> options being available and looks better since the user can see
            // the filled form fields immediately instead of waiting for the server responses.
            Object.keys(this.showClassificationGroups)
                .filter((classificationGroup) => {
                    return this.showClassificationGroups[classificationGroup];
                })
                .forEach((classificationGroup) => {
                    this.selectedClassificationGroupOptions[classificationGroup] =
                        this.car.datIdentification.selectedClassificationGroupOptions[classificationGroup];
                });

            await this.filterClassificationGroupOptions();
        }
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Initialization
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Vehicle Type
    //****************************************************************************/
    public selectVehicleType(vehicleType: Car['datIdentification']['vehicleType']): void {
        this.selectedVehicleType = vehicleType;
        this.setFilteredManufacturers();

        // Show or hide inputs for the classification groups.
        this.showRequiredClassificationGroups();
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Vehicle Type
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Drilldown
    //****************************************************************************/
    public setFilteredManufacturers(): void {
        this.filteredManufacturers = this.manufacturers
            .filter((manufacturer) => manufacturer.vehicleTypes.includes(this.selectedVehicleType))
            .map((manufacturer) => manufacturer.name);

        // Show or hide inputs for the classification groups.
        this.showRequiredClassificationGroups();
    }

    public async setFilteredBaseModels(): Promise<Manufacturer> {
        const selectedManufacturer = this.getManufacturerByName(this.selectedManufacturerName);

        try {
            // The base models and sub models are loaded when a specific manufacturer is selected. This prevents too much consumption of local storage space.
            const manufacturerWithBaseModels = await this.manufacturerService.get(selectedManufacturer._id);

            this.manufacturer = manufacturerWithBaseModels;
            this.filteredBaseModels = this.manufacturer.baseModels.filter(
                (baseModel) => baseModel.vehicleType === this.selectedVehicleType,
            );

            // Show or hide inputs for the classification groups.
            await this.showRequiredClassificationGroups();
            return manufacturerWithBaseModels;
        } catch (error) {
            this.apiErrorService.handleAndRethrow({
                axError: error,
                handlers: {
                    ...getDatErrorHandlers(),
                },
                defaultHandler: {
                    title: 'Hersteller nicht geladen',
                    body: "Der Hersteller konnte nicht abgerufen werden. Probiere es erneut. Falls das Problem bestehen bleibt, melde dich bei der <a href='https://www.autoixpert.de/Kontakt.html'>Hotline</a>.",
                },
            });
        }
    }

    public setFilteredSubModels(): void {
        this.filteredSubModels = this.filteredBaseModels
            .find((baseModel) => baseModel.name === this.selectedBaseModelName)
            .subModels.map((subModel) => subModel.name);

        // Show or hide inputs for the classification groups.
        this.showRequiredClassificationGroups();
    }

    public async setFilteredMotorClassificationOptions(): Promise<void> {
        // Show or hide inputs for the classification groups.
        await this.showRequiredClassificationGroups();
        await this.filterClassificationGroupOptions();
    }

    public iconForCarBrandExists = iconForCarBrandExists;

    public iconNameForCarBrand = iconNameForCarBrand;

    /**
     * Only show the classification groups required for this sub model. Cars need different options than trucks to be identified.
     */
    public async showRequiredClassificationGroups(): Promise<any> {
        // If a required value is not yet set, hide all classification group inputs.
        if (!this.selectedManufacturerName || !this.selectedBaseModelName || !this.selectedSubModelName) {
            for (const classificationGroup in this.showClassificationGroups) {
                // The motor must be selected for every model, so always show it. Hide all other options.
                this.showClassificationGroups[classificationGroup] = classificationGroup === 'motor';
            }
            // Reset the type variant since it depends on the classification groups.
            this.typeVariant = null;
            return emptyObservable;
        }

        const requiredClassificationGroupNumbers = await this.getClassificationGroups();

        for (const classificationGroupNumber of requiredClassificationGroupNumbers) {
            // Show the classification group since it is required for this vehicle.
            this.showClassificationGroups[this.classificationGroupNumbersToLabels[classificationGroupNumber]] = true;
        }
    }

    private async getClassificationGroups(): Promise<number[]> {
        const selectedManufacturer: Manufacturer = this.manufacturer;
        const selectedBaseModel: BaseModel = this.getBaseModelByName(this.selectedBaseModelName);
        const selectedSubModel: SubModel = this.getSubModelByName(selectedBaseModel, this.selectedSubModelName);

        try {
            const datJwt = await this.datAuthenticationService.getJwt();
            return await this.httpClient
                .get<number[]>(`/api/v0/dat/getClassificationGroups`, {
                    headers: DatAuthenticationService.getDatJwtHeaders(datJwt),
                    params: {
                        vehicleTypeId: this.selectedVehicleType + '',
                        manufacturerKey: selectedManufacturer.datKey + '',
                        baseModelKey: selectedBaseModel.datKey + '',
                        subModelKey: selectedSubModel.datKey + '',
                    },
                })
                .toPromise();
        } catch (error) {
            this.apiErrorService.handleAndRethrow({
                axError: error,
                handlers: {
                    ...getDatErrorHandlers(),
                },
                defaultHandler: {
                    title: 'Klassifizierungsgruppen nicht abgerufen',
                    body: "Bitte kontaktiere die <a href='/Hilfe'>Hotline</a>.",
                },
            });
        }
    }

    /**
     *
     * @param changedClassificationGroup
     */
    public async filterClassificationGroupOptions(changedClassificationGroup = null) {
        const nextDependingClassificationGroupsMarkedForQuerying =
            this.getNextDependentClassificationGroupForQuerying(changedClassificationGroup);

        await this.fetchClassificationGroupOptions(changedClassificationGroup);
        // Only if the next classification group exists, try to select the only option automatically.
        if (nextDependingClassificationGroupsMarkedForQuerying) {
            // If there is only one classification group option, select it immediately.
            if (
                this.filteredClassificationGroupOptions[nextDependingClassificationGroupsMarkedForQuerying].length === 1
            ) {
                this.selectedClassificationGroupOptions[nextDependingClassificationGroupsMarkedForQuerying] =
                    this.filteredClassificationGroupOptions[nextDependingClassificationGroupsMarkedForQuerying][0].name;
                // Trigger loading the next option.
                await this.filterClassificationGroupOptions(nextDependingClassificationGroupsMarkedForQuerying);
            }
            // No options available for this group, so load the possible options for the next group.
            else if (
                this.filteredClassificationGroupOptions[nextDependingClassificationGroupsMarkedForQuerying].length === 0
            ) {
                // Trigger loading the next option.
                await this.filterClassificationGroupOptions(nextDependingClassificationGroupsMarkedForQuerying);
            }
            // If the currently selected option is still available after changing a superior classification group, select it again.
            else if (
                this.filteredClassificationGroupOptions[nextDependingClassificationGroupsMarkedForQuerying].find(
                    (subModelOption) =>
                        subModelOption.name ===
                        this.selectedClassificationGroupOptions[nextDependingClassificationGroupsMarkedForQuerying],
                )
            ) {
                // Trigger loading the next option.
                await this.filterClassificationGroupOptions(nextDependingClassificationGroupsMarkedForQuerying);
            }
        }
    }

    /**
     * Get possible options about the classification groups from the server.
     */
    private async fetchClassificationGroupOptions(changedClassificationGroup = null): Promise<any> {
        const selectedManufacturer: Manufacturer = this.manufacturer;
        const selectedBaseModel: BaseModel = this.getBaseModelByName(this.selectedBaseModelName);
        const selectedSubModel: SubModel = this.getSubModelByName(selectedBaseModel, this.selectedSubModelName);

        const selectedClassificationGroupOptionDatKeys: number[] = [];
        for (const classificationGroup in this.showClassificationGroups) {
            // If this option is hidden, do not add its selected value to the list of selected classification group options.
            if (!this.showClassificationGroups[classificationGroup]) {
                continue;
            }

            // Derive the classification group option object form the selected title in order to send the DAT key of the selected option to the server.
            const selectedClassificationGroupOption: SubModelOption = this.filteredClassificationGroupOptions[
                classificationGroup
            ].find(
                (classificationGroupOption) =>
                    classificationGroupOption.name === this.selectedClassificationGroupOptions[classificationGroup],
            );
            // If no value was chosen for this
            if (!selectedClassificationGroupOption) {
                continue;
            }
            selectedClassificationGroupOptionDatKeys.push(selectedClassificationGroupOption.datKey);
        }

        //*****************************************************************************
        //  Get Available Options for Depending Classification Groups
        //****************************************************************************/
        const nextDependingClassificationGroupsMarkedForQuerying =
            this.getNextDependentClassificationGroupForQuerying(changedClassificationGroup);

        // If there is a depending classification group, request its possible values.
        if (nextDependingClassificationGroupsMarkedForQuerying) {
            this.loadingClassificationGroup = nextDependingClassificationGroupsMarkedForQuerying;
            // Request which other classification options are available given the currently chosen ones.
            let classificationGroupOptions: { datKey: number; name: string }[];
            try {
                const datJwt = await this.datAuthenticationService.getJwt();
                classificationGroupOptions = await this.httpClient
                    .get<{ datKey: number; name: string }[]>(`/api/v0/dat/getOptionsByClassification`, {
                        headers: DatAuthenticationService.getDatJwtHeaders(datJwt),
                        params: {
                            vehicleTypeId: this.selectedVehicleType + '',
                            manufacturerKey: selectedManufacturer.datKey + '',
                            baseModelKey: selectedBaseModel.datKey + '',
                            subModelKey: selectedSubModel.datKey + '',
                            // Query only the next depending classification group.
                            classificationGroup:
                                classificationGroupDatIds[nextDependingClassificationGroupsMarkedForQuerying] + '',
                            availableOptions: selectedClassificationGroupOptionDatKeys.map(
                                (selectedClassificationGroupOptionDatKey) =>
                                    '' + selectedClassificationGroupOptionDatKey,
                            ),
                        },
                    })
                    .toPromise();
            } catch (error) {
                this.loadingClassificationGroup = null;
                this.apiErrorService.handleAndRethrow({
                    axError: error,
                    handlers: {
                        ...getDatErrorHandlers(),
                    },
                    defaultHandler: {
                        title: 'Auswahloptionen nicht verfügbar',
                        body: 'Bitte kontaktiere die <a href="/Hilfe" target="_blank">Hotline</a>.',
                    },
                });
            }

            this.loadingClassificationGroup = null;

            // Add those classification group options to the filtered array that are still selectable given the previously selected options.
            this.filteredClassificationGroupOptions[nextDependingClassificationGroupsMarkedForQuerying] =
                classificationGroupOptions;
        }
        // If there is no depending classification group, all classification groups have been set. Compile the DAT€Code.
        else {
            // Request which other classification options are available given the currently chosen ones.
            try {
                const datJwt = await this.datAuthenticationService.getJwt();

                const datECode: string = await this.httpClient
                    .get<string>(`/api/v0/dat/compileDatECode`, {
                        headers: DatAuthenticationService.getDatJwtHeaders(datJwt),
                        params: {
                            vehicleTypeId: this.selectedVehicleType + '',
                            manufacturerKey: selectedManufacturer.datKey + '',
                            baseModelKey: selectedBaseModel.datKey + '',
                            subModelKey: selectedSubModel.datKey + '',
                            selectedOptions: selectedClassificationGroupOptionDatKeys.map(
                                (selectedClassificationGroupOptionDatKey) =>
                                    '' + selectedClassificationGroupOptionDatKey,
                            ),
                        },
                    })
                    .toPromise();

                this.datECode = datECode;
                // The type variant contains of the last 4 digits of the DAT€Code.
                this.typeVariant = datECode.substr(datECode.length - 4);

                let marketIndexes: { datKey: string; name: string }[];
                try {
                    marketIndexes = await this.httpClient
                        .get<any>(`/api/v0/dat/getPriceFocusCases`, {
                            headers: DatAuthenticationService.getDatJwtHeaders(datJwt),
                            params: {
                                datECode: this.datECode,
                                datConstructionTime: this.car.productionYear
                                    ? '' + date2ConstructionTime(this.car.productionYear)
                                    : '',
                            },
                        })
                        .toPromise();
                } catch (error) {
                    this.apiErrorService.handleAndRethrow({
                        axError: error,
                        handlers: {
                            ...getDatErrorHandlers(),
                            MARKET_INDEX_NOT_AVAILABLE_FOR_CONSTRUCTION_TIME: {
                                title: 'Ungültige Bauzeit',
                                body: 'Für das angegebene Baujahr kann die DAT keinen Marktindex zur Verfügung stellen.<br><br>Bitte versuche, das Fahrzeug direkt über die DAT-Oberfläche auszuwählen.',
                            },
                            GETTING_MARKET_INDEX_FAILED: {
                                title: 'Marktindex nicht abrufbar',
                                body: 'Der Marktindex konnte nicht abgerufen werden. Bitte kontaktiere die <a href="/Hilfe" target="_blank">Hotline</a>.',
                            },
                        },
                        defaultHandler: {
                            title: 'Marktindex nicht abrufbar',
                            body: 'Der Marktindex konnte nicht abgerufen werden. Bitte kontaktiere die <a href="/Hilfe" target="_blank">Hotline</a>.',
                        },
                    });
                }

                this.filteredMarketIndexes = marketIndexes;
                // If there is only one market index, set it automatically.
                if (this.filteredMarketIndexes.length === 1) {
                    this.selectedMarketIndex = this.filteredMarketIndexes[0].name;
                } else if (
                    marketIndexes.find((marketIndex) => marketIndex.datKey === this.car.datIdentification.marketIndex)
                ) {
                    this.selectedMarketIndex = this.car.datIdentification.marketIndexName;
                }
            } catch (error) {
                console.error('Error compiling the DAT€Code.', { error });
            }
        }
        /////////////////////////////////////////////////////////////////////////////*/
        //  END Get Available Options for Depending Classification Groups
        /////////////////////////////////////////////////////////////////////////////*/
    }

    private getNextDependentClassificationGroupForQuerying(thisClassificationGroup): ClassificationGroup {
        const dependingClassificationGroupsMarkedForQuerying = [];

        // Iterate over all classification groups that come after this classification group and are shown for this vehicle type.
        const classificationGroupsArray = Object.keys(this.showClassificationGroups);
        // Get all classification groups located below the recently changed classification group.
        const dependingClassificationGroups = classificationGroupsArray.slice(
            classificationGroupsArray.indexOf(thisClassificationGroup) + 1,
        );
        // Check if the classification group is active. If so, mark it for querying.
        for (const dependingClassificationGroup of dependingClassificationGroups) {
            if (!this.showClassificationGroups[dependingClassificationGroup]) {
                continue;
            }
            dependingClassificationGroupsMarkedForQuerying.push(dependingClassificationGroup);
        }
        return dependingClassificationGroupsMarkedForQuerying[0];
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Drilldown
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Convert DAT Names to DAT Objects
    //****************************************************************************/
    public getManufacturerByName(manufacturerName: string): Manufacturer {
        return this.manufacturers.find((manufacturer) => manufacturer.name === manufacturerName);
    }

    public getBaseModelByName(baseModelName: string): BaseModel {
        return this.manufacturer.baseModels.find((baseModel) => baseModel.name === baseModelName);
    }

    public getSubModelByName(baseModel: BaseModel, subModelName: string): SubModel {
        return baseModel.subModels.find((subModel) => subModel.name === subModelName);
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Convert DAT Names to DAT Objects
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  DAT€Code
    //****************************************************************************/
    public getVehicleTypeGerman(vehicleType: Car['datIdentification']['vehicleType']): string {
        let vehicleTypeGerman = '';
        switch (vehicleType) {
            case '1':
                vehicleTypeGerman = 'PKW, SUV, Kleintransporter';
                break;
            case '2':
                vehicleTypeGerman = 'Transporter';
                break;
            case '3':
                vehicleTypeGerman = 'Motorrad';
                break;
            case '4':
                vehicleTypeGerman = 'LKW';
                break;
        }

        return vehicleTypeGerman;
    }

    // For a description what the DAT€Code should look like, go to https://www.dat.de/fileadmin/media/download/download-KD/Schnittstellen-Kompendium/online/DAT_Schnittstellen/deutsch/SilverDAT%20Schnittstellen-Kompendium/index.htm#1917.
    public getVehicleTypeDatECodeKey(): string {
        if (!this.selectedVehicleType) {
            return '';
        }
        return (this.selectedVehicleType + '').padStart(2, '0');
    }

    public getManufacturerDatECodeKey(): string {
        if (!this.selectedManufacturerName || !this.getManufacturerByName(this.selectedManufacturerName)) {
            return '';
        }
        return (this.getManufacturerByName(this.selectedManufacturerName).datKey + '').padStart(3, '0');
    }

    public getBaseModelDatECodeKey(): string {
        if (!this.selectedBaseModelName || !this.getManufacturerByName(this.selectedManufacturerName)) {
            return '';
        }
        const baseModel = this.getBaseModelByName(this.selectedBaseModelName);
        if (!baseModel) {
            return '';
        }

        return (baseModel.datKey + '').padStart(3, '0');
    }

    public getSubModelDatECodeKey(): string {
        if (!this.selectedSubModelName) {
            return '';
        }
        const baseModel = this.getBaseModelByName(this.selectedBaseModelName);
        const subModel = this.getSubModelByName(baseModel, this.selectedSubModelName);
        if (!subModel) {
            return '';
        }

        return (subModel.datKey + '').padStart(3, '0');
    }

    // The type variant is saved after a DAT request. Only the DAT knows this ID.
    public getTypeVariantDatECodeKey(): string {
        return this.typeVariant || '';
    }

    public getMarketIndexKey(): string {
        const selectedMarketIndexObject = this.filteredMarketIndexes.find(
            (marketIndex) => marketIndex.name === this.selectedMarketIndex,
        );

        if (!selectedMarketIndexObject) {
            return '';
        }

        return selectedMarketIndexObject.datKey;
    }

    public getManufacturingTime(): string {
        return (date2ConstructionTime(this.constructionTime) || '') + '';
    }

    public getGermanConstructionTime(): string {
        return moment(this.constructionTime).format('YYYY');
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END DAT€Code
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  View Handlers
    //****************************************************************************/
    public areFieldsComplete(): boolean {
        // The user needs to define the sub model.
        if (!this.selectedManufacturerName || !this.selectedBaseModelName || !this.selectedSubModelName) {
            return false;
        }

        if (!this.typeVariant) {
            return false;
        }

        // The market index is not required but the DAT€Code request really only makes sense with it. So autoiXpert requires it.
        return !!this.selectedMarketIndex;
    }

    /**
     * If a base model (or any other property along the drilldown way) changes, all options that were set for the previous base model need to be
     * cleared because the new base model can have other options.
     * @param {string} below
     */
    public clearOptions(below: ClassificationGroup | 'manufacturer' | 'baseModel' | 'subModel'): void {
        const belowArray: string[] = [
            'manufacturer', // Index 0
            'baseModel', // Index 1
            'subModel', // Index 2
            ...Object.keys(this.classificationGroupLabels), // Index 3 to 13
        ];
        const belowIndex = belowArray.indexOf(below);

        // Never reset the manufacturers since they are the base from which to start.
        // this._manufacturers        = [];
        if (belowIndex < 1) {
            this.selectedBaseModelName = null;
            this.filteredBaseModels = [];
        }
        if (belowIndex < 2) {
            this.selectedSubModelName = null;
            this.filteredSubModels = [];
            // Which classification group needs to be worked on depends on the selected sub model.
            this.showRequiredClassificationGroups();
        }

        const indexOfOptionBelowWhichToClear = belowArray.indexOf(below);
        // Clear all classification group options below the given classification group.
        for (const classificationGroup in this.showClassificationGroups) {
            if (belowArray.indexOf(classificationGroup) > indexOfOptionBelowWhichToClear) {
                this.filteredClassificationGroupOptions[classificationGroup] = [];
                this.selectedClassificationGroupOptions[classificationGroup] = null;
            }
        }

        // Always filter the market index since that element depends on all other options.
        this.selectedMarketIndex = null;
        this.filteredMarketIndexes = [];
    }

    public closeAndSave(): void {
        this.dispatchChangeEvent();
        this.dispatchCloseEvent();
    }

    public closeAndDiscard(): void {
        this.dispatchCloseEvent();
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END View Handlers
    /////////////////////////////////////////////////////////////////////////////*/

    private dispatchChangeEvent(): void {
        this.car.datIdentification.vehicleType = this.selectedVehicleType;
        this.car.make = this.selectedManufacturerName;
        this.car.model = this.selectedBaseModelName;
        this.car.submodel = this.selectedSubModelName;
        this.car.datIdentification.marketIndex = this.filteredMarketIndexes.find(
            (marketIndex) => marketIndex.name === this.selectedMarketIndex,
        ).datKey;
        this.car.datIdentification.datEuropaCode = this.datECode;
        this.car.productionYear = this.constructionTime;

        if (!this.car.datIdentification.selectedClassificationGroupOptions) {
            this.car.datIdentification.selectedClassificationGroupOptions = new SelectedClassificationGroupOptions();
        }

        Object.assign(
            this.car.datIdentification.selectedClassificationGroupOptions,
            this.selectedClassificationGroupOptions,
        );

        this.carChange.emit();
    }

    private dispatchCloseEvent(): void {
        this.close.emit();
    }
}

//*****************************************************************************
//  Utility Functions
//****************************************************************************/
export const classificationGroupDatIds = {
    motor: 1,
    autobody: 2,
    build: 7,
    gearbox: 11,
    wheelbase: 3,
    propulsion: 4,
    numberOfAxles: 9,
    driversCab: 5,
    tonnage: 6,
    suspension: 8,
    equipmentLine: 10,
};

export const DATStartOfTime = moment().set({
    year: 1969,
    // 11 = December
    month: 11,
    date: 1,
    hour: 0,
    minute: 0,
    second: 0,
    // Believe it or not, this is important for calculating the time difference between two dates via moment.diff.
    milliseconds: 0,
});

/**
 * Converts a date (moment object or anything a moment object accepts as a construction parameter) to a DAT construction time.
 */

export function date2ConstructionTime(dateStringOrMomentInstance): number {
    if (!dateStringOrMomentInstance) return null;

    const date = moment(dateStringOrMomentInstance);
    // Get the months between DAT's start date (1969-12-01) and the given date.
    const differenceInMonths = date.diff(DATStartOfTime.clone(), 'month');

    // Set all dates above the 28th to the 28 since DAT only allows 9 3-day-steps within their decimal date. And 3 * 9 = 28.
    // If there was a date like 2016-01-31, 31 / 3 = 10.33333334. If we add that to the DAT decimal date, it would add a full month which would result in 2016-02-01 --> bad.
    const dayInDate = date.date() > 28 ? 28 : date.date();
    const differenceIn3DaySteps = Math.floor(dayInDate / 3);

    return +(differenceInMonths + '' + differenceIn3DaySteps);
}

/////////////////////////////////////////////////////////////////////////////*/
//  END Utility Functions
/////////////////////////////////////////////////////////////////////////////*/

import { HttpClient } from '@angular/common/http';
import { SwUpdate } from '@angular/service-worker';
import { Query as FeathersQuery } from '@feathersjs/feathers';
import { Observable, of } from 'rxjs';
import { startWith, tap } from 'rxjs/operators';
import { removeFromArray } from '@autoixpert/lib/arrays/remove-from-array';
import {
    DataTypeBase,
    DatabaseServiceName,
    ObjectStoreAndIndexMigrations,
    RecordMigrations,
} from '@autoixpert/models/indexed-db/database.types';
import { FeathersSocketioService } from '../../services/feathers-socketio.service';
import { FrontendLogService } from '../../services/frontend-log.service';
import { NetworkStatusService } from '../../services/network-status.service';
import { SyncIssueNotificationService } from '../../services/sync-issue-notification.service';
import { ToastService } from '../../services/toast.service';
import { applyMongoQuery } from './apply-mongo-query';
import { LiveSyncServiceBase } from './live-sync.service-base';

/**
 * When a list of records (report list, invoice list etc.) is open, we provide live updates.
 *
 * This service allows registering which record updates the user is currently interested in.
 */
export class LiveSyncWithInMemoryCacheServiceBase<DataType extends DataTypeBase> extends LiveSyncServiceBase<DataType> {
    /**
     * If there are more than this number of cache entries, remove the oldest entry. This ensures this in-memory cache
     * does not use too much memory.
     */
    private readonly maximumNumberOfInMemoryCacheEntries: number = 100;
    /**
     * This is the Map that actually holds the cached records.
     */
    private inMemoryCache = new Map<DataType['_id'], DataType>();
    /**
     * This array is for tracking the order in which the entries were added to the cache. These are tracked so that the
     * oldest entries may be removed from the cache when the maximum number of cache entries is exceeded.
     * The youngest entry lives at the beginning of the array.
     */
    private inMemoryCacheOrder: DataType['_id'][] = [];

    constructor(params: {
        serviceName: DatabaseServiceName;
        serviceNamePlural?: string;
        httpClient: HttpClient;
        feathersSocketioClient: FeathersSocketioService;
        networkStatusService: NetworkStatusService;
        syncIssueNotificationService: SyncIssueNotificationService;
        frontendLogService: FrontendLogService;
        skipOutstandingSyncsCheckOnLogout?: boolean;
        recordMigrations?: RecordMigrations<DataType>;
        objectStoreAndIndexMigrations?: ObjectStoreAndIndexMigrations<DataType>;
        maximumNumberOfInMemoryCacheEntries?: number;
        keysOfQuickSearchRecords?: string[];
        get$SearchMongoQuery?: (searchTerm: string) => [string, any];
        toastNotificationService?: ToastService;
        serviceWorker: SwUpdate;
    }) {
        super({
            serviceName: params.serviceName,
            serviceNamePlural: params.serviceNamePlural,
            httpClient: params.httpClient,
            feathersSocketioClient: params.feathersSocketioClient,
            networkStatusService: params.networkStatusService,
            syncIssueNotificationService: params.syncIssueNotificationService,
            frontendLogService: params.frontendLogService,
            skipOutstandingSyncsCheckOnLogout: params.skipOutstandingSyncsCheckOnLogout,
            recordMigrations: params.recordMigrations,
            objectStoreAndIndexMigrations: params.objectStoreAndIndexMigrations,
            keysOfQuickSearchRecords: params.keysOfQuickSearchRecords,
            get$SearchMongoQuery: params.get$SearchMongoQuery,
            toastNotificationService: params.toastNotificationService,
            serviceWorker: params.serviceWorker,
        });

        if (Number.isInteger(params.maximumNumberOfInMemoryCacheEntries)) {
            this.maximumNumberOfInMemoryCacheEntries = params.maximumNumberOfInMemoryCacheEntries;
        }

        this.registerLocalDatabaseEventHandlers();
    }

    //*****************************************************************************
    //  Return In-Memory Cached Record If Possible
    //****************************************************************************/
    public async get(recordId: DataType['_id']): Promise<DataType> {
        const cachedRecord: DataType = this.inMemoryCache.get(recordId);
        if (cachedRecord) {
            return cachedRecord;
        }

        return super.get(recordId);
    }

    public find(feathersQuery?: FeathersQuery | Partial<DataType>): Observable<DataType[]> {
        // Return empty array for { _id: { $in: [] } } to be consistent with MongoDB behavior
        if (feathersQuery?._id?.$in && feathersQuery._id.$in.length === 0) {
            return of([]);
        }

        return super.find(feathersQuery).pipe(
            // Start with cached records if there are any.
            this.inMemoryCache.size
                ? startWith(
                      // Filter and sort cached records.
                      applyMongoQuery(
                          feathersQuery ?? {},
                          [...this.inMemoryCache.values()],
                          this.localDb.get$SearchMongoQuery,
                      ),
                  )
                : tap(),
            tap({
                next: (records: DataType[]) => {
                    for (const record of records) {
                        this.setInMemoryCache(record);
                    }
                },
            }),
        );
    }
    /////////////////////////////////////////////////////////////////////////////*/
    //  END Return In-Memory Cached Record If Possible
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Update Cached Records on Service Events
    //****************************************************************************/
    private registerLocalDatabaseEventHandlers() {
        /**
         * Replace the changed record in the record cache when it's updated in the record details component.
         */
        this.patchedInLocalDatabase$.subscribe(({ serverShadow, patchedRecord }) => {
            this.setInMemoryCache(patchedRecord);
        });
        this.createdInLocalDatabase$.subscribe((record) => {
            this.setInMemoryCache(record);
        });
        this.deletedInLocalDatabase$.subscribe((recordId) => {
            this.deleteInMemoryCache(recordId);
        });
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Update Cached Records on Service Events
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  In-Memory Cache
    //****************************************************************************/
    /**
     * This method is public so that an entry may explicitly be added to the cache through a component, e.g. when
     * a user opens a report from the report list. If the report list entry is the 101st entry, it would not be part
     * of the in-memory cache by default. That happens when a user searches e.g. all reports from the last quarter.
     */
    public setInMemoryCache(record: DataType): void {
        this.inMemoryCache.set(record._id, record);

        // Put the youngest cache entry at the front of the array. Remove the old position if it exists.
        removeFromArray(record._id, this.inMemoryCacheOrder);
        this.inMemoryCacheOrder.unshift(record._id);

        while (this.inMemoryCache.size > this.maximumNumberOfInMemoryCacheEntries) {
            const obsoleteCacheEntryId = this.inMemoryCacheOrder.pop();
            this.inMemoryCache.delete(obsoleteCacheEntryId);
        }
    }

    private getInMemoryCache(recordId): DataType | undefined {
        return this.inMemoryCache.get(recordId);
    }

    private deleteInMemoryCache(recordId): void {
        this.inMemoryCache.delete(recordId);
        const inMemoryCacheOrderIndex: number = this.inMemoryCacheOrder.findIndex(
            (cachedRecordId) => cachedRecordId === recordId,
        );
        if (inMemoryCacheOrderIndex > -1) {
            this.inMemoryCacheOrder.splice(inMemoryCacheOrderIndex, 1);
        }
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END In-Memory Cache
    /////////////////////////////////////////////////////////////////////////////*/

    public clearDatabase() {
        this.inMemoryCacheOrder = [];
        this.inMemoryCache = new Map();
        return super.clearDatabase();
    }
}

/**
 * Ensure the output is an array.
 * Used for XML conversion in SOAP. XML does not know about whether a child occurs once (object) or multiple times (array of objects). In
 * cases where sometimes objects or an array of objects occur, this function can be used to unify the input to an array of object(s).
 */
export function ensureArray<Type>(input: Type | Type[]): Type[] {
    if (Array.isArray(input)) {
        return input;
    }
    /**
     * If the input is null or undefined, return an empty array.
     */
    if (input == null) {
        return [];
    }
    return [input];
}

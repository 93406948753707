<div class="dialog-overlay" (mousedown)="handleOverlayClick($event)" @dialogEnterAndLeave>
    <div class="dialog-container">
        <!-- Close button -->
        <div id="close-button" (click)="closeAndDiscard()">
            <mat-icon>close</mat-icon>
        </div>

        <!-- Headline -->

        <!-- Headline -->
        <div class="editor-headline">
            <h2>Bedingungen des Bausteins</h2>
            <div>{{ buildingBlock.title }}</div>
        </div>

        <!-- Condition group editor -->
        <document-building-block-condition-group-editor
            [availableDocumentPlaceholders]="availableDocumentPlaceholders"
            [placeholderValues]="placeholderValues"
            [placeholderValueTree]="placeholderValueTree"
            [fieldGroupConfigs]="fieldGroupConfigs"
            [conditionGroup]="buildingBlock"></document-building-block-condition-group-editor>

        <!-- No conditions placeholder -->
        <div *ngIf="!(buildingBlock.conditions ?? []).length" class="no-conditions-placeholder">
            <div class="no-conditions-placeholder-icon-container">
                <mat-icon>rule</mat-icon>
            </div>

            <div class="no-conditions-placeholder-info-text">
                <div class="no-conditions-placeholder-info-text-heading">Bedingungen für gesamten Textbaustein</div>
                <div class="no-conditions-placeholder-info-text-description">
                    Bestimme, ob dieser Baustein - inklusive aller Varianten - abgedruckt werden kann oder nicht.
                </div>
            </div>
        </div>

        <div class="document-building-block-container">
            <!-- Save & Cancel buttons -->
            <div id="buttons-container">
                <button
                    (click)="isSavingAllowed() && closeAndSave()"
                    [class.disabled]="!isSavingAllowed()"
                    [matTooltip]="getSavingButtonTooltip()">
                    Speichern
                </button>
                <button class="flat cancel-button" (click)="closeAndDiscard()">Abbrechen</button>
            </div>
        </div>
    </div>
</div>

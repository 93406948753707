import { HttpClient, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable()
export class ContactExportService {
    constructor(private httpClient: HttpClient) {}

    public downloadListOfContactPeople(): Observable<HttpResponse<Blob>> {
        return this.httpClient.get(`/api/v0/exports/contactPeople`, {
            observe: 'response',
            responseType: 'blob',
        });
    }
}

import { AxError } from '@autoixpert/models/errors/ax-error';
import { AxAngularErrorHandlerData } from './ax-angular-error-handler-data';

/**
 * Create a browser console entry of an AxError which is easy to read and debug.
 */
export function consoleLogAxError(axError: AxError) {
    /**
     * We do not need to log errors that were handled by the frontend. Examples of handled errors:
     * - Show an error toast about DOCX template errors with instructions to solve the template issues.
     * - Redirect the user to the login when the JWT expired.
     *
     * Errors are marked as handled instead of swallowed in the ApiErrorService because we still want them to
     * break the control flow of the function where ApiErrorService.handle() is called. That ensures no
     * further action is taken after the error was handled except there is another explicit catch block.
     */
    if ((axError.data as AxAngularErrorHandlerData)?.didFrontendHandleError) {
        return;
    }

    //*****************************************************************************
    //  Error Chain
    //****************************************************************************/
    const causedByErrorCodes: string[] = [axError.code];
    let causedByError: AxError | Error | null = axError.causedBy;
    while (causedByError) {
        if (typeof causedByError === 'object' && 'causedBy' in causedByError) {
            causedByErrorCodes.push(causedByError.code);
            causedByError = causedByError.causedBy;
        } else {
            causedByErrorCodes.push(causedByError.name);
            break;
        }
    }
    let causedByErrorCodesString: string = axError.code;
    if (causedByErrorCodes.length >= 2) {
        causedByErrorCodesString = causedByErrorCodes.join(' ⬅ ');
    }
    /////////////////////////////////////////////////////////////////////////////*/
    //  END Error Chain
    /////////////////////////////////////////////////////////////////////////////*/

    /**
     * "%o" outputs the object that follows in the optimal form: in our case an object which can be opened & inspected
     * by the developer.
     * Details: https://developer.mozilla.org/en-US/docs/Web/API/console#o_2
     * If we would not use "%o", console.log('Message\n\n', error) would join the two parameters with a space, so the
     * object would be a little indented -> Harder to visually process since the left alignment of the object is a little off.
     */
    const errorMessage = `${causedByErrorCodesString}  |  ${axError.message}\n\n%o`;

    console.error(errorMessage, {
        /**
         * Use an object which wraps the error properties because the Developer Tools print an error without custom
         * properties such as causedBy or data.
         *
         * Destructure the error object so that we do not have to open an extra object layer with only the property "error".
         */
        ...axError,
    });
}

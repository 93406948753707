import { Component, EventEmitter, HostListener, Input, OnInit, Output, ViewChild } from '@angular/core';
import {
    MatLegacyAutocomplete as MatAutocomplete,
    MatLegacyAutocompleteSelectedEvent as MatAutocompleteSelectedEvent,
    MatLegacyAutocompleteTrigger as MatAutocompleteTrigger,
} from '@angular/material/legacy-autocomplete';
import { MatLegacyChipInputEvent as MatChipInputEvent } from '@angular/material/legacy-chips';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import moment from 'moment';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { ConfirmDialogComponent } from '@autoixpert/components/confirm-dialog/confirm-dialog.component';
import { removeFromArray } from '@autoixpert/lib/arrays/remove-from-array';
import { sortByProperty } from '@autoixpert/lib/arrays/sort-by-property';
import {
    extractCarBrandIconName,
    iconFilePathForCarBrand,
    iconForCarBrandExists,
} from '@autoixpert/lib/car/icon-for-car-brand-exists';
import { getContactPersonFullNameWithOrganization } from '@autoixpert/lib/contact-people/get-contact-person-full-name-with-organization';
import { getFullNameWithSalutation } from '@autoixpert/lib/placeholder-values/get-full-name-with-salutation';
import { ContactPerson } from '@autoixpert/models/contacts/contact-person';
import { AxError } from '@autoixpert/models/errors/ax-error';
import { Report } from '@autoixpert/models/reports/report';
import { ResidualValueBid } from '@autoixpert/models/reports/residual-value/residual-value-bid';
import { ResidualValueBidder } from '@autoixpert/models/reports/residual-value/residual-value-bidder';
import { ResidualValueBidderGroup } from '@autoixpert/models/reports/residual-value/residual-value-bidder-group';
import { ResidualValueInvitationReceipt } from '@autoixpert/models/reports/residual-value/residual-value-invitation-receipt';
import {
    IResidualValueInvitationReceipt,
    ResidualValueRequest,
} from '@autoixpert/models/reports/residual-value/residual-value-request';
import { User } from '@autoixpert/models/user/user';
import { fadeInAndOutAnimation } from '../../../../shared/animations/fade-in-and-out.animation';
import { slideOutSide } from '../../../../shared/animations/slide-out-side.animation';
import {
    PromptDialogComponent,
    PromptDialogData,
    PromptDialogReturnValue,
} from '../../../../shared/components/prompt-dialog/prompt-dialog.component';
import { ApiErrorService } from '../../../../shared/services/api-error.service';
import { ContactPersonService } from '../../../../shared/services/contact-person.service';
import { LoggedInUserService } from '../../../../shared/services/logged-in-user.service';
import { NetworkStatusService } from '../../../../shared/services/network-status.service';
import { ReportDetailsService } from '../../../../shared/services/report-details.service';
import { ResidualValueBidderGroupService } from '../../../../shared/services/residual-value-bidder-group.service';
import {
    ResidualValueInvitationService,
    ResidualValueInvitationsCreateResponse,
} from '../../../../shared/services/residual-value-invitation.service';
import { ResidualValueRequestService } from '../../../../shared/services/residual-value-request.service';
import { ToastService } from '../../../../shared/services/toast.service';
import { TutorialStateService } from '../../../../shared/services/tutorial-state.service';
import { UserPreferencesService } from '../../../../shared/services/user-preferences.service';
import { UserService } from '../../../../shared/services/user.service';

@Component({
    selector: 'residual-value-request-dialog',
    templateUrl: 'residual-value-request-dialog.component.html',
    styleUrls: ['residual-value-request-dialog.component.scss'],
    animations: [slideOutSide(100, 100), fadeInAndOutAnimation()],
})
export class ResidualValueRequestDialogComponent implements OnInit {
    constructor(
        private toastService: ToastService,
        public userPreferences: UserPreferencesService,
        private contactPersonService: ContactPersonService,
        private residualValueInvitationService: ResidualValueInvitationService,
        private residualValueRequestService: ResidualValueRequestService,
        private apiErrorService: ApiErrorService,
        private reportDetailsService: ReportDetailsService,
        private tutorialStateService: TutorialStateService,
        private networkStatusService: NetworkStatusService,
        private residualValueBidderGroupService: ResidualValueBidderGroupService,
        private dialog: MatDialog,
        private loggedInUserService: LoggedInUserService,
        private userService: UserService,
    ) {}

    public user: User;

    @Input() report: Report;
    @Input() residualValueTargetDate: string;

    @Output() close: EventEmitter<void> = new EventEmitter();
    @Output() deleteResidualValueExchange: EventEmitter<void> = new EventEmitter();
    @Output() reportChange: EventEmitter<Report> = new EventEmitter();

    public availableBidderGroups: ResidualValueBidderGroup[] = [];
    public selectedBidderGroups: ResidualValueBidderGroup[] = [];
    // Used to prevent closing the entire dialog when hitting Escape while the title dialog is open.
    public groupTitleDialogOpen: boolean;

    public availableRecipients: ResidualValueBidder[] = [];
    public recipientInEditMode: ResidualValueBidder;

    public residualValueCreationPending: boolean;
    public previewPending: boolean = false;
    public invitationsPending: Map<ContactPerson, boolean> = new Map();
    public emailJustSent: boolean = false;
    // The contacts from custom bids, used for the autocomplete of the contact person form
    public previousBidders: ResidualValueBidder[] = [];

    //Filtered bidder groups for autocomplete
    public filteredBidderGroupsIds: ResidualValueBidderGroup[] = [];

    @ViewChild('bidderGroupAutocomplete', { static: false }) bidderGroupAutocomplete: MatAutocomplete = null;

    //*****************************************************************************
    //  Initialization
    //****************************************************************************/
    ngOnInit() {
        if (!this.report.valuation.autoixpertResidualValueOffer) {
            this.report.valuation.autoixpertResidualValueOffer = new ResidualValueRequest();
            // we need the id of the report to check if an offer belongs to the report or an amendment report
            this.report.valuation.autoixpertResidualValueOffer.createdInReportId = this.report._id;
            this.emitReportChange();
        } else if (!this.report.valuation.autoixpertResidualValueOffer.createdInReportId) {
            // we need the id of the report to check if an offer belongs to the report or an amendment report
            this.report.valuation.autoixpertResidualValueOffer.createdInReportId = this.report._id;
            this.emitReportChange();
        }
        this.getAvailableRecipients().subscribe({
            next: () => {
                // Remove any references to recipients that have been deleted. Keeping them could lead to the send button being active, while there is no one visibly selected.
                this.removeNonExistentRecipients();
                this.selectDefaultRecipients();
            },
        });
        this.setDefaultBindingPeriod();
        this.getPreviousBidders();

        this.user = this.loggedInUserService.getUser();
        this.retrieveResidualValueBidderGroups();
    }

    public setDefaultBindingPeriod(): void {
        if (!this.report.valuation.autoixpertResidualValueOffer.bindingPeriodInDays) {
            this.report.valuation.autoixpertResidualValueOffer.bindingPeriodInDays =
                this.userPreferences.residualValueBindingPeriod;
            this.emitReportChange();
        }
    }

    public selectDefaultRecipients(): void {
        // Don't overwrite previously selected recipients
        if (this.report.valuation.autoixpertResidualValueOffer.selectedRecipientIds.length) return;

        // Select either the default recipients (if set) or all available recipients
        if (this.userPreferences.residualValueDefaultRecipients.length) {
            this.report.valuation.autoixpertResidualValueOffer.selectedRecipientIds = [
                ...this.filterOutMissingRecipients(this.userPreferences.residualValueDefaultRecipients),
            ];
        } else {
            this.report.valuation.autoixpertResidualValueOffer.selectedRecipientIds = this.availableRecipients.map(
                (contactPerson) => contactPerson._id,
            );
        }
    }

    public getPreviousBidders(): void {
        this.contactPersonService
            .find({ organizationType: { $in: ['residualValueRequestRecipient'] } })
            .pipe(
                tap({
                    next: (recipients: ResidualValueBidder[]) => {
                        this.previousBidders = recipients;
                    },
                    error: (error) => {
                        this.apiErrorService.handleAndRethrow({
                            axError: error,
                            handlers: {},
                            defaultHandler: {
                                title: 'Bieter konnten nicht ausgelesen werden',
                                body: 'Bitte kontaktiere die Hotline.',
                            },
                        });
                    },
                }),
            )
            .subscribe();
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Initialization
    /////////////////////////////////////////////////////////////////////////////*/

    public rememberBindingPeriod(): void {
        this.userPreferences.residualValueBindingPeriod =
            this.report.valuation.autoixpertResidualValueOffer.bindingPeriodInDays;
    }

    //*****************************************************************************
    //  CRUD Recipients
    //****************************************************************************/

    public getAvailableRecipients(): Observable<ContactPerson[]> {
        return this.contactPersonService.find({ organizationType: { $in: ['residualValueRequestRecipient'] } }).pipe(
            tap({
                next: (recipients: ResidualValueBidder[]) => {
                    this.availableRecipients = recipients;
                },
                error: (error) => {
                    this.apiErrorService.handleAndRethrow({
                        axError: error,
                        handlers: {},
                        defaultHandler: {
                            title: 'Kontakte für Restwertanfrage nicht ausgelesen',
                            body: 'Bitte kontaktiere die Hotline.',
                        },
                    });
                },
            }),
        );
    }

    public editNewRecipient(): void {
        this.editContact(new ResidualValueBidder({ organizationType: 'residualValueRequestRecipient' }));
    }

    public async saveRecipientInEditMode(): Promise<void> {
        if (this.isReportLocked()) return;

        // Don't save without email
        if (!this.recipientInEditMode.email) return;

        // If the user selected a person from the autocomplete, chances are that that person has type 'bidder'. Therefore, always set the type to 'residualValueRequestRecipient'
        this.recipientInEditMode.organizationType = 'residualValueRequestRecipient';

        if (!this.recipientInEditMode.assignedBidderGroupIds) {
            this.recipientInEditMode.assignedBidderGroupIds = [];
        }

        const index: number = this.availableRecipients.findIndex(
            (recipient) => recipient._id === this.recipientInEditMode._id,
        );

        // Recipient is not yet in list -> add
        if (index < 0) {
            this.availableRecipients.push(this.recipientInEditMode);
            // Mark the newly created recipient as selected.
            this.toggleRecipient(this.recipientInEditMode);
            try {
                await this.contactPersonService.create(this.recipientInEditMode);
            } catch (error) {
                this.apiErrorService.handleAndRethrow({
                    axError: error,
                    handlers: {},
                    defaultHandler: {
                        title: 'Kontakt konnte nicht angelegt werden',
                        body: 'Bitte kontaktiere die Hotline.',
                    },
                });
            }
        }
        // Recipient is already listed -> update
        else {
            this.availableRecipients.splice(index, 1, this.recipientInEditMode);
            try {
                await this.contactPersonService.put(this.recipientInEditMode);
            } catch (error) {
                this.apiErrorService.handleAndRethrow({
                    axError: error,
                    handlers: {},
                    defaultHandler: {
                        title: 'Kontakt konnte nicht aktualisiert werden',
                        body: 'Bitte kontaktiere die Hotline.',
                    },
                });
            }
        }

        this.leaveRecipientEditMode();
    }

    public async editContact(contactPerson: ResidualValueBidder): Promise<void> {
        this.recipientInEditMode = JSON.parse(JSON.stringify(contactPerson));
    }

    public leaveRecipientEditMode(): void {
        this.recipientInEditMode = null;
    }

    public async deleteContact(contactPerson: ResidualValueBidder): Promise<void> {
        const index: number = this.availableRecipients.indexOf(contactPerson);
        this.availableRecipients.splice(index, 1);

        try {
            await this.contactPersonService.delete(contactPerson._id);
        } catch (error) {
            this.apiErrorService.handleAndRethrow({
                axError: error,
                handlers: {},
                defaultHandler: {
                    title: 'Kontakt konnte nicht gelöscht werden',
                    body: 'Bitte kontaktiere die Hotline.',
                },
            });
        }
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END CRUD Recipients
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Bidder Groups
    //****************************************************************************/

    /**
     * API request to fill up our list of all available bidder groups.
     */
    public retrieveResidualValueBidderGroups(): void {
        this.residualValueBidderGroupService.find().subscribe({
            next: (groups) => {
                this.availableBidderGroups = groups.sort(sortByProperty('title'));
            },
            error: (error) => {
                this.apiErrorService.handleAndRethrow({
                    axError: error,
                    handlers: {},
                    defaultHandler: {
                        title: 'Bietergruppen konnten nicht geholt werden',
                        body: "Bitte kontaktiere die <a href='/Hilfe'>Hotline</a>.",
                    },
                });
            },
        });
    }

    /**
     * Before the initial create happens, open up a dialog which extracts the user input as the title of the new bidder group
     */
    public async openResidualValueBidderGroupTitleDialog(): Promise<void> {
        this.groupTitleDialogOpen = true;

        const result = await this.dialog
            .open<PromptDialogComponent, PromptDialogData, PromptDialogReturnValue>(PromptDialogComponent, {
                data: {
                    heading: 'Gruppentitel',
                    content: 'Welchen Titel möchtest du für die Bietergruppe vergeben?',
                    placeholder: 'Titel',
                    confirmLabel: 'Übernehmen',
                    cancelLabel: 'Abbrechen',
                },
            })
            .afterClosed()
            .toPromise();

        this.groupTitleDialogOpen = false;

        if (!result || !result.confirmed) {
            return;
        }

        if (result.confirmed && result.userInput) {
            await this.createResidualValueBidderGroup(result.userInput);
        } else {
            this.toastService.error(
                'Ungültiger Titel',
                'Da ist wohl etwas schief gelaufen. Bitte trage einen gültigen Titel ein.',
            );
            return;
        }
    }

    /**
     * Change the selection state of the bidder group
     * @param group
     */
    public toggleSelectBidderGroup(group: ResidualValueBidderGroup) {
        //
        if (this.selectedBidderGroups.includes(group)) {
            removeFromArray(group, this.selectedBidderGroups);
        } else {
            this.selectedBidderGroups.push(group);
        }

        this.selectBiddersInSelectedGroups();
    }

    /**
     * Select all recipients in any of the selected groups.
     */
    public selectBiddersInSelectedGroups() {
        // If a recipient is assigned at least one of the selected groups, select them as recipient.
        const biddersToBeSelected: ResidualValueBidder[] = this.getAllAssignedBidders(this.selectedBidderGroups);

        this.report.valuation.autoixpertResidualValueOffer.selectedRecipientIds = biddersToBeSelected.map(
            (bidder) => bidder._id,
        );
    }

    /**
     * Shortcut function, which provides all bidders who are assigned to the given bidder group
     * @param residualValueBidderGroups
     */
    public getAllAssignedBidders(residualValueBidderGroups: ResidualValueBidderGroup[]): ResidualValueBidder[] {
        return this.availableRecipients.filter((recipient) => {
            for (const group of residualValueBidderGroups) {
                if (recipient.assignedBidderGroupIds?.includes(group._id)) {
                    return true;
                }
            }
        });
    }

    /**
     * Create new bidder group via service and save it locally in the component
     * @param groupTitle
     */
    public async createResidualValueBidderGroup(groupTitle: string): Promise<ResidualValueBidderGroup> {
        if (this.bidderGroupAlreadyExists(groupTitle)) {
            this.toastService.error(
                `Bietergruppe existiert bereits`,
                `Die Gruppe ${groupTitle} existiert bereits. Du kannst sie in der Liste deiner Bietergruppen finden`,
            );
            return;
        }

        const newBidderGroup: ResidualValueBidderGroup = new ResidualValueBidderGroup({
            title: groupTitle,
            carBrand: extractCarBrandIconName(groupTitle),
            createdBy: this.user._id,
            teamId: this.user.teamId,
        });

        try {
            await this.residualValueBidderGroupService.create(newBidderGroup);
        } catch (error) {
            this.apiErrorService.handleAndRethrow({
                axError: error,
                handlers: {},
                defaultHandler: {
                    title: 'Bietergruppe nicht gespeichert',
                    body: "Die Bietergruppe konnte nicht gespeichert werden. Bitte kontaktiere die <a href='/Hilfe'>Hotline</a>.",
                },
            });
        }
        this.availableBidderGroups.push(newBidderGroup);
        this.availableBidderGroups.sort(sortByProperty('title'));
        return newBidderGroup;
    }

    protected iconForCarBrandExists = iconForCarBrandExists;
    protected iconFilePathForCarBrand = iconFilePathForCarBrand;

    public openResidualValueBidderGroupUpdateDialog(bidderGroup: ResidualValueBidderGroup): void {
        this.dialog
            .open<PromptDialogComponent, PromptDialogData, PromptDialogReturnValue>(PromptDialogComponent, {
                data: {
                    heading: 'Gruppentitel',
                    content: 'Welchen Titel möchtest du für die Bietergruppe ergänzen?',
                    placeholder: 'Gruppe',
                    initialInputValue: bidderGroup.title,
                    confirmLabel: 'Übernehmen',
                    cancelLabel: 'Abbrechen',
                },
            })
            .afterClosed()
            .subscribe((response) => {
                if (response?.userInput) {
                    this.updateResidualValueBidderGroup(response.userInput, bidderGroup);
                }
            });
    }

    /**
     * Update the title of a bidder group and also emit the changes in every assigned residual value bidder
     * @param groupTitle
     * @param bidderGroup
     */
    public async updateResidualValueBidderGroup(
        groupTitle: string,
        bidderGroup: ResidualValueBidderGroup,
    ): Promise<void> {
        try {
            bidderGroup.title = groupTitle;
            bidderGroup.carBrand = extractCarBrandIconName(groupTitle);
            await this.residualValueBidderGroupService.put(bidderGroup);
        } catch (error) {
            this.apiErrorService.handleAndRethrow({
                axError: error,
                handlers: {},
                defaultHandler: {
                    title: 'Änderung nicht gespeichert',
                    body: "Die Änderung an der Bietergruppe konnte nicht gespeichert werden. Bitte kontaktiere die <a href='/Hilfe'>Hotline</a>.",
                },
            });
        }
    }

    public async deleteResidualValueBidderGroup(bidderGroup: ResidualValueBidderGroup): Promise<void> {
        try {
            await this.residualValueBidderGroupService.delete(bidderGroup._id);
        } catch (error) {
            this.apiErrorService.handleAndRethrow({
                axError: error,
                handlers: {},
                defaultHandler: {
                    title: 'Bietergruppe nicht gelöscht',
                    body: "Die Bietergruppe konnte nicht gelöscht werden. Bitte kontaktiere die <a href='/Hilfe'>Hotline</a>.",
                },
            });
        }
        removeFromArray(bidderGroup, this.availableBidderGroups);
        await this.removeGroupIdFromAllAssignedBidders(bidderGroup._id);
    }

    public async removeBidderGroupIdFromBidder(groupId: string, bidder: ResidualValueBidder) {
        removeFromArray(groupId, bidder.assignedBidderGroupIds);

        try {
            await this.contactPersonService.put(bidder);
        } catch (error) {
            this.apiErrorService.handleAndRethrow({
                axError: error,
                handlers: {},
                defaultHandler: {
                    title: 'Kontakt konnte nicht aktualisiert werden',
                    body: 'Bitte kontaktiere die Hotline.',
                },
            });
        }
    }

    public async removeGroupIdFromAllAssignedBidders(groupId: string): Promise<void> {
        /**
         * Handle the recipient being edited since that is a copy of the recipient until the user hits the save button. Not saving
         * automatically enables the user to discard the changes he made.
         */
        removeFromArray(groupId, this.recipientInEditMode?.assignedBidderGroupIds);

        await Promise.all(
            this.availableRecipients.map((recipient) => {
                if (recipient.assignedBidderGroupIds?.includes(groupId)) {
                    return this.removeBidderGroupIdFromBidder(groupId, recipient);
                }
            }),
        );
    }

    /**
     * Further actions after chip list input has been ended.
     * @param chipInputEvent
     */
    public async handleBidderGroupInputTokenEnd(chipInputEvent: MatChipInputEvent) {
        if (this.bidderGroupAutocomplete.isOpen) {
            return;
        }

        const inputValue = (chipInputEvent.value || '').trim();

        // If the user hit enter without having specified a bidder group name, don't try to create one with an empty name.
        if (!inputValue) {
            return;
        }

        let existingBidderGroup: ResidualValueBidderGroup = this.availableBidderGroups.find(
            (group) => group.title === inputValue,
        );
        if (!existingBidderGroup) {
            existingBidderGroup = await this.createResidualValueBidderGroup(inputValue);
        }

        await this.assignBidderGroupToBidderInEditMode(existingBidderGroup._id);

        // Clear input
        this.clearInputAndResetAutocomplete(chipInputEvent.chipInput.inputElement);
    }

    public bidderGroupAlreadyExists(title: string): boolean {
        return this.availableBidderGroups.some((group) => group.title === title);
    }

    /**
     * Persist IDs of the contact person on the bidder group and vice versa, after adding a contact person to a bidder group. Execute "put" in order to save the changes.
     */
    public async assignBidderGroupToBidderInEditMode(bidderGroupId: string): Promise<void> {
        if (!bidderGroupId) {
            return;
        }

        // Don't add duplicates
        if (this.recipientInEditMode.assignedBidderGroupIds.includes(bidderGroupId)) {
            this.toastService.info(`Gruppe '${this.getBidderGroupById(bidderGroupId).title}' bereits vorhanden`);
            return;
        }
        this.recipientInEditMode.assignedBidderGroupIds.push(bidderGroupId);
    }

    public async assignBidderGroupToSelectedBidders(bidderGroupId: string): Promise<void> {
        for (const bidder of this.getSelectedRecipients()) {
            if (!bidder.assignedBidderGroupIds) {
                bidder.assignedBidderGroupIds = [];
            }

            if (!bidder.assignedBidderGroupIds.includes(bidderGroupId)) {
                bidder.assignedBidderGroupIds.push(bidderGroupId);
                await this.contactPersonService.put(bidder);
            }
        }
    }

    public clearInputAndResetAutocomplete(input: HTMLInputElement): void {
        input.value = '';
    }

    /**
     * Change the selection change of all bidders that are assigned to the bidder group that has been toggled
     * @param searchTerm
     * @constructor
     */
    public selectBidderInGroup(searchTerm: string = '') {
        const inputValue = (searchTerm || '').trim().toLowerCase();
        this.filteredBidderGroupsIds = this.availableBidderGroups
            // Only suggest values matching the search term.
            .filter((group) => group.title.toLowerCase().includes(inputValue))
            // Don't suggest already selected auxiliary devices.
            .filter((group) => !this.recipientInEditMode.assignedBidderGroupIds?.includes(group._id))
            .sort(sortByProperty('title'));
    }

    public getBidderGroupById(groupId: string): ResidualValueBidderGroup {
        return this.availableBidderGroups.find((group) => group._id === groupId);
    }

    /**
     * Insert the bidder group from the autocomplete by its title into the chip list
     * @param event
     * @param inputElement
     * @param autocompleteTrigger
     */
    public selectBidderGroupFromAutocomplete(
        event: MatAutocompleteSelectedEvent,
        inputElement: HTMLInputElement,
        autocompleteTrigger: MatAutocompleteTrigger,
    ): void {
        this.assignBidderGroupToBidderInEditMode(event.option.value);
        this.clearInputAndResetAutocomplete(inputElement);
        setTimeout(() => {
            autocompleteTrigger.openPanel();
        }, 0);
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Bidder Groups
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Select Recipients
    //****************************************************************************/
    public toggleRecipient(contactPerson: ResidualValueBidder): void {
        // Shorthand
        const recipients = this.report.valuation.autoixpertResidualValueOffer.selectedRecipientIds;

        // Currently, not active -> activate
        const index: number = recipients.indexOf(contactPerson._id);
        if (index < 0) {
            if (!contactPerson.email) {
                this.toastService.warn('Kontakt hat keine E-Mailadresse');
            }
            recipients.push(contactPerson._id);
        }
        // Currently active -> remove
        else {
            recipients.splice(index, 1);
        }
        this.emitReportChange();
    }

    public rememberRecipients(): void {
        // Before we save a recipient ID whose referenced contact might have been deleted, clear all those.
        this.removeNonExistentRecipients();

        this.userPreferences.residualValueDefaultRecipients = [
            ...this.report.valuation.autoixpertResidualValueOffer.selectedRecipientIds,
        ];
        this.toastService.success('Empfänger gemerkt', 'Sie werden beim nächsten Inserat automatisch selektiert.');
    }

    public removeNonExistentRecipients(): void {
        const existingRecipients = this.filterOutMissingRecipients(
            this.report.valuation.autoixpertResidualValueOffer.selectedRecipientIds,
        );

        if (
            JSON.stringify(this.report.valuation.autoixpertResidualValueOffer.selectedRecipientIds) !==
            JSON.stringify(existingRecipients)
        ) {
            this.report.valuation.autoixpertResidualValueOffer.selectedRecipientIds = existingRecipients;
            this.emitReportChange();
        }
    }

    public filterOutMissingRecipients(recipientIds: string[]): string[] {
        return recipientIds.filter(
            (selectedRecipientId) =>
                !!this.availableRecipients.find((contactPerson) => contactPerson._id === selectedRecipientId),
        );
    }

    public getSelectedRecipients(): ResidualValueBidder[] {
        return this.availableRecipients.filter(
            (availableRecipient) =>
                !!this.report.valuation.autoixpertResidualValueOffer.selectedRecipientIds.find(
                    (selectedRecipientId) => availableRecipient._id === selectedRecipientId,
                ),
        );
    }

    /**
     * Shortcut to select or deselect all recipients.
     */
    public toggleSelectionOnAllRecipients(): void {
        const selectedRecipientIds = this.report.valuation.autoixpertResidualValueOffer.selectedRecipientIds;

        /**
         * If all recipients are currently selected, remove the selection on all of them.
         */
        if (selectedRecipientIds.length === this.availableRecipients.length) {
            selectedRecipientIds.length = 0;
            this.selectedBidderGroups.length = 0;
        } else {
            /**
             * If only some are selected, deselect those and select all.
             */
            // Clear before selecting all available recipients. That avoids duplicates.
            selectedRecipientIds.length = 0;
            for (const recipient of this.availableRecipients) {
                selectedRecipientIds.push(recipient._id);
                if (!recipient.assignedBidderGroupIds) continue;

                for (const bidderGroupId of recipient.assignedBidderGroupIds) {
                    if (!this.selectedBidderGroups.includes(this.getBidderGroupById(bidderGroupId))) {
                        this.selectedBidderGroups.push(this.getBidderGroupById(bidderGroupId));
                    }
                }
            }
        }
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Select Recipients
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Residual Value Request
    //****************************************************************************/
    private async createResidualValueRequest(): Promise<any> {
        // Since a residual value offer is an online-only feature, block this feature if offline.
        if (!this.networkStatusService.isOnline()) {
            this.toastService.offline(
                'Offline nicht verfügbar',
                'Lokale Restwertauktionen sind verfügbar, sobald du wieder online bist.',
            );
            return;
        }

        this.residualValueCreationPending = true;

        // In case it has not yet been set
        if (!this.report.valuation.autoixpertResidualValueOffer.bindingPeriodInDays) {
            this.report.valuation.autoixpertResidualValueOffer.bindingPeriodInDays =
                this.userPreferences.residualValueBindingPeriod;
        }

        if (!this.report.valuation.autoixpertResidualValueOffer.closingDate) {
            this.report.valuation.autoixpertResidualValueOffer.closingDate = this.residualValueTargetDate;
        }

        const infoToast = this.toastService.info('Inserat wird erstellt...', 'Dies kann einige Sekunden dauern.', {
            timeOut: 7000,
        });

        try {
            // First save the report, then trigger creating the residual value request
            await this.reportDetailsService.patch(this.report, { waitForServer: true });
            await this.residualValueRequestService.create(this.report);
        } catch (error) {
            this.residualValueCreationPending = false;

            // Reset the request into the "not-created" state
            this.report.valuation.autoixpertResidualValueOffer.closingDate = null;

            throw error;
        }

        this.toastService.remove(infoToast.id);
        this.toastService.success('Inserat erstellt', 'Eine Vorschau ist nun möglich.');
        this.residualValueCreationPending = false;
    }

    private handleResidualValueRequestCreationError(error) {
        this.apiErrorService.handleAndRethrow({
            axError: error,
            handlers: {
                RESIDUAL_VALUE_REQUEST_PHOTO_UPLOAD_FAILED: {
                    title: 'Foto-Upload gescheitert',
                    body: 'Das Restwert-Inserat konnte nicht angelegt werden. Bitte kontaktiere die <a href="/Hilfe" target="_blank">Hotline</a>. ',
                },
                DAT_DAMAGE_CALCULATION_DOCUMENT_MISSING: {
                    title: 'DAT-Kalkulation fehlt',
                    body: 'Das Dokument der Schadenskalkulation konnte nicht gefunden werden. Bitte importiere die Schadenskalkulation erneut.',
                },
                AUDATEX_DAMAGE_CALCULATION_DOCUMENT_MISSING: {
                    title: 'Audatex-Kalkulation fehlt',
                    body: 'Das Dokument der Schadenskalkulation konnte nicht gefunden werden. Bitte importiere die Schadenskalkulation erneut.',
                },
                SENDING_RESIDUAL_VALUE_INVITATION_FAILED: {
                    title: 'Einladungen nicht gesendet',
                    body: "Sind deine E-Mailzugangsdaten in den <a href='/Einstellungen#email-settings-container'>Einstellungen</a> sowie die Adressen deiner Empfänger korrekt?",
                },
            },
            defaultHandler: {
                title: 'Restwert-Anfrage nicht angelegt',
                body: 'Es scheint ein Fehler aufgetreten zu sein. Bitte kontaktiere die <a href="/Hilfe" target="_blank">Hotline</a>.',
            },
        });
    }

    public revokeResidualValueRequest(): void {
        this.emitDeleteResidualValueExchange();
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Residual Value Request
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Manage Invitations
    //****************************************************************************/
    public async sendInvitations(): Promise<void> {
        if (!this.isSendingRequestAllowed()) return;

        // Since a residual value offer is an online-only feature, block this feature if offline.
        if (!this.networkStatusService.isOnline()) {
            this.toastService.offline(
                'Offline nicht verfügbar',
                'Lokale Restwertauktionen sind verfügbar, sobald du wieder online bist.',
            );
            return;
        }
        if (moment(this.report.valuation.autoixpertResidualValueOffer.closingDate).isBefore()) {
            const decision = await this.dialog
                .open(ConfirmDialogComponent, {
                    data: {
                        heading: 'Gebotfrist abgelaufen',
                        content: 'Möchtest du trotzdem Einladungen an Bieter versenden?',
                        confirmLabel: "Egal, los geht's!",
                        cancelLabel: 'Lieber nicht.',
                    },
                })
                .afterClosed()
                .toPromise();
            if (!decision) {
                return;
            }
        }

        const selectedRecipients: ContactPerson[] = this.getSelectedRecipients();
        this.invitationsPending = new Map<ContactPerson, boolean>();

        for (const recipient of selectedRecipients) {
            // Register pending event for all invitees
            this.invitationsPending.set(recipient, true);

            // Remove selected recipients from invited list to allow re-sending emails
            const indexOfInvitationReceipt: number =
                this.report.valuation.autoixpertResidualValueOffer.invitationReceipts.findIndex(
                    (invitationReceipt) => invitationReceipt.contactPersonId === recipient._id,
                );
            if (indexOfInvitationReceipt > -1) {
                // Remove the invitation receipt from the server.
                await this.residualValueInvitationService.remove(
                    this.report._id,
                    this.report.valuation.autoixpertResidualValueOffer.invitationReceipts[indexOfInvitationReceipt]
                        .invitationId,
                );
                // Remove it locally too.
                this.report.valuation.autoixpertResidualValueOffer.invitationReceipts.splice(
                    indexOfInvitationReceipt,
                    1,
                );
            }
        }

        try {
            // Save the report, then trigger sending invitations
            await this.reportDetailsService.patch(this.report, { waitForServer: true });

            // If the residual value request does not yet exist, create it first. Pass along the original response in either case.
            if (!this.report.valuation.autoixpertResidualValueOffer.closingDate) {
                try {
                    await this.createResidualValueRequest();
                } catch (error) {
                    this.handleResidualValueRequestCreationError(error);
                    this.invitationsPending.clear();
                    return;
                }
            }

            // Start sending invitations
            const residualValueInvitationsCreateResponse: ResidualValueInvitationsCreateResponse =
                await this.residualValueInvitationService.sendInvitations(this.report._id);

            this.report.valuation.autoixpertResidualValueOffer.invitationReceipts.push(
                ...residualValueInvitationsCreateResponse.newInvitationReceipts,
            );

            const residualValueRecipientToSmtpError = new Map<ResidualValueInvitationReceipt['_id'], AxError>();
            if (residualValueInvitationsCreateResponse.smtpErrors?.length) {
                for (const smtpError of residualValueInvitationsCreateResponse.smtpErrors) {
                    this.apiErrorService.handleAndRethrow({
                        axError: smtpError,
                        handlers: {
                            SENDING_RESIDUAL_VALUE_INVITATION_EMAIL_FAILED: (error) => {
                                residualValueRecipientToSmtpError.set(error.data?.recipientId, error);

                                const recipientFullName = getContactPersonFullNameWithOrganization(
                                    {
                                        firstName: error.data?.recipientFirstName,
                                        lastName: error.data?.recipientLastName,
                                        organization: error.data?.recipientOrganization,
                                    },
                                    ' - ',
                                );

                                return {
                                    title: `Versand fehlgeschlagen`,
                                    body: `${recipientFullName} (${error.data?.recipientEmail}) konnte die E-Mail nicht empfangen oder dein Mail-Server konnte sie nicht an ihn verschicken.<br><br>Bitte sprich mit deinem für deinen E-Mail-Account zuständigen IT-Fachmann.`,
                                };
                            },
                        },
                        defaultHandler: {
                            title: 'Einladung nicht versendet',
                            body: `Bitte kontaktiere die <a href="/Hilfe" target="_blank">Hotline</a>.`,
                        },
                    });
                }
            }

            selectedRecipients.forEach((recipient) => {
                // Remove pending status
                this.invitationsPending.delete(recipient);

                // Remove selection for all recipients to which an email could be sent.
                if (!residualValueRecipientToSmtpError.has(recipient._id)) {
                    removeFromArray(
                        recipient._id,
                        this.report.valuation.autoixpertResidualValueOffer.selectedRecipientIds,
                    );
                }
            });

            this.emitReportChange();

            this.toastService.success('Bieter eingeladen', 'Eingeladene können nun ihr Gebot abgeben.');
            this.tutorialStateService.markUserTutorialStepComplete('residualValueRequestCreated');

            this.emailJustSent = true;
            setTimeout(() => {
                this.emailJustSent = false;
            }, 2000);
        } catch (error) {
            selectedRecipients.forEach((recipient) => {
                // Remove pending status
                this.invitationsPending.delete(recipient);
            });

            this.apiErrorService.handleAndRethrow({
                axError: error,
                defaultHandler: {
                    title: 'Einladungen nicht versendet',
                    body: `Bitte kontaktiere die <a href="/Hilfe" target="_blank">Hotline</a>.`,
                },
            });
        }
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Manage Invitations
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Offer Preview
    //****************************************************************************/
    public async openResidualValueOfferPreview(): Promise<void> {
        if (this.residualValueCreationPending) return;

        this.previewPending = true;

        // If the residual value request does not yet exist, create it first
        if (!this.report.valuation.autoixpertResidualValueOffer.closingDate) {
            try {
                await this.createResidualValueRequest();
            } catch (error) {
                this.previewPending = false;
                this.handleResidualValueRequestCreationError(error);
                return;
            }
        }
        this.previewPending = false;
        const window: Window = this.residualValueRequestService.openResidualValueOfferPreview(this.report._id);

        if (!window) {
            // Don't hide the message until the user clicks it. Give the user time to read and act.
            this.toastService.warn(
                'Bitte Popups zulassen',
                'Das Öffnen der Vorschau wurde blockiert. Bitte erlaube autoiXpert, in deinem Browser Popups zu öffnen.',
                { timeOut: 0 },
            );
        }
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Offer Preview
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  View Helpers
    //****************************************************************************/
    public getFullName = getFullNameWithSalutation;

    public getAssociatedInvitationReceipt(contactPerson: ContactPerson): IResidualValueInvitationReceipt {
        return this.report.valuation.autoixpertResidualValueOffer.invitationReceipts.find(
            (invitationReceipt) => invitationReceipt.contactPersonId === contactPerson._id,
        );
    }

    public getAssociatedBid(contactPerson: ContactPerson): ResidualValueBid {
        return this.report.valuation.customResidualValueBids.find(
            (customBid) => customBid.bidder.contactPersonId === contactPerson._id,
        );
    }

    public determineInvitationState(
        contactPerson: ContactPerson,
    ): 'pending' | 'received' | 'opened' | 'bidEntered' | 'notInvited' | 'notInterested' {
        const invitationReceipt: IResidualValueInvitationReceipt = this.getAssociatedInvitationReceipt(contactPerson);
        const bid: ResidualValueBid = this.getAssociatedBid(contactPerson);

        if (!invitationReceipt) {
            if (this.invitationsPending.has(contactPerson)) {
                return 'pending';
            }
            return 'notInvited';
        }

        if (bid) {
            if (bid.bidValue.value === null) {
                return 'notInterested';
            } else {
                return 'bidEntered';
            }
        }

        if (invitationReceipt.openedResidualValueRequestAt) {
            return 'opened';
        }
        return 'received';
    }

    public isSendingRequestAllowed(): boolean {
        if (!this.report.valuation.autoixpertResidualValueOffer.bindingPeriodInDays) return false;

        return this.report.valuation.autoixpertResidualValueOffer.selectedRecipientIds.length !== 0;
    }

    public getSendButtonTooltip(): string {
        if (this.isSendingRequestAllowed()) return '';

        const warnings: string[] = [];

        if (!this.report.valuation.autoixpertResidualValueOffer.bindingPeriodInDays) {
            warnings.push('Bitte gib eine Bindefrist ein.');
        }
        if (this.report.valuation.autoixpertResidualValueOffer.selectedRecipientIds.length === 0) {
            warnings.push('Bitte wähle mindestens einen Empfänger.');
        }

        return warnings.join(' ');
    }

    public isReportLocked(): boolean {
        return this.report.state === 'done';
    }
    /////////////////////////////////////////////////////////////////////////////*/
    //  END View Helpers
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  User Preferences
    //****************************************************************************/

    public toggleHideResidualValueBidderGroupsExplanation() {
        this.user.userInterfaceStates.hideResidualValueBidderGroupsExplanation =
            !this.user.userInterfaceStates.hideResidualValueBidderGroupsExplanation;
        this.saveUser();
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END User Preferences
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Events
    //****************************************************************************/
    public emitCloseEvent(): void {
        this.close.emit();
    }

    public emitDeleteResidualValueExchange(): void {
        this.deleteResidualValueExchange.emit();
        this.emitCloseEvent();
    }

    public emitReportChange(): void {
        this.reportChange.emit(this.report);
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Events
    /////////////////////////////////////////////////////////////////////////////*/

    public handleOverlayClick(event: MouseEvent): void {
        if (event.target === event.currentTarget) {
            this.emitCloseEvent();
        }
    }

    protected async saveUser(): Promise<void> {
        try {
            await this.userService.put(this.user);
        } catch (error) {
            this.apiErrorService.handleAndRethrow({
                axError: error,
                handlers: {},
                defaultHandler: {
                    title: 'Benutzer nicht gespeichert',
                    body: "Bitte kontaktiere die <a href='/Hilfe'>Hotline</a>.",
                },
            });
        }
    }

    //*****************************************************************************
    //  Keyboard Shortcuts
    //****************************************************************************/
    @HostListener('window:keydown', ['$event'])
    public async handleKeyboardShortcuts(event: KeyboardEvent) {
        switch (event.key) {
            case 'Escape':
                // Don't close the entire dialog while the group title dialog is open.
                if (this.groupTitleDialogOpen) return;

                this.emitCloseEvent();
                break;
            case 'Enter':
                if (event.ctrlKey || event.metaKey) {
                    // Save recipient being edited
                    if (this.recipientInEditMode) {
                        await this.saveRecipientInEditMode();
                    }
                    // If there is no recipient being edited, confirm the dialog.
                    else {
                        await this.sendInvitations();
                    }
                }
        }
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Keyboard Shortcuts
    /////////////////////////////////////////////////////////////////////////////*/
}

import { ApiErrorHandler, ApiErrorHandlerCreator } from '../../services/api-error.service';

export function getOfflineErrorHandlers(): { [key: string]: ApiErrorHandler | ApiErrorHandlerCreator } {
    return {
        CLIENT_IS_OFFLINE: {
            title: 'Offline nicht verfügbar',
            body: `Stelle eine Internetverbindung her, um diese Funktion zu verwenden.`,
            toastType: 'offline',
        },
        AUTOIXPERT_BACKEND_SERVERS_ARE_DOWN: {
            title: 'autoiXpert-Server nicht verfügbar',
            body: `Grundlegende Eingaben sind weiterhin ohne Server-Verbindung möglich.<br><br>Sobald die Server wieder erreichbar sind, werden deine Eingaben synchronisiert und du kannst wieder alle Funktionen nutzen.`,
            toastType: 'offline',
        },
    };
}

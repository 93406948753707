import { AxError } from '../../models/errors/ax-error';

export const placeholderToRegularExpressionDictionary: { [key in string]: RegExp } = {
    TT: /\d{2}/,
    MM: /\d{2}/,
    JJJJ: /\d{4}/,
    JJ: /\d{2}/,
    TYP: /.+/,
    INI: /.+/,
    STO: /.+/,
};

export function convertCounterPatternToRegularExpression({
    pattern,
}: {
    // Example: GA-{TYP}-{JJJJ}-{MM}-{###}
    pattern: string;
}) {
    if (!pattern) {
        throw new AxError({
            code: 'REPORT_TOKEN_OR_INVOICE_NUMBER_PATTERN_MISSING',
            message: `The pattern which is required for extracting the counter from the report token or invoice number is missing.`,
            data: {
                pattern,
            },
        });
    }

    const placeholdersWithoutBrackets: string[] = [];
    const regularExpressionString = pattern.replace(/{[^{}]+}/g, (placeholder) => {
        const placeholderWithoutBrackets: string = placeholder.replace('{', '').replace('}', '');
        let regularExpression: string;

        if (placeholderToRegularExpressionDictionary[placeholderWithoutBrackets]) {
            placeholdersWithoutBrackets.push(placeholderWithoutBrackets);
            regularExpression = placeholderToRegularExpressionDictionary[placeholderWithoutBrackets]
                .toString()
                /**
                 * /\d{2}/ --> \d{2}
                 *
                 * RegExp.toString() returns the regular expression with slashes around it. Remove the slashes since
                 * the resulting string contains concatenated regular expressions that should not each have slashes
                 * around them.
                 */
                .replace(/^\/(.+)\/$/, '$1');
        } else if (/{#+}/.test(placeholder)) {
            /**
             * Use "COUNTER" instead of "###" to enable sharing counters between patterns with a different number of hashtags.
             */
            placeholdersWithoutBrackets.push('COUNTER');
            /**
             * The number of hashtags may vary, so the user may decide on the counter length. That makes it a little harder to replace
             * than the fixed-length placeholders above.
             */
            const numberOfHashtags: number = placeholderWithoutBrackets.length;
            regularExpression = `\\d{${numberOfHashtags}}`;
        } else {
            // Regular placeholders (can contain literally anything)
            placeholdersWithoutBrackets.push(placeholderWithoutBrackets);
            regularExpression = `.*`;
        }
        // Return the regular expression as a group so that its matches can be handled individually.
        return `(${regularExpression})`;
    });

    return {
        placeholdersWithoutBrackets,
        regularExpression: new RegExp(regularExpressionString),
    };
}

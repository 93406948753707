<div id="contact-list-container" class="card">
    <!--============================================-->
    <!-- Header -->
    <!--============================================-->
    <header>
        <!--============================================-->
        <!-- Sort & Filter -->
        <!--============================================-->
        <div id="sort-and-filter-container">
            <div id="sort-select-and-direction-container">
                <div
                    id="sort-contacts-by-container"
                    class="ax-header-select"
                    matTooltip="Sortierung"
                    [matMenuTriggerFor]="sortMenu"
                    [ngSwitch]="userPreferences.sortContactListBy">
                    <span *ngSwitchCase="'lastName'">Nach Nachname</span>
                    <span *ngSwitchCase="'organization'">Nach Firma</span>
                    <div class="ax-header-select-arrow"></div>
                </div>
                <mat-menu #sortMenu="matMenu">
                    <a mat-menu-item (click)="selectSortStrategy('lastName')">Nach Nachname</a>
                    <a mat-menu-item (click)="selectSortStrategy('organization')">Nach Firma</a>
                </mat-menu>
                <mat-icon
                    id="sort-direction-indicator"
                    class="toolbar-icon"
                    [ngClass]="{ flipped: userPreferences.sortContactListDescending }"
                    (click)="toggleSortDirection()"
                    [matTooltip]="
                        userPreferences.sortContactListDescending ? 'Sortierung absteigend' : 'Sortierung aufsteigend'
                    ">
                    arrow_upward
                </mat-icon>
            </div>
            <div
                id="quick-filter-select-container"
                class="ax-header-select"
                matTooltip="Schnellfilter"
                [matMenuTriggerFor]="quickFilterMenu"
                [ngSwitch]="userPreferences.contactListQuickFilter">
                <span *ngSwitchDefault>Kein Filter</span>
                <span *ngSwitchCase="'claimant'">Anspruchsteller</span>
                <span *ngSwitchCase="'garage'">Werkstätten</span>
                <span *ngSwitchCase="'lawyer'">Rechtsanwälte</span>
                <span *ngSwitchCase="'insurance'">Versicherung</span>
                <span *ngSwitchCase="'bidder'">Restwertbieter</span>
                <span *ngSwitchCase="'residualValueRequestRecipient'">Empfänger Restwertinserate</span>
                <span *ngSwitchCase="'visitLocationFavorite'">Besichtigungsfavoriten</span>
                <span *ngSwitchCase="'intermediary'">Vermittler</span>
                <span *ngSwitchCase="'leaseProvider'">Leasinggeber</span>
                <div class="ax-header-select-arrow"></div>
            </div>
            <mat-menu #quickFilterMenu="matMenu">
                <a mat-menu-item (click)="selectQuickFilter(null)">Kein Filter</a>
                <a mat-menu-item (click)="selectQuickFilter('claimant')">Anspruchsteller</a>
                <a mat-menu-item (click)="selectQuickFilter('garage')">Werkstätten</a>
                <a mat-menu-item (click)="selectQuickFilter('lawyer')">Rechtsanwälte</a>
                <a mat-menu-item (click)="selectQuickFilter('insurance')">Versicherung</a>
                <a mat-menu-item (click)="selectQuickFilter('bidder')">Restwertbieter</a>
                <a mat-menu-item (click)="selectQuickFilter('residualValueRequestRecipient')">
                    Empfänger Restwertinserate
                </a>
                <a mat-menu-item (click)="selectQuickFilter('visitLocationFavorite')">Besichtigungsfavoriten</a>
                <a mat-menu-item (click)="selectQuickFilter('intermediary')">Vermittler</a>
                <a mat-menu-item (click)="selectQuickFilter('leaseProvider')">Leasinggeber</a>
            </mat-menu>
        </div>
        <!--============================================-->
        <!-- END Sort & Filter -->
        <!--============================================-->

        <!--============================================-->
        <!-- Search Field -->
        <!--============================================-->
        <div id="contact-search-field-container">
            <mat-form-field id="contacts-search-field" class="search-field mat-block" floatLabel="never">
                <input
                    matInput
                    [(ngModel)]="searchTerm"
                    placeholder="Suchen"
                    (ngModelChange)="filterContacts(); updateSearchTerm$()"
                    [matTooltip]="
                        'Diese Daten werden durchsucht: \n• Vorname, Nachname, Organisation\n• E-Mail\n• Telefon\n• Straße\n• Stadt\n• PLZ\n• Werkstatt-Marken\n• Werkstatt-Eigenschaften\n\nSuchworte müssen mindestens drei Zeichen lang sein.'
                    " />
                <mat-icon matPrefix>search</mat-icon>
            </mat-form-field>
        </div>
        <!--============================================-->
        <!-- END Search Field -->
        <!--============================================-->

        <!--============================================-->
        <!-- Head Runner Icons -->
        <!--============================================-->
        <div id="contact-list-head-runner-icons-container">
            <!--********** New Contact Person **********-->
            <div class="new-contact-person-button" (click)="editNewContactPerson()" matTooltip="Neuer Kontakt (N)">
                <mat-icon id="new-contact-person-button-icon" class="small-icon">add</mat-icon>
                <span id="new-contact-person-button-label">Kontakt</span>
            </div>

            <mat-icon class="toolbar-icon" (click)="openImportContactsDialog()" matTooltip="Kontakte importieren">
                publish
            </mat-icon>
            <img class="toolbar-icon" src="/assets/images/icons/loading-indicator.svg" *ngIf="contactExportPending" />
            <mat-icon class="toolbar-icon" [mat-menu-trigger-for]="contactListSubmenu">more_vert</mat-icon>
            <mat-menu #contactListSubmenu>
                <a
                    mat-menu-item
                    class="menu-item-with-icon"
                    (click)="forceReloadAllContactPeople()"
                    matTooltip="Falls Kontakte seit dem letzten Laden der Liste neu angelegt wurden, kann die Liste hiermit aktualisiert werden.">
                    <mat-icon>refresh</mat-icon>
                    Alle Kontakte neu laden
                </a>
                <a
                    mat-menu-item
                    class="menu-item-with-icon"
                    (click)="downloadListOfContactPeople()"
                    matTooltip="Kontakte exportieren">
                    <mat-icon>file_download</mat-icon>
                    Kontakte exportieren
                </a>
            </mat-menu>
        </div>
        <!--============================================-->
        <!-- END Head Runner Icons -->
        <!--============================================-->
    </header>
    <!--============================================-->
    <!-- END Header -->
    <!--============================================-->

    <!-------------- No data to display -->
    <div *ngIf="contactPeople.length === 0 && !isLoadMoreContactsPending" id="no-contacts-to-display-container">
        <p>
            <strong>
                Hi
                <span *ngIf="user.firstName">{{ user.firstName }}</span>
                !
            </strong>
        </p>
        <p>Hier ist viel Platz für deine Kontakte.</p>
        <img
            id="no-contacts-to-display-icon"
            src="/assets/images/icons/no-data-to-display.png"
            (click)="openImportContactsDialog()" />
        <!-- Prevent prettier from adding a space between "Gutachten abschließt" and the period. -->
        <!-- prettier-ignore -->
        <p>
            Tipp: Alle Beteiligten werden hier gespeichert,
            <br />
            sobald du ein
            <a
                href="https://wissen.autoixpert.de/hc/de/articles/360015874751-Gutachten-abschlie%C3%9Fen"
                target="_blank"
                rel="noopener">
                Gutachten abschließt
            </a>.
        </p>
        <div id="no-contacts-to-display-buttons">
            <button (click)="openImportContactsDialog()">Kontakte importieren</button>
            <button class="secondary" (click)="editNewContactPerson()" matTooltip="Neuen Kontakt manuell anlegen">
                Neuer Kontakt
            </button>
        </div>
    </div>
    <!-------------- ENDE No data to display -->

    <!--============================================-->
    <!-- Data hidden by filters -->
    <!--============================================-->
    <div id="no-contacts-with-filter" *ngIf="contactPeople.length && !filteredContactPeople.length">
        <h3>Keine Kontakte sichtbar</h3>
        <p>Möglicherweise sind Kontakte durch Filter ausgeblendet</p>
    </div>
    <!--============================================-->
    <!-- END Data hidden by filters -->
    <!--============================================-->

    <!--***********************************************
    ** Sync in Progress
    ************************************************-->
    <div id="no-contact-visible-server-search-pending" *ngIf="contactPeople.length === 0 && isLoadMoreContactsPending">
        <img src="/assets/images/icons/loading-indicator.svg" alt="" />
        Durchsuche Server...
    </div>
    <!--***********************************************
    ** END Sync in Progress
    ************************************************-->

    <!--============================================-->
    <!-- Contact List -->
    <!--============================================-->
    <table id="contact-list">
        <tr
            class="record"
            *ngFor="let contactPerson of filteredContactPeople; trackBy: trackById"
            (click)="selectContactPerson(contactPerson)"
            (dblclick)="editContactPerson(contactPerson)"
            [ngClass]="{ active: selectedContactPerson === contactPerson }">
            <td class="contact-type-column" (click)="isMobile() && editContactPerson(contactPerson)">
                <div class="contact-type" [ngSwitch]="contactPerson.organizationType">
                    <img
                        class="contact-type-icon"
                        *ngSwitchCase="'claimant'"
                        src="/assets/images/icons/claimant-blue.svg"
                        matTooltip="Anspruchsteller" />
                    <img
                        class="contact-type-icon"
                        *ngSwitchCase="'garage'"
                        src="/assets/images/icons/garage-blue.svg"
                        matTooltip="Werkstatt" />
                    <img
                        class="contact-type-icon"
                        *ngSwitchCase="'lawyer'"
                        src="/assets/images/icons/lawyer-blue.svg"
                        matTooltip="Anwalt" />
                    <img
                        class="contact-type-icon"
                        *ngSwitchCase="'insurance'"
                        src="/assets/images/icons/insurance-blue.svg"
                        matTooltip="Versicherung" />
                    <mat-icon
                        class="contact-type-icon icon-emphasized"
                        *ngSwitchCase="'bidder'"
                        matTooltip="Restwertbieter">
                        gavel
                    </mat-icon>
                    <mat-icon
                        class="contact-type-icon icon-emphasized"
                        *ngSwitchCase="'residualValueRequestRecipient'"
                        matTooltip="Empfänger Restwertinserate">
                        gavel
                    </mat-icon>
                    <mat-icon
                        class="contact-type-icon"
                        *ngSwitchCase="'visitLocationFavorite'"
                        matTooltip="Besichtigungsfavorit">
                        star
                    </mat-icon>
                    <mat-icon class="contact-type-icon" *ngSwitchCase="'intermediary'" matTooltip="Vermittler">
                        record_voice_over
                    </mat-icon>
                    <mat-icon class="contact-type-icon" *ngSwitchCase="'leaseProvider'" matTooltip="Leasinggeber">
                        account_balance
                    </mat-icon>

                    <div class="sync-issue-container" *ngIf="hasSyncIssues(contactPerson._id)" (click)="triggerSync()">
                        <mat-icon [matTooltip]="getSyncIssueTooltip()">sync_problem</mat-icon>
                    </div>
                </div>
            </td>
            <td class="contact-name-column" (click)="isMobile() && editContactPerson(contactPerson)">
                <div
                    class="contact-name-container"
                    [ngClass]="{
                        'sort-strategy-last-name': userPreferences.sortContactListBy === 'lastName',
                        'sort-strategy-organization': userPreferences.sortContactListBy === 'organization',
                    }">
                    <div
                        class="contact-organization"
                        [class.is-company]="!contactPerson.firstName && !contactPerson.lastName">
                        {{ contactPerson.organization || 'Keine Firma' }}
                    </div>

                    <div
                        class="contact-last-name-first-name semibold"
                        *ngIf="contactPerson.firstName || contactPerson.lastName">
                        {{ contactPerson.lastName }}
                        <span *ngIf="contactPerson.lastName && contactPerson.firstName">,</span>
                        {{ contactPerson.firstName }}
                    </div>
                </div>
            </td>

            <!--********** Phone **********-->
            <td class="contact-phone-column">
                <div *ngIf="contactPerson.phone">
                    <a href="tel:{{ contactPerson.phone }}" target="_blank" rel="noopener" matTooltip="Telefon">
                        {{ contactPerson.phone }}
                    </a>
                </div>
            </td>

            <!--********** Phone 2 **********-->
            <td class="contact-phone-column">
                <div *ngIf="contactPerson.phone2">
                    <a href="tel:{{ contactPerson.phone2 }}" target="_blank" rel="noopener" matTooltip="Telefon 2">
                        {{ contactPerson.phone2 }}
                    </a>
                </div>
            </td>

            <td class="contact-phone-on-mobile-devices" *ngIf="isMobile()">
                <mat-icon
                    class="toolbar-icon"
                    *ngIf="contactPerson.phone || contactPerson.phone2"
                    (click)="callOrSelectPhoneNumber(contactPerson, phoneOnMobileDevicesSubmenuTrigger)"
                    [matMenuTriggerFor]="phoneOnMobileDevicesSubmenu"
                    #phoneOnMobileDevicesSubmenuTrigger="matMenuTrigger">
                    phone
                </mat-icon>
                <mat-menu #phoneOnMobileDevicesSubmenu>
                    <a mat-menu-item *ngIf="contactPerson.phone" href="tel:{{ contactPerson.phone }}">
                        {{ contactPerson.phone }}
                    </a>
                    <a mat-menu-item *ngIf="contactPerson.phone2" href="tel:{{ contactPerson.phone2 }}">
                        {{ contactPerson.phone2 }}
                    </a>
                </mat-menu>
            </td>

            <!--********** E-Mail **********-->
            <td class="contact-email-column">
                <div *ngIf="contactPerson.email">
                    <a href="mailto:{{ contactPerson.email }}" target="_blank" rel="noopener" matTooltip="E-Mail">
                        {{ contactPerson.email }}
                    </a>
                </div>
            </td>

            <!--********** Address **********-->
            <td class="contact-address-column">
                <div
                    class="contact-address-container"
                    *ngIf="contactPerson.streetAndHouseNumberOrLockbox || contactPerson.zip || contactPerson.city">
                    <mat-icon class="medium-icon">place</mat-icon>
                    <div>
                        <a
                            matTooltip="Anschrift in Google Maps öffnen"
                            [href]="
                                'https://www.google.com/maps?q=' +
                                (contactPerson.streetAndHouseNumberOrLockbox || '') +
                                '+' +
                                (contactPerson.zip || '') +
                                '+' +
                                (contactPerson.city || '')
                            "
                            target="_blank"
                            rel="noopener">
                            {{ contactPerson.streetAndHouseNumberOrLockbox }}
                            <br
                                *ngIf="
                                    contactPerson.streetAndHouseNumberOrLockbox &&
                                    (contactPerson.zip || contactPerson.city)
                                " />
                            {{ contactPerson.zip }} {{ contactPerson.city }}
                        </a>
                    </div>
                </div>
            </td>

            <!--********** Toolbar **********-->
            <td class="contact-record-toolbar">
                <div class="contact-record-toolbar-icon-container" *ngIf="contactPerson.organizationType === 'garage'">
                    <mat-icon
                        class="contact-record-toolbar-icon"
                        matTooltip="Kostensätze bearbeiten"
                        (click)="editGarageFees(contactPerson)">
                        euro_symbol
                    </mat-icon>
                </div>
            </td>

            <td class="contact-submenu">
                <mat-icon class="contact-submenu-icon" [matMenuTriggerFor]="contactOptionsMenu">more_vert</mat-icon>

                <mat-menu #contactOptionsMenu="matMenu" xPosition="before">
                    <a mat-menu-item class="menu-item-with-icon" (click)="editContactPerson(contactPerson)">
                        <mat-icon>edit</mat-icon>
                        Bearbeiten
                    </a>
                    <a mat-menu-item class="menu-item-with-icon" (click)="copyContactPerson(contactPerson)">
                        <mat-icon>content_copy</mat-icon>
                        Kopieren
                    </a>
                    <a mat-menu-item class="menu-item-with-icon" (click)="deleteContactPerson(contactPerson)">
                        <mat-icon>delete</mat-icon>
                        Löschen
                    </a>
                </mat-menu>
            </td>
        </tr>
    </table>
    <div id="pending-icon-container" *ngIf="isLoadMoreContactsPending">
        <img class="pending-icon" src="/assets/images/icons/loading-indicator.svg" alt="Loading Indicator" />
    </div>
    <div
        (intersectsViewport)="triggerLoadMoreContacts()"
        *ngIf="filteredContactPeople.length > 0 && !isLoadMoreContactsPending && !recordsLimitReached && !searchTerm">
        <span>Mehr Kontakte laden</span>
    </div>
    <!--============================================-->
    <!-- END Contact List -->
    <!--============================================-->
</div>
<div id="load-more-contacts-container">
    <!--============================================-->
    <!-- Load More Button -->
    <!--============================================-->
    <div id="load-more-contacts-button-container">
        <button
            class="show-more-button"
            (click)="triggerLoadMoreContacts()"
            [ngClass]="{ disabled: recordsLimitReached }">
            <img
                class="pending-icon"
                src="/assets/images/icons/loading-indicator.svg"
                *ngIf="isLoadMoreContactsPending" />
            Mehr laden
        </button>
    </div>
    <!--============================================-->
    <!-- END Load More Button -->
    <!--============================================-->

    <div id="number-of-visible-contacts-container" matTooltip="Sichtbare Kontakte">
        <h4 *ngIf="!recordsLimitReached">
            {{ filteredContactPeople.length }} Kontakte
            <img src="/assets/images/icons/loading-indicator.svg" *ngIf="isLoadMoreContactsPending" />
        </h4>
        <h4 *ngIf="recordsLimitReached">Alle {{ filteredContactPeople.length }} Kontakte geladen</h4>
    </div>
</div>

<!--============================================-->
<!-- Contact Person Editor -->
<!--============================================-->
<!--**********
VAT fields
The VAT fields are displayed for claimants only to reduce the visual load for the user. Usually, claimants are the ones
receiving an invoice for which a VAT ID may need to be added. Lawyers, garages etc. only rarely receive an invoice from
the assessor.
In case, however, the user has already entered a value for mayDeductTaxes e.g. through editing the contact person through
an invoice recipient form, the VAT fields are displayed as well.
**********-->
<contact-person-editor
    [contactPerson]="contactPersonToBeEdited"
    [showOrganizationTypeSelection]="true"
    *ngIf="contactPersonEditorShown"
    [vatFieldsAllowed]="
        contactPersonToBeEdited?.organizationType === 'claimant' ||
        contactPersonToBeEdited?.mayDeductTaxes !== undefined
    "
    [showDebtorNumber]="
        contactPersonToBeEdited?.organizationType === 'claimant' ||
        contactPersonToBeEdited?.mayDeductTaxes !== undefined
    "
    (contactPersonChange)="handleContactPersonChange($event)"
    (close)="hideContactPersonEditor()"></contact-person-editor>
<!--============================================-->
<!-- END Contact Person Editor -->
<!--============================================-->

<!--============================================-->
<!-- Garage Fees -->
<!--============================================-->
<garage-fees
    [garageContact]="contactPersonToBeEdited"
    *ngIf="garageFeesEditorShown"
    (contactPersonChange)="handleContactPersonChange($event)"
    (close)="hideGarageFeesEditor()"></garage-fees>
<!--============================================-->
<!-- END Garage Fees -->
<!--============================================-->

<!--============================================-->
<!-- Contact Import Dialog -->
<!--============================================-->
<contact-import-dialog
    *ngIf="importContactsDialogShown"
    (close)="hideContactImportDialog()"
    (uploadSuccessful)="forceReloadAllContactPeople()"
    (rewindSuccessful)="forceReloadAllContactPeople()"></contact-import-dialog>
<!--============================================-->
<!-- END Contact Import Dialog -->
<!--============================================-->

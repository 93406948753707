import { SecurityContext } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

export function sanitizeHtmlExceptStyle(html: string, domSanitizer: DomSanitizer): string {
    /**
     * Angular's DomSanitizer.sanitize() currently removes all inline styles since there were ways to trigger JavaScript through a style attribute in old browsers. Modern
     * browsers apparently prevent this. Example:
     * <div style="background-image: url(alert('0wn3d'))"></div>
     *
     * There is an Angular GitHub issue asking for filtering dangerous inline styles while keeping all non-dangerous ones: https://github.com/angular/angular/issues/19645
     *
     * In the meantime, we allow the style attribute by replacing it with an aria attribute that Angular does not filter. Before injecting the value into the DOM, we
     * then replace everything back.
     */
    let styleWasReplaced: boolean = false;
    if (html?.includes('style="')) {
        html = html.replace(/style="/g, 'aria-activedescendant="');
        styleWasReplaced = true;
    }
    let sanitizedValue: string = domSanitizer.sanitize(SecurityContext.HTML, html);

    if (styleWasReplaced) {
        sanitizedValue = sanitizedValue.replace(/aria-activedescendant="/g, 'style="');
    }

    return sanitizedValue;
}

<div class="grade-container" [ngClass]="{ bold: category === 'total' }" (click)="openGradeSelectorDialog($event)">
    {{ getSchoolgrade() }}
</div>

<div class="dialog-overlay" *ngIf="gradeSelectorDialogShown" (click)="handleOverlayClick($event)">
    <div class="dialog-container" (click)="handleOverlayClick($event)">
        <h2 id="grade-selector-heading">
            {{ heading }}
            <span
                class="help-indicator"
                *ngIf="gradeDescriptions.length"
                (click)="openGradeInfo()"
                matTooltip="Für Tipps zur Benotung klicken">
                ?
            </span>
        </h2>
        <!--============================================-->
        <!-- Grades -->
        <!--============================================-->
        <div id="grade-buttons-container" @staggeredFadeIn>
            <div class="grade-buttons-column" *ngFor="let gradeBase of ['1', '2', '3', '4', '5']">
                <div
                    class="grade-button grade-button-small"
                    (click)="setSchoolGrade(gradeBase + '+')"
                    [ngClass]="{ active: getSchoolgrade() === gradeBase + '+', disabled: gradeBase === '1' }"
                    [matTooltip]="
                        gradeBase !== '1' ? 'Wähle Note ' + gradeBase + '+' : 'Die Notenskala enthält keine 1+'
                    ">
                    {{ gradeBase }}+
                </div>
                <div
                    class="grade-button grade-button-main"
                    (click)="setSchoolGrade(gradeBase)"
                    [ngClass]="{ active: getSchoolgrade() === gradeBase }"
                    [matTooltip]="'Wähle Note ' + gradeBase">
                    {{ gradeBase }}
                </div>
                <div
                    class="grade-button grade-button-small"
                    (click)="setSchoolGrade(gradeBase + '-')"
                    [ngClass]="{ active: getSchoolgrade() === gradeBase + '-', disabled: gradeBase === '5' }"
                    [matTooltip]="
                        gradeBase !== '5' ? 'Wähle Note ' + gradeBase + '-' : 'Die Notenskala enthält keine 5-'
                    ">
                    {{ gradeBase }}-
                </div>
            </div>

            <!--********** No Grade **********-->
            <div class="grade-buttons-column" *ngIf="true">
                <div
                    class="grade-button grade-button-main"
                    (click)="setSchoolGrade(null)"
                    [ngClass]="{ active: getSchoolgrade() === '-' }"
                    matTooltip="Diese Kategorie nicht im Gutachten abdrucken">
                    -
                </div>
            </div>
        </div>
        <!--============================================-->
        <!-- END Grades -->
        <!--============================================-->

        <!--============================================-->
        <!-- Grade Descriptions -->
        <!--============================================-->
        <section id="grade-description-section" *ngIf="gradeDescriptions.length">
            <!--********** Info **********-->
            <div id="grade-description" class="card card-with-padding" *ngIf="gradeInfoShown">
                <mat-icon class="dialog-close-icon" (click)="hideGradeInfo()">close</mat-icon>

                <h2 id="grade-description-heading">{{ heading }}</h2>

                <div
                    class="grade-description-row"
                    *ngFor="let gradeDescription of gradeDescriptions; let index = index">
                    <div class="grade-description-number">{{ index + 1 }}</div>
                    <div class="grade-description-text">{{ gradeDescription }}</div>
                </div>
            </div>
        </section>
        <!--============================================-->
        <!-- END Grade Descriptions -->
        <!--============================================-->
    </div>
</div>

import { OverlayRef } from '@angular/cdk/overlay';
import { Component, EventEmitter, HostListener, Input, Output } from '@angular/core';
import { InvoiceNumberConfig, LeadingCounterType } from '@autoixpert/models/teams/invoice-or-report-counter-config';
import { InvoiceNumberJournalEntryService } from 'src/app/shared/services/invoice-number-journal-entry.service';

@Component({
    selector: 'select-leading-counter-dialog',
    templateUrl: './select-leading-counter-dialog.component.html',
    styleUrls: ['./select-leading-counter-dialog.component.scss'],
})
export class SelectLeadingCounterDialogComponent {
    @Input() counterId: InvoiceNumberConfig['_id'];
    @Output() selectionChanged: EventEmitter<{
        leadingCounter: LeadingCounterType;
        counterId: InvoiceNumberConfig['_id'];
    }> = new EventEmitter();

    @Input() selectedCounterType: LeadingCounterType = 'reportToken';

    constructor(
        private overlayRef: OverlayRef,
        private invoiceNumberJournalEntryService: InvoiceNumberJournalEntryService,
    ) {}

    protected selectCounterType(counterType: LeadingCounterType): void {
        this.selectedCounterType = counterType;
        this.selectionChanged.emit({ leadingCounter: this.selectedCounterType, counterId: this.counterId });

        this.invoiceNumberJournalEntryService.create({
            entryType: 'invoiceOrReportCounterChanged',
            leadingCounter: this.selectedCounterType,
            reportTokenConfigId: this.counterId,
        });
    }

    protected closeDialog(): void {
        this.overlayRef.detach();
    }

    //*****************************************************************************
    //  Keyboard Shortcuts
    //****************************************************************************/
    @HostListener('window:keydown', ['$event'])
    protected closeEditorOnEscKey(event) {
        if (event.key === 'Escape') {
            // Make sure saving is triggered if the user is still inside an input.
            if (document.activeElement.nodeName === 'INPUT' || document.activeElement.nodeName === 'TEXTAREA') {
                (document.activeElement as HTMLElement).blur();
            }
            void this.closeDialog();
        }
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Keyboard Shortcuts
    /////////////////////////////////////////////////////////////////////////////*/
}

import { FeeTableName } from '../../models/reports/assessors-fee/fee-calculation';
import { bvskFeeTable2024 } from '../../static-data/fee-tables/bvsk-fee-table-2024';
import { cgfFeeTable2023 } from '../../static-data/fee-tables/cgf-fee-table-2023';
import { hukFeeTable2025 } from '../../static-data/fee-tables/huk-fee-table-2025';
import { vksFeeTable2021 } from '../../static-data/fee-tables/vks-fee-table-2021';

export function getYearOfLatestFeeTable(feeTableName: FeeTableName) {
    switch (feeTableName) {
        case 'HUK':
            return hukFeeTable2025.year;
        case 'BVSK':
            return bvskFeeTable2024.year;
        case 'VKS':
            return vksFeeTable2021.year;
        case 'CGF':
            return cgfFeeTable2023.year;
    }
}

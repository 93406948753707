import { Injectable } from '@angular/core';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { fromPromise } from 'rxjs/internal-compatibility';
import { filter } from 'rxjs/operators';
import { getUserFullName } from '@autoixpert/lib/users/get-user-full-name';
import { Report } from '@autoixpert/models/reports/report';
import { createOrUpdateInvoice } from '../libraries/report/create-or-update-report-invoice';
import { lockReport } from '../libraries/report/lock-report';
import { unlockReport } from '../libraries/report/unlock-report';
import { ApiErrorService } from './api-error.service';
import { ContactPersonService } from './contact-person.service';
import { InvoiceNumberService } from './invoice-number.service';
import { InvoiceService } from './invoice.service';
import { LoggedInUserService } from './logged-in-user.service';
import { ReportTokenAndInvoiceNumberService } from './report-token-and-invoice-number.service';
import { ReportTokenService } from './report-token.service';
import { ReportService } from './report.service';
import { ToastService } from './toast.service';
import { UserService } from './user.service';

@Injectable()
export class ReportDetailsService {
    constructor(
        private reportService: ReportService,
        private reportTokenAndInvoiceNumberService: ReportTokenAndInvoiceNumberService,
        private reportTokenService: ReportTokenService,
        private invoiceNumberService: InvoiceNumberService,
        private contactPersonService: ContactPersonService,
        private invoiceService: InvoiceService,
        private loggedInUserService: LoggedInUserService,
        private toastService: ToastService,
        private apiErrorService: ApiErrorService,
        private userService: UserService,
        private dialog: MatDialog,
    ) {}

    private currentReport$: BehaviorSubject<Report> = new BehaviorSubject<Report>(null);
    public reportTypeChange$: Subject<ReportTypeChange> = new Subject<ReportTypeChange>();

    private activeReportRequests = new Set<Report['_id']>();

    /**
     * Return the currently saved report if the reportId param matches the currently saved report. Otherwise, get that report
     */
    public get(reportId: string): Observable<Report> {
        /**
         * If the requested report is not in this service's behavior subject cache, retrieve the report from the Report Service.
         * Also, prevent multiple requests if various calls to reportDetailsService.get() are triggered for the same report.
         */
        if (
            (!this.currentReport$.getValue() || this.currentReport$.getValue()._id !== reportId) &&
            !this.activeReportRequests.has(reportId)
        ) {
            this.activeReportRequests.add(reportId);

            // Get the right report
            fromPromise(this.reportService.get(reportId)).subscribe({
                next: (report) => {
                    this.currentReport$.next(report);
                    this.activeReportRequests.delete(reportId);
                },
                error: (err) => {
                    this.currentReport$.error(err);
                    // After throwing an error, the subject will only emit the error again, no regular values. Create a new one.
                    this.currentReport$ = new BehaviorSubject<Report>(null);

                    this.activeReportRequests.delete(reportId);
                },
                complete: () => {
                    // Don't forward the complete event. A completed subject won't emit next events, not even the saved one.
                },
            });
        }

        // Don't pass the subscriber null values to prevent accessing properties of null.
        // When would we pass null values? Not at first, but when we clear the report after a different report ID is requested. The subscriber is still listening when setting the behavior subject to null.
        return this.currentReport$.pipe(filter((report) => !!report && report._id === reportId));
    }

    public patch(report: Report, options: { waitForServer?: boolean } = {}): Promise<Report> {
        return this.reportService.put(report, options);
    }

    public clearCachedReport(): void {
        this.currentReport$.next(null);
    }

    public emitReportTypeChange(reportTypeChange: ReportTypeChange) {
        this.reportTypeChange$.next(reportTypeChange);
    }

    public async toggleLockReport(report: Report): Promise<void> {
        // Is locked -> unlock
        if (report.state === 'done') {
            unlockReport(report);
            await this.saveReport(report);
        }
        // Isn't locked -> Lock
        else {
            // Maybe an invoice number needs to be generated. That process is asynchronous.
            const completedTasks: string[] = [];

            let tasks: string[];
            try {
                tasks = await lockReport(
                    report,
                    this.loggedInUserService.getUser()._id,
                    this.reportTokenService,
                    this.reportTokenAndInvoiceNumberService,
                    this.invoiceNumberService,
                    this.contactPersonService,
                    this.toastService,
                    this.dialog,
                );
            } catch (error) {
                // Update server state maintained in the ReportService. Otherwise, the ReportService thinks the report on the server is already locked (which didn't happen in this errored request).
                this.clearCachedReport();
                this.get(report._id).subscribe({
                    next: () => {
                        this.saveReport(report);
                    },
                });

                this.apiErrorService.handleAndRethrow({
                    axError: error,
                    handlers: {},
                    defaultHandler: {
                        title: 'Gutachten nicht abgeschlossen',
                        body: 'Bitte öffne das Gutachten erneut und schließe es noch einmal ab. Wenn es immer noch nicht funktioniert, wende dich an die <a href="/Hilfe" target="_blank">Hotline</a>.',
                    },
                });
            }

            completedTasks.push(...tasks);
            await this.saveReport(report);

            //*****************************************************************************
            //  Create Or Update Invoice
            //****************************************************************************/
            let invoiceCreationResult: Awaited<ReturnType<typeof createOrUpdateInvoice>>;
            try {
                invoiceCreationResult = await createOrUpdateInvoice(
                    report,
                    this.loggedInUserService.getUser(),
                    this.loggedInUserService.getTeam(),
                    this.invoiceService,
                    this.toastService,
                    this,
                );
            } catch (error) {
                this.apiErrorService.handleAndRethrow({
                    axError: error,
                    handlers: {
                        INVALID_ELECTRONIC_INVOICE_PARAMETERS: {
                            title: 'Ungültige E-Rechnung',
                            body: 'Die Rechnung konnte nicht geschrieben werden, weil Parameter für die E-Rechnung falsch sind. Bitte prüfe die Rechnungsdaten, öffne das Gutachten und schließe es erneut ab.',
                        },
                    },
                    defaultHandler: {
                        title: 'Rechnung nicht geschrieben',
                        body: "Bitte prüfe, ob die Rechnungsdaten für eine E-Rechnung vollständig sind. Falls du nicht weiterkommst, kontaktiere im Notfall die <a href='/Hilfe'>Hotline</a>.",
                    },
                });
            }

            if (invoiceCreationResult.invoiceUpdated) {
                completedTasks.push('Rechnung aktualisiert, s. Rechnungsmodul');
                // Update the report invoice to reflect that it might have been automatically locked
                await this.invoiceService.getReportInvoice(report);
            } else if (invoiceCreationResult.invoiceCreated) {
                // On saving the report, the invoice has been created
                completedTasks.push('Rechnung geschrieben, s. Rechnungsmodul');
                // Update the report invoice to reflect that it might have been automatically locked
                await this.invoiceService.getReportInvoice(report);
            }
            /////////////////////////////////////////////////////////////////////////////*/
            //  END Create Or Update Invoice
            /////////////////////////////////////////////////////////////////////////////*/

            this.toastService.success('Gutachten abgeschlossen', completedTasks.join('\n'));
        }
    }

    public async saveReport(report: Report, { waitForServer }: { waitForServer?: boolean } = {}): Promise<Report> {
        try {
            return await this.patch(report, { waitForServer });
        } catch (error) {
            this.apiErrorService.handleAndRethrow({
                axError: error,
                handlers: {
                    FEATHERS_ERROR: () => {
                        if (error.data.feathersErrorName === 'Conflict') {
                            return {
                                title: 'Eine Rechnung mit dieser ID existiert bereits',
                                body: 'Es wurde keine Rechnung geschrieben. Bitte wende Dich an den autoiXpert-Support',
                            };
                        } else {
                            return {
                                title: 'Ein Fehler ist aufgetreten',
                                body: 'Es wurde keine Rechnung geschrieben. Bitte wende Dich an den autoiXpert-Support',
                            };
                        }
                    },
                    MISSING_PERMISSIONS_INVOICE: (error) => {
                        // Get the creator from cache to avoid async request
                        const teamMembers = this.userService.getAllTeamMembersFromCache();
                        const creator = teamMembers.find((teamMember) => teamMember._id === error.data.invoiceCreator);
                        return {
                            title: 'Keine Berechtigung für Rechnung',
                            body: `Die Rechnung, die bereits zu diesem Gutachten geschrieben wurde, wurde von ${
                                creator ? getUserFullName(creator) : 'einem anderen Nutzer'
                            } angelegt. Du hast keine Berechtigung, diese Rechnung zu bearbeiten.`,
                        };
                    },
                },
                defaultHandler: {
                    title: 'Gutachten nicht gespeichert',
                    body: 'Bitte wende dich an die <a href="/Hilfe" target="_blank">Hotline</a>.',
                },
            });
        }
    }
}

export interface ReportTypeChange {
    report: Report;
    previousType: Report['type'];
    newType: Report['type'];
}

<div class="dialog-overlay" (mousedown)="handleOverlayClick($event)" @dialogEnterAndLeave>
    <div class="dialog-container display-flex">
        <!--============================================-->
        <!-- Left Column -->
        <!--============================================-->
        <div id="left-column" class="card">
            <h2>Neue Dokumentvorlage</h2>
            <span class="label">
                {{documentType === 'reportAttachment' ? 'Gutachten-Anhang' : 'Unterschriften-Dokument'}}
            </span>

            <div class="input-field-and-icon-container">
                <div class="input-fields">
                    <mat-form-field class="mat-block">
                        <input matInput placeholder="Titel" [(ngModel)]="documentOrderConfig.titleLong" />
                    </mat-form-field>

                    <mat-form-field
                        class="mat-block"
                        matTooltip="Halte für dich und deine Kollegen fest, wofür dieses Dokument vorgesehen ist.">
                        <input
                            matInput
                            placeholder="Interne Beschreibung"
                            [(ngModel)]="documentOrderConfig.customDocumentConfig.description" />
                    </mat-form-field>
                </div>

                <icon-picker
                    *ngIf="documentType === 'signature'"
                    [icon]="documentOrderConfig.customDocumentConfig.googleMaterialIconName"
                    (iconPicked)="documentOrderConfig.customDocumentConfig.googleMaterialIconName = $event.icon"></icon-picker>
            </div>

            <div class="report-type-container">
                <h3 class="left-column-h3">Gutachten-Typen</h3>
                <span class="label">In welchen Gutachten-Typen soll dieses Dokument verfügbar sein?</span>

                <white-button-with-shadow (click)="selectedReportTypes.openDialog()">
                    <selected-report-types
                        #selectedReportTypes
                        (click)="$event.stopPropagation()"
                        [(selectedReportTypes)]="documentOrderConfig.customDocumentConfig.availableInReportTypes"></selected-report-types>
                </white-button-with-shadow>
            </div>

            <div
                *ngIf="documentType === 'signature' && location !== 'signable-pdf-templates'"
                class="info-note"
                @fadeInAndOut>
                <mat-icon class="regular-size-icon">info</mat-icon>
                <div>
                    <h3 class="no-margin">PDF-Vorlage hochladen (optional)</h3>
                    <!-- Don't let prettier format the following inline elements (to prevent whitespace between anchor and full stop) -->
                    <!-- prettier-ignore -->
                    <p class="no-margin">
                            Um eine PDF-Datei hochzuladen, wechsele nach der Anlage dieses Dokuments in den Bereich
                            <a routerLink="/Einstellungen/Unterschreibbare-PDF-Dokumente">PDF-Kundenunterschriften</a>. Dort steht dir bei der Neuanlage ein neuer Dokument-Typ zur Verfügung.
                        </p>
                </div>
            </div>

            <ng-container *ngIf="documentType === 'reportAttachment'">
                <h3 class="left-column-h3">Optionen</h3>

                <preferences-row iconName="attach_file_add" [noHorizontalPadding]="true">
                    <span>Automatisch anhängen</span>
                    <div class="label light">
                        Bei neuen Gutachten automatisch einfügen? Alternativ kannst du es von Hand einfügen.
                    </div>

                    <mat-slide-toggle
                        right
                        [(ngModel)]="documentOrderConfig.customDocumentConfig.isCreatedOnReportCreation"></mat-slide-toggle>
                </preferences-row>
            </ng-container>

            <div class="dialog-buttons-container">
                <button (click)="saveDocumentOrderConfig()" [disabled]="!documentOrderConfig.titleLong">
                    Speichern
                </button>
                <button (click)="closeAndDiscard()" class="secondary">Abbrechen</button>
            </div>
        </div>
        <!--============================================-->
        <!-- END Left Column -->
        <!--============================================-->
        <!--============================================-->
        <!-- Right Column -->
        <!--============================================-->
        <div id="right-column" class="card">
            <!-- Close button -->
            <div id="close-button" (click)="closeAndDiscard()">
                <mat-icon>close</mat-icon>
            </div>

            <h2>Hilfe-Ressourcen</h2>
            <p *ngIf="documentType === 'reportAttachment'" class="text-align-center no-margin">
                Lege eine neue Dokumentvorlage an, um Daten aus einem Vorgang in einer separaten Datei abzudrucken, die
                im Standard nicht vorhanden ist, z. B. ein Beratungsprotokoll oder eine Reparaturkostenübernahme (RKÜ).
                <br />
                <br />
                Dieses Dokument kann Textbausteine beinhalten, optional erweitert um Word-Vorlagen (“DOCX-Partials”).
            </p>

            <p *ngIf="documentType === 'signature'" class="text-align-center no-margin">
                Lasse zusätzliche Dokumente von deinem Kunden unterschreiben.
                <br />
                <br />
                Du kannst zwischen zwei Dokumenttypen wählen: Ein Dokument mit Textbausteinen
                <!--(ohne DOCX-Vorlagen
                <span class="help-indicator" matTooltip="">?</span>-->
                oder eine eigene PDF-Datei, die du im Anschluss hochlädst. Beide unterstützen Platzhalter.
            </p>

            <!-- 
            <div class="help-center-card card">
                <h3 class="no-margin text-align-center">Wissens-Center</h3>
                <div *ngIf="documentType === 'reportAttachment'" class="help-center-links">
                    <a href="">Eigene Gutachten-Anhänge</a>
                    <a href="">Platzhalter in Word verwenden</a>
                    <a href="">Preise Support für DOCX-Anpassungen</a>
                </div>
                <div *ngIf="documentType === 'signature'" class="help-center-links">
                    <a href="">Eigene Unterschriftsdokumente</a>
                    <a href="">PDF-Kundenunterschriften</a>
                </div>
            </div>

            <div
                *ngIf="documentType === 'reportAttachment'"
                class="video-card card"
                (click)="openNewWindow('TODO:replace-with-actual-link')">
                <div class="video-thumbnail-container">
                    <img
                        class="video-thumbnail"
                        src="/assets/images/video-thumbnails/tour-video-thumbnail.png"
                        alt="Tour Video" />
                    <img class="video-play-icon" src="/assets/images/icons/play.svg" alt="Play" />
                </div>
                <div class="video-title">Eigene Gutachten-Anhänge</div>
                <div class="video-duration">X:XX min</div>
            </div>

            <div
                *ngIf="documentType === 'signature'"
                class="video-card card"
                (click)="openNewWindow('TODO:replace-with-actual-link')">
                <div class="video-thumbnail-container">
                    <img
                        class="video-thumbnail"
                        src="/assets/images/video-thumbnails/tour-video-thumbnail.png"
                        alt="Tour Video" />
                    <img class="video-play-icon" src="/assets/images/icons/play.svg" alt="Play" />
                </div>
                <div class="video-title">Eigene Unterschriftsdokumente</div>
                <div class="video-duration">X:XX min</div>
            </div>
            -->
            <mat-icon id="background-info-icon" class="outlined">info</mat-icon>
        </div>
        <!--============================================-->
        <!-- END Right Column -->
        <!--============================================-->
    </div>
</div>

import { Component, Input, OnChanges } from '@angular/core';
import { Router } from '@angular/router';
import { fadeInAndOutAnimation } from '@autoixpert/animations/fade-in-and-out.animation';
import { getFullName } from '@autoixpert/lib/placeholder-values/get-full-name';
import { InvolvedPartyTypeGerman, Translator } from '@autoixpert/lib/placeholder-values/translator';
import { ContactPerson } from '@autoixpert/models/contacts/contact-person';
import { InvolvedPartyRole } from '@autoixpert/models/reports/involved-parties/involved-party';
import { ReportType } from '@autoixpert/models/reports/report';
import { slideInAndOutVertically } from '../../animations/slide-in-and-out-vertical.animation';
import { getFileNameForInsuranceLogo, insuranceLogoExists } from '../../libraries/insurances/insurance-logo-exists';

@Component({
    selector: 'involved-party-contact-details',
    templateUrl: './involved-party-contact-details.component.html',
    styleUrls: ['./involved-party-contact-details.component.scss'],
    animations: [slideInAndOutVertically(200, 200), fadeInAndOutAnimation()],
})
export class InvolvedPartyContactDetailsComponent implements OnChanges {
    @Input() contactPerson: ContactPerson;
    @Input() involvedPartyRole: InvolvedPartyRole;
    @Input() reportType: ReportType;
    @Input() reportId: string;
    @Input() hasReceivedEmail: boolean = false;
    @Input() hasReceivedLetter: boolean = false;

    protected iconNameForInsurance: string;
    protected organizationOrName: string;
    protected firstAndLastName: string;
    protected involvedPartyTypeGerman: InvolvedPartyTypeGerman;

    protected contactDetailsVisible: boolean;

    constructor(private router: Router) {}

    ngOnChanges() {
        if (this.involvedPartyRole === 'insurance') {
            this.iconNameForInsurance = getFileNameForInsuranceLogo(this.contactPerson.organization);
        }

        this.involvedPartyTypeGerman = Translator.involvedPartyType(this.involvedPartyRole, this.reportType);

        if (this.contactPerson) {
            this.organizationOrName = this.contactPerson.organization || getFullName(this.contactPerson);
            this.firstAndLastName = getFullName(this.contactPerson);
        }
    }

    protected toggleContactDetails() {
        this.contactDetailsVisible = !this.contactDetailsVisible;
    }

    protected navigateToPrintAndTransmissionPage(): void {
        if (this.reportId) {
            this.router.navigate(['Gutachten', this.reportId, 'Druck-und-Versand']);
        }
    }

    protected readonly insuranceLogoExists = insuranceLogoExists;
}

import { Component, Input, OnInit } from '@angular/core';
import { HostListener } from '@angular/core';
import { DateTime } from 'luxon';
import 'moment-duration-format';
import { joinListToHumanReadableString } from '@autoixpert/lib/arrays/join-list-to-human-readable-string';
import { getVehicleValueGross } from '@autoixpert/lib/car-valuation/get-vehicle-value';
import { todayIso } from '@autoixpert/lib/date/iso-date';
import { addDocumentToReport } from '@autoixpert/lib/documents/add-document-to-report';
import { removeDocumentByType } from '@autoixpert/lib/documents/remove-document-by-type';
import { isReportLocked } from '@autoixpert/lib/report/is-report-locked';
import { translateDocumentType } from '@autoixpert/lib/translators/translate-document-type';
import { DocumentMetadata } from '@autoixpert/models/documents/document-metadata';
import { AfzzertProcess } from '@autoixpert/models/reports/afzzert-process';
import { Report } from '@autoixpert/models/reports/report';
import { Team } from '@autoixpert/models/teams/team';
import { User } from '@autoixpert/models/user/user';
import { fadeInAndOutAnimation } from '../../../../shared/animations/fade-in-and-out.animation';
import { getAfzzertErrorHandlers } from '../../../../shared/libraries/error-handlers/get-afzzert-error-handlers';
import { AfzzertProcessesService } from '../../../../shared/services/afzzert-processes.service';
import { ApiErrorService } from '../../../../shared/services/api-error.service';
import { DownloadService } from '../../../../shared/services/download.service';
import { NetworkStatusService } from '../../../../shared/services/network-status.service';
import { NewWindowService } from '../../../../shared/services/new-window.service';
import { ReportService } from '../../../../shared/services/report.service';
import { ToastService } from '../../../../shared/services/toast.service';

@Component({
    selector: 'old-vehicle-certificates-overview',
    templateUrl: 'old-vehicle-certificates-overview.component.html',
    styleUrls: ['old-vehicle-certificates-overview.component.scss'],
    animations: [fadeInAndOutAnimation()],
})
export class OldVehicleCertificatesOverviewComponent implements OnInit {
    constructor(
        private reportService: ReportService,
        private toastService: ToastService,
        private newWindowService: NewWindowService,
        private downloadService: DownloadService,
        private apiErrorService: ApiErrorService,
        private networkStatusService: NetworkStatusService,
        private afzzertProcessesService: AfzzertProcessesService,
    ) {}

    @Input() report: Report;
    @Input() user: User;
    @Input() team: Team;

    protected isAfzzertApiPending = false;

    ngOnInit(): void {
        if (!this.report.car.afzzertProcess) {
            this.report.car.afzzertProcess = new AfzzertProcess({
                certificateDate: todayIso(),
            });
        }
    }

    //*****************************************************************************
    //  Create Process & Open Afz-Zert. User Interface
    //****************************************************************************/
    protected areCredentialsComplete(): boolean {
        return !!this.user.afzzertUser.apiKey;
    }

    protected isExportAllowed(): boolean {
        if (!this.report.car.vin) {
            return false;
        }
        if (!this.report.car.firstRegistration) {
            return false;
        }
        if (!getVehicleValueGross(this.report.valuation, 'replacementValue')) {
            return false;
        }
        if (!this.report.car.make || !this.report.car.model) {
            return false;
        }
        if (!this.report.car.afzzertProcess.certificateDate) {
            return false;
        }
        // noinspection RedundantIfStatementJS
        if (!this.report.car.roadworthiness) {
            return false;
        }
        return true;
    }

    protected getTooltipForExportIcon(): string {
        if (!this.areCredentialsComplete()) {
            return 'Die Zugangsdaten müssen erst in den <a href="/Einstellungen#afzzert-container">Einstellungen</a> eingegeben werden.';
        }

        if (this.isReportLocked()) {
            return 'Das Gutachten ist abgeschlossen';
        }

        if (!this.isExportAllowed()) {
            const missingData: string[] = [];
            if (!this.report.car.vin) {
                missingData.push('VIN');
            }
            if (!this.report.car.firstRegistration) {
                missingData.push('Erstzulassung');
            }
            if (!getVehicleValueGross(this.report.valuation, 'replacementValue')) {
                missingData.push('Wiederbeschaffungswert');
            }
            if (!this.report.car.make) {
                missingData.push('Hersteller');
            }
            if (!this.report.car.model) {
                missingData.push('Haupttyp');
            }
            if (!this.report.car.afzzertProcess.certificateDate) {
                missingData.push('Datum des Zertifikats');
            }
            // noinspection RedundantIfStatementJS
            if (!this.report.car.roadworthiness) {
                missingData.push('Fahrfähigkeit');
            }
            return `Bitte gib noch ein: ${joinListToHumanReadableString(missingData)}`;
        }

        if (this.isCertificateImported()) {
            return 'Zertifikat erfolgreich importiert.';
        }
        return 'Zertifikat erstellen';
    }

    /**
     * We cannot reopen this specific certificate reliably because the login URL is only valid for 30 minutes.
     * Instead, we always open the website, let the user log in again and have him look for the certificate
     * manually.
     */
    protected openAfzzertWebsite() {
        this.newWindowService.open(this.report.car.afzzertProcess.loginUrl, '_blank', 'noopener');
    }

    protected isProcessStarted(): boolean {
        return !!this.report.car.afzzertProcess.loginUrl;
    }

    protected isCertificateImported(): boolean {
        return !!this.report.car.afzzertProcess?.certificateImportedAt;
    }

    protected async createAfzzertProcess() {
        if (this.isCertificateImported()) {
            this.toastService.success(
                'Zertifikat schon importiert',
                'Ein weiterer Prozess kann erst gestartet werden, wenn der aktuelle gelöscht wird.',
            );
            return;
        } else if (this.isProcessStarted()) {
            // Auto-login URL is valid for 30 minutes.
            if (DateTime.now() < DateTime.fromISO(this.report.car.afzzertProcess.loginUrlExpiresAt)) {
                this.newWindowService.open(this.report.car.afzzertProcess.loginUrl, '_blank', 'noopener');
                return;
            } else {
                await this.deleteAfzzertProcess();
            }
        }

        if (!this.networkStatusService.isOnline()) {
            try {
                /**
                 * Try to connect to the network. Maybe the user is back online again.
                 */
                await this.networkStatusService.detectNetworkStatus();
            } catch (error) {
                this.toastService.offline('Afz-Zert. nur online nutzbar');
                return;
            }
        }

        if (!this.isExportAllowed()) {
            if (!this.isReportLocked()) {
                this.toastService.partnerError('Pflichtangaben fehlen', this.getTooltipForExportIcon(), 'afzzert');
            }
            return;
        }

        this.isAfzzertApiPending = true;

        //*****************************************************************************
        //  Get Login URL
        //****************************************************************************/
        let loginUrl: string;
        let loginUrlExpiresAt: string;
        try {
            ({ loginUrl, loginUrlExpiresAt } = await this.afzzertProcessesService.create({
                reportId: this.report._id,
            }));
        } catch (error) {
            this.apiErrorService.handleAndRethrow({
                axError: error,
                handlers: {
                    ...getAfzzertErrorHandlers(),
                },
                defaultHandler: {
                    title: 'Zertifikat nicht angelegt',
                    body: 'Der Prozess zum Erstellen des Zertifikats konnte nicht gestartet werden. Bitte versuche es erneut. Bleibt der Fehler bestehen, wende dich bitte an die <a href="/Hilfe" target="_blank">Hotline</a>',
                },
            });
        } finally {
            this.isAfzzertApiPending = false;
        }
        /////////////////////////////////////////////////////////////////////////////*/
        //  END Get Login URL
        /////////////////////////////////////////////////////////////////////////////*/

        //*****************************************************************************
        //  Save Login URL
        //****************************************************************************/
        this.report.car.afzzertProcess.loginUrl = loginUrl;
        this.report.car.afzzertProcess.loginUrlExpiresAt = loginUrlExpiresAt;
        this.saveReport();
        /////////////////////////////////////////////////////////////////////////////*/
        //  END Save Login URL
        /////////////////////////////////////////////////////////////////////////////*/

        this.newWindowService.open(loginUrl, '_blank', 'noopener');
    }
    /////////////////////////////////////////////////////////////////////////////*/
    //  END Create Process & Open Afz-Zert. User Interface
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Import Certificate
    //****************************************************************************/
    protected async importCertificate({ automaticImport = false }: { automaticImport?: boolean } = {}) {
        if (!this.networkStatusService.isOnline()) {
            try {
                /**
                 * Try to connect to the network. Maybe the user is back online again.
                 */
                await this.networkStatusService.detectNetworkStatus();
            } catch (error) {
                !automaticImport && this.toastService.offline('Afz-Zert. nur online nutzbar');
                return;
            }
        }

        try {
            this.isAfzzertApiPending = true;
            await this.afzzertProcessesService.importCertificate({
                reportId: this.report._id,
            });
        } catch (error) {
            if (automaticImport) {
                /**
                 * Fail silently if the certificate is imported because the user switched back to this tab.
                 * This prevents the user from getting error messages simply when switching tabs.
                 */
                return;
            }

            this.apiErrorService.handleAndRethrow({
                axError: error,
                handlers: {
                    ...getAfzzertErrorHandlers(),
                },
                defaultHandler: {
                    title: 'Zertifikat nicht importiert',
                    body: 'Das Zertifikat konnte nicht importiert werden. Bitte versuche es erneut. Bleibt der Fehler bestehen, wende dich bitte an die <a href="/Hilfe" target="_blank">Hotline</a>.',
                },
            });
        } finally {
            this.isAfzzertApiPending = false;
        }

        addDocumentToReport({
            report: this.report,
            team: this.team,
            documentGroup: 'report',
            newDocument: new DocumentMetadata({
                type: 'afzzertCertificate',
                title: translateDocumentType('afzzertCertificate'),
                uploadedDocumentId: null,
                permanentUserUploadedDocument: false,
                createdBy: this.user._id,
            }),
        });

        this.toastService.success('Altfahrzeugzertifikat importiert', 'Du findest es im Reiter "Druck & Versand".');

        this.report.car.afzzertProcess.certificateImportedAt = DateTime.now().toISO();
        this.saveReport();
    }
    /////////////////////////////////////////////////////////////////////////////*/
    //  END Import Certificate
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Delete Process
    //****************************************************************************/
    protected async deleteAfzzertProcess() {
        if (!this.networkStatusService.isOnline()) {
            try {
                /**
                 * Try to connect to the network. Maybe the user is back online again.
                 */
                await this.networkStatusService.detectNetworkStatus();
            } catch (error) {
                this.toastService.offline('Afz-Zert. nur online nutzbar');
                return;
            }
        }

        this.report.car.afzzertProcess.certificateImportedAt = null;
        this.report.car.afzzertProcess.loginUrlExpiresAt = null;
        this.report.car.afzzertProcess.loginUrl = null;

        removeDocumentByType({
            documentOrders: this.report.documentOrders,
            documents: this.report.documents,
            documentType: 'afzzertCertificate',
        });

        this.saveReport();

        // This deletes the potentially imported certificate file from our object store.
        await this.afzzertProcessesService.remove({ reportId: this.report._id });
    }
    /////////////////////////////////////////////////////////////////////////////*/
    //  END Delete Process
    /////////////////////////////////////////////////////////////////////////////*/

    protected async downloadCertificatePdf() {
        const response = await this.afzzertProcessesService.downloadCertificatePdf({ reportId: this.report._id });
        this.downloadService.downloadBlobResponseWithHeaders(response);
    }

    protected isReportLocked(): boolean {
        return isReportLocked(this.report);
    }

    protected saveReport() {
        void this.reportService.put(this.report);
    }
    //*****************************************************************************
    //  Events
    //****************************************************************************/
    @HostListener('window:visibilitychange', ['$event'])
    protected async importPendingMarketAnalyses() {
        if (document.visibilityState === 'visible') {
            if (!this.report) return;

            if (this.isProcessStarted() && !this.isCertificateImported()) {
                await this.importCertificate({ automaticImport: true });
            }
        }
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Events
    /////////////////////////////////////////////////////////////////////////////*/
}

import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { dialogEnterAndLeaveAnimation } from '@autoixpert/animations/dialog-enter-and-leave.animation';
import { fadeInAndOutAnimation } from '@autoixpert/animations/fade-in-and-out.animation';
import { Subtype } from '@autoixpert/helper-types/subtype';
import { DocumentTypeGroupName } from '@autoixpert/lib/documents/get-document-type-groups-from-document-order-configs';
import { DocumentOrderConfig } from '@autoixpert/models/documents/document-order-config';
import { reportTypes } from '@autoixpert/models/reports/report';
import { NewWindowService } from '../../shared/services/new-window.service';

@Component({
    selector: 'document-order-config-creation-dialog',
    templateUrl: './document-order-config-creation-dialog.html',
    styleUrls: ['./document-order-config-creation-dialog.scss'],
    animations: [dialogEnterAndLeaveAnimation(), fadeInAndOutAnimation()],
})
export class DocumentOrderConfigCreationDialogComponent implements OnInit {
    @Input() documentType: Subtype<DocumentTypeGroupName, 'reportAttachment' | 'signature'> = 'signature';
    @Input() location: 'document-building-blocks' | 'signable-pdf-templates' = 'document-building-blocks';

    /**
     * If an existing document order config should be edited, it can be passed here.
     * The dialog creates a copy of the object, so the original object is not modified.
     */
    @Input() documentOrderConfigToEdit: DocumentOrderConfig;

    @Output() close = new EventEmitter<any>();
    @Output() documentOrderConfigCreated = new EventEmitter<DocumentOrderConfig>();

    /**
     * Temporary helper object to store the current user input. Which is either the data for
     * a new document order config or the edited data for an existing config. This data
     * is discarded when the user cancels the dialog. When saving, the parent of this dialog
     * is responsible for saving the new data.
     */
    protected documentOrderConfig: DocumentOrderConfig;

    constructor(private newWindowService: NewWindowService) {}

    ngOnInit() {
        if (this.documentOrderConfigToEdit) {
            // Edit an existing config (copy, so that changes can be discarded)
            this.documentOrderConfig = structuredClone(this.documentOrderConfigToEdit);
        } else {
            // Create a new config
            this.documentOrderConfig = new DocumentOrderConfig({
                customDocumentConfig: {
                    documentTypeGroup: this.documentType,
                    isCreatedOnReportCreation: false,
                    availableInReportTypes: structuredClone(reportTypes),
                    description: null,
                    googleMaterialIconName: this.documentType === 'signature' ? 'account_balance' : null,
                },
            });
            this.documentOrderConfig.type = 'customDocument';
        }
    }

    public handleOverlayClick(event: MouseEvent): void {
        if (event.target === event.currentTarget) {
            this.closeAndDiscard();
        }
    }

    public closeAndDiscard(): void {
        this.dispatchCloseEvent({ confirm: false });
    }

    public openNewWindow(url: string) {
        this.newWindowService.open(url);
    }

    protected saveDocumentOrderConfig(): void {
        this.documentOrderConfig.titleShort = this.documentOrderConfig.titleLong;

        this.documentOrderConfigCreated.emit(this.documentOrderConfig);
        this.dispatchCloseEvent({ confirm: false });
    }

    protected dispatchCloseEvent({ confirm }: { confirm: boolean }): void {
        if (!confirm) {
            this.close.emit();
            return;
        }
    }
}

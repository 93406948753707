import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatIconModule } from '@angular/material/icon';
import { BrowserModule } from '@angular/platform-browser';
import { ActionToolbarItemComponent } from './action-toolbar-item/action-toolbar-item.component';
import { ActionToolbarComponent } from './action-toolbar/action-toolbar.component';

@NgModule({
    imports: [BrowserModule, MatIconModule, FormsModule],
    declarations: [ActionToolbarComponent, ActionToolbarItemComponent],
    exports: [ActionToolbarComponent, ActionToolbarItemComponent],
})
export class ActionToolbarModule {}

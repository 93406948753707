import { IsoDate } from '../../../lib/date/iso-date.types';

export class AudatexValuation {
    referenceDate: IsoDate;

    originalPriceWithoutEquipmentNet: number;
    originalPriceWithoutEquipmentGross: number;
    originalPriceWithEquipmentNet: number;
    originalPriceWithEquipmentGross: number;

    dealerPurchasePriceNet: number;
    dealerPurchasePriceGross: number;
    dealerSalesPriceNet: number;
    dealerSalesPriceGross: number;
    replacementValueNet: number;
    replacementValueGross: number;
    replacementValueDifference: number;
    marketValueNet: number;
    marketValueGross: number;

    // 2023-11-24: There seems to be no option to get Audatex to return the present value ("Zeitwert") in the OverwrittenValuationResults.
    presentValueNet: number;
    presentValueGross: number;

    corridorHiddenOnPrintout: boolean;
    documentHash: string;

    // Values retrieved
    // Before this flag, we checked the vehicle values if greater than 0 to determine, if we already retrieved the valuation results.
    // This is not correct, because the vehicle value may be 0 (e.g. if the "Instandsetzungskosten" in the DAT-Valuation are higher than the vehicle value).
    valuesRetrieved: boolean;
}
